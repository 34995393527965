import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isShow: false,
    isSubmit: false,
    dataAlert: [],
    status_dataAlert: true,
    modal_xray: false,
    xray_page: false,
    dropdown: false
  },
  reducers: {
    show: (state) => {
      state.isShow = true
    },
    close: (state) => {
      state.isShow = false
    },
    open: (state, a) => {
      state.dropdown = false
    },
    reset: (state) => {
      state.isSubmit = false
      state.isShow = false
    },
    submit: (state) => {
      state.isSubmit = true
    },
    unSubmit: (state) => {
      state.isSubmit = false
    },
    setDataAlert: (state, action: any) => {
      state.dataAlert = action.payload
    },
    setStatus_dataAlert: (state, action: any) => {
      state.status_dataAlert = action.payload
    },
    setModal_xray: (state, action: any) => {
      state.modal_xray = action.payload
    },
    setXrayPage: (state, action: any) => {
      state.xray_page = action.payload
    }
  }
})

export const {
  show: showModal,
  close: closeModal,
  open: openDropdown,
  reset: resetModal,
  submit: submitModal,
  unSubmit: unSubmitModal,
  setDataAlert: setDataAlert,
  setStatus_dataAlert: setStatus_dataAlert,
  setModal_xray: setModal_xray,
  setXrayPage: setXrayPage
} = modalSlice.actions

export const handleShow = (state: any) => state.modal.isShow

export default modalSlice.reducer
