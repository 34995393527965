import { Box, Button, Chip } from '@mui/material';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'

export const TypeTabs = styled(Box)(({ theme }) => ({
    margin: '-0.5rem -0.25rem 0',
    maxHeight: 76,
    overflowY: 'auto',
    '> .MuiBox-root': {
        width: '7.69%',
        [theme.breakpoints.down('xl')]: {
            width: '10%'
        },
        [theme.breakpoints.down('md')]: {
            width: '12.5%'
        },
        [theme.breakpoints.down('md')]: {
            width: '16.667%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '33.33%'
        }
    }
}))

export const ButtonType = styled(Button)(({ theme }) => ({
    width: '100%',
    height: 30,
    color: colors.textPrimary,
    borderColor: `${colors.themeSecondColor} !important`,
    '&.active': {
        backgroundColor: colors.themeSecondColor,
        color: colors.white
    }
}))

export const ProductItem = styled(Box)(({ theme }) => ({
    margin: '0 -0.5rem -1rem',
    minHeight: 380,
    maxHeight: 450,
    overflowX: 'auto',
    [theme.breakpoints.down('lg')]: {
        margin: '0 -0.25rem -0.5rem',
    },
    [theme.breakpoints.down('md')]: {
        maxHeight: 375
    },
}))

export const ProductBox = styled(Box)(({ theme }) => ({
    width: '12.5%',
    [theme.breakpoints.down('xl')]: {
        width: '16.66%'
    },
    [theme.breakpoints.down('md')]: {
        width: '25%'
    },
    [theme.breakpoints.down('sm')]: {
        width: '33.33%'
    },
    [theme.breakpoints.down('xs')]: {
        width: '50%'
    }
}))

export const ProductCard = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    position: 'relative',
    color: colors.textPrimary,
    border: `1px solid ${colors.lightGray}`,
    borderRadius: 5,
    transition: 'border .3s ease',
    '.card-image': {
        '> .MuiBox-root': {
            paddingTop: '82.18%',
            img: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                '&.no-prod-image': {
                    transform: 'scale(0.65)',
                    opacity: 0.5
                }
            }
        }
    },
    '.card-detail': {
        borderTop: `1px solid ${colors.lightGray}`,
        padding: '0.5rem 0.5rem 0.625rem'
    },
    '&:hover': {
        borderColor: colors.themeSecondColor
    },
    '&.active': {
        border: `5px solid ${colors.themeSecondColor}`,
        position: 'relative',
        '.MuiTypography-root': {
            color: colors.themeSecondColor
        }
    },
}))

export const ProductBarCode = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    position: 'relative',
    color: colors.textPrimary,
    border: `1px solid ${colors.lightGray}`,
    borderRadius: 5,
    transition: 'border .3s ease',
    '.card-image': {
        '> .MuiBox-root': {
            paddingTop: '82.18%',
            img: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                '&.no-prod-image': {
                    transform: 'scale(0.65)',
                    opacity: 0.5
                }
            }
        },

    },
    '&:hover': {
        borderColor: colors.themeSecondColor
    },
    '&.active': {
        border: `3px solid ${colors.themeSecondColor}`,
        position: 'relative',
        '.MuiTypography-root': {
            color: colors.themeSecondColor
        }
    },
}))

export const ProductQty = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
    height: 24,
    minWidth: 24,
    color: colors.white,
    backgroundColor: colors.themeSecondColor,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14
}))

export const InventoryChip = styled(Chip)<{ limit?: boolean }>(({ theme, limit }) => ({
    height: 18,
    backgroundColor: limit ? colors.themeThirdColor : colors.lightGray,
    border: limit ? `1px solid ${colors.themeThirdColor}` : `1px solid ${colors.lightGray}`,
    borderRadius: 0,
    span: {
        color: limit ? colors.white : colors.black60,
        fontSize: 10,
        padding: '0 4px'
    }
}))


