import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Grid, Box, Typography, Tabs, Tab, MenuItem, Divider } from '@mui/material'
import domtoimage from 'dom-to-image'
import moment from 'moment'
import $ from 'jquery'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import TreatmentsAPI from 'api/dentists/treatment.api'
import ClinicApi from 'api/master/clinic.api'
import { fileUrl } from 'api/api'
import RetrospectiveApi from 'api/counter/retrospective-order.api'

/** UTILS || SLICE */
import { showModal } from 'app/slice/modal.slice'
import { selectMe } from 'app/slice/user.slice'
import { treatment, TreatmentStateInterface, setEditTootChart, setTreatmentByKey, setToothChartSelected, setHistoryToothChart, setEditTootChartType } from 'app/slice/treatment.slice'
import { exportOPD, exportSSO } from 'component/Print/exportPdf'
import { getBranch, getClinicInfo, getServicePoint } from 'utils/app.utils'
/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs } from 'constants/images'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import SearchPatients, { SearchPatientValue } from 'component/SearchPatients'
import BtnProfile from 'component/Treatments/BtnProfile'
import PopupPatientDetail from 'component/Treatments/PopupPatientDetail'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import IndexSummaryTreatment from 'features/treatments/SummaryTreatment/Index'
import FragmentFooter from 'features/treatments/FragmentFooter'
import Loading from 'component/Loading'
import MedicalCertificate from 'component/Treatments/MedicalCertificate'
import IndexOPD from 'features/treatments/MainMenu/OPD/Index'
import IndexDoctorNote from 'features/treatments/MainMenu/DoctorNote/Index'
import IndexImage from 'features/treatments/MainMenu/Image/Index'
import IndexVitalSign from 'features/treatments/MainMenu/VitalSign/Index'
import PopupPayment from 'features/finance/payment/component/popupPayment/Index'
import { swalCustom, swalWarning } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { renderSumDiscount, renderSumAdditional, RawDiscountType, RawAdditionalType } from 'features/treatments/SummaryTreatment/SummaryTreatment'
import { TABS_NAME, SUB_TABS_NAME } from 'features/treatments/treatmentsIndex'
import { DoctorsType } from 'features/treatments/treatmentsIndex'

/** STYLE */
import * as UseStyled from 'features/retrospectiveOrder/UseStyle'
import * as TreatmentsUseStyled from 'features/treatments/UseStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { resetStatePayment } from 'app/slice/payment.slice'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

export default function RetrospectiveOrder() {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectMe)
  const branch = getClinicInfo()
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const footerTabs: string[] = ['TREATMENTS.FOOTER_TAB.SUMMARY_TREATMENT', 'TREATMENTS.FOOTER_TAB.DIAGNOSIS', 'TREATMENTS.FOOTER_TAB.RECOMMEND']
  const tabs: TABS_NAME[] = [TABS_NAME.OPD, TABS_NAME.DOCTOR_NOTE, TABS_NAME.IMAGE, TABS_NAME.VITAL_SIGN]
  const subTabs: SUB_TABS_NAME[] = [SUB_TABS_NAME.DX, SUB_TABS_NAME.TX, SUB_TABS_NAME.TXC, SUB_TABS_NAME.TXP, SUB_TABS_NAME.MED, SUB_TABS_NAME.PD]
  const [activeTab, setActiveTab] = useState<TABS_NAME>(TABS_NAME.OPD)
  const [activeSubTab, setActiveSubTab] = useState<SUB_TABS_NAME>(SUB_TABS_NAME.TX)
  const [activeFooterTab, setActiveFooterTab] = useState(0)

  const [errorMessage, setErrorMessage] = useState({
    EMPTY_DATE: ''
  })

  const [sendPayment, setSendPayment] = useState(false)

  const [anchorElTabletTabs, setAnchorElTabletTabs] = useState<null | HTMLElement>(null)
  const openTabletTabs = Boolean(anchorElTabletTabs)
  const [tabletWidth, setTabletWidth] = useState(0)

  const [anchorElMobileTabs, setAnchorElMobileTabs] = useState<null | HTMLElement>(null)
  const openMobileTabs = Boolean(anchorElMobileTabs)
  const [mobileWidth, setMobileWidth] = useState(0)

  const [doctors, setDoctors] = useState<DoctorsType[]>([])
  const [date, setDate] = useState('')
  const [netDiscount, setNetDiscount] = useState<number>(0)
  const [netAdditional, setNetAdditional] = useState<number>(0)
  const [retrospectiveOrder, setRetrospectiveOrder] = useState({})

  const [patientBranch, setPatientBranch] = useState<number | null>(null)
  const [patientId, setPatientId] = useState(0)
  const [patient, setPatient]: any = useState({})
  const [isShowPopupDetail, setIsShowPopupDetail] = useState<boolean>(false)

  const [exportMedicalCertificate, setExportMedicalCertificate] = useState(false)

  const [loading, setLoading] = useState(false)

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) setDoctors(resp.data)
  }

  const loadPatient = async (valuePatientId: number) => {
    if (valuePatientId) {
      const patientData = await TreatmentsAPI.findPatient(valuePatientId)
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
        setPatient(patientData.data)
      } else setPatient([])
    }
  }

  const getPatient = (val: SearchPatientValue) => {
    if (val?.patientId) {
      setPatientId(val.patientId)
      loadPatient(val.patientId)
      setPatientBranch(val?.branchId)
    }
  }

  const getPatientId = (id: number) => {
    setPatientId(id)
    loadPatient(id)
  }

  const clearPatient = () => {
    setPatientId(0)
    setPatient({})
    clearOperative()
  }

  const clearOperative = () => {
    setDate('')
    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [] }))
    dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: [] }))
    dispatch(setTreatmentByKey({ key: 'patientRecommends', value: [] }))
    dispatch(setEditTootChart(null))
    dispatch(setEditTootChartType(''))
    dispatch(setToothChartSelected([]))
    dispatch(setHistoryToothChart([]))
    setErrorMessage({ EMPTY_DATE: '' })
  }

  const onViewHistory = () => {
    dispatch(showModal())
    setIsShowPopupDetail(true)
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(tabs[newValue])
    dispatch(setEditTootChart(null))
    dispatch(setEditTootChartType(''))
  }

  const handleOpenDropDown = () => {
    const dropdownTabletWidth = Array.from(document.getElementsByClassName('btn-dropdown-menu-tablet-tab') as HTMLCollectionOf<HTMLElement>)
    const dropdownMobileWidth = Array.from(document.getElementsByClassName('btn-dropdown-menu-mobile-tab') as HTMLCollectionOf<HTMLElement>)
    setTabletWidth(Number(dropdownTabletWidth[0]?.offsetWidth))
    setMobileWidth(Number(dropdownMobileWidth[0]?.offsetWidth))
  }

  const onCloseMenu = () => {
    setAnchorElMobileTabs(null)
    setAnchorElTabletTabs(null)
  }

  useEffect(() => {
    if (treatments.summeryTreatments.length > 0) {
      const index = _.findLastIndex(treatments.summeryTreatments, (item: any) => item.teethType)
      if (treatments.summeryTreatments[index]) {
        dispatch(setTreatmentByKey({ key: 'teethType', value: treatments.summeryTreatments[index].teethType }))
      }
    }
  }, [treatments.summeryTreatments])

  const onHandleSaveToothChart = async () => {
    const toothChartFile = {
      fileId: null,
      base64: '',
      type: '',
      fileSize: '',
      fileExtension: 'png'
    }
    let node: any = ''
    if (treatments.teethType === 'ADULT') {
      node = document.getElementById('tooth-chart')
    } else {
      node = document.getElementById('tooth-chart-child')
    }
    if (node) {
      let base64 = await domtoimage.toPng(node)
      if (base64) {
        const re = 'data:image/png;base64,'
        base64 = base64.replace(re, "'")
        toothChartFile.base64 = base64
      }
    }

    return toothChartFile
  }

  const onPayment = async () => {
    setLoading(true)
    const branchCurrent = getBranch()
    if (!patientBranch || patientBranch !== branchCurrent) {
      setLoading(false)
      const branchInfo = getClinicInfo()
      const lang = i18n.language !== 'th' ? "En" : ""
      const handleCallbackNoti = (check: any) => {
        clearPatient()
        dispatch(resetStatePayment())
        return
      }
      const opSwal = {
        cancelButton: {
          showCancelButton: false
        }
      }
      swalCustom(
        t(`RETROSPECTIVE_ORDER.MESSAGE.REGISTERPATIENT`, { branchName: branchInfo?.[`branchName${lang}`] }),
        t(`RETROSPECTIVE_ORDER.MESSAGE.REGISTERPATIENT_SUB`, { branchName: branchInfo?.[`branchName${lang}`] }),
        handleCallbackNoti,
        null,
        opSwal
      )
      return
    }

    if (!date) {
      setLoading(false)
      $('.div-component').delay(500).animate({ scrollTop: 0 }, 500)
      return setErrorMessage({ ...errorMessage, EMPTY_DATE: t('RETROSPECTIVE_ORDER.MESSAGE.ERROR.EMPTY_DATE') })
    }
    if (!treatments.summeryTreatments.length) {
      setLoading(false)
      return swalWarning(t('TREATMENT_SUMMARY.MESSAGE.PLS_SELECT_TREATMENT'), '', null)
    }

    const retrospective: any = {
      date: date,
      teethType: treatments?.teethType || 'ADULT',
      servicePointId: Number(getServicePoint())
    }
    const summeryTreatments: any = []
    const summeryProducts: any = []
    const summeryDiagnosis: any = []
    const summeryRecommends: any = []

    retrospective.toothChart = await onHandleSaveToothChart()

    _.map(treatments.summeryTreatments, (i: any, index: number) => {
      if (i.operativeTypeId) {
        summeryTreatments.push({
          index: index + 1,
          teeth: i?.teeth || null,
          teethJson: i?.teethJson || null,
          operativeId: i.operativeId || 0,
          qty: Number(i.qty) || 0,
          price: Number(i.price) || 0,
          doctorId: _.some(doctors, { userId: i.doctorId }) ? i.doctorId : 0,
          discount: Number(i.discount) || 0,
          discountUnit: i.discountUnit || '',
          additional: Number(i.additional) || 0,
          additionalUnit: i.additionalUnit,
          dfAmount: i.dfAmount || i.cfAmount ? Number(i.dfAmount) : null,
          dfUnit: i.dfUnit || '',
          dfDiscountAmount: Number(i.dfDiscountAmount) || 0,
          dfDiscountUnit: i.dfDiscountUnit || '',
          dfAmountNet: Number(i.dfAmountNet) || 0,
          cfAmount: i.dfAmount || i.cfAmount ? Number(i.cfAmount) : null,
          cfDiscountAmount: Number(i.cfDiscountAmount) || 0,
          cfDiscountUnit: i.cfDiscountUnit || '',
          cfAmountNet: Number(i.cfAmountNet) || 0,
          dfRemark: i.dfRemark || '',
          clinicFeeId: Number(i.clinicFeeId) || null,
          total: Number(i.total) || 0,
          note: i.note || '',
          noteNextVisit: i.noteNextVisit || '',
          refType: i.refType || null,
          refId: i.refId || 0,
          nextVisit: i.nextVisit || null
        })
      }

      if (!i.operativeTypeId) {
        summeryProducts.push({
          index: index + 1,
          inventoryId: i.inventoryId,
          group: i.group,
          qty: Number(i.qty),
          price: Number(i.price),
          doctorId: _.some(doctors, { userId: i.doctorId }) ? i.doctorId : 0,
          discount: Number(i.discount) || 0,
          discountUnit: i.discountUnit || 'BAHT',
          additional: Number(i.additional) || 0,
          additionalUnit: i.additionalUnit || 'BAHT',
          note: i.note || '',
          refType: i.refType || null,
          refId: i.refId || 0,
          total: Number(i.total) || 0,
          sticker: i.sticker
        })
      }
    })

    _.map(treatments.patientDiagnosis, (i: any, index: number) => {
      summeryDiagnosis.push({
        patientDiagnosisId: i.patientDiagnosisId || null,
        diagnosisId: Number(i.diagnosisId) || 0,
        doctorId: _.some(doctors, { userId: Number(i.doctorId) }) ? Number(i.doctorId) : 0,
        note: i.note || '',
        teeth: i?.teeth || null,
        teethJson: i?.teethJson || null,
        teethType: i?.teethType || treatments?.teethType || 'ADULT',
      })
    })

    _.map(treatments.patientRecommends, (i: any, index: number) => {
      summeryRecommends.push({
        patientRecommendId: i.patientRecommendId || null,
        recommendId: i.recommendId
      })
    })

    if (_.some(summeryTreatments, { qty: 0 }) || _.some(summeryProducts, { qty: 0 })) {
      setLoading(false)
      return notiError(t(`TREATMENT_SUMMARY.MESSAGE.${_.some(summeryTreatments, { qty: 0 }) ? 'NO_OPERATIVE_QTY' : 'NO_PRODUCT_QTY'}`), '', null, null, 'register-page')
    }

    if (_.some(summeryTreatments, { doctorId: 0 })) {
      setLoading(false)
      return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_TREATMENT'), '', null, null, 'register-page')
    }

    // if (_.some(summeryProducts, { group: 'MEDICINE', doctorId: 0 })) {
    //   setLoading(false)
    //   return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_MEDICINE'), '', null, null, 'register-page')
    // }

    retrospective.summeryTreatments = summeryTreatments
    retrospective.summeryProducts = summeryProducts
    retrospective.patientDiagnosis = []
    retrospective.patientRecommends = summeryRecommends

    setRetrospectiveOrder(retrospective)
    setLoading(false)
    setSendPayment(true)
  }

  const handlePayment = async (val: any) => {
    const retrospective: any = retrospectiveOrder
    retrospective.payment = val

    setLoading(true)
    await RetrospectiveApi.payment(patientId, retrospective)
      .then((resp) => {
        if (resp.status === 201) {
          setSendPayment(false)
          notiSuccess(t('PAYMENT.MESSAGE.SUCCESS.PAY_SUCCESS'), 'reload', null, null, 'register-page')
          if (resp.data) window.open(`${fileUrl}/${resp.data}`, '_blank')
          clearPatient()
          dispatch(resetStatePayment())
        } else {
          notiError(t('RETROSPECTIVE_ORDER.MESSAGE.ERROR.DEFAULT'), '', null, null, 'register-page')
        }
      })
      .catch(() => {
        notiError(t('RETROSPECTIVE_ORDER.MESSAGE.ERROR.DEFAULT'), '', null, null, 'register-page')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const rawDiscount: number[] = []
    const rawAdditional: number[] = []
    _.map(treatments.summeryTreatments, (i: any, index: number) => {
      const additional: RawAdditionalType = {
        price: i.price,
        qty: i.qty,
        additional: Number(i.additional || 0),
        additionalUnit: i.additionalUnit
      }
      const discount: RawDiscountType = {
        price: i.price,
        qty: i.qty,
        discount: Number(i.discount || 0),
        discountUnit: i.discountUnit,
        dfUnit: i.dfUnit || 0,
        dfAmount: Number(i.dfAmount || 0),
        dfDiscountAmount: Number(i.dfDiscountAmount || 0),
        dfDiscountUnit: i.dfDiscountUnit || '',
        cfAmount: Number(i.cfAmount) || 0,
        cfDiscountAmount: Number(i.cfDiscountAmount || 0),
        cfDiscountUnit: i.cfDiscountUnit || '',
        additionalNet: renderSumAdditional(additional),
        doctorFeeAmount: i.doctorFeeAmount || 0,
        clinicFeeAmount: i.clinicFeeAmount || 0
      }
      rawDiscount.push(renderSumDiscount(discount))
      rawAdditional.push(renderSumAdditional(additional))
    })
    setNetDiscount(_.sum(rawDiscount))
    setNetAdditional(_.sum(rawAdditional))
  }, [treatments.summeryTreatments])

  useEffect(() => {
    clearOperative()
  }, [patient.patientId])

  useEffect(() => {
    loadDoctor()
  }, [])

  const exportOpd = async (valuePatientId: number) => {
    setLoading(true)
    exportOPD(valuePatientId)
      .catch((e) => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handlePatientCode = () => {
    if (branch?.patientCodeQueue === '1') {
      return patient.cnNumber ?? ''
    }
    if (branch?.patientCodeQueue === '0') {
      return patient.branchCnNumber ?? ''
    }

    if (branch?.patientCodeQueue === '2') {
      return patient.snCode ?? ''
    }
  }

  return (
    <div>
      <HeaderCard text={t('RETROSPECTIVE_ORDER.HEADER')} />
      <UseStyled.RetrospectiveOrder>
        <Box className={`px-3 px-md-4 pt-3 pt-md-4 pb-4 ${activeTab === TABS_NAME.IMAGE ? 'h-100' : ''}`}>
          <Box sx={{ paddingBottom: '56px' }}>
            <Grid container spacing={2} className="mb-3">
              <Grid item className="w-100">
                <Box sx={{ maxWidth: { sm: '650px' } }}>
                  <SearchPatients
                    status="ACTIVE"
                    // callbackPatientId={getPatientId} 
                    callbackPatient={getPatient}
                    patientId={patient.patientId}
                    patient={patient}
                    onClear={clearPatient}
                  />
                </Box>
              </Grid>
              <Grid item className="w-100">
                <TreatmentsUseStyled.CardProfile className={`d-flex px-3 ${!patient.patientId ? 'pe-none' : ''}`} onClick={onViewHistory}>
                  <BtnProfile disabled={!patient.patientId} profile={renderImage(patient.profile)} branchCnNumber={handlePatientCode() || ''} firstName={patient.firstname} lastName={patient.lastname} rankId={patient.rankId} lastVisit={patient.updatedAt} />
                </TreatmentsUseStyled.CardProfile>
              </Grid>
              <Grid item className={`w-100 ${activeTab !== TABS_NAME.OPD ? 'd-none' : ''}`}>
                <UseStyled.InputDate className="w-100">
                  <InputSecondNewDatePicker
                    required
                    disableFuture
                    hideTodayButton
                    label={t('RETROSPECTIVE_ORDER.FORM.DATE')}
                    disabled={!patient.patientId}
                    value={date}
                    onchange={(e: any) => {
                      setDate(moment(e).format('YYYY-MM-DD'))
                      setErrorMessage({ EMPTY_DATE: '' })
                    }}
                    helperText={errorMessage.EMPTY_DATE || ''}
                  />
                </UseStyled.InputDate>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item className="w-100">
                <TreatmentsUseStyled.CustomTabs disabled={!patient.patientId}>
                  <Tabs value={activeTab} onChange={handleChangeTab} aria-label="payment tab" className="d-none d-md-block">
                    {_.map(tabs, (title: string, index: number) => {
                      return (
                        (index === 0 && (
                          <>
                            <TreatmentsUseStyled.CustomButton
                              key={`tabChild-${index}`}
                              aria-haspopup="true"
                              aria-expanded={openTabletTabs ? 'true' : undefined}
                              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                handleChangeTab(event, 0)
                                setAnchorElTabletTabs(event.currentTarget)
                                handleOpenDropDown()
                              }}
                              className={`btn-tab btn-dropdown-menu-tablet-tab ${activeTab === TABS_NAME.OPD ? 'Mui-selected' : ''}`}
                              disableRipple
                              disabled={!patient.patientId}
                              {...a11yProps(index)}
                            >
                              <span>{t(title)}</span>
                              <span className={'d-xl-none pl-1'}>({activeSubTab})</span>
                              <FontAwesomeIcon icon={faCaretDown} className={'icon-selector d-xl-none'} />
                            </TreatmentsUseStyled.CustomButton>
                            <TreatmentsUseStyled.MenuBtnMore
                              id="basic-menu-more"
                              anchorEl={anchorElTabletTabs}
                              open={openTabletTabs}
                              onClose={onCloseMenu}
                              onClick={onCloseMenu}
                              className="d-xl-none"
                              sx={{
                                '.MuiMenu-list': {
                                  width: tabletWidth,
                                  maxHeight: '270px'
                                }
                              }}
                            >
                              {_.map(subTabs, (subTab: SUB_TABS_NAME, indexSubTab: number) => {
                                return (
                                  <MenuItem
                                    key={indexSubTab}
                                    className={'d-block text-ellipsis'}
                                    onClick={() => {
                                      setActiveTab(tabs[0])
                                      setActiveSubTab(subTab)
                                      if (indexSubTab === 0) setActiveFooterTab(1)
                                      else setActiveFooterTab(0)
                                    }}
                                  >
                                    {subTab}
                                  </MenuItem>
                                )
                              })}
                            </TreatmentsUseStyled.MenuBtnMore>
                          </>
                        )) || <Tab key={`tab-${index}`} label={t(title)} {...a11yProps(index)} className={`btn-tab ${activeTab === title ? 'Mui-selected' : ''}`} disableRipple />
                      )
                    })}
                  </Tabs>
                </TreatmentsUseStyled.CustomTabs>

                <TreatmentsUseStyled.CustomTabs disabled={!patient.patientId} className="d-md-none">
                  <TreatmentsUseStyled.CustomButton
                    id="mobile-tabs"
                    aria-controls={openMobileTabs ? 'basic-menu-more' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMobileTabs ? 'true' : undefined}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setAnchorElMobileTabs(event.currentTarget)
                      handleOpenDropDown()
                    }}
                    className={'btn-tab btn-dropdown-menu-mobile-tab'}
                    disableRipple
                  >
                    {activeTab === TABS_NAME.OPD ? `OPD (${activeSubTab})` : t(activeTab)}
                    <FontAwesomeIcon icon={faCaretDown} className={'icon-selector'} />
                  </TreatmentsUseStyled.CustomButton>

                  <TreatmentsUseStyled.MenuBtnMore
                    id="basic-menu-more"
                    anchorEl={anchorElMobileTabs}
                    open={openMobileTabs}
                    onClose={onCloseMenu}
                    onClick={onCloseMenu}
                    className="d-xl-none"
                    sx={{
                      '.MuiMenu-list': {
                        width: mobileWidth,
                        maxHeight: '270px'
                      }
                    }}
                  >
                    <Typography className="pb-1 px-3" sx={{ fontWeight: 600 }}>
                      {t(tabs[0])}
                    </Typography>
                    {_.map(subTabs, (subTab: SUB_TABS_NAME, indexSubTab: number) => {
                      return (
                        <MenuItem
                          key={indexSubTab}
                          className={'d-block text-ellipsis'}
                          onClick={() => {
                            setActiveTab(tabs[0])
                            setActiveSubTab(subTab)
                            if (indexSubTab === 0) setActiveFooterTab(1)
                            else setActiveFooterTab(0)
                          }}
                        >
                          {subTab}
                        </MenuItem>
                      )
                    })}
                    <Divider className="mx-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                    {_.map(tabs, (title: string, indexTitle: number) => {
                      if (indexTitle > 0)
                        return (
                          <MenuItem key={indexTitle} className={'d-block text-ellipsis'} onClick={() => setActiveTab(tabs[indexTitle])}>
                            {t(title)}
                          </MenuItem>
                        )
                    })}
                  </TreatmentsUseStyled.MenuBtnMore>
                </TreatmentsUseStyled.CustomTabs>
              </Grid>
              <Grid item className={`w-100 ${activeTab === TABS_NAME.OPD ? 'mt-md-3' : 'mt-3'} ${!patient.patientId ? 'pe-none' : ''}`}>
                <TabPanel value={activeTab} index={TABS_NAME.OPD}>
                  <IndexOPD
                    type="RetrospectiveOrder"
                    userId={user.userId}
                    patient={patient}
                    tabs={subTabs}
                    activeTab={activeSubTab}
                    setActiveTab={(tab: SUB_TABS_NAME) => setActiveSubTab(tab)}
                    setFooterActiveTab={(tab: number) => setActiveFooterTab(tab)}
                    disabledMenu={!patient.patientId}
                    hasChangeProductPrice={user?.hasChangePrice}
                    hasChangeProductDiscountAdditional={user?.hasChangeDiscountAdditional}
                    hasCreateTreatment={user?.hasCreateTreatment || 'PERMISS'}
                    hasCreateTreatmentPlan={user?.hasCreateTreatmentPlan || 'PERMISS'}
                    hasCreateTreatmentCourse={user?.hasCreateTreatmentCourse || 'PERMISS'}
                    medShowType={branch?.medShowType}
                    pdShowType={branch?.pdShowType}
                  />
                </TabPanel>
                <TabPanel value={activeTab} index={TABS_NAME.DOCTOR_NOTE}>
                  <IndexDoctorNote userId={user.userId} patient={patient} hasDoctorNote={user?.hasDoctorNote} hasCreateDoctorNote={user?.hasCreateDoctorNote || 'PERMISS'} hasDeleteDoctorNote={user?.hasDeleteDoctorNote} />
                </TabPanel>
                <TabPanel value={activeTab} index={TABS_NAME.IMAGE}>
                  <IndexImage patient={patient} />
                </TabPanel>
                <TabPanel value={activeTab} index={TABS_NAME.VITAL_SIGN}>
                  <IndexVitalSign patient={patient} loadPatient={(val: any) => loadPatient(val)} />
                </TabPanel>
              </Grid>
            </Grid>

            {activeTab !== TABS_NAME.VITAL_SIGN && (
              <Box className="w-100">
                <IndexSummaryTreatment
                  userId={user.userId}
                  type="RetrospectiveOrder"
                  tabs={footerTabs}
                  activeTab={activeFooterTab}
                  setActiveTab={(tab: number) => {
                    setActiveFooterTab(tab)
                    if (tab === 0) setActiveSubTab(SUB_TABS_NAME.TX)
                    else if (tab === 1) setActiveSubTab(SUB_TABS_NAME.DX)
                  }}
                  patientCaseId={patient.patientCase?.patientCaseId}
                  patientId={patient.patientId}
                  patientName={`${patient.firstname} ${patient.lastname}`}
                  doctors={doctors}
                  hasTreatment={user?.hasTreatment}
                  hasChangeTreatmentCost={user?.hasChangeTreatmentCost}
                  hasChangeTreatmentNote={user?.hasChangeTreatmentNote}
                  hasChangeProductPrice={user?.hasChangePrice}
                  hasChangeProductDiscountAdditional={user?.hasChangeDiscountAdditional}
                  hasDiagnosis={user?.hasDiagnosis}
                  hasDeleteTreatment={user?.hasDeleteTreatment || 'PERMISS'}
                  patientPhone={patient.phone}
                />
              </Box>
            )}
          </Box>

          {activeTab !== TABS_NAME.VITAL_SIGN && (
            <FragmentFooter
              type="RetrospectiveOrder"
              onPayment={() => onPayment()}
              netTotal={_.sumBy(treatments.summeryTreatments, 'total')}
              netDiscount={netDiscount}
              netAdditional={netAdditional}
              onExportSSO={exportSSO}
              onExportOPD={() => exportOpd(patient.patientId)}
              disabled={!patient.patientId}
              tabActive={activeSubTab}
              patientName={`${patient.firstname} ${patient.lastname}`}
              showButtonPrintMedicineSticker={false}
              patientCaseId={patient?.patientCase?.patientCaseId}
            />
          )}

          {exportMedicalCertificate && (
            <MedicalCertificate
              isShow={exportMedicalCertificate}
              setIsShow={(show: boolean) => setExportMedicalCertificate(show)}
              patientId={patient.patientId}
              patientCaseId={patient.patientCase.patientCaseId}
              createdAt={patient.createdAt}
              type={'treatment'}
            />
          )}

          {loading && <Loading show={true} type="fullLoading" />}
          {sendPayment && <PopupPayment type="RETROSPECTIVE" isShow={sendPayment} setIsShow={(show: boolean) => setSendPayment(show)} patient={patient} onSuccess={handlePayment} date={date} />}
          <PopupPatientDetail type="retrospective" profile={renderImage(patient.profile)} patient={patient} showPopup={isShowPopupDetail} onClose={() => setIsShowPopupDetail(false)} />
        </Box>
      </UseStyled.RetrospectiveOrder>
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `payment-tab-${index}`,
    'aria-controls': `payment-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tab" hidden={value !== index} id={`payment-tab-${index}`} aria-labelledby={`payment-tabpanel-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
