import { Box, Tooltip } from '@mui/material'
import PromotionsApi from 'api/promotion/promotion.api'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBranch } from 'utils/app.utils'
import { dateToView } from 'utils/date.utils'
import InfoIcon from '@mui/icons-material/Info';
import Loading from 'component/Loading'
import TextInput from 'new-components/inputs/text-input'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { ListFilterDoctors } from '../appointment/head-appointment'
import InputCheckbox from 'component/Input/InputCheckbox'
import _ from 'lodash'
import MainButton from 'new-components/buttons/main-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { colors } from 'constants/theme'

type FormViewPromotionProps = {
    onClose: () => void
    patientId?: number

}

const FormViewPromotion = (props: FormViewPromotionProps) => {
    const { t } = useTranslation()
    const [loadSelectItemFrist, setLoadSelectItemFrist] = useState<boolean>(true)
    const [loadFristSelect, setLoadFristSelect] = useState<boolean>(true)
    const [loadFrist, setLoadFrist] = useState<boolean>(true)

    const [loadPatient, setLoadPatient] = useState<boolean>(false)
    const [dataPatient, setDataPatient] = useState<any>([])
    const [pagePatient, setPagePatient] = useState(1)
    const [pageLimitPatient, setPageLimitPatient] = useState(10)
    const [rowCountPatient, setRowCountPatient] = useState(0)
    const [sortByPatient, setSortByPatient] = useState('promotionId')
    const [sortTypePatient, setSortTypePatient] = useState('ASC')
    const [searchPatient, setSearchPatient] = useState('')

    const [loadCurrent, setLoadCurrent] = useState<boolean>(false)
    const [dataCurrent, setDataCurrent] = useState<any>([])
    const [pageCurrent, setPageCurrent] = useState(1)
    const [pageLimitCurrent, setPageLimitCurrent] = useState(10)
    const [rowCountCurrent, setRowCountCurrent] = useState(0)
    const [sortByCurrent, setSortByCurrent] = useState('promotionId')
    const [sortTypeCurrent, setSortTypeCurrent] = useState('DESC')
    const [searchCurrent, setSearchCurrent] = useState('')

    const [typeSelect, setTypeSelect] = useState<string[]>([])
    const promotionFormatOption: { name: string, value: string }[] = [{ name: t('PROMOTION.PROMOTION_TYPE.DISCOUNT'), value: 'DISCOUNT' }, { name: t('PROMOTION.PROMOTION_TYPE.EXTRA'), value: 'EXTRA' }]

    const [itemTypeSelect, setItemTypeSelect] = useState<string[]>([])
    const itemTypeOption: { name: string, value: string }[] = [
        { name: t('PROMOTION.FORM.NOITEM_LIST'), value: 'NOITEM' },
        { name: t('PROMOTION.FORM.OPERATIVE_LIST'), value: 'OPERATIVE' },
        { name: t('PROMOTION.FORM.MEDICINE_LIST'), value: 'MEDICINE' },
        { name: t('PROMOTION.FORM.PRODUCT_LIST'), value: 'PRODUCT' },
    ]

    const [itemSelect, setItemSelect] = useState<string[]>([])
    const [itemOption, setItemOption] = useState<any>([])
    const [isLoadItemOption, setIsLoadItemOption] = useState<boolean>(false)

    const loadPatientPromotion = useCallback(async (id: number) => {
        const condition = {
            page: pagePatient,
            pageLimit: pageLimitPatient,
        }

        await PromotionsApi.findItemByPatient(id, condition).then((res) => {
            setRowCountPatient(res?.headers['x-total'] || 0)
            setDataPatient(res?.data)
        }).finally(() => {
            setLoadPatient(false)
        })
    }, [pagePatient, pageLimitPatient])

    useEffect(() => {
        if (props?.patientId) {
            loadPatientPromotion(props?.patientId)
        }
    }, [props?.patientId, loadPatientPromotion])

    const loadItemList = async (search?: string, change?: boolean) => {
        setIsLoadItemOption(true)
        if (loadFristSelect) {
            setLoadFristSelect(false)
            return
        }

        const condition = {
            SEARCH: search || '',
            page: 1,
            pageLimit: 30,
            promotionType: typeSelect.join(','),
            itemType: itemTypeSelect.join(','),
            // item
        }
        const branchId = getBranch()
        await PromotionsApi.findItemUsagePromotion(branchId, condition).then((res) => {
            if (res?.data?.length) {
                const newOption = res?.data.map((item: any) => { return { name: item?.itemName, value: item?.itemName } })
                const newDataSelect = newOption.map((item: any) => { return item?.value })
                setItemOption(newOption)
                if (loadSelectItemFrist) {
                    setItemSelect(newDataSelect)
                } else {
                    if (change) {
                        setItemSelect([])
                    }
                }
            } else {
                setItemOption([])
                setItemSelect([])
            }
            setLoadSelectItemFrist(false)
        }).finally(() => setIsLoadItemOption(false))
    }

    useEffect(() => {
        // loadItemList()
        setTypeSelect(promotionFormatOption.map((item) => item.value))
        setItemTypeSelect(itemTypeOption.map((item) => item.value))
    }, [])

    useEffect(() => {
        loadItemList('', true)
    }, [itemTypeSelect, typeSelect, loadFristSelect])

    const onRequestSortPatient = (sortByVal: string, sortTypeVal: string) => {
        setSortTypePatient(sortTypeVal)
        setSortByPatient(sortByVal)
    }

    const handleChangePagePatient = (val: number) => setPagePatient(val)

    const handleChangeRowsPerPagePatient = (limit: number) => setPageLimitPatient(limit)

    const headCellsPatient: any[] = [
        { id: 'no', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.NUMBER'), width: '50px', align: 'center', },
        { id: 'promotionName', disablePadding: false, label: t('PROMOTION.TABLE.PROMOTION_NAME'), align: 'left', class: 'text-nowrap col-pro-name' },
        { id: 'condition', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.CONDITION_PATIENT'), align: 'left', class: 'text-nowrap' },
        { id: 'promotionFormat', disablePadding: false, label: t('PROMOTION.TABLE.TYPE'), align: 'center', class: 'text-nowrap' },
        { id: 'bindItemType', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.BIND_LISTTYPE'), align: 'left', class: 'text-nowrap' },
        { id: 'bindItem', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.BIND_LIST'), align: 'left', class: 'text-nowrap' },
        { id: 'dateStart', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.DATEEND'), align: 'center', class: 'text-nowrap' },
        { id: 'count', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.COUNTBRANCH'), align: 'center', class: 'text-nowrap' },
    ]

    const loadDataPromotionBranch = async () => {
        setLoadCurrent(true)
        if (loadFrist) {
            setLoadFrist(false)
            return
        }

        const condition = {
            SEARCH: searchCurrent,
            page: pageCurrent,
            pageLimit: pageLimitCurrent,
            sortBy: sortByCurrent,
            sortType: sortTypeCurrent,
            promotionType: typeSelect.join(','),
            // itemType: itemTypeSelect?.length !== itemTypeOption?.length ? itemTypeSelect.join(',') : '',
            itemType: itemTypeSelect.join(','),
            item: itemSelect.join(','),
        }
        const branchId = getBranch()
        await PromotionsApi.findPromotionByBranch(branchId, condition).then((res) => {
            setRowCountCurrent(res?.headers['x-total'] || 0)
            setDataCurrent(res?.data)
        }).finally(() => {
            setLoadCurrent(false)
        })
    }

    useEffect(() => {
        loadDataPromotionBranch()
    }, [pageCurrent, pageLimitCurrent, sortByCurrent, sortTypeCurrent, loadFrist])

    const onRequestSortCurrent = (sortByVal: string, sortTypeVal: string) => {
        setSortTypeCurrent(sortTypeVal)
        setSortByCurrent(sortByVal)
    }

    const handleChangePageCurrent = (val: number) => setPageCurrent(val)

    const handleChangeRowsPerPageCurrent = (limit: number) => setPageLimitCurrent(limit)

    const headCellsCurrent: any[] = [
        { id: 'no', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.NUMBER'), width: '50px', align: 'center', class: ' col-pro-no' },
        { id: 'promotionName', disablePadding: false, label: t('PROMOTION.TABLE.PROMOTION_NAME'), align: 'left', class: 'text-nowrap col-pro-name' },
        // { id: 'condition', disablePadding: false, label: t('เงื่อนไขผู้ป่วย'), align: 'left', class: 'text-nowrap' },
        { id: 'promotionFormat', disablePadding: false, label: t('PROMOTION.TABLE.TYPE'), align: 'center', class: 'text-nowrap' },
        { id: 'bindItemType', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.BIND_LISTTYPE'), align: 'left', class: 'text-nowrap' },
        { id: 'bindItem', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.BIND_LIST'), align: 'left', class: 'text-nowrap' },
        { id: 'dateStart', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.DATEEND'), align: 'center', class: 'text-nowrap' },
        { id: 'count', disablePadding: false, label: t('PROMOTION.REGISTER.TABLE.COUNTBRANCH'), align: 'center', class: 'text-nowrap' },
    ]

    const renderData = (objData: any, index: number, patient?: boolean) => {
        index = patient ? pagePatient * pageLimitPatient - pageLimitPatient + index + 1 : pageCurrent * pageLimitCurrent - pageLimitCurrent + index + 1
        const { promotionId, promotionName, promotionNameEn, type, endDate, itemTypeName, itemName, total, detail, groupItem } = objData

        const newItemTypeNameSplit = itemTypeName?.split(',')
        const newItemTypeName = newItemTypeNameSplit?.length > 2 ? `${newItemTypeNameSplit[0]}, ${newItemTypeNameSplit[1]}, ...` : itemTypeName

        const newItemNameSplit = itemName?.split(',')
        const newItemName = newItemNameSplit?.length > 2 ? `${newItemNameSplit[0]}, ${newItemNameSplit[1]}, ...` : itemName


        const renderColorTotal = () => {
            const [totalUse, totalSum] = total?.split("/")
            if (totalSum === '-' || Number(totalUse) !== Number(totalSum)) {
                return colors.black
            } else {
                return colors.statusInactive
            }
        }

        const commonColumns = [
            { option: 'TEXT', align: 'center', label: index },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box>
                        {promotionName}
                        {detail &&
                            <span>
                                <Tooltip title={detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                    <InfoIcon />
                                </Tooltip>
                            </span>
                        }
                    </Box>
                )
            },
            { option: 'TEXT', align: 'center', class: 'text-nowrap', label: type ? t(`PROMOTION.TYPE.${type}`) : '' },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box>
                        {newItemTypeNameSplit?.length > 2 ?
                            <Tooltip title={itemTypeName} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                <Box>
                                    {newItemTypeName}
                                </Box>
                            </Tooltip>

                            : newItemTypeName || '-'
                        }
                    </Box>
                )
            },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box>
                        {newItemNameSplit?.length > 2 ?
                            <Tooltip title={itemName} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                <Box>
                                    {newItemName}
                                </Box>
                            </Tooltip>
                            :
                            newItemName || '-'
                        }
                    </Box>
                )
            },
            { option: 'TEXT', align: 'left', class: 'text-nowrap', label: dateToView(moment(endDate)) },
            { option: 'TEXT', align: 'center', label: total || '', style: { color: renderColorTotal() } },
        ]
        const separatedColumn = [
            { option: 'TEXT', align: 'left', class: 'text-nowrap', label: groupItem },
        ];
        const newColumns = patient ? [commonColumns[0], commonColumns[1], separatedColumn[0], ...commonColumns.slice(2)] : commonColumns;

        const objRenderData = {
            key: promotionId,
            id: promotionId,
            obj: objData,
            columns: newColumns
        }

        return <TableRowCommon {...objRenderData} />
    }

    const handleChangeFilter = (option: any, select: string[], setSelect: (data: string[]) => void, selectOption: { name: string, value: string }[]) => {
        if (option !== 'ALL') {
            if (!select?.length) {
                setSelect([option?.value])
                return
            }
            if (!select.includes(option.value)) {
                setSelect([...select, option?.value])
            } else {
                setSelect(select.filter((item: any) => item !== option.value))
            }
        } else {
            if (select?.length === selectOption?.length) {
                setSelect([])
            } else {
                setSelect(selectOption.map((item) => { return item?.value }))
            }
        }
    }

    return (
        <>
            <Loading show={loadCurrent || loadPatient} />
            <ModalCustom
                title={t('PROMOTION.REGISTER.BUTTON_VIEW')}
                modalStyle={`modal-pop-view-promotion`}
                onClose={props.onClose}
                onReset={props.onClose}
                alignFooter={'end'}
                textBtnCancel={t('BUTTON.CANCEL')}
                closeButton
                component={(
                    <Box>
                        {!!props?.patientId &&
                            <Box>
                                <Box marginBottom={'16px'}>
                                    {t('PROMOTION.REGISTER.VIEW_PATIENT')}
                                </Box>
                                <Box sx={{ 'tbody': { 'td': { textAlign: dataPatient?.length ? '' : 'center' } } }}>
                                    <TableCustom
                                        page={pagePatient}
                                        pageLimit={pageLimitPatient}
                                        sortType={sortTypePatient}
                                        sortBy={sortByPatient}
                                        onSort={onRequestSortPatient}
                                        setPageLimit={handleChangeRowsPerPagePatient}
                                        setPage={handleChangePagePatient}
                                        rowCount={rowCountPatient}
                                        headCells={headCellsPatient}
                                        rowsData={dataPatient?.length ? dataPatient.map((item: any, index: number) => {
                                            return renderData(item, index, true)
                                        }) : []}
                                    />
                                </Box>
                            </Box>
                        }
                        <Box>
                            <Box>
                                {t('PROMOTION.REGISTER.VIEW_CURRENT')}
                            </Box>
                            <Box>
                                <Box display={'flex'} flexWrap={'wrap'} width={{ md: 'calc(100% + 12px)', xs: '100%' }} margin={{ md: '16px -6px', xs: '16px 0' }} gap={{ md: '0', xs: '8px' }}>
                                    <Box
                                        width={{ md: 'calc(100% / 5)', xs: '100%' }}
                                        padding={{ md: '0px 6px', xs: '0' }}
                                        sx={{ '& .MuiOutlinedInput-root': { '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C5C5C5 !important', }, }, }}
                                    >
                                        <TextInput
                                            onChange={(event) =>
                                                setSearchCurrent(event.target.value)}
                                            fullWidth
                                            value={searchCurrent}
                                            placeholder={t('PROMOTION.REGISTER.SEARCH')}
                                            inputProps={{ endAdornment: <FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} /> }}
                                        />

                                    </Box>
                                    <Box width={{ md: 'calc(100% / 5)', xs: '100%' }} padding={{ md: '0px 6px', xs: '0' }}>
                                        <MultiSelectFilter
                                            labelId='type'
                                            inputLabel={t('PROMOTION.FORM.TYPE')}
                                            selectOption={promotionFormatOption}
                                            handleChange={(data: any) => handleChangeFilter(data, typeSelect, setTypeSelect, promotionFormatOption)}
                                            selectValue={typeSelect}
                                        />
                                    </Box>
                                    <Box width={{ md: 'calc(100% / 5)', xs: '100%' }} padding={{ md: '0px 6px', xs: '0' }}>
                                        <MultiSelectFilter
                                            labelId='type'
                                            inputLabel={t('PROMOTION.TABLE.ITEMTYPE')}
                                            selectOption={itemTypeOption}
                                            handleChange={(data: any) => handleChangeFilter(data, itemTypeSelect, setItemTypeSelect, itemTypeOption)}
                                            selectValue={itemTypeSelect}
                                        />
                                    </Box>
                                    <Box width={{ md: 'calc(100% / 5)', xs: '100%' }} padding={{ md: '0px 6px', xs: '0' }}>
                                        <MultiSelectFilter
                                            labelId='type'
                                            inputLabel={t('PROMOTION.REGISTER.TABLE.LIST')}
                                            selectOption={itemOption}
                                            handleChange={(data: any) => handleChangeFilter(data, itemSelect, setItemSelect, itemOption)}
                                            selectValue={itemSelect}
                                            inputSearch={loadItemList}
                                            loadOption={isLoadItemOption}
                                        />
                                    </Box>
                                    <Box width={{ md: 'calc(100% / 5)', xs: '100%' }} padding={{ md: '0px 6px', xs: '0' }} sx={{ 'button': { width: { md: '100px', xs: '100%' } } }}>
                                        <MainButton
                                            onClick={() => {
                                                setPageCurrent(1)
                                                loadDataPromotionBranch()
                                            }}
                                            title={t('INPUT.SEARCH')}
                                            type={'primary'}
                                            variant={'contained'}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ '.col-pro-no': { borderTop: loadFrist ? '1px solid !important' : '' } }}>
                                    <TableCustom
                                        page={pageCurrent}
                                        pageLimit={pageLimitCurrent}
                                        sortType={sortTypeCurrent}
                                        sortBy={sortByCurrent}
                                        onSort={onRequestSortCurrent}
                                        setPageLimit={handleChangeRowsPerPageCurrent}
                                        setPage={handleChangePageCurrent}
                                        rowCount={rowCountCurrent}
                                        headCells={headCellsCurrent}
                                        rowsData={dataCurrent?.length ? dataCurrent.map((item: any, index: number) => {
                                            return renderData(item, index)
                                        }) : []}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
            />
        </>
    )
}

export default FormViewPromotion

type MultiSelectFilterProps = {
    selectOption: any
    handleChange: (option: any) => void
    selectValue: any[]
    labelId: string
    inputLabel?: string
    inputSearch?: (search?: string) => void
    loadOption?: boolean
}

export const MultiSelectFilter = (props: MultiSelectFilterProps) => {
    const { t } = useTranslation()
    const { handleChange, selectValue, selectOption, inputSearch, loadOption } = props

    const [inputFocus, setInputFocus] = useState<boolean>(false)

    return (
        <Box sx={{
            // height: '100%',
            '.MuiAutocomplete-root': {
                height: '100% !important'
            },
            '.MuiInputBase-root': {
                height: '100% !important'
            },
            '.Mui-multiple': {
                '.MuiFormControl-root': {
                    '.MuiOutlinedInput-root': {
                        marginTop: 0,
                        padding: '10px 24px 0px 16px !important',
                        flexWrap: 'nowrap'
                    },
                    '.MuiOutlinedInput-input': {
                        height: '100% !important',
                        minWidth: 'unset',
                        padding: '0 !important'
                    },
                }
            },
        }}>
            <AutocompleteSelect
                labelId={props.labelId}
                getInputSearch={(e) => setInputFocus(e?.inputProps?.value !== "")}
                inputLabel={props?.inputLabel || ''}
                options={selectOption?.length ? selectOption?.length > 1 ? ['ALL', ...selectOption] : selectOption : []}
                getOptionLabel={(option: any) => `${option?.name || ''}`}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                renderOption={(props, option) => {
                    if (option === 'ALL') {
                        return (
                            <ListFilterDoctors
                                {...props}
                                key={option}
                                component="li"
                                value={option}
                                onClick={() => handleChange("ALL")}
                            >
                                <InputCheckbox
                                    label={t('ALL')}
                                    checked={selectValue?.length === selectOption?.length}
                                    indeterminate={selectValue?.length >= 1 && selectValue?.length !== selectOption?.length}
                                    className={'pe-none'}
                                />
                            </ListFilterDoctors>
                        )
                    } else {
                        return (
                            <ListFilterDoctors
                                {...props}
                                key={option.value}
                                component="li"
                                onClick={() => handleChange(option)}
                            >
                                <InputCheckbox
                                    label={option.name}
                                    checked={selectValue?.includes(option?.value)}
                                    className={'pe-none'}
                                />
                            </ListFilterDoctors>
                        )
                    }
                }}
                renderTags={(tagValue, getTagProps) => {
                    return (
                        <Box className='text-ellipsis ' sx={inputFocus && { width: "0px !important" } || {}}>
                            {((_.size(tagValue) === selectOption?.length)) && (
                                <span>{t('ALL')}</span>
                            ) || (
                                    <Box className='text-ellipsis '>
                                        {
                                            tagValue.map((option: any, index: number) => {
                                                return (
                                                    <span key={index}>
                                                        {_.find(selectOption, { value: option })?.name}
                                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                                    </span>
                                                )
                                            })
                                        }
                                    </Box>
                                )}
                        </Box>
                    )
                }}
                loading={loadOption}
                onInputChange={(e, value) => inputSearch && inputSearch(value)}
                onOpen={(event, value) => inputSearch && inputSearch()}
                onClose={(event, value) => inputSearch && inputSearch()}
                filterOptions={(option) => 'ALL' + option?.name || ''}
                value={selectValue}
                multiple
                disableClearable
                height={40}
            />
        </Box>
    )
}