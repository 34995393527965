import { ListItemText, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import _ from "lodash";
import CheckboxButton from "new-components/buttons/checkbox-button";
import { HelperText } from "./helper-text";


interface Option {
    id: any;
    name: string;
    disabled?: boolean
}

interface SelectCheckBoxInputProps {
    onChange: (selected: any[]) => void
    options: Option[]
    loading?: boolean
    fullWidth?: boolean
    selected: any;
    helperText?: string
    disabled?: boolean
    label?: string
    required?: boolean
    width?: string | number
    tranformOption?: boolean
}

export default function SelectCheckBoxInput(props: SelectCheckBoxInputProps) {

    const allOption: Option = { id: null, name: 'ทั้งหมด' };
    const allSelected = props.selected?.includes(allOption?.id);

    const handleChange = (event: any) => {
        const value = event.target.value as number[];

        if (value.includes(allOption.id)) {
            if (allSelected) {
                props.onChange([]);
            } else {
                const allIds = props.options
                    .filter(option => option.id !== null) // กรองค่าที่เป็น null
                    .map(option => option.id);
                if (allIds.length > 0) {
                    if (allIds.length === props.selected?.length) {
                        props.onChange([])
                    } else {
                        console.log(allIds)
                        props.onChange(allIds);
                    }
                } else {
                    props.onChange([]);
                }
            }
        } else {
            props.onChange(value);
        }
    };

    return (
        <FormControl sx={{ width: props.width || '100%' }} size="small" disabled={props.disabled}>
            <InputLabel id="demo-select-small-label" sx={{
                color: props.helperText ? '#B00020' : '#808080',
                '&.Mui-focused': {
                    color: props.helperText ? '#B00020' : '#1CB99A'
                },
            }}>{props.label} {props.required && <span style={{ color: 'red' }}>*</span>}</InputLabel>
            <Select
                multiple
                error={props.helperText !== '' && props.helperText !== undefined}
                fullWidth={props.fullWidth}
                value={props.selected}
                onChange={handleChange}
                inputProps={{ value: props.selected }}
                MenuProps={props.tranformOption ?
                    {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                    } : undefined}
                renderValue={(selectedIds) => {
                    if (props.options.length > 0 && selectedIds.length === props.options.length) {
                        return 'ทั้งหมด';
                    }
                    return props.options
                        .filter(option => selectedIds.includes(option.id))
                        .map(option => option.name)
                        .join(', ');
                }}
                sx={{
                    backgroundColor: props.disabled ? '#F0F0F0' : '#FFFFFF',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1CB99A',
                        borderWidth: '1px'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#B00020',
                    },
                    '&.Mui-disabled': {
                        borderColor: '#C5C5C5',
                    },
                }}
            >
                <MenuItem key={allOption.id} value={allOption.id}>
                    <CheckboxButton onChange={(event) => {
                        if (event.target.checked) {
                            if (props.options.every(option => props.selected.includes(option.id))) {
                                const newSelectedValues = props.selected.filter((id: any) => id !== allOption.id);
                                props.onChange(newSelectedValues);
                            } else {
                                const selectedOptions = props.options.map(option => option.id);
                                props.onChange(selectedOptions);
                            }
                        } else {
                            props.onChange([]);
                        }
                    }} checked={props.selected.includes(allOption.id) || props.options.every(option => props.selected.includes(option.id))}
                    />
                    <ListItemText primary={allOption.name} />
                </MenuItem>
                {props.loading ? <MenuItem disabled>กำลังโหลด...</MenuItem> :
                    _.map(props.options, (item, index) =>
                        <MenuItem
                            sx={
                                {
                                    "&.Mui-focusVisible": {
                                        backgroundColor: '#FFFFFF'
                                    },
                                    '&:hover': {
                                        backgroundColor: '#EBF2F0',
                                    },
                                    '&.Mui-selected.Mui-focusVisible': {
                                        backgroundColor: '#EBF2F0',
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&.Mui-selected:hover': {
                                        backgroundColor: '#EBF2F0',
                                    },
                                }
                            }
                            key={index}
                            disabled={item.disabled}
                            value={item.id}
                        >
                            <CheckboxButton onChange={() => null} checked={props.selected.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    )}
            </Select>
            {props.helperText && <HelperText label={props.helperText} />}
        </FormControl>
    )
}