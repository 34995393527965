import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportProps } from 'features/report/report-constants';
import TableRowExport from 'component/Pdf/TableRowExport'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, Divider } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { UseSnCode } from 'utils/useSnCode'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import Loading from 'component/Loading'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import FucntionPrint from "component/Print/print"

/** REPORT */
import VisitList from './VisitList'


/** CONSTANTS */
import { getBranch, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { colors } from 'constants/theme'
import { dateTimeToView, dateToView } from 'utils/date.utils'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { routeName } from 'routes/routes-name'
import VisitTotal from './VisitTotal';
import ButtonCustom from 'component/Button/ButtonCustom';
import InputTextSearch from 'component/Input/InputTextSearch';
import Column from 'antd/lib/table/Column';


export enum APPOINTMENT_STATUS {

    OVERDUE = 'OVERDUE', // ค้างชำระ
    PAID = `PAID`, // ชำระเงินเเล้ว
    CANCEL_VISIT = `CANCEL_VISIT`, // ยกเลิก visit
    CANCEL_BILL = `CANCEL_BILL` // ยกเลิก ใบเสร็จ
}


export default function Visit() {
    const { t } = useTranslation()
    const history = useHistory()
    const componentRef = useRef<HTMLDivElement>(null)
    const componentRefPDF = useRef<HTMLDivElement>(null)
    const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const momentDate = () => momentNowRange
    const [loading, setLoading] = useState<boolean>(true);

    const [branches, setBranches] = useState<any[]>([])
    const [branchId, setBranchId] = useState<any>(0)
    const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
    const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])
    const [prefix, setPrefix] = useState<string>('ALL')

    const [visitPage, setVisitPage] = useState<number>(1)
    const [visitPageLimit, setVisitPageLimit] = useState<number>(10)
    const [visitRowCount, setVisitRowCount] = useState<number>(0)
    const [visitSortBy, setVisitSortBy] = useState<string>('createAt')
    const [visitSortType, setVisitSortType] = useState<string>('ASC')
    const [visitData, setVisitData] = useState<any[]>([])
    const [visitExport, setVisitExportData] = useState<any[]>([])

    const [visitTypePage, setVisitTypePage] = useState<number>(1)
    const [visitTypePageLimit, setVisitTypePageLimit] = useState<number>(10)
    const [visitTypeRowCount, setVisitTypeRowCount] = useState<number>(0)
    const [visitTypeSortBy, setVisitTypeSortBy] = useState<string>('createAt')
    const [visitTypeSortType, setVisitTypeSortType] = useState<string>('ASC')
    const [visitTypeData, setVisitTypeData] = useState<any[]>([])
    const [visitTypeExport, setVisitTypeExportData] = useState<any[]>([])

    const [visitOverduePage, setVisitOverduePage] = useState<number>(1)
    const [visitOverduePageLimit, setVisitOverduePageLimit] = useState<number>(10)
    const [visitOverdueRowCount, setVisitOverdueRowCount] = useState<number>(0)
    const [visitOverdueSortBy, setVisitOverdueSortBy] = useState<string>('')
    const [visitOverdueSortType, setVisitOverdueSortType] = useState<string>('')
    const [visitOverdueData, setVisitOverdueData] = useState<any[]>([])
    const [visitOverdueExport, setVisitOverdueExportData] = useState<any[]>([])

    const [appointmentStatus, setAppointmentStatus] = useState('ALL');

    const [fetchData, setFetchData] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('');

    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, [])

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'VISIT') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        /** MASTER DATA */
        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        ClinicApi.allBillPrefix()
            .then(({ data }) => setReceiptPrefix(data))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    /** TABLE 1 */
    const fetchVisit = useCallback(() => {
        let condition: FindAllReportInterface = {
            page: visitPage, pageLimit: visitPageLimit,
            search: search
        }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (appointmentStatus) condition = { ...condition, status: appointmentStatus }
        if (visitSortType) condition = { ...condition, sortType: visitSortType }
        if (visitSortBy) condition = { ...condition, sortBy: visitSortBy }

        if (rangeDate[0] !== null && rangeDate[1] !== null)
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

        ReportApi.reportVisit(condition)
            .then(({ headers, data }) => {
                setVisitData(data)
                setVisitRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))
        setFetchData(false)

    }, [visitPage, visitPageLimit, fetchData])


    const onChangeVisitSort = (sortByVal: string, sortTypeVal: string) => {
        setVisitSortType(sortTypeVal)
        setVisitSortBy(sortByVal)
    }

    const onChangeVisitRowPerPage = (limit: number) => {
        setVisitPageLimit(limit)
    }

    const onChangeVisitPage = (val: number) => {
        setVisitPage(val)
    }

    const loadVisitExport = useCallback(async () => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000,
            status: appointmentStatus,
            export: 1
        }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (rangeDate[0] !== null && rangeDate[1] !== null)
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

        ReportApi.reportVisit(condition)
            .then(({ data }) => {
                setVisitExportData(data)
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

    }, [branchId, rangeDate, prefix, fetchData])

    /** TABLE 2 */
    const fetchVisitType = useCallback(() => {
        let condition: FindAllReportInterface = {
            page: visitTypePage, pageLimit: visitTypePageLimit,
            branchId: branchId, prefix: prefix
        }
        if (visitTypeSortType) condition = { ...condition, sortType: visitTypeSortType }
        if (visitTypeSortBy) condition = { ...condition, sortBy: visitTypeSortBy }

        if (rangeDate[0] !== null && rangeDate[1] !== null)
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    }, [visitTypePage, visitTypePageLimit, visitTypeSortType, visitTypeSortBy, branchId, rangeDate, prefix])

    const onChangeVisitTypeSort = (sortByVal: string, sortTypeVal: string) => {
        setVisitTypeSortType(sortTypeVal)
        setVisitTypeSortBy(sortByVal)
    }

    const onChangeVisitTypeRowPerPage = (limit: number) => {
        setVisitTypePageLimit(limit)
    }

    const onChangeVisitTypePage = (val: number) => {
        setVisitTypePage(val)
    }

    const loadVisitTypeExport = useCallback(async () => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000,
            branchId: branchId, prefix: prefix
        }

        if (rangeDate[0] !== null && rangeDate[1] !== null)
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    }, [branchId, rangeDate, prefix])


    const onChangeVisitOverdueSort = (sortByVal: string, sortTypeVal: string) => {
        setVisitOverdueSortType(sortTypeVal)
        setVisitOverdueSortBy(sortByVal)
    }

    const onChangeVisitOverdueRowPerPage = (limit: number) => {
        setVisitOverduePageLimit(limit)
    }

    const onChangeVisitOverduePage = (val: number) => {
        setVisitOverduePage(val)
    }

    const loadVisitOverdueExport = useCallback(async () => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000,
            branchId: branchId, prefix: prefix
        }

        if (rangeDate[0] !== null && rangeDate[1] !== null)
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

        ReportApi.reportIncomeOverdueDaily(condition)
            .then(({ data }) => {
                setVisitOverdueExportData(data)
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

    }, [branchId, prefix])

    /** UseEffect */
    useEffect(() => {
        fetchVisit()
    }, [fetchVisit])

    useEffect(() => {
        fetchVisitType()
    }, [fetchVisitType])


    useEffect(() => {
        loadVisitExport()
        loadVisitTypeExport()
        loadVisitOverdueExport()
    }, [loadVisitExport, loadVisitTypeExport, loadVisitOverdueExport])

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.VISIT`)} (${rangeDate[0]}-${rangeDate[1]})`,
        sheetName: `${t(`REPORT.REPORT_NAME.VISIT`)}`
    })

    const setHeaderSearch = [
        {
            name: `${t('REPORT.FILTER.BRANCH')}`,
            style: { width: "1%", whiteSpace: "nowrap" }
        },
        {
            name: branchId === 0 ? ` ${t('REPORT.REPORT_GROUP.ALL')}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName')}`,
            style: { width: "1%", whiteSpace: "nowrap", paddingRight: "10px" }
        },
        {
            name: `${t('REPORT.FILTER.APPOINTMENT_STATUS')}`,
            style: { width: "1%", whiteSpace: "nowrap" }
        },
        {
            name: `${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`,
            style: { width: "1%", whiteSpace: "nowrap", paddingRight: "10px" }
        },
        {
            name: `${t('REPORT.FILTER.START')}`,
            style: { width: "1%", whiteSpace: "nowrap" }
        },
        {
            name: `${rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'}`,
            style: { width: "1%", whiteSpace: "nowrap", paddingRight: "10px" }
        },
        {
            name: `${t('REPORT.FILTER.DATE_END')}`,
            style: { width: "1%", whiteSpace: "nowrap" }
        },
        {
            name: `${rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'}`,
            style: { width: "1%", whiteSpace: "nowrap", paddingRight: "10px" }
        },
        {
            name: ``,
            style: {}
        }
    ]

    return (
        <Card className={'border-0 h-100'}>
            <HeaderCard text={t(`REPORT.REPORT_NAME.VISIT`)} />
            <Card.Body>
                <Card.Title className={'mb-0'}>
                    <Row className='mx-0'>
                        <Col sm={12} md={6} lg={5} xl={3} xxl={2} className={'pt-2'}>
                            <InputTextSearch
                                keyInput={'search'}
                                label={t('REPORT.REPORT_NAME.SEARCH_VISIT')}
                                value={search}
                                onchange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                onClear={(event) => {
                                    setSearch('')
                                }}
                            />
                        </Col>
                        <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setBranchId(e.target.value)
                                    setVisitPage(1)
                                    setVisitTypePage(1)
                                }}
                                renderValue={() => branchId === 0 ? `${t('REPORT.FILTER.BRANCH')}: ${t('REPORT.REPORT_GROUP.ALL')}` : `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName')} `}
                                label={''}
                                selectId="select-branch"
                                labelId="label-branch"
                                value={branchId}
                                options={[
                                    <MenuItem key={0} value={0}>{t('REPORT.REPORT_GROUP.ALL')}</MenuItem>,
                                    ..._.map(branches, (b: BranchInterface, index) => (
                                        <MenuItem key={b.branchId} value={b.branchId}>
                                            {b.branchName || b.branchNameEn}
                                        </MenuItem>
                                    ))
                                ]
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setAppointmentStatus(e.target.value)
                                    setVisitPage(1)
                                }}
                                renderValue={() => appointmentStatus === 'ALL'
                                    ? `${t('REPORT.FILTER.APPOINTMENT_STATUS')}: ${t('REPORT.REPORT_GROUP.ALL')}`
                                    : `${t('REPORT.FILTER.APPOINTMENT_STATUS')}: ${t(`REPORT.VISIT_STATUS.${appointmentStatus}`)}`
                                }
                                label={''}
                                selectId="select-appointment-status"
                                labelId="label-appointment-status"
                                value={appointmentStatus}
                                options={
                                    [
                                        <MenuItem key="ALL" value="ALL">
                                            {t('REPORT.FILTER.ALL')}
                                        </MenuItem>,
                                        ...Object.values(APPOINTMENT_STATUS).map((status, index) => (
                                            <MenuItem key={index} value={status}>
                                                {t(`REPORT.VISIT_STATUS.${status}`)}
                                            </MenuItem>
                                        ))
                                    ]
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                            <InputSecondNewRangePicker
                                inputHeight={32}
                                value={rangeDate || emptyRangePicker}
                                onchange={(val: any) => {
                                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                    setVisitPage(1)
                                    setVisitTypePage(1)
                                }}
                                label={''}
                                allowClear
                                onClear={() => setRangeDate(emptyRangePicker)}
                            />
                        </Col>
                        <Col md={1} xl={1} className={'pt-0'}>
                            <ButtonCustom onClick={() => {
                                setFetchData(true);
                                setVisitPage(1);
                                setLoading(true)
                            }}
                                textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                        </Col>
                        <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                            <UseStyled.DropdownDocument>
                                <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                    {t('PRINT_DOCUMENT')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={onDownload}>
                                        {t('Excel')}
                                    </Dropdown.Item>
                                    <FucntionPrint
                                        content={() => componentRefPDF.current}
                                        docTitle={t(`REPORT.REPORT_NAME.VISIT`)}
                                        removeIfram
                                        trigger={() => <Dropdown.Item >
                                            {t('PDF')}
                                        </Dropdown.Item>}
                                        style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                    />
                                </Dropdown.Menu>
                            </UseStyled.DropdownDocument>
                        </Col>
                    </Row>
                </Card.Title>

                <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                    <VisitList
                        page={visitPage}
                        pageLimit={visitPageLimit}
                        rowCount={visitRowCount}
                        sortBy={visitSortBy}
                        sortType={visitSortType}
                        data={visitData}
                        onRequestSort={onChangeVisitSort}
                        handleChangeRowsPerPage={onChangeVisitRowPerPage}
                        handleChangePage={onChangeVisitPage} />
                </Box>
                <Divider className="mx-0 mt-3 mb-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4 d-flex'}>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <VisitTotal
                            page={visitTypePage}
                            pageLimit={visitTypePageLimit}
                            rowCount={visitTypeRowCount}
                            sortBy={visitTypeSortBy}
                            sortType={visitTypeSortType}
                            data={visitData}
                            onRequestSort={onChangeVisitTypeSort}
                            handleChangeRowsPerPage={onChangeVisitTypeRowPerPage}
                            handleChangePage={onChangeVisitTypePage} />
                    </Box>

                </Box>
            </Card.Body>
            <Loading show={loading} type='fullLoading' />


            <div className="print-show" ref={componentRef}>
                <ExportData
                    headCells={t(`REPORT.REPORT_NAME.VISIT`)}
                    componant={
                        <>
                            <UseStyled.Styles className="pt-3">
                                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', }}>
                                    <thead>
                                        <tr>
                                            {_.map(setHeaderSearch, (val: any) => {
                                                return (
                                                    <td style={{ textAlign: "left", fontSize: 10, fontWeight: 500, border: "none", ...val.style }}>
                                                        {t(val.name)}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                </table>
                            </UseStyled.Styles>
                            <br></br>
                            <ExportComponant
                                visitExport={visitExport}
                                visitTypeExport={visitTypeExport}
                                visitOverdueExport={visitOverdueExport}
                            />
                        </>
                    }
                />
            </div>

            <div className="print-show" ref={componentRefPDF}>
                <ExportData
                    headCells={t(`REPORT.REPORT_NAME.VISIT`)}
                    componant={
                        <>
                            <UseStyled.Styles className="pt-3">
                                <div style={{ fontSize: "14px" }}>
                                    <span>{t('REPORT.FILTER.BRANCH')}: </span>
                                    <span style={{ marginRight: "13px" }}>{branchId === 0 ? ` ${t('REPORT.REPORT_GROUP.ALL')}` : ` ${_.get(_.find(branches, { branchId: branchId }), 'branchName')} `}</span>
                                    <span>{t('REPORT.FILTER.APPOINTMENT_STATUS')} : </span>
                                    <span style={{ marginRight: "13px" }}>{appointmentStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : `${t(`REPORT.VISIT_STATUS.${appointmentStatus}`)}`}</span>
                                    <span>{t('REPORT.FILTER.DAY')} </span>
                                    <span style={{ marginRight: "5px" }}>{rangeDate[0] !== "Invalid date" ? dateToView(rangeDate[0]) : ""}</span>
                                    <span>{t('REPORT.FILTER.TO')} </span>
                                    <span>{rangeDate[1] !== "Invalid date" ? dateToView(rangeDate[1]) : ""}</span>
                                </div>
                            </UseStyled.Styles>
                            <ExportComponant
                                visitExport={visitExport}
                                visitTypeExport={visitTypeExport}
                                visitOverdueExport={visitOverdueExport}
                            />
                        </>
                    }
                />
            </div>
        </Card >
    )
}

export function ExportData(props: ExportProps) {
    return <>
        <div className='large'>
            {props.headCells}
        </div>
        {props.componant}
    </>
}

export function ExportComponant({ visitExport, visitTypeExport, visitOverdueExport }: any) {
    const { t } = useTranslation()
    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, [])
    const headCellVisit = [

        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.DATE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.VN', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.CN_NUMBER', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.BRANCH_CN_NUMBER', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.SN_CODE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PATIENT_NAME', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PHONE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PAYMENT_TOTAL', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PAYMENT_PAY', align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PAYMENT_OVERDUE', align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.APPOINTMENT_STATUS', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.SEND_BY', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.CANCEL_BY', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.REMARK_CANCEL', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.TRANSACTION_BY', align: 'center', class: 'text-nowrap' }
    ]
    if (!snType) {
        headCellVisit.splice(4, 1)
    }
    const headCellVisitTotal = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.APPOINTMENT_STATUS', align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.PAID'), align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.CANCEL_BILL'), align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.OVERDUE'), align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.CANCEL_VISIT'), align: 'center', class: 'text-nowrap' },
    ]


    const renderVisitTotalExport = (data: any, no: number, dataType: "sum" | "total") => {
        if (!data) return null;


        const label = dataType === "sum" ? t('REPORT.UNIT.QTY') : t('REPORT.UNIT.QTY_PAYMENT');
        return (
            <tr>
                <td style={{ textAlign: 'center', fontSize: '9px' }}>{label}</td>
                <td style={{ textAlign: 'center', fontSize: '9px' }}>{dataType === 'total' ? numberFormat(data.paid) || "-" : data.paid || "-"}</td>
                <td style={{ textAlign: 'center', fontSize: '9px' }}>{dataType === 'total' ? numberFormat(data.cancelBill) || "-" : data.cancelBill || "-"}</td>
                <td style={{ textAlign: 'center', fontSize: '9px' }}>{dataType === 'total' ? numberFormat(data.overdue) || "-" : data.overdue || "-"}</td>
                <td style={{ textAlign: 'center', fontSize: '9px' }}>{dataType === 'total' ? "-" : data.cancelVisit || "-"}</td>
            </tr>
        );
    }

    const renderVisitExport = (data: any, no: number) => {
        const num = no + 1
        const columns = [
            { option: 'TEXT', align: 'left', label: dateToView(data.updatedAt), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.vnNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.cnNumber || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.snCode || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.fullName },
            { option: 'TEXT', align: 'left', label: data.phone },
            { option: 'TEXT', align: 'right', label: data.visitStatus === "CANCEL_VISIT" ? '-' : numberFormat(data.totalPrice), class: 'text-nowrap' },
            { option: 'TEXT', align: 'right', label: data.visitStatus === "CANCEL_VISIT" ? '-' : numberFormat(data.amountPaid), class: 'text-nowrap' },
            { option: 'TEXT', align: 'right', label: data.visitStatus === "CANCEL_VISIT" ? '-' : numberFormat(data.overdue), class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: t(`REPORT.VISIT_STATUS.${data.visitStatus}`), class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.updatedBy || '-' },
            { option: 'TEXT', align: 'center', label: data.cancelBy || '-' },
            { option: 'TEXT', align: 'center', label: data.billRemark || '-' },
            { option: 'TEXT', align: 'center', label: data.visitStatus === "CANCEL_VISIT" ? '-' : data.updatedBillBy },
        ]
        if (!snType) {
            columns.splice(4, 1)
        }
        return <>
            <TableRowExport
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={columns}
            />
        </>
    }


    return <>
        <UseStyled.Styles className="pt-3">
            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                <thead style={{ fontSize: '8px' }}>
                    <tr>
                        {_.map(headCellVisit, (val: any) => {
                            return <td style={{ textAlign: val.align, fontSize: 7, fontWeight: 500, ...val.style }}> {t(val.labelKey)}</td>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {_.map(visitExport.rows, (d: any, index: number) => {
                        return (
                            renderVisitExport(d, index)
                        )
                    })}
                </tbody>
            </table>
        </UseStyled.Styles>
        <br></br>
        <UseStyled.Styles className="pt-3">
            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '50%', maxWidth: '50%', border: 'none !important' }}>
                <thead>
                    <tr>
                        {_.map(headCellVisitTotal, (val: any) => {
                            return <td style={{ textAlign: val.align, fontSize: 8, fontWeight: 300 }}> {t(val.labelKey)}</td>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {visitExport?.sum && renderVisitTotalExport(visitExport.sum, 1, "sum")}
                    {visitExport?.total && renderVisitTotalExport(visitExport.total, 2, "total")}
                </tbody>
            </table>
        </UseStyled.Styles>


    </>
}