import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { Divider, Box, Menu, MenuItem, Button, styled, Tooltip } from '@mui/material'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/personnel-managements/personnels/style'

/** UTILS || SLICE */
import { selectLoading, editUser, selectUserSetup, selectLoadingProfile } from 'app/slice/user.slice'

/** CONSTANT */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import InputTextField from 'component/Input/InputTextField'
import InputPasswordField from 'component/Input/InputPasswordField'
import { colors } from 'constants/theme'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import InputCheckbox from 'component/Input/InputCheckbox'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getPackage } from 'utils/app.utils'
import RadioButton from 'new-components/buttons/radio-button'
import IconClinicDisabeld from 'assets/icon/icon-clinic-disabeld'

const ButtonPermission = styled(Button)<{ open: boolean }>(({ theme, open }) => ({
  '&.MuiButton-root': {
    height: '2.5rem',
    fontSize: '16px !important',
    paddingLeft: '1rem',
    border: `1px solid ${colors.themeSecondColor} !important`,
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    minWidth: '150px'
  },
  '.MuiButton-endIcon': {
    transform: open ? 'rotate(180deg);' : ''
  },
  '&.MuiButton-text': {
    color: `${colors.themeSecondColor} !important`
  },
  '&.MuiButton-outlined': {
    color: `${colors.themeSecondColor} !important`,
    border: `1px solid ${colors.themeSecondColor} !important`
  },
  '&.MuiButton-contained': {
    background: `${colors.themeSecondColor} !important`,
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`,
    padding: '10px 16px !important',
    border: 'none !important',
    '&:hover': {
      backgroundColor: `${colors.themeSecondColor} !important`,
      color: `${colors.white} !important`
    },
    '& .MuiButton-startIcon': {
      marginLeft: 0
    }
  },
  '&.btn-style': {
    borderRadius: '4px'
  }
}))

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: 'none',
  // padding: '0 1rem',
  // marginBottom: '1.5rem !important',
  '&:first-of-type': {
    marginTop: '1rem !important'
  },
  '&:before': {
    opacity: 0
  },
  '.header-text': {
    h5: {
      fontSize: 18,
      fontWeight: 500,
      '&.required::after': {
        color: colors.statusInactive
      }
    }
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '0 1.5rem',
    '&:first-of-type': {
      marginTop: '1.5rem !important'
    }
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ backgroundColor: colors.white, border: `0.5px solid ${colors.textLightGray}`, borderRadius: '50px', color: colors.themeMainColor }} />} {...props} />
))(({ theme }) => ({
  minHeight: '35px !important',
  backgroundColor: colors.themeSecondColor10,
  '&.error': {
    backgroundColor: colors.statusInactive10
  },
  '.MuiAccordionSummary-content': {
    margin: 0
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0
  },
  '.MuiGrid-container': {
    margin: 0,
    width: '100%',
    '.MuiGrid-item': {
      width: '100%'
    }
  },
  '.MuiIconButton-root': {
    '&.Mui-disabled': {
      path: {
        fill: colors.disabledGray
      }
    }
  },
  '.btn-parent-remove': {
    paddingTop: '0.875rem',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0.5rem'
    }
  }
}))

interface SetupAccountProps {
  isEdit: boolean
  errorMessage?: any
  resetErrorMessage?: (type: string) => void
}
export default function SetupAccount(props: SetupAccountProps) {

  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const userSetup = useSelector(selectUserSetup)
  const loading = useSelector(selectLoading)
  const loadingProfile = useSelector(selectLoadingProfile)

  const [userCode, setUserCode] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  const [scheduleDentist, setScheduleDentist] = useState('0')
  const [scheduleNormal, setScheduleNomal] = useState('0')

  const [hasCancelBill, setHasCancelBill] = useState(false)
  const [hasChangeTreatmentCost, setHasChangeTreatmentCost] = useState(false)
  const [hasChangeTreatmentNote, setHasChangeTreatmentNote] = useState(false)
  const [hasTreatment, setHasTreatment] = useState(false)
  const [hasChangePrice, setHasChangePrice] = useState(false)
  const [hasChangeDiscountAdditional, setHasChangeDiscountAdditional] = useState(false)
  const [hasManageAdvances, setHasManageAdvances] = useState(false)

  const [hasTreatmentPlan, setHasTreatmentPlan] = useState(false)
  const [hasTreatmentCourse, setHasTreatmentCourse] = useState(false)
  const [hasDoctorNote, setHasDoctorNote] = useState(false)
  const [hasCancelVisit, setHasCancelVisit] = useState(false)
  const [hasCancelLabOrder, setHasCancelLabOrder] = useState(false)
  const [hasReplyLabOrder, setHasReplyLabOrder] = useState(false)
  const [hasViewIncome, setHasViewIncome] = useState(false)
  const [isViewDfAndLab, setIsViewDfAndLab] = useState(false)
  const [isViewDfConfirm, setIsViewDfConfirm] = useState(false)
  const [autoShowPatientProfile, setAutoShowPatientProfile] = useState(false)
  const [readOnly, setReadOnly] = useState(true)

  // Create date 8/6/2023
  const [hasTreatmentAdd, setHasTreatmentAdd] = useState(false)
  const [hasTreatmentDelete, setHasTreatmentDelete] = useState(false)
  const [hasTreatmentPlanAdd, setHasTreatmentPlanAdd] = useState(false)
  const [hasTreatmentPlanDelete, setHasTreatmentPlanDelete] = useState(false)
  const [hasTreatmentCourseAdd, setHasTreatmentCourseAdd] = useState(false)
  const [hasTreatmentCourseDelete, setHasTreatmentCourseDelete] = useState(false)
  const [hasDoctorNoteAdd, setHasDoctorNoteAdd] = useState(false)
  const [hasDoctorNoteDelete, setHasDoctorNoteDelete] = useState(false)
  const [hasSelectAll, setHasSelectAll] = useState(false)

  const [hasCancelTreatmentQueue, setHasCancelTreatmentQueue] = useState<boolean>(false)
  const [hasInsuranceCost, setHasInsuranceCost] = useState<boolean>(false)
  const [hasEditBill, setHasEditBill] = useState<boolean>(false)
  const [hasResetBill, setHasResetBill] = useState<boolean>(false)
  const [hasShowPhoneNumber, setHasShowPhoneNumber] = useState<boolean>(false)

  const [hasDiagnosis, setHasDiagnosis] = useState<boolean>(false)
  const [hasCreateDiagnosis, setHasCreateDiagnosis] = useState<boolean>(false)
  const [hasDeleteDiagnosis, setHasDeleteDiagnosis] = useState<boolean>(false)

  const [hasMed, setHasMed] = useState<boolean>(false)
  const [hasCreateMed, setHasCreateMed] = useState<boolean>(false)
  const [hasDeleteMed, setHasDeleteMed] = useState<boolean>(false)

  const [hasProduct, setHasProduct] = useState<boolean>(false)
  const [hasCreateProduct, setHasCreateProduct] = useState<boolean>(false)
  const [hasDeleteProduct, setHasDeleteProduct] = useState<boolean>(false)

  const [hasApproveIncomeAndExpenses, setHasApproveIncomeAndExpenses] = useState<boolean>(false)
  const [hasApproveTheAmountChange, setHasApproveTheAmountChange] = useState<boolean>(false)

  const isPackage = getPackage()

  useEffect(() => {
    // if (!userSetup) {

    setUserCode(userSetup.userCode)
    setUsername(userSetup.username)
    setPassword(userSetup.password)
    setConfirmPass(userSetup.confirmPass)
    setScheduleNomal(userSetup.ownAppointment)
    setScheduleDentist(userSetup.ownSchedule)
    setHasCancelBill(userSetup.hasCancelBill === 'PERMISS' ? true : false)
    setHasChangeTreatmentCost(userSetup.hasChangeTreatmentCost === 'PERMISS' ? true : false)
    setHasChangeTreatmentNote(userSetup.hasChangeTreatmentNote === 'PERMISS' ? true : false)
    setHasTreatment(userSetup.hasTreatment === 'PERMISS' ? true : false)
    setHasChangePrice(userSetup.hasChangePrice === 'PERMISS' ? true : false)
    setHasChangeDiscountAdditional(userSetup.hasChangeDiscountAdditional === 'PERMISS' ? true : false)
    setHasManageAdvances(userSetup.hasManageAdvances === 'PERMISS' ? true : false)

    setHasTreatmentPlan(userSetup.hasTreatmentPlan === 'PERMISS' ? true : false)
    setHasTreatmentCourse(userSetup.hasTreatmentCourse === 'PERMISS' ? true : false)
    setHasDoctorNote(userSetup.hasDoctorNote === 'PERMISS' ? true : false)
    setHasCancelVisit(userSetup.hasCancelVisit === 'PERMISS' ? true : false)
    setHasCancelLabOrder(userSetup.hasCancelLabOrder === 'PERMISS' ? true : false)
    setHasReplyLabOrder(userSetup.hasReplyLabOrder === 'PERMISS' ? true : false)
    setHasViewIncome(userSetup.hasViewIncome === 'PERMISS' ? true : false)
    setIsViewDfAndLab(userSetup.isViewDfAndLab === '1' ? true : false)
    setAutoShowPatientProfile(userSetup.autoShowPatientProfile === '1' ? true : false)
    setIsViewDfConfirm(userSetup.isViewDfConfirm === '1' ? true : false)

    // NEW 8/6/2023
    setHasTreatmentAdd(userSetup.hasCreateTreatment === 'PERMISS' ? true : false)
    setHasTreatmentDelete(userSetup.hasDeleteTreatment === 'PERMISS' ? true : false)
    setHasTreatmentPlanAdd(userSetup.hasCreateTreatmentPlan === 'PERMISS' ? true : false)
    setHasTreatmentPlanDelete(userSetup.hasDeleteTreatmentPlan === 'PERMISS' ? true : false)
    setHasTreatmentCourseAdd(userSetup.hasCreateTreatmentCourse === 'PERMISS' ? true : false)
    setHasTreatmentCourseDelete(userSetup.hasDeleteTreatmentCourse === 'PERMISS' ? true : false)
    setHasDoctorNoteAdd(userSetup.hasCreateDoctorNote === 'PERMISS' ? true : false)
    setHasDoctorNoteDelete(userSetup.hasDeleteDoctorNote === 'PERMISS' ? true : false)

    setHasCancelTreatmentQueue(userSetup.hasCancelTreatmentQueue === 'PERMISS' ? true : false)
    setHasEditBill(userSetup.hasEditBill === 'PERMISS' ? true : false)
    setHasResetBill(userSetup.hasResetBill === 'PERMISS' ? true : false)
    setHasInsuranceCost(userSetup.hasInsuranceCost === 'PERMISS' ? true : false)
    setHasShowPhoneNumber(userSetup.hasShowPhoneNumber === 'PERMISS' ? true : false)

    setHasDiagnosis(userSetup.hasDiagnosis === 'PERMISS' ? true : false)
    setHasCreateDiagnosis(userSetup.hasCreateDiagnosis === 'PERMISS' ? true : false)
    setHasDeleteDiagnosis(userSetup.hasDeleteDiagnosis === 'PERMISS' ? true : false)

    setHasMed(userSetup?.hasMed === 'PERMISS' ? true : false)
    setHasCreateMed(userSetup?.hasCreateMed === 'PERMISS' ? true : false)
    setHasDeleteMed(userSetup?.hasDeleteMed === 'PERMISS' ? true : false)

    setHasProduct(userSetup?.hasProduct === 'PERMISS' ? true : false)
    setHasCreateProduct(userSetup?.hasCreateProduct === 'PERMISS' ? true : false)
    setHasDeleteProduct(userSetup?.hasDeleteProduct === 'PERMISS' ? true : false)

    setHasApproveIncomeAndExpenses(userSetup?.hasApproveIncomeAndExpenses === 'PERMISS' ? true : false)
    setHasApproveTheAmountChange(userSetup?.hasApproveTheAmountChange === 'PERMISS' ? true : false)

    // }
  }, [loading, loadingProfile, userSetup])

  useEffect(() => {
    setHasSelectAll(isSelecAll(userSetup))
  }, [])
  useEffect(() => {
    if (!loading) {
      selectAll()
    }
  }, [hasSelectAll])

  const [anchorElNorm, setAnchorElNorm] = useState<null | HTMLElement>(null)
  const openNorm = Boolean(anchorElNorm)
  const handleClickNorm = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNorm(event.currentTarget)
  }

  const [anchorElDen, setAnchorElDen] = useState<null | HTMLElement>(null)
  const openDen = Boolean(anchorElDen)
  const handleClickDen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDen(event.currentTarget)
  }

  const isSelecAll = (data: any) => {
    const keys = ['hasResetBill', 'hasEditBill', 'hasCancelTreatmentQueue', 'hasDeleteDoctorNote', 'hasCreateDoctorNote', 'hasDeleteTreatmentCourse',
      'hasCreateTreatmentCourse', 'hasDeleteTreatmentPlan', 'hasCreateTreatmentPlan', 'hasDeleteTreatment', 'hasCreateTreatment', 'isViewDfAndLab', 'autoShowPatientProfile', 'isViewDfConfirm',
      'hasViewIncome', 'hasReplyLabOrder', 'hasCancelLabOrder', 'hasCancelVisit', 'hasDoctorNote', 'hasTreatmentCourse', 'hasTreatmentPlan', 'hasChangeDiscountAdditional',
      'hasChangePrice', 'hasManageAdvances', 'hasTreatment', 'hasChangeTreatmentNote', 'hasChangeTreatmentCost', 'hasCancelBill', 'hasInsuranceCost', 'hasShowPhoneNumber',
      'hasDiagnosis', 'hasCreateDiagnosis', 'hasDeleteDiagnosis', 'hasApproveIncomeAndExpenses', 'hasApproveTheAmountChange']

    return !keys.find(item => data[item] === '0' || data[item] === 'UNPERMISS')
  }

  const handleSelectNorm = (e: any) => {
    dispatch(editUser({ value: e.target.value, key: 'ownAppointment' }))
    setScheduleNomal(e.target.value)
    setAnchorElNorm(null)
  }

  const handleSelectDen = (e: any) => {
    dispatch(editUser({ value: e.target.value, key: 'ownSchedule' }))
    setScheduleDentist(e.target.value)
    setAnchorElDen(null)
  }

  const clearReadOnly = () => {
    setReadOnly(false)
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = event.target.value.trim()
    setPassword(enteredValue)
    dispatch(editUser({ value: enteredValue, key: 'password' }))
    if (props.resetErrorMessage) props.resetErrorMessage('setup')
  }
  const selectAll = () => {
    setHasCancelBill(hasSelectAll)
    setHasChangeTreatmentCost(hasSelectAll)
    setHasChangeTreatmentNote(hasSelectAll)
    setHasTreatment(hasSelectAll)
    setHasChangePrice(hasSelectAll)
    setHasChangeDiscountAdditional(hasSelectAll)
    setHasManageAdvances(hasSelectAll)

    setHasTreatmentPlan(hasSelectAll)
    setHasTreatmentCourse(hasSelectAll)
    setHasDoctorNote(hasSelectAll)
    setHasCancelVisit(hasSelectAll)
    setHasCancelLabOrder(hasSelectAll)
    setHasReplyLabOrder(hasSelectAll)
    setHasViewIncome(hasSelectAll)
    setIsViewDfAndLab(hasSelectAll)
    setIsViewDfConfirm(hasSelectAll)
    setAutoShowPatientProfile(hasSelectAll)

    setHasTreatmentAdd(hasSelectAll)
    setHasTreatmentDelete(hasSelectAll)
    setHasTreatmentPlanAdd(hasSelectAll)
    setHasTreatmentPlanDelete(hasSelectAll)
    setHasTreatmentCourseAdd(hasSelectAll)
    setHasTreatmentCourseDelete(hasSelectAll)
    setHasDoctorNoteAdd(hasSelectAll)
    setHasDoctorNoteDelete(hasSelectAll)

    setHasCancelTreatmentQueue(hasSelectAll)
    setHasInsuranceCost(hasSelectAll)
    setHasEditBill(hasSelectAll)
    setHasResetBill(hasSelectAll)
    setHasShowPhoneNumber(hasSelectAll)

    setHasDiagnosis(hasSelectAll)
    setHasCreateDiagnosis(hasSelectAll)
    setHasDeleteDiagnosis(hasSelectAll)

    setHasMed(hasSelectAll)
    setHasCreateMed(hasSelectAll)
    setHasDeleteMed(hasSelectAll)

    setHasProduct(hasSelectAll)
    setHasCreateProduct(hasSelectAll)
    setHasDeleteProduct(hasSelectAll)

    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasViewIncome', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasTreatment', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateTreatment', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteTreatment', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasTreatmentPlan', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateTreatmentPlan', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteTreatmentPlan', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasTreatmentCourse', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateTreatmentCourse', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteTreatmentCourse', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDoctorNote', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateDoctorNote', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteDoctorNote', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasChangePrice', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasChangeDiscountAdditional', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasManageAdvances', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasChangeTreatmentNote', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasChangeTreatmentCost', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelBill', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelVisit', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelLabOrder', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasReplyLabOrder', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelTreatmentQueue', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasInsuranceCost', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasEditBill', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasResetBill', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasShowPhoneNumber', }))
    dispatch(editUser({ value: hasSelectAll === true ? '1' : '0', key: 'isViewDfAndLab' }))
    dispatch(editUser({ value: hasSelectAll === true ? '1' : '0', key: 'isViewDfConfirm' }))
    dispatch(editUser({ value: hasSelectAll === true ? '1' : '0', key: 'autoShowPatientProfile' }))

    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDiagnosis', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateDiagnosis', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteDiagnosis', }))

    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasMed', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateMed', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteMed', }))

    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasProduct', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateProduct', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteProduct', }))

    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasApproveIncomeAndExpenses', }))
    dispatch(editUser({ value: hasSelectAll === true ? 'PERMISS' : 'UNPERMISS', key: 'hasApproveTheAmountChange', }))
  }

  const renderPayment = [
    {
      menuName: t('PERSONNEL.SET_MENU.DX'),
      detail: [
        {
          detailName: 'WATCH',
          checked: hasDiagnosis || false,
          onChange: (e: any) => {
            setHasDiagnosis(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDiagnosis' }))
          },
          // disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'ADD',
          checked: hasCreateDiagnosis || false,
          onChange: (e: any) => {
            setHasCreateDiagnosis(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateDiagnosis' }))
          },
          // disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'DEL',
          checked: hasDeleteDiagnosis || false,
          onChange: (e: any) => {
            setHasDeleteDiagnosis(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteDiagnosis' }))
          },
          // disabled: isPackage?.package === 'STANDARD'
        }
      ]
    },
    {
      menuName: t('PERSONNEL.SET_MENU.TX'),
      detail: [
        {
          detailName: 'WATCH',
          checked: hasTreatment || false,
          onChange: (e: any) => {
            setHasTreatment(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasTreatment' }))
          }
        },
        {
          detailName: 'ADD',
          checked: hasTreatmentAdd || false,
          onChange: (e: any) => {
            setHasTreatmentAdd(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateTreatment' }))
          }
        },
        {
          detailName: 'DEL',
          checked: hasTreatmentDelete || false,
          onChange: (e: any) => {
            setHasTreatmentDelete(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteTreatment' }))
          }
        }
      ]
    },
    {
      menuName: t('PERSONNEL.SET_MENU.TX_P'),
      detail: [
        {
          detailName: 'WATCH',
          checked: hasTreatmentPlan || false,
          onChange: (e: any) => {
            setHasTreatmentPlan(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasTreatmentPlan' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'ADD',
          checked: hasTreatmentPlanAdd || false,
          onChange: (e: any) => {
            setHasTreatmentPlanAdd(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateTreatmentPlan' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'DEL',
          checked: hasTreatmentPlanDelete || false,
          onChange: (e: any) => {
            setHasTreatmentPlanDelete(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteTreatmentPlan' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        }
      ]
    },
    {
      menuName: t('PERSONNEL.SET_MENU.TX_C'),
      detail: [
        {
          detailName: 'WATCH',
          checked: hasTreatmentCourse || false,
          onChange: (e: any) => {
            setHasTreatmentCourse(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasTreatmentCourse' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'ADD',
          checked: hasTreatmentCourseAdd || false,
          onChange: (e: any) => {
            setHasTreatmentCourseAdd(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateTreatmentCourse' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'DEL',
          checked: hasTreatmentCourseDelete || false,
          onChange: (e: any) => {
            setHasTreatmentCourseDelete(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteTreatmentCourse' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        }
      ]
    },
    {
      menuName: t('PERSONNEL.SET_MENU.MED'),
      detail: [
        {
          detailName: 'WATCH',
          checked: hasMed || false,
          onChange: (e: any) => {
            setHasMed(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasMed' }))
          }
        },
        {
          detailName: 'ADD',
          checked: hasCreateMed || false,
          onChange: (e: any) => {
            setHasCreateMed(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateMed' }))
          }
        },
        {
          detailName: 'DEL',
          checked: hasDeleteMed || false,
          onChange: (e: any) => {
            setHasDeleteMed(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteMed' }))
          }
        },

      ]
    },
    {
      menuName: t('PERSONNEL.SET_MENU.PD'),
      detail: [
        {
          detailName: 'WATCH',
          checked: hasProduct || false,
          onChange: (e: any) => {
            setHasProduct(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasProduct' }))
          }
        },
        {
          detailName: 'ADD',
          checked: hasCreateProduct || false,
          onChange: (e: any) => {
            setHasCreateProduct(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateProduct' }))
          }
        },
        {
          detailName: 'DEL',
          checked: hasDeleteProduct || false,
          onChange: (e: any) => {
            setHasDeleteProduct(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteProduct' }))
          }
        },

      ]
    },
    {
      menuName: t('PERSONNEL.SET_MENU.DOCTOR_NOTE'),
      detail: [
        {
          detailName: 'WATCH',
          checked: hasDoctorNote || false,
          onChange: (e: any) => {
            setHasDoctorNote(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDoctorNote' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'ADD',
          checked: hasDoctorNoteAdd || false,
          onChange: (e: any) => {
            setHasDoctorNoteAdd(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCreateDoctorNote' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        },
        {
          detailName: 'DEL',
          checked: hasDoctorNoteDelete || false,
          onChange: (e: any) => {
            setHasDoctorNoteDelete(e.target.checked)
            dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasDeleteDoctorNote' }))
          },
          disabled: isPackage?.package === 'STANDARD'
        },

      ]
    },
  ]

  const handleDetailNameItems = (datailName: string) => {
    if (datailName === "WATCH") {
      return t('PERSONNEL.BUTTON.WATCH')
    } else if (datailName === "ADD") {
      return t('PERSONNEL.BUTTON.ADD')
    } else {
      return t('PERSONNEL.BUTTON.DELETE')
    }
  }

  return (
    <div className="py-3 px-4">
      {!props.isEdit && (
        <>
          <Row className={'mt-3'}>
            <Col sm={4} className="px-2" onClick={clearReadOnly}>
              <InputTextField
                required={true}
                label={t('PERSONNEL.INPUT.PERSONNEL_USERNUMBER')}
                value={userCode}
                onchange={(e) => {
                  dispatch(editUser({ value: e.target.value, key: 'userCode' }))
                  setUserCode(e.target.value)
                  if (props.resetErrorMessage) props.resetErrorMessage('setup')
                }}
                inputProps={{ readOnly: readOnly }}
                helperText={props.errorMessage.USER_CODE}
                disabledAutoComplete
              />
            </Col>
            <Col sm={4} className="px-2" onClick={clearReadOnly}>
              <InputTextField
                required={true}
                label={t('PERSONNEL.INPUT.PERSONNEL_USERNAME')}
                value={username}
                onchange={(e) => {
                  dispatch(editUser({ value: e.target.value, key: 'username' }))
                  setUsername(e.target.value)
                  if (props.resetErrorMessage) props.resetErrorMessage('setup')
                }}
                inputProps={{ readOnly: readOnly }}
                helperText={props.errorMessage.USER_NAME}
              />
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col sm={4} className="px-2" onClick={clearReadOnly}>
              <InputPasswordField label={t(`PERSONNEL.INPUT.PERSONNEL_PASSWORD`)} value={password} onchange={handlePassword} helperText={props.errorMessage.PASSWORD || props.errorMessage.MINIMUM_PASSWORD || ''} inputProps={{ readOnly: readOnly }} />
            </Col>
            <Col sm={4} className="px-2" onClick={clearReadOnly}>
              <InputPasswordField
                label={t(`PERSONNEL.INPUT.PERSONNEL_CONFIRM_PASSWORD`)}
                value={confirmPass}
                onchange={(e) => {
                  dispatch(editUser({ value: e.target.value, key: 'confirmPassword' }))
                  setConfirmPass(e.target.value)
                  if (props.resetErrorMessage) props.resetErrorMessage('setup')
                }}
                helperText={props.errorMessage.CONFIRM_PASSWORD || props.errorMessage.PASSWORD_NOT_MATCH || ''}
                inputProps={{ readOnly: readOnly }}
              />
            </Col>
          </Row>
          <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
        </>
      )}

      <Row>
        <Col sm={12} className="px-2">
          <div className={`d-flex align-items-center ${classes.titleFirst}`}>
            {t('PERSONNEL.MENU_PERMISSION')}
            <Box className="ml-2">
              <ButtonInfoSimple text={t('PERSONNEL.SETTING_PERMISSION')} />
            </Box>
          </div>
        </Col>
      </Row>
      <Row className="mt-3 align-items-center">
        <Col sm={3} className="px-2">
          <div className={`${classes.titleSecond}`}>
            <strong>{t('PERSONNEL.SCHEDULE_APP')}</strong>
          </div>
        </Col>
        <Col>
          <Box sx={{ '.MuiFormGroup-root': { '.MuiBox-root': { width: '30%' } } }}>
            <RadioButton
              name="scheduleApp"
              onChange={handleSelectNorm}
              sx={{ gap: '16px' }}
              row
              value={scheduleNormal}
              options={[
                { label: t('USER.BUTTON.ALL'), value: '0' },
                { label: t('USER.BUTTON.ONLY'), value: '1' },
              ]}
            />
          </Box>
        </Col>
      </Row>

      <Row className="mt-3 align-items-center">
        <Col sm={3} className="px-2">
          <div className={`${classes.titleSecond}`}>
            <strong>{t('PERSONNEL.DOCTOR_SCHEDULE')}</strong>
          </div>
        </Col>
        <Col>
          <Box sx={{ '.MuiFormGroup-root': { '.MuiBox-root': { width: '30%' } } }}>
            <RadioButton
              name="scheduleDetist"
              onChange={handleSelectDen}
              sx={{ gap: '16px' }}
              row
              value={scheduleDentist}
              options={[
                { label: t('USER.BUTTON.ALL'), value: '0' },
                { label: t('USER.BUTTON.ONLY'), value: '1' },
              ]}
            />
          </Box>
        </Col>
      </Row>

      <Divider className="mx-0 mt-3 mb-2" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

      <Box>
        <Row className="mt-3">
          <Col sm={3} className="" style={{ padding: '0 4px 0 12px' }}>
            <div className={` ${classes.titleSecond}`}
              style={{
                minHeight: '35px',
                backgroundColor: colors.themeSecondColor10,
                padding: '0 16px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <h5 style={{
                margin: 0,
                fontSize: 18,
                fontWeight: 500,
              }}>
                {t('USER.BUTTON.SELECT_ALL')}
              </h5>
            </div>
          </Col>
          <Col sm={9} className="px-2" style={{ display: 'flex', alignItems: 'center' }}>
            <Row>
              <Col sm={4} className="px-2">
                <div className="ml-0">
                  <InputCheckbox
                    label={'    '}
                    checked={hasSelectAll}
                    onChange={(e) => {
                      setHasSelectAll(e.target.checked)
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="">
          <Accordion defaultExpanded={true} className={`w-100 `}>
            <AccordionSummary aria-controls="panel2d-content">
              <div className={'header-text'}>
                <h5 className={'m-0'}>{t('PERSONNEL.FORM.HOME')}</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Col sm={9} className="px-2">
                <Row>
                  <Col sm={4} className="px-2 mt-2">
                    <InputCheckbox
                      label={t('PERSONNEL.INPUT.INFORMATION.LIST_VIEW_ALL')}
                      checked={hasViewIncome || false}
                      onChange={(e) => {
                        setHasViewIncome(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasViewIncome', }))
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </AccordionDetails>
          </Accordion>
        </Row>

        <Row className="">
          <Accordion defaultExpanded={true} className={`w-100`}>
            <AccordionSummary aria-controls="panel2d-content">
              <div className={'header-text'}>
                <h5 className={'m-0'}>{t('PERSONNEL.FORM.TREAMENT')}</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails >
              <Col sm={12} className="px-2">
                <Row>
                  <Col sm={4} className="px-2 mt-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.SUM_DF_LAB')}
                      checked={isViewDfAndLab || false}
                      onChange={(e) => {
                        setIsViewDfAndLab(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? '1' : '0', key: 'isViewDfAndLab' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2 mt-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.DF')}
                      checked={isViewDfConfirm || false}
                      onChange={(e) => {
                        setIsViewDfConfirm(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? '1' : '0', key: 'isViewDfConfirm' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2 mt-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.DATA_PATIENT')}
                      checked={autoShowPatientProfile || false}
                      onChange={(e) => {
                        setAutoShowPatientProfile(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? '1' : '0', key: 'autoShowPatientProfile' }))
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </AccordionDetails>
          </Accordion>
        </Row>

        <Row className="">
          <Accordion defaultExpanded={true} className={`w-100`}>
            <AccordionSummary aria-controls="panel2d-content">
              <div className={'header-text'}>
                <h5 className={'m-0'}>{t('PERSONNEL.FORM.PAYMENT')}</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails >
              <Col sm={12} className="px-2">
                <Row className="p-0 mt-2 d-none d-sm-flex" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'item' }}>
                  <Col sm={6} className="p-0" style={{ display: 'flex' }}>
                    <Col sm={3} className="d-flex justify-content-center" >
                      {t('PERSONNEL.BUTTON.WATCH')}
                    </Col>
                    <Col sm={3} className="d-flex justify-content-center" >
                      {t('PERSONNEL.BUTTON.ADD_')}
                    </Col>
                    <Col sm={3} className="d-flex justify-content-center" >
                      {t('PERSONNEL.BUTTON.DELETE')}
                    </Col>
                  </Col>
                </Row>
                <Row>

                  {renderPayment.map((item, index: number) => {
                    const isDisabled = item.detail.every((info: any) => info?.disabled)
                    return (
                      <Col sm={12} className="px-2 mt-2 d-block d-sm-flex" style={{ display: 'flex', alignItems: 'item' }} key={index}>
                        <Col xs={12} sm={6} >
                          <Box color={isDisabled ? '#808080' : ''} display='flex' alignItems='center'>
                            {item.menuName}
                            {item.detail.every((info: any) => info?.disabled !== undefined) &&
                              <Tooltip title={t('ฟังก์ชันนี้สามารถ ใช้งานได้ในแพคเกจ PREMIUM และ ULTRA')} placement='top-start'>
                                <Box>
                                  <IconClinicDisabeld />
                                </Box>
                              </Tooltip>
                            }
                          </Box>
                        </Col>
                        <Col xs={12} sm={6} style={{ display: 'flex', alignItems: 'item' }}>
                          {item.detail.map((detail: any, index_2: number) => {
                            return (
                              <Col key={index_2} xs={3} sm={3} className="d-flex justify-content-center align-items-center">
                                <Box className={'d-block d-sm-none'}>{handleDetailNameItems(detail.detailName)}</Box>
                                <InputCheckbox
                                  label={''}
                                  checked={isDisabled ? false : detail.checked}
                                  onChange={(e) => detail.onChange(e)}
                                  disabled={detail?.disabled}
                                />
                              </Col>
                            )
                          })}
                        </Col>
                      </Col>
                    )
                  })}
                </Row>
                <Divider className="mx-0 mt-3 mb-2" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                <div style={{ display: 'flex' }}>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.EDIT_ADDITIONAL_INFORMATION')}
                      checked={hasChangeTreatmentNote || false}
                      onChange={(e) => {
                        setHasChangeTreatmentNote(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasChangeTreatmentNote' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.EDIT_DRUGS_AND_PRODUCTS_PRICE')}
                      checked={hasChangePrice || false}
                      onChange={(e) => {
                        setHasChangePrice(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasChangePrice' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.EDIT_DISCOUNTS_AND_SURCHARGES')}
                      checked={hasChangeDiscountAdditional || false}
                      onChange={(e) => {
                        setHasChangeDiscountAdditional(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasChangeDiscountAdditional' }))
                      }}
                    />
                  </Col>
                </div>
              </Col>
            </AccordionDetails>
          </Accordion>
        </Row>

      </Box >

      <Box>
        <Row className="">
          <Accordion defaultExpanded={true} className={`w-100`}>
            <AccordionSummary aria-controls="panel2d-content">
              <div className={'header-text'}>
                <h5 className={'m-0'}>{t('PERSONNEL.FORM.OTHER_PRIVILEGES')}</h5>
              </div>
            </AccordionSummary>
            <AccordionDetails >
              <Col sm={12} className="px-2 mt-2">
                <Row>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.GUARANTEE_COST_SHIFTS')}
                      checked={hasInsuranceCost || false}
                      onChange={(e) => {
                        setHasInsuranceCost(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasInsuranceCost' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.EDIT_RECEIPT')}
                      checked={hasEditBill || false}
                      onChange={(e) => {
                        setHasEditBill(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasEditBill' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.CANCEL_VISIT')}
                      checked={hasCancelVisit || false}
                      onChange={(e) => {
                        setHasCancelVisit(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelVisit' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.MANAGE_TREATMENT_COSTS')}
                      checked={hasChangeTreatmentCost || false}
                      onChange={(e) => {
                        setHasChangeTreatmentCost(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasChangeTreatmentCost' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.RESET_RECEIPT')}
                      checked={hasResetBill || false}
                      onChange={(e) => {
                        setHasResetBill(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasResetBill' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.CANCEL_TREATMENT_QUEUE')}
                      checked={hasCancelTreatmentQueue || false}
                      onChange={(e) => {
                        setHasCancelTreatmentQueue(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelTreatmentQueue' }))
                      }}
                    />
                  </Col>

                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.SHOW_PATIENT_PHONE_NUMBER')}
                      checked={hasShowPhoneNumber || false}
                      onChange={(e) => {
                        setHasShowPhoneNumber(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasShowPhoneNumber' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.CANCEL_RECEIPT')}
                      checked={hasCancelBill || false}
                      onChange={(e) => {
                        setHasCancelBill(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelBill' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <Box display="flex" alignItems="center" sx={{ '&.Mui-disabled.MuiCheckbox-root': { opacity: '1 !important' }, '.checkbox': { backgroundColor: isPackage?.package === 'STANDARD' ? '#E5E5E5' : '' } }}>
                      <InputCheckbox
                        label={t('PERSONNEL.FORM.CANCEL_LAB_FORM')}
                        checked={isPackage?.package === 'STANDARD' ? false : hasCancelLabOrder || false}
                        onChange={(e) => {
                          setHasCancelLabOrder(e.target.checked)
                          dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasCancelLabOrder' }))
                        }}
                        disabled={isPackage?.package === 'STANDARD'}
                      />
                      <Tooltip title={t('ฟังก์ชันนี้สามารถ ใช้งานได้ในแพคเกจ PREMIUM และ ULTRA')} placement='top-start'>
                        <Box>
                          <IconClinicDisabeld />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.APPROVE_INCOME_EXPENSES')}
                      checked={hasApproveIncomeAndExpenses || false}
                      onChange={(e) => {
                        setHasApproveIncomeAndExpenses(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasApproveIncomeAndExpenses' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.APPROVE_AMOUNT_CHANGE')}
                      checked={hasApproveTheAmountChange || false}
                      onChange={(e) => {
                        setHasApproveTheAmountChange(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasApproveTheAmountChange' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.EDIT_MANAGE_ADVANCE_PAYMENTS')}
                      checked={hasManageAdvances || false}
                      onChange={(e) => {
                        setHasManageAdvances(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasManageAdvances' }))
                      }}
                    />
                  </Col>
                  {/* <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.EDIT_DRUGS_AND_PRODUCTS_PRICE')}
                      checked={hasChangePrice || false}
                      onChange={(e) => {
                        setHasChangePrice(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasChangePrice' }))
                      }}
                    />
                  </Col>
                  <Col sm={4} className="px-2">
                    <InputCheckbox
                      label={t('PERSONNEL.FORM.EDIT_DISCOUNTS_AND_SURCHARGES')}
                      checked={hasChangeDiscountAdditional || false}
                      onChange={(e) => {
                        setHasChangeDiscountAdditional(e.target.checked)
                        dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasChangeDiscountAdditional' }))
                      }}
                    />
                  </Col> */}
                  {/* <Col sm={4} className="px-2">
                <InputCheckbox
                  label={'ตีกลับรายการ LAB'}
                  checked={hasReplyLabOrder || false}
                  onChange={(e) => {
                    setHasReplyLabOrder(e.target.checked)
                    dispatch(editUser({ value: e.target.checked ? 'PERMISS' : 'UNPERMISS', key: 'hasReplyLabOrder' }))
                  }}
                />
              </Col> */}
                </Row>
              </Col>
            </AccordionDetails>
          </Accordion>
        </Row>
      </Box>
    </div >
  )
}
