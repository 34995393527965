import { Box } from '@mui/material'
import PromotionsApi from 'api/promotion/promotion.api'
import { resetModal, showModal } from 'app/slice/modal.slice'
import { swalActive, swalCustom } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import ButtonExport from 'component/Button/ButtonExport'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import Loading from 'component/Loading'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableRowExport from 'component/Pdf/TableRowExport'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import _ from 'lodash'
import MainButton from 'new-components/buttons/main-button'
import AlertModal from 'new-components/modal/alert-modal'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

const PromotionType = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [load, setLoad] = useState<boolean>(false)

    const [dataPromotion, setDataPromotion] = useState<any>([])
    const [dataPromotionExport, setDataPromotionExport] = useState<any>([])

    const styleSelector = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }
    const statusOption = [
        { status: 'ACTIVE' }, { status: 'INACTIVE' },
    ]
    const [statusSelect, setStatusSelect] = useState<string>('')

    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('promotionTypeId')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')

    const [popProType, setPopProType] = useState<boolean>(false)
    const [promotionTypeId, setPromotionTypeId] = useState<number>(0)
    const [promotionTypeStatus, setPromotionTypeStatus] = useState<'ACTIVE' | 'INACTIVE'>('ACTIVE')
    const [promotionTypeName, setPromotionTypeName] = useState<string>('')
    const [promotionTypeNameEn, setPromotionTypeNameEn] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<any>({})

    const componentExportRef = useRef<HTMLDivElement>(null)

    const [isAlertDel, setIsAlertDel] = useState<number>(0)

    const loadPromotion = async (checkExport?: boolean) => {
        setLoad(true)
        const condition = {
            page: page,
            pageLimit: pageLimit,
            SEARCH: search,
            sortBy: sortBy,
            sortType: sortType,
            STATUS: statusSelect,
            EXPORT: checkExport ? '1' : '0'
        }

        await PromotionsApi.findAllType(condition).then((res) => {
            if (checkExport) {
                setDataPromotionExport(res?.data)
            } else {
                setRowCount(res?.headers['x-total'] || 0)
                setDataPromotion(res?.data)
            }

        }).finally(() => {
            setLoad(false)
        })
        setLoad(false)
    }

    useEffect(() => {
        loadPromotion(true)
    }, [])

    useEffect(() => {
        loadPromotion()
    }, [page, pageLimit, sortBy, sortType])

    const onCreate = () => {
        setPopProType(true)
        dispatch(showModal())
    }

    const onEdit = (obj: any) => {
        setPopProType(true)
        dispatch(showModal())
        setPromotionTypeId(obj.promotionTypeId)
        setPromotionTypeName(obj.promotionTypeName)
        setPromotionTypeNameEn(obj.promotionTypeNameEn)
        setPromotionTypeStatus(obj.status)
    }

    const onActive = async (id: number, valueStatus: "ACTIVE" | "INACTIVE", data: any) => {
        const renderSwal = () => {
            swalActive(
                `<p class="mb-0 mx-auto" style="max-width: 325px">${t('OPERATIVE.ALERT.CONFIRM_STATUS')}</p>`,
                null, (res: any) => {
                    if (res) {
                        PromotionsApi.chagneStatusPromotionType(id, valueStatus)
                            .then((resp) => {
                                notiSuccess(t('OPERATIVE.MESSAGE.SUCCESS.UPDATE_STATUS'))
                                loadPromotion(true)
                                loadPromotion()
                            })
                            .catch((error) => {
                                const errMsg = error?.response?.data?.message
                                if (errMsg === "THIS_PROMOTION_TYPE_IS_BEING_USED") {
                                    notiError(t('PROMOTION_TYPE.MESSAGE.ERROR.THIS_PROMOTION_TYPE_IS_BEING_USED'))
                                } else {
                                    notiError(t('APPROVAL.MESSAGE.ERROR.ERROR'))
                                }
                            })
                    }
                }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
        }

        renderSwal()
    }

    const onDelete = async (id: number) => {
        await PromotionsApi.chagneStatusPromotionType(id, "DELETED")
            .then((resp) => {
                notiSuccess(t('PROMOTION_TYPE.MESSAGE.DEL_SUCCESS'))
                setIsAlertDel(0)
                loadPromotion(true)
                loadPromotion()
            })
            .catch((error) => {
                const errMsg = error?.response?.data?.message
                if (errMsg === "THIS_PROMOTION_TYPE_IS_BEING_USED") {
                    notiError(t('PROMOTION_TYPE.MESSAGE.ERROR.THIS_PROMOTION_TYPE_IS_BEING_USED'))
                } else {
                    notiError(t('APPROVAL.MESSAGE.ERROR.ERROR'))
                }
            })

    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => setPage(val)

    const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '50px', align: 'center', },
        { id: 'promotionTypeName', disablePadding: false, label: t('PROMOTION_TYPE.TABLE.PROMOTION_TYPENAME'), align: 'left', },
        { id: 'promotionTypeNameEn', disablePadding: false, label: t('PROMOTION_TYPE.TABLE.PROMOTION_TYPENAMEEN'), align: 'left', },
        { id: 'status', disablePadding: false, label: t('APPLICATION.TABLE.CELL.STATUS'), align: 'center', width: '150px' },
        { id: 'updatedBy', disablePadding: false, label: t('APPLICATION.TABLE.CELL.UPDATED_BY'), align: 'center', width: '180px' },
        { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
    ]

    const renderData = (objData: any, index: number, isExport?: boolean) => {
        if (isExport) {
            index = index + 1
        } else {
            index = page * pageLimit - pageLimit + index + 1
        }
        const { promotionTypeId, promotionTypeName, promotionTypeNameEn, updatedBy, updatedAt, createdAt, status } = objData

        const objRenderData = {
            key: promotionTypeId,
            id: promotionTypeId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index },
                { option: 'TEXT', align: 'left', label: promotionTypeName || '' },
                { option: 'TEXT', align: 'left', label: promotionTypeNameEn || '' },
                { option: 'STATUS', align: 'center', label: status },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: status === 'ACTIVE' },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: status === 'INACTIVE' },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
                    ]
                },
            ]
        }

        if (isExport) {
            return <TableRowExport {...objRenderData} />
        }

        return (
            <TableRowCommon
                {...objRenderData}
                onedit={() => onEdit(objData)}
                ondelete={() => setIsAlertDel(promotionTypeId)}
                onactive={() => onActive(Number(objRenderData.id), 'ACTIVE', objData)}
                oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE', objData)}
            />
        )
    }

    const clearErrorMessage = () => {
        setErrorMessage({})
    }

    const resetForm = () => {
        setPopProType(false)
        setPromotionTypeId(0)
        setPromotionTypeName('')
        setPromotionTypeNameEn('')
        setPromotionTypeStatus('ACTIVE')
        clearErrorMessage()
    }

    const onSubmit = async () => {
        setLoad(true)
        if (!promotionTypeName || !promotionTypeNameEn) {
            setErrorMessage({
                PROMOTIONTYPENAME: !promotionTypeName && t('PROMOTION_TYPE.MESSAGE.ERROR.PROMOTION_TYPENAME'),
                PROMOTIONTYPENAMEEN: !promotionTypeNameEn && t('PROMOTION_TYPE.MESSAGE.ERROR.PROMOTION_TYPENAMEEN')
            })
            setLoad(false)
            return
        }

        if (promotionTypeName?.length > 255 || promotionTypeNameEn?.length > 255) {
            notiError(t('PROMOTION_TYPE.MESSAGE.ERROR.MAX_LENGTH'))
            setLoad(false)
            return
        }

        if (promotionTypeId) {
            const newPayload = {
                promotionTypeName: promotionTypeName,
                promotionTypeNameEn: promotionTypeNameEn,
                status: promotionTypeStatus
            }
            await PromotionsApi.updatePromotionType(newPayload, promotionTypeId).then((res) => {
                notiSuccess(t('PROMOTION_TYPE.MESSAGE.EDIT_SUCCESS'))
                dispatch(resetModal())
                resetForm()
                loadPromotion(true)
                loadPromotion()
            }).catch((error) => {
                const errMsg = error?.response?.data?.message
                if (errMsg) {
                    if (errMsg === "THIS_PROMOTION_TYPE_IS_BEING_USED") {
                        notiError(t('PROMOTION_TYPE.MESSAGE.ERROR.THIS_PROMOTION_TYPE_IS_BEING_USED'))
                        return
                    }
                    setErrorMessage({
                        PROMOTIONTYPENAME: errMsg === "IS_DUPLICATE_TYPE_NAME" && t('PROMOTION_TYPE.MESSAGE.ERROR.IS_DUPLICATE_TYPE_NAME'),
                        PROMOTIONTYPENAMEEN: errMsg === "IS_DUPLICATE_TYPE_NAME_EN" && t('PROMOTION_TYPE.MESSAGE.ERROR.IS_DUPLICATE_TYPE_NAME_EN')
                    })
                } else {
                    notiError(t('PROMOTION_TYPE.MESSAGE.ERROR_'))
                }

            }).finally(() => {
                setLoad(false)
            })
        } else {
            const newPayload = {
                promotionTypeName: promotionTypeName,
                promotionTypeNameEn: promotionTypeNameEn
            }
            await PromotionsApi.createPromotionType(newPayload).then((res) => {
                notiSuccess(t('PROMOTION_TYPE.MESSAGE.SAVE_SUCCESS'))
                dispatch(resetModal())
                resetForm()
                loadPromotion(true)
                loadPromotion()
            }).catch((error) => {
                const errMsg = error?.response?.data?.message
                if (errMsg) {
                    if (errMsg === "THIS_PROMOTION_TYPE_IS_BEING_USED") {
                        notiError(t('PROMOTION_TYPE.MESSAGE.ERROR.THIS_PROMOTION_TYPE_IS_BEING_USED'))
                        return
                    }
                    setErrorMessage({
                        PROMOTIONTYPENAME: errMsg === "IS_DUPLICATE_TYPE_NAME" && t('PROMOTION_TYPE.MESSAGE.ERROR.IS_DUPLICATE_TYPE_NAME'),
                        PROMOTIONTYPENAMEEN: errMsg === "IS_DUPLICATE_TYPE_NAME_EN" && t('PROMOTION_TYPE.MESSAGE.ERROR.IS_DUPLICATE_TYPE_NAME_EN')
                    })
                } else {
                    notiError(t('PROMOTION_TYPE.MESSAGE.ERROR_'))
                }

            }).finally(() => {
                setLoad(false)
            })
        }
    }

    return (
        <>
            <Box>
                <Loading show={load} />
                <AlertModal
                    isOpen={!!isAlertDel}
                    title={t('PROMOTION_TYPE.MESSAGE.DEL_TITLE')}
                    message={t('PROMOTION_TYPE.MESSAGE.DEL_SUBTITLE', dataPromotion.map((item: any) => ({ ...item, promotionTypeNameEn: !item?.promotionTypeNameEn ? '' : `(${item?.promotionTypeNameEn})` })).find((item: any) => Number(item.promotionTypeId) === Number(isAlertDel)))}
                    type="error"
                    onSubmit={{ onClick: () => onDelete(isAlertDel), title: t("BUTTON.SUBMIT") }}
                    onCancel={{
                        onClick: () => setIsAlertDel(0), title: t("BUTTON.CANCEL")
                    }}
                    handleClose={() => setIsAlertDel(0)}
                />

                <Card className="border-0 h-100">
                    <HeaderCard text={t('PROMOTION_TYPE.TITLE')} />
                    <Card.Body className="overflow-auto">
                        <Card.Title className={'mb-0 pl-xl-3'}>
                            <Box className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                                <Box className="d-flex align-items-center gap-2" sx={{ width: { md: 'calc(100% - 380px)', xs: '100%' } }}>
                                    <Box sx={{ width: { md: '36%', xs: '100%' } }}>
                                        <InputTextField
                                            label={t('PROMOTION_TYPE.SEARCH')}
                                            value={search}
                                            onchange={(e) => {
                                                setSearch(e.target.value)
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: { md: '36%', xs: '100%' } }}>
                                        <AutocompleteSelect
                                            labelId="status"
                                            options={["ALL", ...statusOption]}
                                            getOptionLabel={(option) => `${t('APPROVELLIST.FILTER.STATUS')}: ${option.status ? t(`STATUS.${option.status}`) : t('ALL')} `}
                                            renderOption={(props, option) => {
                                                return (
                                                    <ListFilterDoctors {...props} component="li" sx={styleSelector}>
                                                        {option === "ALL" ? t('ALL') : t(`STATUS.${option.status}`)}
                                                    </ListFilterDoctors>
                                                )
                                            }}
                                            onchange={(e, value) => {
                                                setStatusSelect(value?.status || '')
                                            }}
                                            noOptionsText={t('NOT_FOUND')}
                                            filterOptions={(option) => t(`STATUS.${option?.status || ''}`)}
                                            value={_.find(statusOption, { status: statusSelect }) || ''}
                                            height={43}
                                            disableClearable={!statusSelect && statusSelect !== 'ALL'}
                                        />
                                    </Box>
                                    <Box sx={{ width: 'fit-content' }}>
                                        <MainButton
                                            style={{ width: '100px' }}
                                            onClick={() => {
                                                setPage(1)
                                                loadPromotion()
                                                loadPromotion(true)
                                            }}
                                            title={t('INPUT.SEARCH')}
                                            type={'primary'}
                                            variant={'contained'}
                                        />
                                    </Box>
                                </Box>
                                <Box className="d-flex align-items-center gap-2" sx={{ width: 'fit-content' }}>
                                    <Box>
                                        <ButtonExport
                                            headCells={headCells}
                                            portrait={true}
                                            filter={''}
                                            fileName={t('PROMOTION_TYPE.TABLE.PROMOTION_TYPENAME')}
                                            rowsData={dataPromotionExport?.length ? dataPromotionExport?.map((item: any, index: number) => {
                                                return renderData(item, index, true)
                                            }) : []}
                                            marginPrint={'0.5cm'}
                                            componentExportRef={componentExportRef}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonCustom
                                            mode="add"
                                            disabled={permissions.isCreate.disabled}
                                            onClick={() => onCreate()}
                                            textButton={t('PROMOTION_TYPE.BUTTON.CREATE_PROMOTION')}
                                            className="w-auto ml-auto d-flex mt-auto"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Card.Title>

                        <Box className={'mt-3 pl-xl-3 pr-xl-2'}>
                            <TableCustom
                                page={page}
                                pageLimit={pageLimit}
                                sortType={sortType}
                                sortBy={sortBy}
                                onSort={onRequestSort}
                                setPageLimit={handleChangeRowsPerPage}
                                setPage={handleChangePage}
                                rowCount={rowCount}
                                headCells={headCells}
                                rowsData={dataPromotion?.length ? dataPromotion?.map((item: any, index: number) => {
                                    return renderData(item, index)
                                }) : []}
                            />
                        </Box>
                    </Card.Body>
                </Card>
            </Box>

            {popProType &&
                <ModalCustom
                    title={!promotionTypeId ? t('PROMOTION_TYPE.TITLE_CREATE') : t('PROMOTION_TYPE.TITLE_UPDATE')}
                    modalStyle={'modal-promotion-type'}
                    component={
                        <div className="pb-2">
                            {promotionTypeId ? (
                                <div>
                                    <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                                        <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            onChange={() => setPromotionTypeStatus(promotionTypeStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                                            checked={promotionTypeStatus === 'ACTIVE' ? true : false}
                                            style={{ marginLeft: 'auto' }}
                                        />
                                    </Form>
                                </div>
                            ) : (
                                ''
                            )}

                            <InputTextField
                                onchange={(event) => {
                                    setPromotionTypeName(event.target.value)
                                    clearErrorMessage()
                                }}
                                value={promotionTypeName}
                                label={t('PROMOTION_TYPE.TABLE.PROMOTION_TYPENAME')}
                                helperText={
                                    errorMessage.PROMOTIONTYPENAME ||
                                    errorMessage.IS_DUPLICATE_PROMOTIONTYPENAME
                                }
                                required
                                style={{ marginBottom: '1rem' }}
                            />
                            <InputTextField
                                onchange={(event) => {
                                    setPromotionTypeNameEn(event.target.value)
                                    clearErrorMessage()
                                }}
                                value={promotionTypeNameEn}
                                helperText={
                                    errorMessage.PROMOTIONTYPENAMEEN ||
                                    errorMessage.IS_DUPLICATE_PROMOTIONTYPENAMEEN
                                }
                                label={t('PROMOTION_TYPE.TABLE.PROMOTION_TYPENAMEEN')}
                                required
                            />


                        </div>
                    }
                    alignFooter='end'
                    onReset={resetForm}
                    onSubmit={onSubmit}
                    textBtnCancel={t('TREATMENT_COURSE_TYPE.BUTTON.CANCEL')}
                    textBtnConfirm={t('TREATMENT_COURSE_TYPE.BUTTON.SAVE')}
                />
            }
            <Box>
                <Box className={`print-show`} ref={componentExportRef}>
                    <Box fontSize={'10px'} fontWeight={'500'}>{t('รายการประเภทโปรโมชัน')}</Box>
                    <Box fontSize={'10px'} fontWeight={'500'} display={'flex'} gap={'8px'} marginBottom={'8px'}>
                        <span>
                            {t('ค้นหา')}: {search || '-'}
                        </span>
                        <span>
                            {t('สถานะ')}: {_.find(statusOption, { status: statusSelect })?.status ? t(`STATUS.${_.find(statusOption, { status: statusSelect })?.status}`) : '-'}
                        </span>
                    </Box>
                    <Box
                        width={'100%'}
                        marginBottom={'8px'}
                        sx={{
                            '.no-link': { display: 'flex', justifyContent: 'center', gap: '4px' },
                            'th,td': {
                                fontSize: '8px',
                                margin: '0',
                                padding: '1px',
                                borderBottom: '1px solid black',
                                borderTop: '1px solid black',
                                textAlign: 'center',
                                ':last-child': {
                                    borderRight: 0,
                                }
                            }
                        }}
                    >
                        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                            <thead>
                                {_.map(headCells.filter((headCell: any) => headCell.id !== 'action' && headCell.id !== 'COMPONENT'), (val: any, index: number) => {
                                    return (
                                        <th
                                            style={{
                                                textAlign: val.align ? val.align : 'left',
                                                fontSize: 8,
                                                fontWeight: 500
                                            }}>
                                            {val.label || t(val.labelKey)}
                                        </th>
                                    )
                                })}
                            </thead>
                            <tbody>
                                {dataPromotionExport?.length ? dataPromotionExport?.map((item: any, index: number) => {
                                    return renderData(item, index, true)
                                }) : []}
                                {!dataPromotionExport?.length && (
                                    <td colSpan={headCells.length}>
                                        {t('NOT_FOUND')}
                                    </td>
                                )}
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default PromotionType