import { Button } from "@mui/material"

interface TestButtonProps {
    onClick: () => void
    title: string
    type: 'primary' | 'danger' | 'secondary'
    variant: 'contained' | 'outlined'
    disabled?: boolean
    style?: any
    startIcon?: any
    endIcon?: any
    borderRadius?: string
}

export default function MainButton(props: TestButtonProps) {

    ////-------------------
    ////    HANDLE
    ////-------------------
    const handleColor = () => {
        if (props.variant === 'contained') {
            switch (props.type) {
                case 'primary':
                    return {
                        height: '40px',
                        background: '#1CB99A',
                        '&:hover': {
                            background: '#1BA58A',
                        },
                        '&:active': {
                            background: '#009073',
                        },
                        '&:disabled': {
                            background: '#E0E0E0',
                            color: '#808080',
                        },
                    }
                case 'danger':
                    return {
                        height: '40px',
                        background: '#FB5252',
                        '&:hover': {
                            background: '#E24A4A',
                        },
                        '&:active': {
                            background: '#B00020',
                        },
                        '&:disabled': {
                            background: '#E0E0E0',
                            color: '#808080',
                        },
                    }
                default:
                    break;
            }
        } else {
            switch (props.type) {
                case 'primary':
                    return {
                        height: '40px',
                        borderColor: '#1CB99A',
                        borderRadius: props.borderRadius,
                        color: '#1CB99A',
                        '&:hover': {
                            borderColor: '#1CB99A',
                            background: '#F1FBF9',
                        },
                        '&:active': {
                            background: '#D1F1EB',
                        },
                        '&:disabled': {
                            background: '#E0E0E0',
                            color: '#808080',
                        },
                    }
                case 'secondary':
                    return {
                        height: '40px',
                        borderColor: '#5A5A5A',
                        color: '#5A5A5A',
                        '&:hover': {
                            borderColor: '#5A5A5A',
                            background: '#F7F8F8',
                        },
                        '&:active': {
                            background: '#E5E5E5',
                        },
                        '&:disabled': {
                            background: '#E0E0E0',
                            color: '#808080',
                        },
                    }
                case 'danger':
                    return {
                        height: '40px',
                        borderColor: '#FB5252',
                        color: '#FB5252',
                        '&:hover': {
                            borderColor: '#FB5252',
                            background: '#FFF0F2',
                        },
                        '&:active': {
                            background: '#FFCDD3',
                        },
                        '&:disabled': {
                            background: '#E0E0E0',
                            color: '#808080',
                        },
                    }
                default:
                    break;
            }
        }
    }

    return (
        <Button disableRipple onClick={props.onClick} variant={props.variant || 'contained'} sx={handleColor()} style={props.style} disabled={props.disabled} startIcon={props.startIcon} endIcon={props.endIcon}  >{props.title || 'ค้นหา'}</Button>
    )
}