import { Box, TableCell, TableRow, Typography, Grid } from '@mui/material'
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import TreatmentApi from 'api/dentists/treatment.api'
import TxInformationBox from 'component/Treatments/TxInformationBox'
import { IconAddBox, IconAddBoxDim } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import AddBoxIcon from '@mui/icons-material/AddBox'
import _ from 'lodash'
import { getBranch, numberFormat } from 'utils/app.utils'
import { dateTimeToView } from 'utils/date.utils';
import ClinicApi from 'api/master/clinic.api'
import { Col, Row } from 'react-bootstrap'
import ButtonCustom from 'component/Button/ButtonCustom'
import { styled } from '@mui/material/styles'
import PopupCreateByUsers from 'component/Treatments/PopupCreateByUsers'
import { useDispatch, useSelector } from 'react-redux'
import useWindowSize from 'utils/useWindowSize'
import Loading from 'component/Loading'

/** UTILS || SLICE */
import {
  treatment,
  setTreatmentByKey,
  TreatmentStateInterface,
  setColorToothChart,
  setSubmitTootChart,
  setSummeryTreatments,
} from 'app/slice/treatment.slice';
import { swalDelete } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import PopupTreatmentCouse from 'component/Treatments/PopupTreatmentCourse'
import { resetModal, showModal } from 'app/slice/modal.slice';
import PopupTreatmentHistoryCourses from 'component/Treatments/PopupTreatmentHistoryCourses';
import OperativeApi from 'api/setting/treatments/operative.api';
import OperativeTypeApi from 'api/setting/treatments/operative-type.api';
import TreatmentProductApi from 'api/dentists/treatmentProduct.api';
import InputTextField from 'component/Input/InputTextField';
import { useLocation } from 'react-router-dom';
import { WrapText } from '@mui/icons-material';
import { selectMe } from 'app/slice/user.slice';

export const Item = styled(Box)(({ theme }) => ({
  margin: '0 -0.5rem -1rem',
  maxHeight: 400,
  overflowY: 'auto'
}))

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: 'unset',
    maxWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 972,
    },
  },
}))

type TxpType = {
  userId?: number
  patient?: any
  history?: boolean
  hasCreateTreatmentCourse?: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatmentCourse?: 'PERMISS' | 'UNPERMISS'
  type?: any
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}
interface IFPatientTreatmentCourseItem {
  balance: number
  createdAt: string
  createdBy: string
  id: number
  itemCode: string
  color: string
  itemId: number
  itemName: string
  itemNameEn: string
  itemTypeId: number
  itemTypeName: string
  itemTypeNameEn: string
  itemUnitId: number
  itemUnitName: string
  itemUnitNameEn: string
  patientTreatmentCourseId: number
  price: number
  qty: number
  total: number
  type: "MEDICINE" | "PRODUCT" | "OPERATIVE"
  updatedAt: string
  updatedBy: string
  use: number
  lastPaid: number
  lastBalance: number
  itemSort: number
}

interface IFPatientTreatmentCourse {
  balance: number
  createdAt: string
  createdBy: string
  paid: number
  patientCaseId: number
  patientId: number
  patientTreatmentCourseId: number
  patientTreatmentCourseItems: IFPatientTreatmentCourseItem[]
  use: number
  lastPaid: number
  lastBalance: number
}

export default function TXC(props: TxpType) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const [txc, setTxc] = useState<IFPatientTreatmentCourse[]>([])
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [header, setHeader] = useState<any>('')
  const [edit, setEdit] = useState<any>('')
  const [operativeTypes, setOperativeTypes] = useState([])
  const [opertatives, setOperative] = useState<any[]>([])
  const [operativeType, setOperativeType] = useState<any[]>([])
  const [width] = useWindowSize()
  const [dataRecord, setDataRecord] = useState<any>('')
  const [createByUsers, setCreateByUsers] = useState(false)
  const [createTemplate, setCreateTemplate] = useState(false)
  const [txcHistory, setTxcHistory] = useState(false)
  const [loading, setLoading] = useState(true);
  const location = useLocation()
  const user = useSelector(selectMe)

  const [popHisPayCourese, setPopHisPayCourese] = useState<boolean>(false)
  const [valHisPayCourese, setValHisPayCourese] = useState<any>({})

  const [txcMinSort, setTxcMinSort] = useState<number[]>([])

  const branchId = getBranch()

  const loadData = useCallback(async () => {
    const res = await TreatmentApi.getTxc(props.patient.patientId).finally(() => setLoading(false))
    if (res.status === 200) {
      setTxc(res.data);
      updateLastBalance(res.data)
    }
  }, [props.patient.patientId, treatments.summeryTreatments])

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors().finally(() => setLoading(false))
    if (resp.status === 200) setDoctors(resp.data)
  }

  const loadDataOperativeType = useCallback(async () => {

    OperativeApi.findAll({ export: 1 }).then(({ data }) => {
      setOperative(() => {
        const newData: any = data.map((item: any) => {
          const mapData = {
            itemCode: item?.operativeCode,
            itemId: item?.operativeId,
            itemName: item?.operativeName,
            itemNameEn: item?.operativeNameEn,
            itemTypeId: item?.operativeTypeId,
            itemTypeName: item?.operative_type?.operativeTypeName,
            itemTypeNameEn: item?.operative_type?.operativeTypeNameEn,
            itemUnitName: item?.operative_unit?.operativeUnitName,
            itemUnitNameEn: item?.operative_unit?.operativeUnitNameEn,
            price: item.salePrice,
          }
          return mapData
        })
        return newData
      })
    }).catch((e) => { return })

    OperativeTypeApi.findAll({ export: 1 }).then(({ data }) => {
      setOperativeType(() => {
        const newData: any = data.map((item: any) => {
          const mapData = {
            itemTypeId: item.operativeTypeId,
            itemTypeName: item.operativeTypeName,
            itemTypeNameEn: item.operativeTypeNameEn
          }
          return mapData
        })
        return newData
      })
    }).catch((e) => { return })

    const res = await TreatmentApi.getTxcTypes().finally(() => setLoading(false))
    if (res.status === 200) {
      setOperativeTypes(res.data)
    }
  }, [])

  useEffect(() => {
    loadDataOperativeType()
  }, [])

  useEffect(() => {
    loadDoctor()
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    updateLastBalance(txc || [])
  }, [treatments.summeryTreatments])

  const updateLastBalance = (lastBalanceTxc: any) => {
    _.forEach(lastBalanceTxc, function (c: IFPatientTreatmentCourse, iMain: number) {
      let sumUse = 0
      let lastPaid = 0
      _.forEach(c.patientTreatmentCourseItems, function (tx: IFPatientTreatmentCourseItem, iSub: number) {
        const use = (_.sumBy(_.filter(treatments.summeryTreatments, (i) => { return i.refType === 'COURSE' && Number(i.refId) === Number(tx.id) }), 'qty') || 0)
        let balance = tx.balance - use
        if (balance < 0) balance = 0
        tx.lastBalance = balance
        tx.use = use
        lastPaid += ((tx.qty - balance) * tx.price)
        lastBalanceTxc[iMain].patientTreatmentCourseItems[iSub] = tx
        sumUse += use
      });
      lastBalanceTxc[iMain].use = lastBalanceTxc[iMain].balance - sumUse
      lastBalanceTxc[iMain].lastPaid = lastPaid
      const patientTreatmentCourseItems = lastBalanceTxc[iMain].patientTreatmentCourseItems
      lastBalanceTxc[iMain].lastBalance = _.sumBy(patientTreatmentCourseItems, 'lastBalance')
      setTxc((value) => [...lastBalanceTxc])
    });

  }

  const popupCreateTemplate = () => {
    setCreateByUsers(true)
    setCreateTemplate(true)
    setHeader(t('TXC.POPUP.HEADER_TEMPLATE'))
  }

  const popupCreateByUsers = () => {
    setCreateByUsers(true)
    setCreateTemplate(false)
    setHeader(t('TXC.POPUP.HEADER_BY_USER'))
  }

  const onEdit = (val: any) => {
    setEdit(val)
    setHeader(t('TXC.POPUP.HEADER_EDIT'))
    setCreateByUsers(true)
    setCreateTemplate(false)
  }

  const upDateStatus = (data: any, type: string) => {
    const { patientTreatmentCourseId, treatmentCourseName, createdAt } = data
    const status = { status: type }
    swalDelete(
      `${t('TXC.MESSAGE.DEL_TITLE')}`,
      `${t('TXC.MESSAGE.DEL_SUB')}${treatmentCourseName} ${dateTimeToView(createdAt)}${t('TXC.MESSAGE.DEL_YESNO')}`,
      (res: any) => {
        if (res) {
          TreatmentApi.upDatePatientTreatmentCourses(patientTreatmentCourseId, status)
            .then((resp) => {
              notiSuccess(t('TXC.MESSAGE.DEL_SUCCESS'), '', null, null, !props.history ? 'register-page' : '')
              loadData()
            })
            .catch((e) => {
              notiError(t('TXC.MESSAGE.DEL_FAIL'), '', null, null, !props.history ? 'register-page' : '')
            })
        }
      },
      { confirmButtonText: t('TXC.MESSAGE.DEL_LIST'), cancelButtonText: (t('BUTTON.CANCEL')) }
    )
  }

  const onShowRecord = (val: any) => {
    setDataRecord(val.patientTreatmentCourseId)
    setHeader(`${t('TXC.TITLE_HISTORY')} ${val.treatmentCourseName} `)
    setTxcHistory(true)
  }

  const onShowHistoryPaymentCourses = (val: any) => {

    setValHisPayCourese(val)
    setPopHisPayCourese(true)
    dispatch(showModal())
  }

  const onCloseShowHistoryPaymentCourses = () => {
    dispatch(resetModal())
    setValHisPayCourese({})
    setPopHisPayCourese(false)
  }

  const defaultHeadCells = [
    { id: 'no', disablePadding: false, align: 'center', label: '#', width: width >= 1200 ? '5%' : '50px' },
  ]

  const ItemsHeadCells = [
    { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.CODE') },
    { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.DETAIL'), width: '30%' },
    { id: 'sort', disablePadding: false, label: t('TREATMENT_COURSE.FORM.SUMMARY.TABLE.ORDER'), align: 'center', width: '150px' },
    { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.ALL_TOTAL') },
    { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.QTY') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TXC.TABLE.PRICE') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TXC.TABLE.TOTAL'), class: `${props.history ? 'pr-3' : ''}` },
  ]

  const headCells = width >= 1200 ? [
    ...defaultHeadCells,
    ...ItemsHeadCells,
    { id: 'message', disablePadding: false, align: 'center', label: t('Action'), width: '7%' }] : [
    ...defaultHeadCells,
    { id: 'message', disablePadding: false, align: 'left', label: width >= 768 ? t('TXC.TABLE.CODE') : '' },
    { id: 'message', disablePadding: false, align: 'center', label: t('Action'), width: '90px' }]

  const headCellsHistory = width >= 1200 ? [
    ...defaultHeadCells,
    ...ItemsHeadCells
  ] : [
    ...defaultHeadCells,
    { id: 'message', disablePadding: false, align: 'left', label: width >= 768 ? t('TXC.TABLE.CODE') : '' }
  ]

  const handleAddOperative = async (tx: IFPatientTreatmentCourseItem, iMain: number, iSub: number) => {
    let item = null

    if (tx.type === 'OPERATIVE') {
      const treatmentItem = {
        operativeCode: tx.itemCode,
        operativeId: tx.itemId,
        operativeName: tx.itemName,
        operativeNameEn: tx.itemNameEn,
        operativeTypeId: tx.itemTypeId,
        operativeTypeName: tx.itemTypeName,
        operativeTypeNameEn: tx.itemTypeNameEn,
        operativeUnitName: tx.itemUnitName,
        operativeUnitNameEn: tx.itemUnitNameEn,
        tempOrderTreatmentId: null,
        teeth: '',
        teethJson: '',
        qty: 1,
        price: tx.price,
        doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || props.userId,
        discount: 0,
        additional: 0,
        discountUnit: 'BAHT',
        additionalUnit: 'BAHT',
        total: tx.price,
        dfAmount: 0,
        cfAmount: 0,
        dfDiscountAmount: 0,
        cfDiscountAmount: 0,
        dfDiscountUnit: 'BAHT',
        cfDiscountUnit: 'BAHT',
        note: '',
        noteNextVisit: '',
        refType: 'COURSE',
        refId: tx.id,
        patientTreatmentCourseId: tx.patientTreatmentCourseId
      }
      item = treatmentItem
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, item] }))
      dispatch(setColorToothChart({ value: tx.color }))
    }
    else {
      const index = _.findIndex(treatments.summeryTreatments, { refType: 'COURSE', refId: tx.id, })
      const findProduct = _.find(treatments.summeryTreatments, { refType: 'COURSE', refId: tx.id, })
      if (findProduct) {
        dispatch(setSummeryTreatments({ index: index, key: 'qty', value: Number(treatments.summeryTreatments[index].qty) + 1 <= tx.balance ? Number(treatments.summeryTreatments[index].qty) + 1 : tx.balance }))
        dispatch(setSummeryTreatments({ index: index, key: 'total', value: Number(treatments.summeryTreatments[index].qty) + 1 <= tx.balance ? (Number(treatments.summeryTreatments[index].qty) + 1) * tx.price : tx.balance * tx.price }))
      } else {

        const resItem = await loadTreatmentItem(tx?.type, tx?.itemTypeId, tx?.itemCode)
        const sticker = {
          itemId: resItem?.data[0]?.itemId,
          itemName: resItem?.data[0]?.itemName,
          itemNameEn: resItem?.data[0]?.itemNameEn,
          itemCode: resItem?.data[0]?.itemCode,
          image: {
            fileId: resItem?.data[0]?.image?.fileId || null,
            filePath: resItem?.data[0]?.image?.filePath || '',
            fileName: resItem?.data[0]?.image?.fileName || ''
          },
          stock: resItem?.data[0]?.qty,
          defaultProductUnitName: resItem?.data[0]?.itemUnitName || resItem?.data[0]?.itemUnitNameEn,
          dosage: 1,
          expiredDate: resItem?.data[0]?.expireDate || ''
          // itemUnitName: med.itemUnitName,
          // itemUnitNameEn: med.itemUnitNameEn,
        }
        if (location?.pathname === "/retrospective-order") {
          const newData = {
            ...tx,
            inventoryId: resItem?.data[0].inventoryId,
            qty: 1,
            total: tx.price * 1,
            doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || props?.userId || null,
            discount: 0,
            additional: 0,
            discountUnit: 'BAHT',
            additionalUnit: 'BAHT',
            note: '',
            refType: 'COURSE',
            refId: tx.id,
            patientTreatmentCourseId: tx.patientTreatmentCourseId,
            sticker: JSON.stringify(sticker)
          }
          dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, newData] }))
        } else if (props?.patient?.patientCase?.patientCaseId) {
          await TreatmentProductApi.findStickerById(props.patient.patientCase?.patientCaseId, resItem?.data[0]?.itemId, { isCreate: '1' })
            .then(({ data }) => {
              const newTemId = Math.max(...data.map((item: any) => item.tempOrderProductId))
              const product = {
                tempOrderProductId: newTemId,
                inventoryId: tx.itemId,
                itemId: resItem?.data[0]?.itemId || 0,
                itemCode: tx.itemCode,
                itemName: tx.itemName,
                itemNameEn: tx.itemNameEn,
                itemTypeId: tx.itemTypeId,
                itemTypeName: tx.itemTypeName,
                itemTypeNameEn: tx.itemTypeNameEn,
                itemUnitId: tx.itemUnitId,
                itemUnitName: tx.itemUnitName,
                itemUnitNameEn: tx.itemUnitNameEn,
                group: tx.type,
                qty: 1,
                price: tx.price,
                total: tx.price * 1,
                doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || props.userId,
                discount: 0,
                additional: 0,
                discountUnit: 'BAHT',
                additionalUnit: 'BAHT',
                note: '',
                refType: 'COURSE',
                refId: tx.id,
                patientTreatmentCourseId: tx.patientTreatmentCourseId,
                sticker: JSON.stringify(sticker)
              }
              item = product
              dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, item] }))

            })
            .catch((e) => {
              return notiError(t('OPERATIVE.MESSAGE.ERROR'))
            })
        }
      }
    }
    const use = (_.sumBy(_.filter(treatments.summeryTreatments, (i) => { return i.refType === 'COURSE' && Number(i.refId) === Number(tx.id) }), 'qty') || 0) + 1
    let balance = tx.balance - use
    if (balance < 0) balance = 0
    tx.lastBalance = balance
    tx.use = use
    txc[iMain].patientTreatmentCourseItems[iSub] = tx
    txc[iMain].use = (txc[iMain].use || 0) + use
    txc[iMain].lastPaid = (txc[iMain].lastPaid || txc[iMain].paid || 0) + (1 * tx.price)
    const patientTreatmentCourseItems = txc[iMain].patientTreatmentCourseItems
    txc[iMain].lastBalance = _.sumBy(patientTreatmentCourseItems, 'lastBalance')
    setTxc((value) => [...txc])
  }

  const loadTreatmentItem = useCallback(async (group?: string, typeId?: number, search?: string, callback?: any) => {
    let condition: any = {}
    condition = { ...condition, userId: 0 }
    condition = { ...condition, page: 1 }
    condition = { ...condition, pageLimit: 100 }
    condition = { ...condition, group }
    condition = { ...condition, branchId: branchId }
    if (search) condition = { ...condition, search: search }
    const res = await TreatmentProductApi.findAllProduct(condition)
    return res
  }, [])

  useEffect(() => {
    handleCheckSort()
  }, [txc])

  const handleCheckSort = () => {
    const newTxc = [...txc]
    if (newTxc?.length) {
      const minSort = newTxc.map((item, index) => {
        const newItem = item?.patientTreatmentCourseItems?.filter((item) => item?.itemSort) || []
        if (!newItem?.length) {
          return 0
        }
        const itemsWithZeroQty = newItem.filter(item => item.lastBalance === 0);
        if (itemsWithZeroQty.length) {
          return Number((itemsWithZeroQty?.length || 0) + 1)
        } else {
          return Math.min(...newItem.map(item => item?.itemSort || 0));
        }
      })
      setTxcMinSort(minSort)
    } else {
      setTxcMinSort([])
    }
  }

  const renderData = (data: IFPatientTreatmentCourseItem, no: number, status: any, iMain: number) => {
    let balance = data.lastBalance !== undefined ? data.lastBalance : data.balance
    if (balance < 0) balance = 0
    const hasAdd = user?.hasCreateTreatmentCourse !== 'PERMISS' && location?.pathname === "/payment/create"
    const total = balance * data.price
    return (
      <>
        <TableRow key={`diagnosis-${no}`} className="main-table-row">
          <TableCell align="center" scope="row">
            {no + 1}
          </TableCell>
          {width >= 1200 && (
            <>
              <TableCell align="center" scope="row">
                {data.itemCode || '-'}
              </TableCell>
              <TableCell align="left" scope="row">
                {data.itemName || '-'}
              </TableCell>
              <TableCell align="center" scope="row" >
                {data?.itemSort || ''}
              </TableCell>
              <TableCell align="center" scope="row">
                {data.qty || '-'}
              </TableCell>
              <TableCell align="center" scope="row">
                {balance}
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(data.price) || '-'}
              </TableCell>
              <TableCell align="right" scope="row" className={`${props.history ? 'pr-3' : ''}`}>
                {numberFormat(total) || '-'}
              </TableCell>
            </>
          ) || (
              <TableCell align="left" scope="row">
                <Grid container spacing={1}>
                  <Grid item className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.CODE')}
                      </Typography>
                      <Typography>{data.itemCode || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.DETAIL')}
                      </Typography>
                      <Typography>{data.itemName || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                        {t('TREATMENT_COURSE.FORM.SUMMARY.TABLE.ORDER')}
                      </Typography>
                      <Typography>  {data?.itemSort || ''}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.ALL_TOTAL')}
                      </Typography>
                      <Typography>{data.qty || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.QTY')}
                      </Typography>
                      <Typography>{balance}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.PRICE')}
                      </Typography>
                      <Typography>{numberFormat(data.price) || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.TOTAL')}
                      </Typography>
                      <Typography>{numberFormat(total) || '-'}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
            )}
          {!props.history && (
            <TableCell align="center" scope="row">
              {(balance === 0 || (data?.itemSort && (txcMinSort[iMain] || 0) < data?.itemSort) || hasAdd) ?
                <IconAddBoxDim key={data.id} className="p-0 ml-1" sx={{ backgroundColor: 'transparent !important' }}  >
                  <AddBoxIcon style={{ width: 30, height: 30 }} />
                </IconAddBoxDim> :
                <IconAddBox key={data.id} onClick={() => handleAddOperative(data, iMain, no)} className="p-0 ml-1" sx={{ backgroundColor: 'transparent !important' }}>
                  <AddBoxIcon style={{ width: 30, height: 30 }} />
                </IconAddBox>
              }
            </TableCell>
          )}
        </TableRow>
      </>
    )
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pb-2">
        <Row className="mx-0 w-100">
          <Col className="col-auto px-1 flex-center">
            <Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('TXC.TITLE')} ({txc?.length || 0})</Typography>
          </Col>
          {!props.history && <Col className="col-12 col-sm-8 px-1 ml-auto">
            <Row className='justify-content-md-end'>
              <Col className="col-12 col-sm-5 col-md-auto mt-2 mt-sm-0 px-2">
                <ButtonCustom disabled={props?.hasCreateTreatmentCourse === 'PERMISS' ? false : true} variant="outlined" className={'px-2 px-md-3'} btnStyle={{ height: 42 }} textButton={t('TXC.BUTTON.ADD_TEMPLATE')} style={{ fontSize: 16, width: '100%' }} onClick={popupCreateTemplate} />
              </Col>
              <Col className="col-12 col-sm-7 col-md-auto mt-2 mt-sm-0 px-2">
                <ButtonCustom disabled={props?.hasCreateTreatmentCourse === 'PERMISS' ? false : true} variant="outlined" btnStyle={{ height: 42 }} textButton={t('TXC.BUTTON.ADD_BY_USER')} style={{ fontSize: 16, width: '100%' }} onClick={popupCreateByUsers} />
              </Col>
            </Row>
          </Col>}
        </Row>
      </div>
      <Loading show={loading} type='softLoading' className='m-auto' />
      <Item className='custom-scroll' style={{ minHeight: 500 }}>
        {_.map(txc, (val: any, index: number) => {
          return (
            <TableWidth className='pt-2' >
              <TxInformationBox
                index={index}
                headCells={props.history ? headCellsHistory : headCells}
                renderData={renderData}
                titleName={val.treatmentCourseName}
                arrayTable={val.patientTreatmentCourseItems}
                status={val.status} remark={val.remark}
                createdAt={dateTimeToView(val.createdAt)}
                createdBy={val.createdBy}
                txp={false}
                onEdit={() => onEdit(val)}
                onDelete={() => upDateStatus(val, 'DELETED')}
                onShowRecord={() => onShowRecord(val)}
                onShowHistoryPaymentCourses={() => onShowHistoryPaymentCourses(val)}
                paid={val.lastPaid || val.paid}
                balance={val.lastBalance || val.balance}
                totalPrice={val.totalPrice}
                totalQty={val.totalQty}
                history={props.history}
                hasDeleteTreatmentCourse={props?.hasDeleteTreatmentCourse || 'PERMISS'}
                type={props.type}
              />
            </TableWidth>
          )
        })}
      </Item>
      {
        createByUsers && (
          <PopupCreateByUsers
            header={header}
            isShow={createByUsers}
            onSubmit={(val: 'true' | '') => {
              if (val === 'true') loadData()
            }}
            txp={false}
            doctors={doctors}
            operativeTypes={operativeTypes}
            showTemplate={createTemplate}
            patientCaseId={props.patient.patientCase?.patientCaseId}
            patientId={props.patient.patientId}
            edit={edit}
            setIsShow={(show: boolean) => {
              setCreateByUsers(show)
              setEdit('')
            }}
            operativeType={operativeType}
            opertatives={opertatives}
          />
        )
      }
      {
        txcHistory && (
          <PopupTreatmentCouse courseId={dataRecord} header={header} isShow={txcHistory} setIsShow={(show: boolean) => {
            setTxcHistory(show)
          }} />
        )
      }
      {popHisPayCourese &&
        <PopupTreatmentHistoryCourses
          onClose={onCloseShowHistoryPaymentCourses}
          val={valHisPayCourese}
        />}

    </div >
  )
}
