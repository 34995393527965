import { createSlice } from '@reduxjs/toolkit'
import PromotionsApi, { PromotionTreatmentProps } from 'api/promotion/promotion.api'
import { PDFPayloadOutStandingPaymentProps } from 'component/Treatments/PopupOutStandingPayment'
import _ from 'lodash'
import { getBranch, getCrm } from 'utils/app.utils'
import { Dispatch } from 'redux';
import { ApprovalStatus } from 'api/approval/approval.api'

export interface SummaryTreatmentsInterface {
  tempOrderTreatmentId?: number | null
  teeth?: string
  teethJson?: string
  operativeId?: number
  qty?: number
  price?: number
  doctorId?: number
  discount?: number
  discountUnit?: 'BAHT' | 'PERCENT'
  additional?: number
  additionalUnit?: 'BAHT' | 'PERCENT'
  dfAmount?: number
  dfUnit?: 'BAHT' | 'PERCENT'
  dfDiscountAmount?: number
  dfDiscountUnit?: 'BAHT' | 'PERCENT'
  dfAmountNet?: number
  cfAmount?: number
  cfDiscountAmount?: number
  cfDiscountUnit?: 'BAHT' | 'PERCENT'
  cfAmountNet?: number
  dfRemark?: string
  clinicFeeId?: number | null
  total?: number
  note?: string
  noteNextVisit?: string
  refType?: 'COURSE' | 'PLAN' | null
  refId?: number | null
  nextVisit?: NextVisitInterface
}

export interface SummaryProductsInterface {
  tempOrderProductId: number
  inventoryId: number
  group: 'PRODUCT' | 'MEDICINE' | 'ALL'
  qty: number
  price: number
  doctorId: number
  discount: number
  discountUnit: 'BAHT' | 'PERCENT'
  additional: number
  additionalUnit: 'BAHT' | 'PERCENT'
  note: string
  refType: 'COURSE' | 'PLAN' | null
  refId?: number | null
}

export interface PatientDiagnosisInterface {
  patientDiagnosisId: number
  diagnosisId: number
  doctorId: number
  note: string
}

export interface PatientRecommendsInterface {
  patientRecommendId: number
  recommendId: number
  note: string
  patientRecommends: any
}

export interface TreatmentNextVisitInterface {
  operativeTypeId?: number
  operativeId?: number
}

export interface NextVisitInterface {
  branchId?: number
  refId?: number
  date?: string
  timeStart?: string
  durationMinute?: number
  durationText?: string
  doctorId?: number
  treatment?: any
  practice?: string
  price?: number
  hasLab?: 'HAS' | 'NOT_HAS'
  hasXray?: 'HAS' | 'NOT_HAS'
  remark?: string
}

export interface TreatmentStateInterface {
  importantSymptomsDoctor: string
  teethType: 'ADULT' | 'CHILD' | ''
  toothChart?: any
  servicePointId: number
  summeryTreatments: any
  summeryProducts: SummaryProductsInterface[]
  productsState: ProductStickerType[]
  stickerState: StickerType[]
  patientDiagnosis: any
  patientRecommends: any
  outStandingPayment?: PDFPayloadOutStandingPaymentProps
}

export interface TreatmentStateType {
  importantSymptoms?: string
  importantSymptomsDoctor: string
  toothChart?: any
  teethType: 'ADULT' | 'CHILD' | ''
  servicePointId: number
  summeryTreatments?: SummaryTreatmentsInterface[]
  summeryProducts?: SummaryProductsInterface[]
  patientDiagnosis?: PatientDiagnosisInterface[]
  patientRecommends?: PatientRecommendsInterface[]
  productsState?: ProductStickerType[]
  stickerState?: StickerType[],
  overdueTotal?: number,
  overdueDate?: string,
  overdueDateStart?: string,
  patientSignature?: string,
  doctorId?: number,
  doctorName?: string,
  doctorSignature?: string,
  managerName?: string,
  managerSignature?: string
}

export interface ProductStickerType {
  sticker: string | null
  tempOrderProductId: number
  doctorId: number
  qty: number
  price: number
  discount: number
  discountUnit: "BAHT"
  additional: number
  additionalUnit: "BAHT"
  total: number
  itemId: number
}

export interface StickerType {
  logo?: string
  clinicName?: string
  branchAddress?: string
  branchTel?: string
  patientFullName?: string
  itemId?: number
  itemCode?: string
  itemName?: string
  itemNameEn?: string
  expiredDate?: string
  doctorName?: string
  image?: {
    fileId?: number
    filePath?: string
    fileName?: string
  }
  stock?: number
  defaultProductUnitName?: string
  dosage?: number

  //  -- วิธีใช้ยา/ผลิตภัณฑ์
  productInstructionId?: number
  productInstructionName?: string
  productInstructionNameEn?: string

  //  -- หน่วยยา/ผลิตภัณฑ์
  productUnitId?: number
  productUnitName?: string
  productUnitNameEn?: string

  //  -- เวลาใช้ยา/ผลิตภัณฑ์
  productUseId?: number
  productUseDetail?: string
  productUseDetailEn?: string

  //  -- อาการ
  productSymptomId?: number
  productSymptomDetail?: string
  productSymptomDetailEn?: string

  //  -- คำแนะนำเพิ่มเติม
  itemRecommend?: string
}

const initialState: any = {
  treatment: {
    importantSymptomsDoctor: '',
    teethType: 'ADULT',
    servicePointId: 0,
    summeryTreatments: [],
    summeryProducts: [],
    productsState: [],
    stickerState: [],
    patientDiagnosis: [],
    patientRecommends: []
  },
  toothChart: [],
  toothChartSelected: [],
  toothChartColor: '',
  historyToothChart: [],
  isSubmitTootChart: false,
  isEditTootChartType: '',
  isEditTootChart: null,
  newStickerProduct: [],
  toothChartPositions: [],
  approvalRight: {},
  promotionTreatments: []
}

export const treatmentSlice = createSlice({
  name: 'treatmentState',
  initialState,
  reducers: {
    setTreatment: (state: any, action: any) => {
      state.treatment = action.payload.value
    },
    setTreatmentByKey: (state: any, action: any) => {
      state.treatment[action.payload.key] = action.payload.value
    },
    setSummeryTreatments: (state: any, action: any) => {
      if (!state.treatment.summeryTreatments[action.payload.index][action.payload.key]) state.treatment.summeryTreatments[action.payload.index][action.payload.key] = null
      state.treatment.summeryTreatments[action.payload.index][action.payload.key] = action.payload.value
    },
    setProductsState: (state: any, action: any) => {
      if (!state.treatment.productsState[action.payload.index][action.payload.key]) state.treatment.productsState[action.payload.index][action.payload.key] = null
      state.treatment.productsState[action.payload.index][action.payload.key] = action.payload.value
    },
    setStickerState: (state: any, action: any) => {
      if (!state.treatment.productsState[action.payload.index][action.payload.key]) state.treatment.productsState[action.payload.index][action.payload.key] = null
      state.treatment.stickerState[action.payload.index][action.payload.key] = action.payload.value
    },
    setColorToothChart: (state: any, action: any) => {
      state.toothChartColor = action.payload.value
    },
    setToothChart: (state: any, action: any) => {
      state.toothChart[action.payload.key] = action.payload.value
    },
    setToothChartSelected: (state: any, action: any) => {
      state.toothChartSelected = action.payload
    },
    setTeethPosition: (state: any, action: any) => {
      state.toothChart[action.payload.key].position = action.payload.value
    },
    resetToothChart: (state: any,) => {
      state.toothChart = []
      state.toothChartColor = ''
    },
    setSubmitTootChart: (state: any, action: any) => {
      state.isSubmitTootChart = action.payload
    },
    setEditTootChart: (state: any, action: any) => {
      state.isEditTootChart = action.payload
    },
    setEditTootChartType: (state: any, action: any) => {
      state.isEditTootChartType = action.payload
    },
    setDiagnosisByKey: (state: any, action: any) => {
      state.treatment[action.payload.key] = action.payload.value
    },
    setDiagnosisTreatments: (state: any, action: any) => {
      state.treatment.patientDiagnosis[action.payload.index][action.payload.key] = action.payload.value
    },
    setRecommendByKey: (state: any, action: any) => {
      state.treatment[action.payload.key] = action.payload.value
    },
    setRecommendTreatments: (state: any, action: any) => {
      state.treatment.patientRecommends[action.payload.index] = action.payload.value
    },
    setHistoryToothChart: (state: any, action: any) => {
      state.historyToothChart = action.payload
    },
    setNewStickerProduct: (state: any, action: any) => {
      state.newStickerProduct = action.payload.value
    },
    setToothChartPosition: (state: any, action: any) => {
      state.toothChartPositions = action.payload
    },
    setApprovalRight: (state: any, action: any) => {
      state.approvalRight = action.payload
    },
    setPromotionTreatments: (state: any, action: any) => {
      state.promotionTreatments = action.payload
    },
  }
})
export const treatment = (state: any) => state.treatment.treatment
export const diagnosisObj = (state: any) => state.treatment.treatment
export const RecommendObj = (state: any) => state.treatment.treatment
export const toothChart = (state: any) => state.treatment.toothChart
export const toothChartColor = (state: any) => state.treatment.toothChartColor
export const toothChartSelected = (state: any) => state.treatment.toothChartSelected
export const isSubmitTootChart = (state: any) => state.treatment.isSubmitTootChart
export const isEditTootChart = (state: any) => state.treatment.isEditTootChart
export const isEditTootChartType = (state: any) => state.treatment.isEditTootChartType
export const historyToothChart = (state: any) => state.treatment.historyToothChart
export const newStickerProduct = (state: any) => state.treatment.newStickerProduct
export const toothChartPositions = (state: any) => state.treatment.toothChartPositions
export const checkApprovalRight = (state: any) => state.treatment.approvalRight
export const promotionsTreatments = (state: any) => state.treatment.promotionTreatments

export const { setTreatment, setTreatmentByKey, setToothChart, setColorToothChart, setTeethPosition, resetToothChart, setSummeryTreatments, setProductsState, setStickerState, setDiagnosisByKey, setDiagnosisTreatments, setToothChartSelected, setSubmitTootChart, setEditTootChart, setEditTootChartType, setRecommendByKey, setRecommendTreatments, setHistoryToothChart, setNewStickerProduct, setToothChartPosition, setApprovalRight, setPromotionTreatments } = treatmentSlice.actions

export const loadPromotionTreatments = (patientId: number, itemTreatments: any) => {
  return async (dispatch: Dispatch) => {
    if (!getCrm()) {
      dispatch(setPromotionTreatments([]))
      return
    }
    const newPayload: PromotionTreatmentProps = {
      patientId: patientId,
      branchId: getBranch(),
      totalPrice: _.sumBy(itemTreatments.map((item: any) => {
        if (item?.approvalItems?.status === ApprovalStatus.PENDING) {
          const newData = JSON.parse(item?.approvalItems?.body)
          return { ...item, total: newData?.total }
        } else {
          return { ...item }
        }
      }), 'total'),
      items: itemTreatments?.filter((item: any) => {
        if (item?.promotion?.length) {
          return item?.promotion?.every((info: any) => info?.promotionType !== "EXTRA")
        } else {
          return item
        }

      }).map((item: any) => {
        if (item?.group) {
          return {
            itemType: item?.group,
            itemId: item?.itemId || 0,
            qty: item?.qty
          }
        } else {
          return {
            itemType: "OPERATIVE",
            itemId: item?.operativeId || 0,
            qty: item?.qty
          }
        }
      })
    }
    PromotionsApi.findPromotionTreatments(newPayload).then((res) => {
      if (res?.data?.length) {
        dispatch(setPromotionTreatments(res?.data))
      } else {
        dispatch(setPromotionTreatments([]))
      }
    })
  }
}

export default treatmentSlice.reducer
