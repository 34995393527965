import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportProps } from 'features/report/report-constants'
import TableRowExport from 'component/Pdf/TableRowExport'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, TableCell, TableRow, Typography, colors } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import ProductTypesApi from 'api/setting/default-products/product-type.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import Loading from 'component/Loading'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { dateTimeToView, dateToView } from 'utils/date.utils'

/** REPORT */
import SaleMedicineContent from './SaleMedicineContent'
import SaleProductContent from './SaleProductContent'

/** CONSTANTS */
import { getBranch, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import FucntionPrint from 'component/Print/print'
/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { routeName } from 'routes/routes-name'
import ProductsApi from 'api/warehouses/product.api'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import SaleMaterialContent from './SaleMaterialContent'

export default function SaleItemReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [loading, setLoading] = useState<boolean>(true)
  const [branches, setBranches] = useState<any[]>([])

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [prefix, setPrefix] = useState<string>('ALL')

  const [productType, setProductType] = useState([])
  const [productTypeId, setProductTypeId] = useState(0)
  const [productTypeSelected, setProductTypeSelected] = useState({ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') })

  const [product, setProduct] = useState([])
  const [productId, setProductId] = useState(0)
  const [productSelected, setProductSelected] = useState({ productId: 0, productName: t('REPORT.FILTER.ALL') })

  const [medicinePage, setMedicinePage] = useState<number>(1)
  const [medicinePageLimit, setMedicinePageLimit] = useState<number>(10)
  const [medicineRowCount, setMedicineRowCount] = useState<number>(0)
  const [medicineSortBy, setMedicineSortBy] = useState<string>('createAt')
  const [medicineSortType, setMedicineSortType] = useState<string>('ASC')
  const [medicineData, setMedicineData] = useState<any>([])
  const [medicineExport, setMedicineExportData] = useState<any[]>([])

  const [productPage, setProductPage] = useState<number>(1)
  const [productPageLimit, setProductPageLimit] = useState<number>(10)
  const [productRowCount, setProductRowCount] = useState<number>(0)
  const [productSortBy, setProductSortBy] = useState<string>('createAt')
  const [productSortType, setProductSortType] = useState<string>('ASC')
  const [productData, setProductData] = useState<any[]>([])
  const [productExport, setProductExportData] = useState<any[]>([])

  const [materialPage, setMaterialPage] = useState<number>(1)
  const [materialPageLimit, setMaterialPageLimit] = useState<number>(10)
  const [materialRowCount, setMaterialRowCount] = useState<number>(0)
  const [materialSortBy, setMaterialSortBy] = useState<string>('createAt')
  const [materialSortType, setMaterialSortType] = useState<string>('ASC')
  const [materialData, setMaterialData] = useState<any[]>([])
  const [materialExport, setMaterialExportData] = useState<any[]>([])

  interface ProductData {
    group: string
    itemTypeName: string
    itemName: string
    qty: number
    discount: number
    additional: number
    total: number
    amountPaid: number
  }
  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'SALE_SUMMARY_MONTHLY') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    /** MASTER DATA */
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  /** MEDICINE */
  const fetchMedicine = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: medicinePage,
      pageLimit: medicinePageLimit,
      branchId,
      prefix: prefix
    }
    if (medicineSortType) condition = { ...condition, sortType: medicineSortType }
    if (medicineSortBy) condition = { ...condition, sortBy: medicineSortBy }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (productTypeSelected.productTypeId > 0) condition = { ...condition, productTypeId: productTypeSelected.productTypeId }
    if (productSelected.productId > 0) condition = { ...condition, productId: productSelected.productId }
    ReportApi.reportMedicineSale(condition)
      .then(({ headers, data }) => {
        setMedicineData(data)
        setMedicineRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [medicinePage, medicinePageLimit, medicineSortType, medicineSortBy, branchId, rangeDate, productId, productTypeId, prefix, productSelected])

  const onChangeMedicineSort = (sortByVal: string, sortTypeVal: string) => {
    setMedicineSortType(sortTypeVal)
    setMedicineSortBy(sortByVal)
  }

  const onChangeMedicineRowPerPage = (limit: number) => {
    setMedicinePageLimit(limit)
  }

  const onChangeMedicinePage = (val: number) => {
    setMedicinePage(val)
  }

  const loadMedicineExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId,
      prefix: prefix,
      export: 1
    }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (productTypeSelected.productTypeId > 0) condition = { ...condition, productTypeId: productTypeSelected.productTypeId }
    if (productSelected.productId > 0) condition = { ...condition, productId: productSelected.productId }
    ReportApi.reportMedicineSale(condition)
      .then(({ data }) => {
        setMedicineExportData(_.map(data, (d,) => { return { ...d, group: "MEDICINE" } }))
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [branchId, rangeDate, productTypeId, prefix, productSelected])

  const loadProductType = useCallback((search = '') => {
    ProductTypesApi.findAll({ status: 'ACTIVE', pageLimit: 50, search: search })
      .then(({ data }) => {
        setProductType(data)
      })
      .catch((e) => {
        return
      })
  }, [])

  useEffect(() => {
    ProductsApi.findByType({ status: 'ACTIVE', pageLimit: 50, itemTypeId: productTypeSelected.productTypeId })
      .then(({ data }) => {
        setProduct(data)
      })
      .catch((e) => {
        return
      })
  }, [productTypeSelected, productSelected])

  const headCellsGroup = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.#NO', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'INVENTORY.GROUP.CATEGORY', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.TYPE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.LIST', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.QTY', align: 'center', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.DISCOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.ADDITIONAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PRICE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PAID', align: 'right', class: 'text-nowrap' }
  ]

  const renderMedicineExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'center', label: num },
            { option: 'TEXT', align: 'center', label: data.createdAt },
            { option: 'TEXT', align: 'center', label: data.billNo },
            { option: 'TEXT', align: 'center', label: data.cnNumber },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber },
            { option: 'TEXT', align: 'left', label: data.fullName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.productTypeName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.itemName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.price) },
            { option: 'TEXT', align: 'right', label: data.qty || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
          ]}
        />
      </>
    )
  }

  /** PRODUCT */
  const fetchProduct = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: productPage,
      pageLimit: productPageLimit,
      branchId
    }
    if (productSortType) condition = { ...condition, sortType: productSortType }
    if (productSortBy) condition = { ...condition, sortBy: productSortBy }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (productTypeSelected.productTypeId > 0) condition = { ...condition, productTypeId: productTypeSelected.productTypeId }
    if (productSelected.productId > 0) condition = { ...condition, productId: productSelected.productId }
    ReportApi.reportProductSale(condition)
      .then(({ headers, data }) => {
        setProductData(data)
        setProductRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [productPage, productPageLimit, productSortType, productSortBy, branchId, rangeDate, productTypeId, productSelected])

  const onChangeProductSort = (sortByVal: string, sortTypeVal: string) => {
    setProductSortType(sortTypeVal)
    setProductSortBy(sortByVal)
  }

  const onChangeProductRowPerPage = (limit: number) => setProductPageLimit(limit)

  const onChangeProductPage = (val: number) => setProductPage(val)

  const loadProductExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId,
      export: 1
    }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (productTypeSelected.productTypeId > 0) condition = { ...condition, productTypeId: productTypeSelected.productTypeId }
    if (productSelected.productId > 0) condition = { ...condition, productId: productSelected.productId }

    ReportApi.reportProductSale(condition)
      .then(({ data }) => {
        setProductExportData(_.map(data, (d,) => { return { ...d, group: "PRODUCT" } }))
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [branchId, rangeDate, productTypeId, productSelected])

  const renderProductExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'center', label: num },
            { option: 'TEXT', align: 'center', label: data.createdAt },
            { option: 'TEXT', align: 'center', label: data.billNo },
            { option: 'TEXT', align: 'center', label: data.cnNumber },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber },
            { option: 'TEXT', align: 'left', label: data.fullName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.productTypeName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.itemName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.price) },
            { option: 'TEXT', align: 'right', label: data.qty || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
          ]}
        />
      </>
    )
  }

  /** MATERIAL */
  const fetchMaterial = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: materialPage,
      pageLimit: materialPageLimit,
      branchId
    }
    if (materialSortType) condition = { ...condition, sortType: materialSortType }
    if (materialSortBy) condition = { ...condition, sortBy: materialSortBy }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (productTypeSelected.productTypeId > 0) condition = { ...condition, productTypeId: productTypeSelected.productTypeId }
    if (productSelected.productId > 0) condition = { ...condition, productId: productSelected.productId }
    ReportApi.reportMaterialSale(condition)
      .then(({ headers, data }) => {
        setMaterialData(data)
        setMaterialRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [materialPage, materialPageLimit, materialSortType, materialSortBy, branchId, rangeDate, productTypeId, productSelected])

  const onChangeMaterialSort = (sortByVal: string, sortTypeVal: string) => {
    setMaterialSortType(sortTypeVal)
    setMaterialSortBy(sortByVal)
  }

  const onChangeMaterialRowPerPage = (limit: number) => setMaterialPageLimit(limit)

  const onChangeMaterialPage = (val: number) => setMaterialPage(val)

  const loadMaterialExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId,
      export: 1
    }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (productTypeSelected.productTypeId > 0) condition = { ...condition, productTypeId: productTypeSelected.productTypeId }
    if (productSelected.productId > 0) condition = { ...condition, productId: productSelected.productId }

    ReportApi.reportMaterialSale(condition)
      .then(({ data }) => {
        setMaterialExportData(_.map(data, (d,) => { return { ...d, group: "MATERIAL" } }))
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [branchId, rangeDate, productTypeId, productSelected])

  const renderMaterialExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'center', label: num },
            { option: 'TEXT', align: 'center', label: data.createdAt },
            { option: 'TEXT', align: 'center', label: data.billNo },
            { option: 'TEXT', align: 'center', label: data.cnNumber },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber },
            { option: 'TEXT', align: 'left', label: data.fullName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.productTypeName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.itemName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.price) },
            { option: 'TEXT', align: 'right', label: data.qty || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
          ]}
        />
      </>
    )
  }

  const renderSumProduct = (data: ProductData, no: number, print?: number) => {
    const num = no + 1

    return print ? (
      <TableRowExport
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: no + 1 },
          { option: 'TEXT', align: 'left', label: data.group === "MATERIAL" ? t('INVENTORY.GROUP.MATERIAL') : (data.group === "PRODUCT" ? t('INVENTORY.GROUP.PRODUCT') : t('INVENTORY.GROUP.MEDICINE')) },
          { option: 'TEXT', align: 'left', label: data.itemTypeName },
          { option: 'TEXT', align: 'left', label: data.itemName },
          { option: 'TEXT', align: 'center', label: data.qty },
          { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
          { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
          { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
        ]}
      />
    ) : (
      <TableRowCommon
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: no + 1 },
          { option: 'TEXT', align: 'left', label: data.group === "MATERIAL" ? t('INVENTORY.GROUP.MATERIAL') : (data.group === "PRODUCT" ? t('INVENTORY.GROUP.PRODUCT') : t('INVENTORY.GROUP.MEDICINE')) },
          { option: 'TEXT', align: 'left', label: data.itemTypeName },
          { option: 'TEXT', align: 'left', label: data.itemName },
          { option: 'TEXT', align: 'center', label: data.qty },
          { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
          { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
          { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
        ]}
      />
    )
  }

  /** UseEffect */
  useEffect(() => {
    fetchMedicine()
  }, [fetchMedicine])

  useEffect(() => {
    fetchProduct()
  }, [fetchProduct])

  useEffect(() => {
    fetchMaterial()
  }, [fetchMaterial])

  useEffect(() => {
    loadMedicineExport()
    loadProductExport()
    loadMaterialExport()
  }, [loadMedicineExport, loadProductExport, loadMaterialExport])

  useEffect(() => {
    loadProductType()
  }, [loadProductType])


  const groupTable = () => {
    const summary: any = []
    const groupSum = _.groupBy([...medicineExport, ...productExport, ...materialExport], 'itemName')
    for (const key in groupSum) {
      if (Object.prototype.hasOwnProperty.call(groupSum, key)) {
        const list = groupSum[key];
        summary.push({
          group: list.length ? list[0].group : '',
          itemTypeName: list.length ? list[0].productTypeName : '',
          itemName: key,
          qty: _.sumBy(list, 'qty'),
          discount: _.sumBy(list, 'discount'),
          additional: _.sumBy(list, 'additional'),
          total: _.sumBy(list, 'total'),
          amountPaid: _.sumBy(list, 'amountPaid'),
        })
      }
    }
    return summary
  }

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.SALE_SUMMARY_MONTHLY`)} ${rangeDate[0] ? `(${rangeDate[0]}-${rangeDate[1]})` : ''}`,
    sheetName: `${t(`REPORT.REPORT_NAME.SALE_SUMMARY_MONTHLY`)}`
  })

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.#NO', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.DATE', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.BILL_NO', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.CN_NUMBER', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.BRANCH_CN_NUMBER', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.PATIENT_NAME', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.TYPE', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.LIST', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.PRICE', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.QTY', align: 'center' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.DISCOUNT', align: 'right' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.ADDITIONAL', align: 'right' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.TOTAL', align: 'right' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.AMOUNT_PAID', align: 'right' }
  ]

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t(`REPORT.REPORT_NAME.SALE_SUMMARY_MONTHLY`)} />
      <Card.Body>
        <Card.Title className={'mb-0'}>
          <Row className="mx-0">
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBranchId(e.target.value)
                  setMedicinePage(1)
                  setProductPage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={branchId}
                options={_.map(branches, (b: BranchInterface, index) => {
                  return (
                    <MenuItem key={b.branchId} value={b.branchId}>
                      {b.branchName || b.branchNameEn}
                    </MenuItem>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <InputSecondNewRangePicker
                inputHeight={32}
                value={rangeDate || emptyRangePicker}
                onchange={(val: any) => {
                  if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                  else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  setMedicinePage(1)
                  setProductPage(1)
                }}
                label={''}
                allowClear
                onClear={() => setRangeDate(emptyRangePicker)}
              />
            </Col>

            {/* select operativeType */}
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <UseStyled.HeightAutocompleteSelect>
                <AutocompleteSelect
                  key={'operativeType'}
                  labelId="select-operative-type"
                  inputLabel={t('REPORT.FILTER.OPERATIVE_TYPE')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={[{ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') }, ...productType]}
                  getOptionLabel={(option: any) => option.productTypeName}
                  renderOption={(props, option: any) => (
                    <Box key={option} component="li" {...props}>
                      {option.productTypeName}
                    </Box>
                  )}
                  onchange={(e, value: any) => {
                    if (value) {
                      setProductTypeId(value.productTypeId || '')
                      setProductTypeSelected({ productTypeId: value.productTypeId, productTypeName: value.productTypeName })
                      setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                    } else {
                      setProductTypeId(0)
                      setProductTypeSelected({ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') })
                    }
                  }}
                  inputProps={{
                    onChange: (e: any) => {
                      if (productType.length === 50) {
                        setProductTypeId(0)
                        setProductType([])
                        loadProductType(e.target.value)
                      }
                    }
                  }}
                  onOpen={(e: any) => {
                    setProductTypeId(0)
                    setProductTypeSelected({ productTypeId: 0, productTypeName: '' })
                    loadProductType()
                  }}
                  value={productTypeSelected}
                  disableClearable
                />
              </UseStyled.HeightAutocompleteSelect>
            </Col>

            {/* select item */}
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <UseStyled.HeightAutocompleteSelect>
                <AutocompleteSelect
                  key={'operative'}
                  labelId="select-operative"
                  inputLabel={t('REPORT.FILTER.OPERATIVE')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={[{ productId: 0, productName: t('REPORT.FILTER.ALL') }, ...product]}
                  getOptionLabel={(option: any) => option.productName}
                  renderOption={(props, option: any) => (
                    <Box key={option} component="li" {...props}>
                      {option.productName}
                    </Box>
                  )}
                  onchange={(e, value: any) => {
                    if (value) {
                      setProductSelected({ productId: value.productId, productName: value.productName })
                    } else {
                      setProductId(0)
                      setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                    }
                  }}
                  inputProps={{
                    onChange: (e: any) => {
                      if (product.length === 50) {
                        setProductId(0)
                        setProduct([])
                      }
                    }
                  }}
                  onOpen={(e: any) => {
                    setProductId(0)
                    setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                  }}
                  value={productSelected}
                  disableClearable
                />
              </UseStyled.HeightAutocompleteSelect>
            </Col>

            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
              <UseStyled.DropdownDocument>
                <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                  {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                  <FucntionPrint
                    content={() => componentRef.current}
                    docTitle={t(`REPORT.REPORT_NAME.SALE_SUMMARY_MONTHLY`)}
                    removeIfram
                    trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                    style={`@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                  />
                </Dropdown.Menu>
              </UseStyled.DropdownDocument>
            </Col>
          </Row>
        </Card.Title>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <SaleMedicineContent
            page={medicinePage}
            pageLimit={medicinePageLimit}
            rowCount={medicineRowCount}
            sortBy={medicineSortBy}
            sortType={medicineSortType}
            data={medicineData}
            dataExport={medicineExport}
            onRequestSort={onChangeMedicineSort}
            handleChangeRowsPerPage={onChangeMedicineRowPerPage}
            handleChangePage={onChangeMedicinePage}
          />
        </Box>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <SaleProductContent
            page={productPage}
            pageLimit={productPageLimit}
            rowCount={productRowCount}
            sortBy={productSortBy}
            sortType={productSortType}
            data={productData}
            dataExport={productExport}
            onRequestSort={onChangeProductSort}
            handleChangeRowsPerPage={onChangeProductRowPerPage}
            handleChangePage={onChangeProductPage}
          />
        </Box>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <SaleMaterialContent
            page={materialPage}
            pageLimit={materialPageLimit}
            rowCount={materialRowCount}
            sortBy={materialSortBy}
            sortType={materialSortType}
            data={materialData}
            dataExport={materialExport}
            onRequestSort={onChangeMaterialSort}
            handleChangeRowsPerPage={onChangeMaterialRowPerPage}
            handleChangePage={onChangeMaterialPage}
          />
        </Box>

        <Box sx={{ maxWidth: '950px' }}>
          {/* <UseStyled.ResponsiveReportTable> */}
          <TableCustom
            page={1}
            pageLimit={1000}
            sortBy={'asc'}
            sortType={productSortType}
            rowCount={productRowCount}
            onSort={onChangeMedicineSort}
            setPageLimit={onChangeMedicineRowPerPage}
            setPage={onChangeMedicinePage}
            headCells={headCellsGroup}
            rowsData={_.map(groupTable(), (d: any, index: number) => {
              return renderSumProduct(d, index)
            })}
            hidePagination={true}
            tableMinWidth={950}
            customScroll
          />
          {/* </UseStyled.ResponsiveReportTable> */}
        </Box>
      </Card.Body>
      <Loading show={loading} type="fullLoading" />

      {
        <div className="print-show" ref={componentRef}>
          <ExportData
            header={
              <UseStyled.Styles className="pt-3" style={{ fontSize: 11 }}>
                <table style={{ border: 'none', borderCollapse: 'collapse' }}>
                  <tr>
                    <td style={{ border: 'none', textAlign: 'left' }} colSpan={8}>
                      {t(`REPORT.REPORT_NAME.SALE_SUMMARY_MONTHLY`)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: 'none' }}>{t('REPORT.FILTER.BRANCH')}: </td>
                    <td style={{ border: 'none' }}>{_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}</td>
                    <td style={{ border: 'none' }}>{t('REPORT.FILTER.DATE_START')} </td>
                    <td style={{ border: 'none', marginRight: '5px' }}>{rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'}</td>
                    <td style={{ border: 'none' }}>{t('REPORT.FILTER.DATE_END')} </td>
                    <td style={{ border: 'none' }}>{rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'}</td>
                    <td style={{ border: 'none' }}>{t('REPORT.FILTER.OPERATIVE_TYPE')}: </td>
                    <td style={{ border: 'none' }}>{productTypeSelected.productTypeName}</td>
                  </tr>
                </table>
              </UseStyled.Styles>
            }
            headCells={headCells}
            rowsData={[
              _.map(medicineExport, (d: any, index: number) => {
                return renderMedicineExport(d, index)
              }),
              <TableRow key={23}>
                <TableCell align="right" scope="row" colSpan={8} style={{ fontSize: 10, fontWeight: 800 }}>
                  {t('REPORT.TITLE.SUM')}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(medicineExport, 'price'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(
                    _.sumBy(medicineExport, (item: any) => Number(item.qty)),
                    0
                  )}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(medicineExport, 'discount'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(medicineExport, 'additional'))}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(medicineExport, 'total'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(medicineExport, 'amountPaid'))}
                </TableCell>
              </TableRow>
            ]}
            headCellsSeconds={headCells}
            rowsDataSeconds={[
              _.map(productExport, (d: any, index: number) => {
                return renderProductExport(d, index)
              }),
              <TableRow key={23} sx={{ border: 'none !important' }}>
                <TableCell align="right" scope="row" colSpan={8} style={{ fontSize: 10, fontWeight: 800 }}>
                  {t('REPORT.TITLE.SUM')}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'price'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(
                    _.sumBy(productExport, (item: any) => Number(item.qty)),
                    0
                  )}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'discount'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'additional'))}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'total'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'amountPaid'))}
                </TableCell>
              </TableRow>
            ]}
            headCellsThird={headCells}
            rowsDataThird={[
              _.map(materialExport, (d: any, index: number) => {
                return renderMaterialExport(d, index)
              }),
              <TableRow key={23} sx={{ border: 'none !important' }}>
                <TableCell align="right" scope="row" colSpan={8} style={{ fontSize: 10, fontWeight: 800 }}>
                  {t('REPORT.TITLE.SUM')}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'price'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(
                    _.sumBy(productExport, (item: any) => Number(item.qty)),
                    0
                  )}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'discount'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'additional'))}
                </TableCell>

                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'total'))}
                </TableCell>
                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, fontWeight: 800 }}>
                  {numberFormat(_.sumBy(productExport, 'amountPaid'))}
                </TableCell>
              </TableRow>
            ]}
            headCellsSum={headCellsGroup}
            rowsDataSum={[
              _.map(groupTable(), (d: any, index: number) => {
                return renderSumProduct(d, index, 1)
              })
            ]}
          />
        </div>
      }
    </Card>
  )
}

export function ExportData(props: ExportProps) {
  const { t } = useTranslation()

  const commonStyles = {
    table: {
      tableLayout: 'fixed',
      borderCollapse: 'collapse' as const,
      minWidth: '100%',
      maxWidth: '100%'
    },
    headerCell: {
      textAlign: 'left',
      fontSize: 10,
      fontWeight: 500
    },
    bodyCell: {
      fontSize: 10
    }
  }

  const renderTable = (headCells: any, rowData: any, title: string) => (
    <UseStyled.Styles className="pt-3">
      <Typography className="pb-1" sx={{ fontWeight: 500, fontSize: 10 }}>
        {t(title)}
      </Typography>
      <table style={commonStyles.table as React.CSSProperties}>
        <thead>
          <tr>
            {headCells.map((val: any, index: number) => (
              <td key={index} style={{ ...commonStyles.headerCell, textAlign: val.align }}>
                {t(val.labelKey)}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowData.length ? (
            rowData
          ) : (
            <tr>
              <td colSpan={headCells.length} style={commonStyles.bodyCell}>
                {t('NOT_FOUND')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </UseStyled.Styles>
  )

  const headCells = props.headCells.filter((headCell: any) => headCell.id !== 'action')
  const headCellsSeconds = props.headCellsSeconds ? props.headCellsSeconds.filter((headCell: any) => headCell.id !== 'action') : []
  const headCellsThirds = props.headCellsThird ? props.headCellsThird.filter((headCell: any) => headCell.id !== 'action') : []
  const headCellsSum = props.headCellsSum ? props.headCellsSum.filter((headCell: any) => headCell.id !== 'action') : []

  return (
    <>
      {props.header}
      {renderTable(headCells, props.rowsData, 'REPORT.TABLE.SALE_SUMMARY_MONTH.MED')}
      <br />
      {renderTable(headCellsSeconds, props.rowsDataSeconds, 'REPORT.TABLE.SALE_SUMMARY_MONTH.PD')}
      <br />
      {renderTable(headCellsThirds, props.rowsDataThird, 'REPORT.TABLE.SALE_SUMMARY_MONTH.MAT')}
      <br />
      {renderTable(headCellsSum, props.rowsDataSum, '')}
    </>
  )
}
