import { useState, useEffect } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { expired, setExpired } from 'app/slice/expired.slice'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { scale } from 'constants/scale'
import { colors } from 'constants/theme'
import WarningCircle from 'assets/icon/WarningCircle'
import CloseIcon from '@mui/icons-material/Close';
import { getPackageExpired } from 'utils/app.utils'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'

const Expired = styled(Box)(({ theme }) => ({
  height: scale.expiredAlertHeight,
  backgroundColor: colors.lightYellow
}))

const WarningIcon = styled(Box)(({ theme }) => ({
  svg: {
    width: '17px',
    [theme.breakpoints.down('lg')]: {
      width: '15px',
    }
  }
}))

const TypoGradient = styled(Typography)<{ day: number }>(({ theme, day }) => ({
  fontSize: '16px',
  fontWeight: 700,
  background: day <= 30 ? colors.expired.danger : colors.expired.warningLinear,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  textFillColor: 'transparent',
  WebkitTextFillColor: 'transparent',
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
  }
}))

const Typo = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: colors.textPrimary,
  [theme.breakpoints.down('lg')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  }
}))

const ButtonClose = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  '&:hover': {
    color: colors.textPrimary
  }
}))

export default function ExpiredAlert() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const expire = useSelector(expired)
  const [expiredDay, setExpiredDay] = useState(0);

  useEffect(() => {
    const day = getPackageExpired()
    if (day) {
      const diff = moment(day).add(1, 'days').diff(moment().subtract(1, 'days'), 'days')
      if (diff <= 60) {
        setExpiredDay(diff)
        dispatch(setExpired(true))
      }
    }
  }, [getPackageExpired]);


  return (
    <>
      {
        expire && (
          <Expired className='d-flex align-items-center justify-content-lg-center px-3'>
            <WarningIcon>
              <WarningCircle firstFill={expiredDay <= 30 ? colors.expired.danger : colors.expired.warning} secondFill={expiredDay <= 30 ? colors.expired.danger : colors.expired.danger2} />
            </WarningIcon>
            <Box className='d-flex align-items-baseline pl-2 overflow-hidden'>
              <TypoGradient day={expiredDay} className='text-ellipsis'>{t('EXPIRED_ALERT.TITLE', { day: expiredDay })}</TypoGradient>
              <Typo className='pl-1 pl-xl-2'>{t('EXPIRED_ALERT.SUBTITLE')}</Typo>
            </Box>
            {expiredDay > 30 && (
              <ButtonClose onClick={() => dispatch(setExpired(!expire))} className="p-0 ml-auto ml-lg-2 ml-xl-3">
                <CloseIcon />
              </ButtonClose>
            ) || <></>}
          </Expired>
        ) || <></>
      }
    </>
  )
}
