import { useCallback, useEffect, useState } from 'react'
import { Box, Typography, Button, TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { styled } from '@mui/material/styles'
import ComponentApi, { Bookmark } from 'api/components-api'
import TreatmentApi from 'api/dentists/treatment.api'
import { handleShow, showModal, resetModal } from 'app/slice/modal.slice'
import _ from 'lodash'
import { colors } from 'constants/theme'
import { Icons } from 'constants/images'
import { selectMe } from 'app/slice/user.slice';
import { useDispatch, useSelector } from 'react-redux'
import useWindowSize from 'utils/useWindowSize'
import { clinicTheme } from 'api/api';

/** COMPONENT */
import ToothChart from 'component/ToothChart/ToothChart'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'features/treatments/MainMenu/OPD/Table/TableCustom'
import { IconAddBox, IconBookmark, DxStyled } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import { ButtonToothChart, FragmentToothChart, FragmentTxTable } from 'features/treatments/MainMenu/OPD/style/TxUseStyle'
import PopupAddDx from 'component/Treatments/PopupAddDx'
import { diagnosisObj, setDiagnosisByKey, TreatmentStateInterface, setSubmitTootChart, isEditTootChart, isEditTootChartType } from 'app/slice/treatment.slice';
import { DxRecommendDataInterface } from 'api/setting/treatments/diagnosis.api';
import Loading from 'component/Loading';

/** API */
import DiagnosisApi from 'api/setting/treatments/diagnosis.api';
import TeethAdult from 'assets/toothChart/type-teeth/teethAdult';
import TeethChild from 'assets/toothChart/type-teeth/teethChild';
import { useLocation } from 'react-router-dom';

export interface DiagnosisType {
  bookmarkId?: null | number
  diagnosisCode?: string
  diagnosisId?: number
  diagnosisName?: string
  diagnosisText?: string
  diagnosisTypeId?: number
  isBookmark?: null | number
  doctorId?: number
}

export const ButtonType = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 30,
  color: colors.textPrimary,
  borderColor: `${colors.themeSecondColor} !important`,
  '&.active': {
    backgroundColor: colors.themeSecondColor,
    color: colors.white
  }
}))

export const NavBtn = styled(Box)(({ theme }) => ({
  margin: '0 -0.25rem',
  overflowY: 'auto',
  '> .MuiBox-root': {
    width: '14.28%',
    [theme.breakpoints.down('md')]: {
      width: '16.667%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '33.33%'
    }
  },
  '&.disabled': {
    '.MuiButton-root': {
      color: colors.textLightGray,
      borderColor: `${colors.disabledGray} !important`,
      path: {
        fill: `${colors.textLightGray} !important`
      },
      '&.active': {
        color: colors.white,
        backgroundColor: `${colors.disabledGray} !important`,
        path: {
          fill: `${colors.white} !important`
        },
      }
    }
  }
}))

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(1)': {
          width: '5%'
        },
        '&:nth-of-type(2)': {
          width: '10%'
        },
        '&:nth-of-type(3)': {
          width: '70%'
        },
        '&:nth-of-type(4)': {
          width: '15%'
        },
        [theme.breakpoints.down('sm')]: {
          '&:first-of-type': {
            width: '7%',
            fontSize: 0
          },
          '&:nth-of-type(2)': {
            width: 'auto',
            fontSize: 0
          },
          '&:nth-of-type(3)': {
            width: 'auto',
            fontSize: 0
          },
          '&:last-of-type': {
            width: 90
          }
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'middle',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        [theme.breakpoints.down('sm')]: {
          verticalAlign: 'top'
        }
      }
    },
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
    display: 'none'
  },
  '&.disabled': {
    '.MuiTable-root': {
      '.MuiButton-root, .MuiIconButton-root': {
        backgroundColor: `${colors.white} !important`,
        span: {
          color: `${colors.disabledGray} !important`,
        },
        path: {
          fill: `${colors.disabledGray} !important`,
        },
      },
    },
    '.MuiTablePagination-root': {
      '.MuiButtonBase-root': {
        color: `${colors.disabledGray} !important`,
        borderColor: `${colors.disabledGray} !important`,
        backgroundColor: `${colors.white} !important`,
        '&.active ': {
          color: `${colors.white} !important`,
          backgroundColor: `${colors.disabledGray} !important`,
        }
      }
    }
  }
}))

export const DxFragmentToothChart = styled(FragmentToothChart)(({ theme }) => ({
  '.btn-select-tooth-type': {
    [theme.breakpoints.down(1600)]: {
      marginBottom: '1rem'
    }
  }
}))

export default function Dx() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const modal: boolean = useSelector(handleShow)
  const treatments: TreatmentStateInterface = useSelector(diagnosisObj)
  const isEditTootCharts = useSelector(isEditTootChart)
  const isEditTootChartTypes: any = useSelector(isEditTootChartType)
  const [diagnosis, setDiagnosis] = useState([])
  const user = useSelector(selectMe)
  const [selectDxLoading, setSelectDxLoading] = useState(false);
  const [teethType, setTeethType] = useState('ADULT')

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [search, setSearch] = useState('')
  const [isBookmark, setIsBookmark] = useState(0)
  const [typeId, setTypeId] = useState(0)
  const [typeSelected, setTypeSelected] = useState('All')
  const [operativeTypes, setOperativeTypes] = useState([])
  const [width] = useWindowSize()
  const [isShowPopupRecommend, setIsShowPopupRecommend] = useState<{ show: boolean, data: DxRecommendDataInterface[], dx: any }>({ show: false, data: [], dx: {} });

  const location = useLocation();
  const [checkPath, setCheckPath] = useState<boolean>(false)
  const disabledPath = ['/treatments']

  useEffect(() => {
    if (!disabledPath.includes(location?.pathname)) {
      setCheckPath(true)
    } else {
      setCheckPath(false)
    }
  }, [location])

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    condition = { ...condition, userId: 0 }
    if (isBookmark) condition = { ...condition, isBookmark: isBookmark }
    if (typeId) condition = { ...condition, typeId: typeId }
    if (user.userId) condition = { ...condition, userId: user.userId }

    const res = await TreatmentApi.findAllDiagnosis(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      if (typeId !== 0 && !isNaN(typeId)) {
        const newData = res.data.filter((item: any, index: number) => {
          if (item.diagnosisTypeId === typeId) {
            return item
          }
        })
        setDiagnosis(newData)
      } else {
        setDiagnosis(res.data)
      }

    }
  }, [page, pageLimit, search, isBookmark, typeId])

  const loadDataOperativeType = useCallback(async () => {
    const res = await TreatmentApi.findAllDiagnosisType()
    if (res.status === 200) {
      setOperativeTypes(res.data)

    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    loadDataOperativeType()
  }, [loadDataOperativeType])

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangeType = (ele: any) => {
    const type = ele.currentTarget.getAttribute('data-value')
    setTypeSelected(type)
    setIsBookmark(0)
    setTypeId(0)
    setPage(1)
    if (type === 'BOOKMARK') setIsBookmark(1)
    else setTypeId(parseInt(type))
  }

  const handleBookmark = async (id: number, bookmarkId: number) => {
    const payload: Bookmark = {
      bookmarkType: 'DIAGNOSIS',
      userId: user.userId,
      itemId: id,
      isBookmark: bookmarkId ? '0' : '1'
    }
    const resp = await ComponentApi.bookmark(payload)
    if (resp.status === 201) {
      loadData()
    }
  }

  const handleAddDx = (valueDx: any) => {
    const item = {
      ...valueDx,
      diagnosisCode: valueDx.diagnosisCode,
      diagnosisName: valueDx.diagnosisName,
      doctorId: _.get(treatments.patientDiagnosis[0], 'pinDoctorId') || user.userId,
      refItemId: ['00']
    }
    setSelectDxLoading(true)
    DiagnosisApi.findById(valueDx.diagnosisId)
      .then(({ data }: any) => {
        if (!_.isEmpty(data.diagnosisRecommends)) {
          setIsShowPopupRecommend({ show: true, data: data.diagnosisRecommends, dx: item })
          dispatch(showModal())
        } else {
          dispatch(setDiagnosisByKey({ key: 'patientDiagnosis', value: [...treatments.patientDiagnosis, item] }))
          dispatch(setSubmitTootChart(true))
        }
      })
      .catch((e) => { return })
      .finally(() => setSelectDxLoading(false))
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'dxId', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.DX_ID') },
    { id: 'diagnosis', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS') },
    { id: 'action', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.ACTION') }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { diagnosisId, diagnosisCode, diagnosisName, bookmarkId } = objData

    return (
      <TableRow key={diagnosisId}>
        <TableCell align="center" scope="row" >
          {no}
        </TableCell>
        {(width >= 768 && (
          <>
            <TableCell align="left" scope="row">
              {diagnosisCode}
            </TableCell>
            <TableCell align="left" scope="row">
              {diagnosisName}
            </TableCell>
            <TableCell align="center" scope="row">
              <IconBookmark key={diagnosisId} className="p-0 ml-1" onClick={() => handleBookmark(diagnosisId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
              </IconBookmark>
              <IconAddBox key={diagnosisId} disabled={checkPath && user.hasCreateDiagnosis !== 'PERMISS' || _.some(treatments.patientDiagnosis, { diagnosisId: diagnosisId })} className="p-0 ml-1" onClick={() => handleAddDx(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                <AddBoxIcon style={{ width: 30, height: 30 }} />
              </IconAddBox>
            </TableCell>
          </>
        )) || (
            <>
              <TableCell align="left" scope="row" colSpan={3}>
                <Box className="d-flex justify-content-between">
                  <Box>
                    <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                      {t('TREATMENTS_DX.TABLE.CELL.DX_ID')}
                    </Typography>
                    <Typography className="d-inline">{diagnosisCode}</Typography>
                  </Box>
                  <Box>
                    <IconBookmark key={diagnosisId} className="p-0 ml-1" onClick={() => handleBookmark(diagnosisId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                      {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
                    </IconBookmark>
                    <IconAddBox key={diagnosisId} className="p-0 ml-1" disabled={checkPath && user.hasCreateDiagnosis !== 'PERMISS' || _.some(treatments.patientDiagnosis, { diagnosisId: diagnosisId })} onClick={() => handleAddDx(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                      <AddBoxIcon style={{ width: 30, height: 30 }} />
                    </IconAddBox>
                  </Box>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS')}
                  </Typography>
                  <Typography className="d-inline">{diagnosisName}</Typography>
                </Box>
              </TableCell>
            </>
          )}
      </TableRow>
    )
  }

  useEffect(() => {
    if (!modal) {
      setIsShowPopupRecommend({ show: false, data: [], dx: {} })
      dispatch(resetModal())
    }
  }, [modal]);

  useEffect(() => {
    if (isEditTootChartTypes) {
      setTeethType(isEditTootChartTypes)
    }
  }, [isEditTootChartTypes]);
  return (
    <div className="w-100 h-100">
      <DxStyled>
        {clinicTheme.theme === clinicTheme.type.DENTAL && (
          <DxFragmentToothChart className="mx-auto" sx={{ flex: 'none' }}>
            <Box className="d-none d-md-block">
              <ToothChart type={'dx'} teethType={teethType} patientId={0} />
            </Box>
            <Box className="d-none d-md-flex justify-content-center btn-select-tooth-type">
              <ButtonToothChart onClick={() => setTeethType('ADULT')} className="mt-0 mt-md-3 ">
                <TeethAdult type={teethType} />
              </ButtonToothChart>
              <ButtonToothChart onClick={() => setTeethType('CHILD')} className="mt-0 mt-md-3 pl-1">
                <TeethChild type={teethType} />
              </ButtonToothChart>
            </Box>
          </DxFragmentToothChart>
        )}
        <Box className={`w-100 ${isEditTootCharts !== null && 'pe-none'}`}>
          <Box className="d-flex justify-content-between align-items-center pb-2">
            <Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('TREATMENTS_DX.TITLE.DIAGNOSIS')}</Typography>
            <Box className="w-100 pl-3" sx={{ maxWidth: 379 }}>
              <InputTextSearch
                keyInput={'search'}
                label={t('TREATMENTS_DX.SEARCH')}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                }}
                onClear={(event) => {
                  setSearch('')
                  setPage(1)
                }}
              />
            </Box>
          </Box>
          <NavBtn className={`d-flex flex-wrap custom-scroll ${isEditTootCharts !== null && 'disabled'}`} style={{ maxHeight: '76px' }}>
            <Box className="px-1 pb-2">
              <ButtonType variant="outlined" title={t('TREATMENTS_DX.TITLE.ALL')} className={`${typeSelected === 'All' ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={handleChangeType} key={`btn-operativeTypes-all`}>
                <span className="text-ellipsis">{t('TREATMENTS_DX.TITLE.ALL')}</span>
              </ButtonType>
            </Box>
            <Box className="px-1 pb-2">
              <ButtonType variant="outlined" title={t('BOOKMARK')} className={`${typeSelected === 'BOOKMARK' ? 'active' : ''}`} data-value={'BOOKMARK'} onClick={handleChangeType} key={`btn-operativeTypes-bookmark`}>
                {isBookmark ? <Icons.StarActive fill={colors.white} /> : <Icons.StarInActive fill={colors.textPrimary} />}
              </ButtonType>
            </Box>
            {operativeTypes.map((val: any, i) => {
              return (
                <Box key={`col-operative-typ-${val.diagnosisTypeId}`} className="px-1 pb-2">
                  <ButtonType variant="outlined" key={val.diagnosisTypeId} title={val.diagnosisTypeName} data-value={val.diagnosisTypeId} onClick={handleChangeType} className={`${typeSelected === val.diagnosisTypeId.toString() ? 'active' : ''} text-ellipsis`}>
                    <span className="text-ellipsis">{val.diagnosisTypeName}</span>
                  </ButtonType>
                </Box>
              )
            })}
          </NavBtn>
          <TableWidth className={`mt-2 ${isEditTootCharts !== null && 'disabled'}`}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={diagnosis.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </TableWidth>
        </Box>
      </DxStyled>

      {isShowPopupRecommend.show && (
        <PopupAddDx
          type='dx'
          tx={{}}
          dx={isShowPopupRecommend.dx}
          data={{ dx: [], recommend: isShowPopupRecommend.data }}
          isShow={isShowPopupRecommend.show}
          setIsShow={(show: boolean) => setIsShowPopupRecommend({ show, dx: {}, data: [] })}
        />
      )}

      <Loading show={selectDxLoading} type={'fullLoading'} />
    </div>
  )
}
