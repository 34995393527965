import ClinicApi from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import { getBranch } from 'utils/app.utils'
import * as UseStyled from 'features/report/useStyled'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import { Box } from '@mui/material'
import Loading from 'new-components/loading/loading'
import _ from 'lodash'
import FucntionPrint from 'component/Print/print'
import SelectButton from 'new-components/buttons/select-button'
import SelectInput from 'new-components/inputs/select-input'
import SelectCheckBoxInput from 'new-components/inputs/select_checkbox_input'
import MainButton from 'new-components/buttons/main-button'
import IncomeExpenseList from './IncomeExpenseList'

const IncomeExpense = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const componentRef = useRef<HTMLDivElement>(null)

    const [branchId, setBranchId] = useState<any>(getBranch())
    const [branches, setBranches] = useState<any[]>([])
    const [branchSelect, setBranchSelect] = useState<number>(getBranch())
    const [rangeDate, setRangeDate] = useState<string[]>(emptyRangePicker)

    const [rowCount, setRowCount] = useState<number>(0)
    const [sortBy, setSortBy] = useState<string>('createAt')
    const [sortType, setSortType] = useState<string>('ASC')
    const [fetch, setFetch] = useState(false)
    const [rows, setRows] = useState<any[]>([])
    const [rowsExport, setRowsExport] = useState<any[]>([])
    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [loading, setLoading] = useState<boolean>(true)

    const typeOption = [{ name: t("INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.TITLE"), id: "INCOME" }, { name: t("INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.TITLE"), id: "EXPENSE" }]
    const [typeSelect, setTypeSelect] = useState<string[]>(typeOption.map((item) => item.id))

    const statusOption = [{ name: t("INCOME_EXPENSES.DOCUMENT_STATUS.PENDING_APPROVAL"), id: "PENDING_APPROVAL" }, { name: t("INCOME_EXPENSES.DOCUMENT_STATUS.APPROVAL"), id: "APPROVAL" }, { name: t("INCOME_EXPENSES.DOCUMENT_STATUS.UNAPPROVAL"), id: "UNAPPROVAL" }]
    const [statusSelect, setStatusSelect] = useState<string[]>(statusOption.map((item) => item.id))


    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1,
            pageLimit: 200
        }
        ReportApi.reportList(condition).then(({ data }) => {
            const check = _.find(data, (val: any) => String(val.reportKey) === 'INCOME_EXPENSE') ? true : false
            if (!check) {
                history.push(routeName.report)
                return false
            }
        })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data.map((item: any) => ({ name: item?.branchName, value: item?.branchId }))))
            .catch((e) => {
                return
            })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [branchId])

    const fetchDF = async (isExport?: boolean) => {
        let condition: any = {
            page,
            pageLimit,
            branchId,
            dateStart: rangeDate[0],
            dateEnd: rangeDate[1],
            status: statusSelect?.join(',') || '',
            type: typeSelect?.join(',') || ''
        }

        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (isExport) condition = { ...condition, export: '1' }


        ReportApi.reportIncomeExpense(condition)
            .then(({ headers, data }) => {
                if (!isExport) {
                    setRows(data.rows)
                    setRowCount(headers['x-total'])
                } else {
                    setRowsExport(data.rows)
                }
            })
            .catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
            })
        setFetch(false)
    }

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.INCOME_EXPENSE`)}`,
        sheetName: `${t(`REPORT.REPORT_NAME.INCOME_EXPENSE`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

    const handleChangePage = (val: number) => setPage(val)

    useEffect(() => {
        fetchDF(true)
    }, [])

    useEffect(() => {
        fetchDF()
    }, [page, pageLimit, sortBy, sortType,])

    return (
        <>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.INCOME_EXPENSE`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className="mx-0">
                            <Col sm={6} md={4} xl={2} className={'pt-2'}>
                                <SelectInput
                                    label={t('NAV.BRANCH')}
                                    onChange={(e) => setBranchSelect(e.target.value)}
                                    options={branches}
                                    value={branchSelect.toString()}
                                />
                            </Col>
                            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
                                <InputSecondNewRangePicker
                                    inputHeight={40}
                                    value={rangeDate || emptyRangePicker}
                                    onchange={(val: any) => {
                                        if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                        else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                    }}
                                    label={''}
                                    onClear={() => setRangeDate(emptyRangePicker)}
                                />
                            </Col>
                            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
                                <SelectCheckBoxInput
                                    label={t('REPORT.TABLE.SALE_SUMMARY_MONTH.TYPE')}
                                    onChange={(e) => setTypeSelect(e)}
                                    options={typeOption}
                                    selected={typeSelect}
                                />
                            </Col>
                            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
                                <SelectCheckBoxInput
                                    label={t('STATUS.LABEL')}
                                    onChange={(e) => setStatusSelect(e)}
                                    options={statusOption}
                                    selected={statusSelect}
                                />

                            </Col>
                            <Col sm={6} md={4} xl={2} className={'pt-2 px-1 d-flex flex-row align-items-center'}>
                                <Box sx={{ maxWidth: '100px', width: '100%', button: { width: '100%' } }}>
                                    <MainButton
                                        onClick={() => {
                                            setPage(1)
                                            fetchDF()
                                            fetchDF(true)
                                            setLoading(true)
                                        }}
                                        title={t('INPUT.SEARCH')}
                                        type={'primary'}
                                        variant={'contained'}
                                    />
                                </Box>
                            </Col>

                            <Col md={4} xl={2} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 '}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                                        {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}
                                            removeIfram
                                            trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                                            style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <IncomeExpenseList
                            page={page}
                            pageLimit={pageLimit}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={rows}
                            dataExport={rowsExport}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            setSortType={setSortType}
                            setSortBy={setSortBy}
                            componentRef={componentRef}
                            branchName={_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}
                            rangeDate={rangeDate}
                            statusSelect={statusSelect}
                            typeSelect={typeSelect}
                        />
                    </Box>
                </Card.Body>
                {loading && <Loading />}
            </Card>
        </>
    )
}

export default IncomeExpense