import React, { useState } from 'react';
import {
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    eachDayOfInterval,
    addMonths,
    subMonths,
    format,
    isBefore,
    isAfter,
    isSameMonth,
    addDays,
    isToday,
    startOfYear,
    getYear,
    startOfToday,
    parse,
    eachYearOfInterval,
    endOfYear,
    subYears,
    addYears,
    parseISO

} from 'date-fns';
import { th, enUS } from 'date-fns/locale';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface CustomCalendarProps {
    value: string;
    onChange: (date: any) => void;
    disabledDateFuture?: boolean
    disabledDatePast?: boolean
    language?: "TH" | "EN"
    startDate?: any
    setShowCalendar: (show: boolean) => void;
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({ value, onChange, disabledDateFuture, disabledDatePast, language, startDate, setShowCalendar }) => {
    ////-------------------
    ////    STATE
    ////-------------------
    const [currentMonth, setCurrentMonth] = React.useState<Date>(new Date());
    const [viewType, setViewType] = useState<'day' | 'month' | 'year'>('day');
    const [year, setYear] = useState(getYear(new Date()))

    ////-------------------
    ////    HANDLE
    ////-------------------
    const handleLanguageDayTab = () => {
        if (language === 'EN') {
            return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', "Fri", "Sat"]
        } else {
            return ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'];
        }
    }

    const handleTodayClick = () => {
        const today = new Date();
        if (!isSelectPastDate(today)) {
            onChange(today);
            setCurrentMonth(today);
            setViewType('day')
        }
    };

    const isFutureDate = (date: Date) => isAfter(date, startOfToday());
    const isPastDate = (date: Date) => isBefore(date, startOfToday());
    const isSelectPastDate = (date: Date) => isBefore(date, parseISO(startDate));
    const months = Array.from({ length: 12 }, (_, i) => startOfMonth(new Date(new Date().getFullYear(), i, 1)));
    const start = startOfMonth(currentMonth);
    const end = endOfMonth(currentMonth);
    const days = eachDayOfInterval({
        start: startOfWeek(start, { weekStartsOn: 0 }),
        end: endOfWeek(end, { weekStartsOn: 0 })
    });
    while (days.length < 42) {
        days.push(addDays(days[days.length - 1], 1));
    }

    const handleDateClick = (date: Date) => {
        onChange(date);
        setShowCalendar(false);
        if (isBefore(date, start)) {
            setCurrentMonth(subMonths(currentMonth, 1));
        } else if (isAfter(date, end)) {
            setCurrentMonth(addMonths(currentMonth, 1));
        }
    };

    const handleMonthMenuClose = (monthStart: Date) => {
        setCurrentMonth(new Date(year, monthStart.getMonth()));
        setViewType('day');
    };

    const handleYearMenuClose = (yearStart: Date) => {
        setYear(yearStart.getFullYear())
        setCurrentYear(yearStart.getFullYear())
        setCurrentMonth(new Date(yearStart.getFullYear(), currentMonth.getMonth(), 1));
        setViewType('day');
    };


    const convertToThaiDate = (dateString: string, day: Date) => {
        if (dateString && day) {
            const date = parse(dateString, 'yyyy-MM-dd', new Date());
            const formattedDate = format(date, 'M/d/yyyy');
            if (formattedDate === day.toLocaleDateString()) {
                return true
            } else {
                return false
            }
        }
    }

    const handleDisabledDate = (day: Date) => {
        if (day) {
            if (disabledDateFuture) {
                return isFutureDate(day)
            } else if (disabledDatePast) {
                return isPastDate(day)
            } else if (startDate) {
                return isSelectPastDate(day)
            }
        }
    }

    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
    const startYear = currentYear - 4;
    const endYear = currentYear + 7;
    const years = eachYearOfInterval({
        start: startOfYear(new Date(startYear, 0, 1)),
        end: endOfYear(new Date(endYear, 11, 31)),
    });
    const handlePrevYear = () => {
        setCurrentYear((prevYear) => subYears(new Date(prevYear, 0, 1), 12).getFullYear());
    };

    const handleNextYear = () => {
        setCurrentYear((prevYear) => addYears(new Date(prevYear, 0, 1), 12).getFullYear());
    };
    return (
        <Box width="240px" bgcolor="#FFFFFF" borderRadius="4px" boxShadow={8} p="8px">
            {viewType === 'day' && <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box
                        onClick={() => {
                            setCurrentMonth(subMonths(currentMonth, 1))
                            setYear(getYear(subMonths(currentMonth, 1)))
                        }}
                        sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                    >
                        <ChevronLeft sx={{ width: '16px', height: '16px', color: '#1CB99A' }} />
                    </Box>
                    <Box display="flex" justifyContent='center' alignItems="center">
                        <Typography variant="body2" sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#1CB99A',
                            },
                        }} onClick={() => setViewType('month')}>
                            {format(currentMonth, 'MMMM', { locale: language === 'EN' ? enUS : th })}
                        </Typography>,
                        <Typography variant="body2" sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#1CB99A',
                            },
                        }} onClick={() => setViewType('year')}>
                            {format(language === 'TH' ? addYears(currentMonth, 543) : currentMonth, 'yyyy')}
                        </Typography>
                    </Box>
                    <Box
                        onClick={() => {
                            setCurrentMonth(addMonths(currentMonth, 1))
                            setYear(getYear(addMonths(currentMonth, 1)))
                        }}
                        sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                    >
                        <ChevronRight sx={{ width: '16px', height: '16px', color: '#1CB99A' }} />
                    </Box>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gridAutoRows: 'minmax(32px, auto)' }}>
                    {handleLanguageDayTab().map((day, index) => (
                        <Box key={index} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Typography variant="caption">{day}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gridAutoRows: 'minmax(32px, auto)', backgroundColor: 'white' }}>
                    {days.map((day, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: handleDisabledDate(day) ? 'not-allowed' : "pointer",
                                borderRadius: '4px',
                                border: isToday(day) ? '1px solid #1CB99A' : 'none',
                                backgroundColor: convertToThaiDate(value, day) ? '#1CB99A' : 'white',
                                color: convertToThaiDate(value, day) ? 'white' : handleDisabledDate(day) ? 'gray' : isSameMonth(day, currentMonth) ? 'black' : 'gray',
                            }}
                            onClick={() => {
                                if (!handleDisabledDate(day)) {
                                    handleDateClick(day)
                                }
                            }}
                        >
                            <Typography variant='caption'>{format(day, 'd')}</Typography>
                        </Box>
                    ))}
                </Box>
            </>}
            {
                viewType === 'month' && <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'minmax(32px, auto)', backgroundColor: 'white', gap: '16px' }}>
                    {months.map((month, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleMonthMenuClose(month)}>
                            <Typography variant='caption'>{format(month, 'MMMM', { locale: language === 'EN' ? enUS : th })}</Typography>
                        </Box>
                    ))}
                </Box>
            }
            {
                viewType === 'year' &&
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton
                            onClick={() => handlePrevYear()}
                            sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                        >
                            <ChevronLeft sx={{ width: '16px', height: '16px', color: '#1CB99A' }} />
                        </IconButton>
                        <Box display="flex" justifyContent='center' alignItems="center">
                            <Typography variant="body2" sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#1CB99A',
                                },
                            }} onClick={() => setViewType('month')}>
                                {format(currentMonth, 'MMMM', { locale: language === 'EN' ? enUS : th })}
                            </Typography>,
                            <Typography variant="body2" sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#1CB99A',
                                },
                            }} onClick={() => setViewType('year')}>
                                {format(language === 'TH' ? addYears(currentMonth, 543) : currentMonth, 'yyyy')}
                            </Typography>
                        </Box>
                        <Box
                            onClick={() => {
                                handleNextYear()
                            }}
                            sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                        >
                            <ChevronRight sx={{ width: '16px', height: '16px', color: '#1CB99A' }} />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'minmax(32px, auto)', backgroundColor: 'white', gap: '16px' }}>
                        {years.map((y, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: year === y.getFullYear() ? '#1CB99A' : "none",
                                    borderRadius: '4px', border: y.getFullYear() === getYear(new Date()) ? '1px solid #1CB99A' : 'none',
                                    color: year === y.getFullYear() ? 'white' : "black",
                                }}
                                onClick={() => handleYearMenuClose(y)}>
                                <Typography variant='caption'>{format(language === 'TH' ? addYears(y, 543) : y, 'yyyy')}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            }
            <Box sx={{ marginTop: '4px', borderBottom: '1px solid #ccc', }} />
            <Typography variant='body2' color='#1CB99A' onClick={handleTodayClick} display="flex" justifyContent='center' alignItems='center' marginTop='4px' sx={{ cursor: 'pointer' }}>วันนี้</Typography>
        </Box >
    );
};

export default CustomCalendar;