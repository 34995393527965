import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

const promotion = '/promotions'

export type PromotionTypePostProps = {
    promotionTypeName: string
    promotionTypeNameEn: string
}

export type PromotionTypePatchProps = PromotionTypePostProps & {
    status: string;
};

export type PromotionStatusProps = "ACTIVE" | "INACTIVE" | "DELETED"

export interface PromotionBranchProps {
    branchId: number,
    qty: number,
    startDate: string,
    endDate: string,
}

export interface PromotionGroupProps {
    groupType: string,
    groupTypeId: number,
}

export interface PromotionItemProps {
    itemType: string,
    itemId: number,
    qty: number,
}

export interface PromotionExtraProps {
    groupNumber: number,
    itemType: string,
    itemId: number,
    qty: number,
}

export interface PromotionProps {
    promotionTypeId: number,
    // promotionCode: string;
    promotionName: string,
    promotionNameEn: string,
    interface: string,
    qty: number,
    startDate: string,
    endDate: string,
    detail: string,
    status?: string,
    promotionPrice?: number,
    promotionPriceUnit?: string,
    promotionPriceType?: string,
    promotionUsage?: string,
    minimum?: number,
    usageBranch: string,
    isRepeat?: 0 | 1,
    promotionGroup?: string,
    promotionBranchs: PromotionBranchProps[],
    promotionGroups?: PromotionGroupProps[],
    promotionItems?: PromotionItemProps[],
    promotionExtra?: PromotionExtraProps[],
}

export interface PromotionTreatmentProps {
    patientId: number,
    branchId: number,
    totalPrice: number,
    items: {
        itemType: "OPERATIVE" | "MEDICINE" | "PRODUCT",
        itemId: number,
        qty: number
    }[]
}

export default class PromotionsApi extends BaseAPI {
    static findAllType(props: FindAllInterface): Promise<any> {
        return this.api.get(`${promotion}/types`, { params: { ...props } }).then((res) => res)
    }
    static createPromotionType(props: PromotionTypePostProps): Promise<any> {
        return this.api.post(`${promotion}/types`, { ...props }).then((res) => res)
    }
    static updatePromotionType(props: PromotionTypePatchProps, id: number): Promise<any> {
        return this.api.patch(`${promotion}/types/${id}`, { ...props }).then((res) => res)
    }
    static chagneStatusPromotionType(id: number, props: PromotionStatusProps): Promise<any> {
        return this.api.patch(`${promotion}/types/${id}/status`, { status: props }).then((res) => res)
    }

    static findAllRank(props: FindAllInterface): Promise<any> {
        return this.api.get(`${promotion}/promotion-ranks`, { params: { ...props } }).then((res) => res)
    }

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(`${promotion}`, { params: { ...props } }).then((res) => res)
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${promotion}/${id}`).then((res) => res)
    }

    static createPromotion(props: PromotionProps): Promise<any> {
        return this.api.post(`${promotion}`, { ...props }).then((res) => res)
    }

    static updatePromotion(props: PromotionProps, id: number): Promise<any> {
        return this.api.patch(`${promotion}/${id}`, { ...props }).then((res) => res)
    }

    static deletePromotion(id: number): Promise<any> {
        return this.api.delete(`${promotion}/${id}`).then((res) => res)
    }

    static findPromotionByBranch(id: number, props: FindAllInterface): Promise<any> {
        return this.api.get(`${promotion}/branch/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findItemUsagePromotion(id: number, props: any): Promise<any> {
        return this.api.get(`${promotion}/items/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findItemByPatient(id: number, props: any): Promise<any> {
        return this.api.get(`${promotion}/patient/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findPromotionTreatments(props: PromotionTreatmentProps): Promise<any> {
        return this.api.post(`${promotion}/treatments`, { ...props }).then((res) => res)
    }

    static findPromotionPayment(id: number): Promise<any> {
        return this.api.get(`${promotion}/patient-all/${id}`).then((res) => res)
    }

}