import { Box, Grid, Typography, styled } from '@mui/material'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputSecondNewRangePicker from 'component/Input/InputSecondNewRangePicker'
import InputTextSearch from 'component/Input/InputTextSearch'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { colors } from 'constants/theme'
import IconInfo from 'assets/icon/iconInfo'
import IconCancel from 'assets/icon/iconCancel'
import IconApplival from 'assets/icon/iconApplival'
import Loading from 'component/Loading'
import ApprovalApi, { ApprovalStatus } from 'api/approval/approval.api'
import { toBuddhistYear } from 'utils/app.utils'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfoIcon from '@mui/icons-material/Info';
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import { selectMe } from 'app/slice/user.slice'
import { useSelector } from 'react-redux'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

const ApprovelList = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [load, setLoad] = useState<boolean>(false)
    const user = useSelector(selectMe)

    const [dataApprovellist, setDataApprovellist] = useState<any>([])
    const styleSelector = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }

    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('approvalItemId')
    const [sortType, setSortType] = useState('DESC')
    const [search, setSearch] = useState('')

    const [funcLinkOptions, setFuncLinkOptions] = useState<any>([
        {
            key: "hasCancelReciept",
            funcLinkName: 'ยกเลิกใบเสร็จ',
            value: 'CANCEL_RECEIPT'
        },
        {
            key: "hasChangeCost",
            funcLinkName: 'จัดการค่ารักษา',
            value: 'CHANGE_COST'
        }
    ])
    const [funcLinkSelect, setFuncLinkSelect] = useState<any>({})
    const [rangeDate, setRangeDate] = useState<string[]>(['', ''])
    const [branches, setBranches] = useState<BranchInterface[]>([])
    const [branchSelect, setBranchSelect] = useState<number>(0)
    const [applicant, setApplicant] = useState([
        { label: t('APPROVELLIST.FILTER.APPROVED'), value: 'APPROVED' },
        { label: t('APPROVELLIST.FILTER.PENDING'), value: 'PENDING' },
        { label: t('APPROVELLIST.FILTER.REJECT'), value: 'REJECT' }
    ])
    const [applicantSelect, setApplicantSelect] = useState<any>({})
    const [userOption, setUserOption] = useState<any>([])
    const [userSelect, setUserSelect] = useState<any>({})

    const loadFilterOption = async () => {
        await ClinicApi.findAllActiveBranches().then(({ data }) => data && setBranches(data))
    }

    const loadFilterUser = async (search?: string) => {
        const condition = {
            page: 1,
            pageLimit: 30,
            search: search,
            sortBy: sortBy,
            sortType: sortType,
        }
        await PersonnelApi.findAll(condition).then((res) => {
            setUserOption(res?.data || [])
        })

    }

    useEffect(() => {
        loadFilterOption()
        loadFilterUser()
    }, [])

    const loadApprovalList = async () => {
        setLoad(true)
        const checkDate = ((rangeDate[0] !== 'Invalid date' && rangeDate[0]) && (rangeDate[1] !== 'Invalid date' && rangeDate[1]))
        const condition = {
            page: page,
            pageLimit: pageLimit,
            search: search,
            sortBy: sortBy,
            sortType: sortType,
            branchId: branchSelect || '',
            STATUS: applicantSelect?.value || '',
            USER: userSelect?.userId || '',
            dateStart: checkDate ? rangeDate[0] : '',
            dateEnd: checkDate ? rangeDate[1] : '',
            approveType: funcLinkSelect?.value || '',
            userId: user?.userId || 0
        }

        await ApprovalApi.findApprovalListAll(condition).then((res) => {
            setRowCount(res?.headers['x-total'] || 0)
            setDataApprovellist(res?.data || [])
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        if (user?.userId) {
            loadApprovalList()
        }
    }, [page, pageLimit, search, sortBy, sortType, user?.userId])

    const onView = (objData: any) => {
        if (objData?.approveType === 'CANCEL_RECEIPT') {
            history.push(routeName.approvelListCancelReciept,
                {
                    orderPaymentId: objData?.refId,
                    approval: true,
                    approvalItemId: objData?.approvalItemId,
                })
        } else {
            history.push(routeName.approvelListCost,
                {
                    approval: true,
                    objData: objData,
                })
        }
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => setPage(val)

    const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '50px', align: 'center', },
        { id: 'branchlName', disablePadding: false, label: t('APPROVELLIST.TABLE.BRANCH'), align: 'center', sortable: true },
        { id: 'date', disablePadding: false, label: t('APPROVELLIST.TABLE.DATE'), align: 'center', sortable: true },
        { id: 'menuFunc', disablePadding: false, label: t('APPROVELLIST.TABLE.MENUFUNC'), align: 'center', },
        { id: 'updatedBy', disablePadding: false, label: t('APPROVELLIST.TABLE.APPLICANT'), width: '180px' },
        { id: 'status', disablePadding: false, label: t('APPLICATION.TABLE.CELL.STATUS'), width: '150px' },
        { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
    ]

    const renderData = (objData: any, index: number) => {
        index = page * pageLimit - pageLimit + index + 1
        const { approvalItemId, approvalName, updatedBy, updatedAt, createdAt, status, branchId, approveType } = objData

        const objRenderData = {
            key: approvalItemId,
            id: approvalItemId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index },
                { option: 'TEXT', align: 'center', label: branches?.find((item) => item?.branchId === branchId)?.branchName || '' },
                { option: 'TEXT', align: 'center', label: `${toBuddhistYear(moment(createdAt), 'DD/MM/YYYY HH:mm')} ${t('APPOINTMENT.TIME')}` || '' },
                { option: 'TEXT', align: 'center', label: t(`APPROVELLIST.TYPE.${approveType}`) || '' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
                {
                    option: 'COMPONENT', align: 'center',
                    component: (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <FontAwesomeIcon icon={faCircle} color={status === 'APPROVED' ? colors.statusActive : status === "REJECT" ? colors.statusInactive : '#EDBA07'} />
                            <span className="ml-2">{t(`APPROVELLIST.FILTER.${status}`)}</span>
                        </Box>

                    )
                },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'VIEW', label: t(`APPROVELLIST.TABLE.VIEW`), disabled: permissions.isView.disabled },
                    ]
                },
            ]
        }

        return (
            <TableRowCommon
                {...objRenderData}
                onView={() => {
                    onView(objData)
                }}
            />
        )
    }

    return (
        <Box>
            <Loading show={load} />
            <Card className="border-0 h-100">
                <HeaderCard text={t('APPROVELLIST.TITLE')} />
                <Card.Body className="overflow-auto">
                    <Card.Title className={'mb-0'}>
                        <Grid container columns={16}>
                            <Grid item xs={16} md={4} lg={3} xl={3} className="px-1 mb-2 w-100">
                                <Box sx={{ '.MuiInputBase-root': { paddingRight: '25px !important' } }}>
                                    <AutocompleteSelect
                                        labelId="funcLinkOptions"
                                        options={["ALL", ...funcLinkOptions]}
                                        getOptionLabel={(option) => `${t('APPROVELLIST.TABLE.MENUFUNC')}:${option?.funcLinkName || t('ALL')}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <ListFilterDoctors {...props} key={option.key} component="li" sx={styleSelector}>
                                                    {option === "ALL" ? t('ALL') : t(`APPROVAL.FORM.${String(option.key).toUpperCase()}`)}
                                                </ListFilterDoctors>
                                            )
                                        }}
                                        onchange={(e, value) => setFuncLinkSelect(value)}
                                        noOptionsText={t('NOT_FOUND')}
                                        filterOptions={(option) => option.funcLinkName}
                                        value={_.find(funcLinkOptions, { key: funcLinkSelect?.key }) || ''}
                                        height={43}
                                        disableClearable={!funcLinkSelect}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={16} md={4} lg={3} xl={3} className="px-1 mb-2 w-100">
                                <Box sx={{ '.MuiInputBase-root': { paddingRight: '25px !important' } }}>
                                    <AutocompleteSelect
                                        labelId="branch"
                                        options={["ALL", ...branches]}
                                        getOptionLabel={(option) => `${t('APPROVELLIST.FILTER.BRANCH')}:${option?.branchName || t('ALL')}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <ListFilterDoctors {...props} key={option.branchId} component="li" sx={styleSelector}>
                                                    {option === "ALL" ? t('ALL') : option.branchName}
                                                </ListFilterDoctors>
                                            )
                                        }}
                                        onchange={(e, value) => setBranchSelect(value?.branchId)}
                                        noOptionsText={t('NOT_FOUND')}
                                        filterOptions={(option) => option.branchName}
                                        value={_.find(branches, { branchId: branchSelect }) || ''}
                                        height={43}
                                        disableClearable={!branchSelect}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={16} md={4} lg={3} xl={3} className="px-1 mb-2 w-100">
                                <Box sx={{ '.MuiInputBase-root': { paddingRight: '25px !important' } }}>
                                    <AutocompleteSelect
                                        labelId="status"
                                        options={["ALL", ...applicant]}
                                        getOptionLabel={(option) => `${t('APPROVELLIST.FILTER.STATUS')}:${option.label || t('ALL')}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <ListFilterDoctors {...props} component="li" sx={styleSelector}>
                                                    {option === "ALL" ? t('ALL') : option.label}
                                                </ListFilterDoctors>
                                            )
                                        }}
                                        onchange={(e, value) => setApplicantSelect(value)}
                                        noOptionsText={t('NOT_FOUND')}
                                        filterOptions={(option) => option.label}
                                        value={_.find(applicant, { value: applicantSelect?.value }) || ''}
                                        height={43}
                                        disableClearable={!applicantSelect}

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={16} md={4} lg={3} xl={3} className="px-1 mb-2 w-100">
                                <Box sx={{ '.MuiInputBase-root': { paddingRight: '25px !important' } }}>
                                    <AutocompleteSelect
                                        labelId="user"
                                        options={["ALL", ...userOption]}
                                        getOptionLabel={(option) => `${t('APPROVELLIST.FILTER.APPLICANT')}:${option?.firstname ? option.firstname + " " + option.lastname : t('ALL')}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <ListFilterDoctors {...props} key={option.userId} component="li" sx={styleSelector}>
                                                    {option === "ALL" ? t('ALL') : `${option.firstname} ${option.lastname}`}
                                                </ListFilterDoctors>
                                            )
                                        }}
                                        onchange={(e, value) => setUserSelect(value)}
                                        noOptionsText={t('NOT_FOUND')}
                                        value={_.find(userOption, { userId: userSelect?.userId }) || ''}
                                        height={43}
                                        disableClearable={!userSelect?.userId}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={16} md={4} lg={3} xl={3} className="px-1 mb-2 w-100">
                                <InputSecondNewRangePicker value={rangeDate} inputHeight={43} onchange={(val: any) => {
                                    setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                }} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={1} xl={1} className="px-1 mb-2 w-100">
                                <Box className="" sx={{ button: { padding: '10px', width: '100%', maxWidth: '120px' } }}>
                                    <ButtonCustom
                                        onClick={() => {
                                            loadApprovalList()
                                            setPage(1)
                                        }}
                                        textButton={t('INPUT.SEARCH')}
                                    // className="w-auto ml-auto d-flex mt-auto"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Card.Title>

                    <Box className={'mt-3 pl-xl-3 pr-xl-2'}>
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={dataApprovellist?.length && dataApprovellist?.map((item: any, index: number) => {
                                return renderData(item, index)
                            }) || []}
                        />
                    </Box>
                </Card.Body>
            </Card>
        </Box >
    )
}

export default ApprovelList

export const DropdownToggleApproval = styled(Dropdown.Toggle)<{ bg: string, isdisabled: '0' | '1' }>(({ theme, bg, isdisabled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '50px',
    padding: '6px 16px',
    borderColor: 'transparent !important',
    backgroundColor: bg || '#FFF3E5',
    pointerEvents: isdisabled === '1' ? 'none' : 'auto',
    ":after": {
        display: isdisabled === '1' ? 'none' : 'block'
    }
}))

const DropdownMenu = styled(Dropdown.Menu)(({ theme }) => ({}))
const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
    color: colors.textPrimary,
    '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
    }
}))

const ApprovalSelectorCustoms = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    '.user-approvel': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '12px',
        'path': {
            fill: '#A8A8A8'
        },
        '.user-fullname': {
            color: '#808080'
        }
    }
}))


type ApprovalSelectorCustomProps = {
    approval: ApprovalStatus
    onChange?: (data: ApprovalStatus) => void
    disabled?: boolean
    userApprovel?: { fullname: string, date: string }
}

export const approvalStatusList = {
    'APPROVED':
    {
        bg: '#D1F1EB',
        label: ApprovalStatus.APPROVED,
        disabled: true,
        icon: <IconApplival />,
    },
    'PENDING': {
        bg: '#FFF3E5',
        label: ApprovalStatus.PENDING,
        disabled: false,
        icon: <IconInfo />,
    },
    'REJECT': {
        bg: '#FFF0F2',
        label: ApprovalStatus.REJECT,
        disabled: true,
        icon: <IconCancel />,
    },
    'CANCEL': {
        bg: '#FFF0F2',
        label: ApprovalStatus.CANCEL,
        disabled: true,
        icon: <IconCancel />,
    }

}

export const ApprovalSelectorCustom = (props: ApprovalSelectorCustomProps) => {
    const { t } = useTranslation()

    return (
        <ApprovalSelectorCustoms className={`box-approvel-selector`}>
            {(props?.userApprovel && props?.userApprovel?.fullname) &&
                <Box className={`user-approvel`}>
                    <InfoIcon />
                    <span>
                        {t(`APPROVELLIST.FILTER.${approvalStatusList[props.approval].label}`)}
                    </span>
                    <span className={`user-fullname`}>
                        {t('APPROVELLIST.APPROVAL.USERAPPROVAL', {
                            userApprovel: props?.userApprovel?.fullname,
                            date: `${toBuddhistYear(moment(props?.userApprovel?.date), "DD/MM/YYYY HH:mm")} ${t('TIME_UNIT')}`
                        })}
                    </span>
                </Box>
            }
            <Box className={`approvel-selector`}>
                <Dropdown>
                    <DropdownToggleApproval
                        className="btn-table-action text-black-50 shadow-none"
                        bg={`${approvalStatusList[props.approval].bg} !important`}
                        isdisabled={props?.disabled ? '1' : '0'}
                    >
                        <Box>
                            {approvalStatusList[props.approval].icon}
                        </Box>
                        <Box sx={{ color: 'black' }}>
                            {t(`APPROVELLIST.FILTER.${approvalStatusList[props.approval].label}`)}
                        </Box>
                    </DropdownToggleApproval>
                    <DropdownMenu>
                        {Object.entries(approvalStatusList).map(([key, { bg, label, disabled, icon, }]) => {
                            if (key !== 'CANCEL') {
                                return (
                                    <DropdownItem key={key} onClick={() => {
                                        props?.onChange && props.onChange(label)
                                    }}>
                                        {t(`APPROVELLIST.FILTER.${label}`)}
                                    </DropdownItem>
                                )
                            }
                        })}
                    </DropdownMenu>
                </Dropdown>
            </Box>

        </ApprovalSelectorCustoms>

    )
}