import { Box, Grid, InputAdornment, Typography } from "@mui/material"
import ButtonExport from "component/Button/ButtonExport"
import MainButton from "new-components/buttons/main-button"
import SelectInput from "new-components/inputs/select-input"
import TextInput from "new-components/inputs/text-input"
import AddCircleIcon from '@mui/icons-material/AddCircle'
import TableCustom from "component/Table/TableCustom"
import { useEffect, useRef, useState } from "react"
import VoucherApi from "api/voucher/voucher.api"
import TableRowCommon from "component/Table/TableRowCommon"
import TableRowExport from "component/Pdf/TableRowExport"
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loading from "component/Loading"
import AlertModal from "new-components/modal/alert-modal"
import SnackbarComponent from "new-components/snackbar/snackbar-component"
import PageModal from "new-components/modal/page-modal"
import SwitchButton from "new-components/buttons/switch-button"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

const VoucherType = () => {

    ////-------------------
    ////    I18N
    ////-------------------
    const { t } = useTranslation()

    ////-------------------
    ////    STATE
    ////-------------------
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('approvalId')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [statusSelect, setStatusSelect] = useState<string>('ALL')
    const [loading, setLoading] = useState<boolean>(true)

    const [dataVoucher, setDataVoucher] = useState<any>([])
    const [dataVoucherExport, setDataVoucherExport] = useState<any>([])

    const [isOpen, setIsOpen] = useState(false)
    const [status, setStatus] = useState('')
    const [id, setId] = useState<number>(0)
    const [name, setName] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [alertModal, setAlertModal] = useState(false)
    const [isOpenSnack, setIsOpenSnack] = useState(false)
    const [typeSnack, setTypeSnack] = useState<any>("success")
    const [labelSnack, setLabelSnack] = useState('')

    const [statusSwitch, setStatusSwitch] = useState(true)
    const [errorMessage, setErrorMessage] = useState<any>({})


    const componentExportRef = useRef<HTMLDivElement>(null)

    const preparation = async (searchData?: string, checkExport?: boolean) => {
        setLoading(true)
        const condition = {
            page: page,
            pageLimit: pageLimit,
            SEARCH: searchData === '' ? '' : search,
            sortBy: sortBy,
            sortType: sortType,
            STATUS: statusSelect === 'ALL' ? '' : statusSelect,
            EXPORT: checkExport ? '1' : '0'
        }
        await VoucherApi.findAllType(condition).then((resp) => {
            if (resp.status === 200) {
                if (checkExport) {
                    setDataVoucherExport(resp.data)
                } else {
                    setRowCount(resp?.headers['x-total'] || 0)
                    setDataVoucher(resp.data)
                }
            }
        })

        setLoading(false)
    }

    ////-------------------
    ////    EFFECT
    ////-------------------
    useEffect(() => {
        preparation()
        return () => {
            setId(0)
            setName('')
            setNameEn('')
            setErrorMessage({})
        }
    }, [statusSelect, page, pageLimit, sortBy, sortType])

    useEffect(() => {
        preparation('', true)
    }, [])

    ////-------------------
    ////    HANDLE
    ////-------------------
    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '50px', align: 'center', },
        { id: 'voucherTypeName', disablePadding: false, label: t('VOUCHER_TYPE.TABLE.VOUCHER_TYPENAME'), align: 'left', },
        { id: 'voucherTypeNameEn', disablePadding: false, label: t('VOUCHER_TYPE.TABLE.VOUCHER_TYPENAMEEN'), align: 'left', },
        { id: 'status', disablePadding: false, label: t('VOUCHER_TYPE.TABLE.VOUCHER_STATUS'), align: 'center', width: '150px' },
        { id: 'updatedBy', disablePadding: false, label: t('VOUCHER_TYPE.TABLE.UPDATED_BY'), align: 'center', width: '180px' },
        { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
    ]

    const renderData = (objData: any, index: number, isExport?: boolean) => {
        if (isExport) {
            index = index + 1
        } else {
            index = page * pageLimit - pageLimit + index + 1
        }

        const { voucherTypeId, voucherTypeName, voucherTypeNameEn, updatedBy, updatedAt, createdAt, status } = objData

        const objRenderData = {
            key: voucherTypeId,
            id: voucherTypeId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index },
                { option: 'TEXT', align: 'left', label: voucherTypeName || '' },
                { option: 'TEXT', align: 'left', label: voucherTypeNameEn || '' },
                { option: 'STATUS', align: 'center', label: status },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t('VOUCHER_TYPE.ACTION.STATUS_ACTIVE'), disabled: status === 'ACTIVE' },
                        { option: 'STATUS_INACTIVE', label: t('VOUCHER_TYPE.ACTION.STATUS_INACTIVE'), disabled: status === 'INACTIVE' },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t('VOUCHER_TYPE.ACTION.EDIT'), disabled: permissions.isUpdate.disabled },
                        { option: 'DELETE', label: t('VOUCHER_TYPE.ACTION.DELETE'), disabled: permissions.isDelete.disabled }
                    ]
                },
            ]
        }

        if (isExport) {
            return <TableRowExport {...objRenderData} />
        }

        return (
            <TableRowCommon
                {...objRenderData}
                onedit={() => onEdit(objRenderData.obj)}
                ondelete={() => handleSubmitModal(Number(objRenderData.id), 'DELETED', objRenderData.obj)}
                onactive={() => handleSubmitModal(Number(objRenderData.id), 'ACTIVE', objRenderData.obj)}
                oninactive={() => handleSubmitModal(Number(objRenderData.id), 'INACTIVE', objRenderData.obj)}
            />
        )
    }

    const onEdit = (data: any) => {
        setIsOpen(true)
        setId(data.voucherTypeId)
        setStatusSwitch(data.status === 'INACTIVE' ? false : true)
        setName(data.voucherTypeName)
        setNameEn(data.voucherTypeNameEn)
    }


    const handleSubmitModal = (id: number, status: string, obj?: any) => {
        if (status === 'DELETED') {
            setStatus("DELETED")
            setId(obj.voucherTypeId)
            setName(obj.voucherTypeName)
            setNameEn(obj.voucherTypeNameEn)
            setAlertModal(true)
        } else {
            setAlertModal(true)
            setId(id)
            setStatus(status)
            setName(obj.voucherTypeName)
            setNameEn(obj.voucherTypeNameEn)
        }
    }

    const onActive = () => {
        if (id) {
            VoucherApi.chagneStatusVoucherType(id, { voucherTypeName: name, voucherTypeNameEn: nameEn, status: status }).then((resp) => {
                if (resp.status === 200) {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("success")
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.SUCCESS'))
                    preparation(search, true)
                    preparation()
                } else {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("error")
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.ERROR'))
                }
            }).catch((error) => {
                setAlertModal(false)
                setLoading(false)
                setIsOpenSnack(true)
                setTypeSnack("error")
                if (error?.response?.data?.message === 'THIS_VOUCHER_TYPE_IS_BEING_USED' || error?.response?.data?.message === 'THIS_VOUCHER_IS_BEING_USED') {
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.DUPICATE'))
                } else {
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.ERROR'))
                }
            })
        }
    }

    const onDelete = () => {
        if (id) {
            VoucherApi.chagneStatusVoucherType(id, { voucherTypeName: name, voucherTypeNameEn: nameEn, status: status }).then((resp) => {
                if (resp.status === 200) {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("success")
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.SUCCESS'))
                    preparation(search, true)
                    preparation()
                } else {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("error")
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.ERROR'))
                }
            }).catch((error) => {
                setAlertModal(false)
                setLoading(false)
                setIsOpenSnack(true)
                setTypeSnack("error")
                if (error?.response?.data?.message === 'THIS_VOUCHER_TYPE_IS_BEING_USED' || error?.response?.data?.message === 'THIS_VOUCHER_IS_BEING_USED') {
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.DUPICATE'))
                } else {
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.ERROR'))
                }
            })
        }
    }

    const onSubmit = async () => {
        setLoading(true)
        if (!name) {
            setErrorMessage({
                NAME: !name && t('VOUCHER_TYPE.MODAL.ERROR_VOUCHER_TITLE'),
            })
            setLoading(false)
            return
        }

        if (name?.length > 255 || nameEn?.length > 255) {
            setIsOpenSnack(true)
            setTypeSnack("error")
            setLabelSnack(t('VOUCHER_TYPE.MODAL.ERROR_TITLE_LENGTH'))
            setLoading(false)
            return
        }

        if (id === 0) {
            VoucherApi.createVoucherType({
                voucherTypeName: name,
                voucherTypeNameEn: nameEn
            }).then((resp) => {
                setIsOpen(false)
                setIsOpenSnack(true)
                setTypeSnack('success')
                setLabelSnack('VOUCHER_TYPE.MESSAGE.ADD_SUCCESS')
                preparation(search, true)
                preparation()
            }).catch((error) => {
                setIsOpen(false)
                setIsOpenSnack(true)
                setLoading(false)
                setTypeSnack('error')
                if (error?.response?.data?.message === 'THIS_VOUCHER_TYPE_IS_BEING_USED' || error?.response?.data?.message === 'THIS_VOUCHER_IS_BEING_USED') {
                    setLabelSnack(t('VOUCHER_TYPE.MESSAGE.DUPICATE'))
                } else {
                    setLabelSnack('VOUCHER_TYPE.MESSAGE.ERROR')
                }
            })
        } else {
            VoucherApi.updateVoucherType(id, {
                status: statusSwitch ? 'ACTIVE' : 'INACTIVE',
                voucherTypeName: name,
                voucherTypeNameEn: nameEn
            }).then(() => {
                setIsOpen(false)
                setIsOpenSnack(true)
                setTypeSnack('success')
                setLabelSnack('VOUCHER_TYPE.MESSAGE.EDIT_SUCCESS')
                preparation(search, true)
                preparation()
            }).catch((error) => {
                setIsOpen(false)
                setIsOpenSnack(true)
                setLoading(false)
                setTypeSnack('error')
                if (error?.response?.data?.message === 'THIS_VOUCHER_TYPE_IS_BEING_USED' || error?.response?.data?.message === 'THIS_VOUCHER_IS_BEING_USED') {
                    setLabelSnack('VOUCHER_TYPE.MESSAGE.DUPICATE')
                } else {
                    setLabelSnack('VOUCHER_TYPE.MESSAGE.ERROR')
                }
            })
        }
        setErrorMessage({})
    }

    return (
        <Box>
            <Loading show={loading} />
            <AlertModal
                isOpen={alertModal}
                title={status === "DELETED" ? t('VOUCHER_TYPE.MESSAGE.DELETE_TITLE') : status === 'INACTIVE' ? t('VOUCHER_TYPE.MESSAGE.STATUS_INACTIVE_TITLE') : t('VOUCHER_TYPE.MESSAGE.STATUS_ACTIVE_TITLE')}
                message={status === "DELETED" ? t('VOUCHER_TYPE.MESSAGE.DELETE_MESSAGE', { name: name, nameEn: nameEn }) : t('VOUCHER_TYPE.MESSAGE.STATUS_MESSAGE')}
                type={status === "DELETED" ? 'error' : 'warning'}
                onSubmit={{ onClick: status === 'DELETED' ? onDelete : onActive, title: status === "DELETED" ? t('VOUCHER_TYPE.MESSAGE.BUTTON_SAVE_DELETE') : t('VOUCHER_TYPE.MESSAGE.BUTTON_SAVE_ACTIVE') }}
                onCancel={{ onClick: () => setAlertModal(false), title: t('VOUCHER_TYPE.MESSAGE.BUTTON_CANCEL') }}
                handleClose={() => setAlertModal(false)}
            />
            <SnackbarComponent
                onOpen={isOpenSnack}
                onClose={() => setIsOpenSnack(false)}
                label={labelSnack}
                type={typeSnack}
                transform={`translateX(calc(100vw - 100% - 32px)) translateY(calc(-100vh + 100% + ${window.innerHeight - 50}px))`}
                autoHideTime={3000}
            />
            <PageModal
                isOpen={isOpen}
                title={`${id !== 0 ? t('VOUCHER_TYPE.MODAL.UPDATE_VOUCHER_TITLE') : t('VOUCHER_TYPE.MODAL.CREATE_VOUCHER_TITLE')}`}
                size="XS"
                onSubmit={{ onClick: onSubmit, title: t('VOUCHER_TYPE.MODAL.BUTTON_SAVE') }}
                onCancel={{
                    onClick: () => {
                        setErrorMessage({})
                        setIsOpen(false)
                    }, title: t('VOUCHER_TYPE.MODAL.BUTTON_CANCEL')
                }}
                handleClose={() => {
                    setErrorMessage({})
                    setIsOpen(false)
                }}
            >
                <Box marginTop="16px">
                    {id !== 0 && <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                        <Typography fontSize="16px" fontWeight={400}>{t('VOUCHER_TYPE.MODAL.STATUS')}</Typography>
                        <SwitchButton checked={statusSwitch} onChange={(e) => setStatusSwitch(e.target.checked)} />
                    </Box>}
                    <Box display="flex" flexDirection="column" gap="16px">
                        <TextInput
                            required
                            value={name}
                            inputProps={{ value: name }}
                            label={t('VOUCHER_TYPE.MODAL.VOUCHER_TYPE')}
                            onChange={(e) => {
                                setErrorMessage({})
                                setName(e.target.value)

                            }}
                            fullWidth
                            helperText={errorMessage.NAME}
                        />
                        <TextInput
                            label={t('VOUCHER_TYPE.MODAL.VOUCHER_TYPEEN')}
                            value={nameEn}
                            inputProps={{ value: nameEn }}
                            onChange={(e) => setNameEn(e.target.value)}
                            fullWidth
                        />
                    </Box>
                </Box>
            </PageModal>
            <Box width="100%" height="80px" boxShadow="0px 3px 6px 0px #0000001F" border="1px solid #E5E5E5" paddingLeft="24px" display="flex" alignItems="center" fontSize="20px" fontWeight={500}  >
                {t('VOUCHER_TYPE.TITLE')}
            </Box>
            <Box paddingX="24px" paddingTop="16px">
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5} lg={4}>
                                <TextInput
                                    onChange={(event) => {
                                        setSearch(event.target.value);
                                        setPage(1);
                                    }}
                                    label={t('VOUCHER_TYPE.SEARCH')}
                                    value={search}
                                    size='large'
                                    fullWidth={true}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            setPage(1)
                                            preparation(search, true)
                                            preparation()
                                        }
                                    }}
                                    inputProps={{
                                        value: search,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!search ? <FontAwesomeIcon icon={faSearch} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 15 }} /> :
                                                    <FontAwesomeIcon
                                                        icon={faTimes}
                                                        onClick={() => {
                                                            setSearch('')
                                                            setPage(1)
                                                            preparation('', true)
                                                            preparation('')
                                                        }}
                                                        style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 17, cursor: 'pointer' }} />}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={5} lg={4}>
                                <SelectInput
                                    value={statusSelect}
                                    onChange={(event: any) => {
                                        setStatusSelect(event.target.value)
                                        setPage(1)
                                    }}
                                    defaultValue={statusSelect}
                                    label="สถานะ"
                                    options={[
                                        { name: t('VOUCHER_TYPE.STATUS.ALL'), value: "ALL" },
                                        { name: t('VOUCHER_TYPE.STATUS.ACTIVE'), value: "ACTIVE" },
                                        { name: t('VOUCHER_TYPE.STATUS.INACTIVE'), value: "INACTIVE" }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} lg={4}>
                                <MainButton
                                    title={t('VOUCHER_TYPE.BUTTON.SEARCH')}
                                    onClick={() => {
                                        preparation(search, true)
                                        preparation()
                                    }}
                                    type="primary"
                                    variant="contained"
                                    style={{ height: '40px', width: '100px' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '16px' }}>
                            <ButtonExport
                                headCells={headCells}
                                portrait={true}
                                filter={''}
                                fileName={'รายการประเภท Gift Voucher'}
                                rowsData={dataVoucherExport.map((item: any, index: number) => {
                                    return renderData(item, index, true)
                                })}
                                marginPrint={'0.5cm'}
                                componentExportRef={componentExportRef}
                            />
                            <MainButton
                                onClick={() => {
                                    setName('')
                                    setNameEn('')
                                    setId(0)
                                    setIsOpen(true)
                                }}
                                title={t('VOUCHER_TYPE.BUTTON.CREATE_VOUCHER')}
                                type="primary"
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                                style={{ height: '40px' }}
                                disabled={permissions.isCreate.disabled}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box marginTop="24px">
                    <TableCustom
                        page={page}
                        pageLimit={pageLimit}
                        sortType={sortType}
                        sortBy={sortBy}
                        onSort={(sortByVal: string, sortTypeVal: string) => {
                            setSortType(sortTypeVal)
                            setSortBy(sortByVal)
                        }}
                        setPageLimit={(e) => setPageLimit(e)}
                        setPage={(e) => setPage(e)}
                        rowCount={rowCount}
                        headCells={headCells}
                        rowsData={dataVoucher.map((item: any, index: number) => {
                            return renderData(item, index)
                        })}
                    />
                </Box>
            </Box>
            <Box className={`print-show`} ref={componentExportRef}>
                <Box fontSize={'10px'} fontWeight={'500'}>{'รายการประเภทโปรโมชัน'}</Box>
                <Box fontSize={'10px'} fontWeight={'500'} display={'flex'} gap={'8px'} marginBottom={'8px'}>
                    <span>
                        {'ค้นหา'}: {search || '-'}
                    </span>
                    <span>
                        {'สถานะ'}: {statusSelect === "ALL" ? 'ทั้งหมด' : statusSelect === 'ACTIVE' ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
                    </span>
                </Box>
                <Box
                    width={'100%'}
                    marginBottom={'8px'}
                    sx={{
                        '.no-link': { display: 'flex', justifyContent: 'center', gap: '4px' },
                        'th,td': {
                            fontSize: '8px',
                            margin: '0',
                            padding: '1px',
                            borderBottom: '1px solid black',
                            borderTop: '1px solid black',
                            textAlign: 'center',
                            ':last-child': {
                                borderRight: 0,
                            }
                        }
                    }}
                >
                    <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                        <thead>
                            {_.map(headCells.filter((headCell: any) => headCell.id !== 'action' && headCell.id !== 'COMPONENT'), (val: any, index: number) => {
                                return (
                                    <th
                                        style={{
                                            textAlign: val.align ? val.align : 'left',
                                            fontSize: 8,
                                            fontWeight: 500
                                        }}>
                                        {val.label || (val.labelKey)}
                                    </th>
                                )
                            })}
                        </thead>
                        <tbody>
                            {dataVoucherExport?.length ? dataVoucherExport?.map((item: any, index: number) => {
                                return renderData(item, index, true)
                            }) : []}
                            {!dataVoucherExport?.length && (
                                <td colSpan={headCells.length}>
                                    {'NOT_FOUND'}
                                </td>
                            )}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    )
}
export default VoucherType