import { menuThirds } from "constants/menus";
import FormMemberManagement from "features/setting/member/form-member-manage";
import MemberManagement from "features/setting/member/member-manage";

const routeMemberManagement = [
    {
        ...menuThirds.MEMBER_MANAGEMENT,
        key: menuThirds.MEMBER_MANAGEMENT.key,
        name: menuThirds.MEMBER_MANAGEMENT.name,
        textName: menuThirds.MEMBER_MANAGEMENT.textName,
        active: false,
        appBar: true,
        component: MemberManagement
    },
    {
        ...menuThirds.MEMBER_MANAGEMENT.name,
        key: menuThirds.MEMBER_MANAGEMENT.key,
        packageKey: menuThirds.MEMBER_MANAGEMENT.packageKey,
        textname: `แก้ไข ${menuThirds.name}`,
        active: false,
        path: menuThirds.MEMBER_MANAGEMENT.path + '/update',
        component: FormMemberManagement,
        appBar: true
    }
]

export default routeMemberManagement