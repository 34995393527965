import { Pagination, Box, Select, MenuItem } from "@mui/material"

interface PaginationTableProps {
    onChange: (e: any, value: any) => void
    onPerPageChange: (e: any) => void
    perPage?: number
    page?: number
    totalPage?: number
}
export default function PaginationTable(props: PaginationTableProps) {

    const page = props.page || 1
    const perPage = props.perPage || 10
    const totalPage = props.totalPage || 0

    const handleDataRows = () => {
        const from = page * perPage - perPage + 1
        const to = Math.min(page * perPage, totalPage)
        return `${from}-${to} รายการ จาก ${totalPage} รายการ`
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'end', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                <span style={{ color: '#5A5A5A' }}>
                    จำนวนต่อหน้า
                </span>
                <Select value={props.perPage} defaultValue={10} variant="outlined" onChange={props.onPerPageChange} sx={{ width: 75, height: 24 }}
                    MenuProps={{
                        MenuListProps: {
                            sx: { display: 'flex', flexDirection: 'column', li: { padding: '6px 16px', justifyContent: 'flex-start' } }
                        }
                    }}
                >
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="100">100</MenuItem>
                </Select>
            </Box>
            {handleDataRows()}
            <Box>
                <Pagination
                    count={Math.ceil(totalPage / perPage)}
                    variant="outlined"
                    shape="rounded"
                    page={props.page}
                    onChange={props.onChange}
                    sx={{
                        '& .MuiPaginationItem-root': {
                            color: '#1CB99A',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #1CB99A',
                            height: '24px',
                            minWidth: '24px',
                            minHeight: '24px',
                        },
                        '& .MuiPaginationItem-ellipsis': {
                            color: '#1CB99A',
                            backgroundColor: 'inherit',
                            border: 'none',
                        },
                        '.MuiPaginationItem-page.Mui-selected': {
                            backgroundColor: '#1CB99A',
                            color: '#FFFFFF'
                        },
                        '& .MuiPaginationItem-page.Mui-selected:hover': {
                            backgroundColor: '#1CB99A',
                        },
                    }} />
            </Box>
        </Box>
    )
}