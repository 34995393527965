import { Box, Typography, Grid } from '@mui/material';
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS */
import { numberFormat, toBuddhistYear, checkPackage } from 'utils/app.utils'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { PACKAGE_ADDONS } from 'constants/package'
import { summerySub, valPayment } from 'app/slice/payment.slice'
import { useSelector } from 'react-redux'
import { PaymentInterface } from 'api/counter/payment/payment-interface'
import { useEffect, useState } from 'react';

type SummaryReceiptType = {
  initBalance?: any
  patient?: any
  date?: any
  data?: any
}

export default function SummaryReceipt(props: SummaryReceiptType) {
  const { t } = useTranslation()
  const [allItem, setAllItem] = useState<any>([])


  const renderSum = () => {
    return numberFormat(
      (Number(props.data.paidCash) || 0) +
      (Number(props.data.paidTransfer) || 0) +
      (Number(props.data.paidCredit) || 0) +
      (Number(props.data.paidDebit) || 0) +
      (Number(props.data.paidVoucher) || 0)
    )
  }
  const renderPayment = () => {

    const paymentType = []
    if (props.data.paidCash)
      paymentType.push(
        <Grid container spacing={2} className={'my-0'}>
          <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
            <Typography sx={{ fontWeight: 500 }}>
              {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.CASH`)}
            </Typography>
          </Grid>
          <Grid item sm={6} className={``} >
            <Typography className='text-right' sx={{ fontWeight: 500 }}>
              {numberFormat(props.data.paidCash || 0)}
            </Typography>
          </Grid>
        </Grid>)
    if (props.data.paidTransfer)
      paymentType.push(
        <Grid container spacing={2} className={'my-0'}>
          <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
            <Typography sx={{ fontWeight: 500 }}>
              {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.TRANSFER`)}
            </Typography>
          </Grid>
          <Grid item sm={6} className={``} >
            <Typography className='text-right' sx={{ fontWeight: 500 }}>
              {numberFormat(props.data.paidTransfer || 0)}
            </Typography>
          </Grid>
        </Grid>)
    if (props.data.paidCredit)
      paymentType.push(
        <Grid container spacing={2} className={'my-0'}>
          <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
            <Typography sx={{ fontWeight: 500 }}>
              {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.CREDIT`)}
            </Typography>
          </Grid>
          <Grid item sm={6} className={``} >
            <Typography className='text-right' sx={{ fontWeight: 500 }}>
              {numberFormat(props.data.paidCredit || 0)}

            </Typography>
          </Grid>
        </Grid>)
    if (props.data.paidDebit)
      paymentType.push(
        <Grid container spacing={2} className={'my-0'}>
          <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
            <Typography sx={{ fontWeight: 500 }}>
              {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.DEBIT`)}
            </Typography>
          </Grid>
          <Grid item sm={6} className={``} >
            <Typography className='text-right' sx={{ fontWeight: 500 }}>

              {numberFormat(props.data.paidDebit || 0)}

            </Typography>
          </Grid>
        </Grid>)
    if (props.data.paidVoucher)
      paymentType.push(
        <Grid container spacing={2} className={'my-0'}>
          <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
            <Typography sx={{ fontWeight: 500 }}>
              {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.VOUCHER`)}
            </Typography>
          </Grid>
          <Grid item sm={6} className={``} >
            <Typography className='text-right' sx={{ fontWeight: 500 }}>
              {numberFormat(props.data.paidVoucher || 0)}
            </Typography>
          </Grid>
        </Grid>)



    return paymentType
  }

  return (
    <>
      <Box className='px-md-2 px-xl-3 mb-3'>
        <Box className='d-flex justify-content-between'>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{props.patient.branchCnNumber ? `${props.patient.branchCnNumber}` : ''} <span className='pl-3 pr-2'>{t('PAYMENT.FORM_PAYMENT.NAME_PATIENT')}</span>{props.patient.firstname} {props.patient.lastname}</Typography>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{toBuddhistYear(moment(props.date), 'DD/MM/YYYY')}</Typography>
        </Box>
      </Box>
      <Box className='px-md-2 px-xl-3 mb-3'>
        <Grid container spacing={2} className={'my-0'}>
          <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
            <Typography sx={{ fontWeight: 500 }}>{t('ประเภทการชำระเงิน')}</Typography>
          </Grid>
          <Grid item sm={6} className={``} >
            <Typography className='text-right' sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TABLE.TOTAL_PAY')}</Typography>
          </Grid>
        </Grid>
        {renderPayment()}

      </Box>
      <Box className='px-md-2 px-xl-3 mb-3'>
        <Box className='d-flex align-items-center justify-content-between pt-1'>
          <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.SUM_NET_INCOME')}</Typography>
          <Typography sx={{ fontWeight: 500 }}>{renderSum()} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Box>
      </Box>
    </>
  )
}
