import { useCallback, useEffect, useState } from "react"

import SearchPatients from "component/SearchPatients"
import TableCustom from "component/Table/TableCustom"
import { useTranslation } from "react-i18next"
import AdvanceApi, { AdvanceListInterface } from "api/finances/advance.api"
import { getBranch, numberFormat, numberToCurrencyFormat } from "utils/app.utils"
import { dateTimeToView, dateToView } from "utils/date.utils"
import TableRowCommon from "component/Table/TableRowCommon"
import _ from "lodash"
import ButtonCustom from "component/Button/ButtonCustom"
import ModalCustom from "component/ModalCustom/ModalCustom"
import { Box, Typography, Divider, Grid } from '@mui/material';
import { colors } from "constants/theme"
import PaymentChannels from "./PaymentChannels"
import ClinicApi from "api/master/clinic.api"
import { setBankCredit, setBankDebit, setBankTransfer, setPaymentChannelByKey, setSummeryByKey, valBankCredit, valBankDebit, valBankTransfer } from "app/slice/payment.slice"
import { useDispatch, useSelector } from "react-redux"
import InputTextarea from "component/Input/InputTextarea"
import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'
import { Modal } from "react-bootstrap"
import Info from "./Info"
import SummaryReceipt from "./SummaryReceipt"
import { handleChangeFilterSelect } from '../../dashboard/dashboard';
import { notiError, notiSuccess } from "component/notifications/notifications"
import { resetModal } from "app/slice/modal.slice"

export interface IFormAdvanceProps {
  isShow: boolean
  advanceHistory: { history: AdvanceListInterface[], rowCount: number }
  patient: any
  banks: any
  loadHistory: (filter: any) => void
  setIsShow: (isShow: boolean) => void
  handleSuccess?: () => void
}

export function ManageAdvance(props: IFormAdvanceProps) {
  const { t } = useTranslation()
  const branchId = getBranch()
  const dispatch = useDispatch()
  const bankCredit = useSelector(valBankCredit)
  const bankDebit = useSelector(valBankDebit)
  const bankTransfer = useSelector(valBankTransfer)


  const [fillter, setFillter] = useState({ patientId: 0, page: 1, pageLimit: 10, sortBy: 'createdAt', sortType: "DESC" })
  const [dataEdit, setDataEdit] = useState<{ data?: AdvanceListInterface, action: "TRANSFER" | "ADJUST", newPatientId: number | 0, amount: 0 } | any>({})
  const [dataChange, setDataChange] = useState<any>({})
  const [disabledNext, setDisabledNext] = useState<boolean>(false)

  const [activePage, setActivePage] = useState(0)


  const paymentChannels = [
    { key: 'TREATMENTRIGHT', paymentChanel: 5, isActive: false },
    { key: 'ADVANCE', paymentChanel: 6, isActive: false },
    { key: 'CASH', paymentChanel: 1, isActive: false },
    { key: 'TRANSFER', paymentChanel: 2, isActive: false },
    { key: 'CREDIT', paymentChanel: 3, isActive: false },
    { key: 'DEBIT', paymentChanel: 4, isActive: false },
    { key: 'VOUCHER', paymentChanel: 7, isActive: false }
  ]
  useEffect(() => {
    props.loadHistory(fillter)
  }, [fillter]);

  useEffect(() => {
    setDataEdit({ data: null, action: "TRANSFER", newPatientId: 0, amount: 0 })
    setFillter({ patientId: 0, page: 1, pageLimit: 10, sortBy: 'createdAt', sortType: "DESC" })
  }, [props.isShow]);



  const loadAdvance = useCallback(async ({ data, action }) => {
    dispatch(setBankTransfer({}))
    dispatch(setBankCredit({}))
    dispatch(setBankDebit({}))
    const respAdvances = await AdvanceApi.findById(data.transectionAdvanceId)
    if (respAdvances.status === 200) {
      const paidAdvance = respAdvances.data
      const transectionAdvanceItems = paidAdvance.transectionAdvanceItems
      if (transectionAdvanceItems.length) {
        const selectChannels = []
        let paidCash = 0, paidTransfer = 0, paidCredit = 0, paidDebit = 0, paidVoucher = 0
        let usedCash = 0, usedTransfer = 0, usedCredit = 0, usedDebit = 0, usedVoucher = 0

        const paid: any = {}
        for (let index = 0; index < transectionAdvanceItems.length; index++) {
          const item = transectionAdvanceItems[index];
          const channel = _.find(paymentChannels, { paymentChanel: item.paymentChannelId })
          selectChannels.push(channel)


          const bank = _.find(props.banks, { bankId: item.bankId })
          console.log({ ...bank, cardTypeId: item.cardTypeId });

          if (item.paymentChannelId === 1) {
            paidCash = paidCash + (item.amount - item.used)
            usedCash = usedCash + item.used
            paid.paidCash = paidCash
            paid.usedCash = usedCash
          }
          else if (item.paymentChannelId === 2) {
            paidTransfer = paidTransfer + (item.amount - item.used)
            usedTransfer = usedTransfer + item.used

            paid.paidTransfer = paidTransfer
            paid.usedTransfer = usedTransfer
            dispatch(setBankTransfer(bank))
          }
          else if (item.paymentChannelId === 3) {
            paidCredit = paidCredit + (item.amount - item.used)
            usedCredit = usedCredit + item.used

            paid.paidCredit = paidCredit
            paid.usedCredit = usedCredit


            dispatch(setBankCredit({ ...bank, cardTypeId: item.cardTypeId }))

          }
          else if (item.paymentChannelId === 4) {
            paidDebit = paidDebit + (item.amount - item.used)
            usedDebit = usedDebit + item.used
            paid.paidDebit = paidDebit
            paid.usedDebit = usedDebit
            dispatch(setBankDebit({ ...bank, cardTypeId: item.cardTypeId }))

          }
          else if (item.paymentChannelId === 7) {
            paidVoucher = paidVoucher + (item.amount - item.used)
            usedVoucher = usedVoucher + item.used
            paid.paidVoucher = paidVoucher
            paid.usedVoucher = usedVoucher
          }
        }
        data.transectionAdvanceItems = transectionAdvanceItems
        setDataEdit({ data, action, selectChannels })
        setDataChange({ ...data, ...paid, })
      }
    }
  }, [])

  const handleChange = (value: any) => {
    const sumPaid = (value.paidCash || 0) + (value.paidTransfer || 0) + (value.paidCredit || 0) + (value.paidDebit || 0)
    setDataChange((old: any) => ({ ...old, ...value }))
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
    { id: 'dateAt', disablePadding: false, label: t('ADVANCE.TABLE.DATE'), class: 'text-nowrap' },
    { id: 'paymentNumber', disablePadding: false, label: t('ADVANCE.TABLE.BILL_NO'), class: 'text-nowrap' },
    { id: 'patientFullName', disablePadding: false, label: t('ADVANCE.TABLE.PATIENT_FULL_NAME'), class: 'text-nowrap' },
    // { id: 'orderPaymentChannel', disablePadding: false, label: t('ADVANCE.TABLE.PAYMENT_CHANNELS'), class: 'text-nowrap' },
    { id: 'total', disablePadding: false, label: t('ADVANCE.TABLE.TOTAL'), class: 'text-nowrap' },
    { id: 'remaining', disablePadding: false, label: t('ADVANCE.TABLE.REMAINING'), class: 'text-nowrap' },
    { id: 'statusOrder', disablePadding: false, label: t('ADVANCE.TABLE.STATUS'), class: 'text-nowrap' },
    { id: 'createdBy', disablePadding: false, label: t('ADVANCE.TABLE.STAFF'), class: 'text-nowrap' },
    { id: 'action', disablePadding: false, label: t('ADVANCE.TABLE.ACTION'), class: 'text-nowrap head-action' }
  ]

  const renderData = (objData: AdvanceListInterface, no: number) => {
    no = fillter.page * fillter.pageLimit - fillter.pageLimit + no + 1
    const { transectionAdvanceId: id, date, prefix, cnNumber, branchCnNumber, patientFullName, advanceNumber: advanceNum, paymentChannelText, amountTotal, createdBy: staffBy, statusOrder, advanceBalance } = objData

    const billNo = `${prefix}-${advanceNum}`
    const amount = numberToCurrencyFormat(amountTotal)
    const balance = numberToCurrencyFormat(advanceBalance)
    const objRenderData = {
      key: String(no),
      id: String(id),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'center', label: dateToView(date) || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: billNo || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: patientFullName || '-', class: 'text-nowrap-ellipsis' },
        // { option: 'TEXT', align: 'left', label: paymentChannelText || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: amount || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: balance || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'center', label: t(`ADVANCE.STATUS.${statusOrder}`) || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: staffBy || '-', class: 'text-nowrap-ellipsis' },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <div className="text-nowrap d-flex ">
              <ButtonCustom mode="default" className={'ml-2'} type={'button'} disabled={statusOrder === 'CANCEL'} variant={'contained'} textButton={t('ปรับเปลี่ยน')} onClick={() => loadAdvance({ data: objData, action: "ADJUST" })} />
              {/* <ButtonCustom mode="default" className={'ml-2'} type={'button'} disabled={statusOrder === 'CANCEL'} variant={'contained'} textButton={t('ปรับเปลี่ยน')} onClick={console.log} /> */}
            </div>
          ),
          class: 'text-nowrap-ellipsis'
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onClick={(e, val) => {
          return
        }}
      />
    )
  }


  const submit = () => {
    dataChange.advanceItems = []

    dataChange.amountTotal = 0
    if (dataChange.paidCash !== undefined) {
      dataChange.advanceItems.push({
        paymentChannelId: 1,
        bankId: null,
        bankNumber: null,
        cardTypeId: null,
        amount: Number(dataChange.paidCash) + Number(dataChange.usedCash),
      })
      dataChange.amountTotal += Number(dataChange.paidCash) + Number(dataChange.usedCash)
    }
    if (dataChange.paidTransfer !== undefined) {
      dataChange.advanceItems.push({
        paymentChannelId: 2,
        bankId: bankTransfer.bankId,
        bankAccountNumber: bankTransfer.bankAccountNumber,
        cardTypeId: null,
        amount: Number(dataChange.paidTransfer) + Number(dataChange.usedTransfer),
      })
      dataChange.amountTotal += Number(dataChange.paidTransfer) + Number(dataChange.usedTransfer)

    }

    if (dataChange.paidCredit !== undefined) {
      dataChange.advanceItems.push({
        paymentChannelId: 3,
        bankId: bankCredit.bankId,
        cardTypeId: bankCredit?.cardTypeId,
        bankAccountNumber: bankCredit.bankAccountNumber,
        amount: Number(dataChange.paidCredit) + Number(dataChange.usedCredit),
      })
      dataChange.amountTotal += Number(dataChange.paidTransfer) + Number(dataChange.usedTransfer)

    }

    if (dataChange.paidDebit !== undefined) {
      dataChange.advanceItems.push({
        paymentChannelId: 4,
        bankId: bankDebit.bankId,
        cardTypeId: bankDebit?.cardTypeId,
        bankAccountNumber: bankDebit.bankAccountNumber,
        amount: Number(dataChange.paidDebit) + Number(dataChange.usedDebit),
      })
      dataChange.amountTotal += Number(dataChange.paidDebit) + Number(dataChange.usedDebit)
    }

    if (dataChange.paidVoucher !== undefined) {
      dataChange.advanceItems.push({
        paymentChannelId: 7,
        bankId: null,
        bankNumber: null,
        cardTypeId: null,
        amount: Number(dataChange.paidVoucher) + Number(dataChange.usedVoucher),
      })
      dataChange.amountTotal += Number(dataChange.paidVoucher) + Number(dataChange.usedVoucher)
    }

    AdvanceApi.update(dataChange.transectionAdvanceId, {
      amountTotal: dataChange.amountTotal,
      userId: dataChange.userId,
      remark: dataChange.remark,
      advanceItems: dataChange.advanceItems
    })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          if (props?.handleSuccess) props?.handleSuccess()
          dispatch(resetModal())
          setDataEdit({ data: null, action: "TRANSFER", newPatientId: 0, amount: 0 })
          setFillter({ patientId: 0, page: 1, pageLimit: 10, sortBy: 'createdAt', sortType: "DESC" })
          setDataChange(null)
          notiSuccess(t(`ADVANCE.MESSAGE.SUCCESS.UPDATE`))
          props?.setIsShow(false)

        } else {
          const err = res.response?.data
          notiError(t(`ADVANCE.MESSAGE.${err.message}`))


        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`ADVANCE.MESSAGE.${err.message}`))


      })
      .finally(() => {
        console.log('res');
      })
  }
  return props.isShow ?
    dataEdit?.data ?
      (
        <>
          <UseStyled.ModalStyle size={'xl'} show={props.isShow} centered fullscreen>
            <Modal.Body className="py-0 overflow-hidden">
              {/* <Loading show={loading} type="fullLoading" /> */}
              <Grid container spacing={2} className="my-0 h-100 pb-4">
                <Grid lg={6} item className={`w-100 h-100 ${activePage === 0 ? 'd-block' : 'd-none'} d-xl-block`}>
                  <Box className="pr-xl-3 h-100 d-flex flex-column">
                    <Typography className="pb-4 text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                      {t('รายละเอียดการจัดการเงินล่วงหน้า')}
                    </Typography>
                    <Box className="custom-scroll" sx={{ overflowX: 'auto' }}>
                      <Info patient={props.patient} advance={dataEdit.data.amountTotal} balance={dataEdit.data.advanceBalance} />
                      <PaymentChannels
                        banks={props.banks}
                        advance={true}
                        selectChannels={dataEdit.selectChannels}
                        data={dataChange}
                        onChange={(value: any) => setDataChange((old: any) => ({ ...old, ...value }))} />
                    </Box>
                    <UseStyled.FooterStepButton className="d-flex d-xl-none justify-content-md-end mt-2 mr-md-2">
                      <ButtonCustom textButton={t('BUTTON.CANCEL')} variant="outlined" onClick={() => props.setIsShow(false)} />
                      <ButtonCustom textButton={t('BUTTON.NEXT')} disabled={disabledNext}
                        onClick={() => {
                          setActivePage(1)
                          setDisabledNext(true)
                        }}
                      />
                    </UseStyled.FooterStepButton>
                  </Box>
                </Grid>
                <Grid lg={6} item className={`w-100 h-100 ${activePage === 1 ? 'd-block' : 'd-none'} d-xl-block`} sx={{ borderLeft: { lg: `1px solid ${colors.lightGray}` } }}>
                  <Box className="h-100 d-flex flex-column">
                    <Typography className="pb-4 text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                      {t('สรุปยอดการชำระเงินล่วงหน้า')}
                    </Typography>
                    <Box className="custom-scroll mb-3" sx={{ overflowX: 'auto' }}>
                      <UseStyled.SummaryNetPrice className="d-flex align-items-center mx-md-3 mx-xl-4 mb-3">
                        <Box className="d-flex align-items-center justify-content-center flex-column h-100">
                          <Typography className="text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                            {numberFormat(dataEdit.data.amountTotal)} {t('CURRENCY_CHANGE.TH')}
                          </Typography>
                          <Typography className="text-center" sx={{ fontSize: 14, color: colors.themeMainColor }}>
                            {t('ยอดจ่ายเงินล่วงหน้า')}
                          </Typography>
                        </Box>
                        <Box className="d-flex align-items-center justify-content-center flex-column h-100" sx={{ borderLeft: `1px solid ${colors.lightGray}` }}>
                          <Typography className="text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                            {numberFormat(dataEdit.data.advanceBalance)} {t('CURRENCY_CHANGE.TH')}
                          </Typography>
                          <Typography className="text-center" sx={{ fontSize: 14, color: colors.themeMainColor }}>
                            {t('คงเหลือ')}
                          </Typography>
                        </Box>
                      </UseStyled.SummaryNetPrice>

                      <SummaryReceipt
                        date={dataEdit.data.createdAt}
                        patient={props.patient}
                        data={dataChange}
                      />
                      <Box className="px-md-2 px-xl-3 mb-md-3 pt-md-2">
                        <InputTextarea
                          key={'important-symptoms'}
                          onchange={(e: any) => {
                            setDataEdit((value: any) => ({ ...value, data: { ...value.data, remark: e.target.value } }))
                          }}
                          value={dataEdit.data.remark}
                          label={t('PAYMENT.FORM_PAYMENT.REMARK')}
                        />
                      </Box>
                    </Box>
                    <UseStyled.FooterPaymentButton className="d-flex flex-column mt-auto">
                      <ButtonCustom textButton={t('PAYMENT.FORM_PAYMENT.BUTTON_BACK_TO_DETAIL')} variant="outlined" onClick={() => {
                        setActivePage(0)
                        setDisabledNext(false)
                      }} className="d-xl-none" />
                      <ButtonCustom
                        textButton={t('BUTTON.CANCEL')}
                        variant="outlined"
                        onClick={() => {
                          setDataEdit({ data: null, action: "TRANSFER", newPatientId: 0, amount: 0 })
                          props.setIsShow(false)
                          // dispatch(resetStatePayment())
                        }}
                        className="d-none d-xl-block"
                      />
                      <ButtonCustom
                        textButton={t('PAYMENT.FORM_PAYMENT.BUTTON_PAYMENT')}
                        // disabled={loading || disabledButton}
                        onClick={submit}
                        className="mt-2 mt-md-3"
                      />
                    </UseStyled.FooterPaymentButton>
                  </Box>
                </Grid>
              </Grid>
            </Modal.Body>
          </UseStyled.ModalStyle>
        </>
      ) : (
        <ModalCustom
          size={'xl'}
          title={'จัดการรายการรับเงินล่วงหน้า'}
          // alignFooter={'end'}
          // fullscreen={'lg-down'}
          component={

            <>
              <div className="mb-4 col-12 col-md-12  col-xl-6" >
                <SearchPatients status="ACTIVE" bodyHeight={43} callbackPatientId={(id: any) => setFillter({ ...fillter, patientId: id })} patientId={fillter.patientId} onClear={() => setFillter({ ...fillter, patientId: 0 })} />
              </div>
              <TableCustom
                page={fillter.page}
                pageLimit={fillter.pageLimit}
                sortType={fillter.sortType}
                sortBy={fillter.sortBy}
                rowCount={props.advanceHistory.rowCount}
                onSort={(sortByVal: string, sortTypeVal: string) => {
                  setFillter({ ...fillter, sortType: sortTypeVal, sortBy: sortByVal })
                }}
                setPageLimit={(pageLimit) => setFillter({ ...fillter, pageLimit })}
                setPage={(page) => setFillter({ ...fillter, page })}
                headCells={headCells}
                rowsData={_.map(props.advanceHistory.history, renderData)}
              />
            </>


          }
          onReset={() => {
            setDataEdit({ data: null, action: "TRANSFER", newPatientId: 0, amount: 0 })
            props.setIsShow(false)
          }}
          onSubmit={() => {
            setDataEdit({ data: null, action: "TRANSFER", newPatientId: 0, amount: 0 })
            props.setIsShow(false)
          }}
          // disabledSubmit={loading}
          textBtnCancel={'ปิด'}
        // textBtnConfirm={t('ADVANCE.MODAL_ADD.PAYMENT')}
        />
      ) : (
      <></>
    )

}

