/** FAVICON */
import dentalFavicon from 'assets/favicon/dental.png'
import plusFavicon from 'assets/favicon/plus.png'

/** LOGO */
import dentalLogo from 'assets/logo/dental/logoCliniter.png'
import dentalLogoHorizontal from 'assets/logo/dental/logoHorizontal.png'
import plusLogo from 'assets/logo/plus/logoCliniter.png'
import plusLogoHorizontal from 'assets/logo/plus/logoHorizontal.png'

/** PAGE */
import dentalWelcome from 'assets/images/page/dentalWelcome.png'
import plusWelcome from 'assets/images/page/plusWelcome.png'
import ImgNotFound from 'assets/images/page/pageNotFound'
import AccessDenied from 'assets/images/page/accessDenied'
import DefaultUpdate from 'assets/images/defauli_uspate'

/** LOGIN */
import dentalLoginBackground from 'assets/logo/dental/loginNoBg.png'
import plusLoginBackground from 'assets/logo/plus/loginNoBg.png'

/** PICK */
import bgDentalPickLeft from 'assets/pick/dental/bg-pick-left.png'
import bgDentalPickRight from 'assets/pick/dental/bg-pick-right.png'
import bgPlusPickLeft from 'assets/pick/plus/bg-pick-left.png'
import bgPlusPickRight from 'assets/pick/plus/bg-pick-right.png'

/** ROOM */
import CounterRoom from 'assets/pick/counter'
import PaymentRoom from 'assets/pick/payment'
import DentalBranch from 'assets/pick/dental/branch'
import DentalOtherServiceRoom from 'assets/pick/dental/otherService'
import DentalRoomServiceRoom from 'assets/pick/dental/roomService'
import PlusBranch from 'assets/pick/plus/branch'
import PlusOtherServiceRoom from 'assets/pick/plus/otherService'
import PlusRoomServiceRoom from 'assets/pick/plus/roomService'

/** ICON */
import iconEdit from 'assets/icon/iconEdit.svg'
import iconEditDisable from 'assets/icon/iconEditDisable.svg'
import iconFaTrash from 'assets/icon/iconFaTrash.svg'
import iconFaTrashDisable from 'assets/icon/iconFaTrashDisable.svg'
import iconAddPhoto from 'assets/icon/iconAddPhoto.svg'
import iconFavorite from 'assets/icon/iconFavorite.svg'
import btnTrash from 'assets/icon/btn-trash.svg'
import chatBubble from 'assets/icon/chatBubble-working.svg'
import checkCircle from 'assets/icon/check-circle.svg'
import iconCheckCircle from 'assets/icon/iconCheckCircle.svg'
import incorrectCircle from 'assets/icon/incorrect-circle.svg'
import btnAdd from 'assets/icon/btnAdd.svg'
import btnUp from 'assets/icon/btnUp.svg'
import btnSave from 'assets/icon/btnSave.svg'
import dropdownEdit from 'assets/icon/dropdownEdit.svg'
import dropdownTrash from 'assets/icon/dropdownTrash.svg'
import dropdownPrint from 'assets/icon/dropdownPrint.svg'
import arrowExpand from 'assets/icon/arrows-expand-right.svg'
import btnMinus from 'assets/icon/btnMinus.svg'
import iconCalendarPlus from 'assets/icon/icon-calendar-plus.svg'
import iconFeatherEdit from 'assets/icon/icon-feather-edit.svg'
import iconFeatherEditBlack from 'assets/icon/icon-feather-edit-black.svg'
import markLine from 'assets/icon/appointment/markLine.svg'
import mark from 'assets/icon/appointment/mark.svg'
import medDefault from 'assets/icon/product/medDefault.svg'
import prodDefault from 'assets/icon/product/prodDefault.svg'
import attachmentLine from 'assets/icon/attachment-line.svg'
import trashOutlined from 'assets/icon/trashOutlined.svg'
import userCheckTrue from 'assets/icon/status/userCheckTrue.svg'
import addFrom from 'assets/icon/addForm.png'
import attachmentPlue from 'assets/icon/attachmentPlus.png'
import photo from 'assets/icon/photo.png'
import iconBack from 'assets/icon/iconBack.png'
import missedCall from 'assets/icon/callTimes.svg'
import iconDone from 'assets/icon/appointment/icon-done.svg'
import iconRight from 'assets/icon/appointment/Icon-arrow-right.svg'
import iconWaitPayment from 'assets/icon/appointment/status-btn/wait-payment.png'
import iconPendingPayment from 'assets/icon/appointment/status-btn/online-payment 1.svg'
import iconUserCheckIn from 'assets/icon/appointment/status-btn/user-checkIn.png'
import iconService from 'assets/icon/appointment/status-btn/service.png'
import iconCounterCheckIn from 'assets/icon/appointment/status-btn/counter-checkIn.png'
import iconCancel from 'assets/icon/appointment/status-btn/cancel.svg'
import iconUserCheckTrue from 'assets/icon/appointment/status-btn/userCheckTrue.svg'
import iconNotConnect from 'assets/icon/appointment/status-btn/notConnect.svg'
import iconChangeSchedule from 'assets/icon/appointment/status-btn/changeSchedule.svg'
import iconEnter from 'assets/icon/appointment/status-btn/enter.svg'
import iconUserCheckFalse from 'assets/icon/appointment/status-btn/userCheckFalse.svg'
import cancelVisit from 'assets/icon/appointment/status-btn/cancelVisit.svg'
import iconCopy from 'assets/icon/iconCopy.svg'

import iconDoctor from 'assets/icon/iconDoctor.png'

import iconDelete from 'assets/icon/iconDelete.svg'
import iconDetail from 'assets/icon/IconDetail.svg'
import eraser from 'assets/icon/eraser.svg'
import pencil from 'assets/icon/pencil.svg'
import edit_sign from 'assets/icon/edit_sign.svg'
import exclamation_mark from 'assets/icon/exclamation_mark.svg'
import previewImg from 'assets/icon/previewImg.svg'
import iconCoffeeTime from 'assets/images/coffee-time.svg'

/** ICON TSX */
import IconHighlightPen from 'assets/icon/doctor-note/iconHighlightPen'
import IconClose from 'assets/icon/iconClose'
import Refresh from 'assets/icon/refresh'
import SignOut from 'assets/icon/signOut'
import DentalIconExam from 'assets/icon/dentalDoctor'
import PlusIconExam from 'assets/icon/plusDoctor'
import ApTeeth from 'assets/icon/appointment/card/ap-card-teeth'
import ApTreatment from 'assets/icon/appointment/card/ap-card-treatment'
import IconStar from 'assets/icon/IconStar'
import IconUserPlus from 'assets/icon/iconUserPlus'
import IconUserPlusDis from 'assets/icon/iconUserPlusDis'
import IconLine from 'assets/icon/iconLine'
import IconPayment from 'assets/icon/iconPayment'
import Meatball from 'assets/icon/meatball'
import Gallery from 'assets/icon/gallery'
import StarActive from 'assets/icon/starActive'
import StarInActive from 'assets/icon/starInActive'
import DropdownEditActive from 'assets/icon/dropdownEditActive'
import DropdownTrashActive from 'assets/icon/dropdownTrashActive'
import Update from 'assets/icon/sideMenu/update'
import ActivePencil from 'assets/icon/active_pencil'
import ActiveEditSign from 'assets/icon/ActiveEditSign'
import AC_Dot from 'assets/update/ac_dot'
import Gear from 'assets/update/gear'
import NewUpdate from 'assets/update/new_update'
import Manual from 'assets/update/manual'
import WarningCircle from 'assets/icon/WarningCircle'
import MarkLine from 'assets/icon/MarkLine'
import ToothOutlined from 'assets/icon/tooth-outlined'
import ToothSolid from 'assets/icon/tooth-solid'
import matDefault from 'assets/icon/product/matDefault.svg'

/** OTHER */
import iconHamburger from 'assets/icon/navigation/icon-navigation-ham.svg'

import icocMore from 'assets/icon/navigation/more.svg'

/** DEFAULT */
import defaultAddImage from 'assets/images/add-image.svg'
import defaultAvatar from 'assets/default/defaultAvatar.svg'
import defaultToothChart from 'assets/default/patient_toothChart.png'

/** DEFAULT */
import errNoImg from 'assets/images/image-not-load.png'

/** ICON HEADER */
import Home from 'assets/icon/header/home'
import Register from 'assets/icon/header/register'
import Chart from 'assets/icon/header/chart'
import Appointment from 'assets/icon/header/appointment'
import Schedule from 'assets/icon/header/schedule'

/** ICON SIDE MENU */
import Application from 'assets/icon/sideMenu/application'
import Clinic from 'assets/icon/sideMenu/clinic'
import Counter from 'assets/icon/sideMenu/counter'
import Dentist from 'assets/icon/sideMenu/dentist'
import Doctor from 'assets/icon/sideMenu/doctor'
import Finance from 'assets/icon/sideMenu/finance'
import Inventory from 'assets/icon/sideMenu/inventory'
import Lab from 'assets/icon/sideMenu/lab'
import Report from 'assets/icon/sideMenu/report'
import XRay from 'assets/icon/sideMenu/xRay'
import XRayPlus from 'assets/icon/sideMenu/xRayPlus'
import Settings from 'assets/icon/sideMenu/settings'
import Updates from 'assets/icon/sideMenu/update'
import New from 'assets/update/New'
import Noti from 'assets/update/noti'

/** UPDATE */
import black_gear from 'assets/update/black_gear.svg'
import white_gear from 'assets/update/white_gear.svg'
import black_new from 'assets/update/black_new.svg'
import black_re from 'assets/update/black_re.svg'
import white_manual from 'assets/update/white_manual.svg'
import black_dot from 'assets/update/black_dot.svg'

/** WATERMARK */
import dentalWatermark from 'assets/logo/watermark/dental-mark.png'
import plusWatermark from 'assets/logo/watermark/plus-mark.png'
import cliniterWatermark from 'assets/logo/watermark/cliniter-mark.png'

export const favicon = {
  dentalFavicon,
  plusFavicon
}

export const logo = {
  dentalLogo,
  plusLogo,
  dentalLogoHorizontal,
  plusLogoHorizontal
}

export const imgs = {
  errNoImg,
  defaultAddImage,
  defaultProductNoImg: defaultAddImage,
  defaultMedicineNoImg: defaultAddImage,
  defaultAvatar,
  defaultToothChart,
  dentalWelcome,
  plusWelcome
}

export const Imgs = {
  ImgNotFound,
  AccessDenied,
  DefaultUpdate
}

export const loginBackground = {
  bgDentalLogin: dentalLoginBackground,
  bgPlusLogin: plusLoginBackground
}

export const pickBackground = {
  bgDentalPickLeft,
  bgDentalPickRight,
  bgPlusPickLeft,
  bgPlusPickRight,
  CounterRoom,
  PaymentRoom,
  DentalBranch,
  DentalOtherServiceRoom,
  DentalRoomServiceRoom,
  PlusBranch,
  PlusOtherServiceRoom,
  PlusRoomServiceRoom
}

export const icons = {
  iconCopy,
  iconBack,
  iconEdit,
  iconEditDisable,
  iconFaTrash,
  iconFaTrashDisable,
  iconAddPhoto,
  iconFavorite,
  iconDoctor,
  addFrom,
  btnTrash,
  btnAdd,
  btnUp,
  btnSave,
  chatBubble,
  checkCircle,
  iconCheckCircle,
  incorrectCircle,
  dropdownEdit,
  dropdownTrash,
  dropdownPrint,
  arrowExpand,
  btnMinus,
  icocMore,
  iconCalendarPlus,
  iconFeatherEdit,
  iconFeatherEditBlack,
  mark,
  markLine,
  medDefault,
  prodDefault,
  matDefault,
  attachmentLine,
  attachmentPlue,
  trashOutlined,
  iconDone,
  iconWaitPayment,
  iconPendingPayment,
  iconUserCheckIn,
  iconService,
  iconCounterCheckIn,
  iconRight,
  userCheckTrue,
  missedCall,
  iconDelete,
  iconHamburger,
  black_gear,
  white_gear,
  black_new,
  black_re,
  white_manual,
  black_dot,
  iconDetail,
  iconCoffeeTime,
  eraser,
  pencil,
  photo,
  edit_sign,
  exclamation_mark,
  previewImg,
  appointment: {
    iconCancel,
    iconWaitPayment,
    iconPendingPayment,
    iconUserCheckIn,
    iconService,
    iconCounterCheckIn,
    iconUserCheckTrue,
    iconNotConnect,
    iconChangeSchedule,
    iconEnter,
    iconUserCheckFalse,
    cancelVisit
  }
}

export const Icons = {
  SignOut,
  Refresh,
  Home,
  Register,
  Chart,
  Appointment,
  Schedule,
  Application,
  Clinic,
  Counter,
  Dentist,
  Doctor,
  Finance,
  Inventory,
  Lab,
  Report,
  XRay,
  XRayPlus,
  Settings,
  DentalIconExam,
  PlusIconExam,
  ApTeeth,
  ApTreatment,
  IconStar,
  IconUserPlus,
  IconUserPlusDis,
  IconLine,
  IconPayment,
  CounterRoom,
  DentalRoomServiceRoom,
  PaymentRoom,
  PlusRoomServiceRoom,
  IconClose,
  IconHighlightPen,
  StarActive,
  StarInActive,
  Meatball,
  Gallery,
  DropdownEditActive,
  DropdownTrashActive,
  Updates,
  ActivePencil,
  ActiveEditSign,
  AC_DOT: AC_Dot,
  Gear,
  New,
  NewUpdate,
  Manual,
  Noti,
  WarningCircle,
  MarkLine,
  ToothSolid,
  ToothOutlined
}

export const watermark = {
  dental: dentalWatermark,
  plus: plusWatermark,
  cliniter: cliniterWatermark
}

export const onImgError = ({ currentTarget }: any) => {
  currentTarget.onerror = null
  currentTarget.src = imgs.errNoImg
}

export const onProfileError = ({ currentTarget }: any) => {
  currentTarget.onerror = null
  currentTarget.src = imgs.defaultAvatar
}
