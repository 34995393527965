import { Box, Typography, Avatar, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import LabelRanking from 'component/LabelRanking'

/** UTILS */
import { memberTypeOutline, numberFormat, checkPackage, getMember } from 'utils/app.utils'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { PACKAGE_ADDONS } from 'constants/package'

/** STYLE */
import { styled } from '@mui/material/styles'
import { imgs } from 'constants/images'
import { fileUrl } from 'api/api'
import _ from 'lodash'
import { useEffect, useState } from 'react';
import { TreatmentStateInterface, promotionsTreatments, treatment } from 'app/slice/treatment.slice';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import IconPromotion from 'assets/icon/icon-promotion';
import IconGiftVoucher from 'assets/icon/icon-gift-voucher';

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 49,
  height: 49,
  [theme.breakpoints.up('xs')]: {
    width: 56,
    height: 56
  }
}))

const Balance = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  }
}))

type InfoProps = {
  patient: any
  finance: { advanceBalance: number, outstandingBalance: number }
  treatmentRights?: any[]
}

export default function Info(props: InfoProps) {
  const { t } = useTranslation()
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const [promotionUse, setPromotionUse] = useState<any>([])
  const memberAll = getMember()
  const [voucherUse, setVoucherUse] = useState<any>([])

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  const checkEvenByIndex = (index: number) => {
    if (index % 2 === 0) return 'justify-content-start'
    else return 'justify-content-end'
  }

  useEffect(() => {
    if (treatments?.summeryTreatments?.length) {
      const newPromotionUsage: any[] = []
      const newVoucherUsage: any[] = []
      treatments?.summeryTreatments.forEach((item: any) => {
        if (item?.promotion?.length) {
          item?.promotion.forEach((info: any) => {
            if (!newPromotionUsage?.some((i: any) => i?.promotionBranchId === info?.promotionBranchId)) {
              newPromotionUsage.push(info)
            }
          })
        }
        if (item?.voucherListId) {
          newVoucherUsage.push(item?.voucher)
        }
      })
      setPromotionUse(newPromotionUsage)
      setVoucherUse(newVoucherUsage)
    }

  }, [treatments])

  return (
    <Box className='d-flex px-md-2 px-xl-3 pb-3 mb-3' sx={{ borderBottom: `1px solid ${colors.lightGray}` }}>
      <ProfileAvatar alt="profile" src={renderImage(props.patient.profile)} sx={{ border: `3px solid ${_.find(memberAll, { rankId: props.patient.rankId })?.color}`, backgroundColor: colors.white }}>
        <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
      </ProfileAvatar>
      <Box className='ml-2 ml-sm-3 w-100 overflow-hidden'>
        <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
          <Typography className="d-inline-block align-middle text-ellipsis" sx={{ fontSize: { sm: '18px' }, fontWeight: 600, color: colors.themeMainColor }}>
            {props.patient.branchCnNumber ? `${props.patient.branchCnNumber}` : ''} | {props.patient.firstname} {props.patient.lastname}
          </Typography>
          <Box className="d-inline-block ml-sm-2">
            <LabelRanking rankId={props.patient.rankId} />
          </Box>
        </Box>
        <Box className='pt-2'>
          {checkPackage(PACKAGE_ADDONS.ADDON_ADVANCE) && (
            <Box className='d-flex justify-content-between'>
              <Balance className='pr-1'>{t('PAYMENT.FORM_PAYMENT.ADVANCE_BALANCE')}</Balance>
              <Balance><span className="pr-sm-2">{numberFormat(props.finance.advanceBalance)}</span> {t('CURRENCY_CHANGE.TH')}</Balance>
            </Box>
          )}
          <Box className='d-flex justify-content-between'>
            <Balance className='pr-1' sx={{ color: colors.themeThirdColor }}>{t('PAYMENT.FORM_PAYMENT.OUT_STANDING_BALANCE')}</Balance>
            <Balance sx={{ color: colors.themeThirdColor }}><span className="pr-sm-2">{numberFormat(props.finance.outstandingBalance)}</span> {t('CURRENCY_CHANGE.TH')}</Balance>
          </Box>
          {props.treatmentRights?.length ? (
            <>
              <Box className='d-flex flex-wrap '>
                {_.map(props.treatmentRights, (treatmentRight, index) => {
                  return (
                    <div className={`${checkEvenByIndex(index)} w-50 d-flex`}>
                      <Typography >{treatmentRight.treatmentRightName}</Typography>
                      <Tooltip title={t('PAYMENT.FORM_PAYMENT.TREATMENT_DISCOUNT_INFO', {
                        price: treatmentRight.isDivide === '1' ? treatmentRight.balance : treatmentRight.price,
                        unit: treatmentRight.unit === 'BAHT' ? ` ${t('CURRENCY_CHANGE.TH')}` : '%',
                        balance: treatmentRight.isDivide === '1' ? 1 : treatmentRight.balance,
                        unitIsDivide: treatmentRight.isDivide === '1' ? `${t('PAYMENT.FORM_PAYMENT.RIGHT')}` : `${t('PAYMENT.FORM_PAYMENT.TIMES')}`,
                        isNoDF: treatmentRight.isDoctorFee === '1' ? '' : `${t('PAYMENT.FORM_PAYMENT.OVERDUE_CONFIRM.NO')}`
                      })} placement="top">
                        <InfoOutlinedIcon className='ml-2' sx={{ fontSize: '16px', marginTop: '5px' }} />
                      </Tooltip>
                    </div>
                  )
                })}
              </Box>
            </>
          ) : (<></>)}
          {(promotionUse?.length) ? (
            <Box display={'flex'} flexDirection={'column'}>
              {promotionUse.map((item: any, index: number) => {
                return (
                  <Box key={index} display={'flex'} alignItems={'center'} gap={'8px'}>
                    <Box sx={{ 'path': { fill: '#A8A8A8' }, }}>
                      <IconPromotion />
                    </Box>
                    {item?.promotionFullName || ''}
                    {item?.detail &&
                      <Tooltip title={item?.detail || ''} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                        <Box>
                          <InfoIcon />
                        </Box>
                      </Tooltip>
                    }
                  </Box>
                )
              })

              }
            </Box>
          ) : <></>}
          {(voucherUse?.length) ? (
            <Box display={'flex'} flexDirection={'column'}>
              {voucherUse.map((item: any, index: number) => {
                return (
                  <Box key={index} display={'flex'} alignItems={'center'} gap={'8px'}>
                    <Box sx={{ 'path': { fill: '#A8A8A8' }, }}>
                      <IconGiftVoucher />
                    </Box>
                    {`${item?.voucherCode || ''} ${item?.voucherName}`}
                    {item?.detail &&
                      <Tooltip title={item?.detail || ''} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                        <Box>
                          <InfoIcon />
                        </Box>
                      </Tooltip>
                    }
                  </Box>
                )
              })

              }
            </Box>
          ) : <></>}
        </Box>
      </Box>
    </Box>
  )
}
