import { menuThirds } from "constants/menus"
import FormVoucher from "features/manage-crm/voucher/form-voucher"
import VoucherType from "features/manage-crm/voucher/voucher-type"
import Voucher from "features/manage-crm/voucher/voucher"
import { routeName } from "routes/routes-name"

const routeVoucher = [
    {
        ...menuThirds.VOUCHER,
        key: menuThirds.VOUCHER.key,
        name: menuThirds.VOUCHER.name,
        textName: menuThirds.VOUCHER.textName,
        active: false,
        appBar: true,
        path: routeName.voucher,
        component: Voucher
    },
    {
        ...menuThirds.VOUCHER_TYPE,
        key: menuThirds.VOUCHER_TYPE.key,
        name: menuThirds.VOUCHER_TYPE.name,
        textName: menuThirds.VOUCHER_TYPE.textName,
        active: false,
        appBar: true,
        component: VoucherType
    },
    {
        ...menuThirds.VOUCHER,
        key: menuThirds.VOUCHER.key,
        name: menuThirds.VOUCHER.name,
        textName: menuThirds.VOUCHER.textName,
        active: false,
        appBar: true,
        path: routeName.voucher + '/create',
        component: FormVoucher
    },
    {
        ...menuThirds.VOUCHER,
        key: menuThirds.VOUCHER.key,
        name: menuThirds.VOUCHER.name,
        textName: menuThirds.VOUCHER.textName,
        active: false,
        appBar: true,
        path: routeName.voucher + '/update',
        component: FormVoucher
    },
]

export default routeVoucher