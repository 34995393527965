import { Box } from '@mui/material'

/** STYLE */
import { styled } from '@mui/material/styles'
import { Dropdown } from 'react-bootstrap'
import { ButtonBase } from 'component/Button/ButtonCustom'
import ModalCustom from 'component/ModalCustom/ModalCustom'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'

export const PaymentQueueLayout = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        width: '100%',
        maxWidth: 300,
        borderRight: 0,
        '.area-collapse, .btn-collapse': {
          display: 'none'
        }
      },
      '.layout-right-side': {
        width: '100%'
      },
      '.layout-left-side-container, .layout-right-side-container': {
        opacity: '1 !important'
      }
    }
  },
  [theme.breakpoints.up('md')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        maxWidth: 340
      }
    }
  },
  [theme.breakpoints.up('lg')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        maxWidth: 300
      }
    }
  },
  [theme.breakpoints.up('xl')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        maxWidth: 356
      }
    }
  }
}))

export const FragmentLeft = styled(Box)(({ theme }) => ({
  '.slider-area': {
    flex: '0 0 100%',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 330,
      margin: 'auto',
      whiteSpace: 'nowrap'
    }
  }
}))

export const FragmentRight = styled(Box)(({ theme }) => ({
  '.btn-summary': {
    border: `1px solid ${colors.themeSecondColor}`,
    color: colors.themeSecondColor,
    padding: '2.5px 8px'
  }
}))

export const TableConfig = styled('div')(({ theme }) => ({
  '& .MuiTableContainer-root': { minHeight: '500px !important' },
  '& .MuiTableContainer-root .MuiTableHead-root .MuiTableCell-head:nth-of-type(7)': { minWidth: '320px' },
  td: { height: '48px' },

  [theme.breakpoints.up(1024)]: {
    maxWidth: `calc(100vw - 350px - ${scale.sidebarSize})`
  },
  [theme.breakpoints.up(1441)]: {
    maxWidth: `calc(100vw - 400px - ${scale.sidebarSize})`
  }
}))

export const TableDetailConfig = styled('div')(({ theme }) => ({
  td: { height: '48px' },
  '& tfoot': { display: 'none' },
  '.MuiTable-root': {
    tableLayout: 'fixed',
    '.MuiTableBody-root': {
      td: {
        verticalAlign: 'top !important'
      }
    }
  }
}))

export const DropdownCustom = styled(Dropdown)(({ theme }) => ({
  textDecoration: 'none !important',
  '.dropdown-toggle': {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    border: `1px solid ${colors.themeSecondColor} !important`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none !important',
    '&:after': {
      marginLeft: '0.5rem'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    }
  },
  '.dropdown-menu': {
    position: 'fixed !important',
    zIndex: 1201,
    marginBottom: 4,
    borderRadius: 4,
    background: colors.white,
    border: `1px solid ${colors.lightGray}`,
    maxHeight: '250px',
    maxWidth: '300px',
    overflow: 'auto',
    insetInlineEnd: '0px !important',
    insetInlineStart: "auto !important",
    '.dropdown-item': {
      textWrap: 'wrap',
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

export const THead = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: colors.themeMainColor,
  borderRadius: '8px 8px 0 0',
  padding: '0.5rem 1.5rem',
  fontSize: 16,
  color: colors.white
}))

export const TBody = styled('div')(({ theme }) => ({
  border: `1px solid ${colors.lightGray}`,
  borderTopWidth: 0,
  color: colors.textPrimary,
  fontSize: 16,
  padding: '0.625rem 1.5rem',
  '.expand-more': {
    backgroundColor: colors.white,
    border: `1px solid ${colors.textLightGray}`,
    color: colors.themeMainColor,
    borderRadius: 100,
    fontSize: 22
  }
}))

export const Cell = styled('div')(({ theme }) => ({ '&:first-of-type': { marginRight: '1.25rem', width: '100%' } }))

export const ButtonCancel = styled(ButtonBase)(({ theme }) => ({
  height: 38,
  backgroundColor: `${colors.themeThirdColor} !important`,
  color: `${colors.white} !important`,
  margin: '0 0.25rem',
  '&.disabled': {
    opacity: 0.6,
    cursor: 'default',
    pointerEvents: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 10,
    paddingRight: 10
  }
}))

export const ButtonEdit = styled(ButtonBase)(({ theme }) => ({
  height: 38,
  backgroundColor: `${colors.themeSecondColor80} !important`,
  color: `${colors.white} !important`,
  margin: '0 1rem',
  '&.disabled': {
    opacity: 0.6,
    cursor: 'default',
    pointerEvents: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 10,
    paddingRight: 10
  }
}))

export const ButtonHistoryPayment = styled(ButtonBase)(({ theme }) => ({
  height: 38,
  backgroundColor: `${colors.white} !important`,
  color: `${colors.themeMainColor} !important`,
  border: `1px solid ${colors.themeMainColor} !important`,
  margin: '0 1rem',
  '&.disabled': {
    opacity: 0.6,
    cursor: 'default',
    pointerEvents: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 10,
    paddingRight: 10
  }
}))

export const ModalCancel = styled(ModalCustom)(({ theme }) => ({
  '& .modal-dialog': { width: '600px', maxWidth: 600 }
}))
