import { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import 'features/finance/income-expenses/style.css'
import useStyles from 'features/finance/income-expenses/style'

/** API */
import { routeName } from 'routes/routes-name'
import ExpenseTypesApi from 'api/setting/finances/expense-type.api'
import IncomeExpensesApi from 'api/finances/income-expenses.api'
import PaymentChannelsApi from 'api/setting/finances/payment-channels.api'
import { fileUrl } from 'api/api'

/** CONSTANT */
import { useParams } from 'react-router'
import { Card, Col, Row } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, IconButton, Button, Paper, RadioGroup, FormControlLabel, MenuItem, Box } from '@mui/material'
import { icons } from 'constants/images'
import { colors } from 'constants/theme'

/** COMPONENTS */
import BasicSelect from 'component/Select/BasicSelect'
import InputTextArea from 'component/Input/InputTextarea'
import InputTextField from 'component/Input/InputTextField'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputRadio from 'component/Input/InputRadio'
import { dateTimeToView } from 'utils/date.utils'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { Item } from 'features/treatments/EmrHistory'
import { AddCircleRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import * as modalSlice from 'app/slice/modal.slice'
import { ModalFormExpenseType } from 'features/setting/finances/expense-types/expense-types'
import Loading from 'component/Loading'
import StatusSelect from './statusSelector-income-expenses'
import { selectMe } from 'app/slice/user.slice'


interface ItemInterface {
  expenseTypeId: number | null
  expenseTypeName: string
  price: number
  qty: number
  total: number
  image?: any,
  createdBy?: string,
  createdAt?: string,
  updatedAt?: string
}

const initItems = {
  expenseTypeId: null,
  expenseTypeName: '',
  price: 0,
  qty: 1,
  total: 0,
  image: null
}

export default function IncomeExpensesForm() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectMe)
  const loading = false
  const useType = ''
  const [taxationState, setTaxationState] = useState(false)
  const [paymentChannels, setPaymentChannels] = useState([])
  const [innerBoxTitleText, setInnerBoxTitleText] = useState('')
  const [innerBoxTableText, setInnerBoxTableText] = useState('')
  const [expenseTypes, setExpenseTypes] = useState<Array<any>>([])
  const inputRef = useRef<HTMLInputElement | null>(null)
  const headerCells = [
    {
      id: 'expenseTypeId',
      disablePadding: false,
      label: '#',
      align: 'center',
      style: { width: '60px' }
    },
    {
      id: 'expenseTypeName',
      disablePadding: false,
      label: innerBoxTableText,
      align: 'left',
      style: { width: '30%', minWidth: '250px' }
    },
    {
      id: 'qty',
      disablePadding: false,
      label: t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.QTY'),
      align: 'right',
      style: { width: '150px' }
    },
    {
      id: 'price',
      disablePadding: false,
      label: t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.PRICE'),
      align: 'right',
      style: { width: '150px' }
    },
    {
      id: 'file',
      disablePadding: false,
      label: '',
      align: 'right',
      style: { width: '100px' }
    },
    {
      id: 'total',
      disablePadding: false,
      label: t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.TOTAL'),
      align: 'right',
      style: { width: '200px' }
    },
    {
      id: 'updateBy',
      disablePadding: false,
      label: t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.CREATED_BY'),
      align: 'center',
      style: { width: '100px' }
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'Action',
      align: 'center',
      style: { width: '100px' }
    }
  ]

  const [net, setNet] = useState(Number)
  const [tax, setTax] = useState(Number)
  const [status, setStatus] = useState('')
  const [total, setTotal] = useState(Number)
  const [unitTax, setUnitTax] = useState('')
  const [remarks, setRemarks] = useState('')
  const [documentId, setDocumentId] = useState('')
  const [amountTax, setAmountTax] = useState(Number)
  const [expenseGroup, setExpenseGroup] = useState<string>('INCOME')
  const [documentDate, setDocumentDate] = useState('')
  const [paymentChannelId, setPaymentChannelId] = useState(Number)
  const [incomeExpenseItems, setIncomeExpenseItems] = useState<ItemInterface[]>([{ ...initItems }])
  const [documentStatus, setDocumentStatus] = useState('PENDING_APPROVAL')
  const [imgData, setImgData]: any = useState([])
  const [image, setImage] = useState<{
    fileId: number | null
    base64: string
    fileExtension: string
    fileSize: string
  }>({
    fileId: null,
    base64: '',
    fileExtension: '',
    fileSize: ''
  })
  const [imgName, setImgName]: any = useState([])
  const { id }: any = useParams()

  const [openFormAddExpenseType, setOpenFormAddExpenseType] = useState<boolean>(false)
  const [indexExpenseType, setIndexExpenseType] = useState<number>(0)
  const [loadSave, setLoadSave] = useState<boolean>(false)
  const [docStatusBgColor, setDocStatusBgColor] = useState('')

  const loadDataById = async () => {
    const { status: respStatus, data }: any = await IncomeExpensesApi.findById(id)
    if (respStatus === 200) {
      setExpenseGroup(data.expenseGroup)
      setDocumentDate(data.documentDate)
      setPaymentChannelId(data.paymentChannelId)
      setAmountTax(data.amountTax)
      setUnitTax(data.unitTax)
      setNet(data.net)
      setTax(data.tax)
      setTotal(data.total)
      setRemarks(data.remark)
      setIncomeExpenseItems(
        data.incomeExpenseItems.map((beIE: any) => {
          return {
            expenseTypeId: beIE.expenseTypeId,
            expenseTypeName: beIE.expenseTypeName,
            price: beIE.price,
            qty: beIE.qty,
            total: beIE.total,
            image: beIE.image,
            createdBy: beIE.createdBy,
            createdAt: beIE.createdAt,
            updatedAt: beIE.updatedAt
          }
        })
      )
      setDocumentStatus(data.documentStatus)
      setStatus(data.status)

      if (data.incomeExpenseItems.length) {
        for (const iterator of data.incomeExpenseItems) {
          prepend({ expenseTypeId: iterator.expenseTypeId, expenseTypeName: iterator.expenseTypeName, qty: iterator.qty, price: iterator.price, total: iterator.price * iterator.qty, image: iterator.image }, { focusIndex: 1 })
        }
      }
      setDocumentId(data.prefix + data.documentNo)
      if (data.hasTax === 'HAS') {
        setTaxationState(true)
        if (data.unitTax === 'PERCENT') {
          const finalTax = Math.round(data.net * (data.amountTax / 100))
          setTax(finalTax)
          const finalTotal = data.net - finalTax
          setTotal(finalTotal)
        } else {
          setTax(data.amountTax)
        }
      } else {
        setTaxationState(false)
      }
    }
  }

  type FormValues = { sampleEntry: any[] }

  const { control, getValues } = useForm<FormValues>({ defaultValues: {} })

  const { prepend } = useFieldArray({
    control,
    name: 'sampleEntry'
  })

  const renderTaxAndTotalValue = () => {
    if (taxationState) {
      if (unitTax === 'PERCENT') {
        const finalTax = Math.round(net * (amountTax / 100))
        setTax(finalTax)
        const finalTotal = net - finalTax
        setTotal(finalTotal)
      } else if (unitTax === 'BAHT') {
        setTax(amountTax)
        const finalTotal = net - amountTax
        setTotal(finalTotal)
      } else {
        return
      }
    } else {
      setTax(0)
      setTotal(net)
    }
  }

  const loadExpenseTypes = async (input: string) => {
    let condition: any = {}
    condition = { ...condition, pageLimit: 999 }

    const res = await ExpenseTypesApi.findAll(condition)
    setExpenseTypes([])
    if (res.status === 200) {
      const option = [..._.filter(res.data, (et: any) => et.expenseGroup === input)]
      setExpenseTypes(option)
    }
  }

  const renderInnerText = () => {
    if (expenseGroup === 'EXPENSE') {
      setInnerBoxTitleText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.INNER_TITLE'))
      setInnerBoxTableText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.INNER_TABLE_HEADER'))
    } else if (expenseGroup === 'INCOME') {
      setInnerBoxTitleText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.INNER_TITLE'))
      setInnerBoxTableText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.INNER_TABLE_HEADER'))
    } else {
      return
    }
  }

  const taxSelector = () => {
    if (taxationState) {

      const handleUniTax = (val: number) => {
        if (unitTax === "PERCENT") {
          if (val > 100) {
            return 100
          } else {
            return val
          }
        } else {
          return val
        }
      }

      return (
        <>
          <Col xs={12}>
            <Row className="mt-3">
              <Col xs={12} sm={6} lg={4} xl={3}>
                <InputTextField
                  label={t('INCOME_EXPENSES.INNER_TEXT.AMMOUNT_TAX')}
                  value={amountTax}
                  // type={'number'}
                  required={taxationState ? true : false}
                  onchange={(event) => {
                    const inputValue = event.target.value;
                    const pattern = /^[0-9.]+$/;
                    if (pattern.test(inputValue) && !isNaN(inputValue)) {
                      const value = parseFloat(inputValue);
                      const nonNegativeValue = value >= 0 ? value : 0;
                      setAmountTax(handleUniTax(nonNegativeValue));
                    } if (inputValue === "") {
                      setAmountTax(inputValue);
                    }
                    renderTaxAndTotalValue()
                  }}
                />
              </Col>
              <Col xs={12} sm={6} lg={4} xl={3} className="mt-3 mt-sm-0">
                <BasicSelect
                  defaultValue="PERCENT"
                  value={unitTax}
                  required={taxationState ? true : false}
                  label={t('INCOME_EXPENSES.INNER_TEXT.UNIT_TAX')}
                  onchange={(event) => {
                    setUnitTax(event.target.value)
                    setAmountTax(0);
                    renderTaxAndTotalValue()
                  }}
                  selectId="Tax Decduction Type"
                  labelId="Tax Decduction Type"
                  options={[
                    <MenuItem key={1} value="PERCENT">
                      {'%'}
                    </MenuItem>,
                    <MenuItem key={2} value="BAHT">
                      {t('INCOME_EXPENSES.INNER_TEXT.BAHT')}
                    </MenuItem>
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </>
      )
    } else {
      return <div className="d.none" />
    }
  }

  const loadPaymemntChannels = async () => {
    let condition: any = {}
    if (useType) condition = { ...condition, page: useType }

    const res = await PaymentChannelsApi.findAll(condition)
    if (res.status === 200) {
      const noneShow = [5, 6]
      const newResData = res.data?.filter((item: any) => !noneShow.includes(item.paymentChannelId))
      setPaymentChannels(newResData)
    }
  }

  const renderPaymentChannels = (objData: any, no: number) => {
    const { paymentChannelId: paymentChannelIdData, paymentChannelName } = objData
    return <MenuItem value={paymentChannelIdData}>{paymentChannelName}</MenuItem>
  }

  const openImgOnInsert = (img: any) => {
    if (img.fileExtension === "pdf") {
      const w = window.open('_blank');
      if (w) {
        w.document.write('<body style="margin: 0px"></body>')
        w.document.write(
          `<div style="background-color:black;text-align:center;height:100%;width:100%">
                <iframe style="min-height:100vh;width:100%" frameborder="0" src=${"data:application/pdf;base64," + img.base64}></iframe>
            </div>`
        )
      }
    }
    if (img.fileExtension !== "pdf") {
      const w = window.open('_blank');
      if (w) {
        w.document.write('<body style="margin: 0px"></body>')
        w.document.write(
          `<div style="background-color:black;text-align:center;height:100%;width:100%">
                <img style="display:block; margin:auto; height:100%;" src=${"data:image/png;base64," + img.base64}></img>
                </div>`
        )
      }
    }
  }
  const onChangePicture = (e: any, index: any) => {
    const name = [...imgName]
    if (e.target.files[0]) {
      /**
       * e.target.files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = e.target.files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setImgData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      name[index] = { ...imgName[index], name: file.name }
      setImgName(name)
      reader.readAsDataURL(file)
      setImage(detailImage)
      inputRef.current?.click()
      return detailImage
    }
  }

  const onSave = () => {
    let defaultUnitTax = ''
    if (unitTax === '' && taxationState) {
      notiError(t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_UNIT_TAX'))
    }
    else if (unitTax === '') {
      setUnitTax('BAHT')
      defaultUnitTax = 'BAHT'
    } else {
      defaultUnitTax = unitTax
    }

    const finalData = {
      status: status,
      documentDate: documentDate,
      documentStatus: documentStatus,
      expenseGroup: expenseGroup,
      paymentChannelId: paymentChannelId,
      hasTax: taxationState ? 'HAS' : 'NOT_HAS',
      amountTax: amountTax,
      unitTax: defaultUnitTax,
      net: net,
      tax: tax,
      total: total,
      remark: remarks,
      incomeExpenseItems: incomeExpenseItems.map((obj: any) => {
        return {
          expenseTypeId: obj.expenseTypeId,
          expenseTypeName: obj.expenseTypeName,
          price: obj.price,
          qty: obj.qty,
          total: obj.total,
          image: obj.image?.fileId ? { fileId: obj.image?.fileId, base64: obj.image?.base64 || '', fileExtension: 'pdf', fileSize: obj.image?.fileSize || '' } : obj.image
        }
      }
      )
    }
    if (incomeExpenseItems.length === 0) return notiError(t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_DETAIL'))
    if (_.find(incomeExpenseItems, (et: any) => et.expenseTypeId === null)) return notiError(expenseGroup === 'EXPENSE' ? t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_EXPENSE') : t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_INCOME'))
    // if (_.find(incomeExpenseItems, (et: any) => et.image === null)) return notiError(t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_IMAGE'))
    if (!documentDate) return notiError(t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_DOC_DATE'))
    if (!documentStatus) return notiError(t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_DOC_STATUS'))
    if (!expenseGroup) return notiError(t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_EXPENSE_GROUP'))
    if (!paymentChannelId) return notiError(t('INCOME_EXPENSES.MESSAGE.PLEASE_SET_PAYMENT_CHANNEL'))
    setLoadSave(true)
    onSubmit(finalData)
  }

  const onSubmit = async (inputArray: any) => {
    if (id !== undefined) {
      const res = await IncomeExpensesApi.update(id, inputArray)
      if (res.status === 200) {
        notiSuccess(t('INCOME_EXPENSES.MESSAGE.SUCCESS.UPDATE'), 'go', routeName.incomeExpenses)
      } else {
        setLoadSave(false)
        notiError('Error Status: 400')
      }
    } else {
      delete inputArray['status']
      const res: any = await IncomeExpensesApi.create(inputArray)
      if (res.status === 201) {
        notiSuccess(t('INCOME_EXPENSES.MESSAGE.SUCCESS.CREATE'), 'go', routeName.incomeExpenses)
      } else {
        setLoadSave(false)
        const err = res?.response?.data.message || ''
        notiError(t(`INCOME_EXPENSES.MESSAGE.${err}`))
      }
    }
  }

  useEffect(() => {
    let combined = 0
    for (const i of incomeExpenseItems) {
      const netTotal = i.price * i.qty
      combined = combined + netTotal
    }
    setNet(combined)
    renderTaxAndTotalValue()

    // incomeExpenseItems.map((obj, i)=>{
    //   if(obj.image?.fileId){
    //   obj.image = {fileId: obj.image?.fileId, base64: obj.image?.base64, fileExtension: 'pdf', fileSize: obj.image?.fileSize}
    //   console.log(obj)
    //   }
    //   // setIncomeExpenseItems(obj)
    // }
    // )
  }, [incomeExpenseItems])

  const onChangItems = (index: any, key: string, val: any) => {
    console.log("222 val", val)
    const temp: ItemInterface[] = [...incomeExpenseItems]
    if (['qty', 'price'].includes(key)) {
      const totalIncome = Number(key === 'qty' ? val : incomeExpenseItems[index].qty) * Number(key === 'price' ? val : incomeExpenseItems[index].price)
      temp[index] = { ...incomeExpenseItems[index], total: totalIncome, [key]: val }
    } else {
      temp[index] = { ...incomeExpenseItems[index], [key]: val }
    }
    setIncomeExpenseItems(temp)
  }

  const renderExpenseTpye = (id: number) => {
    const [newItem] = expenseTypes.filter((item: any) => {
      if (item.expenseTypeId === id) {
        return item
      }
    })
    return newItem || {}
  }

  const addRow = () => {
    setIncomeExpenseItems([...incomeExpenseItems, { ...initItems }])
  }
  const delRow = (index: any) => {
    const name = [...imgName]
    name[index] = null
    setImgName(name)
    setIncomeExpenseItems([...incomeExpenseItems.filter((ie: any, i: any) => Number(i) !== Number(index))])
  }

  const popFormAddExpenseType = (index: number) => {
    setIndexExpenseType(index)
    setOpenFormAddExpenseType(true)
    dispatch(modalSlice.showModal())
  }

  const handleAfterSavePopAddExpenseType = (data: any) => {
    onChangItems(indexExpenseType, 'expenseTypeId', data.expenseTypeId)
  }

  const renderInnerContent = () => {
    if (expenseGroup) {
      return (
        <>
          <Row>
            <Col xs={12} className="mt-md-3">
              <p style={{ fontSize: '18px' }}>{innerBoxTitleText}</p>
            </Col>
            <Col sm={6} lg={4} xl={3}>
              <BasicSelect
                label={t('INCOME_EXPENSES.INNER_TEXT.PAYMENT_CHANNEL')}
                selectId="payment id"
                labelId="payment label id"
                required={true}
                value={paymentChannelId}
                onchange={(event) => setPaymentChannelId(event.target.value)}
                options={paymentChannels.map((val, i) => renderPaymentChannels(val, i))}
              />
            </Col>
            <Col className="mt-2 mt-sm-0">
              <InputCheckbox
                checked={taxationState}
                onChange={(event) => {
                  setTaxationState(!taxationState)
                  setAmountTax(0);
                }}
                label={t('INCOME_EXPENSES.INNER_TEXT.DEDUCT_TAX')}
              />
            </Col>
            {taxSelector()}
            <div style={{ borderRadius: '8px', margin: '1em 0' }}>
              <TableContainer component={Paper} style={{ boxShadow: 'none', minHeight: 'calc(100% - 65px)', overflow: 'hidden' }}>
                <Table size="small" sx={{ minWidth: 650 }}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      {headerCells.map((innerTableCell: any) => (
                        <TableCell
                          key={innerTableCell.id}
                          align={innerTableCell.align}
                          padding={'none'}
                          sx={{
                            ...innerTableCell?.style
                          }}
                        >
                          {innerTableCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={`sm-data-table ${classes.tableBody}`}>
                    <TableRow className="cursor-pointer" sx={{ '&:hover button': { fontWeight: 600 } }}>
                      <TableCell className={`${classes.columeHover}`} onClick={addRow} colSpan={9}>
                        <Button className="c-1cb99a" variant="text" sx={{ fontSize: '16px' }}>
                          + {t('INCOME_EXPENSES.BUTTON.ADD_ROW')}
                        </Button>
                      </TableCell>
                    </TableRow>
                    {incomeExpenseItems.map((item: ItemInterface, index: number) => (
                      <TableRow key={index}>
                        <TableCell className="text-center py-2">{index + 1}</TableCell>
                        <TableCell className="py-2">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 'calc(100% - 32px)' }}>
                              <AutocompleteSelect
                                maxHight={200}
                                labelId={'expense-type-id'}
                                key={`expense-type-id`}
                                options={expenseTypes || []}
                                noOptionsText={t('NOT_FOUND')}
                                onchange={(e, value) => {
                                  if (value?.expenseTypeId) {
                                    onChangItems(index, 'expenseTypeId', value.expenseTypeId)
                                  }
                                }}
                                getOptionLabel={(option: any) => (option.expenseTypeId ? option.expenseTypeName : '')}
                                value={item?.expenseTypeId && item?.expenseTypeId !== null &&
                                  renderExpenseTpye(item.expenseTypeId) || ''}
                              />
                            </Box>
                            <Box sx={{ marginLeft: '4px' }}>
                              <IconButton
                                className={'p-0'}
                                sx={{ color: colors.themeSecondColor, backgroundColor: 'transparent !important' }}
                                component="span"
                                onClick={() => popFormAddExpenseType(index)}
                              >
                                <AddCircleRounded style={{ fontSize: 28 }} />
                              </IconButton>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell className="py-2">
                          <InputTextField
                            // type="number"
                            key={`qty-${index}`}
                            label={''}
                            value={item.qty}
                            inputProps={{ min: 0, stap: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                            inputTextAlign={'right'}
                            onchange={(e) => onChangItems(index, 'qty', Number(String(e.target.value).replace(/[^0-9]/g, '')))}
                          />
                        </TableCell>
                        <TableCell className="py-2">
                          <InputTextField
                            // type="number"
                            key={`lot-price-${index}`}
                            label={''}
                            inputProps={{ min: 0, stap: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                            inputTextAlign={'right'}
                            value={item.price}
                            onchange={(e) => onChangItems(index, 'price', Number(String(e.target.value).replace(/[^0-9.]/g, '')))}
                          />
                        </TableCell>
                        <TableCell className="py-2" sx={{ maxWidth: '150px' }}>
                          <label htmlFor={`profilePic-${index}`} key={`img-${index}`} style={{ color: '#006BC8', textDecoration: item.image === null || item.image === '' ? 'none ' : 'underline', width: '100%' }}>
                            {imgName[index]?.name || incomeExpenseItems[index]?.image?.fileName ?
                              incomeExpenseItems[index]?.image?.fileId === null ?
                                <div onClick={() => openImgOnInsert(incomeExpenseItems[index]?.image)}>
                                  <img className="mb-2" style={{}} src={icons.photo} /> {incomeExpenseItems[index]?.image?.fileName ? incomeExpenseItems[index]?.image?.fileName : imgName[index]?.name}
                                </div>
                                :
                                (
                                  <a
                                    href={`${fileUrl}/${incomeExpenseItems[index]?.image?.filePath}/${incomeExpenseItems[index]?.image?.fileName}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    <img className="mb-0" style={{}} src={icons.photo} />
                                    <Box className={'text-ellipsis'}>
                                      {incomeExpenseItems[index]?.image?.fileName ? incomeExpenseItems[index]?.image?.fileName : imgName[index]?.name}boibojno
                                    </Box>
                                  </a>

                                ) : (
                                <>
                                  <img src={icons.attachmentPlue} />
                                  {` ${t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.ATTACH_PIC')}`}
                                </>
                              )}
                          </label>
                          <input
                            hidden
                            id={`profilePic-${index}`}
                            key={`img-${index}`}
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            onChange={(e) => {
                              onChangItems(index, 'image', onChangePicture(e, index))
                            }}
                          />
                        </TableCell>
                        <TableCell className="text-end h-100 m-0 py-2">
                          {Intl.NumberFormat('th-TH', {
                            style: 'currency',
                            currency: 'THB'
                          }).format(item.total)}
                        </TableCell>
                        <TableCell className="text-center py-2">
                          {item?.createdBy &&
                            <Box
                              className={`position-relative text-center text-ellipsis mx-auto`}
                              sx={{ bottom: '8px', display: 'none' }}>
                              {item?.createdBy}
                              <br />
                              <span style={{ color: colors.textLightGray }}>{dateTimeToView(item?.updatedAt || item?.createdAt || '', false)}</span>
                            </Box>
                          }
                        </TableCell>
                        <TableCell className="text-center py-2">
                          <IconButton aria-label="delete" onClick={() => delRow(index)}>
                            <img src={icons.btnMinus} alt="discard" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Row>
          <Row>
            <Col>
              <InputTextArea
                label={t('INCOME_EXPENSES.INNER_TEXT.REMARKS')}
                size={'medium'}
                inputProps={'multiline'}
                helperText={''}
                value={remarks}
                onchange={(event) => {
                  setRemarks(event.target.value)
                }}
              />
            </Col>
            <Col>
              <div>
                <p style={{ display: 'inline-block' }}>{t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.TOTAL_PRICE')}</p>
                <p className="float-end" style={{ display: 'inline-block', marginRight: '1em' }}>
                  {t('INCOME_EXPENSES.INNER_TEXT.BAHT')}
                </p>
                <p className="float-end" style={{ display: 'inline-block', marginRight: '3.5em' }}>
                  {Intl.NumberFormat('th-TH', {
                    style: 'currency',
                    currency: 'THB'
                  }).format(net)}
                </p>
                <br />
                <p style={{ display: 'inline-block' }}>{t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.TAX')}</p>
                <p className="float-end" style={{ display: 'inline-block', marginRight: '1em' }}>
                  {t('INCOME_EXPENSES.INNER_TEXT.BAHT')}
                </p>
                <p className="float-end" style={{ display: 'inline-block', marginRight: '3.5em' }}>
                  {Intl.NumberFormat('th-TH', {
                    style: 'currency',
                    currency: 'THB'
                  }).format(tax)}
                </p>
              </div>
              <div className="py-2" style={{ borderTop: `1px solid ${colors.textLightGray}`, borderBottom: `3px double ${colors.textLightGray}` }}>
                <strong style={{ display: 'inline-block' }}>{t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.NET_TOTAL')}</strong>
                <strong className="float-end" style={{ display: 'inline-block', marginRight: '1em' }}>
                  {t('INCOME_EXPENSES.INNER_TEXT.BAHT')}
                </strong>
                <strong className="float-end" style={{ display: 'inline-block', marginRight: '3.5em' }}>
                  {Intl.NumberFormat('th-TH', {
                    style: 'currency',
                    currency: 'THB'
                  }).format(total)}
                </strong>
              </div>
            </Col>
            <div style={{ height: '5em' }} />
          </Row>
        </>
      )
    } else {
      return <div style={{ height: '30em' }} />
    }
  }

  useEffect(() => {
    loadPaymemntChannels()
    if (id !== undefined) {
      loadDataById()
    } else {
      prepend({ expenseTypeId: 1, expenseTypeName: '', qty: 1, price: 0, total: 0 }, { focusIndex: 1 })
      setDocumentId('-')
    }
  }, [])

  const handleChangeStatus = (newStatus: string) => setDocumentStatus(newStatus)

  useEffect(() => {
    renderInnerText()
    loadExpenseTypes(expenseGroup)
  }, [expenseGroup])

  useEffect(() => {
    renderTaxAndTotalValue()
  }, [unitTax, getValues(`sampleEntry`), net, amountTax])

  const changeStatusSelectBgColor = () => {
    if (documentStatus === 'PENDING_APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.pending)
    } else if (documentStatus === 'APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.approve)
    } else if (documentStatus === 'UNAPPROVAL') {
      setDocStatusBgColor(colors.approveStatus.cancel)
    } else {
      setDocStatusBgColor(colors.disabledLightGray)
    }
  }

  useEffect(() => {
    changeStatusSelectBgColor()
  }, [documentStatus])


  return (
    <>
      <Box >
        <Loading show={loadSave} type="fullLoading" />
      </Box>

      <div className={`container-card overflow-hidden`}>
        <div className="d-flex flex-column h-100 overflow-auto mb-4">
          <HeaderLabel
            text={id ? t('INCOME_EXPENSES.TITLE_UPDATE') : t('INCOME_EXPENSES.TITLE_CREATE')}
            goBack={routeName.incomeExpenses}
            disabledSend={loading}
            onSubmit={onSave}
            navigator={{
              previousTo: routeName.incomeExpenses,
              previousTitle: t('INCOME_EXPENSES.TITLE'),
              currentTitle: id ? t('INCOME_EXPENSES.TITLE_UPDATE') : t('INCOME_EXPENSES.TITLE_CREATE')
            }}
            inputStatus={
              <>
                <Col sm={'auto'} style={{ marginTop: '18px', marginRight: '18px' }}>
                  <StatusSelect
                    onchange={(event) => {
                      const value = event.target.value
                      if (value) {
                        handleChangeStatus(value)
                      }
                    }}
                    label={''}
                    selectId="select-status"
                    value={documentStatus}
                    labelId="label-status"
                    statusSelectorBgColor={docStatusBgColor}
                    disable={documentStatus === 'APPROVAL'}
                    permissionDenied={user.hasApproveIncomeAndExpenses === 'PERMISS'}
                  />
                </Col>
              </>
            }
          />

          <Card className="border shadow-sm mx-4 p-4 ">
            <Card.Body className="h-100 p-0">
              <Row>
                <Col xs={12} lg={3} xl={4} className="order-lg-last text-right">
                  {t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_NO')} {documentId || '-'}
                </Col>
                <Col sm={6} lg={4} xl={3} className="mt-3 mt-lg-0">
                  <InputSecondNewDatePicker
                    key={'documentDate'}
                    required
                    label={t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_CREATION_DATE')}
                    value={documentDate}
                    onchange={(e: any) => {
                      setDocumentDate(moment(e).format('YYYY-MM-DD'))
                    }}
                  />
                </Col>
                <Col sm={12} md={6} lg={5} xl={5} className="mt-3 mt-lg-0">
                  <span className="mr-3">{t('INCOME_EXPENSES.TABLE.CELL.HEADER.EXPENSE_TYPE')}</span>
                  <RadioGroup
                    row
                    value={expenseGroup}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="d-inline"
                    onChange={(event) => {
                      setExpenseGroup(event.target.value)
                      renderInnerText()
                    }}
                  >
                    <FormControlLabel value="INCOME" control={<InputRadio />} label={t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.TITLE')} />
                    <FormControlLabel value="EXPENSE" control={<InputRadio />} label={t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.TITLE')} />
                  </RadioGroup>
                </Col>
              </Row>
              {renderInnerContent()}
            </Card.Body>
          </Card>
        </div>
      </div>

      {openFormAddExpenseType &&
        <ModalFormExpenseType
          loadData={() => loadExpenseTypes(expenseGroup)}
          afterSave={handleAfterSavePopAddExpenseType}
          expenseGroup={expenseGroup}
        />
      }

    </>

  )
}
