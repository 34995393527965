import { Box, Modal, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import ErrorIcon from "@mui/icons-material/ErrorOutline"
import SuccessIcon from "@mui/icons-material/CheckCircleOutline"
import MainButton from "../buttons/main-button"

interface AlertModalProps {
    isOpen: boolean
    handleClose: () => void
    children?: ReactNode
    type: "error" | "warning" | "success"
    onSubmit: { onClick: () => void, title?: string, }
    onCancel: { onClick: () => void, title?: string, type?: "secondary" | 'danger' | 'primary' }
    title: string,
    message?: string,
    onCloseSubmit?: boolean
    onCloseCancel?: boolean
}

export default function AlertModal(props: AlertModalProps) {

    ////-------------------
    ////    SX
    ////-------------------
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: '#F7F7F7',
        borderRadius: '10px',
        boxShadow: 24,
        maxWidth: '95vw',
        maxHeight: '90vh',
        overflow: 'auto',
        paddingX: '32px',
        paddingY: '32px'

    };

    ////-------------------
    ////    RENDER
    ////-------------------
    return (
        <Modal open={props.isOpen} onClose={props.handleClose}>
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '16px' }}>
                    {props.type === 'success'
                        ?
                        <SuccessIcon sx={{ width: '80px', height: '80px', color: "#1CB99A" }} />
                        :
                        <ErrorIcon sx={{ width: '80px', height: '80px', color: props.type === 'error' ? '#FB5252' : '#FFC42E' }} />
                    }
                </Box>
                <Box display="flex" justifyContent="center" flexDirection='column' gap="16px" textAlign="center">
                    <Typography variant="body1" fontSize="16px" fontWeight={600} color="#29302E">{props.title}</Typography>
                    <Typography fontSize="14px" fontWeight={400}>{props.message}</Typography>
                </Box>
                {props.children}
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px', paddingTop: '16px' }}>
                    {!props.onCloseCancel && <MainButton onClick={props.onCancel?.onClick} title={props.onCancel?.title || 'ยกเลิก'} type={props.onCancel.type || 'secondary'} variant="outlined" style={{ width: 120 }} />}
                    {!props.onCloseSubmit && <MainButton onClick={props.onSubmit?.onClick} title={props.onSubmit?.title || 'บันทึก'} type={props.type === 'error' ? 'danger' : 'primary'} variant="contained" style={{ width: 120 }} />}
                </Box>
            </Box>
        </Modal>
    )
}