import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Grid, MenuItem, Typography, Box } from '@mui/material'

import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { CardSalesProps, handleChangeFilterSelect, renderFilterSelect } from 'features/dashboard/dashboard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** UTILS || SLICE */
import { numberFormat } from 'utils/app.utils'

/** API */
import { BranchInterface } from 'api/master/clinic.api'

/** STYLE */
import * as UseStyled from 'features/dashboard/useStyled'
import DashboardApi, { IFPaymentChannel, IFSumeryFilter } from 'api/dashboard.api'
import { KEY_STORAGE } from 'constants/common'

const bg = colors.dashboard.paymentMethod

export default function PaymentMethod(props: CardSalesProps) {
  const { t } = useTranslation()

  const PAYMENT_CHANNELS: any = { 1: 'CASH', 2: 'TRANSFER', 3: 'CREDIT', 4: 'DEBIT', 5: 'TREATMENT_RIGHT', 6: 'ADVANCE' }

  const [data, setData]: any = useState({
    labels: [],
    datasets: []
  })
  const [lists, setLists]: any[] = useState([])

  const [loadFirst, setLoadFirst] = useState(false);
  const dashboardMain = localStorage.getItem(KEY_STORAGE.DASHBOARD)
  const dashboardCurrent = dashboardMain ? JSON.parse(dashboardMain) : {}

  const [count, setCount] = useState<number>(0)
  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [branch, setBranch] = useState<(string | number)[]>(dashboardCurrent?.paymentMethod?.branch || ['ALL'])
  const [rangeDate, setRangeDate] = useState(dashboardCurrent?.paymentMethod?.rangeDate || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const [summary, setSummary] = useState<IFPaymentChannel[]>([]);

  const handleChangeSelectBranch = (e: any) => {
    setBranch(handleChangeFilterSelect(e))
  }

  useEffect(() => {
    if (!loadFirst) {
      const timer = setTimeout(() => {
        setLoadFirst(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [props.rangeDate, props.branches]);

  useEffect(() => {
    setBranches(props.branches || [])
  }, [props.branches])

  useEffect(() => {
    loadFirst && setRangeDate(props.rangeDate || [])
  }, [props.rangeDate])

  useEffect(() => {
    loadFirst && setBranch(props.branch || [])
  }, [props.branch])

  useEffect(() => {
    props?.handleChangeDashboard && props?.handleChangeDashboard("paymentMethod,branch", branch)
  }, [branch])

  useEffect(() => {
    props?.handleChangeDashboard && props?.handleChangeDashboard("paymentMethod,rangeDate", rangeDate)
  }, [rangeDate])

  const loadData = useCallback(async () => {
    const condition: IFSumeryFilter = { dateStart: rangeDate[0], dateEnd: rangeDate[1], branchId: branch[0] === 'ALL' ? `` : branch.toString() }
    const res = await DashboardApi.findTopPaymentChannel(condition)
    const labels: any = []
    const newLists: any = []
    const sumAmountPaid: any = []
    setCount(0)
    setLists([])
    if (res.status === 200) {
      const summeris: IFPaymentChannel[] = res.data
      setSummary(summeris)

      for (const x of summeris) {
        if (x.paymentChannelId) {
          setCount((values: any) => values + x.countPaymentchannel)
          labels.push(t(`DASHBOARD.PAYMENT_CHART.PAYMENT_METHODS.${PAYMENT_CHANNELS[x.paymentChannelId]}`))
          sumAmountPaid.push(x.sumAmountPaid)
          newLists.push(x)
        }
      }
    }
    setLists(newLists)
    setData({
      labels,
      datasets: [
        {
          data: sumAmountPaid,
          spacing: !_.isEmpty(sumAmountPaid) ? 1 : 0,
          backgroundColor: bg,
          hoverBackgroundColor: bg,
          borderWidth: !_.isEmpty(sumAmountPaid) ? 1 : 0,
          cutout: '75%',
        }
      ]
    })
  }, [branch, rangeDate])

  useEffect(() => {
    loadData()
  }, [loadData])
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return ` ${context.label}: ${numberFormat(context.raw)}`
          }
        }
      },
    },
  }

  return (
    <UseStyled.Card>
      <Box className='pt-3 px-3 px-xl-4' sx={{ borderBottom: `1px solid ${colors.lightGray}`, paddingBottom: '12px' }}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={12} md={4} lg={12} xl={4} className={'w-100'}>
            <UseStyled.Title type='title' sx={{ flex: 'none' }}>{t('DASHBOARD.PAYMENT_METHODS')}</UseStyled.Title>
          </Grid>
          <Grid item xs={6} md={4} lg={6} xl={4} className={'w-100 pr-sm-2'}>
            <FilterSelect
              multiple
              label={t('')}
              labelId="label-branch"
              selectId="select-branch"
              onchange={handleChangeSelectBranch}
              renderValue={() => `${t('DASHBOARD.FILTER.BRANCH')}: ${renderFilterSelect('branch', branches, branch) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('branch', branches, branch)}`}
              value={branch}
              options={[
                <MenuItem
                  key="ALL"
                  value="ALL"
                  sx={{
                    '&.MuiMenuItem-root.Mui-selected': {
                      pointerEvents: _.includes(branch, 'ALL') ? `none` : 'auto',
                      backgroundColor: _.includes(branch, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                      color: _.includes(branch, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                    }
                  }}>
                  {t('STATUS.ALL')}
                </MenuItem>,
                _.map(props.branches, (val: BranchInterface, indexBranch: number) => {
                  return (
                    <MenuItem
                      key={indexBranch}
                      value={val.branchId}
                      sx={{
                        '&.MuiMenuItem-root.Mui-selected': {
                          backgroundColor: _.includes(branch, val.branchId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                          color: _.includes(branch, val.branchId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                        }
                      }}>
                      {val.branchName}
                    </MenuItem>
                  )
                })
              ]}
              formControlClass={'not-max-width'}
              classesOption="style-select-doctors"
            />
          </Grid>
          <Grid item xs={6} md={4} lg={6} xl={4} className={'w-100'}>
            <InputSecondNewRangePicker
              inputHeight={32}
              value={rangeDate}
              onchange={(val) => {
                if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
              }}
              allowClear
              onClear={() => setRangeDate(emptyRangePicker)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className='py-3 pt-xl-4 pb-1 px-3 px-xl-4'>
        <Grid container spacing={1} alignItems={!_.isEmpty(summary) ? 'center' : 'start'}>
          <Grid item xs={5} sm={4} lg={6} xl={4} className="w-100">
            <UseStyled.DoughnutChart type='payment' className='mx-auto ml-sm-0 position-relative'>
              <Doughnut options={options} data={data} />
              {_.isEmpty(summary) && <UseStyled.EmptyDoughnutChart /> || <></>}
              <UseStyled.DoughnutDataInside className='text-center position-absolute'>
                <Typography>{t('DASHBOARD.PAYMENT_CHART.PAYMENT_COUNT', { qty: numberFormat(count, 0, 0) })}</Typography>
                <Typography sx={{ fontSize: '14px', color: `${colors.textExtraLightGray} !important` }}>{t('DASHBOARD.PAYMENT_CHART.PAYMENT')}</Typography>
              </UseStyled.DoughnutDataInside>
            </UseStyled.DoughnutChart>
          </Grid>
          <Grid item xs={7} sm={8} lg={6} xl={8} className="w-100">
            <Typography className='mt-3 mt-sm-0 mb-2' sx={{ fontWeight: 500 }}>{t('DASHBOARD.PAYMENT_CHART.PAYMENT_METHODS.TITLE')}</Typography>
            <Grid container spacing={1}>
              {!_.isEmpty(summary) ? (
                _.map(lists, (list: IFPaymentChannel, i: number) => {
                  return (
                    <Grid item xs={6} lg={12} xl={6} className="w-100">
                      <Box className='d-flex align-items-center'>
                        <UseStyled.LabelColor color={bg[i]} />
                        <Typography className='ml-2' sx={{ fontSize: '18px', fontWeight: 500 }}>{numberFormat(list.sumAmountPaid)}</Typography>
                      </Box>
                      <Typography sx={{ color: `${colors.textExtraLightGray} !important` }}>
                        {t(`DASHBOARD.PAYMENT_CHART.PAYMENT_METHODS.${PAYMENT_CHANNELS[list.paymentChannelId]}`)}({t('DASHBOARD.PAYMENT_CHART.PAYMENT_COUNT', { qty: numberFormat(list.countPaymentchannel, 0, 0) })})
                      </Typography>
                    </Grid>
                  )
                })
              ) : (
                <Grid item xs={12} className="w-100 py-3 py-lg-5">
                  <Box className='text-center'>{t('NOT_FOUND')}</Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </UseStyled.Card>
  )
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
)
