/** LOCALE TH */
import common from 'locales/dental/th/common.json'
import validation from 'locales/dental/th/validation.json'
import login from 'locales/dental/th/login.json'
import menus from 'locales/dental/th/menu.json'
import home from 'locales/dental/th/home/home.json'
import breadcrumb from 'locales/dental/th/breadcrumb.json'
import clinicManagement from 'locales/dental/th/admin/clinic-management.json'
import custom from 'locales/dental/th/admin/custom.json'
import labOrder from 'locales/dental/th/lab-order.json'
import historyTreatment from 'locales/dental/th/historyTreatment/historyTreatment.json'
import dashboard from 'locales/dental/th/dashboard.json'
import retrospectiveOrder from 'locales/dental/th/retrospectiveOrder.json'
import retrospectiveEdit from 'locales/dental/th/retrospectiveEdit.json'
import confirmAppointment from 'locales/dental/th/confirmAppointment.json'
import report from 'locales/dental/th/report.json'
import Warehouses from 'locales/dental/th/warehouses/warehouses.locale'
import { Counter } from 'locales/dental/th/counter/counter.locale'
import { Treatment } from 'locales/dental/th/dentist/treatment.locale'
import { Payment } from 'locales/dental/th/finance/payment.locale'
import { Application } from 'locales/dental/th/application/application.locale'
import { Setting } from 'locales/dental/th/setting/setting.locale'
import update from 'locales/dental/th/update/updatepage.json'
import { ApprovalTH } from 'locales/dental/th/approval/approval.locale'
import memberTH from 'locales/dental/th/member.json'
// import print from 'locales/dental/th/print.json'

/** LOCALE EN */

import commonEN from 'locales/dental/en/common.json'
import validationEN from 'locales/dental/en/validation.json'
import loginEN from 'locales/dental/en/login.json'
import menusEN from 'locales/dental/en/menu.json'
import homeEN from 'locales/dental/en/home/home.json'
import breadcrumbEN from 'locales/dental/en/breadcrumb.json'
import clinicManagementEN from 'locales/dental/en/admin/clinic-management.json'
import customEN from 'locales/dental/en/admin/custom.json'
import labOrderEN from 'locales/dental/en/lab-order.json'
import historyTreatmentEN from 'locales/dental/en/historyTreatment/historyTreatment.json'
import dashboardEN from 'locales/dental/en/dashboard.json'
import retrospectiveOrderEN from 'locales/dental/en/retrospectiveOrder.json'
import retrospectiveEditEN from 'locales/dental/en/retrospectiveEdit.json'
import confirmAppointmentEN from 'locales/dental/en/confirmAppointment.json'
import reportEN from 'locales/dental/en/report.json'
import WarehousesEN from 'locales/dental/en/warehouses/warehouses.locale'
import { CounterEN } from 'locales/dental/en/counter/counter.locale'
import { TreatmentEN } from 'locales/dental/en/dentist/treatment.locale'
import { PaymentEN } from 'locales/dental/en/finance/payment.locale'
import { ApplicationEN } from 'locales/dental/en/application/application.locale'
import { SettingEN } from 'locales/dental/en/setting/setting.locale'
import updateEN from 'locales/dental/en/update/updatepage.json'
// import print from 'locales/dental/en/print.json'
import checkIn from 'locales/dental/th/check-in.json'
import checkInEn from 'locales/dental/en/check-in.json'
import material from 'locales/dental/th/warehouses/material.json'
import materialEn from 'locales/dental/en/warehouses/material.json'
import { ApprovalEN } from 'locales/dental/en/approval/approval.locale'
import memberEN from 'locales/dental/en/member.json'
import { crmManageTH } from 'locales/dental/th/crmManage/crmManage.locale'
import { crmManageEN } from 'locales/dental/en/crmManage/crmManage.locale'

export const clinicLocaleTH = {
  ...common,
  ...validation,
  ...login,
  ...menus,
  ...home,
  ...breadcrumb,
  ...labOrder,
  ...clinicManagement,
  ...custom,
  ...historyTreatment,
  ...dashboard,
  ...retrospectiveOrder,
  ...retrospectiveEdit,
  ...confirmAppointment,
  ...report,
  ...Counter,
  ...Treatment,
  ...Payment,
  ...Warehouses,
  ...Setting,
  ...Application,
  ...update,
  ...checkIn,
  ...material,
  ...ApprovalTH,
  ...memberTH,
  // ...print
  ...crmManageTH
}

export const clinicLocaleEN = {
  ...commonEN,
  ...validationEN,
  ...loginEN,
  ...menusEN,
  ...homeEN,
  ...breadcrumbEN,
  ...labOrderEN,
  ...clinicManagementEN,
  ...customEN,
  ...historyTreatmentEN,
  ...dashboardEN,
  ...retrospectiveOrderEN,
  ...retrospectiveEditEN,
  ...confirmAppointmentEN,
  ...reportEN,
  ...CounterEN,
  ...TreatmentEN,
  ...PaymentEN,
  ...WarehousesEN,
  ...SettingEN,
  ...ApplicationEN,
  ...updateEN,
  ...checkInEn,
  ...materialEn,
  ...ApprovalEN,
  ...memberEN,
  ...crmManageEN
}
