import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Typography, TableRow, TableCell, FormControlLabel, RadioGroup, Divider } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import $ from 'jquery'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs } from 'constants/images'

/** COMPONENT */
import BtnProfile from 'component/Treatments/BtnProfile'
import InputRadio from 'component/Input/InputRadio'

/** UTILS */
import * as modalSlice from 'app/slice/modal.slice'
import { getClinicInfo, toBuddhistYear } from 'utils/app.utils'

/** API */
import { fileUrl } from 'api/api'
import HistoryTreatmentApi, { PatientCaseType } from 'api/historyTreatment/historyTreatmentApi'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import ButtonCustom from 'component/Button/ButtonCustom'
import Breadcrumb, { BreadcrumbType } from 'component/Breadcrumb/Breadcrumb'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import { notiPopup } from 'component/notifications/notifications'
import { ToastContainer } from 'react-toastify'

/** STYLE */
import { styled } from '@mui/material/styles'
import 'component/HistoryTreatments/style/style.css'

export const ResponsiveTable = styled(Box)(({ theme }) => ({
  '> div': {
    '&.custom-scroll::-webkit-scrollbar': {
      height: 8
    }
  },
  '.MuiTable-root': {
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:first-of-type': {
          width: 40
        },
        '&:nth-of-type(3)': {
          width: 150,
        },
        '&:last-of-type': {
          padding: 15,
          width: 160
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        '&:last-of-type': {
          paddingTop: 10,
        },
      }
    }
  }
}))

export const ResponsiveTableFollow = styled(Box)(({ theme }) => ({
  '> div': {
    '&.custom-scroll::-webkit-scrollbar': {
      height: 8
    }
  },
  '.MuiTable-root': {
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(2)': {
          width: 200,
        },
        '&:last-of-type': {
          padding: 15,
          width: 325
        }
      },
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        '&:nth-of-type(2)': {
          paddingTop: 8,
        },
        '&:last-of-type': {
          paddingTop: 8,
        },
      },
      '.main-table-row': {
        [theme.breakpoints.up('sm')]: {
          '.MuiTableCell-body.MuiTableCell-root': {
            borderBottomColor: 'transparent !important'
          },
          '&:hover, &.table-row-hover': {
            '.MuiTableCell-body': {
              // borderBottom: `1px solid ${colors.extraLightGray} !important`
            }
          },
          '&:hover + tr, &.table-row-hover': {
            '.MuiTableCell-body': {
              backgroundColor: `${colors.extraLightGray} !important`,
            }
          }
        }
      },
      '.rmdp-container': {
        '.rmdp-ep-arrow, .rmdp-ep-arrow + div': {
          zIndex: '2 !important'
        }
      }
    }
  }
}))

const ActionFooter = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '> div': {
      width: '100%',
      '&:first-of-type': {
        paddingRight: 8
      },
      '&:last-of-type': {
        paddingLeft: 8
      },
      button: {
        width: '100%',
      }
    },
  }
}))

type PopupFollowTreatmentType = {
  patientId?: number
  patientCaseId?: number
  patient?: any
  vnNumber?: string
  onReset?: () => void
}

type FollowUpDetailType = {
  patientId?: number
  patientCase?: PatientCaseType
  setData?: (value: any) => void
}

type FollowingResultType = {
  type: 'FollowUp' | 'EndFollow'
  followUpStatus?: string
  setFollowUpStatus?: (status: string) => void
}

export default function PopupFollowTreatment(props: PopupFollowTreatmentType) {
  const { t } = useTranslation()
  const [patientCases, setPatientCases] = useState([]);
  const [operativeCaseName, setOperativeCaseName] = useState('');
  const [operativeCase, setOperativeCase] = useState<PatientCaseType>({});

  const breadcrumbsFollow: BreadcrumbType[] = [{ title: `HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.VISIT`, variable: `  ${props.vnNumber}` }]
  const breadcrumbsFollowDetail: BreadcrumbType[] = [
    {
      title: `HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.VISIT`,
      variable: `  ${props.vnNumber}`,
      onClick: () => {
        setOperativeCase({})
        setOperativeCaseName('')
        loadFollowPatientCase()
      }
    },
    {
      title: 'HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_VISIT',
      variable: ` ${operativeCaseName}`
    }]

  const branch = getClinicInfo()

  const loadFollowPatientCase = useCallback(async () => {
    if (props.patientId && props.patientCaseId) {
      const resp = await HistoryTreatmentApi.findFollowPatientCase(props.patientId, props.patientCaseId)
      if (resp.status === 200) setPatientCases(resp.data)
    }
  }, [props.patientId, props.patientCaseId])

  const handleFollowUpDetail = (operativeName: string, orderTreatmentId: number, data: any) => {
    const operative: PatientCaseType = {
      treatmentFollowUpId: data?.treatmentFollowUpId || 0,
      patientCaseId: props.patientCaseId,
      orderTreatmentId: orderTreatmentId || 0,
      followUpDate1: data?.followUpDate1 || '',
      followUpStatus1: data?.followUpStatus1 || '',
      followUpRemark1: data?.followUpRemark1 || '',
      followUpDate2: data?.followUpDate2 || '',
      followUpStatus2: data?.followUpStatus2 || '',
      followUpRemark2: data?.followUpRemark2 || '',
      followUpDate3: data?.followUpDate3 || '',
      followUpStatus3: data?.followUpStatus3 || '',
      followUpRemark3: data?.followUpRemark3 || '',
      followUpDate4: data?.followUpDate4 || '',
      followUpStatus4: data?.followUpStatus4 || '',
      followUpRemark4: data?.followUpRemark4 || '',
      remark: data?.remark || ''
    }
    setOperativeCase(operative)
    setOperativeCaseName(operativeName)
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: t('#') },
    { id: 'date', disablePadding: false, label: t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.TABLE.SERVICE') },
    { id: 'treatment-service', disablePadding: false, label: t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.TABLE.LAST_FOLLOW') },
    { id: 'follow', disablePadding: false, label: t('Action'), width: '200px !important' },
  ]

  const renderData = (objData: any, no: number) => {
    const { operativeName, orderTreatmentId, dateFollowUp, treatmentFollowUp } = objData

    const objRenderData = {
      key: String(orderTreatmentId),
      id: String(orderTreatmentId),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no + 1 },
        { option: 'TEXT', align: 'left', label: operativeName },
        { option: 'TEXT', align: 'left', label: dateFollowUp ? toBuddhistYear(moment(dateFollowUp), 'DD/MM/YYYY') : '-' },
        {
          option: 'COMPONENT',
          align: 'left',
          component: <ButtonCustom onClick={() => handleFollowUpDetail(operativeName, orderTreatmentId, treatmentFollowUp)} textButton={t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.BUTTON_FOLLOW')} btnStyle={{ fontSize: 16, height: 32 }} />
        }
      ]
    }

    return <TableRowCommon {...objRenderData} />
  }

  useEffect(() => {
    loadFollowPatientCase()
  }, [loadFollowPatientCase]);

  const handlePatientCode = () => {
    if (branch?.patientCodeQueue === '1') {
      return props?.patient?.cnNumber ?? ''
    }
    if (branch?.patientCodeQueue === '0') {
      return props?.patient?.branchCnNumber ?? ''
    }

    if (branch?.patientCodeQueue === '2') {
      return props?.patient?.snCode ?? ''
    }
  }

  return (
    <ModalCustom
      size={'xl'}
      title={t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.TITLE')}
      alignFooter={'end'}
      closeButton={_.isEmpty(operativeCase)}
      fullscreen
      modalStyle={'modal-popup-follow-treatment'}
      component={
        <>
          <Breadcrumb breadcrumbs={_.isEmpty(operativeCase) ? breadcrumbsFollow : breadcrumbsFollowDetail} />
          {_.isEmpty(operativeCase) && (
            <>
              <Box className={`d-flex pb-4 pt-2`} sx={{ borderBottom: `1px solid ${colors.lightGray}` }}>
                <BtnProfile
                  profile={renderImage(props.patient.profile)}
                  branchCnNumber={handlePatientCode()}
                  firstName={props.patient.firstname}
                  lastName={props.patient.lastname}
                  rankId={props.patient.rankId}
                  vnNumber={props.vnNumber}
                  hideArrow
                />
              </Box>
              <Typography className='mt-4 mb-3' sx={{ fontSize: 18, fontWeight: 500 }}>{t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.OPERATIVE_TITLE')}</Typography>
              <ResponsiveTable className='overflow-hidden'>
                <TableCustom
                  page={0}
                  pageLimit={0}
                  sortType={'ASC'}
                  sortBy={''}
                  rowCount={0}
                  headCells={headCells}
                  rowsData={_.map(patientCases, (val, i) => renderData(val, i))}
                  textEmptyData={t('REGISTER.NO_ITEM')}
                  onSort={() => {
                    return
                  }}
                  setPageLimit={() => {
                    return
                  }}
                  setPage={() => {
                    return
                  }}
                  customScroll
                  hidePagination
                />
              </ResponsiveTable>
            </>
          ) || <FollowUpDetail patientId={props.patientId} patientCase={operativeCase} />}
        </>
      }
    />
  )
}

function FollowUpDetail(props: FollowUpDetailType) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const componentRef = useRef<HTMLDivElement>(null);

  const [hideOnScroll, setHideOnScroll] = useState(true)
  const scrollableElement = document.getElementsByClassName('custom-scroll');
  const contentElement = document.getElementsByClassName('MuiTableBody-root');


  useEffect(() => {
    // componentRef.current?.addEventListener
    scrollableElement[4]?.addEventListener('scroll', () => {
      // Check if scroll has reached the end
      const isAtEnd = scrollableElement[4]?.scrollTop + scrollableElement[4]?.clientHeight >= contentElement[1]?.scrollHeight
      // Take action when scroll reaches the end
      if (isAtEnd) {
        setHideOnScroll(false)
      } else {
        setHideOnScroll(true)
      }
    });
  }, [])
  const [topics, setTopics] = useState([
    {
      title: 'FOLLOW_UP_1',
      date: props.patientCase?.followUpDate1,
      status: props.patientCase?.followUpStatus1,
      remark: props.patientCase?.followUpRemark1
    },
    {
      title: 'FOLLOW_UP_2',
      date: props.patientCase?.followUpDate2,
      status: props.patientCase?.followUpStatus2,
      remark: props.patientCase?.followUpRemark2
    },
    {
      title: 'FOLLOW_UP_3',
      date: props.patientCase?.followUpDate3,
      status: props.patientCase?.followUpStatus3,
      remark: props.patientCase?.followUpRemark3
    },
    {
      title: 'FOLLOW_UP_RESULT',
      date: props.patientCase?.followUpDate4,
      status: props.patientCase?.followUpStatus4,
      remark: props.patientCase?.followUpRemark4
    }
  ]);
  const [remark, setRemark] = useState(props.patientCase?.remark);

  const handleRowHover = (type: 'onMouseEnter' | 'onMouseLeave', item: string) => {
    if (type === 'onMouseEnter') $(`#${item}`).prev().addClass('table-row-hover')
    else $(`#${item}`).prev().removeClass('table-row-hover')
  }

  const handleChange = (id: number, key: 'date' | 'status' | 'remark', value: string) => {
    const topic = [...topics]
    topic[id][key] = value
    setTopics(topic)
  }

  const handleSubmit = async () => {
    notiPopup('clear')
    const payload: PatientCaseType = {
      treatmentFollowUpId: props.patientCase?.treatmentFollowUpId,
      patientCaseId: props.patientCase?.patientCaseId,
      orderTreatmentId: props.patientCase?.orderTreatmentId,
      followUpDate1: (topics[0].status || topics[0].remark) && (!topics[0].date || topics[0].date === '0000-00-00') ? moment().format('YYYY-MM-DD') : topics[0].date,
      followUpStatus1: topics[0].status,
      followUpRemark1: topics[0].remark,
      followUpDate2: (topics[1].status || topics[1].remark) && (!topics[1].date || topics[1].date === '0000-00-00') ? moment().format('YYYY-MM-DD') : topics[1].date,
      followUpStatus2: topics[1].status,
      followUpRemark2: topics[1].remark,
      followUpDate3: (topics[2].status || topics[2].remark) && (!topics[2].date || topics[2].date === '0000-00-00') ? moment().format('YYYY-MM-DD') : topics[2].date,
      followUpStatus3: topics[2].status,
      followUpRemark3: topics[2].remark,
      followUpDate4: (topics[3].status || topics[3].remark) && (!topics[3].date || topics[3].date === '0000-00-00') ? moment().format('YYYY-MM-DD') : topics[3].date,
      followUpStatus4: topics[3].status,
      followUpRemark4: topics[3].remark,
      remark: remark
    }

    if (props.patientId) {
      const resp = await HistoryTreatmentApi.treatmentFollowUp(props.patientId, payload)
      if (resp.status === 201) {
        const topic = [...topics]
        topic[0]['date'] = payload.followUpDate1
        topic[1]['date'] = payload.followUpDate2
        topic[2]['date'] = payload.followUpDate3
        topic[3]['date'] = payload.followUpDate4
        setTopics(topic)
        return notiPopup('success', t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.MESSAGE.SUCCESS`))
      }
    }
  }

  const headCells = [
    { id: 'topic', disablePadding: false, label: t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.TABLE.TOPIC') },
    { id: 'date-follow', disablePadding: false, label: t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.TABLE.DATE_FOLLOW') },
    { id: 'result', disablePadding: false, label: t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.TABLE.RESULT') },
  ]

  const renderData = (objData: any, no: number) => {
    const { title } = objData

    return (
      <>
        <TableRow key={`topic-row-${no}`} className={`main-table-row`}>
          <TableCell align="left" scope="row" className='border-0'>
            <Typography>{t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.TABLE.BODY.${title}`)}</Typography>
          </TableCell>
          <TableCell align="left" scope="row" className='border-0'>
            <InputSecondNewDatePicker
              hideOnScroll={hideOnScroll}
              value={objData.date === '0000-00-00' ? '' : objData.date}
              onchange={(e: any) => handleChange(no, 'date', moment(e).format('YYYY-MM-DD'))}
            />
          </TableCell>
          <TableCell align="left" scope="row" className='border-0'>
            <FollowingResult
              type={`${no === 3 ? 'EndFollow' : 'FollowUp'}`}
              followUpStatus={objData.status}
              setFollowUpStatus={(followStatus: string) => handleChange(no, 'status', followStatus)}
            />
          </TableCell>
        </TableRow>
        <TableRow key={`topic-child-row-${no}`} id={`topic-row-${no}`} onMouseEnter={() => handleRowHover('onMouseEnter', `topic-row-${no}`)} onMouseLeave={() => handleRowHover('onMouseLeave', `topic-row-${no}`)}>
          <TableCell align="left" scope="row" colSpan={3} className="pb-3">
            <Divider className="mx-0 mt-0 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            <InputTextarea placeholder={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.REMARK')} onchange={(event) => handleChange(no, 'remark', event.target.value)} value={objData.remark} />
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <ToastContainer containerId={'popup'} className={'Toast-popup mb-3'} />
      <Typography className='mb-3' sx={{ fontSize: 18, fontWeight: 500 }}>{t('HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.OPERATIVE_DETAIL_TITLE')}</Typography>
      <ResponsiveTableFollow className='overflow-hidden' ref={componentRef}>
        <TableCustom
          page={0}
          pageLimit={0}
          sortType={'ASC'}
          sortBy={''}
          rowCount={0}
          headCells={headCells}
          rowsData={_.map(topics, (topic, index: number) => {
            return renderData(topic, index)
          })}
          textEmptyData={t('REGISTER.NO_ITEM')}
          onSort={() => {
            return
          }}
          setPageLimit={() => {
            return
          }}
          setPage={() => {
            return
          }}
          customScroll
          hidePagination
        />
      </ResponsiveTableFollow>
      <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
      <InputTextarea placeholder={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.REMARK')} onchange={(event) => setRemark(event.target.value)} value={remark} />
      <ActionFooter className='d-flex align-items-center justify-content-md-end mt-4'>
        <ButtonCustom onClick={() => dispatch(modalSlice.resetModal())} textButton={t('BUTTON.CANCEL')} variant="outlined" btnStyle={{ height: 44, fontSize: 16 }} style={{ margin: 0 }} />
        <ButtonCustom onClick={handleSubmit} textButton={t('BUTTON.SAVE')} className="ml-md-3" btnStyle={{ height: 44, fontSize: 16 }} style={{ margin: 0 }} />
      </ActionFooter>
    </>
  )
}

function FollowingResult(props: FollowingResultType) {
  const { t } = useTranslation()
  const follows = ['ASYMPTOMATIC', 'MILD_SYMPTOMS', 'MONITORED_FOR_SYMPTOMS', 'OTHER']
  const endFollows = ['TAKING_CARE_OF_YOURSELF', 'REPORT_POTENTIAL', 'NEXT_APPOINTMENT', 'OTHER']

  return (
    props.type === 'FollowUp' && (
      <RadioGroup row aria-label="follow"
        onChange={(event) => {
          if (props.setFollowUpStatus && (event.target.value !== t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follows[3]}`))) props.setFollowUpStatus(event.target.value)
          else props.setFollowUpStatus && props.setFollowUpStatus(t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follows[3]}`))
        }}
        className="d-block"
        name="row-radio-buttons-group">
        {
          _.map(follows, (follow, index) => {
            if (index + 1 === _.size(follows)) {
              return (
                <Box className='d-flex'>
                  <FormControlLabel
                    className="d-block mr-2"
                    checked={props.followUpStatus === t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follows[3]}`) || (props.followUpStatus !== '' && _.every(follows, (f) => t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${f}`) !== props.followUpStatus))}
                    value={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follow}`)} control={<InputRadio />}
                    label={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follow}`)} sx={{ flex: 'none' }}
                  />
                  <InputTextField
                    onchange={(event) => props.setFollowUpStatus && props.setFollowUpStatus(event.target.value)}
                    value={props.followUpStatus !== '' && _.every(follows, (f) => t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${f}`) !== props.followUpStatus) ? props.followUpStatus : ''}
                  />
                </Box>
              )
            } else {
              return (
                <Box className='d-block'>
                  <FormControlLabel className="d-block mr-0" checked={props.followUpStatus === t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follow}`)} value={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follow}`)} control={<InputRadio />} label={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${follow}`)} />
                </Box>
              )
            }
          })
        }
      </RadioGroup>
    ) || (
      <RadioGroup row aria-label="follow"
        onChange={(event) => {
          if (props.setFollowUpStatus && (event.target.value !== t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollows[3]}`))) props.setFollowUpStatus(event.target.value)
          else props.setFollowUpStatus && props.setFollowUpStatus(t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollows[3]}`))
        }}
        className="d-block"
        name="row-radio-buttons-group">
        {
          _.map(endFollows, (endFollow, index) => {
            if (index + 1 === _.size(endFollows)) {
              return (
                <Box className='d-flex'>
                  <FormControlLabel
                    className="d-block mr-2"
                    checked={props.followUpStatus === t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollows[3]}`) || (props.followUpStatus !== '' && _.every(endFollows, (f) => t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${f}`) !== props.followUpStatus))}
                    value={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollow}`)} control={<InputRadio />}
                    label={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollow}`)} sx={{ flex: 'none' }}
                  />
                  <InputTextField
                    onchange={(event) => props.setFollowUpStatus && props.setFollowUpStatus(event.target.value)}
                    value={props.followUpStatus !== '' && _.every(endFollows, (f) => t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${f}`) !== props.followUpStatus) ? props.followUpStatus : ''}
                  />
                </Box>
              )
            } else {
              return (
                <Box className='d-block'>
                  <FormControlLabel className="d-block mr-0" checked={props.followUpStatus === t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollow}`)} value={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollow}`)} control={<InputRadio />} label={t(`HISTORY_TREATMENT.MODAL_FOLLOW_TREATMENT.FOLLOW_UP.${endFollow}`)} />
                </Box>
              )
            }
          })
        }
      </RadioGroup>
    )
  )
}