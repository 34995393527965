import { Box, MenuItem } from '@mui/material'
import InputTextField from 'component/Input/InputTextField'
import FilterSelect from 'component/Select/FilterSelect'
import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import Hue from 'react-color/lib/components/hue/Hue'
import { useTranslation } from 'react-i18next'
import { BoxManageColor, ColorTag } from './style'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

export type MemberColorManageProps = {
    color: string
    setColor: (value: string) => void
    handleChangeColor: ({ hex }: { hex: string }) => void
    onLoadMember: (search: string) => void
    memberTypes: any
    memberType: any
    handleChangeMember: (val: any) => void
}

const MemberColorManage = (props: MemberColorManageProps) => {
    const { color, setColor, handleChangeColor } = props
    const [renderColor, setRenderColor] = useState<string>('')
    useEffect(() => {
        if (color.includes('#')) setRenderColor(color.slice(1))
        else setRenderColor(color)
    }, [color])

    return (
        <BoxManageColor>
            <Box className={`content-select-color`}>
                <ChromePicker color={color} onChange={handleChangeColor} />
                <Hue color={color} onChange={handleChangeColor} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Box sx={{ '.MuiInputBase-root': { height: '43px' } }}>{'HEX'}</Box>
                    <Box sx={{ '.MuiInputBase-input': { padding: "0 1rem 0 .4rem !important" } }}>
                        <InputTextField
                            value={renderColor}
                            onchange={(e) => {
                                const newValue = e.target.value
                                setColor(`#${newValue}`)
                            }}
                            inputProps={{ startAdornment: <Box>#</Box> }} />
                    </Box>
                </Box>
            </Box>
        </BoxManageColor>
    )
}

export default MemberColorManage