import { useState, useEffect, useCallback } from 'react'
import { numberFormat } from 'utils/app.utils'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { dateTimeToView } from 'utils/date.utils'
import { UseSnCode } from 'utils/useSnCode'
interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
}

export default function IncomeList(props: FcComponent) {
  const { t } = useTranslation()
  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])
  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'ลำดับ', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.DATE_TIME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.BILL_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.BRANCH_CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_SN_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_AGE', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_STATUS', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'ราคาเต็ม', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'ยอดที่จ่าย', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'ค้างชำระ', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'โอน-ธนาคาร', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'เครดิต-ธนาคาร-ประเภท', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'เดบิต', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'เดบิต-ธนาคาร-ประเภท', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'สิทธิการรักษา', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'ชื่อสิทธิการรักษา', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'เงินชำระล่วงหน้า', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK', align: 'center', class: 'text-wrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CREATED_BY', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK_CANCEL', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CANCEL_BY', align: 'center', class: 'text-nowrap' }
  ]
  if (!snType) {
    headCells.splice(5, 1)
  }

  const renderData = (data: any, no: number) => {
    const num = props.page * props.pageLimit - props.pageLimit + no + 1

    const columns = [
      { option: 'TEXT', align: 'left', label: num, class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: dateTimeToView(data.paidAt), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.billNo, class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.cnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.snCode || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.patientFullname, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.age },
      { option: 'TEXT', align: 'center', label: t(`REPORT.BILL_STATUS.${data.billStatus}`), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.totalNet), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.overdue), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountCash), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountTransfer), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.paymentChannelTransfer || '0.00', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountCredit), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.paymentChannelCredit || '0.00', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountDebit), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.paymentChannelDebit || '0.00', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountTreatmentRight), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.paymentChannelTreatmentRight || '0.00', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountAdvance), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.remark || '-', class: 'text-wrap' },
      { option: 'TEXT', align: 'center', label: data.createdBy, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.cancelRemark || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.cancelBy || '-', class: 'text-nowrap' }
    ]

    if (!snType) {
      columns.splice(5, 1)
    }
    return <TableRowCommon key={num.toString()} id={no.toString()} obj={data} columns={columns} />
  }

  return (
    <Box>
      <UseStyled.ResponsiveReportTable>
        <TableCustom
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={headCells}
          rowsData={_.map(props.data, (d: any, index: number) => {
            return renderData(d, index)
          })}
          customScroll
        />
      </UseStyled.ResponsiveReportTable>
    </Box>
  )
}
