import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import CardTypesApi from 'api/setting/finances/card-type.api'

/** STYLE */
import useStyles, { DisabledCheckBox } from 'features/setting/finances/card-types/style'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

/** UTILS || SLICE || CONSTANTS */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import UploadImage from 'component/Upload/UploadImage'
import InputCheckbox from 'component/Input/InputCheckbox'
import TableRowCommon from 'component/Table/TableRowCommon'
import { fileUrl } from 'api/api'
import FilterSelect from 'component/Select/FilterSelect'

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        '&:nth-of-type(4)': {
          paddingTop: 9
        },
        '&:nth-of-type(5)': {
          paddingTop: 9
        },
      }

    }
  }
}))

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

const initStateErrorMessage = {
  NOT_FOUND_CARD_TYPE: ``,
  CARD_TYPE_ALREADY_SYSTEM: ``,
  INVALID_CREATED: ``,
  INVALID_UPDATED: ``,
  IS_DUPLICATE_CARD_TYPE_SHORTNAME: ``,
  IS_DUPLICATE_CARD_TYPE_NAME: ``,
  CARD_TYPE_SHORTNAME: ``,
  CARD_TYPE_SHORTNAME_STRING_BASE: ``,
  CARD_TYPE_SHORTNAME_STRING_EMPTY: ``,
  CARD_TYPE_SHORTNAME_MAXIMUM_LENGTH: ``,
  CARD_TYPE_SHORTNAME_ANY_REQUIRED: ``,
  CARD_TYPE_NAME: ``,
  CARD_TYPE_NAME_STRING_BASE: ``,
  CARD_TYPE_NAME_STRING_EMPTY: ``,
  CARD_TYPE_NAME_MAXIMUM_LENGTH: ``,
  CARD_TYPE_NAME_ANY_REQUIRED: ``,

  CARD_TYPE_NAME_EN: ``,
  CARD_TYPE_NAME_EN_STRING_BASE: ``,
  CARD_TYPE_NAME_EN_STRING_EMPTY: ``,
  CARD_TYPE_NAME_EN_MAXIMUM_LENGTH: ``,
  CARD_TYPE_NAME_EN_ANY_REQUIRED: ``
}
export default function CardTypes() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [cardTypes, setCardTypes] = useState([])

  const [cardTypeId, setCardTypeId] = useState(Number)
  const [cardTypeShortname, setCardTypeShortname] = useState('')
  const [cardTypeName, setCardTypeName] = useState('')
  const [hasCredit, setHasCredit] = useState('NOT_SUPPORT')
  const [hasDebit, setHasDebit] = useState('NOT_SUPPORT')
  const [cardTypeStatus, setCardTypeStatus] = useState('NOT_SUPPORT')
  const [image, setImage] = useState({ fileId: null, base64: '', fileExtension: '', fileSize: '' })
  const [imgData, setImgData]: any = useState()

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await CardTypesApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setCardTypes(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const submit = () => {

    if (!cardTypeShortname || !cardTypeName) {
      return setErrorMessage({ ...errorMessage, ...{ CARD_TYPE_SHORTNAME: !cardTypeShortname ? t(`CARD_TYPE.MESSAGE.CARD_TYPE_SHORTNAME`) : '', CARD_TYPE_NAME: !cardTypeName ? t(`CARD_TYPE.MESSAGE.CARD_TYPE_NAME`) : '' } })
    }

    dispatch(submitModal())
    CardTypesApi.create({ cardTypeShortname, cardTypeName, hasCredit, hasDebit, image })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`CARD_TYPE.MESSAGE.SUCCESS.CREATE`), '', null, null)
          resetForm()

          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`CARD_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`CARD_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {

    if (!cardTypeShortname || !cardTypeName) {
      return setErrorMessage({ ...errorMessage, ...{ CARD_TYPE_SHORTNAME: !cardTypeShortname ? t(`CARD_TYPE.MESSAGE.CARD_TYPE_SHORTNAME`) : '', CARD_TYPE_NAME: !cardTypeName ? t(`CARD_TYPE.MESSAGE.CARD_TYPE_NAME`) : '' } })
    }

    dispatch(submitModal())
    CardTypesApi.update(cardTypeId, { cardTypeShortname, cardTypeName, hasCredit, hasDebit, status: cardTypeStatus, image })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`CARD_TYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`CARD_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`CARD_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setCardTypeId(0)
    setCardTypeShortname('')
    setCardTypeName('')
    setHasDebit('NOT_SUPPORT')
    setHasCredit('NOT_SUPPORT')
    setImgData('')
    setImage({ fileId: null, base64: '', fileExtension: '', fileSize: '' })

    clearErrorMessage()
  }
  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('CARD_TYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          CardTypesApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('CARD_TYPE.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('CARD_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { cardTypeId: cdTypeId, cardTypeName: cdTypeName } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('CARD_TYPE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('CARD_TYPE.ALERT.CONFIRM_DELETE_', {
        cardTypeName: cdTypeName
      })}</p>`,
      (res: any) => {
        if (res) {
          CardTypesApi.remove(cdTypeId)
            .then((resp) => {
              notiSuccess(t('CARD_TYPE.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('CARD_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { cardTypeId: cdTypeId, cardTypeShortname: cdTypeShortName, cardTypeName: cdTypeName, hasDebit: cdTypeHasDebit, hasCredit: cdTypeHasCredit, status: cdTypeStatus, image: cdTypeImage } = data

    setCardTypeId(cdTypeId)
    setCardTypeShortname(cdTypeShortName)
    setCardTypeName(cdTypeName)
    setHasDebit(cdTypeHasDebit)
    setHasCredit(cdTypeHasCredit)
    setCardTypeStatus(cdTypeStatus)

    if (cdTypeImage) {
      setImgData(`${fileUrl}/${cdTypeImage.filePath}/${cdTypeImage.fileName}`)
      setImage({ fileId: data.image.fileId, base64: '', fileExtension: '', fileSize: '' })
    }

    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const uploadImage = (files: any) => {
    if (files[0]) {
      /**
       * files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: image.fileId,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setImgData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setImage(detailImage)
    }
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'cardTypeShortname', disablePadding: false, label: t('CARD_TYPE.TABLE.CELL.CARD_TYPE_SHORTNAME') },
    { id: 'cardTypeName', disablePadding: false, label: t('CARD_TYPE.TABLE.CELL.CARD_TYPE_NAME') },
    { id: 'hasDebit', disablePadding: false, align: 'center', label: t('CARD_TYPE.TABLE.CELL.HAS_DEBIT') },
    { id: 'hasCredit', disablePadding: false, align: 'center', label: t('CARD_TYPE.TABLE.CELL.HAS_CREDIT') },
    { id: 'status', disablePadding: false, label: t('CARD_TYPE.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('CARD_TYPE.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { cardTypeId: cdTypeId, cardTypeShortname: cdTypeShortName, cardTypeName: cdTypeName, hasDebit: cdTypeHasDebit, hasCredit: cdTypeHasCredit, updatedBy, updatedAt, status: cdTypeStatus } = objData

    const statusBtnActive = cdTypeStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = cdTypeStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled

    const objRenderData = {
      key: cdTypeId,
      id: cdTypeId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: cdTypeShortName },
        { option: 'TEXT', align: 'left', label: cdTypeName },
        {
          option: 'COMPONENT', align: 'center', component: (
            <DisabledCheckBox>
              <InputCheckbox disabled value={cdTypeHasDebit} checked={cdTypeHasDebit === 'SUPPORT' ? true : false} />
            </DisabledCheckBox>

          )
        },
        {
          option: 'COMPONENT', align: 'center', component: (
            <DisabledCheckBox>
              <InputCheckbox disabled value={cdTypeHasCredit} checked={cdTypeHasCredit === 'SUPPORT' ? true : false} />
            </DisabledCheckBox>
          )
        },
        { option: 'STATUS', align: 'center', label: cdTypeStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('CARD_TYPE.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('CARD_TYPE.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('CARD_TYPE.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <TableWidth className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={cardTypes.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </TableWidth>
        </Card.Body>
      </Card>

      <ModalCustom
        modalStyle={classes.modal}
        title={cardTypeId ? t('CARD_TYPE.TITLE_UPDATE') : t('CARD_TYPE.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {cardTypeId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                  <Form.Check type="switch" id="custom-switch" onChange={() => setCardTypeStatus(cardTypeStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')} checked={cardTypeStatus === 'ACTIVE' ? true : false} style={{ marginLeft: 'auto' }} />
                </Form>
              </div>
            ) : (
              ''
            )}

            <Row>
              <Col sm={12}>
                <UploadImage setValue={uploadImage} pathFile={imgData} />
              </Col>

              <Col sm={12}>
                <InputTextField
                  onchange={(event) => {
                    setCardTypeShortname(event.target.value)
                    clearErrorMessage()
                  }}
                  inputProps={{ maxLength: 100 }}
                  value={cardTypeShortname}
                  label={t('CARD_TYPE.INPUT.CARD_TYPE_SHORTNAME')}
                  helperText={
                    errorMessage.CARD_TYPE_SHORTNAME ||
                    errorMessage.IS_DUPLICATE_CARD_TYPE_SHORTNAME ||
                    errorMessage.CARD_TYPE_SHORTNAME_STRING_BASE ||
                    errorMessage.CARD_TYPE_SHORTNAME_STRING_EMPTY ||
                    errorMessage.CARD_TYPE_SHORTNAME_MAXIMUM_LENGTH ||
                    errorMessage.CARD_TYPE_SHORTNAME_ANY_REQUIRED
                  }
                  required={true}
                  style={{ margin: '1rem 0' }}
                />
              </Col>

              <Col sm={12}>
                <InputTextField
                  onchange={(event) => {
                    setCardTypeName(event.target.value)
                    clearErrorMessage()
                  }}
                  inputProps={{ maxLength: 150 }}
                  value={cardTypeName}
                  label={t('CARD_TYPE.INPUT.CARD_TYPE_NAME')}
                  helperText={
                    errorMessage.CARD_TYPE_NAME ||
                    errorMessage.CARD_TYPE_NAME_STRING_BASE ||
                    errorMessage.CARD_TYPE_NAME_STRING_EMPTY ||
                    errorMessage.CARD_TYPE_NAME_MAXIMUM_LENGTH ||
                    errorMessage.CARD_TYPE_NAME_ANY_REQUIRED ||
                    errorMessage.IS_DUPLICATE_CARD_TYPE_NAME
                  }
                  required={true}
                  style={{ marginBottom: '1rem' }}
                />
              </Col>
              <Col sm={12}>{t('CARD_TYPE.LABEL.SERVICE')}</Col>
              <Col sm={12} className=" d-lg-flex flex-sm-row ">
                <InputCheckbox
                  className={classes.inputCheckBox}
                  label={t('CARD_TYPE.LABEL.DEBIT')}
                  checked={hasDebit === 'SUPPORT' ? true : false}
                  onChange={(event) => {
                    setHasDebit(event.target.checked ? 'SUPPORT' : 'NOT_SUPPORT')
                  }}
                />
                <InputCheckbox
                  className={classes.inputCheckBox}
                  label={t('CARD_TYPE.LABEL.CREDIT')}
                  checked={hasCredit === 'SUPPORT' ? true : false}
                  onChange={(event) => {
                    setHasCredit(event.target.checked ? 'SUPPORT' : 'NOT_SUPPORT')
                  }}
                />
              </Col>
            </Row>
          </div>
        }
        onReset={resetForm}
        onSubmit={cardTypeId ? submitEdit : submit}
        textBtnCancel={t('CARD_TYPE.BUTTON.CANCEL')}
        textBtnConfirm={t('CARD_TYPE.BUTTON.SAVE')}
      />
    </div>
  )
}
