import { Box, Typography } from '@mui/material'
import { swalActive } from 'component/Alert/Swal'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormManageTag from './form-manage-tag'
import { useDispatch } from 'react-redux'
import { resetModal, showModal } from 'app/slice/modal.slice'
import TagsApi, { tagProps } from 'api/setting/personnel-managements/tags.api'
import MainButton from 'new-components/buttons/main-button'
import TextInput from 'new-components/inputs/text-input'
import SelectInput from 'new-components/inputs/select-input'
const permissions = {
    // isCreate: isCreate(),
    // isDelete: isDelete(),
    // isUpdate: isUpdate(),
    // isView: isView()
    isCreate: false,
    isDelete: false,
    isUpdate: false,
}

const initManageTag: tagProps = {
    tagId: 0,
    color: '',
    tagName: '',
    tagNameEn: '',
    status: 'ACTIVE',
}


const ManageTag = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('tagId')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('ALL')

    const [maganeTag, setManageTag] = useState<any[]>([])
    const [popTag, setPopTag] = useState<boolean>(false)
    const [popTagIsEdit, setPopTagIsEdit] = useState<boolean>(false)
    const [popDataEdit, setPopDataEdit] = useState<any>(initManageTag)

    const loadData = useCallback(async () => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

        const res = await TagsApi.findAll(condition)
        console.log(res, 'testestetrse')
        if (res.status === 200) {
            setRowCount(res.headers['x-total'])
            setManageTag(res.data)
        }
    }, [page, pageLimit, search, sortBy, sortType, status])

    useEffect(() => {
        loadData()
    }, [loadData])

    const onCreate = () => {
        setPopDataEdit(initManageTag)
        setPopTagIsEdit(false)
        setPopTag(true)
        dispatch(showModal())
    }

    const onClosePopTag = () => {
        setPopTag(false)
        dispatch(resetModal())
    }

    const onActive = (id: number, valueStatus: string) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('OPERATIVE.ALERT.CONFIRM_STATUS')}</p>`,
            null, (res: any) => {
                if (res) {
                    TagsApi.updateStatusTag(id, valueStatus)
                        .then((resp) => {
                            notiSuccess(t('TAGS.MESSAGE.CHANGE_STATUS'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('TAGS.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
    }

    const onDelete = (data: any) => {
        const { tagName, tagId } = data
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TAGS.MESSAGE.CONFIRM_DEL')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TAGS.MESSAGE.CONFIRM_DEL_ITEM', {
                tagName: tagName,
            })}</p>`,
            (res: any) => {
                if (res) {
                    TagsApi.deleteTag(tagId)
                        .then((resp) => {
                            notiSuccess(t('TAGS.MESSAGE.DEL_SUCCESSFUL'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('TAGS.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onEdit = (data: any) => {
        setPopDataEdit(data)
        setPopTagIsEdit(true)
        setPopTag(true)
        dispatch(showModal())
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }
    const handleChangeStatus = (statusVal: string) => {
        setStatus(statusVal)
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '80px', class: 'tb-tag-no' },
        { id: 'tagColor', disablePadding: false, label: t('TAGS.TABLE.COLOR'), width: '120px', class: 'tb-tag-tag-color' },
        { id: 'tagName', disablePadding: false, label: t('TAGS.TABLE.TAGNAME'), class: 'tb-tag-tag-name' },
        { id: 'status', disablePadding: false, label: t('TAGS.TABLE.STATUS'), width: '200px', class: 'tb-tag-status' },
        { id: 'updatedBy', disablePadding: false, label: t('BRANCH.TABLE.CELL.UPDATED_BY'), width: '180px', class: 'tb-tag-updateby' },
        { id: 'action', disablePadding: false, label: 'Action', width: '160px', class: 'tb-tag-action' }
    ]

    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { tagId, tagName, color, status, updatedBy, updatedAt } = objData
        // permissions.isUpdate.disabled
        const permissUpdate = false
        const statusBtnActive = status === 'ACTIVE' ? true : permissUpdate
        const statusBtnInActive = status === 'INACTIVE' ? true : permissUpdate

        const objRenderData = {
            key: tagId,
            id: tagId,
            obj: objData,
            columns: [
                {
                    option: 'TEXT',
                    align: 'center',
                    label: no
                },
                {
                    option: 'COMPONENT',
                    align: 'left',
                    component: (<Box sx={{ backgroundColor: color, height: '21px', width: '80px' }} />)
                },
                {
                    option: 'TEXT',
                    align: 'left',
                    label: tagName
                },
                {
                    option: 'STATUS',
                    align: 'center',
                    label: status
                },
                {
                    option: 'UPDATE_BY',
                    align: 'center',
                    label: { updatedBy: updatedBy, updatedAt: updatedAt }
                },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissUpdate },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissUpdate }
                    ]
                }
            ]
        }
        return (
            <TableRowCommon
                {...objRenderData}
                onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
                oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
                onedit={() => onEdit(objRenderData.obj)}
                ondelete={() => onDelete(objRenderData.obj)}
            />
        )
    }

    return (
        <Box>
            {popTag &&
                <FormManageTag
                    onClosePopTag={onClosePopTag}
                    isEdit={popTagIsEdit}
                    data={popDataEdit}
                    reLoadData={loadData}
                />
            }
            <Box height="60px" width="100%" bgcolor="white" boxShadow='0px 3px 6px 0px rgba(0, 0, 0, 0.12)' >
                <Typography marginX="24px" marginY="27px" fontSize='1.25rem'>{t('TAGS.TITLE')}</Typography>
            </Box>
            <Box paddingX="24px" paddingY="16px">
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom='24px'>
                    <Box display="flex" flexDirection="row" gap="16px">
                        <TextInput
                            label={t('TAGS.SEARCH')}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value)
                                setPage(1)
                            }}
                            width={320}
                        />
                        <SelectInput
                            label={t('STATUS.LABEL')}
                            width={320}
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                            options={[
                                { value: 'ALL', name: t('STATUS.ALL') },
                                { value: 'ACTIVE', name: t('STATUS.ACTIVE') },
                                { value: 'INACTIVE', name: t('STATUS.INACTIVE') }
                            ]}
                        />
                    </Box>
                    <MainButton title='เพิ่ม Tag' type='primary' variant='contained' style={{ height: '40px' }} onClick={() => onCreate()} />
                </Box>
                <TableCustom
                    page={page}
                    pageLimit={pageLimit}
                    sortType={sortType}
                    sortBy={sortBy}
                    onSort={onRequestSort}
                    setPageLimit={handleChangeRowsPerPage}
                    setPage={handleChangePage}
                    rowCount={rowCount}
                    headCells={headCells}
                    rowsData={maganeTag.map((item: any, index: number) => renderData(item, index))}
                    pagination
                />
            </Box>
        </Box>
    )
}

export default ManageTag