import Cookies from 'universal-cookie'
import { colors } from 'constants/theme'
import moment from 'moment'
import _, { constant } from 'lodash'
import CryptoJS from 'crypto-js'
import { PACKAGE, PACKAGE_TYPE, PackageItem, PACKAGE_ADDONS } from 'constants/package'
import { removeAllStorage, KEY_STORAGE, ServicePointsType, MEMBER_RANKING_TYPE } from 'constants/common'
import { ChangeEvent, } from 'react';

export const getServicePointsStorage = (type: 'all' | 'branch' | 'servicePoint') => {
  const servicePoint = localStorage.getItem(KEY_STORAGE.SERVICE_POINT)
  if (servicePoint) {
    const data: ServicePointsType = JSON.parse(servicePoint)
    if (type === 'branch') return data.branch
    else if (type === 'servicePoint') return data.servicePoint
    else return data
  }
}


export const getPermissions = () => {
  const permissions = localStorage.getItem(KEY_STORAGE.PERMISSION)
  if (permissions) return JSON.parse(permissions)
}

export const getToken = () => localStorage.getItem(KEY_STORAGE.TOKEN)

export const getSessionExpired = () => localStorage.getItem(KEY_STORAGE.SESSION_EXPIRED)

export const getBranch = () => _.get(getServicePointsStorage('branch'), 'id') || 0

export const getBranchName = () => _.get(getServicePointsStorage('branch'), 'name')

export const getServicePoint = () => _.get(getServicePointsStorage('servicePoint'), 'id') || 0

export const getServicePointName = () => _.get(getServicePointsStorage('servicePoint'), 'name')

export const getPermissionShortcuts = () => _.get(getPermissions(), 'shortcuts')

export const getPermissionMenus = () => _.get(getPermissions(), 'menus')

export const getPermissionActiveMenu = () => _.get(getPermissions(), 'activeMenu')

export const getClinicInfo = () => {
  const info = localStorage.getItem(KEY_STORAGE.CLINIC_INFO)
  if (info) return JSON.parse(info)
}

export const logout = (): void => {
  removeAllStorage()
  const cookies = new Cookies()
  cookies.remove('REMEMBER_ME')
  cookies.remove('TOKEN')
}

export const getMember = () => {
  const members = localStorage.getItem(KEY_STORAGE.MEMBER_RANKING_TYPE)
  if (members) return JSON.parse(members)
}

export const numberFormat = (num: any, minDigits = 2, maxDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits
  })
  return formatter.format(num)
}

export const numberNonDigit = (number: any) => {
  const format = Intl.NumberFormat('th-TH', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return format.format(number)
}

export const getBoolean = (value: any) => {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true
    default:
      return false
  }
}

export const padCode = (length: number, number: any, prefix = ``) => prefix + number.toString().padStart(length, '0')

export const memberTypeOutline = (types: string) => {
  const type = types ? types.toLocaleLowerCase() : MEMBER_RANKING_TYPE.BRONZE
  if (type === MEMBER_RANKING_TYPE.SILVER) return colors.memberRanking.rankingSilver
  else if (type === MEMBER_RANKING_TYPE.GOLD) return colors.memberRanking.rankingGold
  else if (type === MEMBER_RANKING_TYPE.PLATINUM) return colors.memberRanking.rankingPlatinum
  else if (type === MEMBER_RANKING_TYPE.DIAMOND) return colors.memberRanking.rankingDiamond
  else return colors.memberRanking.rankingBronze
}

export const memberTypeLabel = (types: string) => {
  const type = types ? types.toLocaleLowerCase() : MEMBER_RANKING_TYPE.BRONZE
  if (type === MEMBER_RANKING_TYPE.SILVER) return colors.memberRanking.background.rankingSilver
  else if (type === MEMBER_RANKING_TYPE.GOLD) return colors.memberRanking.background.rankingGold
  else if (type === MEMBER_RANKING_TYPE.PLATINUM) return colors.memberRanking.background.rankingPlatinum
  else if (type === MEMBER_RANKING_TYPE.DIAMOND) return colors.memberRanking.background.rankingDiamond
  else return colors.memberRanking.background.rankingBronze
}

export const toBuddhistYear = (date: any, format = 'DD/MM/YYYY') => {
  const localLang = localStorage?.getItem(KEY_STORAGE.LANGUAGE) || "TH"
  const era = localLang === 'TH' ? 543 : 0;
  const christianYear = date.format('YYYY')
  const buddhishYear = (parseInt(christianYear) + era).toString()
  if (format === 'DD/MM/YYYY') return date.format('DD/MM/') + buddhishYear.toString()
  else if (format === 'DD/MM/YYYY HH:mm') return `${date.format('DD/MM/')}${buddhishYear.toString()} ${date.format('HH:mm')}`
  else if (format === 'DD/MM/YYYY HH.mm') return `${date.format('DD/MM/')}${buddhishYear.toString()} ${date.format('HH.mm')}`
  else return date.format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4))).replace(christianYear, buddhishYear)
}

export const isBuddhistYear = () => {
  const localLang = localStorage?.getItem(KEY_STORAGE.LANGUAGE) || "TH"
  const era = localLang === 'TH' ? 543 : 0;
  return era
}

export const numberToCurrencyFormat = (number: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
    .format(number)
    .replace('$', '')
}

export const renderAge = (date: Date | null | string, unit: 'year' | 'month' | 'day') => {
  const now = moment()
  const bd = moment(date)
  if (unit === 'year') return now.diff(bd, 'year')
  if (unit === 'month') return now.diff(bd.add(now.diff(bd, 'year'), 'years'), 'months')
  if (unit === 'day') return now.diff(bd.add(now.diff(bd.add(now.diff(bd, 'year'), 'years'), 'months'), 'months'), 'days')
}

export const getApp = () => {
  const checkApp: string = localStorage.getItem(KEY_STORAGE.APP) || ''
  const decryptApp: string = checkApp ? CryptoJS.AES.decrypt(checkApp, "cliniter dental").toString(CryptoJS.enc.Utf8) : ''
  return decryptApp
}

export const getCrm = () => {
  const checkCrm: string = localStorage.getItem(KEY_STORAGE.CRM) || ''
  const decryptCrm: string = checkCrm ? CryptoJS.AES.decrypt(checkCrm, "cliniter dental").toString(CryptoJS.enc.Utf8) : ''
  return decryptCrm === 'PERMISS'
}

export const getPackage = () => {
  const webPackage: string = localStorage.getItem('PACKAGE') || ''
  const decrypt: string = webPackage ? CryptoJS.AES.decrypt(webPackage, "cliniter dental").toString(CryptoJS.enc.Utf8) : ''
  const item: PackageItem = decrypt ? JSON.parse(decrypt) : {
    time: '', package: PACKAGE_TYPE.STANDARD, packageExpired: null, countBranch: 1, enableApp: '0'
  }
  return item
}

export const getPackageKey = () => { return getPackage()?.package || PACKAGE_TYPE.STANDARD }

export const getPackageTime = () => { return getPackage()?.time || '' }

export const getPackageExpired = () => { return getPackage()?.packageExpired || '' }

export const getCountBranch = () => { return getPackage()?.countBranch || 1 }

export const getEnabledApp = () => { return getPackage()?.enabledApp || 0 }

export const checkPackage = (key: string) => {
  const clientPackage: PACKAGE_TYPE = getPackageKey() || PACKAGE_TYPE.STANDARD

  let packageAddon: string[] = []
  if (getEnabledApp()) packageAddon = [PACKAGE_ADDONS.ADDON_REGISTER_APP, PACKAGE_ADDONS.ADDON_PROMOTIONS, PACKAGE_ADDONS.ADDON_NEWS]

  if (key) return _.includes([...PACKAGE[clientPackage], ...packageAddon], key)
  else return true
}

export const numberOnly = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  // Remove any non-numeric characters from the input
  const numericValue = value.replace(/[^0-9.]/g, '');
  const dotCount = numericValue.split('.').length - 1;
  const sanitizedValue = dotCount <= 1 ? numericValue : numericValue.slice(0, -1);
  return sanitizedValue
};

export const insertDashesIphone = (numberIphone: string) => {
  const result = [];
  let index = 0;
  while (index < numberIphone.length) {
    if (index === 0) {
      result.push(numberIphone.substring(index, index + 1));
    } else if (index === 2 || index === 6 || index === 10) {
      result.push('-');
      result.push(numberIphone.substring(index, index + 1));
    } else {
      result.push(numberIphone.substring(index, index + 1));
    }
    index++;
  }
  return result.join('');
}

export const insertDashesText = (text?: string) => {
  if (!text) {
    return ""; // Handle empty string case
  }

  const result = [];
  let add = 80;
  let index = 0;

  while (index < text.length) {
    if (index === add) {
      result.push(text);
    }
    index++;
    add = add + 80
  }

  return result.join(' ');
};
