import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/treatments/recommends/style'

/** API */
import RecommendApi from 'api/setting/treatments/recommend.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import InputTextarea from 'component/Input/InputTextarea'
import FilterSelect from 'component/Select/FilterSelect'
import { styled } from '@mui/material/styles'
import ButtonExport from 'component/Button/ButtonExport'
import TableRowExport from 'component/Pdf/TableRowExport'
import AlertModal from 'new-components/modal/alert-modal'

const ResponsiveTable = styled('div')(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    '.MuiTableCell-head': {
      '&:nth-of-type(1)': {
        width: '5%'
      },
      '&:nth-of-type(2)': {
        width: '60%'
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        overflow: 'hidden'
      }
    }
  }
}))

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}
const initStateErrorMessage = {
  RECOMMEND_TEXT: ``,
  IS_DUPLICATE_RECOMMEND_TEXT: ``,
  RECOMMEND_TEXT_STRING_BASE: ``,
  RECOMMEND_TEXT_STRING_EMPTY: ``,
  RECOMMEND_TEXT_MAXIMUM_LENGTH: ``,
  RECOMMEND_TEXT_ANY_REQUIRED: ``,

  RECOMMEND_TEXT_EN: ``,
  IS_DUPLICATE_RECOMMEND_TEXT_EN: ``,
  RECOMMEND_TEXT_EN_STRING_BASE: ``,
  RECOMMEND_TEXT_EN_STRING_EMPTY: ``,
  RECOMMEND_TEXT_EN_MAXIMUM_LENGTH: ``,
  RECOMMEND_TEXT_EN_ANY_REQUIRED: ``
}
export default function Recommends() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()


  const [recommends, setRecommends] = useState([])
  const [exportData, setExportData] = useState([])

  const [recommendId, setRecommendId] = useState(Number)
  const [recommendText, setRecommendText] = useState('')
  const [recommendStatus, setRecommendStatus] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const [isAlertDel, setIsAlertDel] = useState<number>(0)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {
    let condition: any = { useWith: 'ALL' }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await RecommendApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setRecommends(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const loadDataForExport = useCallback(async () => {
    let condition: any = { useWith: 'ALL' }
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await RecommendApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const submit = () => {
    if (!recommendText) return setErrorMessage({ ...errorMessage, ...{ RECOMMEND_TEXT: t('RECOMMEND.MESSAGE.RECOMMEND_TEXT') } })

    dispatch(submitModal())
    RecommendApi.create({ recommendText })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('RECOMMEND.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECOMMEND.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECOMMEND.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!recommendText) return setErrorMessage({ ...errorMessage, ...{ RECOMMEND_TEXT: t(`RECOMMEND.MESSAGE.RECOMMEND_TEXT`) } })

    dispatch(submitModal())
    RecommendApi.update(recommendId, {
      recommendText,
      status: recommendStatus
    })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`RECOMMEND.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data

          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECOMMEND.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECOMMEND.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setRecommendId(0)
    setRecommendText('')
    setRecommendStatus('')
    clearErrorMessage()
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('RECOMMEND.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          RecommendApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('RECOMMEND.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('RECOMMEND.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = async (id: number) => {
    await RecommendApi.remove(id)
      .then((resp) => {
        notiSuccess(t('RECOMMEND.MESSAGE.SUCCESS.DELETE'))
        setIsAlertDel(0)
        loadData()
      })
      .catch((e) => {
        notiError(t('RECOMMEND.MESSAGE.ERROR'))
      })
  }

  const onEdit = (data: any) => {
    const { recommendId: rmId, recommendText: rmText, status: rmStatus } = data
    setRecommendId(rmId)
    setRecommendText(rmText)
    setRecommendStatus(rmStatus)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'recommendText', disablePadding: false, label: t('RECOMMEND.TABLE.CELL.RECOMMEND_TEXT') },
    { id: 'status', disablePadding: false, label: t('RECOMMEND.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('RECOMMEND.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action', class: 'py-3' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { recommendId: rmId, recommendText: rmText, updatedBy, updatedAt, status: rmStatus } = objData
    const statusBtnActive = rmStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = rmStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: rmId,
      id: rmId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: rmText },
        { option: 'STATUS', align: 'center', label: rmStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon
            {...objRenderData}
            onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
            oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
            onedit={() => onEdit(objRenderData.obj)}
            ondelete={() => setIsAlertDel(rmId)}
          />
        }
      </>
    )
  }

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}` }
  ]

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className={classes.root}>
      <AlertModal
        isOpen={!!isAlertDel}
        title={t('PROMOTION_TYPE.MESSAGE.DEL_TITLE')}
        message={t('RECOMMEND.MESSAGE.DEL_SUBTITLE', recommends.find((item: any) => Number(item.recommendId) === Number(isAlertDel)))}
        type="error"
        onSubmit={{ onClick: () => onDelete(isAlertDel), title: t("BUTTON.SUBMIT") }}
        onCancel={{
          onClick: () => setIsAlertDel(0), title: t("BUTTON.CANCEL")
        }}
        handleClose={() => setIsAlertDel(0)}
      />

      <Card className="border-0 h-100 overflow-auto">
        <HeaderCard text={t('RECOMMEND.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('RECOMMEND.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2 d-flex">
                  <div className='ml-auto'>
                    <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('RECOMMEND_SUMMARY.TABLE.RECOMMEND_LIST')}
                      rowsData={exportData.map((val, i) => {
                        return renderData(val, i, true)
                      })} />
                  </div>
                  <div className='pl-3'>
                    <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('RECOMMEND.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" btnStyle={{ height: '44px' }} />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Title>

          <ResponsiveTable className={'mt-3 pl-xl-3 pr-xl-2'} sx={{ '.MuiTableCell-root': { verticalAlign: 'top !important' } }}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={recommends.map((val, i) => {
                return renderData(val, i, false)
              })}
              verticalAlign="Top"
            />
          </ResponsiveTable>
        </Card.Body>
      </Card>

      <ModalCustom
        title={recommendId ? t('RECOMMEND.TITLE_UPDATE') : t('RECOMMEND.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {recommendId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                  <Form.Check type="switch" id="custom-switch" onChange={() => setRecommendStatus(recommendStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')} checked={recommendStatus === 'ACTIVE' ? true : false} style={{ marginLeft: 'auto' }} />
                </Form>
              </div>
            ) : (
              ''
            )}

            <InputTextarea
              onkeydown={handleKeyDown}
              onchange={(event) => {
                setRecommendText(event.target.value)
                clearErrorMessage()
              }}
              value={recommendText}
              label={t('RECOMMEND.INPUT.RECOMMEND_TEXT')}
              helperText={
                errorMessage.RECOMMEND_TEXT ||
                errorMessage.IS_DUPLICATE_RECOMMEND_TEXT ||
                errorMessage.RECOMMEND_TEXT_STRING_BASE ||
                errorMessage.RECOMMEND_TEXT_STRING_EMPTY ||
                errorMessage.RECOMMEND_TEXT_MAXIMUM_LENGTH ||
                errorMessage.RECOMMEND_TEXT_ANY_REQUIRED
              }
              required={true}
            />
          </div>
        }
        onReset={resetForm}
        onSubmit={recommendId ? submitEdit : submit}
        textBtnCancel={t('RECOMMEND.BUTTON.CANCEL')}
        textBtnConfirm={t('RECOMMEND.BUTTON.SAVE')}
      />
    </div>
  )
}
