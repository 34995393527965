import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { colors } from 'constants/theme';
import TableCustom from 'component/Table/TableCustom';
import ButtonCustom from 'component/Button/ButtonCustom';
import { RenderDataList } from './form-promotion-discount';
import _ from 'lodash';
import { numberFormat } from 'utils/app.utils';
import { icons } from 'constants/images';

type FormPromotionExtraProps = {
    dataEdit: any
    handleChangeDetailPromotion: (data: any) => void
    errorMessage: any
    pageDisabled: boolean
}

const FormPromotionExtra = (props: FormPromotionExtraProps) => {
    const { dataEdit, handleChangeDetailPromotion, errorMessage, pageDisabled } = props
    const { t } = useTranslation()

    const [dataExtraList, setDataExtraList] = useState<any>([{ dataList: [{}] }])
    const [disabledAddExtraList, setDisabledAddExtraList] = useState<boolean>(false)

    const [dataExtraFreeList, setDataExtraFreeList] = useState<any>([{ dataList: [{}] }])
    const [disabledAddExtraFreeList, setDisabledAddExtraFreeList] = useState<boolean>(false)

    const headCells = [
        { id: 'no', disablePadding: false, label: t('PROMOTION.FORM.NUMBER'), width: '50px', align: 'center', },
        { id: 'group', disablePadding: false, label: t('PROMOTION.FORM.GROUP_LIST'), align: 'left', class: 'text-nowrap col-group' },
        { id: 'type', disablePadding: false, label: t('PROMOTION.FORM.ITEMTYPE_LIST'), align: 'left', class: 'text-nowrap col-group' },
        { id: 'list', disablePadding: false, label: t('PROMOTION.FORM.ITEM_LIST'), align: 'left', class: 'text-nowrap col-group' },
        { id: 'qty', disablePadding: false, label: t('PROMOTION.FORM.COUNT_LIST'), align: 'center', class: 'text-nowrap col-qty' },
        { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
    ]

    const addNewRow = (index: number, free: boolean) => {
        const newDataExtraList = !free ? [...dataExtraList] : [...dataExtraFreeList]
        const newDataList = newDataExtraList[index]
        const isDisabled = newDataList?.dataList?.some((item: any) => !item?.group || !item?.itemId || !item?.itemTypeId || !item?.qty)
        return (
            <TableRow className="row-add-new-row">
                <TableCell
                    align="left"
                    scope="row"
                    colSpan={_.size(headCells)}
                    className={`p-2 ${(isDisabled || pageDisabled) && 'pe-none'}`}
                    onClick={() => {
                        const newDataLists = !newDataList?.dataList?.length ? [{}] : [...newDataList?.dataList, {}]
                        newDataExtraList[index] = { ...newDataExtraList[index], dataList: newDataLists }
                        if (!free) {
                            setDataExtraList(newDataExtraList)
                        } else {
                            setDataExtraFreeList(newDataExtraList)
                        }
                    }}
                    sx={{ button: { filter: isDisabled || pageDisabled ? 'grayscale(1)' : '' } }}
                >
                    <ButtonCustom
                        variant="text"
                        className="btn-add-new-row d-flex align-items-baseline"
                        textButton={`+ ${t('ADJUSTMENT.BUTTON.ADD')}`}
                        disabled={(isDisabled || pageDisabled)}
                    />
                </TableCell>
            </TableRow>
        )
    }

    const handlePromotionExtra = (data: any, status: 'BUY' | 'EXTRA') => {
        const newData: any = []
        data.forEach((item: any, index: number) => {
            if (item?.dataList?.length) {
                item?.dataList?.forEach((info: any) => {
                    const newItemType = info?.group
                    newData.push({
                        groupNumber: index + 1,
                        type: status,
                        itemType: newItemType,
                        itemId: info?.itemId || 0,
                        qty: info?.qty || 0
                    })
                })
            } else {
                newData.push({
                    groupNumber: index + 1,
                    type: status,
                })
            }
        })
        return newData
    }

    useEffect(() => {
        const newDataExtra = handlePromotionExtra(dataExtraList, 'BUY')
        const newDataExtraFree = handlePromotionExtra(dataExtraFreeList, 'EXTRA')

        setDisabledAddExtraList(dataExtraList?.some((item: any) => !item?.dataList || !item?.dataList?.length || item?.dataList?.some((info: any) => !info?.group || !info?.itemId || !info?.itemTypeId || !info?.qty)))
        setDisabledAddExtraFreeList(dataExtraFreeList?.some((item: any) => !item?.dataList || !item?.dataList?.length || item?.dataList?.some((info: any) => !info?.group || !info?.itemId || !info?.itemTypeId || !info?.qty)))

        const newData = [...newDataExtra, ...newDataExtraFree]
        handleChangeDetailPromotion({ ...dataEdit, promotionExtra: newData })

    }, [dataExtraList, dataExtraFreeList])

    useEffect(() => {
        if (dataEdit?.promotionExtraCurrent?.length) {
            setDataExtraList(dataEdit?.promotionExtraCurrent)
        }
        if (dataEdit?.promotionExtraFreeCurrent?.length) {
            setDataExtraFreeList(dataEdit?.promotionExtraFreeCurrent)
        }
    }, [dataEdit?.promotionExtraCurrent, dataEdit?.promotionExtraFreeCurrent])

    return (
        <Box>
            <Box>
                <Box fontSize={'20px'} fontWeight={'500'}>
                    {t('PROMOTION.FORM.BUY_LIST')}
                </Box>
                <Box padding={'0 24px'}>
                    <Box color={(disabledAddExtraList || pageDisabled) ? '#b6b6b6' : colors.themeSecondColor} margin={'16px'} >
                        <Box component={'span'} onClick={() => setDataExtraList([...dataExtraList, { dataList: [{}] }])} sx={{ cursor: 'pointer', pointerEvents: (disabledAddExtraList || pageDisabled) ? 'none' : '' }}>
                            {`+ ${t('PROMOTION.FORM.BUY_ADD_LIST')}`}
                        </Box>

                        <span>
                            <Tooltip title={t('PROMOTION.FORM.BUY_ADD_LIST_HOVER')} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }} >
                                <InfoIcon />
                            </Tooltip>
                        </span>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                        {
                            dataExtraList?.map((item: any, index: number) => {
                                const newFree = false
                                return (
                                    <Box key={index}>
                                        <Box fontSize={'20px'} fontWeight={'500'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            {t('PROMOTION.FORM.BUY_LIST_NUMBER', { index: index + 1 })}
                                            <Box
                                                component={'span'}
                                                color={colors.statusInactive}
                                                marginLeft={'8px'}
                                                onClick={() => { !pageDisabled && setDataExtraList(dataExtraList.filter((info: any, num: number) => num !== index)) }}
                                                sx={{ cursor: 'pointer', button: { filter: pageDisabled ? 'grayscale(1)' : '', opacity: pageDisabled ? '.5' : '1' } }}

                                            >
                                                <IconButton >
                                                    <img src={icons.btnTrash} alt="trash" />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Box marginTop={'16px'} sx={{ '.col-group': { minWidth: '250px' }, '.col-qty': { minWidth: '150px' } }}>
                                            <TableCustom
                                                page={1}
                                                pageLimit={99}
                                                sortType={''}
                                                sortBy={''}
                                                onSort={() => console.log()}
                                                setPageLimit={() => console.log()}
                                                setPage={() => console.log()}
                                                rowCount={1}
                                                hidePagination
                                                headCells={headCells}
                                                rowsData={[
                                                    addNewRow(index, newFree),
                                                    item?.dataList?.map((info: any, index2: number) => {
                                                        return (
                                                            <RenderDataList
                                                                key={index2}
                                                                objData={info}
                                                                index={index2}
                                                                dataList={item?.dataList}
                                                                setDataList={(data: any) => {
                                                                    const newDataExtra = [...dataExtraList]
                                                                    const newData = { ...newDataExtra[index], dataList: data }
                                                                    newDataExtra[index] = newData
                                                                    setDataExtraList(newDataExtra)
                                                                }}
                                                                errorMessage={errorMessage}
                                                                pageDisabled={pageDisabled}
                                                            />
                                                        )
                                                    })
                                                ]}
                                            />
                                        </Box>
                                        <Box bgcolor={colors.themeMainColor06} color={colors.themeMainColor} fontSize={{ sm: '20px', xs: '18px' }} height={'48px'} width={'100%'} marginTop={'8px'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} padding={'0 24px'}>
                                            <Box>
                                                {`${t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')}`}
                                            </Box>
                                            <Box padding={'0 8px 0 48px'}>
                                                {numberFormat(item?.dataList?.length ? item?.dataList.reduce((accumulator: any, item: any) => {
                                                    return accumulator + ((item?.price || 0) * (item?.qty || 0));
                                                }, 0) : 0)}
                                            </Box>
                                            <Box>
                                                {t('CURRENCY_CHANGE.TH')}
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box fontSize={'20px'} fontWeight={'500'}>
                    {t('PROMOTION.FORM.EXTRA_LIST')}
                </Box>
                <Box padding={'0 24px'}>
                    <Box color={(disabledAddExtraFreeList || pageDisabled) ? '#b6b6b6' : colors.themeSecondColor} margin={'16px'} >
                        <Box component={'span'} onClick={() => setDataExtraFreeList([...dataExtraFreeList, { dataList: [{}] }])} sx={{ cursor: 'pointer', pointerEvents: (disabledAddExtraList || pageDisabled) ? 'none' : '' }}>
                            {`+ ${t('PROMOTION.FORM.EXTRA_ADD_LIST')}`}
                        </Box>
                        <span>
                            <Tooltip title={t('PROMOTION.FORM.EXTRA_ADD_LIST_HOVER')} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }} >
                                <InfoIcon />
                            </Tooltip>
                        </span>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                        {
                            dataExtraFreeList?.map((item: any, index: number) => {
                                const newFree = true
                                return (
                                    <Box key={index}>
                                        <Box fontSize={'20px'} fontWeight={'500'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            {t('PROMOTION.FORM.EXTRA_LIST_NUMBER', { index: index + 1 })}
                                            <Box
                                                component={'span'}
                                                color={colors.statusInactive}
                                                marginLeft={'8px'}
                                                onClick={() => !pageDisabled && setDataExtraFreeList(dataExtraFreeList.filter((info: any, num: number) => num !== index))}
                                                sx={{ cursor: 'pointer', button: { filter: pageDisabled ? 'grayscale(1)' : '', opacity: pageDisabled ? '.5' : '1' } }}
                                            >
                                                <IconButton >
                                                    <img src={icons.btnTrash} alt="trash" />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Box marginTop={'16px'} sx={{ '.col-group': { minWidth: '250px' }, '.col-qty': { minWidth: '150px' } }}>
                                            <TableCustom
                                                page={1}
                                                pageLimit={99}
                                                sortType={''}
                                                sortBy={''}
                                                onSort={() => console.log()}
                                                setPageLimit={() => console.log()}
                                                setPage={() => console.log()}
                                                rowCount={1}
                                                hidePagination
                                                headCells={headCells}
                                                rowsData={[
                                                    addNewRow(index, newFree),
                                                    item?.dataList?.map((info: any, index2: number) => {
                                                        return (
                                                            <RenderDataList
                                                                key={index2}
                                                                objData={info}
                                                                index={index2}
                                                                dataList={item?.dataList}
                                                                setDataList={(data: any) => {
                                                                    const newDataExtra = [...dataExtraFreeList]
                                                                    const newData = { ...newDataExtra[index], dataList: data }
                                                                    newDataExtra[index] = newData
                                                                    setDataExtraFreeList(newDataExtra)
                                                                }}
                                                                errorMessage={errorMessage}
                                                                pageDisabled={pageDisabled}
                                                            />
                                                        )
                                                    })
                                                ]}
                                            />
                                        </Box>
                                        <Box bgcolor={colors.themeMainColor06} color={colors.themeMainColor} fontSize={{ sm: '20px', xs: '18px' }} height={'48px'} width={'100%'} marginTop={'8px'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} padding={'0 24px'}>
                                            <Box>
                                                {`${t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')}`}
                                            </Box>
                                            <Box padding={'0 8px 0 48px'}>
                                                {numberFormat(item?.dataList?.length ? item?.dataList.reduce((accumulator: any, item: any) => {
                                                    return accumulator + ((item?.price || 0) * (item?.qty || 0));
                                                }, 0) : 0)}
                                            </Box>
                                            <Box>
                                                {t('CURRENCY_CHANGE.TH')}
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FormPromotionExtra