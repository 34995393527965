import { useEffect, useState } from 'react'
import { FormControl, RadioGroup, FormControlLabel, Typography, styled, Grid, Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

/** API */

/** COMPONENT */
import InputRadio from 'component/Input/InputRadio'

/** STYLE */
import { colors } from 'constants/theme';
import InputCheckbox from 'component/Input/InputCheckbox';

export const FormFormatReceipt = styled(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: 500
  },
  // '&.active': {
  //   backgroundColor: colors.themeSecondColor06,
  //   '.MuiTypography-root': {
  //     color: colors.themeSecondColor
  //   }
  // }
}))

export interface ApplicationInterface {
  medShowType: any;
  pdShowType: any;
  openNotification: string;
  formatAppointment: any;
  callbackMedShowType: (value: any) => void;
  callbackPdShowType: (value: any) => void;
  callbackOpenNotification: (value: string) => void;
  callbackFormatAppointment: (value: any) => void;
  key: any;
  dataABout: any
  setDataAbout: (value: any) => void
}
export default function FormTreatment(props: ApplicationInterface) {
  const { t } = useTranslation()
  const [medShowType, setMedShowType] = useState<any>('')
  const [pdShowType, setPdShowType] = useState<any>('')
  const [formatAppointment, setFormatAppointment] = useState('');
  const [openNotification, setOpenNotification] = useState(props.openNotification === '1');

  useEffect(() => {
    setMedShowType(props.medShowType)
  }, [props.medShowType])

  useEffect(() => {
    setPdShowType(props.pdShowType)
  }, [props.pdShowType])

  useEffect(() => {
    setFormatAppointment(props.formatAppointment)
  }, [props.formatAppointment])


  useEffect(() => {
    props.callbackMedShowType(medShowType)
    props.callbackPdShowType(pdShowType)
    props.callbackFormatAppointment(formatAppointment)
  }, [medShowType, pdShowType, formatAppointment])

  const handleCheckboxChange = () => {
    const newValue = !openNotification;
    setOpenNotification(newValue);
    props.callbackOpenNotification(newValue ? '1' : '0');
  };

  return (
    <div key={props.key} className="px-4 pt-3 pb-3">
      <Typography className='mb-2' sx={{ fontWeight: 500, fontSize: '20px' }}>{t('CLINIC_MANAGEMENT.FORM.SHOW_TYPE')}</Typography>
      <FormControl className="d-block">
        <Typography className='mb-1' sx={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.MED_SHOW_TYPE')}</Typography>
        <RadioGroup
          aria-label="defaultReceipt"
          onChange={(event) => setMedShowType(event.target.value)}
          className="flex-column"
          name="row-radio-buttons-group"
        >
          <Grid container direction="column">
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${medShowType === "1" ? 'active' : ''}`}
                checked={medShowType === "1"}
                value="1"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.MED_TYPE_TABLE')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${medShowType === "0" ? 'active' : ''}`}
                checked={medShowType === "0"}
                value="0"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.MED_TYPE_IMAGE')}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <FormControl className="d-block">
        <Typography className='mb-1' sx={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.PD_SHOW_TYPE')}</Typography>
        <RadioGroup
          aria-label="defaultReceipt"
          onChange={(event) => setPdShowType(event.target.value)}
          className="flex-column"
          name="row-radio-buttons-group"
        >
          <Grid container direction="column">
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${pdShowType === "1" ? 'active' : ''}`}
                checked={pdShowType === "1"}
                value="1"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.MED_TYPE_TABLE')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${pdShowType === "0" ? 'active' : ''}`}
                checked={pdShowType === "0"}
                value="0"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.MED_TYPE_IMAGE')}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <FormControl className="d-block">
        <Typography className='mb-1' sx={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.DISPLAY_FORMAT_APPOINTMENT')}</Typography>
        <RadioGroup
          aria-label="defaultReceipt"
          onChange={(event) => setFormatAppointment(event.target.value)}
          className="flex-column"
          name="row-radio-buttons-group"
        >
          <Grid container direction="column">
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${formatAppointment === "1" ? 'active' : ''}`}
                checked={formatAppointment === "1"}
                value="1"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_FORMAT_500PX')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${formatAppointment === "0" ? 'active' : ''}`}
                checked={formatAppointment === "0"}
                value="0"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_FORMAT_284PX')}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box className={'mt-2 mb-2'}>
        <Divider
          className="mx-0 mt-2"
          sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }}
        />
      </Box>

      <FormControl className="d-block">
        <Typography className='mb-1' sx={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.DISPLAY_DF_0')}</Typography>
        <RadioGroup
          aria-label="defaultReceipt"
          onChange={(event) => props.setDataAbout((value: any) => ({ ...value, hasNoDf: value?.hasNoDf === '1' ? '0' : '1' }))}
          className="flex-column"
          name="row-radio-buttons-group"
        >
          <Grid container direction="column">
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${props.dataABout?.hasNoDf === "1" ? 'active' : ''}`}
                checked={props.dataABout?.hasNoDf === "1"}
                value="1"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.DISPLAY_DF_0_FOLLOW')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFormatReceipt
                className={`ml-2 mr-2 pr-3 ${props.dataABout?.hasNoDf === "0" ? 'active' : ''}`}
                checked={props.dataABout?.hasNoDf === "0"}
                value="0"
                control={<InputRadio size='small' />}
                label={t('CLINIC_MANAGEMENT.FORM.DISPLAY_DF_0_IS_0')}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>

      <Box className={'mt-2 mb-2'}>
        <Divider
          className="mx-0 mt-2"
          sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }}
        />
      </Box>
      <Typography className='mb-2' sx={{ fontWeight: 500, fontSize: '18px' }}>
        {t('CLINIC_MANAGEMENT.FORM.OVERDUE_NOTIFICATION')}
      </Typography>
      <Box>
        <InputCheckbox
          label={t('CLINIC_MANAGEMENT.FORM.OPEN_NOTIFICATION')}
          checked={openNotification}
          onChange={handleCheckboxChange}
        />
      </Box>
    </div>
  )
}
