import React, { useCallback, useEffect, useState } from 'react'
import { Button, Menu, MenuItem, ListItemIcon, Typography, Avatar, Box, Collapse, FormControl, RadioGroup, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { fileUrl } from 'api/api'
import _ from 'lodash'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'
import { KEY_STORAGE, removeAllStorage, removeBranchStorage } from 'constants/common'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserEdit, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { colors } from 'constants/theme'
import { imgs, Icons } from 'constants/images'
import Cookies from 'universal-cookie'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import { editUser, selectMe } from 'app/slice/user.slice'
import { getBranch, getBranchName, getServicePointName } from 'utils/app.utils'
import IconLanguage from 'assets/icon/navigation/icon-language'
import InputRadio from 'component/Input/InputRadio'
import moment from 'moment'

const ButtonProfile = styled(Button)(({ theme }) => ({
  minWidth: 'unset !important',
  backgroundColor: 'transparent !important',
  zIndex: 1,
  '&[aria-controls="menu-list-grow"] svg': {
    transform: 'rotate(180deg)'
  },
  '& svg': {
    color: colors.black
  },
  '& .avatar': {
    width: '2.625rem !important',
    height: '2.625rem !important',
    boxShadow: `0 0 6px 0px ${colors.white}`,
    [theme.breakpoints.down('sm')]: {
      width: '1.875rem !important',
      height: '1.875rem !important'
    }
  },
  '& .buttonLabel': {
    textAlign: 'left',
    paddingLeft: '.5rem',
    color: colors.textPrimary,
    maxWidth: '140px',
    width: '140px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    '& .user-fullName': {
      textTransform: 'capitalize',
      fontSize: '14px'
    },
    '& .pick-detail': {
      fontSize: '14px',
      marginTop: '-3px'
    }
  }
}))

const MenuDropdown = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${colors.lightGray}`,
    boxShadow: 'none',
    borderRadius: '3px',
    marginTop: '.375rem',
    [theme.breakpoints.up('sm')]: {
      left: 'auto !important',
      right: '1.5rem'
    }
  },
  '& .MuiList-root': {
    maxWidth: '208px',
    padding: '1rem .5rem .5rem'
  }
}))

const TextInformation = styled(Typography)(({ theme }) => ({
  lineHeight: 'normal',
  '& .user-fullName': {
    fontSize: '14px',
    color: colors.textPrimary
  },
  '& .pick-detail': {
    fontSize: '12px',
    color: colors.textLightGray,
    borderBottom: `1px solid ${colors.lightGray}`
  }
}))

const MenuItemList = styled(MenuItem)(({ theme }) => ({
  margin: '0 -.5rem !important',
  padding: '.5rem 1rem !important',
  display: 'flex !important',
  justifyContent: 'flex-start !important',
  '& .MuiListItemIcon-root': {
    fontSize: '14px',
    minWidth: '28px',
    '& path': {
      fill: colors.themeSecondColor
    },
    '& img': {
      width: '14px'
    },
    '& img.icon-signOut': {
      width: '17px'
    }
  }
}))

export const MenuLang = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '70px',
  '.menulang-content': {
    position: 'fixed',
    zIndex: '2000',
    width: '150px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '4px 0px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    '.menulang-item-list': {
      padding: '8px 10px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      ':hover': {
        background: colors.navbar,
        backgroundColor: '#fff',
      }
    }
  },
}))

export const dataLanguage = [
  {
    langCode: 'TH',
    langName: 'ไทย ',
    icon: '🇹🇭',
  },
  {
    langCode: 'EN',
    langName: 'English',
    icon: '🇺🇸'
  }
]

export default function UserMenu() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectMe)
  const [profile, setProfile]: any = useState({
    fullname: ``,
    positionName: ``,
    roleName: ``,
    branchName: ``,
    servicePointName: ``
  })

  const [openLanguage, setOpenLanguage] = useState<boolean>(false)
  const [languageCurrent, setLanguageCurrent] = useState<string>(localStorage.getItem(KEY_STORAGE.LANGUAGE) || 'TH')

  function doLogout() {
    removeAllStorage()
    const cookies = new Cookies()
    cookies.remove('REMEMBER_ME')
    cookies.remove('TOKEN')
    window.location.reload()
  }

  const loadProfile = useCallback(async () => {
    if (user) {
      setProfile({
        fullname: user.fullname,
        positionName: user?.position?.positionName,
        roleName: user?.roles?.roleName,
        branchName: getBranchName() || user?.roles?.roleBranch?.branches?.branchName || '-',
        servicePointName: getServicePointName() || user?.roles?.roleBranch?.servicePoints?.servicePointName || '-'
      })
    }
  }, [user])

  const goPick = () => {
    removeBranchStorage()
    window.location.reload()
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderImage = (img: any) => {
    if (img) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  const goEditProfile = async () => {
    const resp = await PersonnelApi.findById(user.userId)
    dispatch(editUser({ key: 'position', value: resp.data.position }))
    if (resp.status === 200 && !_.isEmpty(resp.data)) history.push(routeName.personnels + '/update', resp.data)
    else return
  }

  React.useEffect(() => {
    loadProfile()
  }, [open, loadProfile, getBranch()])

  const handleLanguage = (lang: string) => {
    const newLang = lang.toLocaleLowerCase()
    setLanguageCurrent(lang)
    moment.locale(newLang);
    localStorage.setItem(KEY_STORAGE.LANGUAGE, lang)
    i18n.changeLanguage(newLang)
  }

  const renderLanguage = () => {
    let newLang = "ไทย"

    for (const index in dataLanguage) {
      const newDataLang = dataLanguage[index]
      if (newDataLang.langCode === languageCurrent) {
        newLang = newDataLang.langName
        break
      }
    }
    return newLang
  }

  useEffect(() => {
    if (!open) {
      setOpenLanguage(false)
    }
  }, [open])

  return (
    <>
      <ButtonProfile id="profile-button" aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleToggle} className={'py-0 pr-0 pl-3'} disableRipple>
        <Avatar alt="Profile" src={renderImage(user?.profile?.fullpath)} className={'avatar'} sx={{ backgroundColor: colors.white }}>
          <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
        </Avatar>
        <div className={'buttonLabel'}>
          <div className={'user-fullName text-ellipsis'}>{profile.fullname}</div>
          <div className={'pick-detail text-ellipsis'}>{profile.positionName}</div>
        </div>
        <FontAwesomeIcon icon={faCaretDown} className={'ml-2'} />
      </ButtonProfile>

      <MenuDropdown
        id="menu-list-grow"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-button'
        }}
      >
        <TextInformation variant="inherit">
          <p className={'user-fullName m-0 px-2'}>{profile.fullname}</p>
          <p className={'pick-detail mb-2 p-2'}>
            {t('NAV.BRANCH')}: {profile.branchName} <br />
            {t('NAV.SERVICE_POINT')}: {profile.servicePointName}
          </p>

        </TextInformation>
        <MenuItemList onClick={goEditProfile}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUserEdit} />
          </ListItemIcon>
          <Typography variant="inherit">{t('NAV.EDIT_PROFILE')}</Typography>
        </MenuItemList>
        <MenuItemList onClick={goPick}>
          <ListItemIcon>
            <Icons.Refresh />
          </ListItemIcon>
          <Typography variant="inherit" className={'text-ellipsis'} title={t('NAV.EDIT_SERVICE_POINT')}>{t('NAV.EDIT_SERVICE_POINT')}</Typography>
        </MenuItemList>
        <MenuItemList onClick={() => setOpenLanguage(!openLanguage)} sx={{ position: 'relative' }}>
          <ListItemIcon>
            <IconLanguage />
          </ListItemIcon>
          <Typography variant="inherit">{renderLanguage()}</Typography>
          {openLanguage &&
            <MenuLang>
              <Box className={'menulang-content'}>
                {dataLanguage.map((item) => {
                  return (
                    <Box
                      key={item.langCode}
                      className={'menulang-item-list'}
                      onClick={() => handleLanguage(item.langCode)}
                    >
                      <Box sx={{ fontSize: '18px' }}>
                        {item.icon}
                      </Box>
                      <Box>
                        {item.langName}
                      </Box>

                    </Box>
                  )
                })}
              </Box>
            </MenuLang>
          }
        </MenuItemList>
        <MenuItemList onClick={doLogout}>
          <ListItemIcon>
            <Icons.SignOut />
          </ListItemIcon>
          <Typography variant="inherit">{t('BUTTON.SIGN_OUT')}</Typography>
        </MenuItemList>
      </MenuDropdown>
    </>
  )
}
