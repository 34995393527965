import { TextField } from "@mui/material"
import { HelperText } from "./helper-text"
import React, { useEffect, useRef } from "react"

interface TextInputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    id?: string
    value?: any
    name?: any
    disabled?: boolean
    placeholder?: string
    label?: string
    required?: boolean
    helperText?: string
    inputProps?: any
    fullWidth?: boolean
    width?: number | string
    size?: 'large' | 'small'
    borderRadius?: string
    focus?: boolean
    maxLength?: number
    numberOnly?: boolean
    numberOrText?: boolean
}
export default function TextInput(props: TextInputProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    ////-------------------
    ////    HANDLE
    ////-------------------
    const sxHeightSmall = {
        '& .MuiInputLabel-root': {
            transform: 'translate(14px, 3px)',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -10px) scale(0.75)',
        },
    }

    const sx = {
        '& .MuiInputLabel-root': {
            maxWidth: '85%',
            backgroundColor: 'transparent !important',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: props.borderRadius,
            backgroundColor: props.disabled ? '#F0F0F0' : '#FFFFFF',
            width: props.width,
            height: props.size === 'small' ? 32 : 40,
            '& fieldset': {
                borderWidth: '1px',
                borderColor: '#C5C5C5',
            },
            '&:hover fieldset': {
                borderWidth: '1px',
                borderColor: '#231F20',
                color: '#29302E'
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: '#1CB99A',
                color: '#29302E',
            },
            '&.Mui-disabled fieldset': {
                borderWidth: '1px',
                borderColor: '#C5C5C5',
            },

            '&.Mui-error fieldset': {
                borderWidth: '1px',
                borderColor: `#B00020`,
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#1CB99A',
        },
        '& .MuiInputLabel-root.Mui-error': {
            color: '#B00020'
        },
        '& .MuiOutlinedInput-input': {
            '& .Mui-disabled': {
                color: '#808080'
            }
        },
        '& .css-k4qjio-MuiFormHelperText-root': {
            marginLeft: 0,
            marginTop: 0
        },
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;
        if (props.numberOnly) {
            newValue = newValue.replace(/[^0-9.]/g, '');
            if (newValue.indexOf('.') !== newValue.lastIndexOf('.')) {
                newValue = newValue.slice(0, newValue.lastIndexOf('.'));
            }
        } else if (props.numberOrText) {
            newValue = newValue.replace(/[^A-Za-z0-9]/g, '');
        }
        props.onChange({ ...e, target: { ...e.target, value: newValue } });
    };
    return (
        <TextField
            id={props.id}
            label={props.label}
            onChange={handleChange}
            onKeyPress={props.onKeyPress}
            disabled={props.disabled}
            placeholder={props.placeholder}
            required={props.required}
            value={props.value}
            name={props.name}
            variant="outlined"
            size='small'
            fullWidth={props.fullWidth}
            helperText={props.helperText ? <HelperText label={props.helperText} /> : ''}
            error={props.helperText !== '' && props.helperText !== undefined}
            sx={props.size === 'small' ? { ...sx, ...sxHeightSmall } : sx}
            inputProps={{ maxLength: props.maxLength }}
            InputProps={props.inputProps}
            inputRef={props.focus ? inputRef : null}
            InputLabelProps={{
                sx: {
                    '& .MuiFormLabel-asterisk': {
                        color: (!props.value || props.value === '') ? 'gray !important' : "red !important", // สีเทาเมื่อไม่ได้โฟกัส
                    },
                    '&.Mui-focused .MuiFormLabel-asterisk': {
                        color: 'red !important', // สีแดงเมื่อโฟกัส
                    },
                },
            }}
        />
        // Exanple Text inputProps={{ startAdornment: (<InputAdornment position="start">CI</InputAdornment>) }
        // Example Icon inputProps={{ endAdornment: (<InputAdornment position="end"><SearchIcon /></InputAdornment>) }}
    )
}