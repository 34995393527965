export const routeName = {
  login: '/login',
  resetPassword: '/reset-password/:token',
  confirmAppointment: '/confirm-appointment',
  pick: '/pick',
  home: '/',
  dashboard: '/dashboard',
  userManagement: '/user-management',
  treatmentRights: '/treatment-rights',
  welcome: '/welcome',

  /** DEFAULT LAB */
  labCompany: '/lab-companies',
  labs: '/labs',
  labType: '/lab-type',
  labGroup: '/lab-group',

  /** DEFAULT PRODUCT */
  medicineTypes: '/medicine-types',
  productTypes: '/product-types',
  medicineFormats: '/medicine-formats',
  medicineUnits: '/medicine-units',
  medicineInstructions: '/medicine-instructions',
  productInstructions: '/product-instructions',
  medicinePreserves: '/medicine-preserves',
  productPreserves: '/product-preserves',
  medicineSymptoms: '/medicine-symptoms',
  productUses: '/medicine-uses',
  productUnits: '/product-units',

  materialInstructions: '/material-instructions',
  materialTypes: '/material-types',
  materialPreserves: '/material-preserves',
  materialUnits: '/material-units',

  /** FINANCES  */
  currencies: '/currencies',
  expenseTypes: '/expense-types',
  banks: '/banks',
  cardTypes: '/card-types',

  /** PERSONNEL MANAGEMENT  */
  positions: '/positions',
  reportPermission: '/report-permission',

  /** DEFAULT USER  */
  personnels: '/personnels',
  prefixes: '/prefixes',
  careers: '/careers',
  accessChannels: '/access-channels',
  serviceFactor: '/service-factor',
  experts: '/experts',
  frequenciesAlcohols: '/frequencies-alcohols',
  frequenciesCigarettes: '/frequencies-cigarettes',
  patientTypes: '/patient-types',
  roles: '/roles',
  tag: '/tag',

  /** DEFAULT */
  appointmentDurations: '/appointment-durations',
  templateText: '/template-text',
  keyword: '/keyword',

  /** TREATMENT COURSE */
  treatmentCourseTypes: '/treatment-course-types',
  treatmentCourse: '/treatment-course',

  /** TREATMENT PLAN */
  treatmentPlanTypes: '/treatment-plan-types',
  treatmentPlans: '/treatment-plans',

  /** TREATMENT RIGHT */
  treatmentRight: '/treatment-right',

  /** TREATMENT */
  clinicFees: '/clinic-fees',
  imageTypes: '/image-types',
  operativeTypes: '/operative-types',
  xrayFilmTypes: '/xray-film-types',
  operativeUnits: '/operative-units',
  diagnosisType: '/diagnosis-types',
  recommend: '/recommends',
  diagnosis: '/diagnosis',
  operatives: '/operatives',
  recalls: '/recalls',
  treatmentDoctorNote: '/treatment-doctor-notes',

  /** CLINIC MANAGEMENT */
  clinicManagement: '/admin/clinic-managements',

  /** CUSTOM */
  custom: '/admin/custom',

  /** BRANCH AND SERVICE POINT */
  branch: '/branches',
  servicePoint: '/service-points',

  /** DOCTOR FEE MANAGEMENTS */
  templateDoctorFee: '/template-doctor-fees',
  doctorFee: '/doctor-fees',
  doctorFeeDetail: '/doctor-fees-history',
  handInsurances: '/hand-insurances',

  /** CREDIT FEE MANAGEMENTS */
  templateCreditFee: '/template-credit-fees',
  creditFee: '/credit-fees',

  /** WAREHOUSES */
  medicine: '/medicines',
  importProduct: '/import-products',
  product: '/products',
  inventory: '/inventory',
  adjustment: '/adjustment',
  material: '/materials',

  /** COUNTER */
  doctorSchedule: '/doctor-schedule',
  appointment: '/appointment',
  patientManagement: '/management-patient',
  register: '/register',
  retrospectiveOrder: '/retrospective-order',
  retrospectiveEdit: '/retrospective-edit',
  recallLists: '/recall-lists',
  manageDf: '/manage-doctor-fee',
  manageDfLab: '/manage-doctor-lab-fee',

  /** PAYMENT */
  payment: '/payment',
  paymentDetail: '/payment-detail',
  overdue: '/overdue',
  overdueDetail: '/overdue-detail',
  advance: '/advance',

  /** FINANCE */
  salary: '/salary',
  incomeExpenses: '/income-expenses',

  /** TREATMENTS */
  treatmentsQueue: '/treatments/queue',
  treatmentsForm: '/treatments',
  treatmentsAppointmentHistory: '/treatments/patient-appointment',
  treatmentsEmrHistory: '/treatments/patient-EMR',
  treatmentsHistory: '/treatments/history',

  /** XRAY */
  xray: '/x-ray',

  /** LAB_ORDER */
  labOrder: '/lab-order',

  /** APPLICATION */
  news: '/application/news',
  promotions: '/application/promotions',
  registerApp: '/permission-app',
  banner: '/manage-web/banner',
  about_us: '/manage-web/about-us',
  service: '/manage-web/service',
  price_list: '/manage-web/price-list',
  review: '/manage-web/review',
  article: '/manage-web/article',
  contact_us: '/manage-web/contact-us',

  // APPROVELIST
  approval: '/approval',
  approvelList: '/approvel-list',
  approvelListCost: '/approvel-list/change-cost',
  approvelListCancelReciept: '/approvel-list/cancel-reciept',

  // PROMOTION
  promotionType: '/promotions-type',
  promotion: '/promotions',

  // VOUCHER
  voucherType: '/voucher-type',
  voucher: '/voucher',

  /** REPORT */
  report: '/report',

  /** UPDATE */
  update: '/update',

  /**DOCTOR_NOTE */
  doctorNoteTypeForm: '/doctor-note-type-form',
  doctorNoteForm: '/doctor-note-form',
  doctorNoteTypePic: '/doctor-note-type-pic',
  doctorNotePic: '/doctor-note-pic',

  checkIn: '/check-in',

  memberManagement: '/member-managements',

  messsage_notification: '/messsage-notification'
}
