import { Box, TextField } from "@mui/material";
import { HelperText } from "./helper-text";

interface TextAreaButtonInputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    onClick: () => void
    label?: string
    helperText?: string
    fullWidth?: boolean
    width?: number
    row?: number
    icon?: any
    required?: boolean
    disabled?: boolean
    value?: any
}

export default function TextAreaButtonInput(props: TextAreaButtonInputProps) {

    ////-------------------
    ////    HANDLE
    ////-------------------
    const handleOnClick = () => {
        if (!props.disabled) {
            props.onClick()
        }
    }

    return (
        <Box display="flex" flexDirection="row">
            <TextField
                multiline
                value={props.value}
                disabled={props.disabled}
                required={props.required}
                label={props.label}
                rows={props.row || 3}
                fullWidth={props.fullWidth}
                helperText={props.helperText ? <HelperText label={props.helperText} /> : ''}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '4px 0 0 4px',
                        '& fieldset': {
                            borderColor: '#C5C5C5',
                        },
                        '&:hover fieldset': {
                            borderColor: '#C5C5C5',
                            color: '#29302E'
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#1CB99A',
                            color: '#29302E'
                        },
                        '&.Mui-disabled fieldset': {
                            borderColor: '#C5C5C5',
                        },

                        '&.Mui-error fieldset': {
                            borderColor: `#B00020`,
                        },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#1CB99A',
                    },
                    '& .MuiInputLabel-root.Mui-error': {
                        color: '#B00020'
                    },
                    '& .MuiOutlinedInput-input': {
                        '& .Mui-disabled': {
                            color: '#808080'
                        }
                    },
                    '& .css-k4qjio-MuiFormHelperText-root': {
                        marginLeft: 0,
                        marginTop: 0
                    },

                }} />
            <Box
                width='60px'
                bgcolor={props.disabled ? '#C5C5C5' : '#1CB99A'}
                display='flex'
                alignItems='center'
                justifyContent="center"
                onClick={handleOnClick}
                sx={{ borderRadius: '0 4px 4px 0', cursor: props.disabled ? 'not-allowed' : 'pointer' }}>
                {props.icon}
            </Box>
        </Box>
    )
}