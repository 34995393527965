import { useState, useEffect, useCallback } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, IconButton } from '@mui/material'
import InputCheckbox from 'component/Input/InputCheckbox'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles, { ColLabType, HeaderTable, TitleLab, AddNewRowTable } from 'features/setting/default-labs/lab-type/style'

/** API */
import { routeName } from 'routes/routes-name'
import LabTypeApi from 'api/setting/default-labs/lab-type.api'
import LabsApi from 'api/setting/default-labs/lab.api'

import InputTextField from 'component/Input/InputTextField'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import { icons } from 'constants/images'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

interface LabsInterface {
  inputSupport: string
  labId: number
  labName: string
}

interface LabTypesInterface {
  labTypeId: number
  labTypeName: string
  labId: number
  labName: string
  inputSupport: string
}
const initStateErrorMessage = {
  LAB_TYPE_NAME: ``
}

export default function FormLabType() {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const [labTypeId, setLabTypeId] = useState(Number)
  const [labTypeName, setLabTypeName] = useState('')
  const [labsInit, setLabsInit] = useState<LabsInterface[]>([])
  const [labs, setLabs] = useState<LabsInterface[]>([])
  const [labTypes, setLabTypes] = useState<LabTypesInterface[]>([{ labName: '', labId: 1, inputSupport: 'NOT_SUPPORT', labTypeId: 1, labTypeName: '' }])

  const submit = () => {
    const labTypeTemp = labTypes
    const labTypeObject: any = []
    for (const lab of labTypeTemp) {
      if (lab.labName === '') return notiError(t(`กรุณาเลือกรายการ LAB`))
      labTypeObject.push({ labName: lab.labName, inputSupport: lab.inputSupport })
    }
    const objCreate = {
      labTypeName: labTypeName,
      status: 'ACTIVE',
      mapLab: labTypeObject
    }
    if (!labTypeName) return setErrorMessage({ ...errorMessage, ...{ LAB_TYPE_NAME: t('LAB_TYPE.MESSAGE.LAB_TYPE_NAME') } })
    LabTypeApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('LAB_TYPE.MESSAGE.SUCCESS.CREATE'), '', null)
          history.push(routeName.labType)
        } else {
          const err = res.response.data
          notiError(t(`LAB_TYPE.MESSAGE.${err.message}`))
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`LAB_TYPE.MESSAGE.${err.message}`))
      })
  }

  const submitEdit = () => {
    const labTypeTemp = labTypes
    const labTypeObject: any = []
    for (const lab of labTypeTemp) {
      if (lab.labName === '') return notiError(t(`กรุณาเลือกรายการ LAB`))
      labTypeObject.push({ labName: lab.labName, inputSupport: lab.inputSupport })
    }

    const objCreate = {
      labTypeName: labTypeName,
      status: 'ACTIVE',
      mapLab: labTypeObject
    }
    if (!labTypeName) return setErrorMessage({ ...errorMessage, ...{ LAB_TYPE_NAME: t('LAB_TYPE.MESSAGE.LAB_TYPE_NAME') } })

    LabTypeApi.update(labTypeId, objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t('LAB_TYPE.MESSAGE.SUCCESS.UPDATE'), '', null)
          history.push(routeName.labType)
        } else {
          const err = res.response.data
          notiError(t(`LAB_TYPE.MESSAGE.${err.message}`))
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`LAB_TYPE.MESSAGE.${err.message}`))
      })
  }
  const loadLabs = useCallback(async () => {
    const res = await LabsApi.findAllActive({})

    if (res.status === 200) {
      const lab: any = []
      for (const data of res.data) {
        lab.push({ labName: data.labName, labId: data.labId, inputSupport: data.inputSupport })
      }
      setLabsInit(lab)
      setLabs(lab)
    }
  }, [])

  const loadLabsEdit = useCallback(async (valueLabTypeId: number) => {
    const res = await LabTypeApi.findLabs(valueLabTypeId)
    const labsNew: any = []
    if (res.status === 200) {
      for (const data of res.data) {
        if (data.mapType !== null) {
          labsNew.push({ labName: data.labName, labId: data.labId, inputSupport: data.inputSupport, labTypeId: valueLabTypeId, labTypeName: '' })
        }
      }
    }
    setLabTypes(labsNew)
  }, [])

  const handleLabType = (labName: any, index: number) => {
    const labTypesTemp: any = [...labTypes]
    if (typeof labName === 'object') {
      labTypesTemp[index].labName = labName.labName
      labTypesTemp[index].labId = labName.labId
      labTypesTemp[index].inputSupport = labName.inputSupport
    } else {
      labTypesTemp[index].labName = labName
      labTypesTemp[index].labId = null
    }
    setLabTypes(labTypesTemp)
  }

  const handleSupportLabType = (checked: boolean, index: number) => {
    const labTypesTemp: any = [...labTypes]
    const check = checked === true ? 'SUPPORT' : 'NOT_SUPPORT'
    labTypesTemp[index].inputSupport = check
    setLabTypes(labTypesTemp)
  }
  const handleDeleteLabType = (index: number) => {
    const labTypesTemp = labTypes.filter((lab: any, iLab: number) => index !== iLab)
    setLabTypes(labTypesTemp)
    setLabs(labsInit)
  }

  const onEdit = (data: any) => {
    setLabTypeName(data.labTypeName)
    setLabTypeId(Number(data.labTypeId))
    loadLabsEdit(Number(data.labTypeId))
  }

  useEffect(() => {
    if (location.state) {
      onEdit(location.state)
    }
    loadLabs()
  }, [loadLabs, location])

  return (
    <div className={`${classes.root} ${classes.body} container-card h-100 overflow-hidden`}>
      <div className="d-flex flex-column h-100 overflow-auto">
        <HeaderLabel
          text={labTypeId ? t('LAB_TYPE.TITLE_UPDATE') : t('LAB_TYPE.TITLE_CREATE')}
          navigator={{ previousTo: routeName.labType, previousTitle: t('LAB_TYPE.TITLE'), currentTitle: labTypeId ? t('LAB_TYPE.TITLE_UPDATE') : t('LAB_TYPE.TITLE_CREATE') }}
          goBack={routeName.labType}
          onSubmit={labTypeId ? submitEdit : submit}
        />
        <Card className="border shadow-sm mx-4 mb-4 h-auto card">
          <Card.Body className="h-100 d-flex flex-column">
            <Row>
              <ColLabType sm={4}>
                <InputTextField
                  value={labTypeName}
                  inputProps={{ shrink: true }}
                  label={t('LAB_TYPE.INPUT.LAB_TYPE_NAME')}
                  onchange={(e) => {
                    setLabTypeName(e.target.value)
                    setErrorMessage(initStateErrorMessage)
                  }}
                  helperText={errorMessage.LAB_TYPE_NAME}
                  required={true}
                />
              </ColLabType>
            </Row>
            <Row className='h-100 overflow-hidden'>
              <Col className='h-100'>
                <div className='overflow-hidden h-100'>
                  <div className='d-flex flex-column overflow-hidden h-100'>
                    <TitleLab>{t('LAB_TYPE.TABLE.CELL.LIST_LAB')}</TitleLab>
                    <TableContainer component={Paper} className={`${classes.root} mt-1 custom-scroll overflow-auto`} style={{ boxShadow: 'none', maxHeight: '100%' }}>
                      <Table aria-label="simple table" sx={{ tableLayout: 'fixed', minWidth: '600px' }}>
                        <TableHead className={classes.header}>
                          <TableRow>
                            <TableCell key={'#'} align="center" className='position-sticky top-0' style={{ width: '5%', zIndex: 5 }}>
                              <HeaderTable>#</HeaderTable>
                            </TableCell>
                            <TableCell key={'list-lab'} align="center" className='position-sticky top-0' style={{ width: '50%', zIndex: 5 }}>
                              <HeaderTable>{t('LAB_TYPE.TABLE.CELL.LIST_LAB_NAME')}</HeaderTable>
                            </TableCell>
                            <TableCell key={'lab-remark'} align="center" className='position-sticky top-0' style={{ width: '25%', zIndex: 5 }}>
                              <HeaderTable>{t('LAB_TYPE.TABLE.CELL.FILL_ADDITIONAL_INFORMATION')}</HeaderTable>
                            </TableCell>
                            <TableCell key={'lab-action'} align="center" className='position-sticky top-0' style={{ width: '15%', zIndex: 5 }}>
                              <HeaderTable>{t('LAB_TYPE.TABLE.CELL.ACTION')}</HeaderTable>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={`sm-data-table ${classes.body}`}>
                          <TableRow>
                            <TableCell
                              className={`${classes.columnHover}`}
                              onClick={() => {
                                setLabTypes([{ labName: '', labId: 1, inputSupport: 'NOT_SUPPORT', labTypeId: 1, labTypeName: '' }, ...labTypes])
                              }}
                              colSpan={4}
                            >
                              <AddNewRowTable className='add-new-row'>+ {t('LAB_TYPE.TABLE.CELL.ADD_LAB_ITEM')}</AddNewRowTable>
                            </TableCell>
                          </TableRow>
                          {labTypes.map((lab: any, index: any) => {
                            return (
                              <TableRow key={`${index}`}>
                                <TableCell align='center'>{labTypes.length - index}</TableCell>
                                <TableCell>
                                  <AutocompleteSelect
                                    labelId="grouped-demo"
                                    freeSolo
                                    disableClearable
                                    options={labs}
                                    getOptionLabel={(option: any) => option.labName || ''}
                                    onchange={(e, value) => {
                                      handleLabType(value, index)
                                    }}
                                    inputValue={lab.labName}
                                    inputLabel={t('LAB_TYPE.TABLE.CELL.LIST_LAB')}
                                    onInputChange={(event, inputLabName) => {
                                      if (event != null) {
                                        handleLabType(inputLabName, index)
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <InputCheckbox
                                    label={''}
                                    checked={lab.inputSupport === 'SUPPORT' ? true : false}
                                    onChange={(e) => handleSupportLabType(e.target.checked, index)}

                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    className={'btn-delete ml-auto'}
                                    aria-label="delete"
                                    onClick={(e) => {
                                      handleDeleteLabType(index)
                                    }}
                                    sx={{ backgroundColor: 'transparent !important', padding: 0 }}
                                  >
                                    <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
