export interface IconProp {
    fill?: string
    width?: string
    height?: string
}
export default function IconRemed(props: IconProp) {
    return (
        <svg
            width={props.width || '18'}
            height={props.height || '18'}
            viewBox="0 0 21 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2605 -0.000416109C7.17052 -0.140416 3.00052 3.94958 3.00052 8.99958H1.21052C0.760519 8.99958 0.540519 9.53958 0.860519 9.84958L3.65052 12.6496C3.85052 12.8496 4.16052 12.8496 4.36052 12.6496L7.15052 9.84958C7.46052 9.53958 7.24052 8.99958 6.79052 8.99958H5.00052C5.00052 5.09958 8.18052 1.94958 12.1005 1.99958C15.8205 2.04958 18.9505 5.17958 19.0005 8.89958C19.0505 12.8096 15.9005 15.9996 12.0005 15.9996C10.3905 15.9996 8.90052 15.4496 7.72052 14.5196C7.32052 14.2096 6.76052 14.2396 6.40052 14.5996C5.98052 15.0196 6.01052 15.7296 6.48052 16.0896C8.00052 17.2896 9.91052 17.9996 12.0005 17.9996C17.0505 17.9996 21.1405 13.8296 21.0005 8.73958C20.8705 4.04958 16.9505 0.129584 12.2605 -0.000416109ZM11.7505 4.99958C11.3405 4.99958 11.0005 5.33958 11.0005 5.74958V9.42958C11.0005 9.77958 11.1905 10.1096 11.4905 10.2896L14.6105 12.1396C14.9705 12.3496 15.4305 12.2296 15.6405 11.8796C15.8505 11.5196 15.7305 11.0596 15.3805 10.8496L12.5005 9.13958V5.73958C12.5005 5.33958 12.1605 4.99958 11.7505 4.99958Z"
                fill={props.fill || "#1CB99A"}
            />
        </svg>
    )
}

