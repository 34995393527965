import { useEffect, useState } from 'react'
import { Box, Typography, Grid, IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { onProfileError, onImgError, imgs } from 'constants/images';
import { colors, templateImage } from 'constants/theme'
import { scale } from 'constants/scale'
import { getMember, memberTypeOutline, toBuddhistYear } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'
import { showModal, resetModal } from 'app/slice/modal.slice'

/** API */
import AppointmentApi, { SchedulerStatus } from 'api/counter/appointment.api'
import { fileUrl } from 'api/api'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextarea from 'component/Input/InputTextarea'

/** STYLE */
import { styled } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faGlobe, faUserCheck, faCheck, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLine, faInstagram } from "@fortawesome/free-brands-svg-icons"
import EastIcon from '@mui/icons-material/East';
import IconNotConnect from 'assets/icon/status/NotConnect'
import 'features/auth/modal-confirm-appointment.css'

const Navbar = styled(Box)(({ theme }) => ({
    height: scale.navbarHeight,
    padding: 0,
    margin: 0,
    top: 0,
    position: 'sticky',
    flex: 'none',
    background: `${colors.navbar} !important`,
    border: 'none',
    zIndex: theme.zIndex.drawer + 1,
    '.logo-clinic': {
        maxHeight: 70,
        maxWidth: 120,
        [theme.breakpoints.down('lg')]: {
            maxHeight: 50,
            maxWidth: 95,
        },
        [theme.breakpoints.down('md')]: {
            maxHeight: 40,
            maxWidth: 80,
        }
    },
    [theme.breakpoints.down('xl')]: {
        height: `calc(${scale.navbarHeight} - 1rem)`
    },
    [theme.breakpoints.down('lg')]: {
        height: `calc(${scale.navbarHeight} - 1.5rem)`
    },
    [theme.breakpoints.down('sm')]: {
        height: `calc(${scale.navbarHeight} - 2.5rem)`
    },
}))

const CardBox = styled(Box)(({ theme }) => ({
    borderRadius: 4,
    boxShadow: `0 3px 6px 0 ${colors.black10}`,
    border: `1px solid  ${colors.lightGray}`,
    backgroundColor: colors.white,
    '.MuiButton-root.btn-cancel, .MuiButton-root.btn-submit': {
        height: 44,
        fontSize: 16
    },
    '.MuiButton-root.btn-cancel': {
        borderColor: `${colors.themeThirdColor} !important`,
        color: `${colors.themeThirdColor} !important`,
    },
    '.MuiButton-root.btn-submit': {}
}))

const Avatar = styled(Box)<{ borderColor: string }>(({ theme, borderColor }) => ({
    flex: 'none',
    img: {
        width: 64,
        height: 64,
        borderRadius: 100,
        border: `3px solid ${borderColor}`,
        objectFit: 'cover'
    }
}))

const Typo = styled(Typography)<{ type: 'header' | 'title' | 'body' }>(({ theme, type }) => ({
    fontSize: type === 'header' ? 18 : 16,
    fontWeight: (type === 'header' && 600) || (type === 'title' && 500) || 400,
    width: type === 'title' ? 107 : 'auto',
    flex: type === 'body' ? 1 : 'none',
    color: colors.textPrimary
}))

const Footer = styled(Box)(({ theme }) => ({
    '.btn-social': {
        color: colors.textExtraLightGray,
        '&:hover': {
            color: colors.disabledGray,
        },
    }
}))

const SymbolIcon = styled(Box)(({ theme }) => ({
    width: 88,
    height: 88,
    borderRadius: 100,
    border: `4px solid ${colors.extraLightSky}`,
    color: colors.secondSky,
    fontSize: 60
}))

export enum APPOINTMENT_STATUS {
    NORMAL = `NORMAL`,
    CONFIRM = `CONFIRM`,
    PUT_OFF = `PUT_OFF`, //เลื่อนนัด
    CAN_NOT_CONTACT = `CAN_NOT_CONTACT`, //ติดต่อไม่ได้
    NOT_YET = `NOT_YET`, //ยังไม่มา
    ARRIVED = `ARRIVED`, //มาถึงแล้ว
    PENDING_PAYMENT = 'PENDING_PAYMENT', // รอชำระเงิน
    PAYMENT = 'PAYMENT', // รอชำระเงิน
    PENDING_SERVICE = 'PENDING_SERVICE', // เคาร์เตอร์ส่งตรวจ
    SERVICE = 'SERVICE', // กำลังตรวจ
    DONE = `DONE`, // เสร็จสิ้น
    CANCEL = `CANCEL` // ยกเลิก
}

export default function ConfirmAppointment() {
    const fasFacebook = faFacebook as IconProp;
    const fasLine = faLine as IconProp;
    const fasInstagram = faInstagram as IconProp;
    const memberAll = getMember()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [height] = useWindowSize()

    const queryParams = new URLSearchParams(window.location.search)
    const appointmentId = Number(queryParams.get("id"))
    const [appointment, setAppointment]: any = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState<'submit' | 'cancel'>('submit');

    const loadData = async (id: number) => {
        await AppointmentApi.loadScheduler(id).then((e) => {
            setAppointment(e.data)
        }).catch((e) => { return })
    }

    const handleSubmit = async () => {
        const payload: SchedulerStatus = {
            statusAppointment: 'CONFIRM',
            remarkCancel: ''
        }
        if (appointmentId) {
            await AppointmentApi.updateStatusScheduler(appointmentId, payload).then((e) => {
                loadData(appointmentId)
                setShowPopup(false)
                dispatch(resetModal())
            }).catch((e) => { return })
        }
    }

    const handleCancel = async (remark: string) => {
        const payload: SchedulerStatus = {
            statusAppointment: 'CANCEL',
            remarkCancel: remark
        }
        if (appointmentId) {
            await AppointmentApi.updateStatusScheduler(appointmentId, payload).then((e) => {
                loadData(appointmentId)
                setShowPopup(false)
                dispatch(resetModal())
            }).catch((e) => { return })
        }
    }

    const socialContact = (type: 'line' | 'facebook' | 'instagram' | 'site' | 'phone', chanel: string) => {
        if (type === 'line') return window.open(`https://line.me/ti/p/~${chanel}`, '_blank');
        else return window.open(`https://${chanel}`, '_blank');
    }

    const renderImage = (img: any) => {
        if (img) return `${fileUrl}/${img}`
        else return imgs.defaultAvatar
    }

    const renderClinicDate = (time: string) => {
        if (time) {
            const items = time ? time.replaceAll(' <br />', ' ').replaceAll('น. ', 'น./').split('/').filter(element => element) : '-'
            let newItems: any = []
            const newFormat: any = []
            _.map(items, (i, index) => {
                if (i) {
                    const day = moment(i.split('-')[0], 'dd HH:mm').format('dd')
                    const clinicTime = `${moment(i.split('-')[0], 'dd HH:mm').format('HH:mm')}-${moment(i.split('-')[1], 'HH:mm').format('HH:mm')}`
                    newItems.push({ day: day, time: clinicTime })
                }
            })
            newItems = _.chain(newItems)
                .groupBy('time')
                .map((value: any, key: any) => ({
                    key, data: _.map(value, (i) => { return i.day })
                })).value()
            if (!_.isEmpty(newItems)) {
                _.map(newItems, (j, index) => {
                    newFormat.push(`${j.data.toString().replaceAll(',', '-')} ${j.key} ${t('TIME_UNIT')}`)
                })
            }
            return !_.isEmpty(newFormat) ? newFormat.toString().replaceAll(',', ', ') : '-'
        } else return '-'
    }

    const renderIconStatus = (status: string) => {
        if (status === APPOINTMENT_STATUS.ARRIVED) return <FontAwesomeIcon icon={faUserCheck} style={{ fontSize: 24, color: colors.appointmentStatus.statusArrived }} />
        else if (status === APPOINTMENT_STATUS.CAN_NOT_CONTACT) return <Box sx={{ svg: { width: 26, height: 26 } }}><IconNotConnect color={colors.appointmentStatus.statusUnConnect} /></Box>
        else if (status === APPOINTMENT_STATUS.CONFIRM) return <FontAwesomeIcon icon={faCheck} style={{ fontSize: 28, color: colors.appointmentStatus.statusEnter }} />
        else if (status === APPOINTMENT_STATUS.DONE) return <FontAwesomeIcon icon={faCheck} style={{ fontSize: 28, color: colors.appointmentStatus.statusEnter }} />
        else if (status === APPOINTMENT_STATUS.NOT_YET) return <FontAwesomeIcon icon={faUserTimes} style={{ fontSize: 28, color: colors.appointmentStatus.statusUnArrived }} />
        else if (status === APPOINTMENT_STATUS.PUT_OFF) return <EastIcon sx={{ fontSize: 28, color: colors.appointmentStatus.statusChange }} />
        else if (status === APPOINTMENT_STATUS.PENDING_PAYMENT) return <EastIcon sx={{ fontSize: 28, color: colors.appointmentStatus.statusPayment }} />
        else if (status === APPOINTMENT_STATUS.PAYMENT) return <EastIcon sx={{ fontSize: 28, color: colors.appointmentStatus.statusPayment }} />
        else if (status === APPOINTMENT_STATUS.PENDING_SERVICE) return <EastIcon sx={{ fontSize: 28, color: colors.appointmentStatus.statusCounterCheckIn }} />
        else if (status === APPOINTMENT_STATUS.SERVICE) return <EastIcon sx={{ fontSize: 28, color: colors.appointmentStatus.statusService }} />
        else return <FontAwesomeIcon icon={faTimes} style={{ fontSize: 28, color: colors.appointmentStatus.statusCancel }} />
    }

    useEffect(() => {
        if (Number(appointmentId)) loadData(Number(appointmentId))
    }, [appointmentId]);

    return (
        <Box className='d-flex flex-column h-100'>
            <Navbar className='d-flex align-items-center px-3 px-md-4 m-0'>
                <img src={appointment?.branchImage || templateImage.logoHorizontal} alt="branchImage" className={'logo-clinic'} onError={onImgError} />
            </Navbar>
            {!_.isEmpty(appointment) && (
                <Box className={`overflow-hidden h-100 d-flex justify-content-center ${height < 768 ? 'align-items-start' : 'align-items-center'}`}>
                    <Box className='p-3 custom-scroll overflow-auto d-flex flex-column' sx={{ maxHeight: '100%' }}>
                        <CardBox className='p-3 p-md-4 w-100' sx={{ maxWidth: 702 }}>
                            <Typo type='header' className='text-center mt-md-4' sx={{ fontSize: 20 }}>{t('CONFIRM_APPOINTMENT.TITLE')}</Typo>
                            <Box className='mt-3 m-md-4' sx={{ borderBottom: `1px solid ${colors.lightGray}` }}>
                                <Box className='d-flex flex-column flex-md-row'>
                                    <Avatar className='text-center mr-md-2' borderColor={_.find(memberAll, { rankId: appointment.rankId })?.color}>
                                        <img src={renderImage(appointment?.patientProfile)} alt="patientProfile" onError={onProfileError} />
                                    </Avatar>
                                    <Box className='w-100'>
                                        <Grid container spacing={1} className="mt-0 ml-md-0">
                                            <Grid item className='w-100 pt-md-0 pb-0'>
                                                <Typo type='header'>{t('CONFIRM_APPOINTMENT.FORM.PREFIX')} {appointment?.patientFullname || '-'}</Typo>
                                            </Grid>
                                            <Grid item className='w-100 pb-0'>
                                                <Typo type='title' sx={{ width: 'auto' }}>{t('CONFIRM_APPOINTMENT.FORM.DETAIL')}</Typo>
                                            </Grid>
                                            <Grid item md={12} className='w-100 pb-0 d-flex'>
                                                <Typo type='title'>{t('CONFIRM_APPOINTMENT.FORM.BRANCH')}</Typo>
                                                <Typo type='body'>{appointment?.branchName || appointment?.branchNameEn || '-'}</Typo>
                                            </Grid>
                                            <Grid item md={12} className='w-100 pb-0 d-flex'>
                                                <Typo type='title'>{t('CONFIRM_APPOINTMENT.FORM.DOCTOR')}</Typo>
                                                <Typo type='body'>{appointment?.doctorFullname || '-'}</Typo>
                                            </Grid>
                                            <Grid item md={6} className='w-100 pb-0 d-flex'>
                                                <Typo type='title'>{t('CONFIRM_APPOINTMENT.FORM.DATE')}</Typo>
                                                <Typo type='body'>{appointment?.date ? toBuddhistYear(moment(appointment?.date), `D MMMM YYYY`) : '-'}</Typo>
                                            </Grid>
                                            <Grid item md={6} className='w-100 pb-0 d-flex'>
                                                <Typo type='title'>{t('CONFIRM_APPOINTMENT.FORM.TIME')}</Typo>
                                                <Typo type='body'>{appointment?.timeStart ? `${toBuddhistYear(moment(appointment?.timeStart, 'HH:mm'), `HH.mm`)} ${t('TIME_UNIT')}` : '-'}</Typo>
                                            </Grid>
                                            <Grid item className='w-100 pb-0 d-flex'>
                                                <Typo type='title'>{t('CONFIRM_APPOINTMENT.FORM.TREATMENT')}</Typo>
                                                <Typo type='body'>{appointment?.operative || '-'}</Typo>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Typo type='body' className='my-3 mb-md-4'>{t('CONFIRM_APPOINTMENT.FORM.PARAGRAPH')}</Typo>
                            </Box>
                            {appointment?.statusAppointment && appointment?.statusAppointment.toUpperCase() !== APPOINTMENT_STATUS.NORMAL && (
                                <>
                                    <Box className='d-flex align-items-center justify-content-center'>
                                        {renderIconStatus(appointment?.statusAppointment)}
                                        <Typo type='header' className='ml-3' sx={{ fontWeight: 500 }}>{t(`CONFIRM_APPOINTMENT.APPOINTMENT_STATUS.${appointment?.statusAppointment}`)}</Typo>
                                    </Box>
                                    {appointment?.statusAppointment.toUpperCase() === APPOINTMENT_STATUS.CANCEL && (
                                        <Typo type='body' className='text-center mt-2' sx={{ color: colors.themeThirdColor, fontSize: '15px' }}>
                                            {t('CONFIRM_APPOINTMENT.FORM.CONFIRM_UNSUCCESS_REMARK')}
                                        </Typo>
                                    ) || <></>}
                                    {(appointment?.statusAppointment.toUpperCase() === APPOINTMENT_STATUS.CANCEL || appointment?.statusAppointment.toUpperCase() === APPOINTMENT_STATUS.CONFIRM) && (
                                        <Typo type='body' className={`text-center ${appointment?.statusAppointment.toUpperCase() === APPOINTMENT_STATUS.CANCEL ? 'mt-3' : 'mt-2'}`}>
                                            {t('CONFIRM_APPOINTMENT.FORM.CONFIRM_SUCCESS_SUB')}
                                        </Typo>
                                    ) || <></>}
                                </>
                            ) || (
                                    <Box className='pt-4 py-md-3 px-md-4'>
                                        <Grid container spacing={1}>
                                            <Grid item sm={6} className='w-100 py-0 pr-0'>
                                                <ButtonCustom
                                                    onClick={() => {
                                                        setPopupType('cancel')
                                                        setShowPopup(true)
                                                        dispatch(showModal())
                                                    }}
                                                    variant={'outlined'}
                                                    textButton={t('CONFIRM_APPOINTMENT.BUTTON.CANCEL')}
                                                    className={`btn-cancel w-100`} />
                                            </Grid>
                                            <Grid item sm={6} className='w-100 py-0 pr-0 mt-2 mt-md-0'>
                                                <ButtonCustom
                                                    disabled={!appointment?.date || moment().subtract(1, 'days') > moment(appointment?.date)}
                                                    onClick={() => {
                                                        setPopupType('submit')
                                                        setShowPopup(true)
                                                        dispatch(showModal())
                                                    }}
                                                    textButton={t('CONFIRM_APPOINTMENT.BUTTON.CONFIRM')}
                                                    className={`btn-submit w-100`} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                        </CardBox>
                        <Footer className='pt-3 pt-md-4 pb-2 w-100 text-center' sx={{ maxWidth: 702 }}>
                            <Box className='d-flex align-items-center justify-content-center mb-2'>
                                {appointment?.branchLine && (
                                    <IconButton onClick={() => socialContact('line', appointment?.branchLine)} className="btn-social mx-1 mx-md-2" aria-label="line">
                                        <FontAwesomeIcon icon={fasLine} />
                                    </IconButton>
                                ) || <></>}
                                {appointment?.branchFacebook && (
                                    <IconButton onClick={() => socialContact('facebook', appointment?.branchFacebook)} className="btn-social mx-1 mx-md-2" aria-label="facebook">
                                        <FontAwesomeIcon icon={fasFacebook} />
                                    </IconButton>
                                ) || <></>}
                                {appointment?.branchInstagram && (
                                    <IconButton onClick={() => socialContact('instagram', appointment?.branchInstagram)} className="btn-social mx-1 mx-md-2" aria-label="instagram">
                                        <FontAwesomeIcon icon={fasInstagram} />
                                    </IconButton>
                                ) || <></>}
                                {appointment?.branchWebsite && (
                                    <IconButton onClick={() => socialContact('site', appointment?.branchWebsite)} className="btn-social mx-1 mx-md-2" aria-label="site">
                                        <FontAwesomeIcon icon={faGlobe} style={{ fontSize: 21 }} />
                                    </IconButton>
                                ) || <></>}
                            </Box>
                            <Typo type='body' sx={{ fontSize: '14px', color: colors.darkGray }}>
                                {appointment?.clinicAndBranchName} | {appointment?.branchFullAddress}
                            </Typo>
                            <Typo type='body' sx={{ fontSize: '14px', color: colors.darkGray }}>
                                {t('CONFIRM_APPOINTMENT.FORM.OPEN')} {renderClinicDate(appointment?.textTime)}
                            </Typo>
                            {appointment?.branchPhone && (<Typo type='body' sx={{ fontSize: '14px', color: colors.darkGray }}>{appointment?.branchPhone}</Typo>) || <></>}
                        </Footer>
                    </Box>
                </Box>
            ) || (
                    <Box className='d-flex align-items-center justify-content-center h-100 w-100'>
                        <Typography className='text-center'>{t('REGISTER.NO_ITEM')}</Typography>
                    </Box>
                )}
            {showPopup && (
                <ModalAppointment
                    type={popupType}
                    onSubmit={(remark: string) => {
                        if (popupType === 'submit') handleSubmit()
                        else handleCancel(remark)
                    }}
                    onReset={() => {
                        setShowPopup(false)
                        dispatch(resetModal())
                    }}
                />
            )}
        </Box>
    )
}

type ModalAppointmentProps = {
    type: 'submit' | 'cancel'
    onSubmit?: (remark: string) => void
    onReset?: () => void
}

function ModalAppointment(props: ModalAppointmentProps) {
    const { t } = useTranslation()

    const [remark, setRemark] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = () => {
        if (props.type === 'cancel') {
            if (!remark) return setErrorMessage(t('CONFIRM_APPOINTMENT.MODAL.PLS_FIELD'))
            else props.onSubmit && props.onSubmit(remark)
        } else {
            props.onSubmit && props.onSubmit('')
        }
    }

    return (
        <ModalCustom
            size={'lg'}
            title={t('')}
            alignFooter={'center'}
            footerInline
            textBtnCancel={t('BUTTON.CANCEL')}
            textBtnConfirm={props.type === 'submit' ? t('CONFIRM_APPOINTMENT.BUTTON.CONFIRM') : t('CONFIRM_APPOINTMENT.BUTTON.CANCEL')}
            onReset={props.onReset}
            onSubmit={handleSubmit}
            btnCancelTheme={'default'}
            btnConfirmTheme={props.type === 'submit' ? 'default' : 'danger'}
            modalStyle={`modal-confirm-appointment modal-confirm-appointment-${props.type}`}
            component={
                <>
                    <SymbolIcon className='d-flex align-items-center justify-content-center mx-auto'>?</SymbolIcon>
                    {props.type === 'submit' && (
                        <Typo type='title' className='w-100 text-center pt-4 pb-2' sx={{ fontSize: 20 }}>{t('CONFIRM_APPOINTMENT.MODAL.CONFIRM_TITLE')}</Typo>
                    ) || (
                            <>
                                <Typo type='title' className='w-100 text-center pt-4' sx={{ fontSize: 20 }}>{t('CONFIRM_APPOINTMENT.MODAL.CANCEL_TITLE')}</Typo>
                                <Typo type='body' className='w-100 text-center' sx={{ color: colors.darkGray }}>{t('CONFIRM_APPOINTMENT.MODAL.CANCEL_SUBTITLE')}</Typo>
                                <Box className='mt-3 mb-2'>
                                    <InputTextarea
                                        label={t('CONFIRM_APPOINTMENT.MODAL.PLACEHOLDER_CANCEL')}
                                        onchange={(e: any) => {
                                            setRemark(e.target.value)
                                            setErrorMessage('')
                                        }}
                                        helperText={errorMessage || ''}
                                    />
                                </Box>
                            </>
                        )}
                </>
            }
        />
    )
}