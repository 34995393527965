import Swal from 'sweetalert2'
import { colors } from 'constants/theme'

export function swalSuccess(title?: string, option?: string, url?: any, callback?: any) {
  Swal.fire({
    icon: 'success',
    title: title,
    confirmButtonColor: colors.themeSecondColor
  }).then(() => {
    if (option === 'reset') {
      return
    } else if (option === 'reload') {
      window.location.reload()
    } else if (option === 'go') {
      window.location.href = url
    } else if (callback != null) callback(true)
  })
}
export function swalError(title?: string, confirmButtonText?: string) {
  Swal.fire({
    icon: 'error',
    title: title,
    confirmButtonText: confirmButtonText || 'ตกลง',
    confirmButtonColor: colors.statusDangerColor
  })
}
type OptionWarning = {
  confirmButtonTheme?: 'default' | 'danger'
  allowOutsideClick?: boolean
  confirmButtonText?: string
  showCancelButton?: boolean
  cancelButtonText?: string
}
export function swalWarning(title?: string, text?: any, callback?: any, option?: OptionWarning) {
  Swal.fire({
    icon: 'warning',
    title: title,
    html: text,
    reverseButtons: true,
    showCancelButton: option?.showCancelButton ? true : false,
    cancelButtonText: option?.cancelButtonText || 'ยกเลิก',
    confirmButtonText: option?.confirmButtonText || 'ตกลง',
    confirmButtonColor: option?.confirmButtonTheme === 'danger' ? colors.statusDangerColor : colors.themeSecondColor,
    allowOutsideClick: option?.allowOutsideClick || false
  }).then((result) => {
    if (result.isConfirmed && callback) callback()
  })
}

export function swalActive(title?: string, text?: any, callback?: any, icon?: null | 'info' | 'warning' | 'question', confirmButtonText?: string, cancelButtonText?: string) {
  Swal.fire({
    title: title,
    html: text,
    icon: icon || 'warning',
    showCloseButton: true,
    showCancelButton: true,
    reverseButtons: true,
    focusConfirm: false,
    confirmButtonText: confirmButtonText || 'ยืนยัน',
    confirmButtonColor: colors.themeSecondColor,
    cancelButtonText: cancelButtonText || 'ยกเลิก',
    customClass: {
      container: 'swal2-warning'
    }
  }).then((confirm) => {
    if (confirm.value) {
      return callback(true)
    }
    return callback(false)
  })
}

type OptionDelete = {
  confirmButtonText?: string
  cancelButtonText?: string
  width?: any
}

export function swalDelete(title: string, text?: any, callback?: any, option?: OptionDelete) {
  Swal.fire({
    title: title,
    html: text,
    icon: 'warning',
    width: option?.width || 'auto',
    showCloseButton: true,
    showCancelButton: true,
    reverseButtons: true,
    focusConfirm: false,
    confirmButtonText: option?.confirmButtonText || 'ยืนยัน',
    confirmButtonColor: colors.themeThirdColor,
    cancelButtonText: option?.cancelButtonText || 'ยกเลิก',
    customClass: {
      container: 'swal2-warning'
    }
  }).then((confirm) => {
    if (confirm.value) {
      return callback(true)
    }
    return callback(false)
  })
}

type OptionButton = {
  confirmButton?: {
    showConfirmButton?: boolean
    confirmButtonText?: string
    confirmClassName?: string
  }
  cancelButton?: {
    showCancelButton?: boolean
    cancelButtonText?: string
    cancelClassName?: string
  }
  DenyButton?: {
    showDenyButton?: boolean
    denyButtonText?: string
    denyButtonClassName?: string
  }
  reverseButtons?: boolean
  showCloseButton?: boolean
  containerClass?: string
}

export function swalCustom(title?: string, text?: any, callback?: any, icon?: null | 'info' | 'warning' | 'question', optionButton?: OptionButton) {
  Swal.fire({
    title: title,
    html: text,
    icon: icon || 'warning',
    showConfirmButton: optionButton?.confirmButton?.showConfirmButton === false ? false : true,
    showCloseButton: optionButton?.showCloseButton === false ? false : true,
    showCancelButton: optionButton?.cancelButton?.showCancelButton === false ? false : true,
    confirmButtonText: optionButton?.confirmButton?.confirmButtonText || 'ยืนยัน',
    confirmButtonColor: colors.themeSecondColor,
    cancelButtonText: optionButton?.cancelButton?.cancelButtonText || 'ยกเลิก',
    reverseButtons: optionButton?.reverseButtons || true,
    focusConfirm: false,
    showDenyButton: optionButton?.DenyButton?.showDenyButton === true ? true : false,
    denyButtonText: optionButton?.DenyButton?.denyButtonText || "",
    customClass: {
      container: optionButton?.containerClass || 'swal2-warning',
      cancelButton: optionButton?.cancelButton?.cancelClassName || "",
      denyButton: optionButton?.DenyButton?.denyButtonClassName || "",
      confirmButton: optionButton?.confirmButton?.confirmClassName || "",
    },
  }).then((confirm) => {
    if (confirm.value) {
      return callback(true)
    } else if (confirm.isDenied) {
      return callback(confirm)
    }
    return callback(false)
  })
}
