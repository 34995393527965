import { useState, useEffect } from 'react'
import { Typography, Divider, Button, Box, Tooltip, Avatar } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { QRCodeSVG } from 'qrcode.react'
import { InputGroup } from 'react-bootstrap'
import _ from 'lodash'
import { useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { lineConnectConfig, fileUrl } from 'api/api'
import RegisterApi from 'api/counter/register.api'

/** UTILS */
import { getMember, memberTypeOutline } from 'utils/app.utils'
import { selectMe } from 'app/slice/user.slice'
import { imgs } from 'constants/images';
import ButtonCustom from 'component/Button/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { KEY_STORAGE } from 'constants/common'
import { colors, templateImage } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'

export const ContainerForm = styled('div')(({ theme }) => ({
  padding: '1rem',
  color: colors.textPrimary
}))

export const Header = styled(`div`)(({ theme }) => ({
  fontSize: 14
}))

export const CardForm = styled(Box)<{ type?: 'cennect' | 'unConnect' }>(({ theme, type }) => ({
  borderRadius: 4,
  boxShadow: `0 3px 6px 0 ${colors.black10}`,
  border: `solid 1px ${colors.lightGray}`,
  backgroundColor: colors.white,
  maxWidth: type === 'unConnect' ? 'unset' : 688,
  '.btn-unConnect': {
    borderColor: `${colors.themeThirdColor} !important`,
    color: `${colors.themeThirdColor} !important`
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: type === 'unConnect' ? `70px !important` : 0,
    paddingBottom: type === 'unConnect' ? `70px !important` : 0
  }
}))

export const FragmentWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  },
  [theme.breakpoints.up('lg')]: {
    '&.fragment-right': {
      minWidth: 350
    }
  }
}))

export const FragmentLeft = styled(Box)(({ theme }) => ({
  '.group-copy-link': {
    button: {
      borderRadius: '0 4px 4px 0'
    }
  },
  '.btn-copy': {
    borderRadius: '0 4px 4px 0 !important',
    borderColor: `${colors.themeSecondColor} !important`,
    color: colors.themeSecondColor
  },
  '.btn-connect, .btn-copy': {
    height: 43
  }
}))

export const FragmentRight = styled(Box)(({ theme }) => ({
  svg: {
    width: '100%',
    height: '100%',
    maxWidth: 350
  },
  [theme.breakpoints.down('lg')]: {
    textAlign: 'center',
    svg: {
      maxWidth: 200
    }
  },
  [theme.breakpoints.down('sm')]: {
    svg: {
      maxWidth: 150
    }
  }
}))

export default function LineConnect(props: any) {
  const location = useLocation()
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const [patient, setPatient] = useState<any>({})
  const [link, setLink] = useState('Lorem Ipsum is simply dummy text')
  const [copied, setCopied] = useState(false)
  const [loading, setLoading] = useState(true);
  const memberAll = getMember()

  const loadPatient = (id: number) => {
    RegisterApi.findPatientById(id).then((resPatient) => {
      if (resPatient.status === 200 && !_.isEmpty(resPatient.data)) setPatient(resPatient.data)
      else setPatient([])
    }).finally(() => setLoading(false))
  }

  const lineConnect = () => {
    const url = 'https://notify-bot.line.me/oauth/authorize'
    const params = {
      responseType: 'code',
      clientId: lineConnectConfig.LINE_NOTI_CLIENT_ID,
      redirectUri: encodeURIComponent(`${lineConnectConfig.LINE_NOTI_CALLBACK_URL}`) || encodeURIComponent('https://cliniter.knacx.dev/register/line-connect/callback'),
      // redirectUri: encodeURIComponent('http://localhost:3000/register/line-connect/callback'),
      scope: 'notify',
      state: patient.patientId
    }
    console.log(params, 'line-connect params')
    setLink(`${url}?response_type=${params.responseType}&client_id=${params.clientId}&redirect_uri=${params.redirectUri}&scope=${params.scope}&state=${params.state}`)
  }

  const handleCopied = () => {
    navigator.clipboard.writeText(link)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const disconnectLineNotify = async () => {
    const disconnect = await RegisterApi.disconnectLineConnect(patient.patientId)
    if (disconnect.status === 200) loadPatient(patient.patientId)
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  useEffect(() => {
    if (localStorage.getItem(KEY_STORAGE.PATIENT)) {
      let state: any = localStorage.getItem(KEY_STORAGE.PATIENT)
      state = decodeURI(state)
      if (state) {
        state = JSON.parse(state)
        loadPatient(state.id)
      }
    } else {
      window.location.reload()
    }
  }, [location])

  useEffect(() => {
    if (_.isEmpty(patient.lineToken)) lineConnect()
  }, [patient])

  return (
    <ContainerForm className={`${loading ? 'opacity-0' : ''}`}>
      <Typography className={'mb-4'} sx={{ fontSize: 24, fontWeight: 500 }}>
        {t('REGISTER.LINE_CONNECT.TITLE')}
      </Typography>

      {(_.isEmpty(patient.lineToken) && (
        <CardForm type="unConnect" className={'p-3 p-md-4'}>
          <Box className="d-flex flex-column flex-xl-row align-item-center justify-content-center">
            <FragmentWrap className="fragment-left order-1 order-xl-0">
              <FragmentLeft className={'pr-xl-3 mx-auto ml-xl-auto mr-xl-0 pb-3 pb-xl-0'} sx={{ maxWidth: 592 }}>
                <Box className={'text-center mb-3 d-none d-xl-block'}>
                  <img
                    src={templateImage.logoHorizontal}
                    alt="Logo"
                    style={{
                      width: 188
                    }}
                  />
                </Box>
                <Box className={'pt-2'}>
                  <Typography sx={{ fontSize: 24, fontWeight: 500 }}>{t('REGISTER.LINE_CONNECT.CLINITER_LINE_CONNECT')}</Typography>
                  <Typography sx={{ fontSize: 18 }}>{t('REGISTER.LINE_CONNECT.SCAN_QR_CONNECT')}</Typography>
                </Box>
                <Divider
                  className={'my-4 mx-0'}
                  sx={{
                    '&:before, &:after': {
                      borderColor: colors.lightGray
                    }
                  }}
                >
                  <Typography sx={{ fontSize: 14, color: colors.black60 }}>{t('REGISTER.LINE_CONNECT.OR')}</Typography>
                </Divider>

                <InputGroup className={'group-copy-link w-100 d-flex flex-nowrap'}>
                  <Box className={'d-flex align-items-center overflow-hidden w-100'} style={{ userSelect: 'all', border: `1px solid ${colors.black12}`, borderRight: 0, borderRadius: '4px 0 0 4px' }}>
                    <Typography className={'text-ellipsis px-3'} sx={{ fontSize: 16, color: colors.black60 }}>
                      {link}
                    </Typography>
                  </Box>
                  <Tooltip
                    arrow
                    placement="top"
                    PopperProps={{
                      disablePortal: true
                    }}
                    open={copied}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={`${t('REGISTER.LINE_CONNECT.COPIED')}`}
                  >
                    <Button disabled={!link} variant="outlined" onClick={handleCopied} className={'text-nowrap btn-copy px-2'} style={{ margin: 0, fontSize: 16 }}>
                      {t('REGISTER.LINE_CONNECT.COPY')}
                    </Button>
                  </Tooltip>
                </InputGroup>
                <ButtonCustom
                  disabled={!link}
                  variant="outlined"
                  onClick={() => {
                    window.open(link, '_self')
                  }}
                  textButton={t('REGISTER.LINE_CONNECT.BUTTON_CONNECT_LINE_NOTI')}
                  className={'w-100 mt-3 btn-connect'}
                  style={{ margin: 0, fontSize: 16 }}
                />
              </FragmentLeft>
            </FragmentWrap>
            <FragmentWrap className="fragment-right ml-xl-3 order-0 order-xl-1 mb-4 mb-xl-0">
              <FragmentRight>
                <Box className={'pt-4 pt-xl-0'}>{link && <QRCodeSVG value={link} />}</Box>
              </FragmentRight>
            </FragmentWrap>
          </Box>
        </CardForm>
      )) || (
          <CardForm className={'p-3 p-md-4 p-xl-5 mx-auto'}>
            <Box>
              <Box className={'text-center mb-3'}>
                <img
                  src={templateImage.logoHorizontal}
                  alt="Logo"
                  style={{
                    width: 208
                  }}
                />
              </Box>
              <Typography className={'text-center mb-4'} sx={{ fontSize: 24, fontWeight: 500 }}>
                {t('REGISTER.LINE_CONNECT.CLINITER_LINE_CONNECT')}
              </Typography>
              <Avatar src={renderImage(patient.profile)} alt={'profile'} className={'mx-auto mb-3'} sx={{ width: 110, height: 110, border: `5px solid ${_.find(memberAll, { rankId: patient.rankId })?.color}`, backgroundColor: colors.white }}>
                <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
              </Avatar>
              <Box className={'d-flex align-sm-items-center pt-1'}>
                <Typography className={'mb-3'} sx={{ width: '50%', fontSize: 16, fontWeight: 500 }}>
                  <span className={'d-block w-100 ml-sm-auto'} style={{ maxWidth: 140 }}>{t('REGISTER.LINE_CONNECT.FULL_NAME')}</span>
                </Typography>
                <Typography className={'ml-2 mb-3'} sx={{ width: '50%', fontSize: 16 }}>
                  {patient.firstname} {patient.lastname}
                </Typography>
              </Box>
              <Box className={'d-flex align-sm-items-center'}>
                <Typography className={'mb-3'} sx={{ width: '50%', fontSize: 16, fontWeight: 500 }}>
                  <span className={'d-block w-100 ml-sm-auto'} style={{ maxWidth: 140 }}>
                    {user?.roles?.roleBranch?.branches?.branchCode || 'CN'}
                  </span>
                </Typography>
                <Typography className={'ml-2 mb-3'} sx={{ width: '50%', fontSize: 16 }}>
                  {patient.branchCnNumber || '-'}
                </Typography>
              </Box>
              <Box className={'d-flex align-sm-items-center'}>
                <Typography className={'mb-4'} sx={{ width: '50%', fontSize: 16, fontWeight: 500 }}>
                  <span className={'d-block w-100 ml-sm-auto'} style={{ maxWidth: 140 }}>
                    {t('REGISTER.LINE_CONNECT.STATUS_CONNECT')}
                  </span>
                </Typography>
                <Typography className={'ml-2 mb-4'} sx={{ width: '50%', fontSize: 16 }}>
                  <FontAwesomeIcon icon={faCheckCircle} className={'mr-1'} style={{ color: colors.statusSuccess }} /> {t('REGISTER.LINE_CONNECT.CONNECT')}
                </Typography>
              </Box>
              <Box className="pt-4" sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                <ButtonCustom disabled={!link} variant="outlined" onClick={disconnectLineNotify} textButton={t('REGISTER.LINE_CONNECT.BUTTON_UNCONNECT_LINE_NOTI')} className={'w-100 m-0 btn-unConnect'} style={{ margin: 0, fontSize: 16 }} />
              </Box>
            </Box>
          </CardForm>
        )}
    </ContainerForm>
  )
}
