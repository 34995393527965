import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row, Dropdown, Image } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/xray/style/style'

/** API */
import xrayApi from 'api/xray.api'
import XrayFilmTypeApi from 'api/setting/treatments/xray-film-type.api'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { dateTimeToView } from 'utils/date.utils'

/** COMPONENT */
import { swalDelete } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import _ from 'lodash'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import { routeName } from 'routes/routes-name'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { icons, Icons } from 'constants/images'
import print from 'assets/icon/carlendar/print.svg'

import { useDispatch, useSelector } from 'react-redux'
import * as modalSlice from 'app/slice/modal.slice'
import ListXray from 'component/Xray/ListXray'

const DropdownMenu = styled(Dropdown.Menu)(({ theme }) => ({}))

const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
  color: colors.textPrimary,
  '&:hover': {
    backgroundColor: colors.themeSecondColor06,
    color: colors.themeSecondColor
  }
}))

const ImageLabel = styled(Image)(({ theme }) => ({
  verticalAlign: 'top',
  marginTop: '5px',
  marginRight: '4px'
}))

// const permissions = {
//     isCreate: isCreate(),
//     isDelete: isDelete(),
//     isUpdate: isUpdate(),
//     isView: isView()
// }

type Xray = {
  opd?: boolean | false
  patient?: any
  patientId: string
  vnNumber?: string | null
  onSaveTreatment?: () => void
  isClose?: boolean
}

export default function Xray(props: Xray) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [xray, setXray] = useState([])
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const permissions = { isCreate: isCreate(), isDelete: isDelete(), isUpdate: isUpdate(), isView: isView() }

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createdAt')
  const [sortType, setSortType] = useState('DESC')
  const [search, setSearch] = useState('')
  const [xrayFilmTypeId, setXrayFilmTypeId] = useState<any>(0)
  const [xrayFilmTypes, setXrayFilmTypes] = useState<any[]>([])
  const [rangeDate, setRangeDate] = useState<string[]>(emptyRangePicker)

  const loadData = useCallback(async () => {
    let condition: any = {}
    let res
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (xrayFilmTypeId) condition = { ...condition, xrayFilmTypeId: xrayFilmTypeId === 0 ? '' : xrayFilmTypeId }
    if (rangeDate) {
      if ((rangeDate[0] !== null || rangeDate[0] !== '') && (rangeDate[1] !== null || rangeDate[1] !== '')) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    if (props.opd) res = await xrayApi.findXrayPatient(props.patientId, condition)
    else res = await xrayApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setXray(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, xrayFilmTypeId, rangeDate, props.patient])

  const loadFilter = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      sortBy: 'createAt',
      sortType: 'ASC',
      status: 'ACTIVE'
    }

    const res = await XrayFilmTypeApi.findAll(condition)
    if (res.status === 200) setXrayFilmTypes(res.data)
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    loadFilter()
  }, [loadFilter])

  const onDelete = (data: any) => {
    const { patientGalleryId, xrayFilmTypeName, createdAt } = data

    swalDelete(
      `${t('XRAY.MESSAGE.DEL_TITLE')}`,
      `${t('XRAY.MESSAGE.DEL_SUB')}${xrayFilmTypeName} ${dateTimeToView(createdAt)}${t('XRAY.MESSAGE.YESNO')}`,
      (res: any) => {
        if (res) {
          xrayApi
            .deleteXrayPatient(String(patientGalleryId))
            .then((resp) => {
              notiSuccess(t('XRAY.MESSAGE.SUCCESS.DEL'))
              setPage(1)
              setPageLimit(10)
              loadData()
            })
            .catch((e) => {
              notiError(t('XRAY.MESSAGE.ERROR.DEL'))
            })
        }
      },
      { confirmButtonText: t('XRAY.BUTTON.DEL_LIST'), cancelButtonText: t('BUTTON.CANCEL') }
    )
  }

  const onEdit = (data: any) => {
    const state: any = location?.state
    const val = {
      data,
      routeName: window.location.pathname,
      text: t('XRAY.PATH.EDIT'),
      isEdit: true,
      patientId: state?.patientId,
      servicePointId: state?.servicePointId
    }
    if (props?.onSaveTreatment) {
      props.onSaveTreatment()
    }
    history.push(routeName.xray + '/history', val)
  }

  const onCreate = () => {
    const state: any = location?.state
    const val = {
      routeName: window.location.pathname,
      vnNumber: props.opd ? props.vnNumber : null,
      text: t('XRAY.PATH.CREATE'),
      isCreate: true,
      patientId: state?.patientId,
      servicePointId: state?.servicePointId
    }

    if (props?.onSaveTreatment) {
      props.onSaveTreatment()
    }

    history.push(routeName.xray + '/create', val)
  }

  const onPrint = (data: any) => {
    const state: any = location?.state
    const val = {
      data,
      routeName: window.location.pathname,
      text: t('XRAY.PATH.PRINT'),
      isPrint: true,
      patientId: state?.patientId,
      servicePointId: state?.servicePointId
    }
    history.push(routeName.xray + '/history', val)
  }

  const handleChangeStatus = (valueXrayFilmTypeId: any) => setXrayFilmTypeId(valueXrayFilmTypeId)

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => setPage(val)

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const isShow = useSelector((state: any) => state.modal.isShow)
  const modal_onPreview = useSelector((state: any) => state.modal.modal_xray)
  useEffect(() => {
    if (!isShow) {
      dispatch(modalSlice.setModal_xray(false))
    }
  }, [isShow])
  const onPreview = (data: any) => {
    const state: any = location?.state
    const currentPath = window.location.pathname
    const val = {
      data,
      routeName: currentPath,
      text: t('XRAY.PATH.PREVIEW'),
      patientId: state?.patientId,
      servicePointId: state?.servicePointId
    }
    history.push(currentPath, val)
    setTimeout(() => {
      dispatch(modalSlice.showModal())
    }, 400)
    dispatch(modalSlice.setModal_xray(true))
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'date', disablePadding: false, label: t('XRAY.TABLE.DATE') },
    { id: 'message', disablePadding: false, labelKey: 'XRAY.TABLE.CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID', align: 'left', class: 'text-nowrap' },
    { id: 'vn', disablePadding: false, label: 'VN' },
    { id: 'namePatient', disablePadding: false, label: t('XRAY.TABLE.NAME_PATIENT') },
    { id: 'typeXray', disablePadding: false, label: t('XRAY.TABLE.CATEGORY_XRAY') },
    { id: 'numXray', align: 'center', disablePadding: false, label: t('XRAY.TABLE.COUNT_XRAY') },
    { id: 'crateName', align: 'center', disablePadding: false, label: t('XRAY.TABLE.CREATE_NAME') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { patientGalleryId, patientId: xRayPatientId, cnNumber, branchCnNumber, vnNumber, xrayFilmTypeName, patientFullName, xrayImages, countXRay, createdAt, updatedBy, updatedAt } = objData
    const objRenderData = {
      key: patientGalleryId,
      id: xRayPatientId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: createdAt ? dateTimeToView(createdAt) : '-' },
        { option: 'TEXT', align: 'left', label: cnNumber || '-' },
        { option: 'TEXT', align: 'left', label: branchCnNumber || props.patient?.branchCnNumber },
        { option: 'TEXT', align: 'left', label: vnNumber },
        { option: 'TEXT', align: 'left', label: patientFullName || `${props.patient?.firstname} ${props.patient?.lastname}` },
        { option: 'TEXT', align: 'left', label: xrayFilmTypeName },
        { option: 'TEXT', align: 'center', label: countXRay ? countXRay : _.isEmpty(xrayImages) ? 0 : xrayImages.length },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt } },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <Dropdown>
              <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                <Icons.Meatball />
              </Dropdown.Toggle>
              <DropdownMenu className="dropdown-table-action position-fixed">
                {/* <DropdownItem onClick={() => onShowRecord(objRenderData.obj)} disabled={permissions.isView.disabled}>
                                    ดูประวัติ X-Ray
                                </DropdownItem> */}
                <DropdownItem onClick={() => onPreview(objRenderData.obj)} disabled={permissions.isView.disabled}>
                  {t('XRAY.DROPDOWN.PREVIEW_XRAY')}
                </DropdownItem>
                <Dropdown.Divider style={{ backgroundColor: 'transparent', borderColor: colors.lightGray, opacity: 1 }} />
                {!props.isClose && <DropdownItem onClick={() => onEdit(objRenderData.obj)} disabled={permissions.isUpdate.disabled}>
                  <img src={icons.dropdownEdit} alt="" className="align-baseline" />
                  <span className="ml-2 align-text-bottom">{t('XRAY.DROPDOWN.EDIT_XRAY')}</span>
                </DropdownItem>}
                {!props.isClose && <DropdownItem onClick={() => onDelete(objRenderData.obj)} disabled={permissions.isDelete.disabled}>
                  <img src={icons.dropdownTrash} alt="" className="align-baseline" />
                  <span className="ml-2 align-text-bottom">{t('XRAY.DROPDOWN.DEL_XRAY')}</span>
                </DropdownItem>}
                <DropdownItem onClick={() => onPrint(objRenderData.obj)}>
                  <ImageLabel src={print} className="align-baseline" />
                  <span className="align-text-bottom">{t('XRAY.DROPDOWN.PRINT_XRAY')}</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        }
      ]
    }
    return <TableRowCommon {...objRenderData} rowLink={true} onClick={() => onPreview(objRenderData.obj)} />
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('XRAY.TITLE.XRAY_LIST')} />
        <Card.Body className="overflow-auto">
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col xl={9}>
                <Row className="flax-row">
                  <Col sm={6} md={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('XRAY.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={6} md={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        handleChangeStatus(value)
                        setPage(1)
                      }}
                      renderValue={() => `${t('XRAY.FORM.CATEGORY')}: ${_.find(xrayFilmTypes, (p: any) => p.xrayFilmTypeId === xrayFilmTypeId)?.xrayFilmTypeName || t('XRAY.FORM.ALL')}`}
                      label={t('XRAY.FORM.CATEGORY_ALL')}
                      selectId="select-status"
                      value={xrayFilmTypeId}
                      labelId="label-status"
                      options={[
                        <MenuItem key="ALL" value={0}>
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        _.map(xrayFilmTypes, (props: any, i: number) => (
                          <MenuItem key={i} value={props.xrayFilmTypeId}>
                            {props.xrayFilmTypeName}
                          </MenuItem>
                        ))
                      ]}
                      formControlStyle={{ maxWidth: '100% !important' }}
                    />
                  </Col>
                  <Col sm={6} md={4} className="pl-md-0 pt-2">
                    <div className="w-100">
                      <InputSecondNewRangePicker
                        value={rangeDate || []}
                        inputHeight={32}
                        onchange={(val: any) => {
                          if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                          else {
                            setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                            setPage(1)
                          }
                        }}
                        allowClear
                        onClear={() => setRangeDate(emptyRangePicker)}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              {!props.isClose && <Col xl={3} className="mt-2 mt-xl-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('XRAY.BUTTON.CREATE')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>}
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={xray.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      {isShow && modal_onPreview ? <ListXray updateSuccess={() => loadData()} /> : <></>}
    </div>
  )
}
