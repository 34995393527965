import React from "react"
import { Alert, Snackbar } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/Error';

interface SnackbarComponentProps {
    onOpen: boolean
    label: string
    type: "success" | "error"
    onClose: any
    transform: string
    autoHideTime?: number
}

export default function SnackbarComponent(props: SnackbarComponentProps) {

    ////-------------------
    ////    RENDER
    ////-------------------
    return (
        <Snackbar
            open={props.onOpen}
            autoHideDuration={props.autoHideTime || 5000}
            onClose={props.onClose}
            action={<button onClick={props.onClose}>Close</button>}
            sx={{
                position: 'absolute',
                transform: props.transform,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                borderRadius: '6px'
            }}
        >
            <Alert
                icon={props.type === 'success' ? <CheckCircleIcon sx={{ color: '#00AA86' }} /> : <ErrorOutlineIcon sx={{ color: '#FB5252' }} />}
                onClose={props.onClose}
                sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    '& .MuiAlert-message': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    borderRadius: '6px',
                    position: 'relative',
                    paddingLeft: '16px',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '6px',
                        height: '100%',
                        backgroundColor: props.type === 'success' ? '#00AA86' : '#FB5252',
                        borderRadius: '6px 0 0 6px',
                    },
                }}
            >
                {props.label}
            </Alert>
        </Snackbar>
    )
}