import RegisterApi from "api/counter/register.api"

export enum CLINIC_THEME {
  DENTAL = 'DENTAL',
  PLUS = 'PLUS'
}

export interface ClinicThemeInterface {
  type: CLINIC_THEME
  theme: CLINIC_THEME
}

export enum EXPENSE_GROUP {
  INCOME = `INCOME`,
  EXPENSE = `EXPENSE`
}

export enum FIXED {
  FIXED = `FIXED`,
  NOT_FIXED = `NOT_FIXED`
}

export const SCOPE = {
  FIXED: {
    ...FIXED
  },
  IS_DEFAULT: {
    IS_DEFAULT: `1`,
    IS_NOT_DEFAULT: `0`
  }
}

export enum TIME_DURATION_TYPE {
  MINUTES = `MINUTES`,
  HOURS = `HOURS`,
  DAY = `DAY`,
  MONTH = `MONTH`
}

export const MEMBERSHIP_RANKING = [
  { key: `BRONZ`, text: `Bronze Member`, class: `bronze-member` },
  { key: `SILVER`, text: `Silver Member`, class: `silver-member` },
  { key: `GOLD`, text: `Gold Member`, class: `gold-member` },
  { key: `PLATINUM`, text: `Platinum Member`, class: `platinum-member` },
  { key: `DIMON`, text: `Diamond Member`, class: `daimon-member` }
]



export const RANKS = []

export enum MEMBER_RANKING_TYPE {
  BRONZE = 'bronz',
  SILVER = 'silver',
  GOLD = 'gold',
  PLATINUM = 'platinum',
  DIAMOND = 'dimon'
}

export enum KEY_STORAGE {
  TOKEN = 'TOKEN',
  PERMISSION = 'PERMISSION',
  SERVICE_POINT = 'SERVICE_POINT',
  PACKAGE = 'PACKAGE',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  PATIENT = 'PATIENT',
  FILTER_PATIENT_ALL_BRANCH = 'FILTER_PATIENT_ALL_BRANCH',
  FILTER_PATIENT_CONDITION = 'FILTER_PATIENT_CONDITION',
  PROFILE = 'PROFILE',
  CLINIC_INFO = 'CLINIC_INFO',
  DATE_FINANCE = 'DATE_FINANCE',
  TREATMENT_CLOSE_POPUP = 'TREATMENT_CLOSE_POPUP',
  TREATMENT_CLOSE_DROPDOWN = 'TREATMENT_CLOSE_DROPDOWN',
  REPORT_CONDITION = 'REPORT_CONDITION',
  LANGUAGE = 'LANGUAGE',
  APP = "APP",
  FORMATAPPOINTMENT = "FORMATAPPOINTMENT",
  DASHBOARD = "DASHBOARD",
  MEMBER_RANKING_TYPE = "MEMBER_RANKING_TYPE",
  HOME = "HOME",
  CRM = "CRM"
}

export enum BMI_RANGE {
  'UNDER_WEIGHT' = 'UNDER_WEIGHT',
  'NORMAL_RANGE' = 'NORMAL_RANGE',
  'PRE_OBESE' = 'PRE_OBESE',
  'OBESE_I' = 'OBESE_I',
  'OBESE_II' = 'OBESE_II',
  'OBESE_III' = 'OBESE_III'
}

export enum SORT_TYPE {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum CHECK_ALL_TYPE {
  'INDETERMINATE' = 'indeterminate',
  'CHECKED' = 'checked'
}

export type BranchObjType = {
  id?: number
  name?: string
}

export type ServicePointsType = {
  branch?: BranchObjType
  servicePoint?: BranchObjType
}

export type PermissionMenusType = {
  shortcuts?: any
  menus?: any
  activeMenu?: any
}

export const removeAllStorage = () => {
  localStorage.removeItem('BRANCH_ID')
  localStorage.removeItem('SERVICE_POINT_ID')
  localStorage.removeItem(KEY_STORAGE.TOKEN)
  localStorage.removeItem(KEY_STORAGE.PERMISSION)
  localStorage.removeItem(KEY_STORAGE.SERVICE_POINT)
  localStorage.removeItem(KEY_STORAGE.PACKAGE)
  localStorage.removeItem(KEY_STORAGE.SESSION_EXPIRED)
  localStorage.removeItem(KEY_STORAGE.PATIENT)
  localStorage.removeItem(KEY_STORAGE.PROFILE)
  localStorage.removeItem(KEY_STORAGE.CLINIC_INFO)
  localStorage.removeItem(KEY_STORAGE.DATE_FINANCE)
  localStorage.removeItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP)
  localStorage.removeItem(KEY_STORAGE.APP)
  localStorage.removeItem(KEY_STORAGE.FORMATAPPOINTMENT)
  localStorage.removeItem(KEY_STORAGE.CRM)
}

export const removeBranchStorage = () => {
  localStorage.removeItem('BRANCH_ID')
  localStorage.removeItem('SERVICE_POINT_ID')
  localStorage.removeItem(KEY_STORAGE.SERVICE_POINT)
  localStorage.removeItem(KEY_STORAGE.PERMISSION)
  localStorage.removeItem(KEY_STORAGE.PATIENT)
}

export const reloadClinicInfoAsMinute = 10
