import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import { Card, Col, Row } from 'react-bootstrap'
import InputTextSearch from 'component/Input/InputTextSearch'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import moment from 'moment'

/** ROUTE */
import { routeName } from 'routes/routes-name'
import { useTranslation } from 'react-i18next'
import OverdueApi from 'api/finances/overdue.api'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { numberToCurrencyFormat } from 'utils/app.utils'
import { dateToView } from 'utils/date.utils'
import _ from 'lodash'

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(1)': {
          width: '5%'
        },
        '&:nth-of-type(2)': {
          width: '10%'
        },
        '&:nth-of-type(3)': {
          width: '15%'
        },
        '&:nth-of-type(4)': {
          width: '70%'
        },
        '&:nth-of-type(5)': {
          width: '15%'
        },
        '&:nth-of-type(6)': {
          width: '15%',
          padding: 15
        },
        [theme.breakpoints.down('lg')]: {
          '&:nth-of-type(1)': {
            width: '5%'
          },
          '&:nth-of-type(2)': {
            width: '30%'
          },
          '&:nth-of-type(3)': {
            width: '30%'
          },
          '&:nth-of-type(4)': {
            width: '70%'
          },
          '&:nth-of-type(5)': {
            width: '40%'
          },
          '&:nth-of-type(6)': {
            width: '40%',
            padding: 15
          },
        },
        [theme.breakpoints.down('sm')]: {
          '&:nth-of-type(1)': {
            width: '5%'
          },
          '&:nth-of-type(2)': {
            width: '30%'
          },
          '&:nth-of-type(3)': {
            width: '30%'
          },
          '&:nth-of-type(4)': {
            width: '70%'
          },
          '&:nth-of-type(5)': {
            width: '40%'
          },
          '&:nth-of-type(6)': {
            width: '40%',
            padding: 15
          },
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'middle',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        [theme.breakpoints.down('sm')]: {
          verticalAlign: 'top'
        }
      }
    },
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
    display: 'none'
  }
}))

export default function Overdue() {
  const { t } = useTranslation()
  const history = useHistory()
  const [overDue, setOverdue] = useState([])
  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('lastPad')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [rangeDate, setRangeDate] = useState<string[]>(emptyRangePicker)

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (rangeDate) {
      if ((rangeDate[0] !== null || rangeDate[0] !== '') && (rangeDate[1] !== null || rangeDate[1] !== ''))
        condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    const res = await OverdueApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setOverdue(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, rangeDate])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onView = (e: any, objVal: any) => {
    history.push(routeName.overdueDetail, { patientId: objVal.patientId })
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }
  const handleChangePage = (val: number) => setPage(val)

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.CN_NUMBER') },
    { id: 'branchId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID'), class: 'pr-1' },
    { id: 'namePatient', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.NAME_CUSTOMER') },
    { id: 'namePatient', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.PAY_LASTED') },
    { id: 'typeXray', align: 'right', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.SUM_PAYED') }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { patientId, cnNumber, branchCnNumber, patientFullName, lastPad, overdue } = objData
    const objRenderData = {
      key: String(no),
      id: patientId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: cnNumber || '-' },
        { option: 'TEXT', align: 'left', label: branchCnNumber || '-' },
        { option: 'TEXT', align: 'left', label: patientFullName },
        { option: 'TEXT', align: 'left', label: dateToView(lastPad) },
        { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(overdue) }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        rowLink={true}
        onClick={(e, val) => onView(e, val)}
      />
    )
  }

  return (
    <div className={'h-100'}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('OVERDUE_DETAIL.BREADCRUMB.MAIN')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="flax-row">
              <Col xs={12} sm={6} lg={3} className="pt-2">
                <InputTextSearch
                  keyInput={'search'}
                  label={t('OVERDUE_DETAIL.SEARCH')}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                  onClear={(event) => {
                    setSearch('')
                    setPage(1)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} lg={3} className="pl-md-0 pt-2">
                <div className="d-flex flex-column flex-sm-row">
                  <div className="pt-2 pt-sm-0 pl-sm-2 ml-sm-1 w-100">

                    <InputSecondNewRangePicker
                      value={rangeDate || []}
                      inputHeight={32}
                      onchange={(val: any) => {
                        if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                        else {
                          setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                          setPage(1)
                        }
                      }}
                      allowClear
                      onClear={() => setRangeDate(emptyRangePicker)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableWidth>
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                rowCount={rowCount}
                headCells={headCells}
                rowsData={overDue.map((val, i) => renderData(val, i))}
              />
            </TableWidth>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
