import { useState, useEffect } from 'react'
import { Box, Typography, IconButton, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { getBranch, getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { branchInfo, selectMe } from 'app/slice/user.slice'

/** API */
import { routeName } from 'routes/routes-name'
import TreatmentQueueAPI, { ListSummary } from 'api/dentists/treatmentQueue.api'

/** CONSTANTS */
import { colors, ThemeIcon } from 'constants/theme'
import { Icons } from 'constants/images'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as UseStyled from 'features/treatments/Queue/Style/StyleTreatmentsQueue'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { KEY_STORAGE } from 'constants/common'

const Layout = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative'
}))



export interface SummaryBoxType {
  className?: string
  date?: string
  appointment?: number
  queue?: number
  success?: number
  onClose?: () => void
}

export interface ISummaryBox {
  appoint?: string | number
  date?: string | number
  doctorFee?: string | number | null
  doctorId?: number
  defaultDfCon?: number
  done?: string | number
  lab?: string | number
  wait?: string | number
}

export default function SummaryBox(props: SummaryBoxType) {
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const [summary, setSummary] = useState<ISummaryBox>({})
  const date = moment()

  const [isViewDfAndLab, setIsViewDfAndLab] = useState(false)
  const [tooltip, setTooltip]: any[] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [expanded, setExpanded] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem(KEY_STORAGE.TREATMENT_CLOSE_DROPDOWN) || 'false');
  });

  // const branchesInfo = useSelector(branchInfo)
  const branchesInfo = getClinicInfo()

  const loadSummary = async (doctorId: number, branchId: number, date: string) => {
    const condition: ListSummary = { branchId: branchId, date: date }
    const resp = await TreatmentQueueAPI.summary(doctorId, condition)
    if (resp.status === 200) setSummary(resp.data)
  }

  const goToReport = () => {
    const dfLab = branchesInfo?.defaultDfReport === '0' ? 'df-lab' : 'df-lab-operative'
    window.open(`${routeName.report}/${dfLab}?doctorId=${user.userId}${branchesInfo?.defaultDfReport === '0' ? `&&condition=${summary.defaultDfCon}` : ''}`, '_blank')
  }

  const onCollapse = () => {
    setTooltip(false)
    setCollapse(!collapse)
  }

  useEffect(() => {
    if (user.userId) {
      loadSummary(user.userId, Number(getBranch()), moment().format('YYYY-MM-DD'))
      setIsViewDfAndLab(user.isViewDfAndLab === '1' ? true : false)
    }

    localStorage.getItem(KEY_STORAGE.TREATMENT_CLOSE_DROPDOWN);
    console.log(localStorage.getItem(KEY_STORAGE.TREATMENT_CLOSE_DROPDOWN))

  }, [user.userId])

  const handleChangeClose = () => {
    setExpanded(prev => {
      const newExpanded = !prev;
      localStorage.setItem(KEY_STORAGE.TREATMENT_CLOSE_DROPDOWN, JSON.stringify(newExpanded));
      return newExpanded;
    });
  };


  return (
    <UseStyled.SummaryBox className={`layout-summary ${props.className || ''} p-3 pr-xl-4`}>
      <Box className="d-flex align-items-center justify-content-between pt-1 pb-3">
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>
          {t('TREATMENT_QUEUE.SUMMARY.SUMMARY_TODAY')} {toBuddhistYear(moment(date), 'DD MMMM YYYY')}
        </Typography>
        <IconButton onClick={props.onClose} className="close d-xl-none p-0">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box style={{ backgroundColor: '#E5E5E5', width: '350px', height: '3px', marginLeft: '-18px', marginBottom: '20px' }}>
        <IconButton onClick={() => handleChangeClose()} style={{ zIndex: 999, backgroundColor: 'white', width: 22, height: 22, borderRadius: 100, border: `1px solid ${colors.textLightGray}`, position: 'relative', left: '300px', bottom: '14px' }}>
          <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
        </IconButton>
      </Box>
      <Box className={`d-flex align=items-center justify-content-between mt-1 expandable-content ${!expanded ? 'collapsed' : ''}`}>
        <button style={{ backgroundColor: 'white', border: 'none', width: '100%' }} onClick={goToReport}>
          <UseStyled.SummaryValue type="DF" className="mr-1 pe-none" style={{ display: isViewDfAndLab ? 'block' : 'none' }}>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>฿ {numberFormat(Number(summary.doctorFee) || 0)}</Typography>
            <Box className="d-flex align-items-center justify-content-between">
              <Typography sx={{ fontSize: 12 }}>{t('TREATMENT_QUEUE.SUMMARY.DF_VALUE')}</Typography>
              <UseStyled.Arrow >
                <ArrowUpwardIcon />
              </UseStyled.Arrow>
            </Box>
          </UseStyled.SummaryValue>
        </button>
        <UseStyled.SummaryValue type="LAB" className="ml-1 pe-none" style={{ display: isViewDfAndLab ? 'block' : 'none' }}>
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>฿ {numberFormat(Number(summary.lab) || 0)}</Typography>
          <Box className="d-flex align-items-center justify-content-between">
            <Typography sx={{ fontSize: 12 }}>{t('TREATMENT_QUEUE.SUMMARY.LAB_VALUE')}</Typography>
            <UseStyled.Arrow className="opacity-0">
              <ArrowUpwardIcon />
            </UseStyled.Arrow>
          </Box>
        </UseStyled.SummaryValue>
      </Box>
      <Box sx={{ margin: '0 -0.5rem', padding: '0 0.5rem' }} style={{ borderTop: `1px solid ${colors.lightGray}`, marginTop: '10px' }}>
        <UseStyled.SummaryList type="appointment" className="d-flex align-items-center">
          <Box className="d-flex align-items-center" sx={{ width: 38, height: 38 }}>
            <Icons.Appointment color={colors.themeMainColor} />
          </Box>
          <Box className="pl-3">
            <Typography>
              {Number(summary.appoint) || 0} {t('TREATMENT_QUEUE.SUMMARY.APPOINTMENT')}
            </Typography>
            <Typography>{t('TREATMENT_QUEUE.SUMMARY.MADE_APPOINTMENT')}</Typography>
          </Box>
        </UseStyled.SummaryList>
        <UseStyled.SummaryList type="wait" className="d-flex align-items-center">
          <Box className="d-flex align-items-center" sx={{ width: 38, height: 38 }}>
            <Box sx={{ svg: { width: 33, height: 33 } }}>
              <ThemeIcon.SendExam color={colors.white} />
            </Box>
          </Box>
          <Box className="pl-3">
            <Typography>
              {props.queue || Number(summary.wait) || 0} {t('TREATMENT_QUEUE.SUMMARY.PEOPLE')}
            </Typography>
            <Typography>{t('TREATMENT_QUEUE.SUMMARY.WAITING_SERVICE')}</Typography>
          </Box>
        </UseStyled.SummaryList>
        <UseStyled.SummaryList type="service" className="d-flex align-items-center">
          <Box className="d-flex align-items-center" sx={{ width: 38, height: 38 }}>
            <CheckCircleOutlineOutlinedIcon />
          </Box>
          <Box className="pl-3">
            <Typography>
              {Number(summary.done) || 0} {t('TREATMENT_QUEUE.SUMMARY.PEOPLE')}
            </Typography>
            <Typography>{t('TREATMENT_QUEUE.SUMMARY.SUCCESS_SERVICE')}</Typography>
          </Box>
        </UseStyled.SummaryList>
      </Box>
    </UseStyled.SummaryBox>
  )
}
