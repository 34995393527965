import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap';
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { routeName } from 'routes/routes-name'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import ReportPermissionApi from 'api/setting/personnel-managements/report-permission.api'

/** STYLE */

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

export default function ReportPermission() {
    const { t } = useTranslation()
    const history = useHistory()

    const [reportPermissions, setReportPermissions] = useState([])

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createAt')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')

    const loadData = useCallback(async () => {

        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }

        const respReportPermissions = await ReportPermissionApi.reportsList(condition)
        if (respReportPermissions.status === 200) {
            setRowCount(respReportPermissions.headers['x-total'])
            setReportPermissions(respReportPermissions.data)
        }
    }, [page, pageLimit, search, sortBy, sortType])

    useEffect(() => { loadData() }, [loadData])

    const onEdit = (objData: any) => {
        history.push(routeName.reportPermission + '/update', objData)
    }

    const onDelete = (data: any) => {
        const { roleName } = data
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('REPORT_ROLE.ALERT.CONFIRM_DELETE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('REPORT_ROLE.ALERT.CONFIRM_DELETE_', {
                roleName: roleName
            })}</p>`,
            (res: any) => {
                if (res) {
                    return
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '100px' },
        { id: 'roleName', disablePadding: false, label: t('REPORT_ROLE.TABLE.CELL.ROLE_NAME'), width: '40%' },
        { id: 'quantity', disablePadding: false, label: t('REPORT_ROLE.TABLE.CELL.QUANTITY_ROLE'), align: 'center' },
        { id: 'updatedBy', disablePadding: false, label: t('REPORT_ROLE.TABLE.CELL.UPDATED_BY') },
        { id: 'action', disablePadding: false, label: 'Action' }
    ]

    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { reportPermissionId, roleName, countReport, updatedAt, updatedBy } = objData

        const objRenderData = {
            key: reportPermissionId,
            id: reportPermissionId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: roleName || '-', class: 'text-ellipsis' },
                { option: 'TEXT', align: 'center', label: countReport || '-' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled }
                    ]
                }
            ]
        }
        return <TableRowCommon {...objRenderData} onedit={() => onEdit(objRenderData.obj)} ondelete={() => onDelete(objRenderData.obj)} />
    }

    return (
        <div>
            <Card className="border-0 h-100">
                <HeaderCard text={t('REPORT_ROLE.TITLE')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <Row className="flax-row">
                                    <Col sm={12} className="pt-2">
                                        <InputTextSearch
                                            keyInput={'search'}
                                            label={t('REPORT_ROLE.SEARCH')}
                                            value={search}
                                            onchange={(event) => {
                                                setSearch(event.target.value)
                                                setPage(1)
                                            }}
                                            onClear={(event) => {
                                                setSearch('')
                                                setPage(1)
                                            }}
                                            formStyle={{ maxWidth: '340px' }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Title>
                    <div className={'mt-3 pl-xl-3 pr-xl-2'}>
                        <TableCustom
                            tableFixedWidth
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={_.map(reportPermissions, renderData)}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}
