
export interface IconProp {
    fill?: string
    width?: string
    height?: string
}
export default function IconCheck(props: IconProp) {
    return (
        <svg
            width={props.width || '14'}
            height={props.height || '14'}
            viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99967 0.333008C3.31967 0.333008 0.333008 3.31967 0.333008 6.99967C0.333008 10.6797 3.31967 13.6663 6.99967 13.6663C10.6797 13.6663 13.6663 10.6797 13.6663 6.99967C13.6663 3.31967 10.6797 0.333008 6.99967 0.333008ZM5.19301 9.85968L2.79967 7.46634C2.53967 7.20634 2.53967 6.78634 2.79967 6.52634C3.05967 6.26634 3.47967 6.26634 3.73967 6.52634L5.66634 8.44634L10.253 3.85967C10.513 3.59967 10.933 3.59967 11.193 3.85967C11.453 4.11967 11.453 4.53967 11.193 4.79967L6.13301 9.85968C5.87967 10.1197 5.45301 10.1197 5.19301 9.85968Z"
                fill={props.fill || "#1CB99A"} />
        </svg>
    )
}