import { menuThirds } from "../../constants/menus";
import MesssageNotification from "../../features/application/messsage-notification/messsage-notification";

const routeMesssageNotification = [
    {
        ...menuThirds.MESSAGE_NOTIFICATION,
        key: menuThirds.MESSAGE_NOTIFICATION.key,
        name: menuThirds.MESSAGE_NOTIFICATION.name,
        textName: menuThirds.MESSAGE_NOTIFICATION.textName,
        active: false,
        appBar: true,
        component: MesssageNotification
    },
]

export default routeMesssageNotification

