
const IconClinicDisabeld = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.07161 7.07715C4.19925 7.17035 4.34663 7.21498 4.4927 7.21498C4.71245 7.21498 4.92957 7.1139 5.07037 6.92225C5.33224 6.56519 5.62436 6.2252 5.93886 5.91015C8.76277 3.09307 13.25 2.60343 16.6081 4.74446C17.1305 5.07789 17.6187 5.46908 18.0609 5.91015C18.3398 6.18844 18.7925 6.18844 19.0715 5.91015C19.3505 5.63185 19.3505 5.18028 19.0715 4.90199C18.557 4.38872 17.9872 3.9319 17.3792 3.54333C13.4592 1.04656 8.22326 1.61628 4.92826 4.90199C4.56112 5.26823 4.22031 5.66467 3.91502 6.0808C3.68211 6.39848 3.75185 6.8448 4.07029 7.07583L4.07161 7.07715Z" fill="#A8A8A8" />
            <path d="M21.2672 8.20393C21.1185 7.839 20.7027 7.6631 20.3368 7.81143C19.971 7.95977 19.7947 8.37458 19.9434 8.73952C20.7027 10.6075 20.775 12.6947 20.1487 14.6152C20.0263 14.9893 20.2316 15.391 20.6066 15.5131C20.6803 15.5367 20.7553 15.5485 20.8277 15.5485C21.129 15.5485 21.408 15.3569 21.5067 15.0563C22.2383 12.8168 22.1528 10.3843 21.2672 8.20393Z" fill="#A8A8A8" />
            <path d="M16.6743 18.1723C16.3321 18.5136 16.1729 18.9678 16.19 19.4168C16.0663 19.4863 15.9413 19.5546 15.815 19.6176C12.5147 21.2532 8.54732 20.6048 5.93922 18.0043C5.66946 17.7352 5.41681 17.4477 5.18784 17.1484C4.38909 16.1087 3.85353 14.9286 3.59561 13.6422C3.32585 12.2979 3.38507 10.892 3.76536 9.57669C3.87458 9.19863 3.65614 8.8035 3.27716 8.69455C2.89819 8.58428 2.5021 8.8035 2.39288 9.18156C1.94811 10.7148 1.87968 12.3544 2.19418 13.9218C2.49026 15.4025 3.13373 16.8176 4.05223 18.0148C4.31935 18.3627 4.61411 18.6987 4.92861 19.0111C6.85113 20.929 9.40791 21.9372 12.0015 21.9372C13.5109 21.9372 15.0334 21.5959 16.4493 20.8936C16.5835 20.8266 16.7151 20.7544 16.8467 20.6809C17.1467 20.9093 17.502 21.0301 17.8612 21.0301C18.2915 21.0301 18.7205 20.8673 19.0482 20.5404C19.7022 19.888 19.7022 18.8247 19.0482 18.1723C18.3942 17.5199 17.3283 17.5199 16.6743 18.1723ZM18.2402 19.7344C18.031 19.9432 17.6915 19.9432 17.4822 19.7344C17.273 19.5257 17.273 19.187 17.4822 18.9783C17.5862 18.8733 17.7244 18.8221 17.8612 18.8221C17.9981 18.8221 18.1349 18.8746 18.2402 18.9783C18.4494 19.187 18.4494 19.5257 18.2402 19.7344Z" fill="#A8A8A8" />
            <path d="M12.0016 18.2982C14.3913 18.2982 16.5572 16.9816 17.6534 14.8616C18.1139 13.9702 18.3574 12.966 18.3574 11.9578C18.3574 11.564 18.0376 11.245 17.6429 11.245C17.2481 11.245 16.9283 11.564 16.9283 11.9578C16.9283 12.752 16.7454 13.5081 16.3835 14.2078C15.5335 15.8513 13.8544 16.8739 12.0016 16.8739C9.28429 16.8739 7.07359 14.6686 7.07359 11.9578C7.07359 11.2936 7.21045 10.6543 7.46573 10.0531C7.51705 10.0583 7.56968 10.061 7.62232 10.061C8.07104 10.061 8.49213 9.88638 8.80926 9.57002C9.46326 8.9176 9.46326 7.85431 8.80926 7.20189C8.49213 6.88553 8.07104 6.71094 7.62232 6.71094C7.1736 6.71094 6.75251 6.88553 6.43538 7.20189C6.11825 7.51825 5.94324 7.93832 5.94324 8.38595C5.94324 8.72201 6.04193 9.04231 6.22352 9.31404C5.8406 10.145 5.64453 11.0337 5.64453 11.9578C5.64453 15.4536 8.49607 18.2982 12.0003 18.2982H12.0016ZM7.24334 8.00658C7.34467 7.9055 7.47889 7.85037 7.62232 7.85037C7.76575 7.85037 7.89997 7.9055 8.0013 8.00658C8.21052 8.2153 8.21052 8.55398 8.0013 8.7627C7.79865 8.96486 7.44599 8.96486 7.24334 8.7627C7.03412 8.55398 7.03412 8.2153 7.24334 8.00658Z" fill="#A8A8A8" />
            <path d="M13.9169 7.42677C14.9183 7.84947 15.7631 8.60296 16.2974 9.55074C16.429 9.78309 16.6711 9.91436 16.9198 9.91436C17.0382 9.91436 17.1593 9.88548 17.2698 9.82247C17.6133 9.6295 17.7357 9.19499 17.5422 8.85238C16.854 7.63156 15.7645 6.66015 14.4736 6.11406C13.6893 5.78326 12.8563 5.61523 11.9997 5.61523C11.6049 5.61523 11.2852 5.93422 11.2852 6.32804C11.2852 6.72185 11.6049 7.04084 11.9997 7.04084C12.6655 7.04084 13.3103 7.1708 13.9183 7.42677H13.9169Z" fill="#A8A8A8" />
        </svg>
    )
}

export default IconClinicDisabeld
