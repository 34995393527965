import { useState, useEffect } from "react"
import { TFunction, useTranslation } from 'react-i18next'
import MainButton from 'new-components/buttons/main-button'
import InfoIcon from '@mui/icons-material/Info';
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import IconClose from 'assets/icon/iconClose'
import TreatmentProductApi, { AllProduct } from 'api/dentists/treatmentProduct.api'
import MedicinesApi from 'api/warehouses/medicine.api'
import { RawAdditionalType, RawDiscountType, renderSumAdditional, renderSumDiscount } from 'features/treatments/SummaryTreatment/SummaryTreatment'
import { notiError } from 'component/notifications/notifications'
import { Box, Grid, Typography, MenuItem, Tooltip } from '@mui/material'
import { numberFormat, getBranch, getClinicInfo } from 'utils/app.utils'
import { colors } from "constants/theme";
import { treatment, setTreatmentByKey, TreatmentStateInterface, checkApprovalRight, setApprovalRight, promotionsTreatments, loadPromotionTreatments, StickerType, ProductStickerType, } from 'app/slice/treatment.slice'
import TreatmentApi, { FindDoctorFeeProps } from 'api/dentists/treatment.api'
import OperativeApi from 'api/setting/treatments/operative.api'
import _ from 'lodash'
import { swalCustom } from "component/Alert/Swal";

type ModalSelectPromotionExtra = {
  onClose: () => void
  promotionExtraValue: any
  promotionExtra: any
  promotionBuy: any
  onChangePromotionExtra: (data: any, dataBuy?: any) => void
}

export const ModalSelectPromotionExtra = (props: ModalSelectPromotionExtra) => {
  const { onClose, promotionExtra, onChangePromotionExtra, promotionBuy, promotionExtraValue } = props
  const { t } = useTranslation()

  const [disabledAddExtra, setDisabledAddExtra] = useState<boolean>(false)
  const [selectBuy, setSelectBuy] = useState<any>([])

  useEffect(() => {
    setDisabledAddExtra(promotionBuy?.length ? true : false)
  }, [promotionBuy])

  const handleSelectBuy = (data: any) => {
    setDisabledAddExtra(false)
    setSelectBuy(data)
  }

  const headCells = [
    { id: 'index', disablePadding: false, label: t('#'), class: 'text-nowrap', width: '50px', align: 'center', },
    { id: 'list', disablePadding: false, label: t('PROMOTION.FORM.ITEM_LIST'), class: 'text-nowrap', width: '250px', align: 'left', },
    { id: 'count', disablePadding: false, label: t('PROMOTION.FORM.COUNT_LIST'), class: 'text-nowrap', width: '100px', align: 'center', },
    { id: 'price', disablePadding: false, label: t('PROMOTION.FORM.COST'), class: 'text-nowrap', width: '100px', align: 'center', },
    { id: 'action', disablePadding: false, label: t('Action'), class: 'text-nowrap', width: '100px', align: 'center', },
  ]

  const renderData = (objData: any, index: number, status: "BUY" | "EXTRA") => {

    const totalObj = objData
      .map((item: any) => item.qty * item.itemPrice)
      .reduce((acc: any, value: any) => acc + value, 0)



    const objRenderData = {
      key: index,
      id: index,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: objData[0]?.groupNumber },
        {
          option: 'COMPONENT',
          component: (
            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
              {objData.map((item: any, num: number) => {
                return (
                  <Box key={index}>
                    {item?.itemName}
                  </Box>
                )
              })}
            </Box>
          )
        },
        {
          option: 'COMPONENT',
          component: (
            <Box display={'flex'} flexDirection={'column'} gap={'8px'} textAlign={'center'}>
              {objData.map((item: any, num: number) => {
                return (
                  <Box key={index}>
                    {item?.qty}
                  </Box>
                )
              })}
            </Box>
          )
        },
        { option: 'TEXT', align: 'center', label: numberFormat(totalObj) },
        {
          option: 'COMPONENT',
          component: (
            <Box textAlign={'center'} sx={{ button: { minWidth: 'fit-content !important', padding: '0 14px', fontSize: '20px', fontWeight: '600' } }}>
              <MainButton
                disabled={(status === "EXTRA" && disabledAddExtra) || objData.every((item: any) => item?.disabled)}
                type={'primary'}
                variant={'contained'}
                title={'+'}
                onClick={() => {
                  if (status === 'EXTRA') {
                    onChangePromotionExtra(objData, selectBuy)
                  } else {
                    handleSelectBuy(objData)
                  }
                }}
              />
            </Box>
          )
        },
      ]
    }

    return (
      <TableRowCommon {...objRenderData} />
    )
  }

  return (
    <>
      <Box
        position={'fixed'}
        zIndex={2900}
        top={0}
        right={0}
        left={0}
        bottom={0}
        width={'100%'}
        height={'100%'}
      >
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100%'} padding={'24px'} bgcolor={'#231f201a'} >
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'fit-content'} gap={'24px'} bgcolor={'#fff'} padding={'24px'} borderRadius={'10px'} overflow={'hidden'} position={'relative'}>
            <Box position={'absolute'} top={'12px'} right={'12px'} sx={{ cursor: 'pointer', path: { fill: '#000000 !important', stroke: 'transparent !important', opacity: '0.5' } }}
              onClick={onClose}
            >
              <IconClose />
            </Box>
            <Box textAlign={'center'} fontSize={'20px'} fontWeight={'600'} color={colors.themeMainColor} >
              {t('PROMOTION.MODAL_MANAGE.TITLE')}
            </Box>
            <Box display={'flex'} flexDirection={'column'} height={'100%'} overflow={'hidden'} >
              <Box marginBottom={'16px'} display={'flex'} alignItems={'center'}>
                {t('PROMOTION.FORM.PROMOTION_NAME')}:{promotionExtraValue?.promotionFullName}
                {promotionExtraValue?.detail &&
                  (
                    <span>
                      <Tooltip title={promotionExtraValue?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                        <InfoIcon />
                      </Tooltip>
                    </span>
                  )
                }
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'16px'} height={'100%'} overflow={'auto'}>
                {promotionBuy?.length ?
                  <Box height={'fit-content'}>
                    <Box marginBottom={'8px'}>
                      {t('PROMOTION.FORM.BUY_LIST')} {selectBuy[0]?.groupNumber && ` : ${t('PROMOTION.FORM.BUY_LIST_NUMBER', { index: selectBuy[0].groupNumber })}`}
                    </Box>
                    <Box height={'fit-content'}>
                      <TableCustom
                        hidePagination
                        tableFixedWidth
                        page={1}
                        pageLimit={1}
                        sortType={''}
                        sortBy={''}
                        rowCount={1}
                        onSort={() => { return }}
                        setPageLimit={() => { return }}
                        setPage={() => { return }}
                        headCells={headCells}
                        rowsData={promotionBuy?.length ? promotionBuy.map((item: any, index: number) => {
                          return renderData(item, index, "BUY")
                        }) : []}
                      />
                    </Box>
                  </Box>
                  : <></>
                }
                <Box height={'fit-content'}>
                  {promotionBuy?.length ?
                    <Box marginBottom={'8px'}>
                      {t('PROMOTION.FORM.EXTRA_LIST')}
                    </Box> : <></>}
                  <Box height={'fit-content'}>
                    <TableCustom
                      hidePagination
                      tableFixedWidth
                      page={1}
                      pageLimit={1}
                      sortType={''}
                      sortBy={''}
                      rowCount={1}
                      onSort={() => { return }}
                      setPageLimit={() => { return }}
                      setPage={() => { return }}
                      headCells={headCells}
                      rowsData={promotionExtra?.length ? promotionExtra.map((item: any, index: number) => {
                        return renderData(item, index, "EXTRA")
                      }) : []}
                    />
                  </Box>
                </Box>
              </Box>

            </Box>
            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
              <MainButton
                onClick={onClose}
                type={'secondary'}
                title={t('BUTTON.CANCEL')}
                variant={'outlined'}
              />
            </Box>
          </Box>
        </Box>

      </Box>

    </>
  )
}

type usePromotionSaveProps = {
  patient: any
  promotionDelId: number[] | number
  promotionExtraBuy: any[]
  promotionExtraNew: any[]
  promotionExtraValue: any
  dataPromotions: any
  treatments: TreatmentStateInterface
  user: any
  t: TFunction<"translation">
}

export const onPromotionSave = async (props: usePromotionSaveProps) => {
  const { promotionDelId, promotionExtraBuy, promotionExtraValue, patient, promotionExtraNew, dataPromotions, treatments, user, t, } = props
  const branchInfo = getClinicInfo()

  const handleSetOperatives = () => {
    const treatmentsSummery = _.merge([], treatments.summeryTreatments)
    const newPromotionDelId: number[] = typeof promotionDelId === 'number' ? [promotionDelId] : promotionDelId
    if (newPromotionDelId?.length) {
      const newOp: any = []
      treatmentsSummery.forEach((item: any) => {
        if (item?.promotion?.length) {
          item?.promotion.forEach(((info: any) => {
            if (info?.promotionType !== 'EXTRA') {
              if (!newPromotionDelId.includes(info?.promotionBranchId) || info?.isMain === '1') {
                newOp.push(item)
              } else {
                const newData = { ...item, promotion: item?.promotion?.filter((i: any) => !newPromotionDelId.includes(i?.promotionBranchId)) }
                newOp.push(newData)
              }
            } else if (!newPromotionDelId.includes(info?.promotionBranchId)) {
              newOp.push(item)
            }
          }))
        } else {
          newOp.push(item)
        }
      })
      return newOp
    }
    return treatmentsSummery
  }

  const dataTreatmentsPromotions = handleSetOperatives()

  if (!dataPromotions) {
    return {
      dataTreatmentsPromotions: dataTreatmentsPromotions,
      errorExtra: false,
      newBuy: [],
      newExtra: [],
      errorBuy: false
    }
  }

  const handleSetProducts = async (info: any, itemAdd: any, status: "BUY" | "EXTRA") => {
    const newQty = itemAdd?.qty || 1
    let newInfo: any = {
      itemId: info?.itemId,
      itemName: info?.itemName,
      itemNameEn: info?.itemNameEn,
      itemCode: info?.itemCode,
      image: {
        fileId: info?.image?.fileId || null,
        filePath: info?.image?.filePath || '',
        fileName: info?.image?.fileName || ''
      },
      stock: info?.qty,
      defaultProductUnitName: info?.itemUnit?.itemUnitName || info?.itemUnit?.itemUnitNameEn,
      dosage: 1,
      expireDate: info?.expireDate || ''
    }
    let newTemId = 0

    const res = await TreatmentProductApi.findStickerById(patient?.patientCaseId, info?.itemId, { isCreate: '1' })
    newTemId = Math.max(...res?.data.map((item: any) => item.tempOrderProductId))
    if (info?.group === "MEDICINE") {
      await MedicinesApi.findById(info?.itemId)
        .then(({ data }: any) => {
          const objSticker: StickerType = {
            ...newInfo,
            productInstructionId: data?.productInstruction?.productInstructionId,
            productInstructionName: data?.productInstruction?.productInstructionName,
            productUnitId: data?.productUnit?.productUnitId,
            productUnitName: data?.productUnit?.productUnitName,
            productUnitNameEn: data?.productUnit?.productUnitNameEn,
            productUseId: data?.productUse?.productUseId,
            productUseDetail: data?.productUse?.productUseDetail,
            logo: branchInfo?.branchLogo,
            clinicName: branchInfo?.clinicAndBranchName,
            branchAddress: branchInfo?.branchFullAddress,
            branchTel: branchInfo?.branchPhone,
            patientFullName: `${props?.patient?.patientName}` || ''
          }
          newInfo = { ...newInfo, ...objSticker }
        })
    }

    const newValue: ProductStickerType = {
      sticker: JSON.stringify(newInfo),
      tempOrderProductId: newTemId,
      doctorId: 0,
      qty: newQty,
      // price: status === "EXTRA" ? 0 : info?.price,
      price: info?.price,
      discount: 0,
      discountUnit: 'BAHT',
      additional: 0,
      additionalUnit: 'BAHT',
      // total: status === "EXTRA" ? 0 : info?.price * newQty,
      total: info?.price * newQty,
      itemId: info?.itemId,
    }

    await TreatmentProductApi.updateStickerById(patient?.patientCaseId, newValue.itemId, newValue).then(() => { return }).catch(() => { return })


    return {
      ...info,
      tempOrderProductId: newTemId,
      doctorId: null,
      qty: newQty,
      price: status === "EXTRA" ? 0 : info?.price,
      discount: 0,
      discountUnit: 'BAHT',
      additional: 0,
      additionalUnit: 'BAHT',
      total: status === "EXTRA" ? 0 : info?.price * newQty,
      sticker: JSON.stringify(newInfo)
    }

  }

  const handleSetOperative = async (itemOp: any, itemAdd: any, status: "BUY" | "EXTRA") => {
    const newQty = itemAdd?.qty || 1
    const item = {
      ...itemOp,
      price: status === "EXTRA" ? 0 : itemOp?.price,
      qty: newQty,
      total: status === "EXTRA" ? 0 : itemOp?.price * newQty,
      doctorId: user?.userId || null,
      discount: 0,
      additional: 0,
      discountUnit: 'BAHT',
      additionalUnit: 'BAHT',
      dfAmount: 0,
      cfAmount: 0,
      dfDiscountAmount: 0,
      cfDiscountAmount: 0,
      dfDiscountUnit: 'BAHT',
      cfDiscountUnit: 'BAHT',
      isEdit: null
    }
    const resp: any = await OperativeApi.findById(itemOp?.operativeId)
    const data = {
      clinicFeeId: resp?.data?.operativeClinicFees[0]?.clinicFeeId,
      clinicFeeName: resp?.data?.operativeClinicFees[0]?.clinicFeeText,
      clinicFeePrice: resp?.data?.operativeClinicFees[0]?.price
    }
    return { ...item, ...data }
  }

  const renderPromotionExtra = async () => {
    let errorExtra = false
    const errorBuy = false

    let newBuy: any = []
    if (promotionExtraBuy?.length > 1) {

      const handleChangeInfoTreatment = (item: any) => {
        const newIndexTreatment = dataTreatmentsPromotions.findIndex((info: any) => info?.itemId === item?.itemId || info?.operativeId === item?.itemId)
        const list = dataTreatmentsPromotions[newIndexTreatment]
        const newQty = item?.qty
        const isOperative = list.operativeTypeId ? true : false
        const rawAdditional: RawAdditionalType = {
          price: list.price,
          qty: newQty,
          additional: Number(list.additional),
          additionalUnit: list.additionalUnit
        }

        const rawDiscount: RawDiscountType = {
          price: list.price,
          qty: newQty,
          discount: Number(list.discount),
          discountUnit: list.discountUnit,
          dfUnit: list.dfUnit,
          dfAmount: Number(list.dfAmount),
          dfDiscountAmount: Number(list.dfDiscountAmount),
          dfDiscountUnit: list.dfDiscountUnit,
          cfAmount: Number(list.cfAmount),
          cfDiscountAmount: Number(list.cfDiscountAmount),
          cfDiscountUnit: list.cfDiscountUnit,
          additionalNet: renderSumAdditional(rawAdditional),
          doctorFeeAmount: list.doctorFeeAmount,
          clinicFeeAmount: list.clinicFeeAmount
        }
        const discount = (!isOperative && rawDiscount.discount) || renderSumDiscount(rawDiscount) || 0
        const additional = (!isOperative && rawAdditional.additional) || renderSumAdditional(rawAdditional) || 0
        const total = !isOperative ? list.price * newQty - discount + additional : list.price * newQty - discount + additional

        const oldPromotion = dataTreatmentsPromotions[newIndexTreatment]?.promotion || []

        if (oldPromotion?.some((item: any) => item?.promotionBranchId !== promotionExtraValue?.promotionBranchId)) {
          dataTreatmentsPromotions[newIndexTreatment] = {
            ...list, qty: newQty, total: total, promotion: [
              ...oldPromotion,
              {
                promotionBranchId: promotionExtraValue?.promotionBranchId,
                promotionType: "BUY",
                promotionId: promotionExtraValue?.promotionId,
                isMain: '0',
                groupNumber: promotionExtraBuy[0]?.groupNumber
              }
            ]
          }
        }
      }

      const treatmentSummerysCanUse = dataTreatmentsPromotions.filter((item: any) => {
        if ((item?.promotion?.legnth && item?.promotion.some((info: any) => info?.promotionType !== "EXTRA")) || !item?.promotion?.legnth) {
          return item
        }
      })

      // const promises = promotionExtraBuy.map(async (item: any) => {
      //   const dataTreatment = treatmentSummerysCanUse.find((info: any) => (info?.itemId === item?.itemId || info?.operativeId === item?.itemId));
      //   if (dataTreatment) {
      //     if (dataTreatment?.qty !== item?.qty) {
      //       if (dataTreatment?.promotion?.length && dataTreatment?.promotion.some((info: any) => info?.promotionType === 'BUY')) {
      //         await swalCustom(
      //           t('PROMOTION.MODAL_MANAGE.WARNING_TITLE'),
      //           t('PROMOTION.MODAL_MANAGE.WARNING_SUB'),
      //           async (res: any) => errorBuy = res
      //         );
      //       }
      //     }
      //   }
      // });

      // await Promise.all(promises);

      // if (errorBuy) {
      //   return { errorBuy: errorBuy }
      // }

      const newData = await Promise.all(promotionExtraBuy.map(async (item: any) => {
        const dataTreatment = treatmentSummerysCanUse.find((info: any) => (info?.itemId === item?.itemId || info?.operativeId === item?.itemId))
        if (dataTreatment) {
          handleChangeInfoTreatment(item)
        } else {
          if (item?.itemType !== "OPERATIVE") {
            const condition: AllProduct = { page: 1, pageLimit: 1, branchId: getBranch(), userId: user.userId || 0, group: item?.itemType, search: item?.itemCode }
            const resp = await TreatmentProductApi.findAllProduct(condition)
            if (resp?.data[0]) {
              const newData = await handleSetProducts(resp?.data[0], item, "BUY")
              newBuy.push({
                ...newData, promotion: [
                  {
                    promotionBranchId: promotionExtraValue?.promotionBranchId,
                    promotionType: "BUY",
                    promotionId: promotionExtraValue?.promotionId,
                    isMain: '0',
                    groupNumber: promotionExtraBuy[0]?.groupNumber
                  }
                ]
              })
            } else {
              errorExtra = true
              notiError(t('TREATMENT_SUMMARY.MESSAGE.ERROR_PRODUCT_IMPORT', { productName: item?.itemName }))
            }
          } else {
            const condition = { page: 1, pageLimit: 1, userId: user.userId || 0, search: item?.itemCode }
            const resp = await TreatmentApi.findAllOperative(condition)
            if (resp?.data[0]) {
              const newData = await handleSetOperative(resp?.data[0], item, "BUY")
              newBuy.push({
                ...newData, promotion: [
                  {
                    promotionBranchId: promotionExtraValue?.promotionBranchId,
                    promotionType: "BUY",
                    promotionId: promotionExtraValue?.promotionId,
                    isMain: '0',
                    groupNumber: promotionExtraBuy[0]?.groupNumber
                  }
                ]
              })
            }
          }
        }
      }))
    }

    if (newBuy?.length) {
      newBuy = newBuy.filter((item: any) => (item?.operativeId !== dataPromotions?.operativeId || item?.itemId !== dataPromotions?.itemId))
    }


    let newExtra: any = []
    if (promotionExtraNew?.length) {
      const dataExtra = await Promise.all(promotionExtraNew.map(async (item: any) => {
        if (item?.itemType !== "OPERATIVE") {
          const condition: AllProduct = { page: 1, pageLimit: 1, branchId: getBranch(), userId: user.userId || 0, group: item?.itemType, search: item?.itemCode }
          const resp = await TreatmentProductApi.findAllProduct(condition)
          if (resp?.data[0]) {
            const newData = await handleSetProducts(resp?.data[0], item, 'EXTRA')
            return {
              ...newData, promotion: [
                {
                  promotionBranchId: promotionExtraValue?.promotionBranchId,
                  promotionType: promotionExtraValue?.type,
                  promotionId: promotionExtraValue?.promotionId,
                  isMain: '0',
                  groupNumber: promotionExtraNew[0]?.groupNumber
                }
              ]
            }
          } else {
            errorExtra = true
            notiError(t('TREATMENT_SUMMARY.MESSAGE.ERROR_PRODUCT_IMPORT', { productName: item?.itemName }))
          }
        } else {
          const condition = { page: 1, pageLimit: 1, userId: user.userId || 0, search: item?.itemCode }
          const resp = await TreatmentApi.findAllOperative(condition)
          if (resp?.data[0]) {
            const newData = await handleSetOperative(resp?.data[0], item, 'EXTRA')
            return {
              ...newData, promotion: [
                {
                  promotionBranchId: promotionExtraValue?.promotionBranchId,
                  promotionType: promotionExtraValue?.type,
                  promotionId: promotionExtraValue?.promotionId,
                  isMain: '0',
                  groupNumber: promotionExtraNew[0]?.groupNumber
                }
              ]
            }
          }
        }
      }));
      newExtra = dataExtra?.length ? dataExtra : []
    }
    return { dataTreatmentsPromotions: dataTreatmentsPromotions, errorExtra: errorExtra, newBuy: newBuy, newExtra: newExtra, errorBuy: errorBuy }
  }

  return await renderPromotionExtra()
}