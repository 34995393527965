import { useCallback, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { IconButton, Badge, Divider, Button } from '@mui/material'
import { Collapse, Nav } from 'react-bootstrap'
import TextEllipsis from 'component/SlideMenu/TextEllipsis'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { PACKAGE_ADDONS } from 'constants/package'
import { menuFirsts, menuSeconds, menuThirds } from 'constants/menus'
import { scale } from 'constants/scale'
import { colors } from 'constants/theme'
import { matchRoute } from 'utils/mathRoute'
import { checkPackage, getPermissions, getPermissionMenus, getPermissionActiveMenu } from 'utils/app.utils'
import { selectMe } from 'app/slice/user.slice'
import { expired } from 'app/slice/expired.slice'
import { KEY_STORAGE } from 'constants/common'

/** STYLES */
import { styled } from '@mui/material/styles'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded'
import 'component/SlideMenu/Style.css'

const MenuFirstLevel = styled('div')<{ expiredAlert: boolean }>(({ theme, expiredAlert }) => ({
  width: '100%',
  top: expiredAlert ? `calc(${scale.navbarHeight} + ${scale.expiredAlertHeight})` : scale.navbarHeight,
  zIndex: 1200,
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    maxWidth: `calc(${scale.sidebarSize} - 3px)`
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: scale.sidebarSize
  },
  [theme.breakpoints.down('xl')]: {
    top: expiredAlert ? `calc(calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 1rem)` : `calc(${scale.navbarHeight} - 1rem)`
  },
  [theme.breakpoints.down('lg')]: {
    top: expiredAlert ? `calc(calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 1.5rem)` : `calc(${scale.navbarHeight} - 1.5rem)`,
    maxWidth: 0,
    '& .menu-first-level .flex-name': {
      paddingLeft: '.5rem !important',
      paddingRight: '.5rem !important'
    },
    '& .menu-first-level .flex-name .menu-first-text': {
      paddingLeft: '.5rem !important'
    },
    '& .menu-first-level .flex-name .menu-first-icon::before': {
      background: 'transparent'
    }
  },
  [theme.breakpoints.down('sm')]: {
    top: expiredAlert ? `calc(calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 2.5rem)` : `calc(${scale.navbarHeight} - 2.5rem)`,
  },
  '&.show': {
    maxWidth: '45%',
    overflow: 'unset !important',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '30%'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '248px'
    }
  },
  '.menu-first-level': {
    '.flex-name': {
      '&:hover, &.active': {
        backgroundColor: colors.themeSecondColor06,
        '.menu-first-text': {
          color: colors.themeSecondColor
        }
      },
      '&.menu-first-arrow': {
        '&::after': {
          color: colors.themeSecondColor
        }
      },
      svg: {
        width: 24,
        height: 24,
        [theme.breakpoints.up('lg')]: {
          width: 27,
          height: 27
        },
        [theme.breakpoints.up('xl')]: {
          width: 30,
          height: 30
        }
      }
    },
    '.menu-first-icon': {
      '&.active': {
        '&::before': {
          backgroundColor: colors.themeSecondColor15
        }
      }
    }
  }
}))

const MenuSecondLevel = styled('div')(({ theme }) => ({
  '&.active': {
    width: '100%',
    // height: '100%',
    maxWidth: '248px',
    [theme.breakpoints.down('lg')]: {
      '.menu-second-level .item-second-list': {
        padding: '0.406rem 0.5rem !important'
      }
    }
  },
  '.menu-second-level': {
    '.item-second-list': {
      '&:hover, &[aria-expanded="true"]': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  },
}))

const MenuThirdLevel = styled('div')(({ theme }) => ({
  '.menu-third-level': {
    '.item-third-list': {
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

const MenuScroll = styled('div')<{ expiredAlert: boolean }>(({ theme, expiredAlert }) => ({
  maxHeight: expiredAlert ? `calc(100vh - calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}))` : `calc(100vh - ${scale.navbarHeight})`,
  [theme.breakpoints.down('xl')]: {
    maxHeight: expiredAlert ? `calc(100vh - (calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 1rem))` : `calc(100vh - (${scale.navbarHeight} - 1rem))`
  },
  [theme.breakpoints.down('lg')]: {
    maxHeight: expiredAlert ? `calc(100vh - (calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 1.5rem))` : `calc(100vh - (${scale.navbarHeight} - 1.5rem))`,
  },
  [theme.breakpoints.down('sm')]: {
    maxHeight: expiredAlert ? `calc(100vh - (calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 2.5rem))` : `calc(100vh - (${scale.navbarHeight} - 2.5rem))`
  }
}))

const MenuFirstLevelScroll = styled(MenuScroll)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    paddingBottom: '0rem !important'
  }
}))

const SidebarBackdrop = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    '&.show': {
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 1199
    }
  }
}))

const CustomBadge = styled(Badge)<{ badgeContent?: string | number }>(({ theme, badgeContent }) => ({
  '.MuiBadge-badge': {
    backgroundColor: colors.themeThirdColor,
    fontSize: 9,
    minWidth: 'unset',
    width: (badgeContent && (parseInt(String(badgeContent)) >= 10 ? "18px" : "14px")) || "0",
    height: 14,
    padding: 0
  }
}))

export default function Sidebar() {
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const expire = useSelector(expired)
  const menus = getPermissionMenus()
  const menuRef: any = useRef(null)
  const [hover, setHover]: any[] = useState(false)
  const [toggleLevel2, setToggleLevel2] = useState(0)
  const [toggleLevel3, setToggleLevel3]: any[] = useState([])
  const [activeMenuLevel1, setActiveMenuLevel1] = useState<string>('');
  const [secondMenuMaxHeight, setSecondMenuMaxHeight] = useState<number>(0);
  const menuEllipsis = ['TEMPLATE_DOCTOR_FEE', 'XRAY_FILM_TYPE', 'TREATMENT_PLAN_TYPE',
    'PRODUCT_PRESERVE', 'SERVICE_FACTOR', 'EXPERT', 'REPORT_PERMISSION', 'TREATMENT_COURSE_TYPE',
    'DOCTOR_NOTE_TYPE_FORM', 'DOCTOR_NOTE_TYPE_PIC', 'DOCTOR_NOTE_PIC', 'APPOINTMENT_DURATION',
    'TEMPLATE_CREDIT_FEE', 'CREDIT_FEE', 'MATERIAL_TYPE', "MATERIAL_INSTRUCTION", "MATERIAL_PRESERVE", "MATERIAL_UNIT", "VOUCHER_TYPE"
  ]

  const onToggle = (index: string) => {
    setToggleLevel3({ [index]: toggleLevel3[index] === undefined ? true : !toggleLevel3[index] })
  }

  const checkActiveMenu = useCallback(() => {
    const activeMenu = getPermissionActiveMenu()
    if (activeMenu?.menuSecondId) onToggle(`menu-second-${activeMenu.menuSecondId}`)
  }, [])

  useEffect(() => {
    checkActiveMenu()
  }, [checkActiveMenu])

  const goToRoute = (route: string, permission: any) => {
    if (route === '/check-in') {
      const url = window.location.origin
      const urlCheckin = `${url}/check-in`;
      return window.open(urlCheckin, '_blank')
    }
    const permissions = { ...getPermissions(), activeMenu: permission }
    localStorage.setItem(KEY_STORAGE.PERMISSION, JSON.stringify(permissions))
    return (window.location.href = route || '#')
  }

  const isToggleLevel1 = (id: number) => {
    setToggleLevel2(id)
  }

  const hideSidebar = () => {
    setHover(false)
    setToggleLevel2(0)
    setToggleLevel3([])
  }

  const pathname = window.location.pathname
  const activePath = useCallback(() => {
    const preName = pathname.substring(1).split('/')
    if (preName) {
      const name = preName[0]
      setActiveMenuLevel1(matchRoute(name))
    }
  }, [pathname])

  useEffect(() => {
    activePath()
  }, [activePath]);

  useEffect(() => {
    if (toggleLevel2 > 0) {
      const height = menuRef?.current?.offsetHeight
      const menuOffsetTop = document.getElementById(`secondMenu-${toggleLevel2}`)?.offsetTop
      const value = (height && menuOffsetTop) && height - menuOffsetTop || 0
      setSecondMenuMaxHeight(value)
    }
    else setSecondMenuMaxHeight(0)
  }, [toggleLevel2]);

  return (
    <>
      <MenuFirstLevel
        ref={menuRef}
        expiredAlert={expire}
        className={`menu-first ${hover ? 'show' : ''}`}
        // onMouseEnter={() => setHover(true)}
        onClick={() => setHover(true)}
        onMouseLeave={() => {
          // if (toggleLevel2 <= 10)
          hideSidebar()
        }}>
        <MenuFirstLevelScroll expiredAlert={expire} className={'menu-first-wrap'}>
          {_.map(menus, ({ menuFirstId, menuFirstKey, menuSeconds: mSub }: any, iMFrist: number) => {
            if (menuFirsts[menuFirstKey] && checkPackage(menuFirsts[menuFirstKey].packageKey))
              return (
                menuFirstId && <>
                  {menuFirsts[menuFirstKey] && mSub.length && (
                    <>
                      <Nav.Item
                        key={`menu-first-${menuFirstId}`}
                        aria-controls={menuFirstId}
                        id={menuFirstId}
                        className={`flex-row menu-first-level ${menuFirsts[menuFirstKey].key === 'APPLICATION' ? (((checkPackage(PACKAGE_ADDONS.ADDON_REGISTER_APP) || checkPackage(PACKAGE_ADDONS.ADDON_PROMOTIONS) || checkPackage(PACKAGE_ADDONS.ADDON_NEWS)) && 'd-flex') || 'd-none') : 'd-flex'}`}
                        onClick={() => isToggleLevel1(Number(menuFirstId))}
                      // onMouseEnter={() => isToggleLevel1(Number(menuFirstId))}
                      >
                        <div className={`flex-name menu-first-arrow ${toggleLevel2 === menuFirstId ? 'active' : ''}`}>
                          <CustomBadge badgeContent={10} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                            <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                              {menuFirsts[menuFirstKey].icon}
                            </IconButton>
                          </CustomBadge>
                          <div className="menu-first-text text-ellipsis">
                            <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                          </div>
                        </div>

                        {menuFirsts[menuFirstKey] && mSub.length && (
                          <>
                            <MenuSecondLevel
                              id={`secondMenu-${menuFirstId}`}
                              className={`menu-second ${toggleLevel2 === menuFirstId ? 'active' : ''}`}
                              sx={{ top: menuFirstKey === 'SETTING' ? '-1px' : 'auto' }}>
                              <MenuScroll expiredAlert={expire} className={'menu-second-wrap'} sx={{ maxHeight: secondMenuMaxHeight ? `${secondMenuMaxHeight}px !important` : '' }}>
                                {menuFirsts[menuFirstKey] &&
                                  mSub &&
                                  _.map(mSub, ({ menuSecondId, menuSecondKey, menuSecoundTags, menuThirds: mThirds }: any, iMSub: number) => {
                                    if (menuSeconds[menuSecondKey] && checkPackage(menuSeconds[menuSecondKey].packageKey))
                                      return (menuSeconds[menuSecondKey] && mThirds.length) && (
                                        <Nav.Item key={`menu-second-${menuSecondId}`} id={menuSecondId} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`}>
                                          <Button
                                            className={`item-second-list text-ellipsis w-100`}
                                            onClick={() => onToggle(`menu-second-${menuSecondId}`)}
                                            aria-controls={`menu-second-${menuSecondId}`}
                                            aria-expanded={toggleLevel3[`menu-second-${menuSecondId}`]}
                                            endIcon={toggleLevel3[`menu-second-${menuSecondId}`] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>
                                            <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} ellipsis={menuSecondKey === 'MANAGE_DOCTOR_FEE' || menuSecondKey === 'DEFAULT_PRODUCT'} />
                                          </Button>
                                          <Collapse in={toggleLevel3[`menu-second-${menuSecondId}`]}>
                                            <MenuThirdLevel id={`menu-second-${menuSecondId}`} className="menu-third">
                                              <ul className={'menu-third-level'}>
                                                {_.map(mThirds, ({ menuThirdId, menuThirdKey, menuThirdTags }: any, iMThirds: number) => {
                                                  if (menuThirds[menuThirdKey] && checkPackage(menuThirds[menuThirdKey].packageKey))
                                                    return (
                                                      menuThirds[menuThirdKey] && (
                                                        <Nav.Item key={`menu-third-${menuThirdId}`} className={`item-third-list ${menuThirdKey === 'CURRENCY' && 'd-none'}`} onClick={() => goToRoute(menuThirds[menuThirdKey].path, mThirds[iMThirds])}>
                                                          <li className={'w-100 text-ellipsis'} onClick={() => setToggleLevel3}>
                                                            <TextEllipsis
                                                              title={t(menuThirds[`${menuThirdKey}`].textName)}
                                                              ellipsis={menuEllipsis.includes(menuThirdKey)} />
                                                          </li>
                                                        </Nav.Item>
                                                      )
                                                    )
                                                })}
                                              </ul>
                                            </MenuThirdLevel>
                                          </Collapse>
                                        </Nav.Item>
                                      ) || (
                                          menuSeconds[menuSecondKey] && (
                                            <Nav.Item key={`menu-second-${menuSecondId}`} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`} onClick={() => goToRoute(menuSeconds[menuSecondKey].path, mSub[iMSub])}>
                                              <div className={'item-second-list text-ellipsis'}>
                                                <CustomBadge
                                                  badgeContent={10}
                                                  max={10}
                                                  color="error"
                                                  className="menu-badge w-100"
                                                  invisible={!menuSeconds[menuSecondKey].badge}
                                                  sx={{ '.MuiBadge-badge:not(.MuiBadge-invisible)': { position: 'relative', top: 5, left: 8, right: 0, bottom: 0, transform: 'none' } }}>
                                                  <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} />
                                                </CustomBadge>
                                              </div>
                                            </Nav.Item>
                                          )
                                        )
                                  })}
                              </MenuScroll>
                            </MenuSecondLevel>
                          </>
                        ) || (
                            <></>
                          )}
                      </Nav.Item>
                    </>
                  ) || (
                      menuFirsts[menuFirstKey] &&
                      !menuFirsts[menuFirstKey].disabled && (
                        (menuFirsts[menuFirstKey].key === 'CLINIC_MANAGEMENT' || menuFirsts[menuFirstKey].key === 'CUSTOM') && (
                          user?.isAdmin === '1' && (
                            <Nav.Item
                              key={`menu-first-${menuFirstId}`}
                              className={`d-flex flex-row menu-first-level`}
                              onClick={() => goToRoute(menuFirsts[menuFirstKey].path, menus[iMFrist])}
                            // onMouseEnter={() => isToggleLevel1(Number(0))}
                            >
                              <div className={'flex-name'}>
                                <CustomBadge badgeContent={1} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                                  <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                    {menuFirsts[menuFirstKey].icon}
                                  </IconButton>
                                </CustomBadge>
                                <div className="menu-first-text text-ellipsis">
                                  <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                                </div>
                              </div>
                            </Nav.Item>
                          ) || <></>
                        ) ||
                        <Nav.Item
                          key={`menu-first-${menuFirstId}`}
                          className={`d-flex flex-row menu-first-level`}
                          onClick={() => goToRoute(menuFirsts[menuFirstKey].path, menus[iMFrist])}
                        // onMouseEnter={() => isToggleLevel1(Number(0))}
                        >
                          <div className={'flex-name'}>
                            <CustomBadge badgeContent={1} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                              <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                {menuFirsts[menuFirstKey].icon}
                              </IconButton>
                            </CustomBadge>
                            <div className="menu-first-text text-ellipsis">
                              <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                            </div>
                          </div>
                        </Nav.Item>
                      )
                    )
                  }
                  {
                    menuFirsts[menuFirstKey].position === 'FRONTEND' && (
                      <Divider
                        className="mx-0 my-2 d-none"
                        sx={{
                          backgroundColor: 'transparent',
                          opacity: 1,
                          borderColor: `${colors.lightGray} !important`,
                          '&:last-of-type': {
                            display: 'block !important'
                          }
                        }}
                      />
                    )
                  }
                </>
              )
          })}
        </MenuFirstLevelScroll>
      </MenuFirstLevel >
      {toggleLevel2 > 0 && <SidebarBackdrop className={`sidebar-backdrop-desktop ${toggleLevel2 > 0 ? 'show' : ''}`} onClick={hideSidebar}></SidebarBackdrop>}
    </>
  )
}
