import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { clinicTheme } from 'api/api'

/** Contain */
import { KEY_STORAGE } from 'constants/common'

/** API */
import PaymentApi from 'api/counter/payment/payment.api'
import TreatmentsAPI from 'api/dentists/treatment.api'
import { PaymentDetail as PaymentDetailType, PaymentDetailOrderProduct, PaymentDetailOrderTreatment } from 'api/counter/payment/payment-interface'

/** COMPONENT */
import { routeName } from 'routes/routes-name'
import { ButtonPrintBill } from 'features/finance/payment/payment-list'
import PopupEditPayment from 'features/finance/payment/component/popupEditPayment/Index'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import DefaultPagination from 'component/Pagination/DefaultPagination'
import Loading from 'component/Loading'
import iconDetail from 'assets/icon/IconDetail.svg'

/** UTILS & CONSTANT */
import { showModal } from 'app/slice/modal.slice'
import { colors } from 'constants/theme'
import UseWindowSize from 'utils/useWindowSize'
import { dateToView } from 'utils/date.utils'
import { numberToCurrencyFormat, checkPackage, getBranch, getCrm } from 'utils/app.utils'
import { PACKAGE_ADDONS } from 'constants/package'

/** STYLE */
import * as UseStyled from 'features/finance/payment/style/payment-style'
import CancelPayment from 'features/finance/payment/component/cancel-payment'
import { setPaymentByKey, setSummery, setSummerySubByKey } from 'app/slice/payment.slice'
import { selectMe } from 'app/slice/user.slice'
import PopupPayment from './component/popupPayment/Index'
import { styled } from '@mui/material/styles'
import { Icons } from 'constants/images'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { swalCustom, swalWarning } from 'component/Alert/Swal'
import OverdueApi from 'api/finances/overdue.api'
import { setTreatmentByKey } from 'app/slice/treatment.slice'
import { Box } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import { ApprovalSelectorCustom } from 'features/approvel-list/approvel-list'
import IconHistoryTreatment from 'assets/icon/iconHistoryTreatment'
import ApprovalApi, { ApprovalCancelReceipt, ApprovalLinkProps, ApprovalStatus } from 'api/approval/approval.api'

const DropdownMenu = styled(Dropdown.Menu)(({ theme }) => ({}))
const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
  color: colors.textPrimary,
  '&:hover': {
    backgroundColor: colors.themeSecondColor06,
    color: colors.themeSecondColor
  }
}))

export default function PaymentDetail(props: any) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(selectMe)
  const queryParams: any = new URLSearchParams(window.location.search)
  const id: number = parseInt(queryParams?.get('orderPaymentId'))
  const location: any = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [width] = UseWindowSize()
  const [sendPayment, setSendPayment] = useState(false)
  const disabledOpenPopupInfo = localStorage.getItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP)
  /** FILTER */
  const [page, setPage] = useState(1)
  const pageLimit = 5
  const [rowCount, setRowCount] = useState(0)
  /** DATA */
  const [paymentItems, setPaymentItems] = useState<any[]>([])
  const [orderPaymentId, setOrderPaymentId] = useState<number>(0)
  const [paymentDetail, setPaymentDetail] = useState<PaymentDetailType | null>(null)
  const [popupCancel, setPopupCancel] = useState<boolean>(false)
  const [patient, setPatient]: any[] = useState([])
  const [remarkPayment, setRemarkPayment] = useState('')

  const [initBalance, setInitBalance] = useState<any>({})
  const [logHistories, setLogHistories] = useState<any[]>([])

  const [approval, setApproval] = useState<boolean>(false)
  const [approvalSelect, setApprovalSelect] = useState<ApprovalStatus>(ApprovalStatus.PENDING)
  const [approvalDisabled, setApprovalDisabled] = useState<boolean>(false)
  const [approvalItemId, setApprovalItemId] = useState<number>(0)
  const [userApprovel, setUserApprovel] = useState<{ fullname: string, date: string } | undefined>(undefined)
  const [checkApprovalPermiss, setCheckApprovalPermiss] = useState<boolean>(false)

  const [outToken, setOutToken] = useState<boolean>(false)
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const loadCheckApprovePermiss = async () => {
    await ApprovalApi.checkApprovePermiss().then(({ data }) => {
      if (data?.hasCancelReciept) {
        setCheckApprovalPermiss(true)
      }
    })
  }

  useEffect(() => {
    if (!params.get('id')) {
      loadCheckApprovePermiss()
    }
  }, [])

  const loadPaymentApproval = async () => {
    if (params.get('id') && typeof params.get('id') === 'string') {
      setLoading(true)
      await ApprovalApi.findApprovalPayment(String(params.get('id'))).then(({ data }) => {
        const resData = data
        if (resData?.approvalItems) {
          const checkDis = (resData?.approvalItems?.status === "APPROVED" || resData?.approvalItems?.status === "REJECT" || resData?.approvalItems?.status === "CANCEL")
          setApprovalSelect(resData?.approvalItems?.status)
          setApprovalDisabled(checkDis)
          if (checkDis && resData?.approvalItems?.approvedBy) {
            setUserApprovel({ fullname: resData?.approvalItems?.approvedBy, date: resData?.approvalItems?.approvedAt })
            swalCustom(
              t(`APPROVELLIST.MESSAGE.IS_APPROVED_TITLE`),
              t(`APPROVELLIST.MESSAGE.IS_APPROVED_SUB`),
              () => history.push(routeName.login),
              'warning',
              { cancelButton: { showCancelButton: false }, showCloseButton: false }
            )
            return
          }
        }

        if (location?.state?.dataOrderPayment) setPaymentDetail({ ...resData, dataOrderPayment: location?.state?.dataOrderPayment })
        else setPaymentDetail({ ...resData })

        if (data?.order) {
          const temp: any[] = [..._.map(data?.order.orderTreatments, (op) => ({ type: 'treatment', ...op })), ..._.map(data?.order.orderProducts, (pd) => ({ type: 'product', ...pd }))]

          setPaymentItems(temp)
          setRowCount(temp.length)
        }
      }).finally(() => setLoading(false))
    }

  }

  const loadLocation = () => {
    if (location?.search && params.get('id')) {
      setOutToken(true)
      loadPaymentApproval()
      return
    }
    if (location.state) {
      const { orderPaymentId: id, orderPaymentChannelText: remark, approval, approvalItemId }: any = location.state
      setOrderPaymentId(id)
      setRemarkPayment(remark)
      setApproval(approval || false)
      setApprovalItemId(approvalItemId)
    } else if (id) {
      setOrderPaymentId(id)
    }
  }

  useEffect(() => {
    loadLocation()
  }, [location])

  const onPaidOverdule = async (orderId: number, valueOrderPaymentId: number) => {
    const resp = await OverdueApi.findDetailPayment(orderId, valueOrderPaymentId)

    if (resp.status === 200) {
      const summeryProducts: any = []
      if (!_.isEmpty(resp.data.orderOrderProducts)) {
        _.map(resp.data.orderOrderProducts, (prod, index) => {
          summeryProducts.push({ ...prod, total: prod.price * prod.qty - prod.discount + prod.additional })
        })
      }
      const summeryTreatments: any[] = [...resp.data.orderOrderTreatments, ...summeryProducts]
      setOrderPaymentId(resp.data.orderPaymentId)
      const items: any = {}
      const histories: any[] = _.map(resp.data.history, (his: any) => {
        const itemPaids: any = {}
        const paidHistory: any = []
        let amountItemPaid: any = 0
        const paidItems: any[] = _.map(his.orderPaymentChannels, (channel: any) => {
          // let abc: any = []
          return _.map(channel.orderPaymentItems, (payItem: any, i: number) => {
            if (items[payItem.refItemId] === undefined) items[payItem.refItemId] = 0
            items[payItem.refItemId] = items[payItem.refItemId] + payItem.amountPaid

            if (!paidHistory[i]) paidHistory[i] = payItem.amountPaid
            amountItemPaid += payItem.amountPaid

            if (itemPaids[payItem.refItemType] === undefined) itemPaids[payItem.refItemType] = {}
            if (itemPaids[payItem.refItemType][payItem.refItemId] === undefined) itemPaids[payItem.refItemType][payItem.refItemId] = 0
            itemPaids[payItem.refItemType][payItem.refItemId] = itemPaids[payItem.refItemType][payItem.refItemId] + payItem.amountPaid

            return {
              refItemId: payItem.refItemId,
              refItemType: payItem.refItemType,
              itemName: payItem.itemName,
              amountPaid: payItem.amountPaid
            }
          })
        })

        return {
          createdAt: his.createdAt,
          createdBy: his.createdBy,
          amountPaid: parseFloat(his.amountPaid) + parseFloat(his.amountAdvance),
          paidItems,
          itemPaids,
          paidHistory,
          amountItemPaid,
          itemBalances: _.map(summeryTreatments, (st: any) => {
            const type = st.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
            const refId = st.orderTreatmentId || st.orderProductId
            let paid = 0
            if (itemPaids[type] !== undefined && itemPaids[type][refId] !== undefined) paid = itemPaids[type][refId]

            return {
              itemCode: st.operativeCode || st.itemCode || '',
              itemName: st.operativeName || st.itemName || '',
              total: st.total,
              paid: paid
            }
          })
        }
      })
      setLogHistories(histories)
      const balanceTemp: any = {}
      _.map(Object.keys(items), (itemId: any) => {
        const index: any = _.findIndex(summeryTreatments, (st: any) => String(st.orderTreatmentId || st.orderProductId) === String(itemId))

        if (balanceTemp[index] === undefined) balanceTemp[index] = 0
        balanceTemp[index] = histories[histories?.length - 1]?.itemBalances[index]?.total - items[itemId]
      })
      setInitBalance(balanceTemp)

      const arrNoPc = [0, 5, 6, 1, 2, 3, 4, 7]
      const allItem = [...(paymentDetail?.order?.orderTreatments || []), ...(paymentDetail?.order?.orderProducts || [])]
      for (const keyPcId of arrNoPc) {
        for (const index in allItem) {

          if (Object.prototype.hasOwnProperty.call(allItem, index)) {
            const summaryTreatment: any = allItem[index]
            let id = null
            if (summaryTreatment.orderTreatmentId) id = summaryTreatment.orderTreatmentId
            else if (summaryTreatment.orderProductId) id = summaryTreatment.orderProductId

            const isTreatment = summaryTreatment.orderTreatmentId || summaryTreatment.operativeId ? true : false
            const type = isTreatment ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'

            let pay = 0
            let payEdit = 0
            if (histories) {
              pay = _.sum(
                _.chain(histories)
                  .map((his, id) => his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'] ? his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'][summaryTreatment?.orderTreatmentId || summaryTreatment?.orderProductId] : 0)
                  .value()
              ) || 0
              const newEdit = [histories[histories.length - 1]]
              payEdit = _.sum(
                _.chain(newEdit)
                  .map((his, id) => his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'] ? his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'][summaryTreatment?.orderTreatmentId || summaryTreatment?.orderProductId] : 0)
                  .value()
              )
            }
            dispatch(setSummerySubByKey({ key: keyPcId, keySub: index, value: { isCheck: false, value: 0, treatmentPrice: summaryTreatment.total - (pay || 0) + (payEdit || 0), paidBalance: summaryTreatment.total - (pay || 0), type: type, id: id, isDisabled: true } }))

          }
        }
      }
    }

    setSendPayment(true)
  }

  const loadPaymentDetail = (id: number) => {
    setLoading(true)
    PaymentApi.detail(id)
      .then(({ data }) => {
        const resData = data
        if (resData?.approvalItems) {
          const checkDis = (resData?.approvalItems?.status === "APPROVED" || resData?.approvalItems?.status === "REJECT" || resData?.approvalItems?.status === "CANCEL")
          setApprovalSelect(resData?.approvalItems?.status)
          setApprovalDisabled(checkDis)
          if (checkDis && resData?.approvalItems?.approvedBy) {
            setUserApprovel({ fullname: resData?.approvalItems?.approvedBy, date: resData?.approvalItems?.approvedAt })
          }
        }

        if (location?.state?.dataOrderPayment) setPaymentDetail({ ...resData, dataOrderPayment: location?.state?.dataOrderPayment })
        else setPaymentDetail({ ...resData })

        if (data?.order) {
          const temp: any[] = [..._.map(data?.order.orderTreatments, (op) => ({ type: 'treatment', ...op })), ..._.map(data?.order.orderProducts, (pd) => ({ type: 'product', ...pd }))]

          setPaymentItems(temp)
          setRowCount(temp.length)
        }
        setLoading(false)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    orderPaymentId && loadPaymentDetail(orderPaymentId)
  }, [orderPaymentId])
  useEffect(() => {
    if (sendPayment) {
      if (paymentDetail?.orderPaymentChannels) {
        const orderPaymentChannels = _.reverse(paymentDetail?.orderPaymentChannels)
        dispatch(setSummery({}))
        const sum: any = {}
        const useChannel: any = { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
        for (const keyPayChannel in orderPaymentChannels) {
          if (Object.prototype.hasOwnProperty.call(orderPaymentChannels, keyPayChannel)) {
            const orderPaymentChannel = orderPaymentChannels[keyPayChannel]
            sum[orderPaymentChannel.paymentChannelId] = orderPaymentChannel.amountPaid
            useChannel[orderPaymentChannel.paymentChannelId] = true
          }
        }
        dispatch(setPaymentByKey({ key: 'prefix', value: paymentDetail.prefix }))
        dispatch(setPaymentByKey({ key: 'orderPaymentChannels', value: useChannel }))
        dispatch(setSummery(sum))
      }

    }
  }, [sendPayment])

  const onCancel = async () => {
    try {
      await PaymentApi.validate(orderPaymentId)
    } catch (error: any) {
      if (error?.response?.data?.message === 'CANT_UPDATE_ORDER_PAYMENT') {
        swalWarning('', t(`PAYMENT_DETAIL.MESSAGE.SUCCESS.WARNING_NOT_CANCEL`))
      } else {
        swalWarning('', t(`PAYMENT_DETAIL.MESSAGE.SUCCESS.WARNING_ERROR`))
      }
      return
    }
    dispatch(showModal())
    setPopupCancel(true)
  }
  const onReset = () => {
    setLoading(true)
    PaymentApi.resetPayment(orderPaymentId)
      .then((res) => {
        notiSuccess(t(`PAYMENT_DETAIL.MESSAGE.SUCCESS.RESSET`))
        loadPaymentDetail(orderPaymentId)
        setLoading(false)
      })
      .catch((err) => {
        const error_message = err.response.data.message
        if (error_message === 'CANT_CANCEL_PAYMENT_WRONG_ORDER' || error_message === 'TREATMENT_RIGHT_NOT_ENOUGH' || error_message === 'ADVANCE_NOT_ENOUGH') {
          notiError(t(`PAYMENT_DETAIL.BILL_RESET.${error_message}`))
        } else {
          notiError(t(`PAYMENT_DETAIL.BILL_RESET.NOT_FOUND_ORDER_PAYMENT`))
        }
      })
      .finally(() => setLoading(false))
  }

  const onHistoryPayment = async (paymentDetail: any) => {
    window.open(`${routeName.treatmentsEmrHistory}?patientId=${paymentDetail?.order?.patientId}&patientCaseId=${paymentDetail?.order?.patientCaseId}`, '_blank')
  }

  const onEdit = async (paymentDetail: any) => {
    try {
      await PaymentApi.validate(paymentDetail.orderPaymentId)
    } catch (error: any) {
      if (error?.response?.data?.message === 'CANT_UPDATE_ORDER_PAYMENT') {
        swalWarning('', t(`PAYMENT_DETAIL.MESSAGE.SUCCESS.WARNING_NOT_CANCEL`))
      } else {
        swalWarning('', t(`PAYMENT_DETAIL.MESSAGE.SUCCESS.WARNING_ERROR`))
      }
      return
    }
    const patientData = await TreatmentsAPI.findPatient(paymentDetail.order.patientId)
    setPatient(patientData.data)
    onPaidOverdule(paymentDetail.orderId, paymentDetail.orderPaymentId)
    // setSendPayment(true)
  }

  const headCellsTooth = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'teeth', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.TOOTH'), class: 'text-nowrap' }] : []

  const headCells = [
    { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
    { id: 'itemTypeName', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.TYPE'), class: 'text-nowrap' },
    { id: 'itemName', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.ITEM_NAME'), class: 'text-nowrap' },
    ...headCellsTooth,
    { id: 'qty', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.QTY'), class: 'text-nowrap', align: 'center' },
    { id: 'price', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.PRICE'), class: 'text-nowrap text-right' },
    { id: 'discount', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.DISCOUNT'), class: 'text-nowrap text-right' },
    { id: 'discount', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.ADDITIONAL'), class: 'text-nowrap text-right' },
    { id: 'total', disablePadding: false, label: t('PAYMENT_DETAIL.TABLE.TOTAL'), class: 'text-nowrap text-right px-3' }
  ]

  const sumDiscount = (op: any, no: number) => {
    const { discountUnit, price, qty, discount, dfUnit, dfAmount, cfAmount } = op
    const oriPrice = price * qty
    let result = 0
    const mainDiscount = discountUnit === 'PERCENT' ? (price * qty * discount) / 100 : discount
    if (mainDiscount) result += mainDiscount
    const dfOri = dfUnit === 'PERCENT' ? (oriPrice - mainDiscount) * (dfAmount / 100) : dfAmount
    const dfDiscount = op.dfDiscountUnit === 'PERCENT' ? dfOri * (op.dfDiscountAmount / 100) : op.dfDiscountAmount
    if (dfDiscount) result += dfDiscount
    const cfOri = dfUnit === 'PERCENT' ? (oriPrice - mainDiscount) * (cfAmount / 100) : cfAmount
    const cfDiscount = op.cfDiscountUnit === 'PERCENT' ? cfOri * (op.cfDiscountAmount / 100) : op.cfDiscountAmount
    if (cfDiscount) result += cfDiscount
    return result
  }

  const renderData = (objData: any, no: number) => {
    const op: PaymentDetailOrderTreatment | undefined = objData.type === 'treatment' ? objData : undefined
    const pd: PaymentDetailOrderProduct | undefined = objData.type === 'product' ? objData : undefined

    no = no + 1
    const { orderPaymentId: id } = objData
    const itemTypeName: any = op?.operativeTypeName || pd?.itemTypeName || ''
    const itemName: any = op?.operativeName || pd?.itemName || ''
    const teeth: any = op?.teeth || ''
    const qty: any = op?.qty || pd?.qty || ''
    const price: any = numberToCurrencyFormat(Number(op?.price || pd?.price || 0))
    const discount: any = numberToCurrencyFormat(Number(op !== undefined ? sumDiscount(op, no) : pd?.discount || 0))
    const additional: any = numberToCurrencyFormat(Number(op?.additional || pd?.additional || 0))
    const total: any = numberToCurrencyFormat(Number(op?.total || pd?.total || 0))
    const objRenderData = {
      key: String(no),
      id: String(id),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: itemTypeName, class: '--text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: itemName, class: '--text-nowrap-ellipsis' },
        clinicTheme.theme === clinicTheme.type.DENTAL && { option: 'TEXT', align: 'left', label: teeth, class: '--text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'center', label: qty, class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: price, class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: discount, class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: additional, class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: total, class: 'text-nowrap-ellipsis' }
      ]
    }
    return <TableRowCommon {...objRenderData} onClick={(e, val) => (!approval && !outToken) && history.push(routeName.paymentDetail, val)} />
  }

  const renderDataSlim = (objData: any, no: number) => {
    const op: PaymentDetailOrderTreatment | undefined = objData.type === 'treatment' ? objData : undefined
    const pd: PaymentDetailOrderProduct | undefined = objData.type === 'product' ? objData : undefined
    no = no + 1
    const itemTypeName: any = op?.operativeTypeName || pd?.itemTypeName || ''
    const itemName: any = op?.operativeName || pd?.itemName || ''
    const teeth: any = op?.teeth || ''
    const qty: any = op?.qty || pd?.qty || ''
    const price: any = numberToCurrencyFormat(Number(op?.price || pd?.price || 0))
    const discount: any = numberToCurrencyFormat(Number(op?.discount || pd?.discount || 0))
    const total: any = numberToCurrencyFormat(Number(op?.total || pd?.total || 0))
    return (
      <UseStyled.TBody>
        <div className={'d-flex align-items-center position-relative'}>
          <UseStyled.Cell>
            <Row className="pl-4 mx-0">
              <div className="position-absolute w-auto px-0" style={{ marginLeft: '-1.5rem' }}>
                {no}
              </div>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_DETAIL.TABLE.TYPE')}
              </Col>
              <Col xs={6} lg={8} style={{ wordBreak: 'break-all' }}>
                {itemTypeName}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_DETAIL.TABLE.ITEM_NAME')}
              </Col>
              <Col xs={6} lg={8} style={{ wordBreak: 'break-all' }}>
                {itemName}
              </Col>
              {clinicTheme.theme === clinicTheme.type.DENTAL && (
                <>
                  <Col xs={6} lg={4} className="font-weight-bold">
                    {t('PAYMENT_DETAIL.TABLE.TOOTH')}
                  </Col>
                  <Col xs={6} lg={8} style={{ wordBreak: 'break-all' }}>
                    {teeth}
                  </Col>
                </>
              )}
              <Col xs={6} lg={3} className="font-weight-bold">
                {t('PAYMENT_DETAIL.TABLE.QTY')}
              </Col>
              <Col xs={6} lg={3}>
                {qty}
              </Col>
              <Col xs={6} lg={3} className="font-weight-bold">
                {t('PAYMENT_DETAIL.TABLE.PRICE')}
              </Col>
              <Col xs={6} lg={3}>
                {price}
              </Col>
              <Col xs={6} lg={3} className="font-weight-bold">
                {t('PAYMENT_DETAIL.TABLE.DISCOUNT')}
              </Col>
              <Col xs={6} lg={3}>
                {discount}
              </Col>
              <Col xs={6} lg={3} className="font-weight-bold">
                {t('PAYMENT_DETAIL.TABLE.TOTAL')}
              </Col>
              <Col xs={6} lg={3}>
                {total}
              </Col>
            </Row>
          </UseStyled.Cell>
        </div>
      </UseStyled.TBody>
    )
  }

  const onGoBackApproval = () => {
    history.push(routeName.approvelList)
  }

  const onSubmitApproval = async () => {
    if (approvalSelect === 'PENDING' || approvalDisabled) {
      history.push(routeName.approvelList)
      return
    }
    swalCustom(
      t('APPROVELLIST.MESSAGE.APPROVAL_SAVE_TITLE'),
      t('APPROVELLIST.MESSAGE.APPROVAL_SAVE_SUB', { approval: t(`APPROVELLIST.FILTER.${approvalSelect}`) }),
      async (res: any) => {
        if (res) {
          setLoading(true)
          const newPayload: ApprovalCancelReceipt = {
            requestReason: paymentDetail?.approvalItems?.requestReason,
            requestUserName: paymentDetail?.approvalItems?.requestUserName,
            requestUserId: paymentDetail?.approvalItems?.requestUserId,
            approveRemark: paymentDetail?.approvalItems?.approveRemark,
            refId: paymentDetail?.approvalItems?.refId,
            branchId: getBranch(),
            status: approvalSelect
          }
          await ApprovalApi.approvalCancelReceipt(approvalItemId, newPayload).then((res) => {
            notiSuccess(t('APPROVAL.MESSAGE.SUC_CREATE'))
            history.push(routeName.approvelList)
          }).catch((e) => {
            const error = e.response?.data?.message
            const errorMessage = ["IS_APPROVED", "IS_CANCEL"]
            if (errorMessage.includes(error)) {
              swalCustom(
                t(`APPROVELLIST.MESSAGE.${error}_TITLE`),
                t(`APPROVELLIST.MESSAGE.${error}_SUB`),
                () => history.push(routeName.approvelList),
                'warning',
                { cancelButton: { showCancelButton: false }, showCloseButton: false }
              )
            } else {
              notiError(t('APPROVELLIST.MESSAGE.ERROR'))
            }
          }).finally(() => {
            setLoading(false)
          })
        }
      },
    )

  }

  const handleCheckApproved = () => {
    if (paymentDetail?.approvalItems?.status === "APPROVED") {
      return paymentDetail?.statusOrder !== "NORMAL"
    } else {
      return true
    }
  }

  const onSubmitApprovalOutToken = async (status: ApprovalStatus.APPROVED | ApprovalStatus.REJECT) => {
    if (paymentDetail?.approvalItems?.approvalItemId && params?.get('token')) {
      setLoading(true)
      const newPayload: ApprovalLinkProps = {
        approveRemark: '',
        status: status,
        approveToken: String(params.get('token'))
      }
      await ApprovalApi.approvalLink(paymentDetail?.approvalItems?.approvalItemId, newPayload).then((res) => {
        swalCustom(
          t('APPROVELLIST.MESSAGE.IS_APPROVED_SUCCESS_TITLE'),
          `<div style="width:300px" ></div>`,
          () => history.push(routeName.login),
          'warning',
          { cancelButton: { showCancelButton: false }, showCloseButton: false }
        )
      }).catch((e) => {
        const error = e.response?.data?.message
        const errorMessage = ["IS_APPROVED", "IS_CANCEL"]
        if (errorMessage.includes(error)) {
          swalCustom(
            t(`APPROVELLIST.MESSAGE.${error}_TITLE`),
            t(`APPROVELLIST.MESSAGE.${error}_SUB`),
            () => history.push(routeName.login),
            'warning',
            { cancelButton: { showCancelButton: false }, showCloseButton: false }
          )
        } else {
          notiError(t('APPROVELLIST.MESSAGE.ERROR'))
        }
      }).finally(() => setLoading(false))
    }

  }

  return (
    <div className={`h-100 container-card overflow-auto`} style={{ backgroundColor: colors.white }}>
      <Loading show={loading} type="fullLoading" />
      {!outToken &&
        <HeaderLabel
          text={t('PAYMENT_DETAIL.HEAD_LABEL.RECEIPT')}
          goBack={routeName.payment}
          navigator={approval ? undefined : { previousTo: routeName.payment, previousTitle: t('PAYMENT_DETAIL.HEAD_LABEL.PAYMENT'), currentTitle: t('PAYMENT_DETAIL.HEAD_LABEL.RECEIPT') }}
          approval={paymentDetail?.approvalItems}
          componentBtnCustom={
            approval ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: ' 16px' }}>
                {!loading &&
                  <Box>
                    <ApprovalSelectorCustom
                      approval={approvalSelect}
                      onChange={setApprovalSelect}
                      disabled={approvalDisabled}
                      userApprovel={userApprovel}
                    />
                  </Box>
                }
                <Box sx={{ width: '100px', button: { width: '100%', padding: '6px 16px', filter: 'grayscale(100%) sepia(0%) brightness(35%) contrast(120%)' } }}>
                  <ButtonCustom
                    variant={"outlined"}
                    textButton={t("BUTTON.CANCEL")}
                    onClick={onGoBackApproval}
                  />
                </Box>
                <Box sx={{ width: '100px', button: { width: '100%', padding: '6px 16px', } }}>
                  <ButtonCustom
                    textButton={t("BUTTON.SAVE")}
                    onClick={onSubmitApproval}
                  />
                </Box>
              </Box>
            )
              :
              <>
                {(!loading &&
                  paymentDetail?.approvalItems &&
                  checkApprovalPermiss &&
                  handleCheckApproved()) &&
                  <Box className={` mr-3 `}>
                    <ApprovalSelectorCustom
                      approval={approvalSelect}
                      onChange={setApprovalSelect}
                      disabled={(approvalDisabled || !approval)}
                      userApprovel={userApprovel}
                    />
                  </Box>
                }
                <Box className={` mr-3 `}>
                  <ButtonCustom
                    onClick={() => onHistoryPayment(paymentDetail)}
                    variant={"outlined"}
                    textButton={t('PAYMENT_DETAIL.HEAD_LABEL.HISTORY_PAYMENT')}
                    startIcon={<IconHistoryTreatment />}
                  />
                </Box>

                {/* {user.hasEditBill === "PERMISS" &&
                            <UseStyled.ButtonEdit
                                onClick={() => paymentDetail?.statusOrder !== 'CANCEL' && onEdit(paymentDetail)}
                                className={`${paymentDetail?.statusOrder === 'CANCEL' ? "disabled" : ''}`}>
                                {t('PAYMENT_DETAIL.HEAD_LABEL.EDIT_RECEIPT')}
                            </UseStyled.ButtonEdit>
                        }
                        <UseStyled.ButtonCancel
                            onClick={() => paymentDetail?.statusOrder !== 'CANCEL' && onCancel()}
                            className={`ml-0 mr-3 ${paymentDetail?.statusOrder === 'CANCEL' ? "disabled" : ''}`}>
                            {t('PAYMENT_DETAIL.HEAD_LABEL.CANCEL_RECEIPT')}
                        </UseStyled.ButtonCancel> */}
                <ButtonPrintBill orderPaymentId={orderPaymentId} onLoading={setLoading} isDisabled={false} />
                <Dropdown>
                  <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                    <UseStyled.ButtonHistoryPayment
                      style={{
                        minWidth: '30px',
                        padding: '6px 8px',
                        border: '1px solid #EBEBEB !important'
                      }}
                    >
                      <Icons.Meatball />
                    </UseStyled.ButtonHistoryPayment>
                  </Dropdown.Toggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => onEdit(paymentDetail)} disabled={user.hasEditBill === 'UNPERMISS' || paymentDetail?.statusOrder === 'CANCEL' || paymentDetail?.statusOrder === 'PENDING'}>
                      <span className="ml-2 align-text-bottom">{t('PAYMENT_DETAIL.HEAD_LABEL.EDIT_RECEIPT')}</span>
                    </DropdownItem>
                    <DropdownItem onClick={() => onCancel()} disabled={user.hasCancelBill === 'UNPERMISS' || paymentDetail?.statusOrder === 'CANCEL' || paymentDetail?.statusOrder === 'PENDING'}>
                      <span className="ml-2 align-text-bottom">{t('PAYMENT_DETAIL.HEAD_LABEL.CANCEL_RECEIPT')}</span>
                    </DropdownItem>
                    <DropdownItem onClick={() => onReset()} disabled={user.hasResetBill === 'UNPERMISS' || paymentDetail?.statusOrder === 'NORMAL' || paymentDetail?.statusOrder === 'PENDING'}>
                      <span className="ml-2 align-text-bottom">{t('PAYMENT_DETAIL.HEAD_LABEL.RESET_BILL')}</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
          }
        />
      }
      {outToken &&
        <>
          <Box sx={{ fontSize: '48px', fontWeight: '600', margin: '24px 0 16px 0 ', textAlign: 'center' }}>
            {t('APPROVELLIST.TITLE_OUTTOKEN_CANCELRECEIPT')}
          </Box>
          <Box sx={{ fontSize: '24px', fontWeight: '500', margin: '0 0 16px 24px ' }}>
            {t('APPROVELLIST.SUB_OUTTOKEN_CANCELRECEIPT')}
          </Box>
        </>

      }
      <Card className="border shadow-sm mx-4 mb-4">
        <Card.Body className="h-100">
          <Row className="mx-0 w-100">
            <Col xs={12} xl={4} className="px-1 col-3xl-3">
              <Row className="mx-0">
                <Col xs={6} sm={3} xl={4} xxl={4} className="px-1 mb-2">
                  <strong>{t('PAYMENT_DETAIL.LABEL.PAYMENT_NO')}</strong>
                </Col>
                <Col xs={6} sm={9} xl={8} xxl={8} className="px-1 mb-2">
                  {paymentDetail?.paymentNumber}
                </Col>
                <Col xs={6} sm={3} xl={4} xxl={4} className="px-1 mb-2">
                  <strong>{t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID')}</strong>
                </Col>
                <Col xs={6} sm={3} xl={8} xxl={8} className="px-1 mb-2">
                  {paymentDetail?.branchCnNumber || '-'}
                </Col>
                <Col xs={6} sm={2} xl={4} xxl={4} className="px-1 mb-2">
                  <strong>{t('PAYMENT_DETAIL.LABEL.PATIENT_NAME')}</strong>
                </Col>
                <Col xs={6} sm={4} xl={8} xxl={8} className="px-1 mb-2">
                  {paymentDetail?.patientFullName}
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={4} className="px-1 col-3xl-3">
              <Row className="mx-0">
                <Col xs={6} sm={2} xl={4} xxl={4} className="px-1 mb-2">
                  <strong>{t('PAYMENT_DETAIL.LABEL.DATE_RECEIPT')}</strong>
                </Col>
                <Col xs={6} sm={9} xl={8} xxl={8} className="px-1 mb-2">
                  {dateToView(paymentDetail?.dateAt)}
                </Col>
                <Col xs={6} sm={2} xl={4} xxl={4} className="px-1 mb-2">
                  <strong>{t('PAYMENT_DETAIL.LABEL.DOCTOR_NAME')}</strong>
                </Col>
                <Col xs={6} sm={9} xl={8} xxl={8} className="px-1 mb-2">
                  {paymentDetail?.doctorFullname}
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={4} className="px-1 col-3xl-3">
              <Row className="mx-0">
                <Col xs={6} sm={2} xl={4} xxl={4} className="px-1 mb-2">
                  <strong>{t('PAYMENT_DETAIL.LABEL.STAFF')}</strong>
                </Col>
                <Col xs={6} sm={8} xl={8} xxl={8} className="px-1 mb-2">
                  {paymentDetail?.createdBy}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mx-0 w-100">
            <Col sm={8} xs={12} xl={6} xxl={4} className="px-1">
              <Row className="mx-0">
                <Col xs={4} sm={4} xl={4} xxl={4} className="px-1 mb-2">
                  <strong>{t('PAYMENT_DETAIL.LABEL.PAYMENT_CHANNEL')}</strong>
                </Col>
                <Col xs={8} sm={8} xl={8} xxl={8} className="px-1 mb-2">
                  {paymentDetail?.paymentChannel || 'สิทธิการรักษา (0.00)'}
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="mt-2">
            <UseStyled.TableDetailConfig>
              {width >= 1200 ? (
                <TableCustom
                  hidePagination
                  customScroll
                  page={0}
                  pageLimit={0}
                  sortType={'ASC'}
                  sortBy={''}
                  rowCount={0}
                  onSort={() => 0}
                  setPageLimit={() => 0}
                  setPage={() => 0}
                  textEmptyData={t('REGISTER.NO_ITEM')}
                  headCells={headCells}
                  rowsData={_.map(paymentItems, (val, i) => renderData(val, i))}
                />
              ) : (
                <>
                  <UseStyled.THead>
                    <UseStyled.Cell>{t('#')}</UseStyled.Cell>
                  </UseStyled.THead>
                  <div className={'treatment-body'}>
                    {_.map(paymentItems, renderDataSlim)}
                    <div className={'mt-2 '}>
                      <DefaultPagination count={rowCount} allRow={rowCount} pageLimit={pageLimit} page={page} setPage={setPage} />
                    </div>
                  </div>
                </>
              )}
            </UseStyled.TableDetailConfig>
          </div>
          <hr className="mx-0 mb-1" />
          {paymentDetail && (
            <div className="mt-3 row mx-0">
              <Col>
                <Row className="mx-0">
                  <Col xs={'auto'} sm={'auto'}>
                    <strong>{t('PAYMENT.FORM_PAYMENT.REMARK')} : </strong>
                  </Col>
                  <Col xs={'auto'} sm={'auto'}>
                    {paymentDetail?.order?.remark}
                  </Col>
                </Row>
              </Col>
              <Col xl={6} className="px-0 ml-auto">
                <Row className="mx-0">
                  <Col xs={6} sm={7} xl={8} className="px-1">
                    <strong>{t('PAYMENT_DETAIL.TOTAL')}</strong>
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                    {numberToCurrencyFormat(Number(paymentDetail?.order?.totalPrice || 0))}
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                    {t('PAYMENT_DETAIL.BATH')}
                  </Col>

                  {getCrm() &&
                    <>
                      <Col xs={6} sm={7} xl={8} className="px-1">
                        <strong>{t('PAYMENT_DETAIL.PROMOTION')}</strong>
                      </Col>
                      <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                        {numberToCurrencyFormat(Number(paymentDetail?.promotionTotal || 0))}
                      </Col>
                      <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                        {t('PAYMENT_DETAIL.BATH')}
                      </Col>
                      <Col xs={6} sm={7} xl={8} className="px-1">
                        <strong>{t('Gift Voucher')}</strong>
                      </Col>
                      <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                        {numberToCurrencyFormat(Number(paymentDetail?.voucherTotal || 0))}
                      </Col>
                      <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                        {t('PAYMENT_DETAIL.BATH')}
                      </Col>
                    </>
                  }

                  <Col xs={6} sm={7} xl={8} className="px-1">
                    <strong>{t('PAYMENT_DETAIL.TR_PAID')}</strong> {paymentDetail?.orderPaymentChannelTreatmentRight && `(${numberToCurrencyFormat(Number(paymentDetail?.orderPaymentChannelTreatmentRight || 0))})`}
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                    {numberToCurrencyFormat(Number(paymentDetail?.orderPaymentChannelTreatmentRight || 0))}
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                    {t('PAYMENT_DETAIL.BATH')}
                  </Col>

                  {(checkPackage(PACKAGE_ADDONS.ADDON_ADVANCE) || (!checkPackage(PACKAGE_ADDONS.ADDON_ADVANCE) && Number(paymentDetail?.orderPaymentChannelAdvance || 0) > 0)) && (
                    <>
                      <Col xs={6} sm={7} xl={8} className="px-1">
                        <strong>{t('PAYMENT_DETAIL.ADVANCE_PAID')}</strong>
                      </Col>
                      <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                        {numberToCurrencyFormat(Number(paymentDetail?.orderPaymentChannelAdvance || 0))}
                      </Col>
                      <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                        {t('PAYMENT_DETAIL.BATH')}
                      </Col>
                    </>
                  )}

                  <Col xs={6} sm={7} xl={8} className="px-1">
                    <strong>{t('PAYMENT_DETAIL.PRICE_PAID')}</strong>
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                    {numberToCurrencyFormat(Number(paymentDetail?.totalPrice || 0) - Number(paymentDetail?.orderPaymentChannelTreatmentRight || 0) - Number(paymentDetail?.orderPaymentChannelAdvance || 0))}
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                    {t('PAYMENT_DETAIL.BATH')}
                  </Col>

                  <div className="col-12 px-0">
                    <hr className="m-1" />
                  </div>

                  <Col xs={6} sm={7} xl={8} className="px-1">
                    <strong>{t('PAYMENT_DETAIL.PAID')}</strong>
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                    {numberToCurrencyFormat(Number(paymentDetail?.amountPaid + paymentDetail?.amountChange || 0))}
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                    {t('PAYMENT_DETAIL.BATH')}
                  </Col>

                  <Col xs={6} sm={7} xl={8} className="px-1">
                    <strong>{t('PAYMENT.FORM_PAYMENT.SUM_NET_CHANGE')}</strong>
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                    {numberToCurrencyFormat(Number(paymentDetail?.amountChange || 0))}
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                    {t('PAYMENT_DETAIL.BATH')}
                  </Col>

                  <Col xs={6} sm={7} xl={8} className="px-1">
                    <strong>{t('PAYMENT_DETAIL.OVERDUE')}</strong>
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={3} className="px-1 ml-auto text-right">
                    {numberToCurrencyFormat(Number(paymentDetail?.overdue || 0))}
                  </Col>
                  <Col xs={'auto'} sm={'auto'} xl={1} className="px-1 text-right">
                    {t('PAYMENT_DETAIL.BATH')}
                  </Col>
                </Row>
              </Col>
            </div>
          )}
        </Card.Body>
      </Card>
      {/* {sendPayment &&
                <PopupEditPayment
                    isShow={sendPayment}
                    setIsShow={(show: boolean) => setSendPayment(show)}
                    patient={patient}
                    detail={paymentDetail}
                    onSuccess={() => {
                        setSendPayment(false)
                        history.push(routeName.payment)
                        const val = disabledOpenPopupInfo ? JSON.parse(disabledOpenPopupInfo) : []
                        const newValueClosePopup = _.filter(val, (v) => Number(v) !== Number(paymentDetail?.order.patientId))
                        localStorage.setItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP, JSON.stringify(newValueClosePopup))
                    }} />
            } */}
      {(paymentDetail?.statusOrder === 'CANCEL' || paymentDetail?.approvalItems?.status === ApprovalStatus.PENDING || (approval && paymentDetail?.approvalItems?.status === ApprovalStatus.REJECT)) && (
        <div className="mx-4 mb-4">
          <Row className="ml-2 mb-1">
            <Col className="d-flex">
              <strong className="text-nowrap">
                <span style={{ color: '#FB5252' }}>{t('PAYMENT_DETAIL.CANCEL_REMARK')}</span> :{' '}
              </strong>
              <Box
                sx={{
                  wordWrap: 'break-word',
                  width: { xs: '100%', md: '50vw' },
                  overflow: 'hidden'
                }}
              >
                {paymentDetail?.approvalItems?.status === ApprovalStatus.PENDING ? paymentDetail?.approvalItems?.requestReason : paymentDetail.cancelRemark}
              </Box>
            </Col>
          </Row>
          <Row className="ml-2">
            <Col className="d-flex">
              <strong className="text-nowrap">
                <span style={{ color: '#FB5252' }}>{t('PAYMENT_DETAIL.CANCELED_BY')}</span> :{' '}
              </strong>
              <Box
                sx={{
                  wordWrap: 'break-word',
                  width: { xs: '100%', md: '50vw' },
                  overflow: 'hidden'
                }}
              >
                {paymentDetail?.approvalItems?.status === ApprovalStatus.PENDING ? paymentDetail?.approvalItems?.requestUserName : paymentDetail.canceledBy}
              </Box>
            </Col>
          </Row>
        </div>
      )}

      {outToken &&
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '12px',
          padding: '24px',
          width: '100%'
        }}>
          <Box sx={{ width: '100px' }}>
            <ButtonCustom
              textButton={t('APPROVELLIST.FILTER.REJECT')}
              variant={"outlined"}
              mode={'del'}
              btnStyle={{ width: "100%" }}
              onClick={() => onSubmitApprovalOutToken(ApprovalStatus.REJECT)}
            />
          </Box>
          <Box sx={{ width: '100px' }}>
            <ButtonCustom
              textButton={t('APPROVELLIST.FILTER.APPROVED')}
              btnStyle={{ width: "100%" }}
              onClick={() => onSubmitApprovalOutToken(ApprovalStatus.APPROVED)}
            />
          </Box>
        </Box>
      }

      {sendPayment && (
        <PopupPayment
          type="EDIT_PAYMENT"
          isShow={sendPayment}
          setIsShow={(show: boolean) => setSendPayment(show)}
          patient={patient}
          detail={paymentDetail}
          initBalance={initBalance}
          histories={logHistories}
          onSuccess={() => {
            setSendPayment(false)
            history.push(routeName.payment)
            const val = disabledOpenPopupInfo ? JSON.parse(disabledOpenPopupInfo) : []
            const newValueClosePopup = _.filter(val, (v) => Number(v) !== Number(paymentDetail?.order.patientId))
            localStorage.setItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP, JSON.stringify(newValueClosePopup))
          }}
        />
      )}
      <CancelPayment
        isShow={popupCancel}
        paymentNumber={paymentDetail?.paymentNumber || ''}
        id={orderPaymentId}
        resetSuccess={(status: boolean) => {
          if (status) history.push(routeName.payment)
        }}
        checkApprovalPermiss={checkApprovalPermiss}
      />
    </div>
  )
}
