import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/default-users/patient-types/style'

/** API */
import PatientTypeApi from 'api/setting/default-users/patient-type.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

const initStateErrorMessage = {
  PATIENT_TYPE_NAME: ``,
  IS_DUPLICATE_PATIENT_TYPE_NAME: ``,
  PATIENT_TYPE_NAME_STRING_BASE: ``,
  PATIENT_TYPE_NAME_STRING_EMPTY: ``,
  PATIENT_TYPE_NAME_MAXIMUM_LENGTH: ``,
  PATIENT_TYPE_NAME_ANY_REQUIRED: ``,

  PATIENT_TYPE_NAME_EN: ``,
  IS_DUPLICATE_PATIENT_TYPE_NAME_EN: ``,
  PATIENT_TYPE_NAME_EN_STRING_BASE: ``,
  PATIENT_TYPE_NAME_EN_STRING_EMPTY: ``,
  PATIENT_TYPE_NAME_EN_MAXIMUM_LENGTH: ``,
  PATIENT_TYPE_NAME_EN_ANY_REQUIRED: ``
}
export default function PatientTypes() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [patientTypes, setPatientTypes] = useState([])

  const [patientTypeId, setPatientTypeId] = useState(Number)
  const [patientTypeName, setPatientTypeName] = useState('')
  const [patientTypeNameEn, setPatientTypeNameEn] = useState('')
  const [patientTypeStatus, setPatientTypeStatus] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await PatientTypeApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setPatientTypes(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const submit = () => {
    if (!patientTypeName) return setErrorMessage({ ...errorMessage, ...{ PATIENT_TYPE_NAME: t('PATIENT_TYPE.MESSAGE.PATIENT_TYPE_NAME') } })
    dispatch(submitModal())
    PatientTypeApi.create(patientTypeName, patientTypeNameEn)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('PATIENT_TYPE.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()

          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`PATIENT_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`PATIENT_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!patientTypeName) return setErrorMessage({ ...errorMessage, ...{ PATIENT_TYPE_NAME: t(`PATIENT_TYPE.MESSAGE.PATIENT_TYPE_NAME`) } })

    dispatch(submitModal())
    PatientTypeApi.update(patientTypeId, {
      patientTypeName,
      patientTypeNameEn,
      status: patientTypeStatus
    })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`PATIENT_TYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data

          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`PATIENT_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`PATIENT_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setPatientTypeId(0)
    setPatientTypeName('')
    setPatientTypeNameEn('')
    setPatientTypeStatus('')
    clearErrorMessage()
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PATIENT_TYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          PatientTypeApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('PATIENT_TYPE.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('PATIENT_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { patientTypeId: ptTypeId, patientTypeName: ptTypeName, patientTypeNameEn: ptTypeNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PATIENT_TYPE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PATIENT_TYPE.ALERT.CONFIRM_DELETE_', {
        patientTypeName: ptTypeName,
        patientTypeNameEn: ptTypeNameEn ? ' (' + ptTypeNameEn + ')' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          PatientTypeApi.remove(ptTypeId)
            .then((resp) => {
              notiSuccess(t('PATIENT_TYPE.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('PATIENT_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { patientTypeId: ptTypeId, patientTypeName: ptTypeName, patientTypeNameEn: ptTypeNameEn, status: ptTypeStatus } = data
    setPatientTypeId(ptTypeId)
    setPatientTypeName(ptTypeName)
    setPatientTypeNameEn(ptTypeNameEn)
    setPatientTypeStatus(ptTypeStatus)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'patientTypeName', disablePadding: false, label: t('PATIENT_TYPE.TABLE.CELL.PATIENT_TYPE_NAME') },
    { id: 'patientTypeNameEn', disablePadding: false, label: t('PATIENT_TYPE.TABLE.CELL.PATIENT_TYPE_NAME_EN') },
    { id: 'status', disablePadding: false, label: t('PATIENT_TYPE.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('PATIENT_TYPE.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { patientTypeId: ptTypeId, patientTypeName: ptTypeName, patientTypeNameEn: ptTypeNameEn, updatedBy, updatedAt, status: ptTypeStatus } = objData
    const statusBtnActive = ptTypeStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = ptTypeStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: ptTypeId,
      id: ptTypeId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: ptTypeName },
        { option: 'TEXT', align: 'left', label: ptTypeNameEn },
        { option: 'STATUS', align: 'center', label: ptTypeStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('PATIENT_TYPE.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('PATIENT_TYPE.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('PATIENT_TYPE.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={patientTypes.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      <ModalCustom
        title={patientTypeId ? t('PATIENT_TYPE.TITLE_UPDATE') : t('PATIENT_TYPE.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {patientTypeId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0 d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                  <Form.Check type="switch" id="custom-switch" onChange={() => setPatientTypeStatus(patientTypeStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')} checked={patientTypeStatus === 'ACTIVE' ? true : false} style={{ marginLeft: 'auto' }} />
                </Form>
              </div>
            ) : (
              ''
            )}

            <InputTextField
              onchange={(event) => {
                setPatientTypeName(event.target.value)
                clearErrorMessage()
              }}
              value={patientTypeName}
              label={t('PATIENT_TYPE.INPUT.PATIENT_TYPE_NAME')}
              helperText={
                errorMessage.PATIENT_TYPE_NAME ||
                errorMessage.IS_DUPLICATE_PATIENT_TYPE_NAME ||
                errorMessage.PATIENT_TYPE_NAME_STRING_BASE ||
                errorMessage.PATIENT_TYPE_NAME_STRING_EMPTY ||
                errorMessage.PATIENT_TYPE_NAME_MAXIMUM_LENGTH ||
                errorMessage.PATIENT_TYPE_NAME_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
            />
            <InputTextField
              key="patientTypeNameEn"
              onchange={(event) => {
                setPatientTypeNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={patientTypeNameEn}
              helperText={
                errorMessage.PATIENT_TYPE_NAME_EN ||
                errorMessage.IS_DUPLICATE_PATIENT_TYPE_NAME_EN ||
                errorMessage.PATIENT_TYPE_NAME_EN_STRING_BASE ||
                errorMessage.PATIENT_TYPE_NAME_EN_STRING_EMPTY ||
                errorMessage.PATIENT_TYPE_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.PATIENT_TYPE_NAME_EN_ANY_REQUIRED
              }
              label={t('PATIENT_TYPE.INPUT.PATIENT_TYPE_NAME_EN')}
              required={false}
            />
          </div>
        }
        onReset={resetForm}
        onSubmit={patientTypeId ? submitEdit : submit}
        textBtnCancel={t('PATIENT_TYPE.BUTTON.CANCEL')}
        textBtnConfirm={t('PATIENT_TYPE.BUTTON.SAVE')}
      />
    </div>
  )
}
