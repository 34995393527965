import { useState, useEffect, useCallback } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { colors } from 'constants/theme'

/** API */
import { fileUrl } from 'api/api'
import AdvanceApi, { AdvanceListInterface } from 'api/finances/advance.api'
import TreatmentsAPI from 'api/dentists/treatment.api'

/** COMPONENT */
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import InputTextSearch from 'component/Input/InputTextSearch'
import DefaultPagination from 'component/Pagination/DefaultPagination'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import ButtonCustom from 'component/Button/ButtonCustom'
import Loading from 'component/Loading'
import { notiError } from 'component/notifications/notifications'
import { ManageAdvance } from './form-manage'

import * as UseStyled from 'features/finance/advance/style/advance-list'
import { FormAdvance } from 'features/finance/advance/form-advance'
import CancelAdvance from 'features/finance/advance/cancel-advance'


/** SLICE & UTILS & FEATURES */
import { showModal } from 'app/slice/modal.slice'
import UseWindowSize from 'utils/useWindowSize'
import { dateToView } from 'utils/date.utils'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { getBranch, numberToCurrencyFormat } from 'utils/app.utils'
import ClinicApi from 'api/master/clinic.api'

import MainButton from 'new-components/buttons/main-button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'

export default function Advance() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [width] = UseWindowSize()
  const branchId = getBranch()
  const [loading, setLoading] = useState<boolean>(false)
  const permissions = { isCreate: isCreate(), isDelete: isDelete(), isUpdate: isUpdate(), isView: isView() }

  /** FILTER */
  const [filterSearch, setFilterSearch] = useState<string>('')
  const [filterRangeDate, setFilterRangeDate] = useState<string[]>(emptyRangePicker)
  const [page, setPage] = useState(1)
  useEffect(() => {
    setPageLimit(width >= 1024 ? 10 : 3)
  }, [width])
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createdAt')
  const [sortType, setSortType] = useState('DESC')
  const [showForm, setShowForm] = useState<{ form: "ADD" | "MANAGE" | "CANCEL"; isShow: boolean; }>({ form: "ADD", isShow: false })


  /** data */
  const [advances, setAdvances] = useState<AdvanceListInterface[]>([])
  const [transectionAdvanceId, setTransectionAdvanceId] = useState<number>(0)
  const [advanceNumber, setAdvanceNumber] = useState<string>('')

  const [patientId, setPatientId] = useState<number>(0)
  const [advanceHistory, setAdvanceHistory] = useState<{ history: any[], rowCount: number }>({ history: [], rowCount: 0 })
  const [patient, setPatient]: any[] = useState([])
  const [banks, setBanks] = useState<any[]>([])

  const [profile, setProfile] = useState<any>(null)


  const onCreate = () => {
    dispatch(showModal())
    setShowForm({ form: "ADD", isShow: true })
  }

  const onEdit = () => {
    dispatch(showModal())
    setShowForm({ form: "MANAGE", isShow: true })
  }

  const onCancel = (objData: AdvanceListInterface) => {
    const { transectionAdvanceId: id, prefix, advanceNumber: advanceNum } = objData
    setTransectionAdvanceId(id)
    setAdvanceNumber(`${prefix}-${advanceNum}`)
    dispatch(showModal())
    setShowForm({ form: "CANCEL", isShow: true })
  }

  const loadData = useCallback(async () => {
    setLoading(true)
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (filterSearch) condition = { ...condition, search: filterSearch }
    if (filterRangeDate) {
      if ((filterRangeDate[0] !== null || filterRangeDate[0] !== '') && (filterRangeDate[1] !== null || filterRangeDate[1] !== ''))
        condition = { ...condition, dateStart: filterRangeDate[0], dateEnd: filterRangeDate[1] }
    }
    const respAdvances = await AdvanceApi.findAll(condition).finally(() => setLoading(false))

    await PersonnelApi.findProfile().then((data) => {
      setProfile(data?.data)
    }).finally(() => setLoading(false))
    if (respAdvances.status === 200) {
      setAdvances(respAdvances.data)
      setRowCount(respAdvances.headers['x-total'])
    }
    if (!banks.length) {
      const resBanks = await ClinicApi.findAllBanks(branchId)
      if (resBanks.status === 200) setBanks(resBanks.data)
    }
  }, [page, pageLimit, sortBy, sortType, filterSearch, filterRangeDate])

  useEffect(() => {
    loadData()
  }, [page, pageLimit, sortBy, sortType, filterSearch, filterRangeDate])

  const loadHistory = useCallback(async (fillter) => {
    const respAdvances = await AdvanceApi.findAll(fillter).finally(() => setLoading(false))
    if (respAdvances.status === 200) {
      setAdvanceHistory({ history: respAdvances.data, rowCount: respAdvances.headers['x-total'] })
    }
    if (fillter.patientId) {
      const patientData = await TreatmentsAPI.findPatient(fillter.patientId)
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
        setPatient(patientData.data)
      }
    }
  }, [])


  const desktopHeadCells = [
    { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
    { id: 'dateAt', disablePadding: false, label: t('ADVANCE.TABLE.DATE'), class: 'text-nowrap' },
    { id: 'paymentNumber', disablePadding: false, label: t('ADVANCE.TABLE.BILL_NO'), class: 'text-nowrap' },
    { id: 'message', disablePadding: false, label: t('LAB_ORDER.TABLE.CN'), class: 'text-nowrap' },
    { id: 'branchId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID'), class: 'text-nowrap' },
    { id: 'patientFullName', disablePadding: false, label: t('ADVANCE.TABLE.PATIENT_FULL_NAME'), class: 'text-nowrap' },
    { id: 'orderPaymentChannel', disablePadding: false, label: t('ADVANCE.TABLE.PAYMENT_CHANNELS'), class: 'text-nowrap' },
    { id: 'total', disablePadding: false, label: t('ADVANCE.TABLE.TOTAL'), class: 'text-nowrap' },
    { id: 'remaining', disablePadding: false, label: t('ADVANCE.TABLE.REMAINING'), class: 'text-nowrap' },
    { id: 'createdBy', disablePadding: false, label: t('ADVANCE.TABLE.STATUS'), class: 'text-nowrap' },
    { id: 'statusOrder', disablePadding: false, label: t('ADVANCE.TABLE.STAFF'), class: 'text-nowrap' }
  ]

  const tableHeadCells = [{ id: 'action', disablePadding: false, label: t('ADVANCE.TABLE.ACTION'), class: 'text-nowrap head-action' }]

  const headCells = width >= 1200 ? [...desktopHeadCells, ...tableHeadCells] : [...tableHeadCells]

  const renderData = (objData: AdvanceListInterface, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { transectionAdvanceId: id, date, prefix, cnNumber, branchCnNumber, patientFullName, advanceNumber: advanceNum, paymentChannelText, amountTotal, createdBy: staffBy, statusOrder, advanceBalance } = objData

    const billNo = `${prefix}-${advanceNum}`
    const amount = numberToCurrencyFormat(amountTotal)
    const balance = numberToCurrencyFormat(advanceBalance)
    const objRenderData = {
      key: String(no),
      id: String(id),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'center', label: dateToView(date) || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: billNo || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: cnNumber || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: branchCnNumber || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: patientFullName || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: paymentChannelText || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: amount || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: balance || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'center', label: t(`ADVANCE.STATUS.${statusOrder}`) || '-', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: staffBy || '-', class: 'text-nowrap-ellipsis' },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <div className="text-nowrap d-flex">
              <ButtonCustom mode="del" disabled={statusOrder === 'CANCEL'} variant={'outlined'} textButton={t('ADVANCE.TABLE.CANCEL_BILL')} onClick={() => onCancel(objData)} />
              <ButtonPrintBillAdvance orderPaymentId={id} isDisabled={statusOrder === 'CANCEL'} onLoading={setLoading} />
            </div>
          ),
          class: 'text-nowrap-ellipsis'
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onClick={(e, val) => {
          return
        }}
      />
    )
  }

  const renderDataSlim = (objData: AdvanceListInterface, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { transectionAdvanceId: id, date, prefix, cnNumber, branchCnNumber, patientFullName, advanceNumber: advanceNum, paymentChannelText, amountTotal, createdBy: staffBy, statusOrder } = objData
    const billNo = `${prefix}-${advanceNum}`
    const amount = numberToCurrencyFormat(amountTotal)
    return (
      <UseStyled.TBody>
        <div className={'d-flex align-items-center position-relative'}>
          <UseStyled.Cell>
            <Row className="pl-4 mx-0">
              <div className="position-absolute w-auto px-0" style={{ marginLeft: '-1.5rem' }}>
                {no}
              </div>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.DATE')}
              </Col>
              <Col xs={6} lg={8}>
                {dateToView(date)}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.BILL_NO')}
              </Col>
              <Col xs={6} lg={8}>
                {billNo}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('APPLICATION.TABLE.CELL.CN_NUMBER')}
              </Col>
              <Col xs={6} lg={8}>
                {cnNumber || '-'}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID')}
              </Col>
              <Col xs={6} lg={8}>
                {branchCnNumber || '-'}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.PATIENT_FULL_NAME')}
              </Col>
              <Col xs={6} lg={8}>
                {patientFullName}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.PAYMENT_CHANNELS')}
              </Col>
              <Col xs={6} lg={8}>
                {paymentChannelText}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.TOTAL')}
              </Col>
              <Col xs={6} lg={8}>
                {amount}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.STATUS')}
              </Col>
              <Col xs={6} lg={8}>
                {t(`ADVANCE.STATUS.${statusOrder}`)}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.STAFF')}
              </Col>
              <Col xs={6} lg={8}>
                {staffBy}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('ADVANCE.TABLE.ACTION')}
              </Col>
              <Col xs={6} lg={8} className="text-nowrap d-flex gap-2 " style={{ width: 'fit-content' }}>
                <ButtonCustom mode="del" disabled={statusOrder === 'CANCEL'} variant={'outlined'} textButton={t('ADVANCE.TABLE.CANCEL_BILL')} onClick={() => onCancel(objData)} />
                <ButtonPrintBillAdvance orderPaymentId={id} onLoading={setLoading} />
              </Col>
            </Row>
          </UseStyled.Cell>
        </div>
      </UseStyled.TBody>
    )
  }

  return (
    <div>
      <Loading show={loading} type="fullLoading" />
      <div className="p-3 " style={{ boxShadow: `0px 3px 6px ${colors.black08}` }}>
        <h5>{t('ADVANCE.HEADER')}</h5>
      </div>
      <div className="p-3">
        <Row className="mb-2 mx-0 d-flex aling-align-items-center">
          <Col xs={12} md={12} lg={4} xxl={3} className="px-1 mb-2">
            <InputTextSearch
              keyInput={'search'}
              label={t('ADVANCE.INPUT_SEARCH')}
              value={filterSearch}
              onchange={(event) => {
                setFilterSearch(event.target.value)
                setPage(1)
              }}
              onClear={(event) => {
                setFilterSearch('')
                setPage(1)
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={4} xxl={3} className="px-1 mb-2">
            <InputSecondNewRangePicker
              value={filterRangeDate || []}
              inputHeight={32}
              onchange={(val: any) => {
                if (_.isEmpty(val)) setFilterRangeDate(emptyRangePicker)
                else {
                  setFilterRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  setPage(1)
                }
              }}
              allowClear
              onClear={() => setFilterRangeDate(emptyRangePicker)}
            />
          </Col>
          <Col xs={12} md={6} lg={'auto'} xxl={'auto'} className="px-1 mb-2 ml-auto">
            <MainButton title={t('จัดการรายการรับเงินล่วงหน้า')} disabled={permissions.isCreate.disabled || profile?.hasManageAdvances === "UNPERMISS"} onClick={onEdit} type='primary' variant='outlined' startIcon={<AddCircleIcon />} style={{ marginRight: '8px' }} />
            <MainButton title={t('ADVANCE.BUTTON.ADD')} disabled={permissions.isCreate.disabled} onClick={onCreate} type='primary' variant='contained' startIcon={<AddCircleIcon />} />
          </Col>
        </Row>
        <div className=" overflow-auto custom-scroll ">
          <UseStyled.TableConfig className="table-payment custom-scroll">
            {width >= 1200 ? (
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                onSort={(sortByVal: string, sortTypeVal: string) => {
                  setSortType(sortTypeVal)
                  setSortBy(sortByVal)
                }}
                setPageLimit={setPageLimit}
                setPage={setPage}
                headCells={headCells}
                rowsData={_.map(advances, renderData)}
              />
            ) : (
              <>
                <UseStyled.THead>
                  <UseStyled.Cell>{t('#')}</UseStyled.Cell>
                </UseStyled.THead>
                <div className={'treatment-body'}>
                  {_.map(advances, renderDataSlim)}
                  <div className={'mt-2 '}>
                    <DefaultPagination count={rowCount} allRow={rowCount} pageLimit={pageLimit} page={page} setPage={setPage} />
                  </div>
                </div>
              </>
            )}
          </UseStyled.TableConfig>
        </div>
        <FormAdvance isShow={showForm.form === "ADD" && showForm.isShow} handleSuccess={loadData} />

        {showForm.form === "MANAGE" && showForm.isShow && <ManageAdvance
          isShow={showForm.form === "MANAGE" && showForm.isShow}
          handleSuccess={loadData}
          advanceHistory={advanceHistory}
          patient={patient}
          banks={banks}
          loadHistory={(fillter: any) => loadHistory(fillter)}
          setIsShow={(isShow: boolean) => setShowForm({ form: showForm.form, isShow: isShow })}
        />}
        <CancelAdvance isShow={showForm.form === "CANCEL" && showForm.isShow} id={transectionAdvanceId} advanceNumber={advanceNumber} handleSuccess={loadData} />
      </div>
    </div>
  )
}

export interface IPrintProps {
  orderPaymentId: number
  isDisabled?: boolean
  onLoading: (load: boolean) => void
}

export function ButtonPrintBillAdvance(props: IPrintProps) {
  const { t } = useTranslation()

  const printReceipt = async (type: 'A5' | 'A5Copy') => {
    props.onLoading(true)
    const printProp: any = { lang: 'TH', type: 'ORIGINAL' }

    if (type === 'A5Copy') printProp.type = 'COPY'
    const receipt = await AdvanceApi.printA5(props.orderPaymentId, printProp)

    if (receipt && receipt.status === 200) {
      props.onLoading(false)
      return window.open(`${fileUrl}/${receipt.data}`, '_blank')
    } else {
      props.onLoading(false)
      notiError(t('ADVANCE.MESSAGE.ERROR.ERROR'))
      return false
    }
  }

  return (
    <>
      <UseStyled.DropdownCustom>
        <Dropdown.Toggle id="dropdown-basic" disabled={props?.isDisabled || false}>
          {t('ADVANCE.PRINT_BILL')}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => printReceipt('A5')}>{t('ADVANCE.BILL_A5_ORI')}</Dropdown.Item>
          <Dropdown.Item onClick={() => printReceipt('A5Copy')}>{t('ADVANCE.BILL_A5_COPY')}</Dropdown.Item>
        </Dropdown.Menu>
      </UseStyled.DropdownCustom>
    </>
  )
}
