import { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { MenuItem, Box, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import MasterInventoryApi from 'api/master/masterInventory.api'
import InventoryApi, { PRODUCT_GROUP } from 'api/warehouses/inventory.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import FilterSelect from 'component/Select/FilterSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { routeName } from 'routes/routes-name'
import { numberFormat } from 'utils/app.utils'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** STYLE */
import * as UseStyled from 'features/warehouses/inventory/styled'
import ButtonExport from 'component/Button/ButtonExport'
import TableRowExport from 'component/Pdf/TableRowExport'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

export type InventoryType = {
    amounDayBeforeExpired?: null | number
    branchId?: number
    explanation?: string
    group?: "MEDICINE" | "PRODUCTION" | "MATERIAL"
    image?: any
    inventoryId?: number
    itemCode?: string
    itemId?: number
    itemName?: string
    itemNameEn?: string
    itemTypeId?: number
    itemTypeName?: string
    itemTypeNameEn?: string
    itemUnitId?: number
    itemUnitName?: string
    itemUnitNameEn?: string
    lotNo?: string
    minStock?: number
    costPrice?: number
    salePrice?: number
    price?: number
    productFormatName?: string
    productInstructionName?: string
    productPreserveName?: string
    productUseDetail?: null | string
    qty?: number
    showOnReceipt?: "SHOW" | "HIDE"
    status?: "ACTIVE" | "INACTIVE"
    strength?: string
    total?: number
    warnings?: null | string
    files?: FilesType[]
}

export type FilesType = {
    fileId: number
    fileName: string
    filePath: string
}

export default function Inventory() {
    const { t, i18n } = useTranslation()
    const history = useHistory()

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createAt')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [group, setGroup] = useState('ALL');
    const [category, setCategory] = useState<any>({})
    const [stock, setStock] = useState<any>({})

    const [productsCategory, setProductsCategory] = useState([]);
    const [productCategoryLoading, setProductCategoryLoading] = useState(false);
    const [inventory, setInventory] = useState([]);
    const [exportData, setExportData] = useState([])

    const optionStock = [{ value: 'ALL', label: t('ALL') }, { value: 'NORMAL', label: t('INVENTORY.FILTER.NORMAL') }, { value: 'MIN', label: t('INVENTORY.FILTER.LOWER_STOCK') }]

    const selectPCRef = useRef<any>(null)
    const [selectPCWidth, setSelectPCWidth] = useState<number>(112)
    const selectOPRef = useRef<any>(null)
    const [selectOPWidth, setSelectOPWidth] = useState<number>(112)

    const loadProductType = (categorySearch?: string) => {
        MasterInventoryApi.findAllProductsType({ pageLimit: 10, search: categorySearch || '' })
            .then(({ data }) => setProductsCategory(data))
            .catch(() => { return })
    }

    const handleInputSearch = useCallback(
        _.debounce((categorySearch?: string) => {
            loadProductType(categorySearch)
            setPage(1)
        }, 1000), []);

    const loadData = useCallback(() => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (group) condition = { ...condition, group: group === 'ALL' ? '' : group }
        if (!_.isEmpty(category)) condition = { ...condition, itemTypeId: category?.productTypeId || '' }
        if (!_.isEmpty(stock)) condition = { ...condition, stockType: stock.value }
        InventoryApi.findAll(condition)
            .then(({ data, headers }) => {
                setInventory(data)
                setRowCount(headers['x-total'])
            })
            .catch(() => { return })
    }, [page, pageLimit, sortBy, sortType, search, group, category, stock])

    const loadDataForExport = useCallback(() => {
        let condition: any = {}
        condition.export = 1
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (group) condition = { ...condition, group: group === 'ALL' ? '' : group }
        if (!_.isEmpty(category)) condition = { ...condition, itemTypeId: category?.productTypeId || '' }

        InventoryApi.findAll(condition)
            .then(({ data }) => setExportData(data))
            .catch(() => { return })
    }, [page, pageLimit, sortBy, sortType, search, group, category])

    const handleChangeCategory = (value: string) => {
        setCategory(value)
    }

    const handleChangeStock = (value: string) => {
        setStock(value)
    }

    const handleChangeGroup = (status: string) => {
        setGroup(status)
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const onView = (id: number) => {
        history.push(routeName.inventory + '/view', { inventoryId: id })
    }

    useEffect(() => {
        loadData()
    }, [loadData]);

    useEffect(() => {
        loadDataForExport()
    }, [loadDataForExport]);

    useEffect(() => {
        loadProductType()
    }, []);

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '50px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.TYPE'), width: '130px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.PRODUCT') },
        { id: 'price', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.PRICE'), width: '200px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.INVENTORY_QTY'), width: '150px', align: 'center' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.UNIT'), width: '160px', align: 'center' },
        { id: 'updatedBy', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.UPDATED_BY'), width: '200px' },
        { id: 'action', disablePadding: false, label: 'Action', width: '100px' }
    ]

    const renderData = (objData: any, no: number, valueExportData: boolean) => {
        const { inventoryId, group, itemName, itemNameEn, price, qty, itemUnitName, itemUnitNameEn, updatedBy, updatedAt, minStock } = objData

        no = page * pageLimit - pageLimit + no + 1
        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: group ? t(`INVENTORY.FILTER.GROUP.${group}`) : '-' },
                { option: 'TEXT', align: 'left', label: itemName || itemNameEn || '-', class: 'text-ellipsis' },
                { option: 'PRICE', align: 'right', label: numberFormat(price || 0) },
                { option: 'TEXT', align: 'center', label: qty >= minStock ? <Typography >{qty || '-'}</Typography> : <Typography color="red" >{qty || '-'}</Typography> },
                { option: 'TEXT', align: 'center', label: itemUnitName || itemUnitNameEn || '-' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'VIEW', label: t(`BUTTON.VIEW`), disabled: permissions.isView.disabled },
                    ]
                }
            ]
        }

        if (valueExportData) objRenderData.columns.pop()

        return (
            valueExportData === true &&
            <TableRowExport {...objRenderData} /> ||
            <TableRowCommon  {...objRenderData} onView={() => onView(inventoryId)} />
        )
    }

    const filter = [
        { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
        { label: `${t('INVENTORY.FILTER.GROUP.LABEL')}: ${t(`INVENTORY.FILTER.GROUP.${group}`)}` },
        { label: `${t('INVENTORY.FILTER.CATEGORY.LABEL')}: ${!_.isEmpty(category) ? category?.productTypeName || '-' : t(`INVENTORY.FILTER.CATEGORY.ALL`)}` },
    ]

    const handleWidthPC = () => {
        if (selectPCRef) {
            setSelectPCWidth(selectPCRef?.current?.clientWidth + 5)
        } else {
            setSelectPCWidth(112)
        }
    }

    const handleWidthOP = () => {
        if (selectPCRef) {
            setSelectOPWidth(selectOPRef?.current?.clientWidth + 5)
        } else {
            setSelectOPWidth(112)
        }
    }

    useEffect(() => {
        handleWidthPC()
        handleWidthOP()
    }, [selectPCRef, selectOPRef, i18n.language])

    return (
        <Card className={'border-0 h-100 overflow-auto'}>
            <HeaderCard text={t('INVENTORY.HEADER.DEFAULT')} />
            <Card.Body>
                <Card.Title className={'mb-0 pl-xl-3'}>
                    <Row>
                        <Col >
                            <Row>
                                <Col sm={12} md={6} lg={3} xl={4} className={'pt-2'}>
                                    <InputTextSearch
                                        keyInput={'search'}
                                        label={t('INVENTORY.SEARCH')}
                                        value={search}
                                        onchange={(event) => {
                                            setSearch(event.target.value)
                                            setPage(1)
                                        }}
                                        onClear={(event) => {
                                            setSearch('')
                                            setPage(1)
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={5} lg={3} xl={2} className={'pl-md-0 pt-2'}>
                                    <FilterSelect
                                        onchange={(event) => {
                                            const value = event.target.value
                                            if (value) {
                                                handleChangeGroup(value)
                                                setPage(1)
                                            }
                                        }}
                                        renderValue={() => `${t('INVENTORY.FILTER.GROUP.LABEL')}: ${t(`INVENTORY.FILTER.GROUP.${group}`)}`}
                                        label={t('INVENTORY.FILTER.GROUP.LABEL')}
                                        selectId="select-group"
                                        value={group}
                                        labelId="label-group"
                                        options={[
                                            <MenuItem key="1" value="ALL">
                                                {t(`INVENTORY.FILTER.GROUP.ALL`)}
                                            </MenuItem>,
                                            <MenuItem key="2" value={PRODUCT_GROUP.PRODUCT}>
                                                {t(`INVENTORY.FILTER.GROUP.${PRODUCT_GROUP.PRODUCT}`)}
                                            </MenuItem>,
                                            <MenuItem key="3" value={PRODUCT_GROUP.MEDICINE}>
                                                {t(`INVENTORY.FILTER.GROUP.${PRODUCT_GROUP.MEDICINE}`)}
                                            </MenuItem>,
                                            <MenuItem key="4" value={PRODUCT_GROUP.MATERIAL}>
                                                {t(`INVENTORY.FILTER.GROUP.${PRODUCT_GROUP.MATERIAL}`)}
                                            </MenuItem>
                                        ]}
                                        formControlStyle={{ maxWidth: 'unset !important' }}
                                    />
                                </Col>
                                <Col sm={8} md={4} lg={2} xl={2} className={'pl-md-0 pt-2'}>
                                    <Box className='position-relative' sx={{ '.MuiOutlinedInput-input': { paddingLeft: `${selectPCWidth}px !important` } }}>
                                        <Typography ref={selectPCRef} className='position-absolute top-50 translate-middle-y' sx={{ left: '12px', zIndex: 1 }}>{t('INVENTORY.FILTER.CATEGORY.LABEL')}:</Typography>
                                        <AutocompleteSelect
                                            labelId="medicineType"
                                            noOptionsText={t('NOT_FOUND')}
                                            options={productsCategory}
                                            getOptionLabel={(option: any) => option.productTypeName || t('ALL')}
                                            renderOption={(props, option: any) => (
                                                <Box component="li" {...props}>
                                                    {option.productTypeName}
                                                </Box>
                                            )}
                                            onchange={(e, value: any) => {
                                                setPage(1)
                                                handleChangeCategory(value)
                                            }}
                                            inputProps={{
                                                onChange: (e: any) => {
                                                    setProductsCategory([])
                                                    handleInputSearch(e.target.value)
                                                }
                                            }}
                                            onOpen={(e: any) => loadProductType()}
                                            value={!_.isEmpty(category) ? category : ''}
                                            loading={productCategoryLoading}
                                            height={32}
                                            disabledBorder
                                            disableClearable={_.isEmpty(category)}
                                        />
                                    </Box>
                                </Col>
                                <Col sm={8} md={4} lg={2} xl={2} className={'pl-md-0 pt-2'}>
                                    <Box className='position-relative' sx={{ '.MuiOutlinedInput-input': { paddingLeft: `${selectOPWidth}px !important` } }}>
                                        <Typography ref={selectOPRef} className='position-absolute top-50 translate-middle-y' sx={{ left: '12px', zIndex: 1 }}>{t('INVENTORY.FILTER.STOCK.LABEL')}:</Typography>
                                        <AutocompleteSelect
                                            labelId="medicineType"
                                            noOptionsText={t('NOT_FOUND')}
                                            options={optionStock}
                                            getOptionLabel={(option: any) => option.label || t('ALL')}
                                            renderOption={(props, option: any) => (
                                                <Box component="li" {...props}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            onchange={(e, value: any) => {
                                                setPage(1)
                                                handleChangeStock(value)
                                            }}
                                            value={!_.isEmpty(stock) ? stock : ''}
                                            loading={productCategoryLoading}
                                            height={32}
                                            disabledBorder
                                            disableClearable={_.isEmpty(stock)}
                                        />
                                    </Box>
                                </Col>
                                <Col className="mt-2 mt-lg-auto">
                                    <div className="pr-xl-2 d-flex">
                                        <div className='ml-auto'>
                                            <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('INVENTORY.HEADER.DEFAULT')}
                                                rowsData={exportData.map((val, i) => {
                                                    return renderData(val, i, true)
                                                })} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Title>

                <UseStyled.ResponsiveTable className={'mt-3 pl-xl-3 pr-xl-2'}>
                    <TableCustom
                        page={page}
                        pageLimit={pageLimit}
                        sortType={sortType}
                        sortBy={sortBy}
                        rowCount={rowCount}
                        onSort={onRequestSort}
                        setPageLimit={handleChangeRowsPerPage}
                        setPage={handleChangePage}
                        headCells={headCells}
                        rowsData={(
                            _.map(inventory, (item, index: number) => {
                                return renderData(item, index, false)
                            })
                        )}
                        tableFixedWidth
                        tableMinWidth={1200}
                    />
                </UseStyled.ResponsiveTable>
            </Card.Body>
        </Card>
    )
}
