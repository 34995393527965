import { TableRow, TableCell, Box, Tooltip, IconButton } from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import moment from 'moment'
import InputTextarea from 'component/Input/InputTextarea'
import { colors } from 'constants/theme'
import { getBranch, numberFormat } from 'utils/app.utils'
import FormPromotionDiscount from './form-promotion-discount'
import FormPromotionExtra from './form-promotion-extra'
import { DateObject } from 'react-multi-date-picker'

type FormPromotionDetailProps = {
    dataEdit: any
    handleChangeDetailPromotion: (data: any) => void
    errorMessage: any
    pageDisabled: boolean
}

const FormPromotionDetail = (props: FormPromotionDetailProps) => {
    const { dataEdit, handleChangeDetailPromotion, errorMessage, pageDisabled } = props
    const { t } = useTranslation()

    return (
        <Box>
            <Box width={'100%'} height={'1px'} bgcolor={'#E5E5E5'} margin={'16px 0'} />

            {dataEdit?.type === 'DISCOUNT' &&
                <FormPromotionDiscount
                    dataEdit={dataEdit}
                    handleChangeDetailPromotion={handleChangeDetailPromotion}
                    errorMessage={errorMessage}
                    pageDisabled={pageDisabled}
                />
            }
            {dataEdit?.type === 'EXTRA' &&
                <FormPromotionExtra
                    dataEdit={dataEdit}
                    handleChangeDetailPromotion={handleChangeDetailPromotion}
                    errorMessage={errorMessage}
                    pageDisabled={pageDisabled}
                />}

            <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'flex-start'} width={{ md: '80%', xs: '100%' }} gap={'8px'}>
                <Box width={{ md: '50%', xs: '100%' }} marginTop={'16px'}>
                    <InputSecondNewDatePicker
                        required
                        key={'start-date'}
                        label={t('PROMOTION.FORM.DATE_START')}
                        value={dataEdit?.startDate}
                        helperText={errorMessage.PROMOTION_STARTDATE}
                        onchange={(e: any) => {
                            const newUsageBranchDateData = dataEdit?.usageBranchDateData?.length ? [...dataEdit.usageBranchDateData].map((item: any) => {
                                return { ...item, dateStart: null, dateEnd: null }
                            }) : []
                            const newData = newUsageBranchDateData?.length ? { usageBranchDateData: newUsageBranchDateData } : {}
                            handleChangeDetailPromotion({ ...dataEdit, startDate: moment(e).format('YYYY-MM-DD'), ...newData })
                        }}
                        disablePast
                        minDate={new DateObject()}
                        maxDate={dataEdit?.endDate ? new Date(dataEdit?.endDate) : ''}
                        disableFuture={dataEdit?.endDate ? true : false}
                        disabled={pageDisabled}
                    />
                </Box>
                <Box width={{ md: '50%', xs: '100%' }} marginTop={'16px'}>
                    <InputSecondNewDatePicker
                        required
                        key={'end-date'}
                        label={t('PROMOTION.FORM.DATE_END')}
                        value={dataEdit?.endDate}
                        helperText={errorMessage.PROMOTION_ENDDATE}
                        onchange={(e: any) => {
                            const newUsageBranchDateData = dataEdit?.usageBranchDateData?.length ? [...dataEdit.usageBranchDateData].map((item: any) => {
                                return { ...item, dateStart: null, dateEnd: null }
                            }) : []
                            const newData = newUsageBranchDateData?.length ? { usageBranchDateData: newUsageBranchDateData } : {}
                            handleChangeDetailPromotion({ ...dataEdit, endDate: moment(e).format('YYYY-MM-DD'), ...newData })
                        }}
                        disablePast
                        minDate={dataEdit?.startDate ? new Date(dataEdit?.startDate) : new DateObject()}
                        disabled={pageDisabled}
                    />
                </Box>
            </Box>
            <Box width={{ md: '80%', xs: '100%' }} marginTop={'16px'}>
                <InputTextarea
                    label={t('PROMOTION.FORM.DETAIL')}
                    value={dataEdit?.detail || ''}
                    onchange={(e) => handleChangeDetailPromotion({ ...dataEdit, detail: e.target.value })}
                    disabled={pageDisabled}
                    helperText={errorMessage?.DETAIL_MAXLENGTH}
                />
            </Box>
        </Box>
    )
}

export default FormPromotionDetail