import { SORT_TYPE } from 'constants/common'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as UseStyled from 'features/report/useStyled'
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import { dateToView } from 'utils/date.utils'
import { Box, Typography } from '@mui/material'
import TableCustom from 'component/Table/TableCustom'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import moment from 'moment'
import _ from 'lodash'
import { colors } from 'constants/theme'

interface IncomeExpenseListProps {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
  branchName: string
  rangeDate: string[]
  statusSelect: string[]
  typeSelect: string[]
}

const IncomeExpenseList = (props: IncomeExpenseListProps) => {
  const { t } = useTranslation()

  const [dataIncome, setDataIncome] = useState<any>([])
  const [dataExpense, setDataExpense] = useState<any>([])

  useEffect(() => {
    if (props.dataExport?.length) {
      const newDataIncome: any[] = []
      const newDataExpense: any[] = []
      props.dataExport.forEach((item: any) => {
        if (item?.expenseGroup === "EXPENSE") {
          newDataExpense.push(item)
        } else {
          newDataIncome.push(item)
        }
      })
      setDataIncome(newDataIncome)
      setDataExpense(newDataExpense)
    } else {
      setDataIncome([])
      setDataExpense([])
    }
  }, [props.dataExport])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '1', disablePadding: false, labelKey: t('REPORT.TABLE.FOLLOW_UP.#'), align: 'center', class: 'text-nowrap', width: '30px', },
    { id: '3', disablePadding: false, labelKey: t('REPORT.TABLE.STOCK.DOCUMENT_NO'), align: 'left', class: 'text-nowrap', width: '150px', },
    { id: '4', disablePadding: false, labelKey: t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_CREATION_DATE'), align: 'center', class: 'text-nowrap', width: '150px', },
    { id: '5', disablePadding: false, labelKey: t('REPORT.TABLE.SALE_SUMMARY_MONTH.TYPE'), align: 'left', class: 'text-nowrap', width: '200px', },
    { id: '6', disablePadding: false, labelKey: t("REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.LIST"), align: 'left', class: 'text-nowrap', width: '250px', },
    { id: '7', disablePadding: false, labelKey: t("REPORT.TABLE.COMMISSION.QTY"), align: 'right', class: 'text-nowrap', width: '10px', },
    { id: '8', disablePadding: false, labelKey: t("REPORT.TABLE.INCOME_EXPENSE.PRICEBAHT"), align: 'right', class: 'text-nowrap', width: '150px', },
    { id: '9', disablePadding: false, labelKey: t("REPORT.TABLE.INCOME_EXPENSE.TOTALBAHT"), align: 'center', class: 'text-nowrap', width: '150px', },
    { id: '10', disablePadding: false, labelKey: t('STATUS.LABEL'), align: 'center', class: 'text-nowrap', width: '120px' },
    { id: '11', disablePadding: false, labelKey: t("REPORT.TABLE.INCREASE.UPDATED_BY"), align: 'center', class: 'text-nowrap', },
  ]

  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1

    const { documentNo, documentDate, expenseGroup, expenseTypeName, qty, price, status, updatedAt, updatedBy, total } = data

    const objRenderData = {
      key: num.toString(),
      id: no.toString(),
      obj: data,
      columns: [
        { option: 'TEXT', align: 'center', label: num, class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: documentNo, class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', label: dateToView(documentDate), class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: t(`INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.${expenseGroup}.TITLE`), class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: expenseTypeName, class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', label: qty, class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', label: numberFormat(price), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', label: numberFormat(total), class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', label: t(`INCOME_EXPENSES.DOCUMENT_STATUS.${status}`), class: 'text-nowrap' },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt }, class: 'text-nowrap-ellipsis' },
      ]
    }

    return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
  }

  const headCellsSub = [
    { id: '1', disablePadding: false, labelKey: t('REPORT.TABLE.FOLLOW_UP.#'), align: 'center', class: 'text-nowrap', width: '30px', },
    { id: '6', disablePadding: false, labelKey: t("REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.LIST"), align: 'left', class: 'text-nowrap', width: '250px', },
    { id: '7', disablePadding: false, labelKey: t("REPORT.TABLE.COMMISSION.QTY"), align: 'right', class: 'text-nowrap', width: '10px', },
    { id: '7', disablePadding: false, labelKey: t("REPORT.TABLE.INCOME_EXPENSE.PRICEBAHT"), align: 'right', class: 'text-nowrap', width: '150px', },
    { id: '7', disablePadding: false, labelKey: t("REPORT.TABLE.INCOME_EXPENSE.TOTALBAHT"), align: 'center', class: 'text-nowrap', width: '150px', },
  ]

  const renderDataIncomeExpense = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    const { documentNo, documentDate, expenseGroup, expenseTypeName, qty, price, status, updatedAt, updatedBy, total } = data
    if (render === 'data') {
      const objRenderData = {
        key: (no + 1).toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', label: no + 1, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: expenseTypeName, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: qty, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(price), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(total), class: 'text-nowrap' },
        ]
      }

      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
    const fontSize = exportData ? '10px !important' : '16px'
    const objRenderData = {
      key: no.toString(),
      id: no.toString(),
      obj: data,
      columns: [
        { option: 'MERGE', align: 'center', colSpan: 3, class: 'col-merge' },
        {
          option: 'TEXT',
          align: 'right',
          label: <Typography sx={{ fontWeight: 500, fontSize: fontSize }}>{t('REPORT.TITLE.SUM')}</Typography>,
          class: 'text-nowrap col-sum'
        },
        { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
      ],
      classes: 'row-summary'
    }
    return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>

  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            // tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={props.data?.length ? [
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
            ] : []}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Box marginTop='32px'>
        <Typography fontSize={'20px'} marginBottom={'16px'} fontWeight={500}>
          {t('REPORT.TABLE.INCOME_EXPENSE.SUM_INCOME')}
        </Typography>
        <Box maxWidth='900px' sx={!dataIncome?.length ? { tr: { td: { textAlign: 'center' } } } : {}}>
          <UseStyled.ResponsiveReportTable>
            <TableCustom
              rowHeadCell={2}
              page={props.page}
              pageLimit={props.pageLimit}
              sortType={props.sortType}
              sortBy={props.sortBy}
              rowCount={props.rowCount}
              onSort={onRequestSort}
              setPageLimit={props.handleChangeRowsPerPage}
              setPage={props.handleChangePage}
              headCells={headCellsSub}
              rowsData={
                dataIncome?.length ? [
                  _.map(dataIncome, (d: any, index: number) => {
                    return renderDataIncomeExpense(d, index, false, 'data')
                  }),
                  renderDataIncomeExpense(dataIncome, 0, false, 'sum')
                ] : []}
              customScroll
              hidePagination
            />
          </UseStyled.ResponsiveReportTable>
        </Box>
      </Box>

      <Box marginTop='32px'>
        <Typography fontSize={'20px'} marginBottom={'16px'} fontWeight={500}>
          {t('REPORT.TABLE.INCOME_EXPENSE.SUM_EXPENSES')}
        </Typography>
        <Box maxWidth='900px' sx={!dataExpense?.length ? { tr: { td: { textAlign: 'center' } } } : {}}>
          <UseStyled.ResponsiveReportTable>
            <TableCustom
              rowHeadCell={2}
              page={props.page}
              pageLimit={props.pageLimit}
              sortType={props.sortType}
              sortBy={props.sortBy}
              rowCount={props.rowCount}
              onSort={onRequestSort}
              setPageLimit={props.handleChangeRowsPerPage}
              setPage={props.handleChangePage}
              headCells={headCellsSub}
              rowsData={
                dataExpense?.length ? [
                  _.map(dataExpense, (d: any, index: number) => {
                    return renderDataIncomeExpense(d, index, false, 'data')
                  }),
                  renderDataIncomeExpense(dataExpense, 0, false, 'sum')
                ] : []}
              customScroll
              hidePagination
            />
          </UseStyled.ResponsiveReportTable>
        </Box>
      </Box>

      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.INCOME_EXPENSE`)}
          branchName={props.branchName}
          rangeDate={props.rangeDate}
          statusSelect={props.statusSelect}
          typeSelect={props.typeSelect}
          componant={
            <Box sx={{
              'tbody tr:last-child td': {
                paddingBottom: '8px'
              },
              '.merge-colspan': {
                borderRight: 'none !important',
              },
              '.sum-colspan': {
                borderLeft: 'none !important',
              },
              'td': {
                border: '1px ridge rgb(0, 0, 0) !important',
                padding: '1px 4px !important'
              },
              '.col-sum': { borderLeft: '0px solid !important' },
              '.col-merge': { borderRight: '0px solid !important' }
            }}>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', border: '1px solid' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 8, fontWeight: 500, height: '36px', ...val?.style }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {props.dataExport?.length ? [
                      _.map(props.dataExport, (d: any, index: number) => {
                        return renderData(d, index, true, 'data')
                      }),
                    ] : <tr><td colSpan={headCells.length} style={{ textAlign: 'center', fontSize: 8, fontWeight: 500, height: '36px' }}>{t('NOT_FOUND')}</td></tr>}
                  </tbody>
                </table>
              </UseStyled.Styles>

              <Box sx={{ marginTop: '20px', '.box-table': { width: '500px' } }}>
                <Typography fontSize={'12px'} fontWeight={500}>
                  {t('REPORT.TABLE.INCOME_EXPENSE.SUM_INCOME')}
                </Typography>
                <UseStyled.Styles className="pt-3 box-table">
                  <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', border: '1px solid' }}>
                    <thead>
                      <tr>
                        {_.map(headCellsSub, (val: any, index: number) => {
                          return <td style={{ textAlign: val.align, fontSize: 8, fontWeight: 500, height: '36px', ...val?.style }}> {t(val.labelKey)}</td>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataIncome?.length ? [
                        _.map(dataIncome, (d: any, index: number) => {
                          return renderDataIncomeExpense(d, index, true, 'data')
                        }),
                        renderDataIncomeExpense(dataIncome, 0, true, 'sum')
                      ] : <tr><td colSpan={headCellsSub.length} style={{ textAlign: 'center', fontSize: 8, fontWeight: 500, height: '36px' }}>{t('NOT_FOUND')}</td></tr>}
                    </tbody>
                  </table>
                </UseStyled.Styles>
              </Box>

              <Box sx={{ marginTop: '20px', '.box-table': { width: '500px' }, }}>
                <Typography fontSize={'12px'} fontWeight={500}>
                  {t('REPORT.TABLE.INCOME_EXPENSE.SUM_EXPENSES')}
                </Typography>
                <UseStyled.Styles className="pt-3 box-table">
                  <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', border: '1px solid' }}>
                    <thead>
                      <tr>
                        {_.map(headCellsSub, (val: any, index: number) => {
                          return <td style={{ textAlign: val.align, fontSize: 8, fontWeight: 500, height: '36px', ...val?.style }}> {t(val.labelKey)}</td>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataExpense?.length ? [
                        _.map(dataExpense, (d: any, index: number) => {
                          return renderDataIncomeExpense(d, index, true, 'data')
                        }),
                        renderDataIncomeExpense(dataExpense, 0, true, 'sum')
                      ] : <tr><td colSpan={headCellsSub.length} style={{ textAlign: 'center', fontSize: 8, fontWeight: 500, height: '36px' }}>{t('NOT_FOUND')}</td></tr>}
                    </tbody>
                  </table>
                </UseStyled.Styles>
              </Box>
            </Box>
          }
        />
      </div>
    </>
  )
}

export default IncomeExpenseList

type ExportDataDecreaseListProps = {
  headCells: string
  branchName: string
  componant: any
  rangeDate: string[]
  statusSelect: string[]
  typeSelect: string[]
}

export function ExportData(props: ExportDataDecreaseListProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box sx={{ fontSize: '14px', fontWeight: "500" }}>{props.headCells}</Box>
      <Box sx={{ display: "flex", alignItems: 'center', gap: '8px' }}>
        <Box sx={{ fontSize: '10px' }}>{t('REPORT.TABLE.DECREASE.BRANCH')}: {props.branchName}</Box>
        <Box sx={{ fontSize: '10px' }}>
          {`${t('REPORT.TABLE.DECREASE.DATE_START')}: ${toBuddhistYear(moment(props.rangeDate[0])) !== 'Invalid dateNaN' ? toBuddhistYear(moment(props.rangeDate[0])) : '-'} `}
        </Box>
        <Box sx={{ fontSize: '10px' }}>
          {`${t('REPORT.TABLE.DECREASE.DATE_END')}: ${toBuddhistYear(moment(props.rangeDate[1])) !== 'Invalid dateNaN' ? toBuddhistYear(moment(props.rangeDate[1])) : '-'} `}
        </Box>
        <Box sx={{ fontSize: '10px' }}>{t('REPORT.TABLE.SALE_SUMMARY_MONTH.TYPE')}: {`${props.typeSelect?.length === 2 ? t(`REPORT.FILTER.ALL`) : props.typeSelect?.length ? props.typeSelect.map((item: any) => t(`INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.${item}.TITLE`)).join(', ') : '-'}`}</Box>
        <Box sx={{ fontSize: '10px' }}>{t('REPORT.TABLE.DECREASE.STATUS')}: {`${props.statusSelect?.length === 3 ? t(`REPORT.FILTER.ALL`) : props.statusSelect?.length ? t(`INCOME_EXPENSES.DOCUMENT_STATUS.${props.statusSelect[0]}`) : '-'}`}</Box>
      </Box>

      {props.componant}
    </>
  )
}