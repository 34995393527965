import styled from "styled-components"
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'

interface HelperTextProps {
    label: string
}

const CustomHelperText = styled('div')(({ theme }) => ({
    path: {
        fill: '#B00020'
    },
    span: {
        color: '#B00020',
        fontSize: 12,
        paddingLeft: 4
    },
}))

export function HelperText(props: HelperTextProps) {
    return (
        <CustomHelperText>
            <ErrorOutlinedIcon sx={{ fontSize: 13 }} />
            <span>{props.label}</span>
        </CustomHelperText>
    )
}