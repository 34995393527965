import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'
import { Button, Paper, Typography, Menu, Chip, Box } from '@mui/material'
import { Dropdown, Row, InputGroup } from 'react-bootstrap'
import { Icons } from 'constants/images'

export const ContainerRegister = styled('div')(({ theme }) => ({
  height: '100%',
  '.btn-new-patient': {
    height: 32,
    fontSize: 16,
    padding: '0 7px',
    '.MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 4,
      svg: {
        fontSize: 16
      }
    },
    '&:disabled': {
      borderColor: `${colors.disabledGray} !important`,
      color: `${colors.disabledGray} !important`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 0,
      minWidth: 40,
      padding: 0,
      '.MuiButton-startIcon': {
        margin: 0
      }
    }
  }
}))

export const FragmentRegister = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}))

export const FragmentLeft = styled('div')(({ theme }) => ({
  width: '37%',
  padding: '1rem',
  paddingBottom: '4.5rem',
  borderRight: `1px solid ${colors.lightGray}`,
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '1.5rem',
    paddingRight: '1.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100% - 1rem)',
    overflowY: 'auto',
    paddingRight: '0.5rem',
    paddingTop: 0,
    marginTop: '1rem'
  },
  [theme.breakpoints.down('xl')]: {
    width: '40%'
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    borderRight: 'none'
  }
}))

export const FragmentRight = styled('div')(({ theme }) => ({
  width: '63%',
  paddingBottom: '4.5rem',
  [theme.breakpoints.between('sm', 'lg')]: {
    paddingBottom: '1.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    height: '100%'
  },
  [theme.breakpoints.down('xl')]: {
    width: '60%'
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  }
}))

export const SectionProfile = styled('section')(({ theme }) => ({
  width: '100%',
  margin: '1rem 0',
  padding: '0 2.5rem 0 4rem',
  [theme.breakpoints.down('xl')]: {
    padding: 0
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: '2rem'
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '1.5rem'
  }
}))

export const CardProfileImg = styled('div')(({ theme }) => ({
  '&:not(.disabled) .personal-figure': {
    cursor: 'pointer'
  },
  '.personal-figure': {
    position: 'relative',
    width: 123,
    height: 123,
    borderRadius: 100,
    border: '5px solid transparent',
    [theme.breakpoints.down('lg')]: {
      width: 108,
      height: 108
    },
    [theme.breakpoints.down('sm')]: {
      width: 85,
      height: 85
    }
  },
  img: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: 100,
    objectFit: 'cover'
  },
  '.btn-add-profile': {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 29,
    height: 29,
    color: colors.white,
    backgroundColor: `${colors.themeSecondColor} !important`,
    svg: {
      width: 16,
      height: 'auto'
    },
    [theme.breakpoints.down('lg')]: {
      width: 26,
      height: 26
    },
    [theme.breakpoints.down('sm')]: {
      width: 23,
      height: 23
    }
  }
}))

export const CardAddProfileImg = styled('div')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  border: '5px solid transparent',
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '50%',
  },
  [theme.breakpoints.down('lg')]: {
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: '50%',
    }
  },
  [theme.breakpoints.down('sm')]: {
    img: {
      width: 300,
      height: 300,
      borderRadius: '50%',
    }
  },
  '.btn-close-profile': {
    position: 'absolute',
    zIndex: 9999,
    color: colors.white,
    backgroundColor: `${colors.disabledGray} !important`,
    right: 390,
    top: 100,
    width: 30,
    height: 30,
    svg: {
      width: 16,
      height: 'auto'
    },
    [theme.breakpoints.down('lg')]: {
      right: 260,
      top: 103,
      width: 30,
      height: 30
    },
    [theme.breakpoints.down('sm')]: {
      right: 80,
      top: 110,
      width: 23,
      height: 23
    }
  }
}))

export const CardProfile = styled('div')(({ theme }) => ({
  paddingLeft: '2rem',
  [theme.breakpoints.down('xl')]: {
    paddingLeft: '1.5rem'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0.5rem'
  }
}))

export const PatientFullName = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: colors.themeMainColor
}))

export const DropdownRank = styled(Dropdown)(({ theme }) => ({
  'button.dropdown-toggle': {
    minWidth: 176,
    height: 30,
    textAlign: 'left',
    padding: '0 0.5rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
    boxShadow: 'none !important',
    '&:after': {
      marginLeft: 'auto'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    },
    // '&.bronze-member': {
    //   background: colors.memberRanking.background.rankingBronze,
    //   borderColor: 'transparent'
    // },
    // '&.silver-member': {
    //   background: colors.memberRanking.background.rankingSilver,
    //   borderColor: 'transparent'
    // },
    // '&.gold-member': {
    //   background: colors.memberRanking.background.rankingGold,
    //   borderColor: 'transparent'
    // },
    // '&.platinum-member': {
    //   background: colors.memberRanking.background.rankingPlatinum,
    //   borderColor: 'transparent'
    // },
    // '&.daimon-member': {
    //   background: colors.memberRanking.background.rankingDiamond,
    //   borderColor: 'transparent'
    // },
    '&:disabled': {
      background: colors.disabledGray,
      borderColor: colors.disabledGray,
      opacity: 1
    },
    '& + .dropdown-menu.show': {
      position: 'fixed !important',
      transform: 'unset !important',
      inset: 'unset !important',
      borderRadius: 3,
      borderColor: colors.lightGray,
      marginTop: 5,
      width: 176,
      '.dropdown-item': {
        '&:hover, &:focus': {
          backgroundColor: colors.themeSecondColor06,
          color: colors.themeSecondColor
        }
      }
    }
  }
}))

export const ButtonTreatment = styled(Button)(({ theme }) => ({
  minHeight: 48,
  fontSize: 16,
  padding: '0 10px',
  borderRadius: 6,
  borderWidth: '2px !important',
  borderColor: `${colors.themeSecondColor} !important`,
  color: colors.themeSecondColor,
  '&:disabled': {
    borderColor: `${colors.disabledGray} !important`,
    color: `${colors.disabledGray} !important`
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 0
  }
}))

export const SectionTreatmentHistory = styled('section')(({ theme }) => ({
  marginBottom: '1.5rem',
  '.text-treatment-history': {
    fontSize: 20,
    fontWeight: 600,
    color: colors.themeMainColor,
    marginBottom: 10
  }
}))

export const HeaderBar = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 104,
  boxShadow: `1px 2px 2px 0 ${colors.black16}`,
  padding: '0 1rem',
  marginBottom: 2,
  [theme.breakpoints.between('sm', 'lg')]: {
    paddingRight: '1.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    position: 'sticky'
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '2.25rem'
  }
}))

export const HeaderBarInfo = styled('div')(({ theme }) => ({
  color: colors.textPrimary,
  p: {
    fontSize: 14,
    '&.title': {
      fontSize: 24,
      fontWeight: 500
    }
  }
}))

export const HeaderBarAction = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    position: 'absolute',
    right: '1rem'
  },
  button: {
    minWidth: 'unset',
    height: 44,
    boxShadow: 'none !important',
    fontSize: 16,
    '&.btn-info': {
      backgroundColor: colors.white,
      padding: '0 0.5rem'
    },
    '&.btn-submit': {
      '&:disabled': {
        backgroundColor: `${colors.disabledGray} !important`,
        color: `${colors.white} !important`
      }
    },
    '&.btn-more': {
      width: 44,
      border: `1px solid ${colors.lightGray}`,
      backgroundColor: colors.white,
      padding: 0,
      '&:disabled': {
        'svg g path': {
          stroke: `${colors.disabledGray} !important`
        }
      }
    },
    '&:disabled': {
      borderColor: `${colors.disabledGray} !important`,
      color: `${colors.disabledGray} !important`
    }
  }
}))

export const MenuBtnMore = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    marginTop: '0.5rem',
    borderRadius: 3,
    mixBlendMode: 'multiply',
    backgroundBlendMode: 'multiply',
    background: `linear-gradient(to bottom, ${colors.white}, ${colors.white})`,
    boxShadow: `none !important`,
    border: `1px solid ${colors.lightGray}`
  },
  '.MuiMenuItem-root': {
    color: colors.textPrimary,
    fontSize: '16px !important',
    '&:hover': {
      background: colors.themeSecondColor06,
      color: colors.themeSecondColor
    }
  }
}))

export const SectionForm = styled('section')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: '100%',
    maxHeight: 'calc(100% - 104px)',
    overflowY: 'auto'
  }
}))

export const ButtonNotSpecified = styled(Button)(({ theme }) => ({
  display: 'block !important',
  maxWidth: '123px',
  minWidth: 56,
  maxHeight: 43,
  borderColor: colors.themeSecondColor,
  color: colors.themeSecondColor,
  fontSize: 16,
  padding: 0,
  background: colors.white,
  marginLeft: '-3px !important',
  '&:hover': {
    background: colors.white,
    borderColor: colors.themeSecondColor
  },
  '&:disabled': {
    backgroundColor: colors.disabledLightGray,
    color: colors.black60
  },
  '&.is-disabledInputs': {
    backgroundColor: colors.themeSecondColor,
    color: colors.white
  },
  '&.has-error': {
    borderTop: `2px solid ${colors.statusInactive}`,
    borderRight: `2px solid ${colors.statusInactive}`,
    borderBottom: `2px solid ${colors.statusInactive}`
  }
}))

export const CustomTagMultiple = styled(Chip)<{ type?: string }>(({ theme, type }) => ({
  backgroundColor: (type === 'disease' && colors.tag.disease) || (type === 'medicine-use' && colors.tag.medicineUse) || (type === 'be-allergic' && colors.tag.beAllergic) || colors.disabledGray,
  color: colors.white,
  fontSize: 16,
  height: 25,
  borderRadius: 8,
  display: 'block !important',
  '.MuiSvgIcon-root': {
    fontSize: 14,
    path: {
      fill: `${colors.white} !important`
    }
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    height: 24
  }
}))

export const ButtonAddEmergencyContact = styled(Button)(({ theme }) => ({
  padding: 0,
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  '&:disabled': {
    color: colors.disabledGray
  }
}))

export const FragmentFooter = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  minHeight: 72,
  width: '100%',
  boxShadow: `0 -2px 2px 0 ${colors.black16}`,
  backgroundColor: colors.white,
  zIndex: 100,
  padding: '0 1rem',
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '0 1.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    left: scale.sidebarSize,
    maxWidth: `calc(100% - ${scale.sidebarSize})`
  },
  button: {
    height: 44,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    boxShadow: 'none !important',
    fontSize: 16,
    '&:disabled': {
      borderColor: `${colors.disabledGray} !important`,
      color: `${colors.disabledGray} !important`,
      opacity: 1,
      '&.btn-send-exam': {
        backgroundColor: `${colors.disabledGray} !important`,
        color: `${colors.white} !important`
      }
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
}))

export const DropdownDocument = styled(Dropdown)(({ theme }) => ({
  '.dropdown-toggle': {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    border: `1px solid ${colors.themeSecondColor} !important`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none !important',
    '&:after': {
      marginLeft: '0.5rem'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    }
  },
  '.dropdown-menu': {
    marginBottom: 4,
    borderRadius: 4,
    background: colors.white,
    border: `1px solid ${colors.lightGray}`,
    '.dropdown-item': {
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

export const RowFormManagement = styled(Row)(({ theme }) => ({
  '.title .MuiTypography-root': {
    fontSize: 18,
    fontWeight: 500
  },
  'span.Mui-disabled': {
    color: `${colors.textPrimary} !important`
  },
  '.Mui-checked.Mui-disabled .checkbox.isChecked::before': {
    background: `${colors.disabledLightGray} !important`
  },
  '.row-menu': {
    paddingLeft: 32,
    paddingRight: 32,
    fontFamily: 'Sarabun',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.textPrimary
  }
}))

export const InputBranchCnGroup = styled(InputGroup)<{ cnLength?: number }>(({ theme, cnLength }) => ({
  '.input-group-text': {
    '& + .MuiTextField-root': {
      fieldset: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 'none'
      }
    }
  },
  '&.input-group-date': {
    '.ant-picker': {
      top: 5,
      transform: 'unset'
    }
  },
  '&.input-group-idCard, &.input-group-date': {
    '.MuiFormHelperText-root.Mui-error': {
      whiteSpace: 'nowrap'
    }
  },
  '&.input-group-cn': {
    '> span': {
      position: 'absolute',
      top: '50%',
      left: '1rem',
      transform: 'translateY(-50%)',
      fontSize: 16,
      color: colors.black60,
      zIndex: 1
    },
    '.MuiTextField-root': {
      marginLeft: '0 !important'
    },
    '.MuiInputBase-input': {
      paddingLeft: (cnLength === 3 && '3rem') || (cnLength === 2 && '2.5rem') || '2rem'
    }
  },
}))

export const BloodPressureBox = styled(Box)(({ theme }) => ({
  '.MuiInputBase-input': {
    '&::placeholder': {
      color: `${colors.black30} !important`
    }
  }
}))

export const Background = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(41, 48, 46, 0.3)',
  left: 0,
  right: 9,
  top: 0,
  bottom: 0,
  zIndex: 2000
}))

export const Popupstyles = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  width: 1016,
  height: 664,
  borderRadius: '12px',
  backgroundColor: colors.white,
  zIndex: 10,

  [theme.breakpoints.between('sm', 'lg')]: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '80%',
    height: 640,
  },

  [theme.breakpoints.between(375, 767)]: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '100%',
    height: '80%',
  },

}))
export const InsidePopup = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: 12,
  backgroundColor: colors.white,
  minHeight: 480,
  padding: 16,
  img: {
    width: 400,
    height: 400,
  }
}))

export const AddImgGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ButtonWebcam = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.themeSecondColor,
  background: colors.white,
  border: `1px solid ${colors.themeSecondColor}`,
  borderRadius: 4,
  fontSize: '16px',
  padding: 4,
  margin: 4,
  width: 120,
  height: 48,

  '&:hover': {
    color: colors.white,
    background: colors.themeSecondColor,
    opacity: 0.6
  }
}))

export const ButtonSelectFile = styled('button')(({ theme }) => ({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.themeSecondColor,
  background: colors.white,
  border: `1px solid ${colors.themeSecondColor}`,
  borderRadius: 4,
  padding: 4,
  margin: 4,
  fontSize: '16px',
  width: 'fit-content',
  height: 48,

  '&:hover': {
    color: colors.white,
    background: colors.themeSecondColor,
    opacity: 0.6
  }
}))

export const ButtonCapture = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.white,
  background: colors.themeSecondColor,
  border: 'none',
  borderRadius: 4,
  padding: 4,
  width: '80%',
  '&:hover': {
    opacity: 0.6
  }
}))

export const Groupoptionbutton = styled('span')(() => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: '0px 16px 16px',
  display: 'flex',
  flexDirection: 'row',
}))

export const ButtonCancel = styled('div')(({ theme }) => ({
  top: 10,
  right: 15,
  position: 'absolute',
  color: colors.gray,
  '&:hover': {
    opacity: 0.6
  }
}))

export const ButtonWebcamSubmit = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.themeSecondColor,
  background: colors.white,
  border: `1px solid ${colors.themeSecondColor}`,
  borderRadius: 4,
  padding: 4,
  fontSize: '16px',
  width: 120,
  height: 48,
  marginLeft: 8,

  '&:hover': {
    color: colors.white,
    background: colors.themeSecondColor,
    opacity: 0.6
  }
}))

export const SignatureBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(41, 48, 46, 0.3)',
  left: 0,
  right: 9,
  top: 0,
  bottom: 0,
  zIndex: 99999
}))

export const SignaturePopupstyles = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  width: 1016,
  height: 664,
  borderRadius: '12px',
  backgroundColor: colors.white,
  zIndex: 10,

  [theme.breakpoints.between('sm', 'lg')]: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '80%',
    height: 640,
  },

  [theme.breakpoints.between(375, 767)]: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '100%',
    height: '80%',
  },

}))

export const BoxSignature = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: 12,
  backgroundColor: colors.white,
  minHeight: 480,

}))

export const ButtonaddSignature = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  backgroundColor: colors.white,
  color: colors.themeSecondColor,
  borderRadius: '4px',
  border: `1px solid ${colors.themeSecondColor}`,
  fontSize: '16px',
  fontWeight: 400,

  width: 136,
  height: 32,
}))

export const ButtonsubmitSignature = styled('button')(({ theme }) => ({
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  borderRadius: '4px',
  border: `1px solid ${colors.themeSecondColor}`,
  fontSize: '16px',

  width: 88,
  height: 48,
}))

export const ButtoncancelSignature = styled('button')(({ theme }) => ({
  backgroundColor: colors.white,
  color: colors.themeSecondColor,
  borderRadius: '4px',
  border: `1px solid ${colors.themeSecondColor}`,
  fontSize: '16px',

  width: 88,
  height: 48,
}))


export const GroupButtonSignature = styled('div')(({ theme }) => ({
  width: '25%',
  position: 'absolute',
  left: '76%',
  top: '90%',
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.between(375, 767)]: {
    width: '25%',
    position: 'absolute',
    left: '70%',
    display: 'flex',
    justifyContent: 'center',
  },

}))

export const SignatureDraw = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: "560px",
  height: "264px",
  minHeight: 200,

  [theme.breakpoints.down(376)]: {
    height: 120,
    width: 400,
  },

}))

export const ButtonOptionIncanvas = styled('div')(({ theme }) => ({
  width: '25px',
  height: '25px',

  position: 'absolute',
  top: '18%',
  right: '24%',
}))

export const ButtontoaddImgSignature = styled('button')(({ theme }) => ({
  width: 288,
  height: 40,
  color: colors.white,
  backgroundColor: colors.themeSecondColor,
  borderRadius: 4,
  border: 'none',
}))


export const TrashButton = styled('img')(({ theme }) => ({
  position: "absolute",
  cursor: "pointer",
  top: "10%",
  right: "24%",
  zIndex: 3,

  [theme.breakpoints.between('sm', 'lg')]: {
    position: "absolute",
    cursor: "pointer",
    top: "10%",
    right: "16%",
    zIndex: 3,
  },
  [theme.breakpoints.between(375, 426)]: {
    position: 'absolute',
    cursor: 'pointer',
    top: '10%',
    right: 0,
    zIndex: 3
  }

}))

export const PencilButton = styled('div')(({ theme }) => ({
  position: "absolute",
  cursor: "pointer",
  top: "10%",
  right: "24%",
  zIndex: 3,

  [theme.breakpoints.between('sm', 'lg')]: {
    position: 'absolute',
    cursor: 'pointer',
    top: '10%',
    right: '26%',
    zIndex: 3
  },
  [theme.breakpoints.between(375, 426)]: {
    position: 'absolute',
    cursor: 'pointer',
    top: '10%',
    right: '22%'
  }
}))

export const EraserButton = styled('img')(({ theme }) => ({
  position: "absolute",
  cursor: "pointer",
  top: "10%",
  right: "28%",
  zIndex: 3,

  [theme.breakpoints.between('sm', 'lg')]: {
    position: 'absolute',
    cursor: 'pointer',
    top: '10%',
    right: '22%',
    zIndex: 3,
  },
  [theme.breakpoints.between(375, 426)]: {
    position: 'absolute',
    cursor: 'pointer',
    top: '10%',
    right: '13%'
  }
}))