import { handleShow, resetModal, showModal } from 'app/slice/modal.slice'
import Webcam from 'react-webcam'
import React, { useRef, useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import { Box, ClickAwayListener, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SketchField, Tools } from 'react-sketch2'
import iconUndo from 'assets/icon/doctor-note/undo.svg'
import { colors } from 'constants/theme'
import { Button, Col, Dropdown, Row } from 'react-bootstrap'
import ButtonCustom from 'component/Button/ButtonCustom'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { fabric } from 'fabric'

import iconCamera from 'assets/icon/doctor-note/iconCamera.svg'
import iconAddText from 'assets/icon/doctor-note/iconsAddText.svg'
import iconImg from 'assets/icon/doctor-note/img.svg'
import trashOutlined from 'assets/icon/trashOutlined.svg'
import iconPrint from 'assets/icon/iconPrint.png'
import iconEdit from 'assets/icon/doctor-note/iconEdit.png'
import IconPencil from 'assets/icon/doctor-note/iconPencil'
import IconRubber from 'assets/icon/doctor-note/iconRubber'
import IconLasso from 'assets/icon/doctor-note/iconLasso'
import { swalDelete } from 'component/Alert/Swal'
import TreatmentApi from 'api/dentists/treatment.api'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { TemplatePopup } from './template-popup'
import { Icons } from 'constants/images'
import { pdfjs } from 'react-pdf'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import FucntionPrint from 'component/Print/print'
import { ImageLabel } from 'component/Xray/style'
import printIcon from 'assets/icon/carlendar/print.svg'
import InputTextarea from 'component/Input/InputTextarea'
import PageModal from 'new-components/modal/page-modal'
import MainButton from 'new-components/buttons/main-button'
import CachedIcon from '@mui/icons-material/Cached';
import IconButton from 'new-components/buttons/icon-button'
import CameraAltIcon from '@mui/icons-material/CameraAlt';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const ImgIconDoctorNote = styled('img')<{ brushColor?: string }>(({ theme, brushColor }) => ({
  maxWidth: 30,
  maxHeight: 30,
  '&.disabled': { opacity: 0.7, '&:hover': { backgroundColor: colors.white } },
  '&.active': {
    borderRadius: '4px',
    border: `2.5px solid ${brushColor || colors.lightGray}`,
    backgroundColor: colors.lightGray
  },
  '&:hover': { borderRadius: '4px', backgroundColor: colors.lightGray }
}))

const ButtonTool = styled('div')<{ brushColor?: string }>(({ theme, brushColor }) => ({
  display: 'inline-block',
  '&:hover': {
    borderRadius: '4px',
    backgroundColor: colors.lightGray
  },
  '&.active': {
    borderRadius: '4px',
    backgroundColor: colors.lightGray
  },
  svg: {
    maxWidth: 30,
    maxHeight: 30
  }
}))

const ToolsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: 0,
  transform: 'translateX(-50%)',
  zIndex: 1,
  backgroundColor: colors.lightGray,
  borderRadius: 100,
  padding: '6px 16px',
  [theme.breakpoints.down('lg')]: {
    padding: '4px 10px'
  }
}))

const ToolsButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 'unset',
  width: 30,
  height: 30,
  backgroundColor: colors.extraLightGray,
  borderRadius: 100,
  color: colors.textPrimary,
  svg: {
    fontSize: 18
  },
  '&.btn-reset-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '8.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&.btn-print-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&.btn-preview-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '4.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&:hover': {
    backgroundColor: colors.gray
  },
  [theme.breakpoints.down('lg')]: {
    width: 24,
    height: 24
  }
}))

export type StatusNoteProps = 'WRITE' | 'TEXT'
interface CanvasProps {
  submit?: (val: any, object: any) => void
  loadDoctorNote?: () => void
  setNewPage?: (val: any) => void
  dataUrl?: any
  doctorNoteId: number
  brushColor?: string
  brushSize?: number
  hasDoctorNote?: any
  hasCreateDoctorNote?: any
  isHistoryTreatment?: any
  hasDeleteDoctorNote?: string
  statusNote: StatusNoteProps
  setStatusNode: (status: StatusNoteProps) => void
  submitPrint: () => void
  textDoctorNote: string
  setTextDoctorNote: (data: string) => void
  dataObject?: any
}

function CanvasDisign(props: CanvasProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const componentRef = useRef<HTMLDivElement>(null)
  const webcamRef = useRef<Webcam>(null)

  const paletteColors: string[] = colors.paletteColorsDoctorNote
  const [brushColor, setBrushColor] = useState<string>(colors.black)
  const [brushSize, setBrushSize] = useState<number>(3)
  const modal: boolean = useSelector(handleShow)
  const [toolType, setToolType] = useState<'Pencil' | 'Line' | 'Rectangle' | 'Circle' | 'Select' | 'Pan' | 'Rubber' | 'Highlighter'>('Select')
  const [tool, setTool] = useState<any>(Tools.Select)

  /** data use */

  const [isTemplatePopup, setIsTemplatePopup] = useState<boolean>(false)
  const [isZoomDoctorNote, setIsZoomDoctorNote] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState<boolean>(true)
  const [insertMode, setInsertMode] = useState<any>()
  const [opacity, setOpacity] = useState(1)
  const [backgroundColor, setBackgroundColor] = useState<any>(colors.white)
  const sketchRef = useRef<SketchField | null>(null)
  const [prints, setPrints] = useState<any>()
  const [openWebcam, setOpenWebcam] = useState(false)
  const [tempImgData, setTempImgData] = useState<any>()
  const [tempImageSrc, setTempImageSrc] = useState<any>(null)
  const [colorSelect, setColorSelect] = useState<any>(1)
  const [colorUse1, setColorUse1] = useState<any>(colors.black)
  const [colorUse2, setColorUse2] = useState<any>('#B00020')
  const [colorUse3, setColorUse3] = useState<any>('#2F4269')
  const [history, setHistory] = useState<any>()
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const [permissionStatus, setPermissionStatus] = useState<string>('unknown');
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');

  sketchRef.current?._history.print()

  const capture = useCallback(() => {
    const imageSrc: any = webcamRef.current?.getScreenshot()

    setTempImgData(imageSrc)
    setTempImageSrc(imageSrc)
  }, [webcamRef])

  const cancelwebcam = () => {
    setOpenWebcam(false)
    setTempImgData(null)
    setTempImageSrc(null)
  }

  const savePicture = () => {
    setOpenWebcam(false)
    sketchRef.current?.addImg(tempImgData, { top: 100, left: 100, scale: 0.8 })

    setTempImgData(null)
    setTempImageSrc(null)
  }

  const handleTool = (toolType: any) => {
    setOpacity(1)
    const canvas = sketchRef?.current?._fc
    if (toolType === 'Pencil') {
      canvas.freeDrawingBrush = new fabric.PencilBrush(canvas)
      setTool(Tools.Pencil)
    }
    if (toolType === 'Line') setTool(Tools.Line)
    if (toolType === 'Rectangle') setTool(Tools.Rectangle)
    if (toolType === 'Circle') setTool(Tools.Circle)
    if (toolType === 'Select') {
      setTool(Tools.Select)
      canvas.forEachObject(function (object: any) {
        object.evented = true // Select the object
      })
      canvas.discardActiveObject()
      canvas.forEachObject(function (object: any) {
        canvas.setActiveObject(object)
        canvas.discardActiveObject()
      })
      canvas.requestRenderAll()
    }
    if (toolType === 'Pan') setTool(Tools.Pan)
    if (toolType === 'Text') setTool(Tools.Text)
    if (toolType === 'Rubber') {
      setTool(Tools.Pencil)
      canvas.freeDrawingBrush = new fabric.EraserBrush(canvas)
      canvas.isDrawingMode = true
      canvas.discardActiveObject()
    }
    if (toolType === 'Arrow') setTool(Tools.Arrow)
    if (toolType === 'Highlighter') {
      canvas.freeDrawingBrush = new fabric.PencilBrush(canvas)
      setTool(Tools.Pencil)
      setOpacity(0.5)
    }
    canvas.freeDrawingBrush.width = brushSize
    canvas.freeDrawingBrush.color = brushColor
  }

  const handleRemoveSelected = () => {
    if (sketchRef.current) {
      sketchRef.current.removeSelected()
    }
    return true
  }

  const handleAddText = () => {
    if (sketchRef.current) {
      sketchRef.current.addText('ADD TEXT HERE')
      setTool(Tools.Select)
      setToolType('Select')
    }
  }

  const handleChangeColor = (pColor: string) => {
    if (colorSelect === 1) {
      setColorUse1(pColor)
    } else if (colorSelect === 2) {
      setColorUse2(pColor)
    } else if (colorSelect === 3) {
      setColorUse3(pColor)
    }
    setBrushColor(pColor)

    if (toolType === 'Highlighter') {
      const color = pColor
      const opacity = '50'
      const highlighter = color + opacity
      setBrushColor(highlighter)
    }
  }

  const handleAddImage = (accepted: any) => {
    const canvas = sketchRef?.current?._fc
    if (accepted && accepted.length > 0) {
      const reader = new FileReader()

      reader.onload = (event: any) => {
        const dataUrl = event.target.result
        // sketchRef.current?.addImg(dataUrl, { top: 200, left: 200, scale: 0.3 })
        fabric.Image.fromURL(dataUrl, (image) => {
          image.clone((img: any) => {
            canvas.renderAll()
          })
          image.scaleToWidth(400)
          image.set({
            left: 100,
            top: 100,
            // erasable: false
          })
          canvas.add(image)
          setTool(Tools.Select)
          setToolType('Select')
          // })
        })
      }
      reader.readAsDataURL(accepted[0])
    }
  }
  const defaultTool = () => {
    if (sketchRef.current) {
      sketchRef.current.addText('')
      // setToolType('Rubber')
      sketchRef.current._fc.freeDrawingBrush = new fabric.EraserBrush(sketchRef.current._fc)
      // sketchRef.current._fc.isDrawingMode = true
      setTool(Tools.Select)
      // setTool(Tools.Pencil)
      if (props.isHistoryTreatment) {
        setToolType('Select')
      }
      sketchRef.current._fc.freeDrawingBrush = new fabric.PencilBrush(sketchRef.current._fc)
      sketchRef.current._fc.freeDrawingBrush.width = brushSize
      sketchRef.current._fc.freeDrawingBrush.color = brushColor
    }
  }

  const handleObj = (obj: any, evt?: string) => {
    const canvas = sketchRef?.current?._fc
    const activeObj = canvas.getActiveObject()
    const item = obj.target

    if (toolType === 'Highlighter' && evt === 'mouseUp') {
      setTool(Tools.Select)
      setTimeout(() => {
        setTool(Tools.Pencil)
        setToolType('Highlighter')
      }, 1)
    }
    if (item && toolType !== 'Rubber') {
      if (item?.stroke !== null) {
        item.hasControls = false
        canvas.renderAll()
      }
    }

    if (activeObj && toolType !== 'Rubber') {
      activeObj.transparentCorners = false
      activeObj.cornerColor = 'blue'
      activeObj.cornerStyle = 'circle'
      if (_.some(activeObj._objects, (i: any) => i?.stroke !== null)) {
        activeObj.hasControls = false
      }

      canvas.renderAll()
    }
    if (item?.name === 'template') {
      item.selectable = false
      item.evented = false
      canvas.renderAll()
      canvas.discardActiveObject()
    }
  }

  const handleUndo = () => {
    const canvas = sketchRef.current?._fc
    canvas.discardActiveObject()
    if (sketchRef.current?._history) {
      if (sketchRef.current.canUndo()) {
        sketchRef.current.undo()
        handleTool('Select')
        setTimeout(() => {
          handleTool(toolType)
        }, 1)
      }
      if (history && !sketchRef.current.canUndo()) {
        sketchRef.current.fromJSON(history)
        setHistory(null)
      }
    }
  }
  const handleRedo = () => {
    if (sketchRef.current) {
      sketchRef.current.redo()
    }
  }
  const handleClearCanvas = async () => {
    if (sketchRef.current) {
      const history = sketchRef.current.clear()
      setHistory(history)
      setBackgroundColor(colors.white)
    }
  }
  const handleSubmit = () => {

    if (props.statusNote === 'TEXT') {
      props?.submitPrint()
      return
    }

    if (sketchRef.current) {
      const dataURL = sketchRef.current.toDataURL()
      const dataObject = sketchRef.current.toJSON()
      if (dataURL) {
        props.submit?.(dataURL, dataObject)
      }
    }
  }
  const handleInsert = async (img: any, insertMode: any) => {
    setIsTemplatePopup(false)
    const canvasfc = sketchRef?.current?._fc
    if (insertMode === 'image') {
      fabric.Image.fromURL(img.src, (image) => {
        image.clone((img: any) => {
          canvasfc.renderAll()
        })
        image.scaleToWidth(400)
        image.set({
          left: 100,
          top: 100,
          // erasable: false
        })
        canvasfc.add(image)
        setTool(Tools.Select)
        setToolType('Select')
      })
    }

    if (insertMode === 'template') {
      const pdf = await pdfjs.getDocument(img.src).promise
      const page = await pdf.getPage(1)

      const canvas = document.createElement('canvas')
      const viewport = page.getViewport({ scale: 1.0 })

      canvas.width = viewport.width
      canvas.height = viewport.height

      const context = canvas.getContext('2d')
      if (context !== null) {
        await page.render({ canvasContext: context, viewport }).promise
      }
      // Convert canvas image to Data URL
      const dataURL = canvas.toDataURL('image/png')
      // sketchRef?.current?.setBackgroundFromDataUrl(dataURL)
      fabric.Image.fromURL(dataURL, (image) => {
        image.set({
          left: 0,
          top: 0,
          // erasable: false,
          backgroundColor: '#ffff',
          selectable: false,
          evented: false,
          name: 'template'
          // scaleY: 700
        })
        image.scaleToHeight(750, false)

        canvasfc.add(image)
        image.selectable = false
        canvasfc.renderAll()
      })
    }
  }

  const handleData = () => {
    if (props.dataObject) {
      sketchRef?.current?.fromJSON(JSON.parse(props.dataObject))
    } else {
      sketchRef?.current?.setBackgroundFromDataUrl(props.dataUrl)
    }
  }

  const handleTemplate = async (insertMode: any) => {
    setInsertMode(insertMode)
    dispatch(showModal())
    setIsTemplatePopup(true)
  }

  const handleClassName = (condition: any) => {
    if (condition === 'UNPERMISS') {
      return 'pe-none opacity-0'
    } else if (condition === 'UNDO') {
      if (!sketchRef.current?.canUndo()) {
        return 'none'
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  const handleStyleSelect = (condition: number) => {
    if (colorSelect === condition) {
      return `3px solid ${colors.themeSecondColor80}`
    } else {
      return ''
    }
  }

  const handleColorSelect = (condition: number) => {
    if (colorSelect === condition) {
      return 'active'
    } else {
      return ''
    }
  }

  const hadleToolSelect = (condition: string) => {
    if (toolType === condition) {
      return 'active'
    } else {
      return ''
    }
  }

  const hadleBrushSize = (condition: number) => {
    if (brushSize === condition) {
      return 'active'
    } else {
      return ''
    }
  }

  const hadleToolColor = (condition: string, color: string) => {
    if (toolType === condition) {
      return color
    } else {
      return ''
    }
  }

  const onCancelDortorNote = () => {
    if (props.statusNote === 'WRITE') {
      if (props.doctorNoteId === 0) {
        props.loadDoctorNote?.()
        props.setNewPage?.(0)
        setBrushColor(colors.black)
        defaultTool()
      } else {
        props.loadDoctorNote?.()
        props.setNewPage?.(0)
        defaultTool()
      }
    } else {
      props.setTextDoctorNote("")
      props.loadDoctorNote?.()
    }
  }

  const handleCancelEdit = async () => {
    swalDelete(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DOCTOR_NOTE.ALERT.CONFIRM_CANCEL_EDIT')}</p>`,
      ``,
      (res: any) => {
        if (res) {

          handleClearCanvas()
          onCancelDortorNote()
          // setIsEdit(false)
        }
      },
      { confirmButtonText: t(`DOCTOR_NOTE.BUTTON.EDIT_NOTE`), cancelButtonText: t('BUTTON.CANCEL') }
    )
  }

  const detroy = async (valueDoctorNoteId: number) => {
    let notiProps = ''
    if (valueDoctorNoteId === 0) return
    if (!props.isHistoryTreatment) notiProps = 'register-page'
    swalDelete(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DOCTOR_NOTE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="text-center mb-0 mx-auto" style="max-width: 306px">${t('DOCTOR_NOTE.ALERT.CONFIRM_DELETE_DETAIL')} </p>`,
      (res: any) => {
        if (res) {
          if (props.hasDeleteDoctorNote === 'PERMISS') {
            TreatmentApi.doctorNoteDelete(valueDoctorNoteId)
              .then((resp: any) => {
                if (resp.status === 200) {
                  notiSuccess(t(`DOCTOR_NOTE.MESSAGE.SUCCESS.DELETE`), '', null, null, notiProps)
                  props.loadDoctorNote?.()
                  props.setNewPage?.(0)
                }
              })
              .catch((e) => {
                const err = e.response.data
                notiError(t(`DOCTOR_NOTE.MESSAGE.${err.message}`), '', null, null, notiProps)
              })
          } else if (props.hasDeleteDoctorNote !== 'PERMISS') {
            notiError(t(`DOCTOR_NOTE.MESSAGE.ERROR.NOTRIGHT`))
          }
        }
      },
      { confirmButtonText: t(`DOCTOR_NOTE.BUTTON.DEL_NOTE`), cancelButtonText: t('BUTTON.CANCEL') }
    )
  }

  useEffect(() => {
    navigator.permissions
      .query({ name: 'camera' as PermissionName })
      .then((result) => {
        setPermissionStatus(result.state);

        // ตรวจสอบการเปลี่ยนแปลงสถานะการอนุญาต
        result.onchange = () => {
          setPermissionStatus(result.state);
        };
      })
      .catch(() => {
        setPermissionStatus('not supported');
      });
    if (toolType === 'Rubber') {
      setBrushColor(colors.white)
    } else {
      if (colorSelect === 1) {
        setBrushColor(colorUse1)
      } else if (colorSelect === 2) {
        setBrushColor(colorUse2)
      } else if (colorSelect === 3) {
        setBrushColor(colorUse3)
      }
    }

    if (toolType === 'Highlighter') {
      const opacity = '50'
      if (colorSelect === 1) {
        const highlighter = colorUse1 + opacity
        setBrushColor(highlighter)
      } else if (colorSelect === 2) {
        const highlighter = colorUse2 + opacity
        setBrushColor(highlighter)
      } else if (colorSelect === 3) {
        const highlighter = colorUse3 + opacity
        setBrushColor(highlighter)
      }
    }
  }, [colorSelect, brushSize, toolType])
  useEffect(() => {
    if (!modal) {
      setIsTemplatePopup(false)
      setIsZoomDoctorNote(false)
      dispatch(resetModal())
    }
  }, [modal])

  useEffect(() => {
    if (props.doctorNoteId === 0) {
      handleClearCanvas()
    } else {
      handleClearCanvas()
      handleData()
    }
    defaultTool()
    if (!isEdit) {
      const canvas = sketchRef.current?._fc
      canvas.isDrawingMode = false
      setHistory('')
    }
  }, [props.doctorNoteId, props.dataUrl, isZoomDoctorNote])

  // useEffect(() => {
  //   const canvas = sketchRef.current?._fc
  //   canvas.isDrawingMode = true
  // }, [])
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.key === 'Backspace' && (event.metaKey || event.ctrlKey)) || event.key === 'Delete') {
        handleRemoveSelected()
      }
    }
    const handleMouseUp = (event: MouseEvent) => {
      handleRemoveSelected()
    }
    window.addEventListener('keydown', handleKeyDown)
    if (toolType === 'Rubber') {
      window.addEventListener('mousedown', handleMouseUp)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('mousedown', handleMouseUp)
    }
  }, [toolType])

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  return (
    <>
      {isEdit ? (
        <Row className={`p-2 mx-0 ${props.statusNote !== 'WRITE' && 'justify-content-between'}`} style={{ borderBottom: `1px solid ${colors.lightGray}` }}>
          <Col className={`col-auto px-1`}>
            <ButtonCustom
              btnStyle={{
                height: 30,
                fontWeight: 400,
                '&.MuiButton-outlined': {
                  color: 'black !Important',
                  borderColor: 'black !Important'
                }
              }}
              variant={"outlined"}
              textButton={t(`DOCTOR_NOTE.BUTTON.DOCTORNOTE_TYPE_${props.statusNote === "WRITE" ? "TEXT" : "WRITE"}`)}
              startIcon={<div>T</div>}
              onClick={() => {
                handleClearCanvas()
                if (props.statusNote === 'WRITE') {
                  props.setStatusNode('TEXT')
                  props.setTextDoctorNote("")
                } else {
                  props.setTextDoctorNote("")
                  props.loadDoctorNote?.()
                  props.setNewPage?.(0)
                  setBrushColor(colors.black)
                  defaultTool()
                  props.setStatusNode('WRITE')
                }
              }}
            />
          </Col>
          {!props.isHistoryTreatment &&
            <>
              <Col className={`col-auto px-1 ${handleClassName(props.hasDoctorNote)} ${props.statusNote !== 'WRITE' && 'd-none'}`}>
                <button style={{ border: 'none', background: 'none' }} onClick={() => handleUndo()} >
                  <ImgIconDoctorNote src={iconUndo} />
                </button>
                <button style={{ border: 'none', background: 'none' }} onClick={() => handleRedo()} >
                  <ImgIconDoctorNote className="flip" src={iconUndo} />
                </button>
              </Col>
              <Col className={`col-auto mx-auto ${handleClassName(props.hasDoctorNote)} ${props.statusNote !== 'WRITE' && 'd-none'}`}>
                <Dropdown className="d-inline-flex" drop={'down'} align={'start'}>
                  <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="text-black-50 btn-pencil">
                    <ButtonTool
                      onClick={() => {
                        handleTool('Pencil')
                        setToolType('Pencil')
                      }}
                      brushColor={brushColor}
                      className={`${hadleToolSelect('Pencil')}`}
                    >
                      <IconPencil color={hadleToolColor('Pencil', brushColor)} />
                    </ButtonTool>
                  </Dropdown.Toggle>
                </Dropdown>
                <ButtonTool
                  onClick={() => {
                    handleTool('Rubber')
                    setToolType('Rubber')
                  }}
                  className={`${hadleToolSelect('Rubber')}`}
                >
                  <IconRubber active={brushColor === colors.white} color={hadleToolColor('Rubber', 'black')} />
                </ButtonTool>
                <Dropdown className="d-inline-flex" drop={'down'} align={'start'}>
                  <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="text-black-50 btn-pencil">
                    <ButtonTool
                      onClick={() => {
                        handleTool('Highlighter')
                        setToolType('Highlighter')
                      }}
                      brushColor={brushColor}
                      className={`${hadleToolSelect('Highlighter')}`}
                    >
                      <Icons.IconHighlightPen color={hadleToolColor('Highlighter', brushColor)} />
                    </ButtonTool>
                  </Dropdown.Toggle>
                </Dropdown>
                <ImgIconDoctorNote src={iconAddText} onClick={() => handleAddText()} />
                <ButtonTool
                  onClick={() => {
                    handleTool('Select')
                    setToolType('Select')
                  }}
                  className={`${hadleToolSelect('Select')}`}
                >
                  <IconLasso active={brushColor === colors.white} color={hadleToolColor('Select', 'black')} />
                </ButtonTool>
                <ImgIconDoctorNote className="" src={iconCamera} onClick={() => setOpenWebcam(true)} />
                <ImgIconDoctorNote className="" src={iconImg} onClick={() => handleTemplate('image')} />
                {/* hidden TemplateNote */}
                {/* {' | '}
            <ImgIconDoctorNote src={iconTemplateNote} onClick={() => handleTemplate('template')} />
            <span style={{ fontSize: '14px', color: '#929292' }}>{' Template Note'}</span> */}

                {toolType !== 'Rubber' && (
                  <>
                    {' | '}
                    <div style={{ display: 'inline-block', position: 'relative', top: 5 }}>
                      <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
                        <Dropdown show={showDropdown} autoClose="outside" className="d-inline-flex" drop={'down'} align={'end'}>
                          <Dropdown.Toggle onClick={() => setShowDropdown(true)} variant="link" bsPrefix="p-0  " className="text-black-50 btn-pencil">
                            <ButtonTool
                              className={`px-1 col-3 ${handleColorSelect(1)}`}
                              style={{ backgroundColor: colorUse1, cursor: 'pointer', width: 20, height: 20, border: handleStyleSelect(1), borderRadius: '50%' }}
                              onClick={() => setColorSelect(1)}
                            ></ButtonTool>
                            <ButtonTool
                              className={`px-1 col-3 ${handleColorSelect(2)}`}
                              style={{ backgroundColor: colorUse2, cursor: 'pointer', width: 20, height: 20, marginLeft: '5px', border: handleStyleSelect(2), borderRadius: '50%' }}
                              onClick={() => setColorSelect(2)}
                            ></ButtonTool>
                            <ButtonTool
                              className={`px-1 col-3 ${handleColorSelect(3)}`}
                              style={{ backgroundColor: colorUse3, cursor: 'pointer', width: 20, height: 20, marginLeft: '5px', border: handleStyleSelect(3), borderRadius: '50%' }}
                              onClick={() => setColorSelect(3)}
                            ></ButtonTool>
                          </Dropdown.Toggle>
                          <Dropdown.Menu onClick={() => setShowDropdown(false)} className="p-2 palette" align={'end'} style={{ fontSize: '16px', width: '200px', inset: '0px 0px auto' }}>
                            <Row className="mx-0">
                              <Col className="px-2">
                                <Row className="mx-0">
                                  {_.map(paletteColors, (pColor: any, i: number) => (
                                    <Col className={`px-1 col-3 palette-color ${brushColor === pColor && 'active'}`} style={{ backgroundColor: pColor }} onClick={() => handleChangeColor(pColor)}></Col>
                                  ))}
                                </Row>
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      </ClickAwayListener>
                    </div>
                  </>
                )}
                {' | '}
                {toolType !== 'Highlighter' && (
                  <div style={{ display: 'inline-flex', position: 'relative', top: '2px' }}>
                    <ButtonTool
                      className={`${hadleBrushSize(3)}`}
                      style={{ width: 25, height: 25, flexWrap: 'wrap', alignContent: 'center', justifyItems: 'center', display: 'inline-flex', justifyContent: 'center' }}
                      onClick={() => {
                        if (brushSize !== 3) {
                          setBrushColor('')
                          setBrushSize(3)
                        }
                      }}
                    >
                      <div style={{ backgroundColor: colors.black, cursor: 'pointer', width: 8, height: 8, border: `1px solid ${colors.lightGray}`, borderRadius: '50%' }}></div>
                    </ButtonTool>
                    <ButtonTool
                      className={`${hadleBrushSize(15)}`}
                      style={{ width: 25, height: 25, flexWrap: 'wrap', alignContent: 'center', justifyItems: 'center', display: 'inline-flex', justifyContent: 'center' }}
                      onClick={() => {
                        if (brushSize !== 15) {
                          setBrushColor('')
                          setBrushSize(15)
                        }
                      }}
                    >
                      <div style={{ backgroundColor: colors.black, cursor: 'pointer', width: 15, height: 15, border: `1px solid ${colors.lightGray}`, borderRadius: '50%' }}></div>
                    </ButtonTool>
                    <ButtonTool
                      className={`${hadleBrushSize(25)}`}
                      style={{ width: 25, height: 25, flexWrap: 'wrap', alignContent: 'center', justifyItems: 'center', display: 'inline-flex', justifyContent: 'center' }}
                      onClick={() => {
                        if (brushSize !== 25) {
                          setBrushColor('')
                          setBrushSize(25)
                        }
                      }}
                    >
                      <div style={{ backgroundColor: colors.black, cursor: 'pointer', width: 22, height: 22, border: `1px solid ${colors.lightGray}`, borderRadius: '50%' }}></div>
                    </ButtonTool>
                  </div>
                )}
                {toolType === 'Highlighter' && (
                  <div style={{ display: 'inline-flex', position: 'relative', top: '-2px' }}>
                    <ButtonTool
                      className={`${hadleBrushSize(3)}`}
                      style={{ width: 25, height: 25, flexWrap: 'wrap', alignContent: 'center', justifyItems: 'center', display: 'inline-flex', justifyContent: 'center' }}
                      onClick={() => {
                        if (brushSize !== 3) {
                          setBrushColor('')
                          setBrushSize(3)
                        }
                      }}
                    >
                      <div style={{ backgroundColor: colors.black, cursor: 'pointer', width: 20, height: 5, border: `1px solid ${colors.lightGray}`, borderRadius: '25%' }}></div>
                    </ButtonTool>
                    <ButtonTool
                      className={`${hadleBrushSize(15)}`}
                      style={{ width: 25, height: 25, flexWrap: 'wrap', alignContent: 'center', justifyItems: 'center', display: 'inline-flex', justifyContent: 'center' }}
                      onClick={() => {
                        if (brushSize !== 15) {
                          setBrushColor('')
                          setBrushSize(15)
                        }
                      }}
                    >
                      <div style={{ backgroundColor: colors.black, cursor: 'pointer', width: 20, height: 8, border: `1px solid ${colors.lightGray}`, borderRadius: '25%' }}></div>
                    </ButtonTool>
                    <ButtonTool
                      className={`${hadleBrushSize(25)}`}
                      style={{ width: 25, height: 25, flexWrap: 'wrap', alignContent: 'center', justifyItems: 'center', display: 'inline-flex', justifyContent: 'center' }}
                      onClick={() => {
                        if (brushSize !== 25) {
                          setBrushColor('')
                          setBrushSize(25)
                        }
                      }}
                    >
                      <div style={{ backgroundColor: colors.black, cursor: 'pointer', width: 20, height: 12, border: `1px solid ${colors.lightGray}`, borderRadius: '25%' }}></div>
                    </ButtonTool>
                  </div>
                )}
              </Col>
              <Col className={`row col-auto`}>
                <Col className={`col-auto px-1 ${handleClassName(props.hasDoctorNote)}`}>
                  <ButtonCustom btnStyle={{ height: 30 }} mode={'del'} textButton={t('DOCTOR_NOTE.BUTTON.CANCEL_EDIT')} onClick={() => handleCancelEdit()} />
                </Col>
                <Col className={`col-auto px-1 ${handleClassName(props.hasDoctorNote)}`}>
                  <ButtonCustom
                    btnStyle={{ height: 30 }}
                    variant="outlined"
                    textButton={t('DOCTOR_NOTE.BUTTON.RESET')}
                    onClick={() => {
                      props.setTextDoctorNote("")
                      handleClearCanvas()
                    }}
                  />
                </Col>
                <Col className={`col-auto px-1 ${handleClassName(props.hasDoctorNote)}`}>
                  <ButtonCustom disabled={props?.hasCreateDoctorNote === 'UNPERMISS' ? true : false} btnStyle={{ height: 30 }} textButton={t('DOCTOR_NOTE.BUTTON.SAVE')} onClick={handleSubmit} />
                </Col>
              </Col>
            </>
          }

        </Row>
      ) : (
        <Row className="p-2 mx-0" style={{ borderBottom: `1px solid ${colors.lightGray}` }}>
          <Col className={`col-auto px-1 ${handleClassName(props.hasDoctorNote)}`}></Col>
          <Col className={`col-auto mx-auto ${handleClassName(props.hasDoctorNote)}`}></Col>
          {!props.isHistoryTreatment && (
            <Col className={`col-auto px-1 ${handleClassName(props.hasDoctorNote)}`}>
              <ImgIconDoctorNote className="" src={iconEdit} onClick={() => {
                setIsEdit(true)
                if (props.hasCreateDoctorNote === 'UNPERMISS') {
                  notiError(t(`DOCTOR_NOTE.MESSAGE.ERROR.NOTADD`))
                }
              }} />
            </Col>
          )}
          {!props.isHistoryTreatment && (
            <Col className={`col-auto px-1 ${handleClassName(props.hasDoctorNote)}`}>
              <ImgIconDoctorNote className="" src={trashOutlined} onClick={() => detroy(props.doctorNoteId)} />
            </Col>
          )}
          <FucntionPrint
            content={() => componentRef?.current?.lastChild}
            docTitle="PreviewImg"
            removeIfram
            trigger={() => (
              <Col className={`col-auto px-1 ${props.hasDoctorNote === 'UNPERMISS' ? 'pe-none opacity-0' : ''}`}>
                <ImgIconDoctorNote className="" src={iconPrint} onClick={() => setPrints(true)} />
              </Col>
            )}
            style={'@page { size:  600.7007874px 700.519685px; } @media print { body { -webkit-print-color-adjust: exact; } '}
          />
        </Row>
      )}
      {props.statusNote === "WRITE" ?
        <Box className="position-relative h-100 overflow-hidden" style={{ border: `1px solid ${colors.lightGray}` }}>
          <TransformWrapper centerOnInit panning={{ disabled: true }} doubleClick={{ disabled: true }}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <ToolsButton onClick={() => resetTransform()} className={`btn-reset-position  d-none`}>
                  {t('BUTTON.RESET')}
                </ToolsButton>
                <ToolsBox className={`d-flex align-items-center justify-content-center mb-2 'd-none'`}>
                  <ToolsButton onClick={() => zoomOut()} className="mr-1 mr-xl-2">
                    <RemoveIcon />
                  </ToolsButton>
                  <ToolsButton onClick={() => zoomIn()} className="ml-1 ml-xl-2">
                    <AddIcon />
                  </ToolsButton>
                </ToolsBox>
                <TransformComponent wrapperClass="w-100 h-100" contentClass="w-100" contentStyle={{ cursor: 'move', height: '100%', backgroundColor: colors.lightGray, display: 'flex', justifyContent: 'center' }}>
                  <div className={`d-flex flex-center ${handleClassName(props.hasDoctorNote)}`} style={{ backgroundColor: colors.lightGray }}>
                    <div className="position-relative" style={{ border: `1px solid ${colors.black}` }} ref={componentRef}>
                      <SketchField
                        className="canvas-area"
                        style={{ backgroundColor: colors.white }}
                        ref={sketchRef}
                        opacity={opacity}
                        width={550}
                        height={700}
                        // tool={isEdit === true ? tool : 'default-tool'}
                        tool={tool}
                        lineColor={brushColor}
                        lineWidth={brushSize}
                        widthCorrection={2}
                        heightCorrection={0}
                        undoSteps={50}
                        backgroundColor={backgroundColor}
                        onObjectAdded={(obj: any) => handleObj(obj, 'addObj')}
                        onMouseUp={(obj: any) => handleObj(obj, 'mouseUp')}
                        onMouseDown={(obj: any) => handleObj(obj, 'mouseDown')}
                        onMouseMove={(obj: any) => handleObj(obj, 'mouseMove')}

                      />
                    </div>
                  </div>
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Box> :
        <Box sx={{ '.MuiInputBase-input': { minHeight: '350px', height: '100% !important' } }}>
          <InputTextarea
            placeholder={t('DOCTOR_NOTE.INPUT_TEXT')}
            value={props.textDoctorNote}
            onchange={(e) => props.setTextDoctorNote(e.target.value)}
            disabled={props.isHistoryTreatment}
          />
        </Box>
      }
      {prints && (
        <>
          <FucntionPrint
            content={() => props.dataUrl}
            docTitle="PreviewImg"
            removeIfram
            trigger={() => (
              <ToolsBox className={`btn-print-position d-none`}>
                <ImageLabel src={printIcon} className="align-baseline" />
              </ToolsBox>
            )}
            style={'@page { size:  793.7007874px 1122.519685px;  margin: 1cm; } @media print { body { -webkit-print-color-adjust: exact; } '}
          />
        </>
      )}
      <TemplatePopup isShow={isTemplatePopup} insertMode={insertMode} onInsert={(img: any, insertMode: any) => handleInsert(img, insertMode)} onCancel={() => setIsTemplatePopup(false)} onUploadImg={(e: any) => handleAddImage(e)} />
      <PageModal title="" size='S' isOpen={openWebcam} handleClose={() => cancelwebcam()} onCancel={{ onClick: () => cancelwebcam() }} onSubmit={{ onClick: () => savePicture(), disabled: tempImageSrc === null }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="16px" position='relative'>
          {tempImageSrc === null ?
            permissionStatus === 'denied' ? <Box width={500} height={375} bgcolor="white" border="2px dashed #E5E5E5" display="flex" justifyContent="center" alignItems="center"><CameraAltIcon style={{ color: '#A8A8A8' }} sx={{ width: "60px", height: '60px' }} /></Box>
              :
              <Webcam ref={webcamRef} width={500} height={375} screenshotFormat="image/jpeg" videoConstraints={{ facingMode: facingMode }} /> : <img src={tempImageSrc} style={{ height: 375, width: 500 }} />
          }
          <Box display="flex" justifyContent="space-between" alignItems="center" width="500px" position="relative">
            <IconButton icon={<CachedIcon />} size='medium' type='primary' variant='outlined' onClick={toggleCamera}></IconButton>
            <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}><MainButton title={tempImageSrc === null ? t('DOCTOR_NOTE.BUTTON.CAPTURE') : 'ถ่ายรูปภาพใหม่'} onClick={() => tempImageSrc === null ? capture() : setTempImageSrc(null)} type='primary' variant='outlined' /></Box>
          </Box>
        </Box>
      </PageModal>
    </>
  )
}

export default CanvasDisign
