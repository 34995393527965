import { menuSeconds, menuThirds } from 'constants/menus'

import Approval from 'features/approval/approval'
import FormApproval from 'features/approval/form-approval'
import ApprovelList from 'features/approvel-list/approvel-list'
import ChangeCost from 'features/approvel-list/list/change-cost'
import PaymentDetail from 'features/finance/payment/payment-detail'
import { routeName } from 'routes/routes-name'

const routeApproval = [
    {
        ...menuThirds.APPROVAL,
        key: menuThirds.APPROVAL.key,
        name: menuThirds.APPROVAL.name,
        textName: menuThirds.APPROVAL.textName,
        active: false,
        appBar: true,
        // path: routeName.approval,
        component: Approval
    },
    {
        ...menuThirds.APPROVAL,
        key: menuThirds.APPROVAL.key,
        name: menuThirds.APPROVAL.name,
        textName: menuThirds.APPROVAL.textName + 'add',
        active: false,
        appBar: true,
        path: routeName.approval + '/create',
        component: FormApproval
    },
    {
        ...menuThirds.APPROVAL,
        key: menuThirds.APPROVAL.key,
        name: menuThirds.APPROVAL.name,
        textName: menuThirds.APPROVAL.textName + 'edit',
        active: false,
        appBar: true,
        path: routeName.approval + '/update',
        component: FormApproval
    },
    {
        ...menuThirds.APPROVELIST,
        key: menuThirds.APPROVELIST.key,
        name: menuThirds.APPROVELIST.name,
        textName: menuThirds.APPROVELIST.textName,
        active: false,
        appBar: true,
        component: ApprovelList
    },
    {
        ...menuThirds.APPROVELIST,
        key: menuThirds.APPROVELIST.key,
        name: menuThirds.APPROVELIST.name,
        textName: menuThirds.APPROVELIST.textName,
        active: false,
        appBar: true,
        path: routeName.approvelListCost,
        component: ChangeCost
    },
    {
        ...menuThirds.APPROVELIST,
        key: menuThirds.APPROVELIST.key,
        name: menuThirds.APPROVELIST.name,
        textName: menuThirds.APPROVELIST.textName,
        active: false,
        appBar: true,
        path: routeName.approvelListCancelReciept,
        component: PaymentDetail
    },
]

export default routeApproval