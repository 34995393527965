import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Grid, MenuItem, Typography, Box } from '@mui/material'

import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { CardSalesProps, handleChangeFilterSelect, renderFilterSelect } from 'features/dashboard/dashboard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** UTILS || SLICE */
import { numberFormat } from 'utils/app.utils'

/** API */
import { BranchInterface } from 'api/master/clinic.api'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as UseStyled from 'features/dashboard/useStyled'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DashboardApi, { IFSellerTreatment, IFSumeryFilter } from 'api/dashboard.api'
import { KEY_STORAGE } from 'constants/common'

const bg = colors.dashboard.saleOperative

const Arrow = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: 100,
  backgroundColor: colors.extraLightGray,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'rotate(45deg)',
  svg: {
    fill: colors.textExtraLightGray,
    fontSize: 16
  }
}))

export default function SalesOperative(props: CardSalesProps) {
  const { t } = useTranslation()

  const labels = [t('DASHBOARD.OPERATIVE_CHART.DF'), t('DASHBOARD.OPERATIVE_CHART.CF')]

  const [data, setData] = useState({
    labels,
    datasets: [
      {
        data: [0, 0],
        spacing: 0,
        backgroundColor: bg,
        hoverBackgroundColor: bg,
        borderWidth: 0,
        cutout: '75%'
      }
    ]
  });
  const [sumery, setSumery] = useState<IFSellerTreatment>({ sumery: 0, doctorFee: 0, clinicFee: 0, });

  const [loadFirst, setLoadFirst] = useState(false);
  const dashboardMain = localStorage.getItem(KEY_STORAGE.DASHBOARD)
  const dashboardCurrent = dashboardMain ? JSON.parse(dashboardMain) : {}

  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [branch, setBranch] = useState<(string | number)[]>(dashboardCurrent?.salesOperative?.branch || ['ALL'])
  const [rangeDate, setRangeDate] = useState(dashboardCurrent?.salesOperative?.rangeDate || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])

  const handleChangeSelectBranch = (e: any) => {
    setBranch(handleChangeFilterSelect(e))
  }

  useEffect(() => {
    if (!loadFirst) {
      const timer = setTimeout(() => {
        setLoadFirst(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [props.rangeDate, props.branches]);

  useEffect(() => {
    setBranches(props.branches || [])
  }, [props.branches])

  useEffect(() => {
    loadFirst && setRangeDate(props.rangeDate || [])
  }, [props.rangeDate])

  useEffect(() => {
    loadFirst && setBranch(props.branch || [])
  }, [props.branch])

  useEffect(() => {
    props?.handleChangeDashboard && props?.handleChangeDashboard("salesOperative,branch", branch)
  }, [branch])

  useEffect(() => {
    props?.handleChangeDashboard && props?.handleChangeDashboard("salesOperative,rangeDate", rangeDate)
  }, [rangeDate])

  const loadData = useCallback(async () => {
    const condition: IFSumeryFilter = { dateStart: rangeDate[0], dateEnd: rangeDate[1], branchId: branch[0] === 'ALL' ? `` : branch.toString() }
    const res = await DashboardApi.findBastSellerTreatment(condition)
    setSumery({ sumery: 0, doctorFee: 0, clinicFee: 0, })
    if (res.status === 200) {
      const summeris: IFSellerTreatment = res.data
      setData({
        labels,
        datasets: [
          {
            data: [summeris.doctorFee, summeris.clinicFee],
            spacing: summeris.doctorFee && summeris.clinicFee ? 1 : 0,
            backgroundColor: bg,
            hoverBackgroundColor: bg,
            borderWidth: summeris.doctorFee && summeris.clinicFee ? 1 : 0,
            cutout: '75%',
          }
        ]
      })
      setSumery(summeris)
    }
  }, [branch, rangeDate])

  useEffect(() => {
    loadData()
  }, [loadData])
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return ` ${context.label}: ${numberFormat(context.raw)}`
          }
        }
      },
    },
  }

  return (
    <UseStyled.Card className='d-flex flex-column'>
      <Box className='pt-3 px-3 px-xl-4' sx={{ borderBottom: `1px solid ${colors.lightGray}`, paddingBottom: '12px' }}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item className={'w-100'}>
            <UseStyled.Title type='title' sx={{ flex: 'none' }}>{t('DASHBOARD.SALES_OPERATIVE')}</UseStyled.Title>
          </Grid>
          <Grid item xs={6} sm={12} className={'w-100 pr-sm-2 pr-md-0'}>
            <FilterSelect
              multiple
              label={t('')}
              labelId="label-branch"
              selectId="select-branch"
              onchange={handleChangeSelectBranch}
              renderValue={() => `${t('DASHBOARD.FILTER.BRANCH')}: ${renderFilterSelect('branch', branches, branch) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('branch', branches, branch)}`}
              value={branch}
              options={[
                <MenuItem
                  key="ALL"
                  value="ALL"
                  sx={{
                    '&.MuiMenuItem-root.Mui-selected': {
                      pointerEvents: _.includes(branch, 'ALL') ? `none` : 'auto',
                      backgroundColor: _.includes(branch, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                      color: _.includes(branch, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                    }
                  }}>
                  {t('STATUS.ALL')}
                </MenuItem>,
                _.map(props.branches, (val: BranchInterface, indexBranch: number) => {
                  return (
                    <MenuItem
                      key={indexBranch}
                      value={val.branchId}
                      sx={{
                        '&.MuiMenuItem-root.Mui-selected': {
                          backgroundColor: _.includes(branch, val.branchId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                          color: _.includes(branch, val.branchId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                        }
                      }}>
                      {val.branchName}
                    </MenuItem>
                  )
                })
              ]}
              formControlClass={'not-max-width'}
              classesOption="style-select-doctors"
            />
          </Grid>
          <Grid item xs={6} sm={12} className={'w-100'}>
            <InputSecondNewRangePicker
              inputHeight={32}
              value={rangeDate}
              onchange={(val) => {
                if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
              }}
              allowClear
              onClear={() => setRangeDate(emptyRangePicker)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className='pt-3 pb-1 d-flex flex-column justify-content-center h-100'>
        <UseStyled.DoughnutChart type='operative' className='mx-auto position-relative'>
          <Doughnut options={options} data={data} />
          {!sumery.doctorFee && !sumery.clinicFee && (<UseStyled.EmptyDoughnutChart />) || <></>}
          <UseStyled.DoughnutDataInside className='text-center position-absolute'>
            <Typography>{numberFormat(sumery.sumery)}</Typography>
            <Typography sx={{ fontSize: '14px', color: `${colors.textExtraLightGray} !important` }}>{t('DASHBOARD.OPERATIVE_CHART.SUM_OPERATIVE')}</Typography>
          </UseStyled.DoughnutDataInside>
        </UseStyled.DoughnutChart>
        <Box className='pt-3 pb-2 px-3 px-xl-4'>
          <Typography className='mb-1' sx={{ fontWeight: 500 }}>{t('DASHBOARD.OPERATIVE_CHART.SALARY_OPERATIVE')}</Typography>
          <Grid container spacing={1}>
            <Grid item xs={6} lg={12} xl={6} className="w-100">
              <Box className='d-flex align-items-center'>
                <UseStyled.LabelColor color={bg[0]} />
                <Typography className='ml-2' sx={{ fontSize: '18px', fontWeight: 500 }}>{numberFormat(sumery.doctorFee)}</Typography>
              </Box>
              <Box className='d-flex align-items-center'>
                <Typography sx={{ color: `${colors.textExtraLightGray} !important` }}>{t('DASHBOARD.OPERATIVE_CHART.DF')}</Typography>
                <Arrow className='ml-2'>
                  <ArrowUpwardIcon />
                </Arrow>
              </Box>
            </Grid>
            <Grid item xs={6} lg={12} xl={6} className="w-100">
              <Box className='d-flex align-items-center'>
                <UseStyled.LabelColor color={bg[1]} />
                <Typography className='ml-2' sx={{ fontSize: '18px', fontWeight: 500 }}>{numberFormat(sumery.clinicFee)}</Typography>
              </Box>
              <Box className='d-flex align-items-center'>
                <Typography sx={{ color: `${colors.textExtraLightGray} !important` }}>{t('DASHBOARD.OPERATIVE_CHART.CF')}</Typography>
                <Arrow className='ml-2'>
                  <ArrowUpwardIcon />
                </Arrow>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </UseStyled.Card>
  )
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
)
