import BaseAPI from "api/api";
import { FindAllInterface } from "api/api-interface";

const voucher = '/vouchers'

export interface VoucherFindByCodeProps {
    code: string
    itemType: "OPERATIVE" | "MEDICINE" | "PRODUCT",
    itemId: number
    qty: number
    min: number
    patientId: number
}

export interface VoucherPaymentFindByCodeProps {
    code: string
}

export interface VoucherPatientUseProps {
    patientId: number
    vouchers: VoucherPatientUseVouchersProps[]
}

export interface VoucherPatientUseVouchersProps {
    voucherId: number
    lotNumber: number
    qty: number
}

export default class VoucherApi extends BaseAPI {
    // VOUCHER TYPE
    static findAllType(props: FindAllInterface): Promise<any> {
        return this.api.get(`${voucher}/types`, { params: { ...props } }).then(res => res)
    }
    static createVoucherType(props: any): Promise<any> {
        return this.api.post(`${voucher}/types`, { ...props }).then((res) => res)
    }
    static chagneStatusVoucherType(id: number, props: any): Promise<any> {
        return this.api.patch(`${voucher}/types/${id}`, { ...props }).then((res) => res)
    }
    static updateVoucherType(id: number, props: any): Promise<any> {
        return this.api.patch(`${voucher}/types/${id}`, { ...props }).then((res) => res)
    }

    // VOUCHER
    static findAllVoucher(props: FindAllInterface): Promise<any> {
        return this.api.get(`${voucher}`, { params: { ...props } }).then(res => res)
    }
    static findVoucherById(id: number): Promise<any> {
        return this.api.get(`${voucher}/${id}`).then(res => res)
    }
    static chagneStatusVoucher(id: number, props: any): Promise<any> {
        return this.api.patch(`${voucher}/${id}/status`, { status: props }).then((res) => res)
    }
    static deleteVoucher(id: number): Promise<any> {
        return this.api.delete(`${voucher}/${id}`).then((res) => res)
    }
    static createVoucher(props: any): Promise<any> {
        return this.api.post(`${voucher}`, { ...props }).then((res) => res)
    }
    static findVoucherListId(id: number, props: any): Promise<any> {
        return this.api.get(`${voucher}/list/${id}`, { params: { ...props } }).then(res => res)
    }

    static createVoucherModal(id: number, props: any): Promise<any> {
        return this.api.patch(`${voucher}/${id}/add`, { ...props }).then((res) => res)
    }
    static deleteVoucherList(props: any): Promise<any> {
        return this.api.patch(`${voucher}/delete-list`, { voucherListId: props }).then((res) => res)
    }
    static findAllRankVoucher(props: any): Promise<any> {
        return this.api.get(`/member`, { params: { ...props } }).then((res) => res)
    }


    // REGISTER
    static findVoucherPatient(id: number, condition: any): Promise<any> {
        return this.api.get(`/vouchers/patient/${id}`, { params: { ...condition } }).then((res) => res)
    }

    static updateVoucherPatientUse(payload: VoucherPatientUseProps): Promise<any> {
        return this.api.patch(`/vouchers/patient-use`, { ...payload }).then((res) => res)
    }

    // TREATMENT
    static findVoucherByCode(props: VoucherFindByCodeProps): Promise<any> {
        return this.api.get(`/vouchers/item`, { params: { ...props } }).then((res) => res)
    }

    // PAYMENT
    static findVoucherPaymentByCode(id: number, props: VoucherPaymentFindByCodeProps): Promise<any> {
        return this.api.get(`/vouchers/patient-code/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findVoucherPatientAll(temId: number): Promise<any> {
        return this.api.get(`/vouchers/patient-all/${temId}`).then((res) => res)
    }

}
