import { useState, useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { Box, Grid, Divider, Typography, IconButton } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { useDispatch, useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/personnel-managements/personnels/style'
import * as UseStyled from 'features/setting/personnel-managements/personnels/useStyles'
import { imgs, onImgError } from 'constants/images'
import { PhotoCamera } from '@mui/icons-material'

/** API */
import { routeName } from 'routes/routes-name'
import { fileUrl } from 'api/api'
import PersonnelApi, { ChangePassword } from 'api/setting/personnel-managements/personnel.api'

/** UTILS || SLICE */
import { loadUserFormSetup, selectUser, loadUserFormInfo, loadUserFormEduction, loadUserFormSalary, loadUserFormSchedule, loadUserFormDocument, loadUserProfile, editUser, selectMe, editMe } from 'app/slice/user.slice'
import { showModal, resetModal } from 'app/slice/modal.slice'
import { getPackageKey } from 'utils/app.utils'

/** CONSTANT */
import { colors } from 'constants/theme'
import { userLimit } from 'constants/package'

/** COMPONENT */
import { notiSuccess, notiError } from 'component/notifications/notifications'
import { checkUserLimit, checkIsUltraPackage } from 'features/setting/personnel-managements/personnels/personnels'
import SetupAccount from 'features/setting/personnel-managements/personnels/form-personnel-setup'
import Information from 'features/setting/personnel-managements/personnels/form-personnel-info'
import SalarynPosition from 'features/setting/personnel-managements/personnels/form-personnel-salary'
import Education from 'features/setting/personnel-managements/personnels/form-personnel-education'
import Schedule from 'features/setting/personnel-managements/personnels/form-personnel-schedule'
import Document from 'features/setting/personnel-managements/personnels/form-personnel-document'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputPasswordField from 'component/Input/InputPasswordField'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import Loading from 'component/Loading'
import { swalWarning } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import BtnEditOutlined from 'assets/button/BtnEditOutlined'
import PopupEditAuth from './popup/pop-edit-auth'

const initStateErrorMessage = {
  USER_CODE: '',
  USER_NAME: '',
  PASSWORD: '',
  CONFIRM_PASSWORD: '',
  PREFIX: '',
  FIRST_NAME: '',
  LAST_NAME: '',
  LAST_NAME_EN: '',
  FIRST_NAME_EN: '',
  POSITION: '',
  CERTIFICATE: '',
  ROLE: '',
  PASSWORD_NOT_MATCH: '',
  MINIMUM_PASSWORD: ''
}

export const initStatePasswordErrorMessage = {
  NEW_PASSWORD: '',
  CONFIRM_PASSWORD: '',
  PASSWORD_NOT_MATCH: '',
  MINIMUM_PASSWORD: ''
}

const initStateValue = {
  userCode: '',
  username: '',
  password: '',
  confirmPassword: '',
  prefix: '',
  firstname: '',
  lastname: '',
  firstnameEn: '',
  lastnameEn: '',
  displayName: '',
  email: '',
  idCard: '',
  dateOfBirth: '',
  phone: '',
  telephone: '',
  facebook: '',
  line: '',
  address: '',
  countryId: 0,
  provinceId: 0,
  districtId: 0,
  subDistrictId: 0,
  postcode: '',
  positionId: 0,
  roleId: 0,
  certificate: '',
  salary: 0,
  creditCardFee: 0,
  creditCardFeeType: 'PERCENT',
  expertId: 0,
  bankId: 0,
  bankAccountNumber: '',
  bankAccountName: '',
  isInsure: '0',
  isAdmin: '0',
  ownAppointment: '0',
  ownSchedule: '0',
  ownDocument: '0',
  isViewDfAndLab: '0',
  isViewDfConfirm: '0',
  autoShowPatientProfile: '0',
  hasCancelBill: 'UNPERMISS',
  hasResetBill: 'UNPERMISS',
  hasChangeTreatmentCost: 'UNPERMISS',
  hasChangeTreatmentNote: 'UNPERMISS',
  hasChangePrice: 'UNPERMISS',
  hasChangeDiscountAdditional: 'UNPERMISS',
  hasManageAdvances: 'UNPERMISS',
  hasDiagnosis: 'UNPERMISS',
  hasCreateDiagnosis: 'UNPERMISS',
  hasDeleteDiagnosis: 'UNPERMISS',
  hasMed: 'UNPERMISS',
  hasCreateMed: 'UNPERMISS',
  hasDeleteMed: 'UNPERMISS',
  hasProduct: 'UNPERMISS',
  hasCreateProduct: 'UNPERMISS',
  hasDeleteProduct: 'UNPERMISS',
  hasTreatment: 'UNPERMISS',
  hasCreateTreatment: 'UNPERMISS',
  hasInsuranceCost: 'UNPERMISS',
  hasDeleteTreatment: 'UNPERMISS',
  hasTreatmentPlan: 'UNPERMISS',
  hasCreateTreatmentPlan: 'UNPERMISS',
  hasDeleteTreatmentPlan: 'UNPERMISS',
  hasTreatmentCourse: 'UNPERMISS',
  hasCreateTreatmentCourse: 'UNPERMISS',
  hasDeleteTreatmentCourse: 'UNPERMISS',
  hasDoctorNote: 'UNPERMISS',
  hasCancelTreatmentQueue: 'UNPERMISS',
  hasEditBill: 'UNPERMISS',
  hasCreateDoctorNote: 'UNPERMISS',
  hasDeleteDoctorNote: 'UNPERMISS',
  hasCancelVisit: 'UNPERMISS',
  hasCancelLabOrder: 'UNPERMISS',
  hasReplyLabOrder: 'UNPERMISS',
  hasViewIncome: 'UNPERMISS',
  hasShowPhoneNumber: 'UNPERMISS',
  hasApproveIncomeAndExpenses: 'UNPERMISS',
  hasApproveTheAmountChange: 'UNPERMISS',
  userWorkplaces: [],
  userBackgrounds: [],
  userAttachments: [],
  profile: {
    fileId: 0,
    base64: '',
    type: '',
    fileExtension: '',
    fileSize: ''
  },
  signatureAttachments: {
    fileId: 0,
    base64: '',
    fileExtension: '',
    fileSize: ''
  },
  status: 'ACTIVE'
}

type TemplatePassword = {
  newPassword: string
  confirmPassword: string
}

type PersonnelType = {
  userCode: string
  username: string
}

export type InitStatePasswordErrorMessageType = {
  NEW_PASSWORD: string
  CONFIRM_PASSWORD: string
  PASSWORD_NOT_MATCH: string
  MINIMUM_PASSWORD: string
}

type InitStateErrorMessageType = {
  USER_CODE: string
  USER_NAME: string
  PASSWORD: string
  CONFIRM_PASSWORD: string
  PREFIX: string
  FIRST_NAME: string
  LAST_NAME: string
  FIRST_NAME_EN: string
  LAST_NAME_EN: string
  POSITION: string
  CERTIFICATE: string
  ROLE: string
  PASSWORD_NOT_MATCH: string
  MINIMUM_PASSWORD: string
}

enum TABS_FORM_TYPE {
  SETUP = 'SETUP',
  INFO = 'INFO',
  SALARY = 'SALARY',
  EDUCATION = 'EDUCATION',
  SCHEDULE = 'SCHEDULE',
  DOCUMENT = 'DOCUMENT'
}

export default function FormPersonnel() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const user = useSelector(selectMe)

  const [tab, setTab] = useState<TABS_FORM_TYPE>(TABS_FORM_TYPE.SETUP)
  const userSetup = useSelector(selectUser)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [tabForm, setTabForm] = useState<TABS_FORM_TYPE>(TABS_FORM_TYPE.SETUP)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<'ACTIVE' | 'INACTIVE'>('ACTIVE')
  const [oldStatus, setOldStatus] = useState<'ACTIVE' | 'INACTIVE'>('ACTIVE')

  const [readOnly, setReadOnly] = useState(true)
  const templatePassword: TemplatePassword = { newPassword: '', confirmPassword: '' }
  const [password, setPassword] = useState<TemplatePassword>(templatePassword)

  const [errorMessage, setErrorMessage] = useState<InitStateErrorMessageType>(initStateErrorMessage)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<InitStatePasswordErrorMessageType>(initStatePasswordErrorMessage)

  const [userId, setUserId] = useState(Number)
  const [profileData, setProfileData] = useState<any | null>()
  const [profile, setProfile] = useState<any | null>()
  const [personnel, setPersonnel] = useState<PersonnelType>({ userCode: '', username: '' })
  const [attachments, setAttachments] = useState([])

  const [popEditAuth, setPopEditAuth] = useState<boolean>(false)
  const [statusEditAuth, setStatusEditAuth] = useState<'username' | 'password'>('username')

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      uploadProfile(acceptedFiles)
    }
  })
  useEffect(() => {
    if (location.state) {
      reduxEdit(location.state)
    } else {
      create()
      if (!checkIsUltraPackage()) {
        const packageUserLimit = async () => {
          await checkUserLimit(
            t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.TITLE'),
            t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.SUBTITLE', {
              package: getPackageKey(),
              number: userLimit(getPackageKey())
            }),
            () => {
              history.push(routeName.personnels)
            },
            () => {
              return
            }
          )
        }
        packageUserLimit()
      }
    }
  }, [location, dispatch])

  useEffect(() => {
    const password = userSetup.setup.password
    const username = userSetup.setup.username

    /**
     * a. ความยาวอย่างน้อย 8 หลัก
     * b. ประกอบด้วยตัวอักษร และ ตัวเลข
     * c. ถ้าเป็นอักษรภาษาอังกฤษ ต้องมีอักษรตัวใหญ่และเล็กผสมกัน
     * d. ไม่สามารถใช้รหัสผ่านเป็นภาษาไทยได้
     * e. ต้องไม่มีชื่อผู้ใช้งานเป็นส่วนหนึ่งส่วนใดของรหัสผ่าน
     */
    if (password) {
      const newTab: TABS_FORM_TYPE = TABS_FORM_TYPE.SETUP
      setTab(newTab)
      setTabForm(newTab)

      const tempMessage = t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.MESSAGE_PASSWORD')
      let message = ''
      /** ความยาวอย่างน้อย 8 หลัก */
      if (password.length < 8) message = tempMessage
      /** ประกอบด้วยตัวอักษมีอักษรตัวใหญ่และเล็กผสมกัน และ ตัวเลข */ else if (password.toLowerCase() === password) message = tempMessage
      else if (password.toUpperCase() === password) message = tempMessage
      else if (!/\d/.test(password)) message = tempMessage
      else if (/[ก-๙]/.test(password)) message = tempMessage
      /**  e. ต้องไม่มีชื่อผู้ใช้งานเป็นส่วนหนึ่งส่วนใดของรหัสผ่าน */ else if (username && password && password.search(username) !== -1) message = tempMessage

      return setErrorMessage({
        ...errorMessage,
        PASSWORD: message
      })
    }
  }, [userSetup.setup.username, userSetup.setup.password])

  const create = () => {
    const {
      userCode,
      username,
      password,
      confirmPassword,
      prefix,
      firstname,
      lastname,
      firstnameEn,
      lastnameEn,
      displayName,
      email,
      idCard,
      dateOfBirth,
      phone,
      telephone,
      facebook,
      line,
      address,
      countryId,
      provinceId,
      districtId,
      subDistrictId,
      postcode,
      positionId,
      roleId,
      certificate,
      salary,
      creditCardFee,
      creditCardFeeType,
      expertId,
      bankId,
      bankAccountNumber,
      bankAccountName,
      isInsure,
      isAdmin,
      ownAppointment,
      ownSchedule,
      ownDocument,
      isViewDfAndLab,
      isViewDfConfirm,
      autoShowPatientProfile,
      hasCancelBill,
      hasResetBill,
      hasCancelTreatmentQueue,
      hasCancelVisit,
      hasCancelLabOrder,
      hasReplyLabOrder,
      hasViewIncome,
      hasChangeTreatmentCost,
      hasChangeTreatmentNote,
      hasChangePrice,
      hasChangeDiscountAdditional,
      hasManageAdvances,
      hasDiagnosis,
      hasCreateDiagnosis,
      hasDeleteDiagnosis,
      hasMed,
      hasCreateMed,
      hasDeleteMed,
      hasProduct,
      hasCreateProduct,
      hasDeleteProduct,
      hasTreatment,
      hasCreateTreatment,
      hasDeleteTreatment,
      hasTreatmentPlan,
      hasCreateTreatmentPlan,
      hasDeleteTreatmentPlan,
      hasTreatmentCourse,
      hasCreateTreatmentCourse,
      hasDeleteTreatmentCourse,
      hasDoctorNote,
      hasEditBill,
      hasCreateDoctorNote,
      hasDeleteDoctorNote,
      userBackgrounds,
      userWorkplaces,
      hasInsuranceCost,
      userAttachments,
      signatureAttachments,
      profile,
      hasShowPhoneNumber,
      hasApproveIncomeAndExpenses,
      hasApproveTheAmountChange
    } = initStateValue

    dispatch(
      loadUserFormSetup({
        userCode,
        username,
        ownAppointment,
        ownSchedule,
        ownDocument,
        isViewDfAndLab,
        isViewDfConfirm,
        autoShowPatientProfile,
        hasCancelBill,
        hasResetBill,
        hasCancelVisit,
        hasCancelLabOrder,
        hasReplyLabOrder,
        hasViewIncome,
        hasChangeTreatmentCost,
        hasChangeTreatmentNote,
        hasChangePrice,
        hasChangeDiscountAdditional,
        hasManageAdvances,
        hasDiagnosis,
        hasCreateDiagnosis,
        hasDeleteDiagnosis,
        hasMed,
        hasCreateMed,
        hasDeleteMed,
        hasProduct,
        hasCreateProduct,
        hasDeleteProduct,
        hasTreatment,
        hasCreateTreatment,
        hasDeleteTreatment,
        hasTreatmentPlan,
        hasCreateTreatmentPlan,
        hasDeleteTreatmentPlan,
        hasTreatmentCourse,
        hasCreateTreatmentCourse,
        hasDeleteTreatmentCourse,
        hasDoctorNote,
        hasCreateDoctorNote,
        hasDeleteDoctorNote,
        password,
        confirmPassword,
        hasCancelTreatmentQueue,
        hasEditBill,
        hasInsuranceCost,
        hasShowPhoneNumber,
        hasApproveIncomeAndExpenses,
        hasApproveTheAmountChange
      })
    )
    dispatch(loadUserFormInfo({ prefix, firstname, lastname, firstnameEn, lastnameEn, displayName, email, idCard, dateOfBirth, phone, telephone, facebook, line, address, countryId, provinceId, districtId, subDistrictId, postcode }))
    dispatch(loadUserFormEduction({ userBackgrounds: [...userBackgrounds] }))
    dispatch(loadUserFormSalary({ positionId, roleId, certificate, salary, creditCardFee, creditCardFeeType, expertId, bankId, bankAccountNumber, bankAccountName, isInsure, isAdmin }))
    dispatch(loadUserFormSchedule({ userWorkplaces: [...userWorkplaces] }))
    dispatch(loadUserFormDocument({ userAttachments: [...userAttachments], signatureAttachments: signatureAttachments }))
    dispatch(loadUserProfile(profile))
    setIsEdit(false)
  }

  const reduxEdit = (locationState: any) => {
    setUserId(Number(locationState.userId))
    const {
      userCode,
      username,
      prefix,
      firstname,
      lastname,
      firstnameEn,
      lastnameEn,
      displayName,
      email,
      idCard,
      dateOfBirth,
      phone,
      telephone,
      facebook,
      line,
      address,
      countryId,
      provinceId,
      districtId,
      subDistrictId,
      postcode,
      positionId,
      roleId,
      certificate,
      salary,
      creditCardFee,
      creditCardFeeType,
      expertId,
      bankId,
      bankAccountNumber,
      bankAccountName,
      isInsure,
      isAdmin,
      ownAppointment,
      isViewDfAndLab,
      isViewDfConfirm,
      autoShowPatientProfile,
      ownSchedule,
      ownDocument,
      hasCancelBill,
      hasResetBill,
      hasCancelTreatmentQueue,
      hasCancelVisit,
      hasCancelLabOrder,
      hasReplyLabOrder,
      hasViewIncome,
      hasChangeTreatmentCost,
      hasChangeTreatmentNote,
      hasChangePrice,
      hasChangeDiscountAdditional,
      hasManageAdvances,
      hasDiagnosis,
      hasMed,
      hasCreateMed,
      hasDeleteMed,
      hasProduct,
      hasCreateProduct,
      hasDeleteProduct,
      hasCreateDiagnosis,
      hasDeleteDiagnosis,
      hasTreatment,
      hasCreateTreatment,
      hasDeleteTreatment,
      hasTreatmentPlan,
      hasCreateTreatmentPlan,
      hasDeleteTreatmentPlan,
      hasTreatmentCourse,
      hasCreateTreatmentCourse,
      hasDeleteTreatmentCourse,
      hasDoctorNote,
      hasEditBill,
      hasCreateDoctorNote,
      hasDeleteDoctorNote,
      userBackgrounds,
      userWorkplaces,
      userAttachments,
      signature,
      status,
      profile,
      hasInsuranceCost,
      hasShowPhoneNumber,
      hasApproveIncomeAndExpenses,
      hasApproveTheAmountChange,
      userTags
    } = locationState
    setStatus(status)
    setOldStatus(status)
    setAttachments(userAttachments)

    if (profile?.fileName) {
      const { fileName, filePath } = profile
      const path: any = `${fileUrl}/${filePath}/${fileName}`
      setProfileData(path)
    }
    const userBackground = userBackgrounds.map((obj: any) => {
      delete obj.userId
      delete obj.userBackgroundId
      return obj
    })

    const userWorkplace = userWorkplaces.map((obj: any) => {
      delete obj.userId
      delete obj.userWorkplaceId
      return obj
    })

    const userAttachment = userAttachments.map((obj: any) => {
      if (!obj.file) {
        return { attachmentType: obj.attachmentType, userAttachmentId: obj.userAttachmentId, fileId: 0, base64: '', type: '', fileSize: '', fileExtension: '' }
      }
      return { attachmentType: obj.attachmentType, userAttachmentId: obj.userAttachmentId, fileId: obj.file.fileId, base64: '', type: obj.file.fileName, fileSize: '', fileExtension: '' }
    })

    let urlS: any = ''
    let sig = { fileId: 1, base64: '', type: 'fileName', fileExtension: '', fileSize: '', filePath: '' }
    const pro = { fileId: 1, base64: '', type: 'profile.fileName', fileExtension: '', fileSize: '' }

    if (signature) {
      const timeNow = moment().format('HHmmss')
      const { filePath: sFilePath, fileName: sFileName } = signature
      urlS = `${fileUrl}/${sFilePath}/${sFileName}?${timeNow}`
      sig = { fileId: signature.fileId, base64: '', type: '', fileExtension: '', fileSize: '', filePath: urlS }
    }

    dispatch(
      loadUserFormSetup({
        userCode,
        username,
        ownAppointment,
        ownSchedule,
        ownDocument,
        isViewDfAndLab,
        isViewDfConfirm,
        autoShowPatientProfile,
        hasCancelBill,
        hasCancelVisit,
        hasCancelLabOrder,
        hasReplyLabOrder,
        hasViewIncome,
        hasChangeTreatmentCost,
        hasChangeTreatmentNote,
        hasChangePrice,
        hasChangeDiscountAdditional,
        hasManageAdvances,
        hasDiagnosis,
        hasCreateDiagnosis,
        hasDeleteDiagnosis,
        hasMed,
        hasCreateMed,
        hasDeleteMed,
        hasProduct,
        hasCreateProduct,
        hasDeleteProduct,
        hasTreatment,
        hasTreatmentPlan,
        hasTreatmentCourse,
        hasDoctorNote,
        status,
        hasResetBill,
        hasCreateTreatment,
        hasDeleteTreatment,
        hasCreateTreatmentPlan,
        hasDeleteTreatmentPlan,
        hasCreateTreatmentCourse,
        hasDeleteTreatmentCourse,
        hasCreateDoctorNote,
        hasDeleteDoctorNote,
        hasEditBill,
        hasCancelTreatmentQueue,
        hasInsuranceCost,
        hasShowPhoneNumber,
        hasApproveIncomeAndExpenses,
        hasApproveTheAmountChange
      })
    )
    dispatch(loadUserFormInfo({ prefix, firstname, lastname, firstnameEn, lastnameEn, displayName, email, idCard, dateOfBirth, phone, telephone, facebook, line, address, countryId, provinceId, districtId, subDistrictId, postcode }))
    dispatch(loadUserFormEduction({ userBackgrounds: [...userBackground] }))
    dispatch(loadUserFormSalary({ positionId, roleId, certificate, salary, creditCardFee, creditCardFeeType, expertId, bankId, bankAccountNumber, bankAccountName, isInsure, isAdmin, userTags }))
    dispatch(loadUserFormSchedule({ userWorkplaces: [...userWorkplace] }))
    dispatch(loadUserFormDocument({ userAttachments: [...userAttachment], signatureAttachments: sig }))
    dispatch(loadUserProfile(pro))

    setIsEdit(true)
    setPersonnel({ userCode: userCode, username: username })
  }

  const submit = async () => {
    const { setup, info, salary, education, schedule, document } = userSetup
    const objCreate = {
      ...setup,
      ...info,
      ...salary,
      ...education,
      ...schedule,
      ...document,
      profile
    }

    if (objCreate?.userTags?.length) {
      objCreate['userTags'] = objCreate?.userTags?.map((item: any) => { return { tagId: item.tagId } })
    }

    if (
      !objCreate.userCode ||
      !objCreate.username ||
      !objCreate.password ||
      !objCreate.confirmPassword ||
      !objCreate.prefix ||
      !objCreate.firstname ||
      !objCreate.lastname ||
      !objCreate.firstnameEn ||
      !objCreate.lastnameEn ||
      !objCreate.positionId ||
      (Number(objCreate.positionId) === 2 && !objCreate.certificate) ||
      !objCreate.roleId
    ) {
      let newTab: TABS_FORM_TYPE = tab
      if (!objCreate.userCode || !objCreate.username || !objCreate.password || !objCreate.confirmPassword) newTab = TABS_FORM_TYPE.SETUP
      else if (!objCreate.prefix || !objCreate.firstname || !objCreate.lastname || !objCreate.firstnameEn || !objCreate.lastnameEn) newTab = TABS_FORM_TYPE.INFO
      else if (!objCreate.positionId || !objCreate.roleId || (Number(objCreate.positionId) === 2 && !objCreate.certificate)) newTab = TABS_FORM_TYPE.SALARY
      setTab(newTab)
      setTabForm(newTab)
      return setErrorMessage({
        ...errorMessage,
        USER_CODE: !objCreate.userCode ? t('VALIDATION.USER_CODE') : '',
        USER_NAME: !objCreate.username ? t('VALIDATION.USER_NAME') : '',
        PASSWORD: !objCreate.password ? t('LOGIN.MESSAGE.PASSWORD') : '',
        CONFIRM_PASSWORD: !objCreate.confirmPassword ? t('LOGIN.MESSAGE.PASSWORD') : '',
        PREFIX: !objCreate.prefix ? t('VALIDATION.PREFIX_ID') : '',
        FIRST_NAME: !objCreate.firstname ? t('VALIDATION.FIRST_NAME') : '',
        LAST_NAME: !objCreate.lastname ? t('VALIDATION.LAST_NAME') : '',
        FIRST_NAME_EN: !objCreate.firstnameEn ? t('VALIDATION.FIRST_NAME_EN') : '',
        LAST_NAME_EN: !objCreate.lastnameEn ? t('VALIDATION.LAST_NAME_EN') : '',
        POSITION: !objCreate.positionId ? t('VALIDATION.POSITION') : '',
        ROLE: !objCreate.roleId ? t('VALIDATION.ROLE') : '',
        CERTIFICATE: Number(objCreate.positionId) === 2 && !objCreate.certificate ? t('VALIDATION.CERTIFICATE') : ''
      })
    }

    /**
     * a. ความยาวอย่างน้อย 8 หลัก
     * b. ประกอบด้วยตัวอักษร และ ตัวเลข
     * c. ถ้าเป็นอักษรภาษาอังกฤษ ต้องมีอักษรตัวใหญ่และเล็กผสมกัน
     * d. ไม่สามารถใช้รหัสผ่านเป็นภาษาไทยได้
     * e. ต้องไม่มีชื่อผู้ใช้งานเป็นส่วนหนึ่งส่วนใดของรหัสผ่าน
     */
    if (objCreate.password.length < 8) return setErrorMessage({ ...errorMessage, MINIMUM_PASSWORD: t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.MESSAGE_ERROR') })

    const tempMessage = t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.MESSAGE_PASSWORD')
    let message = ''
    /** ความยาวอย่างน้อย 8 หลัก */
    if (objCreate.password.length < 8) message = tempMessage
    /** ประกอบด้วยตัวอักษมีอักษรตัวใหญ่และเล็กผสมกัน และ ตัวเลข */ else if (objCreate.password.toLowerCase() === objCreate.password) message = tempMessage
    else if (objCreate.password.toUpperCase() === objCreate.password) message = tempMessage
    else if (!/\d/.test(objCreate.password)) message = tempMessage
    else if (/[ก-๙]/.test(objCreate.password)) message = tempMessage
    /**  e. ต้องไม่มีชื่อผู้ใช้งานเป็นส่วนหนึ่งส่วนใดของรหัสผ่าน */ else if (objCreate.username && objCreate.password && objCreate.password.search(objCreate.username) !== -1) message = tempMessage

    if (message) return setErrorMessage({ ...errorMessage, PASSWORD: message })

    if (objCreate.password !== objCreate.confirmPassword) return setErrorMessage({ ...errorMessage, PASSWORD_NOT_MATCH: t('PERSONNEL.ALERT.PASSWORDS_DO_NOT_MATCH') })

    if (education.userBackgrounds) {
      let checkNull: any = _.find(education.userBackgrounds, { name: '' })
      if (checkNull) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_QUALIFICATION`))
      checkNull = _.find(education.userBackgrounds, { institute: '' })
      if (checkNull) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_INSTITUTION`))
      checkNull = _.find(education.userBackgrounds, { year: '' })
      if (checkNull) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_GRADUATION_YEAR`))
    }

    if (schedule.userWorkplaces) {
      const checkNullName = _.find(schedule.userWorkplaces, { name: '' })
      const checkNullDayOfWork = _.find(schedule.userWorkplaces, { dayOfWork: '' })
      if (checkNullName) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_WORKPLACE_NAME`))
      else if (checkNullDayOfWork) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_WORK_DATE`))
    }

    if (!checkIsUltraPackage()) {
      return checkUserLimit(
        t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.TITLE'),
        t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.SUBTITLE', {
          package: getPackageKey(),
          number: userLimit(getPackageKey())
        }),
        () => {
          return
        },
        () => {
          crateNewUser(objCreate)
        }
      )
    } else {
      await crateNewUser(objCreate)
    }
  }

  const submitUpdate = async () => {
    const { setup, info, salary, education, schedule, document, profile: oldProfile } = userSetup
    const objUpdate = {
      ...setup,
      ...info,
      ...salary,
      ...education,
      ...schedule,
      ...document,
      profile: profile || oldProfile
    }

    if (objUpdate?.userTags?.length) {
      objUpdate['userTags'] = objUpdate?.userTags?.map((item: any) => { return { tagId: item.tagId } })
    }

    if (!objUpdate.prefix || !objUpdate.firstname || !objUpdate.lastname || !objUpdate.firstnameEn || !objUpdate.lastnameEn || !objUpdate.positionId || !objUpdate.roleId || (Number(objUpdate.positionId) === 2 && !objUpdate.certificate)) {
      let newTab: TABS_FORM_TYPE = tab
      if (!objUpdate.prefix || !objUpdate.firstname || !objUpdate.lastname || !objUpdate.firstnameEn || !objUpdate.lastnameEn) newTab = TABS_FORM_TYPE.INFO
      else if (!objUpdate.positionId || !objUpdate.roleId || (Number(objUpdate.positionId) === 2 && !objUpdate.certificate)) newTab = TABS_FORM_TYPE.SALARY
      setTab(newTab)
      setTabForm(newTab)
      return setErrorMessage({
        ...errorMessage,
        PREFIX: !objUpdate.prefix ? t('VALIDATION.PREFIX_ID') : '',
        FIRST_NAME: !objUpdate.firstname ? t('VALIDATION.FIRST_NAME') : '',
        LAST_NAME: !objUpdate.lastname ? t('VALIDATION.LAST_NAME') : '',
        FIRST_NAME_EN: !objUpdate.firstnameEn ? t('VALIDATION.FIRST_NAME_EN') : '',
        LAST_NAME_EN: !objUpdate.lastnameEn ? t('VALIDATION.LAST_NAME_EN') : '',
        POSITION: !objUpdate.positionId ? t('VALIDATION.POSITION') : '',
        ROLE: !objUpdate.roleId ? t('VALIDATION.ROLE') : '',
        CERTIFICATE: Number(objUpdate.positionId) === 2 && !objUpdate.certificate ? t('VALIDATION.CERTIFICATE') : ''
      })
    }

    if (education.userBackgrounds) {
      let checkNull: any = _.find(education.userBackgrounds, { name: '' })
      if (checkNull) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_QUALIFICATION`))
      checkNull = _.find(education.userBackgrounds, { institute: '' })
      if (checkNull) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_INSTITUTION`))
      checkNull = _.find(education.userBackgrounds, { year: '' })
      if (checkNull) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_GRADUATION_YEAR`))
    }

    if (schedule.userWorkplaces) {
      const checkNullName = _.find(schedule.userWorkplaces, { name: '' })
      const checkNullDayOfWork = _.find(schedule.userWorkplaces, { dayOfWork: '' })
      if (checkNullName) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_WORKPLACE_NAME`))
      else if (checkNullDayOfWork) return notiError(t(`PERSONNEL.ALERT.PLEASE_ENTER_WORK_DATE`))
    }

    if (user?.userId === userId && objUpdate.status === 'INACTIVE') {
      return swalWarning(
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.WARNING_MY_USER')}</p>`,
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.WARNING_MY_USER_', { user: `${objUpdate.firstname} ${objUpdate.lastname}` })}</p>`,
        () => {
          setStatus('ACTIVE')
          dispatch(editUser({ value: 'ACTIVE', key: 'status' }))
        }
      )
    }

    if (!checkIsUltraPackage() && oldStatus !== status && objUpdate.status === 'ACTIVE') {
      return checkUserLimit(
        t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.TITLE'),
        t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.SUBTITLE', {
          package: getPackageKey(),
          number: userLimit(getPackageKey())
        }),
        () => {
          return
        },
        () => {
          updateUser(objUpdate)
        }
      )
    } else await updateUser(objUpdate)
  }

  const crateNewUser = async (objCreate: any) => {
    await PersonnelApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          history.push(routeName.personnels)
          notiSuccess(t(`PERSONNEL.ALERT.CREATE_SUCCEED`), '', null, () => {
            return
          })
        } else {
          const err = res.response.data
          if (err?.message?.includes('userWorkplaces')) {
            return notiError(t(`PERSONNEL.ALERT.USERWORKPLACES_ISDUPLICATE`))
          }
          notiError(t(`${err.message}`))
        }
      })
      .catch((e) => {
        const err = e.response.data
        if (err?.message?.includes('userWorkplaces')) {
          return notiError(t(`PERSONNEL.ALERT.USERWORKPLACES_ISDUPLICATE`))
        }
        notiError(t(`${err.message}`))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateUser = async (objUpdate: any) => {
    await PersonnelApi.update(userId, objUpdate)
      .then(async (res: any) => {
        if (user?.userId === userId) await reloadMyUser()
        notiSuccess(t(`USER.MESSAGE.SUCCESS.UPDATE`), '', null, () => {
          return
        })
        history.push(routeName.personnels)
      })
      .catch((e) => {
        const err = e.response.data
        if (err?.message?.includes('userWorkplaces')) {
          return notiError(t(`PERSONNEL.ALERT.USERWORKPLACES_ISDUPLICATE`))
        }
        notiError(t(`${err.message}`))
      })
      .finally(() => setLoading(false))
  }

  const reloadMyUser = async () => {
    setLoading(true)
    const { data } = await PersonnelApi.findProfile().finally(() => setLoading(false))
    if (data) {
      dispatch(editMe({ value: data, key: 'me' }))
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: TABS_FORM_TYPE) => {
    setTab(newValue)
  }

  const uploadProfile = (files: any) => {
    if (files[0]) {
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        const base64 = reader.result.split(',')
        setProfileData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setProfile(detailImage)
    }
  }

  const onResetAuth = () => {
    dispatch(resetModal())
    setPassword(templatePassword)
    setPasswordErrorMessage(initStatePasswordErrorMessage)
  }

  const onSubmitAuth = async () => {
    if (!password.newPassword || !password.confirmPassword) {
      return setPasswordErrorMessage({ ...passwordErrorMessage, NEW_PASSWORD: !password.newPassword ? t('LOGIN.MESSAGE.PASSWORD') : '', CONFIRM_PASSWORD: !password.confirmPassword ? t('LOGIN.MESSAGE.PASSWORD') : '' })
    }

    /**
     * a. ความยาวอย่างน้อย 8 หลัก
     * b. ประกอบด้วยตัวอักษร และ ตัวเลข
     * c. ถ้าเป็นอักษรภาษาอังกฤษ ต้องมีอักษรตัวใหญ่และเล็กผสมกัน
     * d. ไม่สามารถใช้รหัสผ่านเป็นภาษาไทยได้
     * e. ต้องไม่มีชื่อผู้ใช้งานเป็นส่วนหนึ่งส่วนใดของรหัสผ่าน
     */

    const tempMessage = t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.MESSAGE_PASSWORD')
    let message = ''
    /** ความยาวอย่างน้อย 8 หลัก */
    if (password.newPassword.length < 8) message = tempMessage
    /** ประกอบด้วยตัวอักษมีอักษรตัวใหญ่และเล็กผสมกัน และ ตัวเลข */ else if (password.newPassword.toLowerCase() === password.newPassword) message = tempMessage
    else if (password.newPassword.toUpperCase() === password.newPassword) message = tempMessage
    else if (!/\d/.test(password.newPassword)) message = tempMessage
    else if (/[ก-๙]/.test(password.newPassword)) message = tempMessage
    /**  e. ต้องไม่มีชื่อผู้ใช้งานเป็นส่วนหนึ่งส่วนใดของรหัสผ่าน */ else if (personnel.username && password.newPassword && password.newPassword.search(personnel.username) !== -1) message = tempMessage
    if (message) return setPasswordErrorMessage({ ...passwordErrorMessage, NEW_PASSWORD: message })

    if (password.newPassword !== password.confirmPassword) {
      return setPasswordErrorMessage({ ...passwordErrorMessage, PASSWORD_NOT_MATCH: t('PERSONNEL.ALERT.PASSWORDS_DO_NOT_MATCH') })
    }

    const payload: ChangePassword = {
      password: password.newPassword,
      confirmPassword: password.confirmPassword
    }

    PersonnelApi.changePassword(userId, payload)
      .then(() => {
        dispatch(resetModal())
        setPassword(templatePassword)
        notiSuccess(t('PERSONNEL.ALERT.PASSWORD_CHANGE_SUCCESSFUL'))
      })
      .catch(() => {
        return
      })
  }

  const clearReadOnly = () => {
    setReadOnly(false)
  }

  const handleOpenEditAuth = (status: 'username' | 'password') => {
    setStatusEditAuth(status)
    setPopEditAuth(true)
    dispatch(showModal())
  }

  const handleReLoadData = async () => {
    await PersonnelApi.findById(userId).then((res) => {
      history.push(routeName.personnels + '/update', res?.data)
    })
  }

  return (
    <div className={`${classes.root} h-100 container-card overflow-hidden`}>
      <Box className="d-flex flex-column h-100">
        <HeaderLabel
          text={userId ? t('USER.TITLE_UPDATE') : t('USER.TITLE_CREATE')}
          navigator={{ previousTo: routeName.personnels, previousTitle: t('USER.TITLE'), currentTitle: userId ? t('USER.TITLE_UPDATE') : t('USER.TITLE_CREATE') }}
          goBack={routeName.personnels}
          disabledSend={loading}
          onSubmit={userId ? submitUpdate : submit}
        // isEditPersonnel={isEdit}
        // onEditPersonnel={() => dispatch(showModal())}
        />
        <Card className={`border shadow-sm mx-4 mb-4 h-auto overflow-hidden`} style={{ maxHeight: '100%' }}>
          <Card.Body className="h-100 p-0">
            <Grid container spacing={2} className="m-0 h-100 w-100 flex-column flex-xl-row">
              <Grid lg={2} className={`w-100 px-3`} sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` } }}>
                <Box className="py-2">
                  <div className="align-content-center flex-center pt-3">
                    <UseStyled.CardProfileImg {...getRootProps({ isDragActive, isDragAccept, isDragReject })} className={`card-profile personal-avatar mt-2 mt-md-0 `}>
                      <div className={`personal-figure `}>
                        <input {...getInputProps()} />
                        {profileData ? <img src={profileData} alt="profile" onError={onImgError} /> : <img src={imgs.defaultAvatar} onError={onImgError} alt="default profile" />}
                        <IconButton className={`btn-add-profile`} aria-label="upload picture">
                          <PhotoCamera />
                        </IconButton>
                      </div>
                    </UseStyled.CardProfileImg>
                  </div>
                  {isEdit && (
                    <Box className={'mt-3'} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                      <Box>
                        <Box sx={{ fontSize: '14px', fontWeight: '400', color: '#5A5A5A' }}>{`${t('PERSONNEL.MODAL.EMPLOYEE_ID')}: ${userSetup?.setup?.userCode || '-'}`}</Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: '400', color: '#5A5A5A' }}>{`${t('PERSONNEL.MODAL.USERNAME')}: ${userSetup?.setup?.username || '-'}`}</Box>
                        <Box>
                          <ButtonCustom
                            btnStyle={{
                              padding: '2px 10px !important',
                              '.MuiButton-startIcon': {
                                marginRight: '0px !important'
                              }
                            }}
                            variant={'outlined'}
                            textButton={t(`PERSONNEL.BUTTON.EDIT`)}
                            startIcon={<BtnEditOutlined />}
                            onClick={() => handleOpenEditAuth('username')}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: '400', color: '#5A5A5A' }}>{`${t('รหัสผ่าน')}: ${'******'}`}</Box>
                        <Box>
                          <ButtonCustom
                            btnStyle={{
                              padding: '2px 10px !important',
                              '.MuiButton-startIcon': {
                                marginRight: '0px !important'
                              }
                            }}
                            variant={'outlined'}
                            textButton={t(`PERSONNEL.BUTTON.EDIT`)}
                            startIcon={<BtnEditOutlined />}
                            onClick={() => handleOpenEditAuth('password')}
                          />
                        </Box>
                      </Box>
                      <Form className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-xl-between w-100">
                        <Form.Label className="mr-3 mb-0">{t('INPUT.LABEL_STATUS')}</Form.Label>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          onChange={() => {
                            const changeStatus = status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
                            setStatus(changeStatus)
                            dispatch(editUser({ value: changeStatus, key: 'status' }))
                          }}
                          checked={status === 'ACTIVE' ? true : false}
                          className="ml-xl-auto"
                        />
                      </Form>
                      <Divider className="mx-0 mt-3 d-xl-none" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid lg={10} className={'px-0 w-100 h-100 overflow-hidden'} sx={{ flex: 1 }}>
                <Box className="d-flex flex-column overflow-hidden h-100 pb-2 pr-3" sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: colors.extraLightBlue }}>
                      <TabList className="pl-3" onChange={handleTabChange} aria-label="personel tabs" variant="scrollable" TabIndicatorProps={{ style: { backgroundColor: colors.transparent, color: colors.themeSecondColor } }}>
                        <UseStyled.CustomTab
                          tabForm={tabForm}
                          selectLabel={TABS_FORM_TYPE.SETUP}
                          label={`${t('USER.HEAD_TITLE.ACCOUNT_SETTING')}*`}
                          value={TABS_FORM_TYPE.SETUP}
                          onClick={(e) => setTabForm(TABS_FORM_TYPE.SETUP)}
                          className={`py-3 px-4 ${errorMessage.USER_CODE || errorMessage.USER_NAME || errorMessage.PASSWORD || errorMessage.CONFIRM_PASSWORD || errorMessage.MINIMUM_PASSWORD || errorMessage.PASSWORD_NOT_MATCH ? 'error' : ''}`}
                        />
                        <UseStyled.CustomTab
                          tabForm={tabForm}
                          selectLabel={TABS_FORM_TYPE.INFO}
                          label={`${t('USER.HEAD_TITLE.PROFILE')}*`}
                          value={TABS_FORM_TYPE.INFO}
                          onClick={(e) => setTabForm(TABS_FORM_TYPE.INFO)}
                          className={`py-3 px-4 ${errorMessage.PREFIX || errorMessage.FIRST_NAME || errorMessage.LAST_NAME ? 'error' : ''}`}
                        />
                        <UseStyled.CustomTab
                          tabForm={tabForm}
                          selectLabel={TABS_FORM_TYPE.SALARY}
                          label={`${t('USER.HEAD_TITLE.ROLE')}*`}
                          value={TABS_FORM_TYPE.SALARY}
                          onClick={(e) => setTabForm(TABS_FORM_TYPE.SALARY)}
                          className={`py-3 px-4 ${errorMessage.POSITION || errorMessage.CERTIFICATE || errorMessage.ROLE ? 'error' : ''}`}
                        />
                        <UseStyled.CustomTab
                          tabForm={tabForm}
                          selectLabel={TABS_FORM_TYPE.EDUCATION}
                          label={t('USER.HEAD_TITLE.EDUCATION')}
                          value={TABS_FORM_TYPE.EDUCATION}
                          onClick={(e) => setTabForm(TABS_FORM_TYPE.EDUCATION)}
                          className={'py-3 px-4'}
                        />
                        <UseStyled.CustomTab
                          tabForm={tabForm}
                          selectLabel={TABS_FORM_TYPE.SCHEDULE}
                          label={t('USER.HEAD_TITLE.WORK_SCHEDULE')}
                          value={TABS_FORM_TYPE.SCHEDULE}
                          onClick={(e) => setTabForm(TABS_FORM_TYPE.SCHEDULE)}
                          className={'py-3 px-4'}
                        />
                        <UseStyled.CustomTab tabForm={tabForm} selectLabel={TABS_FORM_TYPE.DOCUMENT} label={t('USER.HEAD_TITLE.DOCUMENT')} value={TABS_FORM_TYPE.DOCUMENT} onClick={(e) => setTabForm(TABS_FORM_TYPE.DOCUMENT)} className={'py-3 px-4'} />
                      </TabList>
                    </Box>

                    <div className="h-100 custom-scroll" style={{ display: tabForm === 'SETUP' ? 'block' : 'none', overflowY: 'auto' }}>
                      <SetupAccount
                        isEdit={isEdit}
                        errorMessage={errorMessage}
                        resetErrorMessage={() => {
                          setErrorMessage({ ...errorMessage, USER_CODE: '', USER_NAME: '', PASSWORD: '', CONFIRM_PASSWORD: '', PASSWORD_NOT_MATCH: '', MINIMUM_PASSWORD: '' })
                        }}
                      />
                    </div>
                    <div className="h-100 custom-scroll" style={{ display: tabForm === 'INFO' ? 'block' : 'none', overflowY: 'auto' }}>
                      <Information
                        isEdit={isEdit}
                        errorMessage={errorMessage}
                        resetErrorMessage={() => {
                          setErrorMessage({ ...errorMessage, PREFIX: '', FIRST_NAME: '', LAST_NAME: '', FIRST_NAME_EN: '', LAST_NAME_EN: '' })
                        }}
                      />
                    </div>
                    <div className="h-100 custom-scroll" style={{ display: tabForm === 'SALARY' ? 'block' : 'none', overflowY: 'auto' }}>
                      <SalarynPosition
                        isEdit={isEdit}
                        errorMessage={errorMessage}
                        user={location.state}
                        resetErrorMessage={() => {
                          setErrorMessage({ ...errorMessage, POSITION: '', CERTIFICATE: '', ROLE: '' })
                        }}
                      />
                    </div>
                    <div className="h-100 custom-scroll" style={{ display: tabForm === 'EDUCATION' ? 'block' : 'none', overflowY: 'auto' }}>
                      <Education isEdit={isEdit} />
                    </div>
                    <div className="h-100 custom-scroll" style={{ display: tabForm === 'SCHEDULE' ? 'block' : 'none', overflowY: 'auto' }}>
                      <Schedule isEdit={isEdit} />
                    </div>
                    <div
                      className="h-100 custom-scroll"
                      style={{ display: tabForm === 'DOCUMENT' ? 'block' : 'none', overflowY: 'auto' }}
                      onClick={() => {
                        const canvasCollection = document.getElementsByTagName('canvas')
                        Array.from(canvasCollection).forEach(function (element) {
                          element.style.removeProperty('width')
                          element.style.removeProperty('height')
                        })
                      }}
                    >
                      <Document isEdit={isEdit} attachment={attachments} />
                    </div>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </Box>

      {/* <ModalCustom
        title={t('PERSONNEL.MODAL.EDIT_PASSWORD')}
        component={
          <Grid container spacing={2} className="pb-3">
            <Grid item className="w-100">
              <Box>
                <Typography className="d-inline-block mr-2" sx={{ width: '90px' }}>
                  {t('PERSONNEL.MODAL.EMPLOYEE_ID')}:
                </Typography>
                <Typography className="d-inline-block">{personnel.userCode}</Typography>
              </Box>
              <Box className="mt-1">
                <Typography className="d-inline-block mr-2" sx={{ width: '90px' }}>
                  {t('PERSONNEL.MODAL.USERNAME')}:
                </Typography>
                <Typography className="d-inline-block">{personnel.username}</Typography>
              </Box>
            </Grid>
            <Grid item className="w-100" onClick={clearReadOnly}>
              <InputPasswordField
                label={t(`PERSONNEL.MODAL.NEW_PASSWORD`)}
                value={password.newPassword}
                onchange={(e) => {
                  setPassword({ ...password, newPassword: e.target.value })
                  setPasswordErrorMessage(initStatePasswordErrorMessage)
                }}
                helperText={passwordErrorMessage.NEW_PASSWORD || passwordErrorMessage.MINIMUM_PASSWORD || ''}
                inputProps={{ readOnly: readOnly }}
              />
            </Grid>
            <Grid item className="w-100" onClick={clearReadOnly}>
              <InputPasswordField
                label={t(`PERSONNEL.MODAL.CONFIRM_PASSWORD`)}
                value={password.confirmPassword}
                onchange={(e) => {
                  setPassword({ ...password, confirmPassword: e.target.value })
                  setPasswordErrorMessage(initStatePasswordErrorMessage)
                }}
                helperText={passwordErrorMessage.CONFIRM_PASSWORD || passwordErrorMessage.PASSWORD_NOT_MATCH || ''}
                inputProps={{ readOnly: readOnly }}
              />
            </Grid>
          </Grid>
        }
        onReset={onResetAuth}
        onSubmit={onSubmitAuth}
        textBtnCancel={t('BUTTON.CANCEL')}
        textBtnConfirm={t('BUTTON.SUBMIT')}
      /> */}
      <Loading show={loading} />

      {popEditAuth &&
        <PopupEditAuth
          type={statusEditAuth}
          userId={userId}
          onClose={() => setPopEditAuth(false)}
          onReLoad={handleReLoadData}
        />
      }
    </div>
  )
}
