import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */

/** API */
import OperativeTypeApi, { OperativeTypeCreateInterface, OperativeTypeInterface } from 'api/setting/treatments/operative-type.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import { colorOperativeTypes } from 'constants/theme'
import FilterSelect from 'component/Select/FilterSelect'
import TableRowExport from 'component/Pdf/TableRowExport'
import ButtonExport from 'component/Button/ButtonExport'
import OperativeTypesForm from './operative-types-form';
import AlertModal from 'new-components/modal/alert-modal'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}
export const initStateErrorMessage = {
  OPERATIVE_TYPE_NAME: ``,
  IS_DUPLICATE_OPERATIVE_TYPE_NAME: ``,
  OPERATIVE_TYPE_NAME_STRING_BASE: ``,
  OPERATIVE_TYPE_NAME_STRING_EMPTY: ``,
  OPERATIVE_TYPE_NAME_MAXIMUM_LENGTH: ``,
  OPERATIVE_TYPE_NAME_ANY_REQUIRED: ``,

  OPERATIVE_TYPE_NAME_EN: ``,
  IS_DUPLICATE_OPERATIVE_TYPE_NAME_EN: ``,
  OPERATIVE_TYPE_NAME_EN_STRING_BASE: ``,
  OPERATIVE_TYPE_NAME_EN_STRING_EMPTY: ``,
  OPERATIVE_TYPE_NAME_EN_MAXIMUM_LENGTH: ``,
  OPERATIVE_TYPE_NAME_EN_ANY_REQUIRED: ``
}
export default function OperativeTypes() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [operativeTypes, setOperativeTypes] = useState([])
  const [exportData, setExportData] = useState([])

  const [operativeTypeId, setOperativeTypeId] = useState(Number)
  const [operativeTypeName, setOperativeTypeName] = useState('')
  const [operativeTypeNameEn, setOperativeTypeNameEn] = useState('')
  const [operativeTypeColor, setOperativeTypeColor] = useState('')
  const [operativeTypeStatus, setOperativeTypeStatus] = useState('')
  const [operativeTypeFile, setOperativeTypeFile] = useState<any>({})
  const [operativeTypeDes, setOperativeTypeDes] = useState<string>('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const [modalOpType, setModalOpType] = useState<boolean>(false)
  const [delImage, setDelImage] = useState<boolean>(false)

  const [isAlertDel, setIsAlertDel] = useState<number>(0)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await OperativeTypeApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setOperativeTypes(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  const loadDataForExport = useCallback(async () => {
    let condition: any = {}
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await OperativeTypeApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const submit = () => {
    if (!operativeTypeName) return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_TYPE_NAME: t('OPERATIVE_TYPE.MESSAGE.OPERATIVE_TYPE_NAME') } })

    dispatch(submitModal())

    let newPayLoad: OperativeTypeCreateInterface = {
      operativeTypeName,
      operativeTypeNameEn,
      color: operativeTypeColor || colorOperativeTypes[0],
      description: operativeTypeDes || ''
    }

    if (operativeTypeFile?.base64Show || delImage) {
      newPayLoad = {
        ...newPayLoad,
        image: {
          fileId: operativeTypeFile?.fileId || 0,
          base64: operativeTypeFile?.base64 || '',
          type: operativeTypeFile?.fileType || '',
          fileExtension: operativeTypeFile?.fileExtension || '',
          fileSize: operativeTypeFile?.fileSize || ''
        }
      }
    }

    OperativeTypeApi.create(newPayLoad)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          resetForm()

          loadData()
          dispatch(resetModal())
          notiSuccess(t('OPERATIVE_TYPE.MESSAGE.SUCCESS.CREATE'), '', null, null)
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`OPERATIVE_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`OPERATIVE_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!operativeTypeName) return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_TYPE_NAME: t(`OPERATIVE_TYPE.MESSAGE.OPERATIVE_TYPE_NAME`) } })

    let newPayLoad: OperativeTypeInterface = {
      operativeTypeName,
      operativeTypeNameEn,
      color: operativeTypeColor,
      status: operativeTypeStatus,
      description: operativeTypeDes || ''
    }

    if (operativeTypeFile?.base64Show) {
      newPayLoad = {
        ...newPayLoad,
        image: {
          fileId: operativeTypeFile?.fileId || 0,
          base64: operativeTypeFile?.base64 || '',
          type: operativeTypeFile?.fileType || '',
          fileExtension: operativeTypeFile?.fileExtension || '',
          fileSize: operativeTypeFile?.fileSize || ''
        }
      }
    }

    dispatch(submitModal())
    OperativeTypeApi.update(operativeTypeId, newPayLoad)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          resetForm()
          loadData()
          dispatch(resetModal())
          notiSuccess(t(`OPERATIVE_TYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
        } else {
          const err = res.response.data

          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`OPERATIVE_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`OPERATIVE_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setOperativeTypeId(0)
    setOperativeTypeName('')
    setOperativeTypeNameEn('')
    setOperativeTypeColor('')
    setOperativeTypeStatus('')
    setOperativeTypeFile({})
    setOperativeTypeDes('')
    clearErrorMessage()
    setModalOpType(false)
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('OPERATIVE_TYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          OperativeTypeApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('OPERATIVE_TYPE.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('OPERATIVE_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = async (id: number) => {
    await OperativeTypeApi.remove(id)
      .then((resp) => {
        notiSuccess(t('OPERATIVE_TYPE.MESSAGE.SUCCESS.DELETE'))
        setIsAlertDel(0)
        loadData()
      })
      .catch((e) => {
        notiError(t('OPERATIVE_TYPE.MESSAGE.ERROR'))
      })
  }

  const onEdit = (data: any, form?: boolean) => {
    const { operativeTypeId: optTypeId, operativeTypeName: optTypeName, operativeTypeNameEn: optTypeNameEn, color: optTypeColor, status: optTypeStatus, } = data
    setOperativeTypeId(optTypeId)
    setOperativeTypeName(optTypeName)
    setOperativeTypeNameEn(optTypeNameEn)
    setOperativeTypeColor(optTypeColor)
    setOperativeTypeStatus(optTypeStatus)
    setOperativeTypeFile(data?.file || {})
    setOperativeTypeDes(data.description)
    if (!form) {
      dispatch(showModal())
      setModalOpType(true)
    }
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'color', disablePadding: false, label: t('OPERATIVE_TYPE.TABLE.CELL.OPERATIVE_TYPE_COLOR') },
    { id: 'operativeTypeName', disablePadding: false, label: t('OPERATIVE_TYPE.TABLE.CELL.OPERATIVE_TYPE_NAME') },
    { id: 'operativeTypeNameEn', disablePadding: false, label: t('OPERATIVE_TYPE.TABLE.CELL.OPERATIVE_TYPE_NAME_EN') },
    { id: 'status', disablePadding: false, label: t('OPERATIVE_TYPE.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('OPERATIVE_TYPE.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { operativeTypeId: optTypeId, operativeTypeName: optTypeName, operativeTypeNameEn: optTypeNameEn, color: optTypeColor, updatedBy, updatedAt, status: optTypeStatus } = objData
    const statusBtnActive = optTypeStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = optTypeStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: optTypeId,
      id: optTypeId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        {
          option: 'TEXT',
          align: 'left',
          label: (
            <>
              {valueExportData && <div className="flex-row">
                {optTypeColor}
              </div> ||
                <div className="flex-row">
                  <div style={{ width: 75, height: 25, backgroundColor: `${optTypeColor}`, marginRight: 10 }}></div>
                </div>
              }
            </>
          )
        },
        { option: 'TEXT', align: 'left', label: optTypeName },
        { option: 'TEXT', align: 'left', label: optTypeNameEn },
        { option: 'STATUS', align: 'center', label: optTypeStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon
            {...objRenderData}
            onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
            oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
            onedit={() => onEdit(objRenderData.obj, false)}
            ondelete={() => setIsAlertDel(optTypeId)}
          />
        }
      </>
    )
  }

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}` }
  ]

  return (
    <div className={'h-100'}>
      <AlertModal
        isOpen={!!isAlertDel}
        title={t('PROMOTION_TYPE.MESSAGE.DEL_TITLE')}
        message={t('OPERATIVE_TYPE.MESSAGE.DEL_SUBTITLE', operativeTypes.map((item: any) => ({ ...item, operativeTypeNameEn: !item?.operativeTypeNameEn ? '' : `(${item?.operativeTypeNameEn})` })).find((item: any) => Number(item.operativeTypeId) === Number(isAlertDel)))}
        type="error"
        onSubmit={{ onClick: () => onDelete(isAlertDel), title: t("BUTTON.SUBMIT") }}
        onCancel={{
          onClick: () => setIsAlertDel(0), title: t("BUTTON.CANCEL")
        }}
        handleClose={() => setIsAlertDel(0)}
      />
      <Card className="border-0 h-100">
        <HeaderCard text={t('OPERATIVE_TYPE.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={7}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('OPERATIVE_TYPE.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={5} className="mt-2 mt-md-auto">
                <div className="pr-xl-2 d-flex">
                  <div className='ml-auto'>
                    <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('OPERATIVE.OPERATIVE_LIST_TYPE')}
                      rowsData={exportData.map((val, i) => {
                        return renderData(val, i, true)
                      })} />
                  </div>
                  <div className='pl-3'>
                    <ButtonCustom
                      mode="add"
                      disabled={permissions.isCreate.disabled}
                      onClick={() => {
                        dispatch(showModal())
                        setModalOpType(true)
                      }}
                      textButton={t('OPERATIVE_TYPE.BUTTON.ADD')}
                      className="w-auto ml-auto d-flex mt-auto"
                      btnStyle={{ height: '44px' }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={operativeTypes.map((val, i) => {
                return renderData(val, i, false)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      {modalOpType &&
        <OperativeTypesForm
          type={'SETTING'}
          data={{
            operativeTypeId: operativeTypeId,
            operativeTypeStatus: operativeTypeStatus,
            operativeTypeName: operativeTypeName,
            operativeTypeNameEn: operativeTypeNameEn,
            operativeTypeFile: operativeTypeFile,
            operativeTypeColor: operativeTypeColor,
            operativeTypeDes: operativeTypeDes,
          }}
          errorMessage={errorMessage}
          clearErrorMessage={clearErrorMessage}
          resetForm={resetForm}
          submit={submit}
          submitEdit={submitEdit}
          onEdit={onEdit}
          setDelImage={setDelImage}
        />
      }

    </div>
  )
}
