import { Box, Tooltip } from '@mui/material'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info';
import TableRowCommon from 'component/Table/TableRowCommon'
import { dateToView } from 'utils/date.utils'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

type FormViewVoucherProps = {
    onClose: () => void
    dataVoucher: any
}

const FormViewVoucher = (props: FormViewVoucherProps) => {
    const { t } = useTranslation()

    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(5)
    const [rowCount, setRowCount] = useState<number>(0)
    const [sortBy, setSortBy] = useState('voucherId')
    const [sortType, setSortType] = useState('DESC')

    const [copy, setCopy] = useState(0)

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => setPage(val)

    const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

    const headCells = [
        { id: 'no', disablePadding: false, label: t('#'), width: '50px', align: 'center', },
        { id: 'code', disablePadding: false, label: t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE_'), align: 'left', class: 'text-nowrap ' },
        { id: 'codeList', disablePadding: false, label: t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE_LOT'), width: '100px', align: 'center', class: 'text-nowrap ' },
        { id: 'endDate', disablePadding: false, label: t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_DATEEND'), width: '120px', align: 'center', class: 'text-nowrap ' },
    ]

    const renderData = (objData: any, index: number,) => {
        const newIndex = page * pageLimit - pageLimit + index + 1
        const { voucherListId, voucherCode, endDate, lotNumber } = objData

        const formatNumber = (num: number) => {
            if (!num) {
                return num
            }
            return num.toString().padStart(4, '0');
        };

        const commonColumns = [
            { option: 'TEXT', align: 'center', label: newIndex },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} position="relative">
                        {voucherCode}
                        <Box onClick={() => handleCopyClick(voucherCode, newIndex)} sx={{ cursor: 'pointer', 'svg': { fill: '#808080' } }}>
                            <FileCopyOutlinedIcon />
                            <Box>
                                {copy === newIndex &&
                                    <Box position="absolute" padding="10px" bgcolor="#5A5A5A" color="#FFFFFF" borderRadius="2px" top="-40px" fontSize="14px" fontWeight={400}>Copied</Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                )
            },
            { option: 'TEXT', class: 'text-nowrap ', align: 'center', label: formatNumber(lotNumber) },
            { option: 'TEXT', align: 'center', label: dateToView(endDate) },
        ]

        const objRenderData = {
            key: voucherListId,
            id: voucherListId,
            obj: objData,
            columns: commonColumns
        }

        return <TableRowCommon {...objRenderData} />
    }

    const renderVoucher = () => {
        const startIndex = (page - 1) * pageLimit
        return props.dataVoucher?.voucherDetails?.slice(startIndex, startIndex + pageLimit)
    }

    useEffect(() => {
        setRowCount(props.dataVoucher?.voucherDetails?.length || 0)
    }, [props.dataVoucher?.voucherDetails])

    const handleCopyClick = (text: string, index: number) => {
        setCopy(index);
        navigator?.clipboard?.writeText(text)
        setTimeout(() => {
            setCopy(0);
        }, 2000);
    };

    return (
        <>
            <ModalCustom
                title={t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE')}
                textBtnCancel={t('BUTTON.CANCEL')}
                onReset={() => props.onClose()}
                onClose={() => props.onClose()}
                alignFooter={'end'}
                closeButton
                size={'lg'}
                component={(
                    <>
                        <Box>
                            <Box display={'flex'} alignItems={'center'} gap={'8px'} >
                                <Box>
                                    {t('VOUCHER.POP_PRODUCT.FORM.NAME')}
                                </Box>
                                <Box>
                                    {props.dataVoucher?.voucherName}
                                </Box>

                                {props.dataVoucher?.detail &&
                                    <span>
                                        <Tooltip title={props.dataVoucher?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </span>
                                }
                            </Box>
                            <Box sx={{ marginTop: '16px' }}>
                                <TableCustom
                                    page={page}
                                    pageLimit={pageLimit}
                                    sortType={sortType}
                                    sortBy={sortBy}
                                    onSort={onRequestSort}
                                    setPageLimit={handleChangeRowsPerPage}
                                    setPage={handleChangePage}
                                    rowCount={rowCount}
                                    headCells={headCells}
                                    rowsData={props.dataVoucher?.voucherDetails?.length ? renderVoucher().map((item: any, index: number) => {
                                        return renderData(item, index)
                                    }) : []}
                                />
                            </Box>
                        </Box>

                    </>
                )}
            />
        </>
    )
}

export default FormViewVoucher