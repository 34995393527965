import { Box, Grid, styled } from '@mui/material';
import { colors } from 'constants/theme'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectMe, selectProfile, selectUser } from 'app/slice/user.slice';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'

/** STYLE */
import { Title, Subtitle as Body } from 'features/page/style'
import { Imgs } from 'constants/images'
import { getPermissionShortcuts } from 'utils/app.utils';

export const CustomTitle = styled(Title)(({ theme }) => ({
  fontSize: 36,
  color: colors.themeMainColor,
  [theme.breakpoints.up('xl')]: {
    fontSize: 40
  }
}))

export const CustomSubTitle = styled(Title)(({ theme }) => ({
  fontSize: 28,
  [theme.breakpoints.up('xl')]: {
    fontSize: 32,
  }
}))

export const CustomImgNotFound = styled(Box)(({ theme }) => ({
  maxWidth: '748px',
  svg: {
    width: '100%',
    height: 'auto'
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '320px',
  }
}))

export default function PermissionDenied() {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useSelector(selectMe)
  const permissionShortcuts = getPermissionShortcuts()

  const backToHome = () => {
    history.push(routeName.welcome)
  }


  return (
    <Box className='h-100 p-3'>
      <Box className='mx-auto h-100 d-flex align-items-center d-xl-block' sx={{ maxWidth: { lg: '85%' } }}>
        <Grid container justifyContent={'center'} sx={{ height: { lg: '100%' } }}>
          <Grid item lg={6} xl={5} className='w-100 d-flex align-items-center justify-content-center d-flex'>
            <CustomImgNotFound className='w-100'>
              <Imgs.AccessDenied />
            </CustomImgNotFound >
          </Grid >
          <Grid item lg={6} className='w-100 d-flex flex-column align-items-center justify-content-center px-sm-3'>
            <Box>
              <CustomTitle className='mt-4 mt-xl-0 text-center'>{t('SORRY')}</CustomTitle>
              <CustomSubTitle className='mt-3'>{t('NO_PAGE')}</CustomSubTitle>
              <Body className='mt-0 pt-1'>{t('PERMISSION_DENIED', { name: user?.roles?.roleName || user?.roles?.roleNameEn || '' })}</Body>
              {permissionShortcuts.includes('HOME') && <ButtonCustom
                textButton={t('BUTTON.BACK_TO_HOME')}
                onClick={backToHome}
                style={{ margin: 0, textAlign: 'center' }}
                btnStyle={{ height: '52px', marginTop: '40px', fontSize: '16px', width: '100%', maxWidth: '300px' }} />}
            </Box>
          </Grid>
        </Grid >
      </Box >
    </Box >
  )
}
