import promotionTH from '../crmManage/promotion.json'
import voucherTH from '../crmManage/voucher.json'
import giftVoucherTH from '../crmManage/gift-voucher.json'

export const crmManageTH = {
  ...promotionTH,
  ...voucherTH,
  ...giftVoucherTH,
  VOUCHER: {
    ...voucherTH.VOUCHER,
    ...giftVoucherTH.VOUCHER
  }
}
