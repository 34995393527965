import { Button } from "@mui/material";

interface IconButtonProps {
    onClick: () => void
    size: 'small' | 'medium' | 'large'
    variant: 'contained' | 'outlined'
    type?: 'primary' | 'danger'
    disabled?: boolean
    icon: any
}

export default function IconButton(props: IconButtonProps) {

    ////-------------------
    ////    HANDLE
    ////-------------------
    const handleColor = () => {
        const cssContained = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: props.type === 'primary' ? '#1CB99A' : '#FB5252',
            color: "#FFFFFF",
            '&:hover': {
                background: props.type === 'primary' ? '#1BA58A' : '#E24A4A'
            },
            '&:active': {
                background: props.type === 'primary' ? '#009073' : '#B00020'
            },
            '&:disabled': {
                background: '#E0E0E0',
                color: '#808080',
            },
        }
        const cssOutlined = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: props.type === 'primary' ? '#1CB99A' : '#FB5252',
            color: props.type === 'primary' ? '#1CB99A' : '#FB5252',
            '&:hover': {
                borderColor: props.type === 'primary' ? '#1CB99A' : '#FB5252',
                background: props.type === 'primary' ? '#F1FBF9' : '#FFF0F2',
            },
            '&:active': {
                borderColor: props.type === 'primary' ? '#1CB99A' : '#FB5252',
                background: props.type === 'primary' ? '#D1F1EB' : '#FFCDD3'
            },
            '&:disabled': {
                background: '#E0E0E0',
                color: '#808080',
            },
        }
        const sxSmall = {
            width: '24px',
            height: '24px',
            minWidth: '24px',
            minHeight: '24px',
            padding: 0
        }
        const sxMedium = {
            width: '32px',
            height: '32px',
            minWidth: '32px',
            minHeight: '32px'
        }
        const sxLarge = {
            width: '40px',
            height: '40px',
            minWidth: '40px',
            minHeight: '40px'
        }
        switch (props.size) {
            case 'small':
                if (props.variant === 'contained') {
                    return { ...cssContained, ...sxSmall }
                } else {
                    return { ...cssOutlined, ...sxSmall }
                }
            case 'medium':
                if (props.variant === 'contained') {
                    return { ...cssContained, ...sxMedium }
                } else {
                    return { ...cssOutlined, ...sxMedium }
                }
            case 'large':
                if (props.variant === 'contained') {
                    return { ...cssContained, ...sxLarge }
                } else {
                    return { ...cssOutlined, ...sxLarge }
                }
            default:
                break;
        }
    }

    return (
        <Button disableRipple disabled={props.disabled} onClick={props.onClick} variant={props.variant} sx={handleColor()}>
            {props.icon}
        </Button>
    )
}