import promotionEN from '../crmManage/promotion.json'
import voucherEN from '../crmManage/voucher.json'
import giftVoucherEN from '../crmManage/gift-voucher.json'

export const crmManageEN = {
  ...promotionEN,
  ...voucherEN,
  ...giftVoucherEN,
  VOUCHER: {
    ...voucherEN.VOUCHER,
    ...giftVoucherEN.VOUCHER
  }
}
