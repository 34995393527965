import { useEffect, useState } from 'react'
import { Grid, MenuItem, Divider, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

/** API */

/** COMPONENT */

/** STYLE */
import BasicSelect from 'component/Select/BasicSelect'
import { Col, Row } from 'react-bootstrap'
import { colors } from 'constants/theme';
import InputCheckbox from 'component/Input/InputCheckbox';
import InputRadio from 'component/Input/InputRadio';

export interface ApplicationInterface {
  package: "STANDARD" | "PREMIUM" | "ULTRA"
  enableApp: string
  branchDefaultRegisterApps: any[]
  callbackEnableApp: (value: any) => void
  callbackRegisterApps: (value: any) => void
  useWeb: string
  callbackUseWeb: (value: string) => void
  dataABout: any
  setDataAbout: (value: any) => void
}
export default function FormApplication(props: ApplicationInterface) {
  const { t } = useTranslation()
  const [enableApp, setEnableApp]: any = useState(props.enableApp);
  const [usePackage, setPackage] = useState(props.package);
  const [branchDefaultRegisterApps, setBranchDefaultRegisterApps] = useState(props.branchDefaultRegisterApps);

  useEffect(() => {
    setEnableApp(props.enableApp)
    setPackage(props.package)
    setBranchDefaultRegisterApps(props.branchDefaultRegisterApps)
  }, [props])

  return (
    <Box className="px-4 py-2">
      <Typography className='mb-2' sx={{ fontWeight: 500, fontSize: '18px' }}>{t('CLINIC_MANAGEMENT.LABEL.ACTIVATION')}</Typography>
      <Box marginX={'10px'}><InputCheckbox label={t('CLINIC_MANAGEMENT.LABEL.APPLICATION')} checked={enableApp === '1'} onChange={() => props.callbackEnableApp(enableApp === '1' ? '0' : '1')} /></Box>
      <Box marginX={'10px'}><InputCheckbox label={t('CLINIC_MANAGEMENT.FORM.WEBSITE_ENABLE')} checked={props.dataABout?.useWeb === '1'} onChange={() => props.setDataAbout((value: any) => ({ ...value, useWeb: props.dataABout?.useWeb === '1' ? '0' : '1' }))} /></Box>
      <Box marginX={'10px'}><InputCheckbox label={t('CLINIC_MANAGEMENT.FORM.CRM_ENABLE')} checked={props.dataABout?.useCrm === '1'} onChange={() => props.setDataAbout((value: any) => ({ ...value, useCrm: props.dataABout?.useCrm === '1' ? '0' : '1' }))} /></Box>
      <Box marginX={'10px'}><InputCheckbox disabled label={t('CLINIC_MANAGEMENT.FORM.EMAIL_ENABLE')} checked={props.dataABout?.openEmailNotification === '2'} onChange={() => props.setDataAbout((value: any) => ({ ...value, openEmailNotification: props.dataABout?.openEmailNotification === '1' ? '0' : '1' }))} /></Box>
      <Box marginX={'10px'}><InputCheckbox disabled label={t('CLINIC_MANAGEMENT.FORM.LINE_ENABLE')} checked={props.dataABout?.openLineNotification === '2'} onChange={() => props.setDataAbout((value: any) => ({ ...value, openLineNotification: props.dataABout?.openLineNotification === '1' ? '0' : '1' }))} /></Box>
      <Box marginX={'10px'} display="none"><InputCheckbox label={t('CLINIC_MANAGEMENT.FORM.SMS_ENABLE')} checked={props.dataABout?.openSmsNotification === '1'} onChange={() => props.setDataAbout((value: any) => ({ ...value, openSmsNotification: props.dataABout?.openSmsNotification === '1' ? '0' : '1' }))} /></Box>
      <Box className={`d-none`}>
        <Grid container spacing={2}>
          {// usePackage !== 'ULTRA' &&
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <BasicSelect
                name="add_on_app"
                label={t('CLINIC_MANAGEMENT.LABEL.APPLICATION')}
                selectId="sl-add-on-app"
                labelId="lb-add-on-app"
                value={enableApp === '0' ? 'FALSE' : 'TRUE'}
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key="1" value="FALSE">
                    {t('CLINIC_MANAGEMENT.LABEL.CLOSE')}
                  </MenuItem>,
                  <MenuItem key="2" value="TRUE">
                    {t('CLINIC_MANAGEMENT.LABEL.OPEN')}
                  </MenuItem>
                ]}
                onchange={(e) => {
                  props.callbackEnableApp(e.target.value === "TRUE" ? "1" : "0")
                }}
              />
            </Grid>}
        </Grid>
        <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
        <Box sx={{ paddingBottom: "16px" }}>
          <Typography sx={{ fontSize: '1.25rem', fontWeight: '500' }}>{t('CLINIC_MANAGEMENT.FORM.APPLICATION')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography sx={{ fontWeight: 500, pl: 2 }}>
              {t('CLINIC_MANAGEMENT.FORM.NAME_BR')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography sx={{ fontWeight: 500 }}>
              {t('CLINIC_MANAGEMENT.FORM.AUTO_REGIS_APP')}
            </Typography>
          </Grid>
        </Grid>
        {branchDefaultRegisterApps?.map((val: any, index: any) => (
          <Grid container key={index} spacing={2} alignItems="center" sx={{ pt: 2 }}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ pl: 2 }}>
                {val.branchName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <BasicSelect
                disabled={enableApp === '1' ? false : true}
                name={`auto_regis_${index}`}
                label=""
                selectId={`sl-auto-regis-${index}`}
                labelId={`lb-auto-regis-${index}`}
                value={val.isDefaultRegisApp === '0' ? 'FALSE' : 'TRUE'}
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key="1" value="FALSE">
                    {t('CLINIC_MANAGEMENT.LABEL.CLOSE')}
                  </MenuItem>,
                  <MenuItem key="2" value="TRUE">
                    {t('CLINIC_MANAGEMENT.LABEL.OPEN')}
                  </MenuItem>
                ]}
                onchange={(e) => {
                  val.isDefaultRegisApp = e.target.value === "TRUE" ? "1" : "0"
                  props.callbackRegisterApps([...branchDefaultRegisterApps])
                }}
              />
            </Grid>
          </Grid>
        ))}
        <Box>
          <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
          <Box sx={{ paddingBottom: "16px" }}>
            <Typography sx={{ fontSize: '1.25rem', fontWeight: '500' }}>
              {t('CLINIC_MANAGEMENT.FORM.WEBSITE_TITLE')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}
