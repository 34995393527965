import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useRef, useState } from 'react';
import { Box, IconButton, } from '@mui/material';
import CustomCalendar from './calendar';
import { HelperText } from './helper-text';
import CalendarIcon from "@mui/icons-material/CalendarToday"
import ClearIcon from '@mui/icons-material/Clear';

interface DateInputProps {
    label: string
    value: string
    onChange: (e: any) => void
    onClear?: (e: any) => void
    disabled?: boolean
    disabledDateFuture?: boolean
    disabledDatePast?: boolean
    language?: "TH" | "EN"
    width?: number
    fullWidth?: boolean
    helperText?: string
    required?: boolean
    startDate?: any
}

export default function DateInput(props: DateInputProps) {

    ////-------------------
    ////    STATE
    ////-------------------
    const [showCalendar, setShowCalendar] = useState(false);
    const calendarRef = useRef<HTMLDivElement | null>(null);

    ////-------------------
    ////    EFFECT
    ////-------------------
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    ////-------------------
    ////    HANDLE
    ////-------------------
    const handleOnClick = () => {
        if (!props.disabled) {
            setShowCalendar(true)
        }
    }

    const handleClickOutside = (event: any) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setShowCalendar(false);
        }
    };

    function convertToThaiDate(dateString: string) {
        if (dateString) {
            const dateParts = dateString.split('-');
            const year = Number(dateParts[0]);
            const adjustedYear = props.language === 'TH' ? year + 543 : year;
            const month = dateParts[1];
            const day = dateParts[2];
            return `${day}/${month}/${adjustedYear}`;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box position="relative">
                <TextField
                    required={props.required}
                    value={convertToThaiDate(props.value) || ''}
                    onClick={handleOnClick}
                    label={props.label}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <>
                                {/* onClick ควรทำ e.stopPropagation() ทุกครั้งที่เรียกใช้ */}
                                {props.value && !props.disabled && <IconButton onClick={props.onClear}>
                                    <ClearIcon />
                                </IconButton>}
                                {!props.disabled && <IconButton sx={{ width: '24px', height: '24px' }}>
                                    <CalendarIcon sx={{ color: props.disabled ? '#BBBBBB' : '#1CB99A' }} />
                                </IconButton>}
                            </>
                        )
                    }}
                    fullWidth={props.fullWidth}
                    disabled={props.disabled}
                    sx={{
                        width: props.width,
                        '& .MuiInputLabel-root': {
                            transform: 'translate(14px, 9px)',
                            color: showCalendar ? '#1CB99A' : '#808080',
                            backgroundColor: 'transparent !important',
                        },
                        '& .MuiInputLabel-shrink': {
                            transform: 'translate(14px, -10px) scale(0.75)',
                        },
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: props.disabled ? '#F0F0F0' : '#FFFFFF',
                            width: props.width,
                            height: 40,
                            '& fieldset': {
                                borderColor: showCalendar ? '#1CB99A' : '#C5C5C5',
                            },
                            '&:hover fieldset': {
                                borderWidth: '1px',
                                borderColor: showCalendar ? '#1CB99A' : '#231F20',
                                color: '#29302E'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#1CB99A',
                                color: '#29302E'
                            },
                            '&.Mui-disabled fieldset': {
                                borderColor: '#C5C5C5',
                            },
                            '&.Mui-error fieldset': {
                                borderColor: `#B00020`,
                            },
                        },

                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#1CB99A',
                        },
                        '& .MuiInputLabel-root.Mui-error': {
                            color: '#B00020'
                        },
                        '& .MuiOutlinedInput-input': {
                            '& .Mui-disabled': {
                                color: '#808080'
                            }
                        },
                    }}
                    InputLabelProps={{
                        sx: {
                            '& .MuiFormLabel-asterisk': {
                                color: (!props.value || props.value === '') ? 'gray !important' : "red !important",
                            },
                            '&.Mui-focused .MuiFormLabel-asterisk': {
                                color: 'red !important',
                            },
                        },
                    }}
                    helperText={props.helperText ? <HelperText label={props.helperText} /> : ''}
                    error={props.helperText !== '' && props.helperText !== undefined}
                />
                {showCalendar && (
                    <Box ref={calendarRef} width={240} position="absolute" zIndex={50}>
                        <CustomCalendar value={props.value} onChange={props.onChange} disabledDateFuture={props.disabledDateFuture} disabledDatePast={props.disabledDatePast} language={props.language} startDate={props.startDate} setShowCalendar={setShowCalendar} />
                    </Box>
                )}
            </Box>
        </LocalizationProvider>
    )
}