import App from 'App'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from 'reportWebVitals'
import store from 'app/store'
import { Provider } from 'react-redux'
import { CssBaseline } from '@mui/material'
import { create } from 'jss'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { StylesProvider, jssPreset } from '@mui/styles'

/** MOMENT LOCALE */
import 'moment/locale/en-gb'
import 'moment/locale/th'

/** STYLES */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'index.css'
import 'styles/Custom.css'
import 'styles/Tool.css'
import 'styles/DataTable.css'
import 'styles/Input.css'
import 'styles/Toastify.css'
import 'styles/SwalPopup.css'
import 'styles/DatePicker.css'
import 'styles/Slick.css'
import 'styles/Switch.css'
import 'App.css'
import 'styles/SlickXray.css'
import 'styles/print.css'

const jss = create({
  ...jssPreset()
})

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      // xs: 576,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1920
    }
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 2050,
    snackbar: 1400,
    tooltip: 1500
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    }
  }
})

function Main() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst jss={jss}>
          <CssBaseline />
          <Provider store={store}>
            <head>
              <noscript id="jss-insertion-point" />
            </head>
            <App />
          </Provider>
        </StylesProvider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<Main />, document.getElementById('root'))
// const container = document.getElementById('app')
// const root = createRoot(container!) // createRoot(container!) if you use TypeScript
// root.render(<Main />)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
