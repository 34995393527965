import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Tab, Typography, Button } from '@mui/material'
import CanvasDraw from 'react-canvas-draw'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import TreatmentsAPI, { SignatureAttachmentsProps } from 'api/dentists/treatment.api'
import TreatmentSummaryApi from 'api/dentists/treatment-summary.api'
import { fileUrl } from 'api/api'

/** UTILS || SLICE */
import { resetModal } from 'app/slice/modal.slice'
import { getClinicInfo, renderAge } from 'utils/app.utils'
import { ToastContainer } from 'react-toastify'
import { setTreatmentByKey } from 'app/slice/treatment.slice'

/** COMPONENT */
import ModalCustom, { BtnConfirm } from 'component/ModalCustom/ModalCustom'
import { notiError, notiPopup } from 'component/notifications/notifications'
import ButtonCustom from 'component/Button/ButtonCustom'
import Loading from 'component/Loading'

/** STYLE */
import * as UseStyled from 'component/Treatments/style/popupTreatmentConsentUseStyled'
import { colors } from 'constants/theme'
import { onImgError } from 'constants/images'
import PdfInvoice from 'component/Print/pdfInvoice'
import FunctionPrint from 'component/Print/print'

type PopupTreatmentConsentProps = {
  type: 'treatment' | 'historyTreatment'
  patientCaseId: number
  servicePointId: number
  setIsShow: (show: boolean) => void
  handleTreatmentCurrent?: (data: any) => void
}

export default function PopupTreatmentFees(props: PopupTreatmentConsentProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const branchInfo = getClinicInfo()

  const [doctorTab, setDoctorTab] = useState(0);
  const [treatments, setTreatments]: any = useState([]);
  const [treatment, setTreatment]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);

  const canvasDoctorRef: any = useRef()
  const canvasPatientRef: any = useRef()
  const defaultSignature: SignatureAttachmentsProps = {
    doctorId: 0,
    servicePointId: props.servicePointId,
    doctorSignature: '',
    patientSignature: ''
  }
  const [signature, setSignature] = useState<SignatureAttachmentsProps>(defaultSignature);

  const transformScale = 0.7

  const loadData = async (patientCaseId: number) => {
    const resp = await TreatmentsAPI.findInvoiceTreatment(patientCaseId).finally(() => setLoading(false))

    if (resp.status === 200) {
      const invoices = props.type === 'historyTreatment' ?
        _.filter(resp.data, (data) => {
          if (data.invoice) return data
        }) :
        _.filter(resp.data, (data) => {
          if (!_.isEmpty(data.summeryTreatments) || !_.isEmpty(data?.summeryProducts)) return data
        })

      setTreatments(invoices)
      setTreatment(invoices[doctorTab])
      setSignature({
        ...signature,
        doctorId: invoices[doctorTab]?.doctorId,
        doctorSignature: invoices[doctorTab]?.invoice?.doctorSignature,
        patientSignature: invoices[doctorTab]?.invoice?.patientSignature
      })
    }
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    notiPopup('clear')
    if ((signature.doctorSignature || signature.patientSignature) && (!treatment?.invoice?.doctorSignature && !treatment?.invoice?.patientSignature)) {
      return (
        notiPopup(
          'custom',
          '',
          <div className={'d-flex align-items-center justify-content-between'}>
            <span>{t(`MODAL_TREATMENT_CONSENT.MESSAGE.INPROGRESS`)}</span>
            <Button
              variant="text"
              disableRipple
              onClick={() => {
                clearCanvas('both')
                notiPopup('clear')
              }}
              sx={{ color: colors.statusWarning, fontSize: 16, fontWeight: 400, padding: 0, lineHeight: 1, backgroundColor: 'transparent !important' }}>
              {t(`MODAL_TREATMENT_CONSENT.BUTTON.DISCARD`)}
            </Button>
          </div>
        )
      )
    }
    clearCanvas('both')
    setDoctorTab(newValue);
    setTreatment(treatments[newValue])
    setSignature({
      ...signature,
      doctorId: treatments[newValue]?.doctorId,
      doctorSignature: treatments[newValue]?.invoice?.doctorSignature,
      patientSignature: treatments[newValue]?.invoice?.patientSignature
    })
  };

  const onSave = async (type: 'save' | 'print') => {
    await TreatmentsAPI.saveInvoiceTreatment(props.patientCaseId, signature).then(async (e) => {
      clearCanvas('both')
      await loadData(props.patientCaseId)
      notiPopup('success', t(`MODAL_TREATMENT_CONSENT.MESSAGE.SUCCESS.SAVE`))
      await handleChangeTreatmentConsent(props.patientCaseId)
    }).catch((e) => {
      notiError(t('MODAL_TREATMENT_CONSENT.MESSAGE.ERROR.DEFAULT'))
    })
  }

  const onCancel = async () => {
    await TreatmentsAPI.deleteInvoiceTreatment(treatment?.invoice?.patientInvoiceId).then(async (e) => {
      clearCanvas('both')
      loadData(props.patientCaseId)
      notiPopup('success', t(`MODAL_TREATMENT_CONSENT.MESSAGE.SUCCESS.CANCEL`))
      await handleChangeTreatmentConsent(props.patientCaseId)
    }).catch((e) => {
      notiError(t('MODAL_TREATMENT_CONSENT.MESSAGE.ERROR.DEFAULT'))
    })
  }

  const onClosePopup = () => {
    dispatch(resetModal())
    props.setIsShow(false)
  }

  const renderCanvas = (type: 'doctor' | 'patient') => {
    if (type === 'doctor') {
      if (canvasDoctorRef.current) {
        const base64 = canvasDoctorRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
        let base64string: any = `${base64}`.split(',')
        base64string = base64string[base64string.length - 1]
        setSignature({ ...signature, doctorId: treatment.doctorId, doctorSignature: base64string })
      }
    } else {
      if (canvasPatientRef.current) {
        const base64 = canvasPatientRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
        let base64string: any = `${base64}`.split(',')
        base64string = base64string[base64string.length - 1]
        setSignature({ ...signature, doctorId: treatment.doctorId, patientSignature: base64string })
      }
    }
  }

  const clearCanvas = (type: 'doctor' | 'patient' | 'both') => {
    if (type === 'doctor' && canvasDoctorRef.current) {
      canvasDoctorRef.current.clear()
      canvasDoctorRef.current.imgSrc = ''
      canvasDoctorRef.current.resetView()
      setSignature({ ...signature, doctorSignature: '' })
    }

    if (type === 'patient' && canvasPatientRef.current) {
      canvasPatientRef.current.clear()
      canvasPatientRef.current.imgSrc = ''
      canvasPatientRef.current.resetView()
      setSignature({ ...signature, patientSignature: '' })
    }

    if (type === 'both' && canvasDoctorRef.current && canvasPatientRef.current) {
      canvasPatientRef.current.clear()
      canvasPatientRef.current.imgSrc = ''
      canvasPatientRef.current.resetView()
      canvasDoctorRef.current.clear()
      canvasDoctorRef.current.imgSrc = ''
      canvasDoctorRef.current.resetView()
      setSignature({ ...signature, doctorSignature: '', patientSignature: '' })
    }
  }

  const handleChangeTreatmentConsent = async (patientCaseId: number) => {
    const findTry = await TreatmentSummaryApi.findTreatmentSummary(patientCaseId)
    if (findTry.status === 200) {
      const summeryProducts: any = []
      if (!_.isEmpty(findTry.data.summeryProducts)) {
        _.map(findTry.data.summeryProducts, (prod, index) => {
          summeryProducts.push({ ...prod, total: ((prod.price * prod.qty) - prod.discount) + prod.additional })
        })
      }
      props?.handleTreatmentCurrent && props.handleTreatmentCurrent([...findTry.data.summeryTreatments, ...summeryProducts])
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...findTry.data.summeryTreatments, ...summeryProducts] }))
      dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: [...findTry.data.patientDiagnosis] }))
      dispatch(setTreatmentByKey({ key: 'patientRecommends', value: [...findTry.data.patientRecommends] }))
    }
  }

  useEffect(() => {
    if (props.patientCaseId) loadData(props.patientCaseId)
  }, [props.patientCaseId]);

  const pdfInvoiceRef = useRef<any>(null)

  return (
    <ModalCustom
      size={'xl'}
      title={t('PDF_INVOICE.TITLE')}
      alignFooter={'end'}
      fullscreen
      footerInline
      onClose={() => onClosePopup()}
      closeButton={_.isEmpty(treatments)}
      modalStyle={'modal-med-certificate modal-treatment-consent'}
      component={
        <>
          {!loading && (
            !_.isEmpty(treatments) && (
              <>
                <Box sx={{ borderBottom: 1, borderColor: colors.extraLightBlue }}>
                  <UseStyled.DoctorTabs
                    value={doctorTab}
                    onChange={handleChangeTab}
                    aria-label="doctor-tabs"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    {
                      _.map(treatments, (trm, index: number) => {
                        return <Tab label={<span className='text-ellipsis w-100'>{`${trm?.firstname} ${trm?.lastname}`}</span>} {...a11yProps(index)} />
                      })
                    }
                  </UseStyled.DoctorTabs>
                </Box>
                <ToastContainer enableMultiContainer containerId={'popup'} className={'Toast-popup'} />

                <Box
                  sx={{
                    padding: '16px 0',
                    display: 'flex',
                    justifyContent: 'center',
                    height: `calc(${420 * transformScale}mm + 32px)`
                  }}
                >
                  <Box
                    sx={{ transform: `scale(${transformScale})`, height: '0px' }}
                  >
                    <Box ref={pdfInvoiceRef}>
                      <PdfInvoice
                        type={props.type}
                        treatment={treatment}
                        renderCanvas={renderCanvas}
                        clearCanvas={clearCanvas}
                        canvasDoctorRef={canvasDoctorRef}
                        canvasPatientRef={canvasPatientRef}
                        signature={signature}
                      />
                    </Box>

                  </Box>
                </Box>

                <Box
                // sx={{ display: 'none' }}
                >
                  <Box className='mt-3 custom-scroll overflow-auto' sx={{ flex: 'none' }}>
                    <UseStyled.SignatureWrap className={`d-md-flex align-items-center ${props.type === 'historyTreatment' ? 'pe-none' : ''}`}>
                      <Box className={`signatureWrap-box w-100`}>
                        <Box className={`${treatment?.invoice?.doctorSignature ? 'pe-none' : ''}`}>
                          <UseStyled.SignBox className='mx-auto'>
                            {treatment?.invoice?.doctorSignature && (
                              <img src={`data:image/png;base64,${treatment?.invoice?.doctorSignature}`} className="w-100 h-100" alt="" />
                            ) || (
                                <Box className={`clear-box ${props.type === 'historyTreatment' ? 'd-none opacity-0' : ''}`}>
                                  <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('doctor')} />
                                </Box>
                              )}
                            <Box className="canvas-box">
                              <CanvasDraw
                                hideGrid
                                hideInterface
                                brushRadius={2}
                                lazyRadius={2}
                                brushColor={colors.black}
                                ref={canvasDoctorRef}
                                onChange={() => renderCanvas('doctor')} />
                            </Box>
                          </UseStyled.SignBox>
                        </Box>
                        <Box className='text-center mt-2'>
                          <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{`${treatment?.firstname} ${treatment?.lastname}`}<span className='d-md-none'>)</span></Typography>
                          <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_TREATMENT_CONSENT.SIGN.DOCTOR')}</Typography>
                          <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Dentist{"'"}s Signature</Typography>
                        </Box>
                      </Box>
                      <Box className={`signatureWrap-box w-100 mt-3 mt-md-0`}>
                        <Box className={`${treatment?.invoice?.patientSignature ? 'pe-none' : ''}`}>
                          <UseStyled.SignBox className='mx-auto'>
                            {treatment?.invoice?.patientSignature && (
                              <img src={`data:image/png;base64,${treatment?.invoice?.patientSignature}`} className="w-100 h-100" alt="" />
                            ) || (
                                <Box className={`clear-box ${props.type === 'historyTreatment' ? 'd-none opacity-0' : ''}`}>
                                  <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('patient')} />
                                </Box>
                              )}
                            <Box className="canvas-box">
                              <CanvasDraw
                                hideGrid
                                hideInterface
                                brushRadius={2}
                                lazyRadius={2}
                                brushColor={colors.black}
                                ref={canvasPatientRef}
                                onChange={() => renderCanvas('patient')} />
                            </Box>
                          </UseStyled.SignBox>
                        </Box>
                        <Box className='text-center mt-2'>
                          <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{`${treatment?.patientInfo?.firstname} ${treatment?.patientInfo?.lastname}`}<span className='d-md-none'>)</span></Typography>
                          <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_TREATMENT_CONSENT.SIGN.PATIENT')}</Typography>
                          <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Patient{"'"}s Signature</Typography>
                        </Box>
                      </Box>
                    </UseStyled.SignatureWrap>
                  </Box>
                </Box>

                <UseStyled.ModalFooter className='d-flex flex-column flex-md-row align-items-center justify-content-md-end'>
                  <Box className='d-flex align-items-center'>
                    <Box className='pr-1 pr-md-2'>
                      <ButtonCustom
                        className={'btn-cancel'}
                        variant={'outlined'}
                        textButton={t('BUTTON.CLOSE')}
                        onClick={onClosePopup} />
                    </Box>
                    <Box className={`pl-1 pl-md-2`}>
                      <FunctionPrint
                        content={() => pdfInvoiceRef.current}
                        docTitle={t("PDF_INVOICE.TITLE")}
                        removeIfram
                        trigger={() => (
                          <ButtonCustom
                            disabled={!signature?.doctorSignature || !signature?.patientSignature}
                            className={'btn-print'}
                            variant={'outlined'}
                            textButton={t('BUTTON.PRINT')}
                          />
                        )}
                        style={'@page { size: 297mm 420mm;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                        beforePrint={() => {
                          onClosePopup()
                        }}
                        afterPrint={async () => {
                          if (signature?.doctorSignature && signature?.patientSignature && !treatment?.invoice?.patientInvoiceId) {
                            await onSave('save')
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  {props.type !== 'historyTreatment' && (
                    <Box className='pt-2 pt-md-0 pl-md-3'>
                      {(treatment?.invoice?.doctorSignature && treatment?.invoice?.patientSignature) && (
                        <ButtonCustom
                          className={'btn-discard'}
                          textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CANCEL_DOCUMENT')}
                          onClick={onCancel} />
                      ) || (
                          <ButtonCustom
                            disabled={!signature?.doctorSignature || !signature?.patientSignature}
                            className={'btn-save'}
                            textButton={t('BUTTON.SAVE')}
                            onClick={() => onSave('save')} />
                        )}
                    </Box>
                  )}
                </UseStyled.ModalFooter>
                {pdfLoading && <Loading show={pdfLoading} type='fullLoading' />}
              </>
            ) || (
              <Box className='d-flex align-items-center justify-content-center h-100'>
                <Typography className='text-center'>{props.type === 'historyTreatment' ? t('REGISTER.NO_ITEM') : t('MODAL_TREATMENT_CONSENT.NO_TREATMENT')}</Typography>
              </Box>
            )
          ) || (
              <Loading show={loading} type='softLoading' className='m-auto' />
            )}
        </>
      }
    />
  )
}

function a11yProps(index: number) {
  return {
    id: `doctor-tab-${index}`,
    'aria-controls': `doctor-tabpanel-${index}`,
  };
}