import { useState, useCallback, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

/** API */
import ManageDoctorFeeApi, { FindManageDoctorAllInterface, DoctorFeeInterface } from 'api/counter/manage-doctor-fee.api'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView, isExport } from 'utils/verification.utils'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { resetModal, showModal } from 'app/slice/modal.slice'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import Loading from 'component/Loading'

/** STYLE */
import * as UseStyled from 'features/counter/recall/UseStyled'
import FromDoctorFee from './from-doctor-fee'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
  isExport: isExport()
}

export default function ManageDoctorFee() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [doctorFees, setDoctorFees] = useState<DoctorFeeInterface[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [dataModal, setDataModal] = useState<{ isShow: boolean; data: any }>({ isShow: false, data: {} })

  /** pagination */
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(10)
  const [rowCount, setRowCount] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [rangeDate, setRangeDate] = useState<string[]>([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])

  const loadDoctorFees = useCallback(() => {
    setLoading(true)
    let condition: FindManageDoctorAllInterface = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (rangeDate[0] !== null && rangeDate[0] !== '' && rangeDate[1] !== null && rangeDate[1] !== '') {
      condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    ManageDoctorFeeApi.findAll(condition)
      .then(({ data, headers }) => {
        setDoctorFees(data)
        setRowCount(Number(headers['x-total']))
      })
      .catch(() => setDoctorFees([]))
      .finally(() => setLoading(false))
  }, [search, page, pageLimit, rangeDate])

  useEffect(() => {
    loadDoctorFees()
  }, [loadDoctorFees])

  const headCells = [
    { id: 'id', disablePadding: false, label: t('#'), align: 'center', width: '70px' },
    { id: 'date', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.RECEIPT_DATE') },
    { id: 'paymentNumber', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.RECEIPT_NO') },
    { id: 'branchCnNumber', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.PATIENT_CNCODE') },
    { id: 'patientsFullname', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.PATIENT_NAME') },
    { id: 'by', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.PAYMENT_NAME') },
    { id: 'doctorFullname', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.DOCTOR_ORDERER') },
    { id: 'operativeName', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.OPERATIVE_LIST') },
    { id: 'total', align: 'right', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.OPERATIVE_PRICE') },
    { id: 'cfAmountNet', align: 'right', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.CLINIC_FREE') },
    { id: 'dfAmountNet', align: 'right', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.DF') },
    { id: 'updatedAt', align: 'center', disablePadding: false, label: t('MANAGE_DOCTOR_FEE.TABLE.CELLS.UPDATEDAT') },
    { id: 'action', disablePadding: false, label: t('Action') }
  ]

  const onEdit = (objData: any) => {
    setDataModal({ isShow: true, data: objData })
    dispatch(showModal())
  }

  const renderData = (objData: DoctorFeeInterface, no: number, exportDataValue: boolean) => {
    const { orderTreatmentId, date, paymentNumber, doctorFullname, branchCode, branchCnNumber, patientsFullname, clinicFeeId, operativeName, dfUnit, cfAmount, total, clinicFeePrice, dfAmount, dfAmountNet, updatedBy, updatedAt, createdBy } = objData
    const cf = clinicFeeId ? total - clinicFeePrice : total
    const clinicFree = dfUnit === 'PERCENT' ? cf * (dfAmount / 100) : dfAmount
    no = page * pageLimit - pageLimit + no + 1

    const objRenderData = {
      key: String(Math.random()),
      id: String(orderTreatmentId),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no, class: 'px-1' },
        { option: 'TEXT', align: 'left', label: date ? toBuddhistYear(moment(date)) : '-' },
        { option: 'TEXT', align: 'left', label: paymentNumber },
        { option: 'TEXT', align: 'left', label: `${branchCode}${branchCnNumber}` },
        { option: 'TEXT', align: 'left', label: patientsFullname },
        { option: 'TEXT', align: 'left', label: createdBy },
        { option: 'TEXT', align: 'left', label: doctorFullname },
        { option: 'TEXT', align: 'left', label: operativeName },
        { option: 'TEXT', align: 'right', label: numberFormat(total) },
        { option: 'TEXT', align: 'right', label: numberFormat(clinicFeePrice) },
        { option: 'TEXT', align: 'right', label: numberFormat(dfAmountNet) },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },

        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [{ option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled }]
        }
      ]
    }

    return <TableRowCommon {...objRenderData} onedit={() => onEdit(objData)} />
  }

  const onRequestSort = () => {
    setPage(1)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
    setPage(1)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  return (
    <div className="h-100">
      <Card className={'border-0 h-100 overflow-auto'}>
        <HeaderCard text={t('MANAGE_DOCTOR_FEE.HEADER')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <UseStyled.RowFilter>
              <Grid container spacing={1}>
                <Grid item xs={6} md={4} lg={3} xl={2} className="w-100">
                  <InputTextSearch
                    keyInput={'search'}
                    label={t('MANAGE_DOCTOR_FEE.SEARCH')}
                    value={search}
                    onchange={(event) => {
                      setSearch(event.target.value)
                      setPage(1)
                    }}
                    onClear={() => {
                      setPage(1)
                      setSearch('')
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={3} xl={2} className="w-100">
                  <InputSecondNewRangePicker
                    inputHeight={32}
                    value={rangeDate || []}
                    onchange={(val) => {
                      setPage(1)
                      if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                      else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    }}
                    allowClear
                    onClear={() => {
                      setPage(1)
                      setRangeDate(emptyRangePicker)
                    }}
                  />
                </Grid>
              </Grid>
            </UseStyled.RowFilter>
            <UseStyled.ResponsiveTable className="mt-3">
              <TableCustom
                tableFixedWidth
                tableMinWidth={2500}
                page={page}
                pageLimit={pageLimit}
                sortType={'ASC'}
                sortBy={'updatedAt'}
                rowCount={rowCount}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                headCells={headCells}
                rowsData={_.map(doctorFees, renderData)}
              />
            </UseStyled.ResponsiveTable>
          </Card.Title>
        </Card.Body>

        {dataModal.isShow && (
          <FromDoctorFee
            type="CLINIC_FEE"
            data={dataModal.data}
            onReset={() => {
              setDataModal({
                isShow: false,
                data: []
              })
              dispatch(resetModal())
              loadDoctorFees()
            }}
          />
        )}
      </Card>

      <Loading show={loading} type={'fullLoading'} />
    </div>
  )
}
