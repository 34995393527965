import { Modal, Box, IconButton } from "@mui/material";
import MainButton from "new-components/buttons/main-button";
import { ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close"

interface ConfirmModalProps {
    isOpen: boolean
    handleClose: () => void
    children: ReactNode
    size: 'XS' | 'S' | 'M' | 'L'
    title: string
    btnLeft?: { onClick: () => void, title: string, type?: 'primary' | 'secondary' | 'danger', variant?: 'contained' | 'outlined' }
    onSubmit: { onClick: () => void, title?: string, type?: "primary" | 'danger', disabled?: boolean }
    onCancel: { onClick: () => void, title?: string, disabled?: boolean }
    onCloseCancel?: boolean
    onCloseSubmit?: boolean
    overflow?: boolean
}

export default function PageModal(props: ConfirmModalProps) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: props.size === 'XS' ? 500 : props.size === 'S' ? 800 : props.size === 'M' ? 1140 : 1600,
        bgcolor: '#F7F7F7',
        borderRadius: '10px',
        boxShadow: 24,
        minWidth: 300,
        maxWidth: '95vw',
        maxHeight: '90vh',
        paddingX: '24px',
        overflow: props.overflow ? 'auto' : '',
        paddingY: '24px'

    };

    return (
        <Modal open={props.isOpen} onClose={props.handleClose} sx={{ overflow: 'auto' }}>
            <Box sx={style}>
                <Box display="flex" justifyContent='space-between' alignItems='center'>
                    <Box></Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', color: '#2F4269', paddingLeft: '24px', fontSize: '20px', fontWeight: 600 }}>
                        {props.title}
                    </Box>
                    <Box>
                        <IconButton onClick={props.onCancel?.onClick}>
                            <CloseIcon sx={{ width: '24px', height: '24px', color: '#808080', cursor: 'pointer' }} />
                        </IconButton>
                    </Box>
                </Box>
                {props.children}
                <Box sx={{ display: 'flex', justifyContent: props.btnLeft?.title ? 'space-between' : "end", alignItems: 'center', marginTop: '24px' }}>
                    {props.btnLeft?.title && <MainButton onClick={props.btnLeft?.onClick} title={props.btnLeft?.title || ''} type={props.btnLeft?.type || 'primary'} variant={props.btnLeft?.variant || 'outlined'} style={{ width: 120 }} />}
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                        {!props.onCloseCancel && <MainButton onClick={props.onCancel?.onClick} title={props.onCancel?.title || 'ยกเลิก'} type="secondary" variant="outlined" style={{ width: 120 }} disabled={props.onCancel?.disabled} />}
                        {!props.onCloseSubmit && <MainButton onClick={props.onSubmit?.onClick} title={props.onSubmit?.title || 'บันทึก'} type={props.onSubmit.type || 'primary'} variant="contained" style={{ width: 120 }} disabled={props.onSubmit?.disabled} />}
                    </Box>
                </Box>
            </Box>

        </Modal>
    )
}