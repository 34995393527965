import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReportPdfCustom from 'component/Pdf/ReportPdfCustom'
import { useEffect, useRef } from 'react'
import FucntionPrint from "component/Print/print"
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'

export const DropdownDocument = styled(Dropdown)(({ theme }) => ({
    '.dropdown-toggle': {
        backgroundColor: `${colors.white} !important`,
        color: `${colors.themeSecondColor} !important`,
        border: `1px solid ${colors.themeSecondColor} !important`,
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none !important',
        height: 40,
        '&:after': {
            marginLeft: '0.5rem'
        },
        '&[aria-expanded="true"]:after': {
            transform: 'rotate(180deg)'
        }
    },
    '.dropdown-menu': {
        marginBottom: 4,
        borderRadius: 4,
        background: colors.white,
        border: `1px solid ${colors.lightGray}`,
        minWidth: '130px',
        '.dropdown-item': {
            '&:hover': {
                backgroundColor: colors.themeSecondColor06,
                color: colors.themeSecondColor
            }
        }
    }
}))

type Data = {
    headCells?: any
    rowsData?: any
    filter?: any
    portrait?: boolean | true
    fileName: string,
    headCellsSeconds?: any
    rowsDataSeconds?: any
    filterReport?: any
    marginPrint?: string
    componentExportRef?: any
}

export default function ButtonExport(props: Data) {
    const { t } = useTranslation()
    const componentRef = useRef<HTMLDivElement>(null)

    const { onDownload } = useDownloadExcel({
        tableExportRef: props?.componentExportRef?.current || componentRef.current,
        filename: `${props.fileName}`,
        sheetName: `${props.fileName}`
    });

    return (
        <>
            <DropdownDocument>
                <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                    {t('PERSONNEL.BUTTON.PRINT_DOCUMENT')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>
                        {t('Excel')}
                    </Dropdown.Item>
                    <FucntionPrint
                        content={() => props?.componentExportRef?.current || componentRef.current}
                        docTitle={props.fileName}
                        removeIfram
                        trigger={() => <Dropdown.Item >
                            {t('PDF')}
                        </Dropdown.Item>}
                        style={`@page { size: ${props.portrait ? '793.7007874px 1122.519685px' : '1122.519685px 793.7007874px'}  ;  margin: ${props?.marginPrint || '1cm'}; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />

                </Dropdown.Menu>
            </DropdownDocument>

            <div id="table-to-xls" ref={props?.componentExportRef || componentRef} className="print-show" >
                <ReportPdfCustom
                    headCells={props.headCells}
                    rowsData={props.rowsData}
                    filter={props.filter}
                    headCellsSeconds={props.headCellsSeconds}
                    rowsDataSeconds={props.rowsDataSeconds}
                    filterReport={props.filterReport}
                />
            </div>
        </>
    )
}
