import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import useStyles from 'features/setting/personnel-managements/roles/style'
import { routeName } from 'routes/routes-name'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { icons } from 'constants/images';
import { colors } from 'constants/theme';
import { Box, Typography } from '@mui/material';
import InputCheckbox from 'component/Input/InputCheckbox';
import MemberManagementApi from 'api/setting/member.api';
import { getBranch } from 'utils/app.utils';
import ClinicApi from 'api/master/clinic.api';
import Loading from 'component/Loading';
import { KEY_STORAGE } from 'constants/common';

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
}

export default function MemberManagement() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const [ranks, setRanks] = useState<any>([])
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('NO_SETTING')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)
    const [defaultRankId, setDefaultRankId] = useState<any>(0)

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            const response = await ClinicApi.getAutoIncremet(getBranch());
            const data: any = response.data;
            if (data.autoIncrement) {
                setSortBy(data.autoIncrement);
                setDefaultRankId(data.defaultRankId)
                let condition: any = {};
                if (page) condition = { ...condition, page: page };
                if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
                if (sortBy) condition = { ...condition, sortBy: sortBy };
                if (sortType) condition = { ...condition, sortType: sortType };
                if (search) condition = { ...condition, search: search };
                const res = await MemberManagementApi.findAll(condition);
                if (res.status === 200) {
                    if (data.defaultRankId === null) setDefaultRankId(res.data[0].rankId)
                    setRowCount(res.headers['x-total']);
                    setRanks(res.data);
                    localStorage.setItem(KEY_STORAGE.MEMBER_RANKING_TYPE, JSON.stringify(res.data))
                }
            }
            setLoading(false)
        } catch (error) {
            console.error("Error fetching auto increment data:", error);
        }
    }, [page, pageLimit, sortBy, sortType, search]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const onRequestSort = (sortByVal: any, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'rankCode', disablePadding: false, label: t('MEMBER.TABLE.CELL.MEMBER_ID'), class: 'text-nowrap' },
        { id: 'rankName', disablePadding: false, label: t('MEMBER.TABLE.CELL.MEMBER_NAME'), class: 'text-nowrap' },
        { id: 'color', disablePadding: false, label: t('MEMBER.TABLE.CELL.COLOR'), class: 'text-nowrap' },
        { id: 'auto', disablePadding: false, label: t('MEMBER.TABLE.CELL.AUTO_PROMOTION'), class: 'text-nowrap' },
        { id: 'total', disablePadding: false, label: t('MEMBER.TABLE.CELL.TOTAL_AMOUNT'), class: 'text-nowrap' },
        { id: 'defaults', disablePadding: false, label: t('MEMBER.TABLE.CELL.IS_DEFAULT'), class: 'text-nowrap' },
        { id: 'detail', disablePadding: false, label: t('MEMBER.TABLE.CELL.DETAIL'), class: '' },
        { id: 'updatedBy', disablePadding: false, label: t('MEMBER.TABLE.CELL.UPDATED_BY'), class: 'text-nowrap' }
    ]

    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { rankId, rankCode, rankNameEn, color, totalPaid, serviceVisits, updatedBy, updatedAt, detail } = objData
        const mappings: { [key: string]: string } = {
            'TOTAL_SPENDING': `${totalPaid} ${t('MEMBER.UNIT.BATH')}`,
            'VISIT_COUNT': `${serviceVisits} ${t('MEMBER.UNIT.TIME')}`,
        };
        const objRenderData = {
            key: rankId,
            id: rankId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no, class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: rankCode, class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: rankNameEn, class: 'text-nowrap' },
                {
                    option: 'COMPONENT', align: 'left', class: 'text-nowrap', component: (
                        <Box sx={{ background: `${color}`, height: '40px', width: '200px', border: '1px solid #C5C5C5' }} />
                    )
                },
                { option: 'TEXT', align: 'left', class: 'text-nowrap', label: t(`MEMBER.AUTO_PROMOTION.${sortBy}`) },
                { option: 'TEXT', align: 'right', class: 'text-nowrap', label: mappings[sortBy] || '-' },
                {
                    option: 'COMPONENT', class: 'text-nowrap', align: 'center', component: (
                        <Box>
                            <InputCheckbox disabled checked={defaultRankId === rankId} />
                        </Box>
                    )
                },
                {
                    option: 'COMPONENT', class: '', component: (
                        <Typography sx={{ textOverflow: 'ellipsis', width: '300px' }}>{detail}</Typography>
                    )
                },
                // { option: 'TEXT', class: '', label: detail || '-', style: { width: '200px' } },
                { option: 'UPDATE_BY', align: 'center', class: 'text-nowrap', label: { updatedBy: updatedBy, updatedAt: updatedAt } },

            ]
        }
        return (
            <TableRowCommon
                {...objRenderData}
            />
        )
    }

    return (
        <div className={classes.root}>
            <Card className="border-0 h-100">
                <HeaderCard text={t('MEMBER.TITLE')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Row className="flax-row">
                                    <Col sm={12} md={6} xl={4} >
                                        <Box paddingBottom={'10px'}>
                                            <InputTextSearch
                                                keyInput={'search'}
                                                label={t('INPUT.SEARCH')}
                                                value={search}
                                                onchange={(event) => {
                                                    setSearch(event.target.value)
                                                    setPage(1)
                                                }}
                                                onClear={(event) => {
                                                    setSearch('')
                                                    setPage(1)
                                                }}
                                            />
                                        </Box>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} className="mt-2 mt-md-auto">
                                <Box paddingBottom={'10px'}>
                                    <ButtonCustom
                                        startIcon={<img src={icons.iconFeatherEdit} alt=""
                                            style={{ width: 18, color: colors.white }} />}
                                        disabled={permissions.isCreate.disabled}
                                        onClick={() => history.push(routeName.memberManagement + '/update', { autoIncrement: sortBy, defaultRankId })}
                                        textButton={t('MEMBER.BUTTON.EDIT')}
                                        className="w-auto ml-auto d-flex mt-auto"
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2'}>
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={(limit) => setPageLimit(limit)}
                            setPage={(val) => setPage(val)}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={ranks.map((val: any, i: number) => {
                                return renderData(val, i)
                            })}
                        />
                    </Box>
                </Card.Body>
            </Card>{loading && <Loading show={loading} type="fullLoading" />}
        </div>
    )
}
