import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { MenuItem, IconButton } from '@mui/material'
import { useSelector } from 'react-redux'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import Loading from 'component/Loading'

/** CONSTANTS */
import { Icons } from 'constants/images'
import { colors } from 'constants/theme'
import { routeName } from 'routes/routes-name'
import { selectMe } from 'app/slice/user.slice'

/** API */
import ReportApi, { REPORT_GROUP, FindAllReportInterface } from 'api/report.api'
import ComponentApi, { Bookmark } from 'api/components-api'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import VisibilityIcon from '@mui/icons-material/Visibility'

export default function Report() {
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [reportGroup, setReportGroup] = useState<REPORT_GROUP | string>('ALL')

  const [reports, setReports] = useState([])

  const loadReport = useCallback(() => {
    const condition: FindAllReportInterface = {
      page,
      pageLimit,
      sortType,
      sortBy,
      search,
      reportGroup: reportGroup === 'ALL' ? '' : reportGroup
    }

    ReportApi.reportList(condition)
      .then(({ data, headers }) => {
        setReports(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [page, pageLimit, sortType, sortBy, search, reportGroup])

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortBy(sortByVal)
    setSortType(sortTypeVal)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setLoading(true)
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    if (page !== val) {
      setLoading(true)
      setPage(val)
    }
  }

  const handleChangeReportGroup = (group: string) => {
    setLoading(true)
    setReportGroup(group)
    setPage(1)
  }

  const handleSearch = (val: string) => {
    setSearch(val)
    setPage(1)
  }

  const handleBookmark = async (itemId: number, bookmarkId: number) => {
    setLoading(true)
    const payload: Bookmark = {
      bookmarkType: 'REPORT',
      userId: user.userId,
      itemId: itemId,
      isBookmark: bookmarkId ? '0' : '1'
    }
    ComponentApi.bookmark(payload)
      .then((_) => loadReport())
      .catch(() => setLoading(false))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    loadReport()
  }, [loadReport])

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '100px' },
    { id: 'message', disablePadding: false, label: t('REPORT.TABLE.CODE'), width: '100px' },
    { id: 'message', disablePadding: false, label: t('REPORT.TABLE.TYPE'), width: '20%' },
    { id: 'message', disablePadding: false, label: t('REPORT.TABLE.NAME') },
    { id: 'action', disablePadding: false, label: 'Action', width: '110px' }
  ]

  const renderData = (objData: any, no: number) => {
    const { reportId, reportGroup: reportGroupName, reportUri, reportName, reportNameEn, isBookmark, reportCode, reportKey } = objData

    return (
      <TableRowCommon
        key={reportId.toString()}
        id={reportId.toString()}
        obj={objData}
        columns={[
          { option: 'TEXT', align: 'center', label: page * pageLimit - pageLimit + no + 1, class: 'cursor-pointer' },
          { option: 'TEXT', align: 'left', label: reportCode || '', class: 'text-ellipsis cursor-pointer' },
          { option: 'TEXT', align: 'left', label: t(`REPORT.REPORT_GROUP.${reportGroupName}`) || '-', class: 'text-ellipsis cursor-pointer' },
          { option: 'TEXT', align: 'left', label: t(`REPORT.REPORT_NAME.${reportKey}`) || reportName || reportNameEn || '-', class: 'text-ellipsis cursor-pointer' },
          {
            option: 'COMPONENT',
            align: 'center',
            component: (
              <>
                <IconButton
                  onClick={() => {
                    window.open(`${routeName.report}/${reportUri}`, '_blank')
                  }}
                  className={`p-0 mr-1 align-middle`}
                  sx={{ backgroundColor: 'transparent !important' }}
                >
                  <VisibilityIcon sx={{ fontSize: 23, color: colors.themeSecondColor }} />
                </IconButton>
                <IconButton onClick={() => handleBookmark(reportId, isBookmark)} className={`p-0 ml-2 align-middle`} sx={{ backgroundColor: 'transparent !important' }}>
                  {isBookmark ? <Icons.StarActive width="20" height="20" /> : <Icons.StarInActive width="20" height="20" />}
                </IconButton>
              </>
            )
          }
        ]}
        onClick={() => {
          window.open(`${routeName.report}/${reportUri}`, '_blank')
        }}
      />
    )
  }
  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t('REPORT.HEADER')} />
      <Card.Body>
        <Card.Title className={'mb-0 pl-xl-3'}>
          <Row>
            <Col sm={12} md={6} lg={5} xl={4} xxl={3} className={'pt-2'}>
              <InputTextSearch keyInput={'search'} label={t('REPORT.SEARCH')} value={search} onchange={({ target }) => handleSearch(target.value)} onClear={(_) => handleSearch('')} />
            </Col>
            <Col sm={12} md={4} lg={3} xxl={2} className={'pl-md-0 pt-2'}>
              <FilterSelect
                onchange={({ target }) => {
                  if (target.value) handleChangeReportGroup(target.value)
                }}
                renderValue={() => `${t('REPORT.FILTER.REPORT_GROUP')}: ${t(`REPORT.REPORT_GROUP.${reportGroup}`)}`}
                label={t('REPORT.FILTER.REPORT_GROUP')}
                selectId="select-status"
                value={reportGroup}
                labelId="label-status"
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  _.map(REPORT_GROUP, (group, index) => {
                    return (
                      <MenuItem key={index} value={group}>
                        {t(`REPORT.REPORT_GROUP.${group}`)}
                      </MenuItem>
                    )
                  })
                ]}
                classesOption="style-select-doctors"
              />
            </Col>
          </Row>
        </Card.Title>

        <UseStyled.ResponsiveReportTable className={'mt-3 pl-xl-3 pr-xl-2'}>
          <TableCustom
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            onSort={onRequestSort}
            setPageLimit={handleChangeRowsPerPage}
            setPage={handleChangePage}
            headCells={headCells}
            rowsData={_.map([...reports], (report, index) => {
              return renderData(report, index)
            })}
            tableFixedWidth
            tableMinWidth={960}
          />
        </UseStyled.ResponsiveReportTable>
      </Card.Body>
      <Loading show={loading} type="fullLoading" />
    </Card>
  )
}
