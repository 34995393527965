import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import { useSelector } from 'react-redux'

import ComponentApi, { Bookmark } from 'api/components-api'
import { styled } from '@mui/material/styles'
import { Box, Button, Collapse, Divider, IconButton, TableRow, TableCell, Typography } from '@mui/material'
import useWindowSize from 'utils/useWindowSize'
import { colors } from 'constants/theme'
import { Icons, icons } from 'constants/images'
import { selectMe } from 'app/slice/user.slice'
import TreatmentApi, { PlayLoadTreatmentCourse, PlayLoadTreatmentPlans } from 'api/dentists/treatment.api'
import TableCustom from 'component/Table/TableCustom'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import { swalActive } from 'component/Alert/Swal'
import { getBranch } from 'utils/app.utils'
import Loading from 'component/Loading'
import * as popupTx from 'component/Treatments/style/popupTx'
import { CloseButton, Col, Row } from 'react-bootstrap'
import { IconBookmark } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputTextSearch from 'component/Input/InputTextSearch'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ButtonCustom from 'component/Button/ButtonCustom'
import RowTxP from 'component/Treatments/PopupCreateTemplate/RowTxP'
import RowTxC from 'component/Treatments/PopupCreateTemplate/RowTxC'
import RowSummaryTXC from 'component/Treatments/PopupCreateTemplate/RowSummaryTxC'
import PopupPaymentChanel from './PopupPaymentChanel'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'

export const TableWidth = styled(Box)(({ theme }) => ({
    height: '100%',
    '.MuiTableContainer-root': {
        height: '100%',
    },
    '.MuiTable-root': {
        minWidth: 1360,
        '.MuiTableHead-root': {
            '.MuiTableRow-head': {
                position: 'sticky',
                top: 0,
                zIndex: 10,
                '.MuiTableCell-head': {
                    padding: 8
                },
            }
        },
        '.MuiTableBody-root': {
            '.MuiTableCell-body': {
                padding: `8px !important`,
                verticalAlign: 'middle',
                '&.add-new-list': {
                    '&.disabled': {
                        button: {
                            color: colors.disabledGray
                        }
                    }
                }
            }
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: 'unset',
            maxWidth: '100%',
            '.MuiTableBody-root': {
                '.MuiTableCell-body': {
                    verticalAlign: 'top',
                    '&:first-of-type:not(.add-new-list), &:last-of-type:not(.add-new-list)': {
                        paddingTop: `18px !important`
                    }
                }
            },
        }
    }
}))
export const TableWidthSummary = styled(Box)(({ theme }) => ({
    height: '100%',
    '.MuiTableContainer-root': {
        height: '100%',
    },
    '.MuiTable-root': {
        minWidth: 1360,
        '.MuiTableHead-root': {
            '.MuiTableRow-head': {
                position: 'sticky',
                top: 0,
                zIndex: 10,
                '.MuiTableCell-head': {
                    backgroundColor: "#D2F0EB",
                    color: '#000000 !important',
                    padding: 8
                },
            }
        },
        '.MuiTableBody-root': {
            '.MuiTableCell-body': {
                padding: `8px !important`,
                verticalAlign: 'middle',
                '&.add-new-list': {
                    '&.disabled': {
                        button: {
                            color: colors.disabledGray
                        }
                    }
                }
            }
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: 'unset',
            maxWidth: '100%',
            '.MuiTableBody-root': {
                '.MuiTableCell-body': {
                    verticalAlign: 'top',
                    '&:first-of-type:not(.add-new-list), &:last-of-type:not(.add-new-list)': {
                        paddingTop: `18px !important`
                    }
                }
            },
        }
    }
}))
export const ButtonAddRecord = styled(Button)(({ theme }) => ({
    padding: '0 0 0 24px',
    fontSize: 16,
    color: colors.themeSecondColor,
    backgroundColor: 'transparent !important',
    fontWeight: 400,
    span: {
        fontSize: 20
    },
    '&:hover': {
        fontWeight: 600
    }
}))

export const NavBtn = styled(Box)(({ theme }) => ({
    margin: '0 -0.25rem',
    overflowY: 'auto',
    '> .MuiBox-root': {
        width: '14.28%',
        [theme.breakpoints.down('md')]: {
            width: '16.667%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '33.33%'
        }
    }
}))

const CardInfo = styled('div')(({ theme }) => ({
    border: `1px solid ${colors.lightGray}`,
    color: colors.textPrimary,
    fontSize: 16,
    padding: '0.625rem 1.5rem',
    borderRadius: 4,
    '.expand-more': {
        backgroundColor: colors.white,
        border: `1px solid ${colors.textLightGray}`,
        color: colors.themeMainColor,
        borderRadius: 100,
        fontSize: 22
    }
}))

const Detail = styled('div')(({ theme }) => ({
    fontSize: 16,
    '.Mui-disabled .checkbox.isChecked::before': {
        backgroundColor: `${colors.disabledGray} !important`,
    }
}))

const CustomCollapse = styled(Collapse)(({ theme }) => ({
    '.collapse-wrap': {
        margin: '0.625rem -0.5rem 0',
        padding: '0.5rem 0.5rem 0.25rem',
        borderTop: `1px solid ${colors.lightGray}`
    }
}))

export const ButtonType = styled(Button)(({ theme }) => ({
    width: '100%',
    height: 30,
    color: colors.textPrimary,
    borderColor: `${colors.themeSecondColor} !important`,
    '&.active': {
        backgroundColor: colors.themeSecondColor,
        color: colors.white
    }
}))

export const TableWidthTemplate = styled(Box)(({ theme }) => ({
    '.MuiTable-root': {
        tableLayout: 'fixed',
        minWidth: '100%',
        '.MuiTableBody-root': {
            borderTop: `1px solid ${colors.lightGray} !important`,
            '.MuiTableCell-body': {
                padding: '8px',
                verticalAlign: 'middle',
                '&:first-of-type': {
                    paddingLeft: 4,
                    paddingRight: 4
                }
            }
        },
    },
    '.MuiTableFooter-root': {
        display: 'none'
    }
}))
export interface PopupCreateTemplate {
    header?: any
    title?: string
    txp?: boolean
    doctors?: any
    patientCaseId?: any
    patientId?: any
    edit?: any
    isShow?: any
    showTemplate?: boolean | false
    operativeTypes?: any
    onSubmit?: (val: 'true' | '') => void
    setIsShow: (show: boolean) => void
    operativeType?: any
    opertatives?: any
}

interface IFTreatmentListCreate {
    itemTypes: [{
        itemTypeId: number
        itemTypeName: string
    }]
    items: [{
        group: string
        itemCode: string
        itemId: number
        itemName: string
        itemNameEn: string
        itemTypeId: number
        itemTypeName: string
        itemTypeNameEn: string
        itemUnitName?: string
        itemUnitNameEn?: string
        price: number
    }]
    disabled: boolean
    refId?: number
    id?: any
    price?: any,
    dateUse?: any,
    doctorId?: number,
    duration?: any,
    durationUnit?: string
    status?: string
    type?: any,
    itemId: any,
    itemTypeId: any,
    qty?: any,
    qtyNew?: any,
    itemCode?: any,
    balance?: number
    useNew?: any,
    balanceNew?: any,
    totalQtyNow?: number
    oldQty?: number
    remark?: string
    teeth?: string
    teethJson?: string
    teethType?: string
    itemSort?: number
}

export interface IFDoctors {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

const initStateErrorMessage = {
    NAME: '',
}

export const TIME_DURATION_TYPE = [
    { id: 1, value: "YEARS", name: "ปี" },
    { id: 2, value: "MONTH", name: "เดือน" },
    { id: 3, value: "DAYS", name: "วัน" },
    { id: 4, value: "HOURS", name: "ชั่วโมง" },
    { id: 5, value: "MINUTES", name: "นาที" }
]

export const STATUS_TREATMENT_PLAN_ITEM = [
    { id: 1, value: 'PENDING', name: 'TX_INFORMATION.STATUS.PENDING' },
    // { id: 2, value: 'IN_PROGRESS', name: 'TX_INFORMATION.STATUS.IN_PROGRESS' },
    { id: 3, value: 'DONE', name: 'TX_INFORMATION.STATUS.DONE' },
]

export default function PopupCreateByUsers(props: PopupCreateTemplate) {
    const { t } = useTranslation()
    const branchId = getBranch()
    const [userData]: any = useState({});
    const [name, setName] = useState('')
    const [remark, setRemark] = useState('')
    const [treatmentListCreate, setTreatmentListCreate] = useState<IFTreatmentListCreate[]>([])
    const [treatmentItems, setTreatmentItems]: any = useState([])
    const [treatmentItemTypes, setTreatmentItemTypes]: any = useState([])
    const [doctors, setDoctors] = useState<IFDoctors[]>([])
    const [width] = useWindowSize()
    const [errorMessage, setErrorMessage] = useState({ ...initStateErrorMessage })
    const [loading, setLoading] = useState(false)
    const [template, setTemplate] = useState(props.showTemplate)
    const [btn, setBtn] = useState(false)
    const [userTemplate, setUserTemplate] = useState<any>('')
    const [totalQtyNow, setTotalQtyNow] = useState(props.edit.totalQty)

    const [paymentChanel, setPaymentChannel] = useState<any>({})
    const [countTXC, setCountTXC] = useState<number>(0)
    const [sumTXC, setSumTXC] = useState<number>(0)
    const [popPayChanel, setPopPayChanel] = useState<boolean>(false)
    const [editPayChanelCollapse, setEditPayChanelCollapse] = useState<boolean>(false)

    const [isDraging, setIsDraging] = useState<boolean>(false)
    const [isHegihtTableDrag, setIsHegihtTableDrag] = useState<string>('100%')
    const droppableId = 'droppable-1'

    const [errorTxp, setErrorTxp] = useState<any>([])

    const loadTreatmentItemType = useCallback(async (search?: string) => {
        let condition: any = {}
        condition = { ...condition, page: 1 }
        condition = { ...condition, pageLimit: 100 }
        if (search) condition = { ...condition, search: search }
        if (props.txp === true) condition = { ...condition, group: "OPERATIVE" }
        if (userData?.userId) condition = { ...condition, userId: userData?.userId }
        await TreatmentApi.getItemsType(condition).then((res) => {
            if (res.status === 200) setTreatmentItemTypes(res.data)
        })
    }, [])

    const loadTreatmentItem = useCallback(async (group?: string, typeId?: number, search?: string, callback?: any) => {
        let condition: any = {}
        condition = { ...condition, userId: 0 }
        condition = { ...condition, page: 1 }
        condition = { ...condition, pageLimit: 100 }
        condition = { ...condition, group }
        condition = { ...condition, branchId: branchId }
        if (search) condition = { ...condition, search: search }
        if (typeId) condition = { ...condition, typeId: typeId }
        if (userData?.userId) condition = { ...condition, userId: userData?.userId }
        await TreatmentApi.getItems(condition).then((res) => {
            if (res.status === 200) {
                setTreatmentItems(res.data)
                if (callback) callback(res.data)
            }
        })
    }, [])
    useEffect(() => {
        setTotalQtyNow(props.edit.totalQty)
    }, [props.edit.totalQty])
    useEffect(() => {
        loadTreatmentItem()
        if (props.onSubmit) props.onSubmit('')
    }, [loadTreatmentItem])

    useEffect(() => {
        loadTreatmentItemType()
    }, [loadTreatmentItemType])

    useEffect(() => {
        setDoctors(props.doctors)
    }, [props.doctors])

    useEffect(() => {
        setTemplate(props.showTemplate)
    }, [props.showTemplate])

    useEffect(() => {
        if (props.edit) {
            setName(props.txp ? props.edit.treatmentPlanName : props.edit.treatmentCourseName)
            setRemark(props.edit.remark)
            if (props.txp === true) {
                props.edit.patientTreatmentPlanItems.forEach((x: any, index: number) => {
                    setTreatmentListCreate((newTreatmentListCreate) => [...newTreatmentListCreate,
                    {
                        disabled: true,
                        itemTypes: [{
                            itemTypeId: x.operativeTypeId,
                            itemTypeName: x.operativeTypeName
                        }],
                        items: [
                            {
                                group: 'OPERATIVE',
                                itemCode: x.operativeCode,
                                itemId: x.operativeId,
                                itemName: x.operativeName,
                                itemNameEn: x.operativeNameEn,
                                itemTypeId: x.operativeTypeId,
                                itemTypeName: x.operativeTypeName,
                                itemTypeNameEn: x.operativeTypeNameEn,
                                price: x.price,

                            }
                        ],
                        itemId: x.operativeId,
                        itemTypeId: x.operativeTypeId,
                        id: x.id,
                        type: 'OPERATIVE',
                        itemCode: x.operativeCode,
                        price: x.price,
                        dateUse: x.dateUse,
                        doctorId: x.doctorId,
                        duration: x.duration,
                        durationUnit: x.durationUnit,
                        status: x.status,
                        teeth: x.teeth || "",
                        teethJson: x.teethJson || "",
                        teethType: x.teethType || "",
                        remark: x.remark

                    }])
                })
            } else {
                props.edit.patientTreatmentCourseItems.forEach((x: any, index: number) => {
                    setTreatmentListCreate((newTreatmentListCreate) => [...newTreatmentListCreate, {
                        disabled: true,
                        itemTypes: [{
                            itemTypeId: x.itemTypeId,
                            itemTypeName: x.itemTypeName
                        }], items: [
                            {
                                group: x.type,
                                itemCode: x.itemCode,
                                itemId: x.itemId,
                                itemName: x.itemName,
                                itemNameEn: x.itemNameEn,
                                itemTypeId: x.itemTypeId,
                                itemTypeName: x.itemTypeName,
                                itemTypeNameEn: x.itemTypeNameEn,
                                itemUnitName: x.itemUnitName,
                                itemUnitNameEn: x.itemUnitNameEn,
                                price: x.price,
                            }
                        ],
                        itemId: x.itemId,
                        itemTypeId: x.itemTypeId,
                        id: x.id, type: x.type, itemCode: x.itemCode, price: x.price, qty: x.qty, balance: x.balance,
                        qtyNow: x.qty,
                        qtyNew: x.qty,
                        useNew: x.qty - x.balance,
                        balanceNew: x.balance,
                        priceNew: x.price,
                        itemSort: x?.itemSort || 0
                    }])
                })

                const newPaymentChanel = {
                    paymentDetail: props.edit?.paymentDetail || '[]',
                    paymentType: props.edit?.paymentType || ''
                }
                setPaymentChannel(newPaymentChanel)
            }
        }
    }, [props.edit])

    const closePopup = () => {
        setTreatmentListCreate([])
        setName('')
        setRemark('')
        if (props.setIsShow) props.setIsShow(false)
    }

    const clearErrorMessage = () => setErrorMessage(initStateErrorMessage)

    const changeTemplate = () => {
        setUserTemplate('')
        setTreatmentListCreate([])
        setTemplate(true)
        setBtn(false)
        setName('')
    }

    const handleUseTemplate = () => {
        setTemplate(false)
        setBtn(true)
        if (props.txp === true) {
            if (_.size(userTemplate) === 1) setName(userTemplate[0]?.treatmentPlanName || '')
            userTemplate.forEach((val: any, i: number) => {
                val.treatmentPlanItems.forEach((x: any, j: number) => {
                    setTreatmentListCreate((newTreatmentListCreate) =>
                        [
                            ...newTreatmentListCreate,
                            {
                                disabled: true,
                                itemTypes: [{
                                    itemTypeId: x.itemTypeId,
                                    itemTypeName: x.itemTypeName
                                }],
                                items: [x],
                                itemId: x.itemId,
                                itemTypeId: x.itemTypeId,
                                refId: newTreatmentListCreate.length,
                                id: null,
                                itemCode: x.itemCode,
                                price: x.price,
                                dateUse: null,
                                doctorId: 0,
                                duration: null,
                                durationUnit: 'MINUTES',
                                status: 'PENDING',
                                teeth: x.teeth || "",
                                teethJson: x.teethJson || "",
                                teethType: x.teethType || "",
                                remark: x.remark || ''
                            }
                        ])
                })
            })
        } else {
            if (_.size(userTemplate) === 1) setName(userTemplate[0]?.treatmentCourseName || '')
            userTemplate.forEach((userTemp: any) => {
                userTemp.treatmentCourseItems.forEach((courseItem: any) => {
                    setTreatmentListCreate((newTreatmentListCreate) => [...newTreatmentListCreate, {
                        disabled: true,
                        itemTypes: [{
                            itemTypeId: courseItem.itemTypeId,
                            itemTypeName: courseItem.itemTypeName
                        }],
                        items: [courseItem],
                        itemId: courseItem.itemId,
                        itemTypeId: courseItem.itemTypeId,
                        refId: newTreatmentListCreate.length,
                        id: null,
                        type: courseItem.type,
                        itemCode: courseItem.itemCode,
                        price: courseItem.price,
                        qty: courseItem.qty,
                        itemSort: courseItem?.itemSort || 0,
                    }])
                })
            })

            const newPaymentChanel = {
                paymentDetail: userTemplate[0]?.paymentDetail || '[]',
                paymentType: userTemplate[0]?.paymentType || ''
            }
            setPaymentChannel(newPaymentChanel)
        }
    }
    const transformTreatmentList = () => {
        const newTreatmentItems: any = []
        _.map(treatmentListCreate, (item: IFTreatmentListCreate, indexDoctor: number) => {
            const { id, itemId, price, dateUse, doctorId, duration, durationUnit, status, type, qty, balance, remark, teeth, teethJson, teethType, itemSort } = item
            const idBuffer = props?.edit ? id : null
            const balanceBuffer = id !== null ? balance : qty
            if (props.txp) {
                newTreatmentItems.push({
                    id: idBuffer,
                    operativeId: itemId,
                    price: price,
                    dateUse: dateUse,
                    doctorId: doctorId,
                    duration: duration,
                    durationUnit: durationUnit,
                    status: status,
                    remark: remark,
                    teeth: teeth || "",
                    teethJson: teethJson || "",
                    teethType: teethType || "",

                })
            } else {
                newTreatmentItems.push({
                    id: idBuffer,
                    type: type || "OPERATIVE",
                    price: price,
                    itemId: itemId,
                    qty: qty,
                    balance: balanceBuffer,
                    itemSort: itemSort || 0
                })
            }
        })
        return newTreatmentItems
    }

    const chooseAPI = (payload: any, payloadType: any) => {
        if (props.txp) {
            return TreatmentApi.setPatientTreatmentPlans(payload, payloadType)
        } else {
            return TreatmentApi.setPatientTreatmentCourses(payload, payloadType)
        }
    }

    const submitPopup = async () => {
        setLoading(true)
        const msgName = (props.txp ? t(`TXP.WARING`) : t(`TXC.WARING`))
        const checkItemId = treatmentListCreate?.some((item) => !item?.itemId)
        const checkItemTypeId = treatmentListCreate?.some((item) => !item?.itemTypeId)

        if (!name || checkItemId || checkItemTypeId) {
            if (!name) {
                const msg: any = {
                    NAME: msgName,
                }
                // setLoading(false)
                setErrorMessage({ ...initStateErrorMessage, ...msg })
                // return false
            }

            if (checkItemId || checkItemTypeId) {
                const newError: any = []

                treatmentListCreate.forEach((item, index: number) => {
                    if (!item?.itemId) {
                        newError[index] = { ...newError[index], ['itemId']: t('TXP.MESSAGE.ERROR_DETAIL_OPERATIVE') }
                    } else {
                        newError[index] = { ...newError[index], ['itemId']: '' }
                    }
                    if (!item?.itemTypeId) {
                        newError[index] = { ...newError[index], ['itemTypeId']: t('TXP.MESSAGE.ERROR_TXC_TYPE') }
                    } else {
                        newError[index] = { ...newError[index], ['itemTypeId']: '' }
                    }
                })
                setErrorTxp(newError)

            }
            return setLoading(false)
        }

        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TXP.MESSAGE.CONTINUE_TITLE')}</p>`,
            `<p class="text-center mb-0 mx-auto" style="max-width: 306px">${t('TXP.MESSAGE.CONTINUE_SUB')}</p>`,
            (res: any) => {
                if (res) {
                    const newTreatmentItems: any = transformTreatmentList()

                    const { patientTreatmentPlanId, patientTreatmentCourseId, patientId } = props.edit
                    const payloadTxp: PlayLoadTreatmentPlans = {
                        patientTreatmentPlanId: patientTreatmentPlanId || null,
                        treatmentPlanName: name,
                        userId: userData?.userId,
                        remark: remark,
                        treatmentPlanItems: newTreatmentItems
                    }

                    const payloadTxc: PlayLoadTreatmentCourse = {
                        patientTreatmentCourseId: patientTreatmentCourseId || null,
                        treatmentCourseName: name,
                        userId: userData?.userId,
                        remark: remark,
                        treatmentCourseItems: newTreatmentItems,
                        patientCaseId: props?.edit?.patientCaseId || null,
                        // paymentDetail: newPaymentChanel?.paymentDetail || '[]',
                        // paymentType: newPaymentChanel?.paymentType || '',
                    }

                    const payload = patientId || props.patientId || null
                    const payloadType = props.txp ? payloadTxp : payloadTxc
                    setLoading(false)
                    chooseAPI(payload, payloadType).then((result) => {
                        setTreatmentListCreate([])
                        setName('')
                        setRemark('')
                        const submit = props.onSubmit ? props.onSubmit('true') : ''
                        setLoading(false)
                        props.setIsShow(false)
                        return submit
                    })

                } else {
                    setLoading(false)
                }
            },
            undefined,
            t('BUTTON.CONTINUE'),
            t('BUTTON.CANCEL')

        )
    }

    const checkSubmitButton = () => {
        if (template) {
            return handleUseTemplate
        } else {
            return submitPopup
        }
    }
    const headCellsTxpLarge = [
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: '4%' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.TOOTH'), width: '200px', },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.TYPE'), width: '200px', },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DETAIL_OPERATIVE'), width: '200px', },
        { id: 'message', disablePadding: false, align: 'center', label: t('TXP.TABLE.CODE'), width: '7%' },
        { id: 'message', disablePadding: false, align: 'right', label: t('TXP.TABLE.PRICE'), width: '100px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DATE') },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DOCTOR') },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.TIME'), width: '14%' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.STATUS') },
        { id: 'message', disablePadding: false, align: 'center', label: 'Action', width: '6%' }
    ]
    const headCellsTxpSmall = [
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: '50px' },
        { id: 'message', disablePadding: false, align: 'left', label: '' },
        { id: 'message', disablePadding: false, align: 'center', label: 'Action', width: '90px' }
    ]

    const headCellsTxp = width >= 1200 ? headCellsTxpLarge : headCellsTxpSmall


    const headCellsTxcLarge = [
        { id: 'drag', disablePadding: false, label: t(``), width: '40px' },
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: '4%' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.TYPE') },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DETAIL') },
        { id: 'sort', disablePadding: false, label: t('TREATMENT_COURSE.FORM.SUMMARY.TABLE.ORDER'), align: 'center', width: '150px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.CODE'), width: '10%' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.ALL_TOTAL') },
        { id: 'message', disablePadding: false, align: 'right', label: t('TXC.TABLE.PRICE') },
        { id: 'message', disablePadding: false, align: 'right', label: t('TXC.TABLE.TOTAL') },
        { id: 'message', disablePadding: false, align: 'center', label: 'Action', width: '6%' }
    ]

    const headCellsTxcSmall = [
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: '50px' },
        { id: 'message', disablePadding: false, align: 'left', label: '' },
        { id: 'message', disablePadding: false, align: 'center', label: 'Action', width: '90px' }
    ]

    const headCellsTxcEdit = [
        { id: 'drag', disablePadding: false, label: t(``), width: '40px' },
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: '48px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.TYPE'), width: '200px', },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DETAIL'), width: '200px' },
        { id: 'sort', disablePadding: false, label: t('TREATMENT_COURSE.FORM.SUMMARY.TABLE.ORDER'), align: 'center', width: '150px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.CODE'), width: '120px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.ALL_TOTAL_NOW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.ALL_TOTAL_NEW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.USE_AMOUNT_NOW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.USE_AMOUNT_NEW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.REMAINING_AMOUNT_NOW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('TXC.TABLE.REMAINING_AMOUNT_NEW'), width: '180px' },
        { id: 'message', disablePadding: false, align: 'right', label: t('TXC.TABLE.PRICE_NOW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'right', label: t('TXC.TABLE.PRICE_NEW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.TOTAL_NOW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.TOTAL_NEW'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TXC.TABLE.TOTAL_DIF'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: 'Action', width: '72px' }
    ]
    const generateHeadCellsTxc = (width: any, isEdit: any) => {
        if (width >= 1200) {
            return isEdit ? headCellsTxcEdit : headCellsTxcLarge;
        } else {
            return headCellsTxcSmall;
        }
    };
    const headCellsTxc = generateHeadCellsTxc(width, props.edit);


    const headSumarizeLarge = [
        { id: 'message', disablePadding: false, align: 'center', label: '', width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: '', width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: '', width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TXP.TABLE.TOTAL'), width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: '', width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: '', width: '170px' },
        { id: 'message', disablePadding: false, align: 'center', label: '', width: '170px' },
    ]

    const headSumarizeSmall = [
        { id: 'message', disablePadding: false, align: 'center', label: t('TXP.TABLE.TOTAL'), width: '100%' }
    ]
    const headSumarize = width >= 1200 ? headSumarizeLarge : headSumarizeSmall

    const txpHeadCellsCheck = () => {
        if (props.txp) {
            return headCellsTxp
        } else {
            return headCellsTxc
        }
    }

    const valCheck = (val: any) => {
        if (val?.target?.value === '') {
            return 0
        } else {
            return String(val?.target?.value || val).replace(/[^0-9.]/g, '')
        }
    }

    const renderData = (objData: any, no: number) => {
        const { disabled, id, price, dateUse, doctorId, duration, durationUnit, status, qty,
            type, itemId, itemCode, itemTypes, items, itemTypeId, balance, use, remark,
            teeth, teethJson, teethType, itemSort } = objData

        const newIndex = no
        if (props.txp) {
            return (
                <RowTxP
                    patientId={props.patientId || 0}
                    disabled={disabled}
                    no={no}
                    id={id}
                    itemTypes={itemTypes}
                    items={items}
                    itemId={itemId}
                    itemTypeId={itemTypeId}
                    itemCode={itemCode}
                    price={price}
                    dateUse={dateUse}
                    doctorId={doctorId}
                    doctors={doctors}
                    duration={duration}
                    durationUnit={durationUnit}
                    status={status}
                    headCell={txpHeadCellsCheck()}
                    handleChangeItemType={(e: any, value: any) => {
                        setErrorTxp([])
                        loadTreatmentItem(value?.group, value?.itemTypeId, '', function (callbackTreatmentItems: any) {
                            const itemsList: any = _.merge([], treatmentListCreate)
                            let item: any = _.merge({}, itemsList[no])
                            item = { ...item, itemTypeId: value?.itemTypeId, type: value?.group, items: callbackTreatmentItems }
                            itemsList[no] = item
                            setTreatmentListCreate(itemsList)
                        })
                    }}
                    handleChangeItem={(e: any, value: any) => {
                        setErrorTxp([])
                        const itemsList: any = _.merge([], treatmentListCreate)
                        const newPrice = treatmentListCreate[no]?.items?.find((info: any) => info.itemId === value.itemId)
                        let item: any = _.merge({}, itemsList[no])
                        item = {
                            ...item,
                            ...value,
                            price: newPrice?.price || value?.price,
                            disabled: true,
                            qty: 0,
                            items: [value],
                            itemTypes: [{
                                itemTypeId: value.itemTypeId,
                                itemTypeName: value.itemTypeName
                            }]
                        }
                        itemsList[no] = item
                        setTreatmentListCreate(itemsList)
                    }}
                    handleChangePrice={(e) => {
                        setErrorTxp([])
                        const valPrice = valCheck(e)
                        const price = Number(valPrice)
                        treatmentListCreate[no] = { ...objData, price: price }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleChangeDate={(date) => {
                        setErrorTxp([])
                        let dateUse: any = ''
                        dateUse = moment(date).format('YYYY-MM-DD')
                        treatmentListCreate[no] = { ...objData, dateUse: dateUse }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleChangeDoctor={(e) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, doctorId: e.target.value }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleChangeDuration={(e) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, duration: e.target.value }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleChangeDurationUnit={(e) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, durationUnit: e.target.value }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleChangeStatus={(e) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, status: e.target.value }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleDiscard={() => handleDiscard(no)}
                    remarkInfo={remark}
                    handleRemarkInfo={(e: any) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, remark: e.target.value }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    teeth={teeth}
                    teethJson={teethJson}
                    teethType={teethType || 'ADULT'}
                    handleTeeth={(e: any) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, teeth: e.teeth, teethJson: e.teethJson, teethType: e.teethType }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleTeethJson={(e: any) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, teethJson: e }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    handleTeethType={(e: any) => {
                        setErrorTxp([])
                        treatmentListCreate[no] = { ...objData, teethType: e }
                        setTreatmentListCreate([...treatmentListCreate])
                    }}
                    operativeType={props?.operativeType}
                    opertatives={props?.opertatives}
                    errorTxp={errorTxp[no]}
                />
            )
        }
        else {

            const handleChangeSort = (index: number) => {
                const newTreatmentListCreate: any[] = [...treatmentListCreate]
                if (newTreatmentListCreate?.length && !newTreatmentListCreate.some((item) => item?.itemSort)) {
                    newTreatmentListCreate[index].itemSort = 1
                } else if (newTreatmentListCreate.some((item, num: number) => item?.itemSort && num === index)) {
                    const sortCurrent = newTreatmentListCreate[index].itemSort || 0
                    newTreatmentListCreate[index].itemSort = 0
                    const minSortCurrent: { item: any, index: number, itemSort: number }[] = []
                    newTreatmentListCreate.forEach((item, num: number) => {
                        if (item?.itemSort && item.itemSort > sortCurrent) {
                            minSortCurrent.push({ item: { ...item }, index: num, itemSort: item.itemSort })
                        }
                    })
                    minSortCurrent.sort((a, b) => a.itemSort - b.itemSort).forEach((item, num: number) => {
                        newTreatmentListCreate[item.index].itemSort = sortCurrent + num
                    })
                } else {
                    const maxSort = Math.max(...newTreatmentListCreate.map(item => (item?.itemSort || 0)));
                    newTreatmentListCreate[index].itemSort = maxSort + 1
                }
                setTreatmentListCreate(newTreatmentListCreate)
            }

            return (
                <Draggable draggableId={`drag-id-${newIndex}`} index={newIndex}>
                    {(provider) => (
                        <RowTxC
                            id={id}
                            data={treatmentListCreate}
                            disabled={disabled}
                            no={no}
                            itemTypes={itemTypes}
                            items={items}
                            itemId={itemId}
                            itemTypeId={itemTypeId}
                            itemCode={itemCode}
                            qty={qty}
                            price={price}
                            type={type}
                            edit={props.edit}
                            balance={balance}
                            use={(use || qty - balance) <= 0 ? 0 : (use || qty - balance)}
                            totalQtyNow={totalQtyNow}
                            handleChangeItemType={(e: any, value: any) => {
                                loadTreatmentItem(value?.group, value?.itemTypeId, '', function (callbackTreatmentItems: any) {
                                    const itemsList: any = _.merge([], treatmentListCreate)
                                    let item: any = _.merge({}, itemsList[no])
                                    item = { ...item, itemTypeId: value?.itemTypeId, type: value?.group, items: callbackTreatmentItems }
                                    itemsList[no] = item
                                    setTreatmentListCreate(itemsList)
                                })
                            }}
                            handleChangeItem={(e: any, value: any) => {
                                const itemsList: any = _.merge([], treatmentListCreate)
                                let item: any = _.merge({}, itemsList[no])
                                item = {
                                    ...item,
                                    ...value,
                                    disabled: true,
                                    qty: 1,
                                    items: [value],
                                    itemTypes: [{
                                        group: value?.group,
                                        itemTypeId: value?.itemTypeId,
                                        itemTypeName: value?.itemTypeName
                                    }]
                                }
                                itemsList[no] = item
                                setTreatmentListCreate(itemsList)
                            }}
                            handleChangeQty={(e) => {
                                const valQty = valCheck(e)
                                const qty = Number(valQty)
                                const qtyNew = Number(valQty)
                                treatmentListCreate[no] = { ...objData, qty: qty, qtyNew: qtyNew }
                                setTreatmentListCreate([...treatmentListCreate])

                            }}
                            handleChangePrice={(e) => {
                                const valPrice = valCheck(e)
                                const price = Number(valPrice)
                                const priceNew = Number(valPrice)
                                treatmentListCreate[no] = { ...objData, price: price, priceNew: priceNew }
                                setTreatmentListCreate([...treatmentListCreate])
                            }}
                            handleChangeBalance={(e) => {
                                const valBalance = valCheck(e)
                                let numVal = Number(valBalance)
                                if (numVal > qty) numVal = qty
                                if (numVal >= (qty - balance)) {
                                    const sumVal = numVal - (qty - balance)
                                    const finalVal = balance - sumVal
                                    const useNew = use + sumVal
                                    treatmentListCreate[no] = { ...objData, balance: finalVal, balanceNew: finalVal, useNew: useNew }
                                } else {
                                    const sumVal = (qty - balance) - numVal
                                    const finalVal = balance + sumVal
                                    const useNew = use - sumVal
                                    treatmentListCreate[no] = { ...objData, balance: finalVal, balanceNew: finalVal, useNew: useNew }
                                }

                                setTreatmentListCreate([...treatmentListCreate])
                            }}
                            handleDiscard={() => handleDiscard(no)}
                            operativeType={props?.operativeType}
                            opertatives={props?.opertatives}
                            providerDraggable={provider}
                            itemSort={itemSort}
                            handleChangeSort={handleChangeSort}
                        />
                    )}
                </Draggable>
            )
        }
    }
    const renderDataSum = (objData: any, no: number, total: number) => {
        const { disabled, id, price, qty, type, itemId, itemCode, itemTypes, items, itemTypeId, balance } = objData
        return (
            <RowSummaryTXC
                id={id}
                data={treatmentListCreate}
                disabled={disabled}
                no={no}
                itemTypes={itemTypes}
                items={items}
                itemId={itemId}
                itemTypeId={itemTypeId}
                itemCode={itemCode}
                qty={qty}
                price={price}
                type={type}
                edit={props.edit}
                balance={balance}
                totalQtyNow={totalQtyNow}

            />
        )

    }
    const addNewRecordOnClick = () => {
        if (props.txp) {
            setTreatmentListCreate([
                ...treatmentListCreate,
                {
                    disabled: false, itemTypes: treatmentItemTypes, items: treatmentItems, itemId: null,
                    itemTypeId: null, refId: treatmentListCreate.length, id: null, itemCode: null, price: null,
                    dateUse: null, doctorId: 0, duration: null, durationUnit: 'MINUTES', status: 'PENDING',
                    remark: '', teeth: '', teethJson: '', teethType: ''
                }
            ])
        } else {
            setIsHegihtTableDrag('100%')
            props.edit ? setTreatmentListCreate([
                ...treatmentListCreate,
                {
                    disabled: false, itemTypes: treatmentItemTypes, items: treatmentItems, itemId: null,
                    itemTypeId: null, refId: treatmentListCreate.length, id: null, type: null, itemCode: null, price: 0, qty: 0, balance: 0, oldQty: 0
                }
            ]) : (
                setTreatmentListCreate([
                    ...treatmentListCreate,
                    {
                        disabled: false, itemTypes: treatmentItemTypes, items: treatmentItems, itemId: null,
                        itemTypeId: null, refId: treatmentListCreate.length, id: null, type: null, itemCode: null, price: null, qty: null
                    }
                ])
            )
        }
    }

    const addNewRecord = (col: number) => {
        return (
            <TableRow className="row-add-new-message ">
                <TableCell
                    align="left"
                    scope="row"
                    colSpan={col}
                    className={`py-2 add-new-list cursor-pointer ${_.some(treatmentListCreate, { itemTypeId: null }) || _.some(treatmentListCreate, { itemId: null }) ? 'pe-none disabled' : ''}`}
                    onClick={addNewRecordOnClick}
                >
                    <ButtonAddRecord variant="text" className="d-flex align-items-baseline">
                        <span className={'mr-1'}>+</span> {t('TXC.ADD_LIST')}
                    </ButtonAddRecord>
                </TableCell>
            </TableRow >
        )
    }

    const handleDiscard = (id: number) => {
        setTreatmentListCreate(
            _.filter(treatmentListCreate, (i: any, index: number) => {
                return index !== Number(id)
            })
        )
    }

    useEffect(() => {
        if (treatmentListCreate.length) {
            const sum = treatmentListCreate.reduce((total: any, item: any) => total + (item.price * item.qty), 0);
            setSumTXC(sum)
            setCountTXC(treatmentListCreate.length)
            setEditPayChanelCollapse(true)
        }
    }, [treatmentListCreate])


    const handlePopPaymentChannel = (val: any) => {
        setPaymentChannel(val)
    }

    const handleSumTXC = useCallback(() => {
        const val: any = paymentChanel
        const valParse = val?.paymentDetail && JSON.parse(val?.paymentDetail) || []
        const statusSplitPay = valParse[0]?.statusSplitPay

        if (editPayChanelCollapse && val.paymentType === "SPLITPAY" && statusSplitPay && statusSplitPay !== '') {
            const summary = sumTXC
            const countSplitPay = valParse.length
            const summaryClone: any = []

            const partSize = summary / countSplitPay;
            const summaryTableCourses = valParse.map((item: any, index: number) => {
                const reversedIndex = countSplitPay === (index + 1) ? 0 : countSplitPay - index;
                const payPaid = reversedIndex * partSize;
                const payPaid_last = countSplitPay * partSize
                const newSummaryCloneReduce = summaryClone.reduce((a: any, b: any) => a + b.total_paid, 0) || 0
                const newPayPaid_last = payPaid_last - (newSummaryCloneReduce + Number(item?.total_paid || 0))
                const calBalnce = (payPaid - partSize)
                const newData = {
                    index: (index + 1),
                    total_paid: statusSplitPay === "SEPARATE" ? partSize : Number(item?.total_paid || 0),
                    balance: statusSplitPay === "SEPARATE" ? calBalnce > 0 && calBalnce || 0 : newPayPaid_last,
                    total: summary,
                    statusSplitPay: statusSplitPay,
                    date: item?.date || ''
                }
                summaryClone.push(newData)
                return newData
            });
            const newVal = {
                paymentDetail: JSON.stringify(summaryTableCourses),
                paymentType: val.paymentType
            }
            setEditPayChanelCollapse(false)
            handlePopPaymentChannel(newVal)
        } else {
            setEditPayChanelCollapse(false)
        }
    }, [editPayChanelCollapse])

    useEffect(() => {
        handleSumTXC()
    }, [editPayChanelCollapse])

    const handleDragEnd = (e: any) => {
        if (!e?.destination) {
            setIsDraging(false)
            return
        };
        const tempData = Array.from(treatmentListCreate);
        const [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setTreatmentListCreate(tempData);
        setIsDraging(false)
    };

    const handleHeightTableDrag = () => {
        const [divTable]: any = document.querySelectorAll('.table-drag')
        if (divTable) {
            return setIsHegihtTableDrag(`${divTable?.clientHeight}px !important`)
        }
        return setIsHegihtTableDrag('100% !important')
    }

    return (
        <popupTx.ModalStyle fullscreen returnFocusAfterClose={false} size={'xl'} show={props.isShow} aria-labelledby="contained-modal-title-vcenter" centered>
            <Loading show={loading} type="fullLoading" />
            <popupTx.Header >
                <Typography>{props.header}</Typography>
                <CloseButton
                    style={{ position: 'absolute', top: 20, right: 20 }}
                    onClick={() => {
                        props.setIsShow(false)
                    }}
                />
            </popupTx.Header>
            <popupTx.Body className="custom-scroll mb-4">
                <div className='h-100 d-flex flex-column'>
                    <Loading show={loading} type="fullLoading" />
                    {!template &&
                        <>
                            <div className='d-flex '>
                                <div className='pr-2' style={{ width: 288 }}>
                                    <InputTextField
                                        label={props.txp ? t('TXP.POPUP.TITLE_BY_USER') : t('TXC.POPUP.TITLE_BY_USER')}
                                        value={name}
                                        onchange={(e) => { setName(e.target.value) }}
                                        onkeypress={() => { clearErrorMessage() }}
                                        required
                                        helperText={errorMessage.NAME || ''}
                                    />
                                </div>
                                {!template && btn && <div>
                                    <ButtonCustom variant="outlined" btnStyle={{ height: 42, maxWidth: { sm: '220px' } }} textButton={t('TXC.CHANGE_TEMPLATE')} style={{ fontSize: 16, width: '100%' }} onClick={() => { changeTemplate() }} />
                                </div>}
                            </div>
                            <Box
                                className='popup-create-txc custom-scroll pt-1 pb-1'
                                sx={{
                                    height: '100%',
                                    overflow: 'auto',
                                }}
                            >
                                {/* pt-3 */}
                                <Box className='pt-3 overflow-hidden' >
                                    <TableWidth
                                        className={'table-drag'}
                                        onMouseEnter={() => !props.txp && handleHeightTableDrag()}
                                        onMouseLeave={() => !props.txp && setIsHegihtTableDrag('100%')}
                                        sx={{ transition: 'all 0.3s ease-in-out', height: isHegihtTableDrag, '.MuiPaper-root': { paddingBottom: isDraging ? '67.5px' : '' }, 'td': { alignContent: 'flex-start' } }}
                                    >
                                        <DragDropContext
                                            onDragEnd={handleDragEnd}
                                            onDragStart={(e) => setIsDraging(true)}
                                        >
                                            <TableCustom
                                                page={0}
                                                pageLimit={0}
                                                sortType={'ASC'}
                                                sortBy={''}
                                                rowCount={0}
                                                onSort={() => { return }}
                                                setPageLimit={() => { return }}
                                                setPage={() => { return }}
                                                textEmptyData={t('REGISTER.NO_ITEM')}
                                                headCells={txpHeadCellsCheck()}
                                                rowsData={[
                                                    addNewRecord(txpHeadCellsCheck().length),
                                                    _.map(treatmentListCreate, (i, index: number) => {
                                                        return renderData(i, index)
                                                    })
                                                ]}
                                                hidePagination
                                                tableFixedWidth
                                                isDrag={props.txp ? false : true}
                                                droppableId={droppableId}
                                            />
                                        </DragDropContext>
                                    </TableWidth>
                                </Box>
                                {props.edit && !props.txp &&
                                    <div className='pt-3 overflow-hidden'>
                                        <TableWidthSummary>
                                            <TableCustom
                                                customScroll
                                                page={0}
                                                pageLimit={0}
                                                sortType={'ASC'}
                                                sortBy={''}
                                                rowCount={0}
                                                onSort={() => { return }}
                                                setPageLimit={() => { return }}
                                                setPage={() => { return }}
                                                textEmptyData={t('REGISTER.NO_ITEM')}
                                                headCells={headSumarize}
                                                rowsData={[
                                                    _.map([{ row: 1 }, { row: 2 }], (i, index: number) => {
                                                        return renderDataSum(i, index, totalQtyNow)
                                                    }).slice(0, 2)
                                                ]}
                                                hidePagination
                                                tableFixedWidth
                                            />
                                        </TableWidthSummary>
                                    </div>
                                }
                            </Box>
                            <div className='pt-3 mt-auto'>
                                <InputTextarea
                                    hookValue={true}
                                    inputProps={{ maxLength: 255 }}
                                    name="remark"
                                    key={'remark'}
                                    value={remark}
                                    label={t('TXC.REMARK')}
                                    height={96}
                                    onchange={(e) => { setRemark(e.target.value) }}
                                />
                            </div>
                        </>
                    }
                    {popPayChanel &&
                        <>
                            <PopupPaymentChanel
                                onClose={setPopPayChanel}
                                countCourses={countTXC}
                                summaryCourses={sumTXC}
                                handlePopPaymentChannel={handlePopPaymentChannel}
                                dataEdit={paymentChanel}
                            />
                        </>
                    }

                    {template &&
                        <>
                            <ComponentTemplate operativeTypes={props.operativeTypes} txp={props.txp} selectTemplates={(e) => { setUserTemplate(e) }} />
                        </>
                    }

                </div>
            </popupTx.Body>
            <popupTx.Footer className="flex-right text-right border-0">
                <popupTx.BtnCancel fullWidth onClick={closePopup}>
                    {t('BUTTON.CANCEL')}
                </popupTx.BtnCancel>
                <popupTx.BtnConfirm fullWidth className={'btn-submit'}
                    onClick={checkSubmitButton()}>
                    {(template ? t('TXC.USE_TEMPLATE_SELECT') : '') || (props.edit ? t('BUTTON.SAVE') : '') || (props.txp ? t('TXP.CREATE_TXP') : '') || t('TXC.CREATE_TXC')}
                </popupTx.BtnConfirm>
            </popupTx.Footer>
        </popupTx.ModalStyle>
    )
}

export interface ComponentTemplateProps {
    operativeTypes?: any
    txp?: any
    selectTemplates?: (e: any) => void
}

function ComponentTemplate(props: ComponentTemplateProps) {
    const { selectTemplates } = props
    const { t } = useTranslation()
    const user = useSelector(selectMe)
    const [userData, setUserData]: any = useState({});
    const [search, setSearch] = useState('')
    const [opens, setOpens]: any[] = useState([]);
    const [typeSelected, setTypeSelected] = useState('All')
    const [isBookmark, setIsBookmark] = useState('0')
    const [typeId, setTypeId] = useState(0)
    const [templates, setTemplates] = useState([])
    const [selectTemplate, setSelectTemplate] = useState<any[]>([])

    const loadTreatmentPlans = useCallback(async () => {
        let condition: any = {}
        condition = { ...condition, userId: 0 }
        if (isBookmark) condition = { ...condition, isBookmark: isBookmark }
        if (typeId) condition = { ...condition, typeId: typeId }
        if (userData?.userId) condition = { ...condition, userId: userData?.userId }
        if (search) condition = { ...condition, search: search }
        if (props.txp === true) {
            const res = await TreatmentApi.findAllTreatmentPlans(condition)
            if (res.status === 200) setTemplates(res.data)
        } else if (props.txp === false) {
            const res = await TreatmentApi.findAllCourses(condition)
            if (res.status === 200) setTemplates(res.data)
        }

    }, [isBookmark, typeId, search])

    useEffect(() => {
        loadTreatmentPlans()
    }, [loadTreatmentPlans])

    useEffect(() => {
        if (selectTemplates) selectTemplates(selectTemplate)
    }, [selectTemplate])

    useEffect(() => {
        setUserData(user)
    }, [user]);

    const handleChangeType = (ele: any) => {
        const type = ele.currentTarget.getAttribute('data-value')
        setTypeSelected(type)
        setIsBookmark('0')
        setTypeId(0)
        if (type === 'BOOKMARK') setIsBookmark('1')
        else setTypeId(parseInt(type))
    }

    const handleBookmark = async (id: number, bookmarkId: number) => {
        const payload: Bookmark = {
            bookmarkType: props.txp === true ? 'TREATMENT_PLAN' : 'TREATMENT_COURSE',
            userId: userData?.userId,
            itemId: id,
            isBookmark: bookmarkId ? '0' : '1'
        }
        const resp = await ComponentApi.bookmark(payload)
        if (resp.status === 201) {
            loadTreatmentPlans()
        }
    }

    const onCheck = (event: any) => {
        const { checked, value } = event.target
        if (checked)
            setSelectTemplate([...selectTemplate, _.find(templates, (val: any) => (val.treatmentPlanId || val.treatmentCourseId) === parseInt(value))])
        else
            setSelectTemplate(_.filter(selectTemplate, (i: any) => (i.treatmentPlanId || i.treatmentCourseId) !== Number(value)))
    }

    const handleDiscard = (id: number) => {
        setSelectTemplate(_.filter(selectTemplate, (i: any) => (i.treatmentPlanId || i.treatmentCourseId) !== Number(id)))
    }

    const renderData = (objData: any, no: number) => {
        const { treatmentPlanId, treatmentPlanTypeName, treatmentPlanName, treatmentCourseId, treatmentCourseTypeName, treatmentCourseName } = objData
        return (
            <TableRow key={treatmentPlanId}>
                <TableCell align="center" scope="row" className="py-3 py-md-2">
                    {no + 1}
                </TableCell>
                <TableCell align="center" scope="row" className="py-3 py-md-2">
                    {treatmentPlanTypeName || treatmentCourseTypeName}
                </TableCell>
                <TableCell align="center" scope="row" className="py-3 py-md-2">
                    {treatmentPlanName || treatmentCourseName}
                </TableCell>
                <TableCell align="center" scope="row" className="py-3 py-md-2">
                    <IconButton
                        disableFocusRipple
                        disableRipple
                        onClick={() => handleDiscard(treatmentPlanId || treatmentCourseId)}
                        className="p-0 align-self-start mr-2 ml-3"
                        sx={{ backgroundColor: 'transparent' }}>
                        <img src={icons.btnMinus} alt="discard" />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <div className='w-100 h-100 pt-2'>
            <div className="d-flex">
                <div style={{ fontSize: 18, fontWeight: 500 }}>
                    {props.txp ? t('TXP.POPUP.TITLE_TEMPLATE') : t('TXC.POPUP.TITLE_TEMPLATE')}
                </div>
                <div className="w-100 pb-4 overflow-hidden ml-auto" style={{ maxWidth: 379 }}>
                    <InputTextSearch
                        keyInput={'search'}
                        label={props.txp ? t('TXP.POPUP.SEARCH') : t('TXC.POPUP.SEARCH')}
                        value={search}
                        onchange={(event) => { setSearch(event.target.value) }}
                        onClear={(event) => { setSearch("") }}
                    />
                </div>
            </div>
            {props.operativeTypes &&
                (
                    <NavBtn className="d-flex flex-wrap custom-scroll" style={{ maxHeight: '76px' }}>
                        <Box className="px-1 pb-2">
                            <ButtonType variant="outlined" title={t('TREATMENTS_DX.TITLE.ALL')} className={`${typeSelected === 'All' ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={handleChangeType} key={`btn-operativeTypes-all`}>
                                <span className="text-ellipsis">{t('TREATMENTS_DX.TITLE.ALL')}</span>
                            </ButtonType>
                        </Box>
                        <Box className="px-1 pb-2">
                            <ButtonType variant="outlined" title={t('BOOKMARK')} className={`${typeSelected === 'BOOKMARK' ? 'active' : ''}`} data-value={'BOOKMARK'} onClick={handleChangeType} key={`btn-operativeTypes-bookmark`}>
                                {isBookmark === '1' ? <Icons.StarActive fill={colors.white} /> : <Icons.StarInActive fill={colors.textPrimary} />}
                            </ButtonType>
                        </Box>
                        {
                            props.operativeTypes.map((val: any, i: number) => {
                                return (
                                    <Box key={`col-operative-typ-${val.treatmentPlanTypeId}`} className="px-1 pb-2">
                                        <ButtonType variant="outlined" key={val.treatmentPlanTypeId || val.treatmentCourseTypeId} title={val.treatmentPlanTypeName || val.treatmentCourseTypeName} data-value={val.treatmentPlanTypeId || val.treatmentCourseTypeId} onClick={handleChangeType} className={`${props.txp && val.treatmentPlanTypeId && typeSelected === val.treatmentPlanTypeId.toString() ? 'active' : ''} ${props.txp && val.treatmentCourseTypeId && typeSelected === val.treatmentCourseTypeId.toString() ? 'active' : ''} text-ellipsis`}>
                                            <span className="text-ellipsis">{val.treatmentPlanTypeName || val.treatmentCourseTypeName}</span>
                                        </ButtonType>
                                    </Box>
                                )
                            })
                        }
                    </NavBtn >
                )
            }
            <Row className='pt-2'>
                {_.map(templates, (val: any, index: number) => {
                    const valBuffer = val.treatmentPlanId || val.treatmentCourseId
                    return (
                        <Col sm={12} md={6} xl={4} className="pt-2">
                            <CardInfo>
                                <div className={'d-flex align-items-center'}>
                                    <Detail className="">
                                        <InputCheckbox
                                            disabled={selectTemplate.length === 10}
                                            onChange={(event) => onCheck(event)}
                                            value={val.treatmentPlanId || val.treatmentCourseId}
                                            checked={_.some(selectTemplate, (i: any) => {
                                                const iBuffer = Number(i.treatmentPlanId || i.treatmentCourseId)
                                                const valBuffer = Number(val.treatmentPlanId || val.treatmentCourseId)
                                                return iBuffer === valBuffer
                                            })}
                                            className="hover-none-bg btn-select" />
                                    </Detail>
                                    <Detail className="mr-auto">
                                        <div> {val.treatmentPlanName || val.treatmentCourseName} </div>
                                    </Detail>
                                    <Detail >
                                        <div>
                                            <Box>
                                                <IconBookmark
                                                    key={val.treatmentPlanId || val.treatmentCourseId}
                                                    className="p-0 ml-1"
                                                    onClick={() => {
                                                        const valBuffer = val.treatmentPlanId || val.treatmentCourseId
                                                        handleBookmark(valBuffer, val.bookmarkId)
                                                    }}
                                                    sx={{ backgroundColor: 'transparent !important' }}>
                                                    {val.isBookmark ? (<Icons.StarActive width="24" height="24" />) : (<Icons.StarInActive width="24" height="24" />)}
                                                </IconBookmark>
                                            </Box>
                                        </div>
                                    </Detail>
                                    <Detail>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            className={'ml-3'}
                                            onClick={(e) => {
                                                if (opens.indexOf(valBuffer) > -1) {
                                                    setOpens((values: any) => [...(values || []).filter((id: number) => id !== valBuffer)])
                                                }
                                                else {
                                                    setOpens((values: any) => [...(values || []), valBuffer])
                                                }
                                            }}
                                            style={{ transform: `${opens.length && opens.indexOf(valBuffer) > -1 ? 'rotate(180deg)' : 'rotate(0deg)'}` }}>
                                            <ExpandMoreIcon className={'expand-more'} />
                                        </IconButton>
                                    </Detail>
                                </div>
                                <CustomCollapse in={opens.length && opens.indexOf(valBuffer) > -1 ? true : false} timeout="auto" unmountOnExit>
                                    <div className={'collapse-wrap'}>
                                        {_.map(valBuffer, (val2: any, indexTreatment: number) => {
                                            return <div className='d-flex flex-column'>  {indexTreatment + 1} {val2.itemName} {val.qty} </div>
                                        })}
                                    </div>
                                </CustomCollapse>
                            </CardInfo>
                        </Col>
                    )
                })}
            </Row>
            <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            <div className='mb-3' style={{ fontSize: 18, fontWeight: 500 }}>
                {t('TREATMENTS.TEMPLATE_IS_CHOOSE')}
            </div>
            <div className='overflow-hidden'>
                <TableWidthTemplate>
                    <TableCustom
                        customScroll
                        hidePagination
                        textEmptyData={t('REGISTER.NO_ITEM')}
                        page={0}
                        pageLimit={0}
                        sortType={'ASC'}
                        sortBy={''}
                        headCells={[]}
                        rowCount={0}
                        onSort={() => {
                            return
                        }}
                        setPageLimit={() => {
                            return
                        }}
                        setPage={() => {
                            return
                        }}
                        rowsData={selectTemplate.map((val, i) => {
                            return renderData(val, i)
                        })}
                    />
                </TableWidthTemplate>
            </div>
        </div >
    )
}