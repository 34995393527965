import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import useSelection from 'antd/lib/table/hooks/useSelection'
import { selectAllMembers } from 'app/slice/member.slice'
import { RANKS } from 'constants/common'
import { colors } from 'constants/theme'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getMember } from 'utils/app.utils'

type RankType = {
  rankId?: number
  disabled?: boolean
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize'
}

const Ranking = styled(Chip)(({ theme }) => ({
  height: 29,
  borderRadius: 4,
  span: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.white
  },
  [theme.breakpoints.down('sm')]: {
    height: 24,
    span: {
      fontSize: 14
    }
  }
}))

export default function LabelRanking(props: RankType) {
  const memberAll = getMember()
  const rankData = _.find(memberAll, { rankId: props.rankId });

  return (
    <Ranking
      label={rankData ? rankData.rankName : 'RANKING MEMBER'}
      size="small"
      style={{
        background: props.disabled ? colors.disabledGray : rankData ? rankData.color : colors.backdrop,
        textTransform: props.textTransform || 'uppercase'
      }}
    />
  );
}
