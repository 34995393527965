import { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Grid, MenuItem } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import Summary from 'features/dashboard/Dashboard/summary'
import SalesProduct from 'features/dashboard/Dashboard/salesProduct'
import SalesOperative from 'features/dashboard/Dashboard/salesOperative'
import DoctorFee from 'features/dashboard/Dashboard/doctorFee'
import LowSaleProduct from 'features/dashboard/Dashboard/lowSaleProduct'
import HightSaleProduct from 'features/dashboard/Dashboard/hightSaleProduct'
import PaymentMethod from 'features/dashboard/Dashboard/paymentMethod'
import TotalExpenses from 'features/dashboard/Dashboard/totalExpenses'
import OperativeType from './Dashboard/operativeType'
import HightSaleOperative from './Dashboard/hightSaleOperative'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** API */
import ClinicApi, { BranchInterface, DentistInterface } from 'api/master/clinic.api'

/** STYLE */
import * as UseStyled from 'features/dashboard/useStyled'
import { KEY_STORAGE } from 'constants/common'

export interface CardSalesProps {
  branches?: BranchInterface[]
  branch?: (string | number)[]
  rangeDate?: string[]
  handleChangeDashboard?: (key: string, value: any) => void
}

export default function Dashboard() {
  const { t } = useTranslation()

  const dashboardMain = localStorage.getItem(KEY_STORAGE.DASHBOARD)
  const dashboardCurrent = dashboardMain ? JSON.parse(dashboardMain) : {}

  const [dataDashboard, setDataDashboard] = useState<any>(dashboardCurrent || {})

  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [branch, setBranch] = useState<(string | number)[]>(['ALL'])
  const [rangeDate, setRangeDate] = useState([moment().format('YYYY-MM-01'), moment().format('YYYY-MM-DD')])

  const handleChangeDashboard = (key: string, value: any) => {
    const [firstKey, secKey] = key?.split(',')
    if (firstKey === "branch" || firstKey === "rangeDate") {
      const updatedData = { ...dataDashboard };
      if (firstKey === "branch") {
        Object.keys(updatedData).forEach(k => {
          if (updatedData[k].branch) {
            updatedData[k].branch = value;
          }
        });
      }
      if (firstKey === "rangeDate") {
        Object.keys(updatedData).forEach(k => {
          if (updatedData[k].rangeDate) {
            updatedData[k].rangeDate = value;
          }
        });
      }

      setDataDashboard({ ...updatedData, [firstKey]: value });
    } else if (!secKey) {
      setDataDashboard({ ...dataDashboard, [firstKey]: value });
    } else {
      setDataDashboard({
        ...dataDashboard,
        [firstKey]: { ...dataDashboard[firstKey], [secKey]: value },
      });
    }
  }

  useEffect(() => {
    localStorage.setItem(KEY_STORAGE.DASHBOARD, JSON.stringify(dataDashboard))
  }, [dataDashboard])

  useEffect(() => {
    setBranch(dataDashboard?.branch || ['ALL'])
    setRangeDate(dataDashboard?.rangeDate || [moment().format('YYYY-MM-01'), moment().format('YYYY-MM-DD')])
  }, [dataDashboard?.branch, dataDashboard?.rangeDate])

  const loadDefaultData = async () => {
    await ClinicApi.findAllBranches().then(({ data }) => setBranches(data))
  }

  const handleChangeSelectBranch = (e: any) => {
    handleChangeDashboard("branch", handleChangeFilterSelect(e))
    // setBranch(handleChangeFilterSelect(e))
  }

  useEffect(() => {
    loadDefaultData()
  }, [])

  return (
    <div className="p-3 px-md-4">
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={6} md={4} lg={3} xl={2} className="w-100">
          <FilterSelect
            multiple
            label={t('')}
            labelId="label-branch"
            selectId="select-branch"
            onchange={handleChangeSelectBranch}
            renderValue={() => `${t('DASHBOARD.FILTER.BRANCH')}: ${renderFilterSelect('branch', branches, branch) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('branch', branches, branch)}`}
            value={branch}
            options={[
              <MenuItem
                key="ALL"
                value="ALL"
                sx={{
                  '&.MuiMenuItem-root.Mui-selected': {
                    pointerEvents: _.includes(branch, 'ALL') ? `none` : 'auto',
                    backgroundColor: _.includes(branch, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                    color: _.includes(branch, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                  }
                }}
              >
                {t('STATUS.ALL')}
              </MenuItem>,
              _.map(branches, (val: BranchInterface, indexBranch: number) => {
                return (
                  <MenuItem
                    key={indexBranch}
                    value={val.branchId}
                    sx={{
                      '&.MuiMenuItem-root.Mui-selected': {
                        backgroundColor: _.includes(branch, val.branchId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                        color: _.includes(branch, val.branchId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                      }
                    }}
                  >
                    {val.branchName}
                  </MenuItem>
                )
              })
            ]}
            formControlClass={'not-max-width'}
            classesOption="style-select-doctors"
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3} xl={2} className="w-100">
          <InputSecondNewRangePicker
            inputHeight={32}
            value={rangeDate}
            onchange={(val) => {
              if (_.isEmpty(val)) {
                handleChangeDashboard("rangeDate", emptyRangePicker)
                // setRangeDate(emptyRangePicker)
              }
              else {
                handleChangeDashboard("rangeDate", [moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                //  setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
              }
            }}
            allowClear
            onClear={() => handleChangeDashboard("rangeDate", emptyRangePicker)}
          />
        </Grid>
      </Grid>
      <Summary branch={branch} rangeDate={rangeDate} />
      <Grid container spacing={2} className="mb-4">
        <Grid item className="w-100">
          <UseStyled.Title type="header">{t('DASHBOARD.HEADER.SALE')}</UseStyled.Title>
        </Grid>
        <Grid item lg={6} className="w-100">
          <SalesProduct branches={branches} branch={branch} rangeDate={rangeDate} handleChangeDashboard={handleChangeDashboard} />
        </Grid>
        <Grid item sm={6} lg={3} className="w-100">
          <SalesOperative branches={branches} branch={branch} rangeDate={rangeDate} handleChangeDashboard={handleChangeDashboard} />
        </Grid>
        <Grid item sm={6} lg={3} className="w-100">
          <DoctorFee rangeDate={rangeDate} branch={branch} handleChangeDashboard={handleChangeDashboard} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={6} className="w-100 mb-4">
          <Box className="d-flex flex-column h-100">
            <UseStyled.Title type="header">{t('DASHBOARD.HEADER.TOTAL_EXPENSES')}</UseStyled.Title>
            <Box className="pt-3 h-100">
              <TotalExpenses branches={branches} branch={branch} rangeDate={rangeDate} handleChangeDashboard={handleChangeDashboard} />
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} className="w-100 mb-4">
          <Box className="d-flex flex-column h-100">
            <UseStyled.Title type="header">{t('DASHBOARD.HEADER.PAYMENT')}</UseStyled.Title>
            <Box className="pt-3 h-100">
              <PaymentMethod branches={branches} branch={branch} rangeDate={rangeDate} handleChangeDashboard={handleChangeDashboard} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-4">
        <Grid item className="w-100">
          <UseStyled.Title type="header">{t('DASHBOARD.HEADER.OPERATIVE')}</UseStyled.Title>
        </Grid>
        <Grid item lg={6} className="w-100">
          <OperativeType branches={branches} branch={branch} rangeDate={rangeDate} handleChangeDashboard={handleChangeDashboard} />
        </Grid>
        <Grid item lg={6} className="w-100">
          <HightSaleOperative branches={branches} branch={branch} rangeDate={rangeDate} handleChangeDashboard={handleChangeDashboard} />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-4">
        <Grid item className="w-100">
          <UseStyled.Title type="header">{t('DASHBOARD.HEADER.WAREHOUSE')}</UseStyled.Title>
        </Grid>
        <Grid item lg={6} className="w-100">
          <LowSaleProduct branches={branches} branch={branch} handleChangeDashboard={handleChangeDashboard} />
        </Grid>
        <Grid item lg={6} className="w-100">
          <HightSaleProduct branches={branches} branch={branch} rangeDate={rangeDate} handleChangeDashboard={handleChangeDashboard} />
        </Grid>
      </Grid>
    </div>
  )
}

export const handleChangeFilterSelect = (e: any) => {
  const value = e.target.value
  if (_.last(value) === 'ALL' || _.isEmpty(value)) return ['ALL']
  else
    return _.filter(value, (v) => {
      return !_.includes(v, 'ALL')
    })
}

export const renderFilterSelect = (type: 'branch' | 'dentist' | 'item' | 'operativeType', data: any, value: (string | number)[]) => {
  if (_.last(value) === 'ALL') return 'ALL'
  else {
    const items: string[] = []
    _.map(value, (v) => {
      if (type === 'branch') items.push(_.get(_.find(data, { branchId: v }), 'branchName'))
      else if (type === 'dentist') items.push(_.get(_.find(data, { dentistId: v }), 'dentistFullname'))
      else if (type === 'operativeType') items.push(_.get(_.find(data, { itemTypeId: v }), 'itemTypeName'))
      else items.push(_.get(_.find(data, { operativeId: v }), 'operativeName'))
    })
    return items.toString().replaceAll(',', ', ')
  }
}
