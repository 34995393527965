import { Box, IconButton, TableCell, TableRow } from '@mui/material'
import ComponentApi from 'api/components-api'
import { BranchInterface } from 'api/master/clinic.api'
import PromotionsApi from 'api/promotion/promotion.api'
import MemberManagementApi from 'api/setting/member.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputRadio from 'component/Input/InputRadio'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { icons } from 'constants/images'
import { ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type FormPromotionFooterProps = {
    dataEdit: any
    handleChangeDetailPromotion: (data: any) => void
    branches: BranchInterface[]
    errorMessage: any
    pageDisabled: boolean
}

const FormPromotionFooter = (props: FormPromotionFooterProps) => {
    const { dataEdit, handleChangeDetailPromotion, branches, pageDisabled } = props
    const { t } = useTranslation()

    const [newBranches, setNewBranches] = useState<BranchInterface[]>([])

    const promotionGroupOp = [{ name: t('PROMOTION.FORM.RANK_GROUP'), value: 'RANK' }, { name: t('PROMOTION.FORM.PATIENT_GROUP'), value: 'PATIENT' }]

    const [promotionGroupSubOp, setPromotionGroupSubOp] = useState<any>([])
    const [promotionGroupsSelect, setPromotionGroupSelect] = useState<boolean>(false)

    const loadPromotionGroupOption = async () => {
        if (dataEdit?.promotionGroup === promotionGroupOp[1].value) {
            const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
            await ComponentApi.findAllPatientType(condition).then((resPatientTypes) => {
                if (resPatientTypes.status === 200) {
                    const newData = resPatientTypes?.data.map((item: any) => {
                        return { name: item?.patientTypeName, value: item?.patientTypeId }
                    })
                    setPromotionGroupSubOp(newData)
                }
            })
        } else {
            const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'NO_SETTING', status: 'ACTIVE' }
            await MemberManagementApi.findAll(condition).then((res) => {
                if (res?.status === 200) {
                    const newData = res?.data?.map((item: any) => {
                        return { name: item?.rankName, value: item?.rankId }
                    })
                    setPromotionGroupSubOp(newData)
                }
            })
        }
    }

    useEffect(() => {
        if (dataEdit?.promotionGroup && promotionGroupOp.some((item) => item.value === dataEdit?.promotionGroup)) {
            loadPromotionGroupOption()
        } else {
            setPromotionGroupSubOp([])
        }
    }, [dataEdit?.promotionGroup])

    useEffect(() => {
        if (dataEdit?.branchId?.length) {
            const newBranch = branches.filter((item) => dataEdit?.branchId.includes(item.branchId))
            setNewBranches(newBranch)
        } else {
            setNewBranches([])
        }
    }, [branches, dataEdit?.branchId])

    const handlePromotionGroups = (option: any) => {
        if (option === 'ALL') {
            if (dataEdit?.promotionGroups?.length === promotionGroupSubOp?.length) {
                handleChangeDetailPromotion({ ...dataEdit, promotionGroups: [] })
            } else {
                const newData = promotionGroupSubOp.map((item: any) => {
                    return { groupType: dataEdit?.promotionGroup, groupTypeId: item?.value }
                })
                handleChangeDetailPromotion({ ...dataEdit, promotionGroups: newData })
            }
        } else {
            if (!dataEdit?.promotionGroups?.length) {
                const newData = [{ groupType: dataEdit?.promotionGroup, groupTypeId: option?.value }]
                handleChangeDetailPromotion({ ...dataEdit, promotionGroups: newData })
            } else {
                if (dataEdit?.promotionGroups?.some((item: any) => item?.groupTypeId === option?.value)) {
                    const newData = dataEdit?.promotionGroups.filter((item: any) => item?.groupTypeId !== option?.value)
                    handleChangeDetailPromotion({ ...dataEdit, promotionGroups: newData })
                } else {
                    const newData = { groupType: dataEdit?.promotionGroup, groupTypeId: option?.value }
                    handleChangeDetailPromotion({ ...dataEdit, promotionGroups: [...dataEdit?.promotionGroups, newData] })
                }
            }
        }

    }

    return (
        <Box>
            <Box width={'100%'} height={'1px'} bgcolor={'#E5E5E5'} margin={'16px 0'} />
            <Box>
                <Box fontSize={'18px'} fontWeight={'500'}>
                    {t('PROMOTION.FORM.SETTING_OTHER')}
                </Box>
                <Box marginTop={'16px'}>
                    <Box marginBottom={'16px'}>
                        <Box>
                            <InputCheckbox
                                label={t('PROMOTION.FORM.USAGE_BRANCH')}
                                checked={dataEdit?.usageBranch === '1' || ['ALL', 'BRANCH', 'FIXBRANCH'].includes(dataEdit?.usageBranch)}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, usageBranch: (dataEdit?.usageBranch === '1' || ['ALL', 'BRANCH', 'FIXBRANCH'].includes(dataEdit?.usageBranch)) ? 'NOTFIX' : '1', qtyTotal: 0, usageBranchUse: [] })}
                                disabled={pageDisabled}
                            />
                        </Box>
                        {(dataEdit?.usageBranch === '1' || ['FIXBRANCH', 'ALL', 'BRANCH'].includes(dataEdit?.usageBranch)) &&
                            <Box padding={{ sm: '0 24px', xs: '0' }} display={'flex'} flexDirection={'column'} gap={'16px'} >
                                <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'center'} width={{ md: '80%', xs: '100%' }} >
                                    <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                        <Box>
                                            <InputRadio
                                                checked={dataEdit?.usageBranch === 'ALL'}
                                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, usageBranch: 'ALL', qtyTotal: 0, usageBranchUse: [] })}
                                                disabled={pageDisabled}
                                            />
                                        </Box>
                                        <Box>
                                            {t('PROMOTION.FORM.USAGE_BRANCH_ALL')}
                                        </Box>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} padding={{ sm: '0 0 0 24px', xs: '0' }} gap={'8px'}>
                                        <InputTextField
                                            label={t('PROMOTION.FORM.COUNT_PROMOTION')}
                                            value={dataEdit?.usageBranch === 'ALL' ? dataEdit?.qtyTotal || '' : ''}
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value?.length > 8) {
                                                    return
                                                }
                                                if (event.target.value === '') {
                                                    handleChangeDetailPromotion({ ...dataEdit, qtyTotal: null })
                                                    return
                                                }
                                                if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                                    handleChangeDetailPromotion({ ...dataEdit, qtyTotal: value })
                                                }
                                            }}
                                            disabled={dataEdit?.usageBranch !== 'ALL' || pageDisabled}
                                            required
                                            helperText={dataEdit?.usageBranch === 'ALL' ? props?.errorMessage?.PROMOTION_QTYTOTAL || '' : ''}
                                        />
                                        <Box>
                                            {t('PROMOTION.FORM.TIMES')}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'center'} width={{ md: '80%', xs: '100%' }} >
                                    <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                        <Box>
                                            <InputRadio
                                                checked={dataEdit?.usageBranch === 'FIXBRANCH'}
                                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, usageBranch: 'FIXBRANCH', qtyTotal: 0, usageBranchUse: [] })}
                                                disabled={pageDisabled}
                                            />
                                        </Box>
                                        <Box>
                                            {t('PROMOTION.FORM.USAGE_BRANCH_FIXBRANCH')}
                                        </Box>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} padding={{ sm: '0 0 0 24px', xs: '0' }} gap={'8px'}>
                                        <InputTextField
                                            label={t('PROMOTION.FORM.COUNT_PROMOTION')}
                                            value={dataEdit?.usageBranch === 'FIXBRANCH' ? dataEdit?.qtyTotal || '' : ''}
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value?.length > 8) {
                                                    return
                                                }
                                                if (event.target.value === '') {
                                                    handleChangeDetailPromotion({ ...dataEdit, qtyTotal: null })
                                                    return
                                                }
                                                if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                                    handleChangeDetailPromotion({ ...dataEdit, qtyTotal: value })
                                                }
                                            }}
                                            disabled={dataEdit?.usageBranch !== 'FIXBRANCH' || pageDisabled}
                                            required
                                            helperText={dataEdit?.usageBranch === 'FIXBRANCH' ? props?.errorMessage?.PROMOTION_QTYTOTAL || '' : ''}
                                        />
                                        <Box>
                                            {t('PROMOTION.FORM.TIMES')}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box width={{ md: '80%', xs: '100%' }}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box>
                                            <InputRadio
                                                checked={dataEdit?.usageBranch === 'BRANCH'}
                                                onChange={() => {
                                                    if (!dataEdit?.usageBranchUse?.length) {
                                                        handleChangeDetailPromotion({ ...dataEdit, usageBranch: 'BRANCH', usageBranchUse: [{}] })
                                                    } else {
                                                        handleChangeDetailPromotion({ ...dataEdit, usageBranch: 'BRANCH', usageBranchUse: [] })
                                                    }
                                                }}
                                                disabled={pageDisabled}
                                            />
                                        </Box>
                                        <Box>
                                            {t('PROMOTION.FORM.USAGE_BRANCH_BRANCH')}
                                        </Box>
                                    </Box>
                                    {dataEdit?.usageBranch === 'BRANCH' &&
                                        <Box >
                                            <RenderBranchUsage
                                                type={'USE'}
                                                branches={newBranches}
                                                objData={dataEdit?.usageBranchUse || []}
                                                handleBranchUsage={(data: any) => handleChangeDetailPromotion({ ...dataEdit, usageBranchUse: data })}
                                                errorMessage={props.errorMessage}
                                                pageDisabled={pageDisabled}
                                                dataEdit={dataEdit}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>

                    <Box marginBottom={'16px'}>
                        <Box>
                            <InputCheckbox
                                label={t('PROMOTION.FORM.USAGE_BRANCH_DATE')}
                                checked={dataEdit?.usageBranchDate === '1' || dataEdit?.usageBranchDateData?.length > 0}
                                onChange={() => {
                                    if (dataEdit?.usageBranchDate !== '1' && !dataEdit?.usageBranchDateData?.length) {
                                        handleChangeDetailPromotion({ ...dataEdit, usageBranchDate: (dataEdit?.usageBranchDate === '1') ? '0' : '1', usageBranchDateData: [{}] })
                                    } else {
                                        handleChangeDetailPromotion({ ...dataEdit, usageBranchDate: (dataEdit?.usageBranchDate === '1') ? '0' : '1', usageBranchDateData: [] })
                                    }

                                }}
                                disabled={pageDisabled}
                            />
                        </Box>
                        {(dataEdit?.usageBranchDate === '1' || dataEdit?.usageBranchDateData?.length > 0) &&
                            <Box padding={{ sm: '0 24px', xs: '0' }}>
                                <Box width={{ md: '80%', xs: '100%' }}>
                                    <RenderBranchUsage
                                        type={'DATE'}
                                        branches={newBranches}
                                        objData={dataEdit?.usageBranchDateData || []}
                                        handleBranchUsage={(data: any) => handleChangeDetailPromotion({ ...dataEdit, usageBranchDateData: data })}
                                        errorMessage={props.errorMessage}
                                        pageDisabled={pageDisabled}
                                        dataEdit={dataEdit}
                                    />
                                </Box>
                            </Box>
                        }
                    </Box>

                    <Box marginBottom={'16px'} width={{ md: '80%', xs: '100%' }} display={{ sm: 'flex', xs: 'block' }} justifyContent={'space-between'}>
                        <Box>
                            <InputCheckbox
                                label={t('PROMOTION.FORM.BIND_GROUP')}
                                checked={dataEdit?.promotionGroup === '1' || promotionGroupOp.some((item) => item.value === dataEdit?.promotionGroup)}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionGroup: (dataEdit?.promotionGroup === '1' || promotionGroupOp.some((item) => item.value === dataEdit?.promotionGroup)) ? 'ALL' : '1', promotionGroups: [] })}
                                disabled={pageDisabled}
                            />
                        </Box>
                        {(dataEdit?.promotionGroup === '1' || promotionGroupOp.some((item) => item.value === dataEdit?.promotionGroup)) &&
                            <Box width={{ md: '510px', xs: '100%' }} display={'flex'} alignItems={'center'} gap={'8px'}>
                                <Box width={{ md: '50%', xs: '100%' }}>
                                    <AutocompleteSelect
                                        labelId='promotion-group'
                                        inputLabel={t('PROMOTION.FORM.ITEMTYPE_GROUP')}
                                        required
                                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                        options={promotionGroupOp}
                                        getOptionLabel={(option) => option?.name || ''}
                                        renderOption={(props, option) => (<Box {...props} whiteSpace={'nowrap'}> {option?.name}</Box>)}
                                        onchange={(e, value) => handleChangeDetailPromotion({ ...dataEdit, promotionGroup: value?.value, promotionGroups: [] })}
                                        filterOptions={(option) => option?.name}
                                        value={promotionGroupOp.find((item) => item.value === dataEdit?.promotionGroup) || ''}
                                        disableClearable={!dataEdit?.promotionGroup?.group}
                                        errMessage={props.errorMessage?.PROMOTION_GROUP || ''}
                                        disabled={pageDisabled}
                                    />
                                </Box>
                                <Box width={{ md: '50%', xs: '100%' }}
                                    sx={{
                                        // height: '100%',
                                        '.MuiAutocomplete-root': {
                                            height: '100% !important'
                                        },
                                        '.MuiInputBase-root': {
                                            height: '100% !important'
                                        },
                                        '.Mui-multiple': {
                                            '.MuiFormControl-root': {
                                                '.MuiOutlinedInput-root': {
                                                    marginTop: 0,
                                                    padding: '10px 24px 0px 16px !important',
                                                    flexWrap: 'nowrap'
                                                },
                                                '.MuiOutlinedInput-input': {
                                                    height: '100% !important',
                                                    minWidth: 'unset',
                                                    padding: '0 !important'
                                                },
                                            }
                                        },
                                    }}>
                                    <AutocompleteSelect
                                        labelId='promotion-group'
                                        inputLabel={t('PROMOTION.FORM.ITEM_GROUP')}
                                        getInputSearch={(e) => setPromotionGroupSelect(e?.inputProps?.value !== "")}
                                        required
                                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                        options={['ALL', ...promotionGroupSubOp]}
                                        getOptionLabel={(option) => option?.name || ''}
                                        renderOption={(props, option) => {
                                            if (option === 'ALL') {
                                                return (
                                                    <ListFilterDoctors
                                                        {...props}
                                                        component="li"
                                                        onClick={() => handlePromotionGroups("ALL")}
                                                    >
                                                        <InputCheckbox
                                                            label={t('ALL')}
                                                            checked={dataEdit?.promotionGroups?.length === promotionGroupSubOp?.length}
                                                            indeterminate={dataEdit?.promotionGroups && dataEdit?.promotionGroups?.length >= 1 && dataEdit?.promotionGroups?.length !== promotionGroupSubOp?.length}
                                                            className={'pe-none'}
                                                        />
                                                    </ListFilterDoctors>
                                                )
                                            } else {
                                                return (
                                                    <ListFilterDoctors
                                                        {...props}
                                                        component="li"
                                                        onClick={() => handlePromotionGroups(option)}
                                                    >
                                                        <InputCheckbox
                                                            label={option.name}
                                                            checked={dataEdit?.promotionGroups?.some((item: any) => item?.groupTypeId === option?.value)}
                                                            className={'pe-none'}
                                                        />
                                                    </ListFilterDoctors>
                                                )
                                            }
                                        }}
                                        renderTags={(tagValue, getTagProps) => {
                                            return (
                                                <Box className='text-ellipsis ' sx={promotionGroupsSelect && { width: "0px !important" } || {}}>
                                                    {((_.size(tagValue) === promotionGroupSubOp?.length)) && (
                                                        <span>{t('ALL')}</span>
                                                    ) || (
                                                            <Box className='text-ellipsis '>
                                                                {
                                                                    tagValue.map((option: any, index: number) => {
                                                                        return (
                                                                            <span key={index}>
                                                                                {_.find(promotionGroupSubOp, { value: option?.groupTypeId })?.name}
                                                                                {index !== _.findLastIndex(tagValue) && option && `,`}
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </Box>
                                                        )}
                                                </Box>
                                            )
                                        }}
                                        filterOptions={(option) => option?.name || ''}
                                        value={dataEdit?.promotionGroups || []}
                                        disabled={!promotionGroupOp.some((item) => item.value === dataEdit?.promotionGroup) || pageDisabled}
                                        multiple
                                        disableClearable
                                        errMessage={props.errorMessage?.PROMOTION_GROUPS || ''}
                                        height={43}
                                    />
                                </Box>
                            </Box>
                        }
                    </Box>

                    <Box>
                        <Box>
                            <InputCheckbox
                                label={t('PROMOTION.FORM.ISREPEAT')}
                                checked={dataEdit?.isRepeat === "0"}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, isRepeat: (dataEdit?.isRepeat === "1") ? "0" : "1" })}
                                disabled={pageDisabled}
                            />
                        </Box>
                    </Box>

                </Box>
            </Box>

        </Box>
    )
}

export default FormPromotionFooter

type RenderBranchUsageProps = {
    objData: any
    handleBranchUsage: (data: any) => void
    branches: BranchInterface[]
    type: 'USE' | 'DATE'
    errorMessage: any
    pageDisabled: boolean
    dataEdit?: any
}

const RenderBranchUsage = (props: RenderBranchUsageProps) => {
    const { objData, handleBranchUsage, branches, type, errorMessage, pageDisabled, dataEdit } = props
    const { t } = useTranslation()

    const [seletorBranch, setSelectorBranch] = useState<boolean>(false)

    const headCells = {
        'USE': [
            { id: 'branchId', disablePadding: false, label: t('OPERATIVE.BRANCH_PRICE.BRANCH'), align: 'left', class: 'text-nowrap', width: '50%' },
            { id: 'qtyTotal', disablePadding: false, label: t('PROMOTION.FORM.USAGE_TIMES'), align: 'left', class: 'text-nowrap', width: '40%' },
            { id: 'action', disablePadding: false, label: 'Action', width: '80px', class: 'col-action' }
        ],
        'DATE': [
            { id: 'branchId', disablePadding: false, label: t('OPERATIVE.BRANCH_PRICE.BRANCH'), align: 'left', class: 'text-nowrap', width: '30%' },
            { id: 'dateStart', disablePadding: false, label: t('PROMOTION.FORM.DATE_START'), align: 'left', class: 'text-nowrap', width: '30%' },
            { id: 'dateEnd', disablePadding: false, label: t('PROMOTION.FORM.DATE_END'), align: 'left', class: 'text-nowrap', width: '30%' },
            { id: 'action', disablePadding: false, label: 'Action', width: '80px', class: 'col-action' }
        ],
    }

    const handleChangeBranchId = (val: BranchInterface | 'ALL', index: number) => {
        const newData = [...objData]
        const temp: any = { ...newData[index], branchId: [] }
        if (val !== "ALL") {
            if (newData[index]?.branchId === 'ALL') {
                const newBrnachId: number[] = []
                branches.forEach((item) => {
                    if (item.branchId !== val.branchId) {
                        newBrnachId.push(item.branchId)
                    }
                })
                temp['branchId'] = newBrnachId
            } else {
                if (newData[index]?.branchId?.includes(val.branchId)) {
                    const newBrnachId = newData[index].branchId.filter((item: any) => item !== val.branchId)
                    temp['branchId'] = newBrnachId
                } else {
                    const newBrnachId = newData[index]?.branchId?.length ? [...newData[index]?.branchId, val.branchId] : [val.branchId]
                    temp['branchId'] = newBrnachId
                }
            }
        } else {
            let branchSelectAll: number[] = []
            objData.forEach((item: any, num: number) => {
                if (item?.branchId?.length && num !== index) {
                    branchSelectAll = [...branchSelectAll, ...item?.branchId]
                }
            })
            const uniqueBranch = branches.filter((item) => item.branchId && !branchSelectAll?.includes(item.branchId))
            const newBrnachId = (newData[index]?.branchId === 'ALL' || newData[index].branchId?.length === uniqueBranch?.length) ? [] : uniqueBranch?.map((info) => info.branchId)
            temp['branchId'] = newBrnachId
        }
        newData[index] = temp
        handleBranchUsage(newData)
    }

    const onCheckBranchId = (option: BranchInterface, index: number) => {
        if (!objData?.some((item: any) => item?.branchId?.length)) {
            return false
        }
        const check = objData?.some((item: any) => item?.branchId?.includes(option?.branchId))
        return check
    }

    const onDisabledBranch = (option: BranchInterface, index: number) => {
        const check = objData?.some((item: any, num: number) => num !== index && item?.branchId?.includes(option?.branchId))
        return check
    }

    const onIndeterminateBranch = (check: 'all' | 'indeterminate') => {
        let branchSelect: number[] = []
        objData.forEach((item: any) => {
            if (item?.branchId?.length) {
                branchSelect = [...branchSelect, ...item?.branchId]
            }
        })
        if (check === 'all') {
            return branchSelect?.length === branches?.length
        }
        return branchSelect && branchSelect?.length >= 1 && branchSelect?.length !== branches?.length
    }

    const renderValueBranchId = (index: number) => {
        const newData = [...objData]
        if (newData[index]?.branchId === "ALL") {
            return branches?.map((item) => {
                return [item.branchId]
            }) || []
        }
        return newData[index]?.branchId || []
    }

    const renderDisabledDate = (status: 'start' | 'end', index: number) => {
        const newDateMainStart = moment(dataEdit?.startDate)
        const newDateMainEnd = moment(dataEdit?.endDate)
        const today = moment()
        let check = false
        if (status === 'start') {
            check = objData[index]?.dateEnd ? today.isSameOrBefore(moment(objData[index]?.dateEnd)) : true
        } else {
            check = objData[index]?.dateStart ? today.isSameOrAfter(moment(objData[index]?.dateStart)) : true
        }
        return !today.isSameOrAfter(newDateMainStart) || !today.isSameOrBefore(newDateMainEnd) || !check
    }

    const renderData = (data: any, index: any) => {
        const newData = [...objData]

        const colBranch = {
            option: 'COMPONENT',
            class: 'branch-usage-select align-content-start',
            component: (
                <Box
                    sx={{
                        // height: '100%',
                        '.MuiAutocomplete-root': {
                            height: '100% !important'
                        },
                        '.MuiInputBase-root': {
                            height: '100% !important'
                        },
                        '.Mui-multiple': {
                            '.MuiFormControl-root': {
                                '.MuiOutlinedInput-root': {
                                    marginTop: 0,
                                    padding: '10px 24px 0px 16px !important',
                                    flexWrap: 'nowrap'
                                },
                                '.MuiOutlinedInput-input': {
                                    height: '100% !important',
                                    minWidth: 'unset',
                                    padding: '0 !important'
                                },
                            }
                        },
                        '.MuiAutocomplete-listbox': {
                            maxHeight: '160px'
                        }
                    }}>
                    <AutocompleteSelect
                        labelId="branches"
                        getInputSearch={(e) => setSelectorBranch(e?.inputProps?.value !== "")}
                        inputLabel={t('OPERATIVE.BRANCH_PRICE.BRANCH')}
                        options={branches?.length ? ['ALL', ...branches] : []}
                        getOptionLabel={(option: any) => `${option?.branchId || ''}`}
                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                        renderOption={(props, option) => {
                            if (option === 'ALL') {
                                return (
                                    <ListFilterDoctors
                                        {...props}
                                        key={option}
                                        component="li"
                                        value={option}
                                        onClick={() => handleChangeBranchId("ALL", index)}
                                    >
                                        <InputCheckbox
                                            label={t('ALL')}
                                            checked={onIndeterminateBranch('all')}
                                            indeterminate={onIndeterminateBranch('indeterminate')}
                                            className={'pe-none'}
                                        />
                                    </ListFilterDoctors>
                                )
                            } else {
                                return (
                                    <ListFilterDoctors
                                        {...props}
                                        key={option.dentistId}
                                        component="li"
                                        value={option.dentistId}
                                        onClick={() => !onDisabledBranch(option, index) && handleChangeBranchId(option, index)}
                                        title={option.branchName}
                                    >
                                        <InputCheckbox
                                            label={option.branchName}
                                            checked={onCheckBranchId(option, index)}
                                            className={'pe-none'}
                                            disabled={onDisabledBranch(option, index)}
                                        />
                                    </ListFilterDoctors>
                                )
                            }
                        }}
                        renderTags={(tagValue, getTagProps) => {
                            return (
                                <Box className='text-ellipsis ' sx={seletorBranch && { width: "0px !important" } || {}}>
                                    {((_.size(tagValue) === branches?.length) || data.branchId === 'ALL') && (
                                        <span>{t('ALL')}</span>
                                    ) || (
                                            <Box className='text-ellipsis '>
                                                {
                                                    tagValue.map((option: any, index: number) => {
                                                        return (
                                                            <span key={index}>
                                                                {_.find(branches, { branchId: option })?.branchName}
                                                                {index !== _.findLastIndex(tagValue) && option && `,`}
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        )}
                                </Box>
                            )
                        }}
                        filterOptions={(option) => 'ALL' + option?.branchName || ''}
                        value={renderValueBranchId(index)}
                        required
                        multiple
                        disableClearable
                        errMessage={!renderValueBranchId(index)?.length ? errorMessage?.[`PROMOTION_BRANCH_${type}`] || '' : ''}
                        height={43}
                        disabled={pageDisabled}
                    />
                </Box>
            )
        }

        if (type === 'USE') {
            const objRenderData = {
                key: index,
                id: index,
                obj: data,
                columns: [
                    colBranch,
                    {
                        option: 'COMPONENT',
                        class: 'align-content-start',
                        component: (
                            <Box sx={{ '.MuiOutlinedInput-root': { paddingRight: '35px !important' } }}>
                                <InputTextField
                                    value={data?.qtyTotal}
                                    label={t('PROMOTION.FORM.USAGE_TIMES')}
                                    onchange={(event) => {
                                        const value = event.target.value
                                        if (value?.length > 8) {
                                            return
                                        }
                                        if (event.target.value === '') {
                                            newData[index] = { ...newData[index], qtyTotal: value }
                                            handleBranchUsage(newData)
                                            return
                                        }
                                        if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                            newData[index] = { ...newData[index], qtyTotal: value }
                                            handleBranchUsage(newData)
                                        }
                                    }}
                                    required
                                    helperText={objData?.length - 1 === index ? errorMessage?.PROMOTION_USE_USE || '' : ''}
                                    disabled={pageDisabled}
                                />
                            </Box>
                        )
                    },
                    {
                        option: 'COMPONENT',
                        component: (
                            <Box sx={{ height: '43px', textAlign: 'center' }} >
                                <IconButton onClick={() => handleBranchUsage(objData.filter((item: any, num: number) => num !== index))} disabled={pageDisabled}>
                                    <img src={icons.btnTrash} alt="trash" />
                                </IconButton>
                            </Box>
                        )
                    },
                ]
            }

            return (
                <TableRowCommon {...objRenderData} />
            )
        } else {
            const objRenderData = {
                key: index,
                id: index,
                obj: data,
                columns: [
                    colBranch,
                    {
                        option: 'COMPONENT',
                        class: 'align-content-start',
                        component: (
                            <Box sx={{ 'button': { padding: '0' } }}>
                                <InputSecondNewDatePicker
                                    required
                                    key={'start-date'}
                                    label={t('PROMOTION.FORM.DATE_START')}
                                    value={data?.dateStart}
                                    // objData?.length - 1 === index 
                                    helperText={!data?.dateStart ? errorMessage?.PROMOTION_START_DATE || '' : ''}
                                    onchange={(e: any) => {
                                        newData[index] = { ...newData[index], dateStart: moment(e).format('YYYY-MM-DD') }
                                        handleBranchUsage(newData)
                                    }}
                                    maxDate={data?.dateEnd ? new Date(data?.dateEnd) : dataEdit?.endDate ? new Date(dataEdit?.endDate) : ''}
                                    disableFuture={(data?.dateEnd || dataEdit?.endDate) ? true : false}
                                    minDate={dataEdit?.startDate ? new Date(dataEdit?.startDate) : new Date()}
                                    disablePast
                                    disabled={pageDisabled}
                                    disabledToDay={renderDisabledDate('start', index)}
                                />
                            </Box>
                        )
                    },
                    {
                        option: 'COMPONENT',
                        class: 'align-content-start',
                        component: (
                            <Box sx={{ 'button': { padding: '0' } }} >
                                <InputSecondNewDatePicker
                                    required
                                    key={'end-date'}
                                    label={t('PROMOTION.FORM.DATE_END')}
                                    value={data?.dateEnd || null}
                                    helperText={!data?.dateEnd ? errorMessage?.PROMOTION_END_DATE || '' : ''}
                                    onchange={(e: any) => {
                                        newData[index] = { ...newData[index], dateEnd: moment(e).format('YYYY-MM-DD') }
                                        handleBranchUsage(newData)
                                    }}
                                    maxDate={dataEdit?.endDate ? new Date(dataEdit?.endDate) : ''}
                                    disableFuture={!!dataEdit?.endDate}
                                    minDate={data?.dateStart ? new Date(data?.dateStart) : dataEdit?.startDate ? new Date(dataEdit?.startDate) : new Date()}
                                    disablePast
                                    disabled={pageDisabled}
                                    disabledToDay={renderDisabledDate('end', index)}
                                />
                            </Box>
                        )
                    },
                    {
                        option: 'COMPONENT',
                        component: (
                            <Box sx={{ height: '43px', textAlign: 'center' }} >
                                <IconButton onClick={() => handleBranchUsage(objData.filter((item: any, num: number) => num !== index))} disabled={pageDisabled}>
                                    <img src={icons.btnTrash} alt="trash" />
                                </IconButton>
                            </Box>
                        )
                    },
                ]
            }

            return (
                <TableRowCommon {...objRenderData} />
            )
        }

    }

    const addNewRow = () => {
        let disabledAdd = false
        if (type === 'USE') {
            disabledAdd = objData?.length && objData?.some((item: any) => !item?.branchId?.length || !item?.qtyTotal)
        } else {
            disabledAdd = objData?.length && objData?.some((item: any) => !item?.branchId?.length || !item?.dateStart || !item?.dateEnd)
        }

        const isDisabled = disabledAdd || onIndeterminateBranch('all')
        return (
            <TableRow className="row-add-new-row">
                <TableCell
                    align="left"
                    scope="row"
                    colSpan={_.size(headCells[type])}
                    className={`p-2 ${(isDisabled || pageDisabled) && 'pe-none'}`}
                    onClick={() => {
                        handleBranchUsage([...objData, {}])
                    }}
                    sx={{ button: { filter: isDisabled || pageDisabled ? 'grayscale(1)' : '' } }}
                >
                    <ButtonCustom
                        variant="text"
                        className="btn-add-new-row d-flex align-items-baseline"
                        textButton={`+ ${t('ADJUSTMENT.BUTTON.ADD')}`}
                        disabled={isDisabled || pageDisabled}
                    />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Box sx={{ '.branch-usage-select': { maxWidth: '160px' }, '.col-action': { maxWidth: '80px' } }}>
            <TableCustom
                page={1}
                pageLimit={99}
                sortType={''}
                sortBy={''}
                onSort={() => console.log()}
                setPageLimit={() => console.log()}
                setPage={() => console.log()}
                rowCount={1}
                hidePagination
                headCells={headCells[type]}
                rowsData={[
                    addNewRow(),
                    objData?.map((item: any, index: number) => {
                        return renderData(item, index)
                    })
                ]}
            />
        </Box>
    )

}