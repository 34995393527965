/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@mui/material/Autocomplete'
import { TextField, Paper, SxProps, Theme } from '@mui/material'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'
import 'component/Select/Style.css'
import HelperText from 'component/Input/HelperText'

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
    // padding: '8px 8px 8px 8px',
  },
  '.MuiOutlinedInput-root': {
    backgroundColor: colors.white,
    height: 43,
    alignItems: 'center !important',
    '.MuiButtonBase-root': {
      '&.MuiIconButton-root': {
        backgroundColor: `${colors.transparent} !important`,
      },
      svg: {
        fontSize: 16,
        path: {
          fill: colors.black
        }
      }
    },
    '&.Mui-disabled': {
      backgroundColor: `${colors.black12} !important`,
      '.MuiButtonBase-root svg': {
        path: {
          fill: colors.black60
        }
      },
    },
    '&.MuiInputBase-sizeSmall': {
      '.MuiAutocomplete-input': {
        paddingRight: '16px'
      }
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.themeSecondColor}`,
      }
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  '.MuiInputLabel-root, .MuiInputLabel-root span': {
    fontSize: 16,
    color: `${colors.black60} !important`,
    backgroundColor: 'transparent !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      top: '50%',
      zIndex: -1
    },
    '&[data-shrink="true"]': {
      '&:before': {
        backgroundColor: colors.white
      },
      span: {
        color: `${colors.statusInactive} !important`
      }
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: `${colors.black12} !important`
  },
  '.MuiOutlinedInput-input': {
    textIndent: '1px',
    color: `${colors.textPrimary} !important`,
    '-webkit-text-fill-color': 'unset !important',
    '&.Mui-disabled': {
      '-webkit-text-fill-color': 'unset',
      color: `${colors.black60} !important`,
      '&::placeholder': {
        opacity: 1
      }
    }
  },
  '.MuiAutocomplete-clearIndicator': {
    backgroundColor: 'transparent !important',
    svg: {
      fontSize: 20
    },
    path: {
      fill: `${colors.black60} !important`
    }
  },
  'span.MuiAutocomplete-tag': {
    height: 23,
    minWidth: 36,
    backgroundColor: colors.disabledGray,
    borderRadius: 100,
    color: colors.white,
    textAlign: 'center'
  },
  '&.Mui-enabled-border': {
    '.MuiOutlinedInput-root': {
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: `${colors.themeSecondColor} !important`
        }
      }
    },
    '.MuiInputLabel-root, .MuiInputLabel-root span': {
      '&[data-shrink="true"]': {
        '&.Mui-focused': {
          color: `${colors.themeSecondColor} !important`
        }
      }
    }
  },
  '&.Mui-error': {
    '&.Mui-enabled-border, &.Mui-disabled-border, .MuiOutlinedInput-root.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.statusInactive} !important`
      },
      '.MuiInputLabel-root[data-shrink="true"], .MuiInputLabel-root[data-shrink="true"] span': {
        color: `${colors.statusInactive} !important`
      }
    }
  },
  '.MuiInputBase-inputMultiline': {
    height: '90% !important'
  },
  '&.Mui-multiple': {
    '.MuiOutlinedInput-root': {
      paddingTop: '10px !important'
    },
    '.MuiAutocomplete-clearIndicator': {
      marginRight: '0.375rem'
    }
  }
}))

type SelectSearch = {
  labelId: string
  inputLabel?: string
  options: any
  size?: 'medium' | 'small'
  defaultValue?: any
  noOptionsText?: string
  disableClearable?: boolean
  popupIcon?: any
  clearIcon?: any
  open?: boolean
  disabled?: boolean
  renderOption?: (props: any, option: any) => any
  renderTags?: (props: any, option: any) => any
  getOptionLabel?: (option: any) => any
  getOptionDisabled?: (option: any) => any
  filterOptions?: (option: any) => any
  onchange?: (event: any, value: any, reason: string) => void
  onInputChange?: (event: any, value: any) => void
  onOpen?: (event: any, value: any) => void
  onClose?: (event: any, reason: any) => void
  onBlur?: (event: any) => void
  value?: any
  disabledBorder?: boolean
  required?: boolean
  errMessage?: string
  multiple?: boolean
  multiline?: boolean
  freeSolo?: boolean
  height?: number | string
  limitTags?: number
  classesOption?: string
  placeholder?: string
  inputValue?: any
  isOptionEqualToValue?: any
  inputProps?: any
  loading?: boolean
  loadingText?: any
  clearOnBlur?: boolean
  clearOnEscape?: boolean
  isDisabled?: boolean
  params?: any
  maxHight?: number | string
  getInputSearch?: (props: AutocompleteRenderInputParams) => void
  ariaSelectedSx?: any
  sxPaper?: any
  sxAutoComplete?: SxProps<Theme>
}

export default function AutocompleteSelect(props: SelectSearch) {
  const { t } = useTranslation()
  return (
    <>
      <CustomAutocomplete
        id={props.labelId}
        open={props.open}
        ListboxProps={{ style: { maxHeight: props.maxHight ? props.maxHight : '' } }}
        options={props.options}
        renderOption={props.renderOption}
        renderTags={props.renderTags}
        getOptionLabel={props.getOptionLabel}
        getOptionDisabled={props.getOptionDisabled}
        renderInput={(params) => {
          if (props?.getInputSearch) props.getInputSearch(params)
          return (
            <TextField
              {...params}
              {...props.inputProps}
              label={props.inputLabel}
              placeholder={props.placeholder}
              required={props.required}
              multiline={props.multiline}
            />
          )
        }}
        onChange={props.onchange}
        onInputChange={props.onInputChange}
        onOpen={props.onOpen}
        onClose={props.onClose}
        onBlur={props.onBlur}
        disablePortal
        limitTags={props.limitTags}
        size={props.size || 'small'}
        popupIcon={props.popupIcon || <FontAwesomeIcon icon={faCaretDown} />}
        clearIcon={props.clearIcon || <FontAwesomeIcon icon={faTimes} />}
        classes={{ popper: `style-filter-select-search-wrap ${props.classesOption}`, paper: 'style-filter-select-search', listbox: props.multiple ? 'Mui-multiple custom-scroll' : 'custom-scroll' }}
        sx={{
          '.MuiOutlinedInput-root': {
            paddingTop: props.multiple ? '10px !important' : props.height === 32 ? '2px !important' : '',
            minHeight: props.multiple ? props.height : 'unset',
            height: (props.multiple && 'auto') || (props.height) || 43,
            alignItems: props.multiple ? 'start !important' : 'center  !important',
          },
          ...props?.sxAutoComplete
        }}
        className={`${props.disabledBorder ? 'Mui-disabled-border' : 'Mui-enabled-border'} ${props.errMessage ? 'Mui-error' : ''} ${props.multiple ? 'Mui-multiple' : ''}`}
        filterOptions={createFilterOptions({
          stringify: props.filterOptions
        })}
        noOptionsText={props.noOptionsText}
        defaultValue={props.defaultValue}
        disableClearable={props.disableClearable}
        inputValue={props.inputValue}
        value={props.value}
        disabled={props.disabled}
        multiple={props.multiple}
        freeSolo={props.freeSolo}
        isOptionEqualToValue={props.isOptionEqualToValue}
        loading={props.loading}
        loadingText={t('SEARCHING')}
        clearOnBlur={props.clearOnBlur}
        clearOnEscape={props.clearOnEscape}
        blurOnSelect
        PaperComponent={({ children }) => (
          <Paper sx={{
            ...props?.sxPaper,
            '.MuiInputBase-input': {
              height: `10px !important`,
            },
            '.MuiAutocomplete-option': {
              '&:hover, &:hover.Mui-focused': {
                backgroundColor: `${colors.themeSecondColor06} !important`,
                color: colors.themeSecondColor
              },
              '&[aria-selected="true"], &.Mui-focused': {
                backgroundColor: `${colors.transparent} !important`,
                ...props?.ariaSelectedSx
              }
            }
          }}>{children}</Paper >
        )
        }
        {...props?.params}
      />
      {
        props.errMessage && (
          <HelperText label={props.errMessage} />
        )
      }
    </>
  )
}
