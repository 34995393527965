import { TextField } from "@mui/material";
import { HelperText } from "./helper-text";

interface TextAreaInputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    id?: string
    value?: string | string[]
    disabled?: boolean
    placeholder?: string
    label?: string
    required?: boolean
    helperText?: string
    inputProps?: any
    fullWidth?: boolean
    width?: number | string
    size?: 'large' | 'small'
    borderRadius?: string
    rows?: number
    maxLength?: number
}

export default function TextAreaInput(props: TextAreaInputProps) {

    ////-------------------
    ////    HANDLE
    ////-------------------
    const handleSX = {
        '& .MuiOutlinedInput-root': {
            borderRadius: props.borderRadius,
            backgroundColor: props.disabled ? '#F0F0F0' : '#FFFFFF',
            width: props.width,
            '& fieldset': {
                borderColor: '#C5C5C5',
            },
            '&:hover fieldset': {
                borderColor: '#231F20',
                color: '#29302E'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1CB99A',
                color: '#29302E'
            },
            '&.Mui-disabled fieldset': {
                borderColor: '#C5C5C5',
            },

            '&.Mui-error fieldset': {
                borderColor: `#B00020`,
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#1CB99A',
        },
        '& .MuiInputLabel-root.Mui-error': {
            color: '#B00020'
        },
        '& .MuiOutlinedInput-input': {
            fontSize: 16,
            '& .Mui-disabled': {
                color: '#808080'
            }
        },
        '& .css-k4qjio-MuiFormHelperText-root': {
            marginLeft: 0,
            marginTop: 0
        },
    }

    ////-------------------
    ////    RENDER
    ////-------------------
    return (
        <TextField
            id={props.id}
            label={props.label}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            disabled={props.disabled}
            placeholder={props.placeholder}
            required={props.required}
            variant="outlined"
            multiline
            rows={props.rows || 3}
            fullWidth={props.fullWidth}
            helperText={props.helperText ? <HelperText label={props.helperText} /> : ''}
            error={props.helperText !== '' && props.helperText !== undefined}
            sx={handleSX}
            InputProps={props.inputProps}
            inputProps={{ maxLength: props.maxLength }}
        />
    )
    // วิธีทำ maxLength มุมล่างขวา และใส่ props.maxLength ด้วยนะ!!!!
    // inputProps={{
    //     startAdornment: (
    //         <Box sx={{ position: "absolute", right: '12px', bottom: "2px" }}>
    //             <Box sx={{ fontSize: '12px', color: '#808080' }}>{`${0}/100`}</Box>
    //         </Box>
    //     ),
    // }}
}