import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableRow, TableCell, Box, Tooltip, IconButton } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputRadio from 'component/Input/InputRadio'
import TableCustom from 'component/Table/TableCustom'
import InfoIcon from '@mui/icons-material/Info';
import { colors } from 'constants/theme'
import { getBranch, numberFormat } from 'utils/app.utils'
import InputTextField from 'component/Input/InputTextField'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import ProductsApi from 'api/warehouses/product.api'
import TableRowCommon from 'component/Table/TableRowCommon'
import OperativeApi from 'api/setting/treatments/operative.api'
import { icons } from 'constants/images'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import _ from 'lodash'

type FormPromotionDiscountProps = {
    dataEdit: any
    handleChangeDetailPromotion: (data: any) => void
    errorMessage: any
    pageDisabled: boolean
}

const FormPromotionDiscount = (props: FormPromotionDiscountProps) => {
    const { dataEdit, handleChangeDetailPromotion, errorMessage, pageDisabled } = props
    const { t } = useTranslation()

    const [dataList, setDataList] = useState<any>([])

    const unitOp = [{ name: t('PROMOTION.FORM.BATH'), value: 'BAHT' }, { name: t('PROMOTION.FORM.PERCENT'), value: 'PERCENT' }]

    const headCells = [
        { id: 'no', disablePadding: false, label: t('PROMOTION.FORM.NUMBER'), width: '50px', align: 'center', },
        { id: 'group', disablePadding: false, label: t('PROMOTION.FORM.GROUP_LIST'), align: 'left', class: 'text-nowrap col-group' },
        { id: 'type', disablePadding: false, label: t('PROMOTION.FORM.ITEMTYPE_LIST'), align: 'left', class: 'text-nowrap col-group' },
        { id: 'list', disablePadding: false, label: t('PROMOTION.FORM.ITEM_LIST'), align: 'left', class: 'text-nowrap col-group' },
        { id: 'qty', disablePadding: false, label: t('PROMOTION.FORM.COUNT_LIST'), align: 'center', class: 'text-nowrap col-qty' },
        { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
    ]

    const addNewRow = () => {
        const isDisabled = dataList?.some((item: any) => !item?.group || !item?.itemId || !item?.itemTypeId || !item?.qty)
        return (
            <TableRow className="row-add-new-row">
                <TableCell
                    align="left"
                    scope="row"
                    colSpan={_.size(headCells)}
                    className={`p-2 ${(isDisabled || pageDisabled) && 'pe-none'}`}
                    onClick={() => {
                        if ([...dataList, {}].length > 1 && dataEdit?.promotionUsage) {
                            handleChangeDetailPromotion({ ...dataEdit, promotionUsage: null })
                        }
                        setDataList([...dataList, {}])
                    }}
                    sx={{ button: { filter: isDisabled || pageDisabled ? 'grayscale(1)' : '' } }}
                >
                    <ButtonCustom
                        variant="text"
                        className="btn-add-new-row d-flex align-items-baseline"
                        textButton={`+ ${t('ADJUSTMENT.BUTTON.ADD')}`}
                        disabled={isDisabled || pageDisabled}
                    />
                </TableCell>
            </TableRow>
        )
    }

    const handlePromotionItems = () => {
        const newData = dataList.map((item: any) => {
            const newItemType = item?.group
            return {
                itemType: newItemType,
                itemId: item?.itemId || 0,
                qty: item?.qty || 0
            }
        })
        return newData
    }

    useEffect(() => {
        if (dataList?.length) {
            handleChangeDetailPromotion({ ...dataEdit, promotionItems: handlePromotionItems() })
        } else {
            handleChangeDetailPromotion({ ...dataEdit, promotionItems: [] })
        }
    }, [dataList])

    useEffect(() => {
        if (dataEdit?.promotionItemsCurrent?.length) {
            setDataList(dataEdit?.promotionItemsCurrent)
        }
    }, [dataEdit?.promotionItemsCurrent])

    return (
        <>
            <Box>
                <Box >
                    <InputCheckbox
                        checked={dataEdit?.promotionItems?.length > 0}
                        disabled={pageDisabled}
                        label={t('PROMOTION.FORM.BIND_OP_MED_PD')}
                        onChange={() => {
                            let newData: any = {}
                            if (dataEdit?.promotionPriceType === 'SALEPRICE') {
                                newData = { ...newData, promotionPriceType: null }
                            }
                            const newDataList = dataEdit?.promotionItems?.length ? [] : [{}]
                            setDataList(newDataList)
                            handleChangeDetailPromotion({ ...dataEdit, promotionItems: newDataList, ...newData, promotionUsage: null })
                        }}
                    />
                </Box>
                {dataEdit?.promotionItems?.length ?
                    <Box padding={'16px 24px'}>
                        <Box marginBottom={'16px'}>
                            {dataEdit?.promotionItems?.length > 1 ?
                                t('PROMOTION.FORM.BIND_OP_MED_PD_DETAIL_ALL')
                                :
                                t('PROMOTION.FORM.BIND_OP_MED_PD_DETAIL')
                            }
                        </Box>
                        <Box sx={{ '.col-group': { minWidth: '250px' }, '.col-qty': { minWidth: '150px' } }}>
                            <TableCustom
                                page={1}
                                pageLimit={99}
                                sortType={''}
                                sortBy={''}
                                onSort={() => console.log()}
                                setPageLimit={() => console.log()}
                                setPage={() => console.log()}
                                rowCount={1}
                                hidePagination
                                headCells={headCells}
                                rowsData={[
                                    addNewRow(),
                                    dataList?.map((item: any, index: number) => {
                                        return (
                                            <RenderDataList
                                                key={index}
                                                objData={item}
                                                index={index}
                                                dataList={dataList}
                                                setDataList={(data: any, status?: boolean) => {

                                                    if (data?.length <= 1 && status) {
                                                        handleChangeDetailPromotion({ ...dataEdit, promotionUsage: null })
                                                    }

                                                    setDataList(data)
                                                }}
                                                errorMessage={errorMessage}
                                                pageDisabled={pageDisabled}
                                            />
                                        )
                                    })
                                ]}
                            />
                        </Box>
                        <Box bgcolor={colors.themeMainColor06} color={colors.themeMainColor} fontSize={{ sm: '20px', xs: '18px' }} height={'48px'} width={'100%'} marginTop={'8px'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} padding={'0 24px'}>
                            <Box>
                                {`${t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')}`}
                            </Box>
                            <Box padding={'0 8px 0 48px'}>
                                {numberFormat(dataList?.length ? dataList.reduce((accumulator: any, item: any) => {
                                    return accumulator + ((item?.price || 0) * (item?.qty || 0));
                                }, 0) : 0)}
                            </Box>
                            <Box>
                                {t('CURRENCY_CHANGE.TH')}
                            </Box>
                        </Box>
                    </Box>
                    : <></>
                }
            </Box>

            <Box display={{ md: 'flex', xs: 'block' }} alignItems={'flex-start'} marginTop={'16px'}>
                <Box width={'200px'}>
                    {t('PROMOTION.FORM.PRICE_TYPE_COST')}
                </Box>
                <Box width={{ md: '700px', xs: '100%' }}>
                    <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box>
                                <InputRadio
                                    checked={dataEdit?.promotionPriceType === 'DISCOUNT'}
                                    onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionPriceType: 'DISCOUNT', promotionPriceUnit: 'BAHT' })}
                                    disabled={pageDisabled}
                                />
                            </Box>
                            <Box>
                                {t('PROMOTION.FORM.PRICE_TYPE_DISCOUNT')}
                            </Box>
                        </Box>
                        <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'flex-start'} gap={'8px'} width={{ sm: '350px', xs: '100%' }}>
                            <Box sx={{ width: { sm: '50%', xs: '100%' }, margin: { sm: '0', xs: '0 0 16px 0' } }}>
                                <InputTextField
                                    id={'cost'}
                                    label={t('PROMOTION.FORM.COST')}
                                    required
                                    disabled={dataEdit?.promotionPriceType !== 'DISCOUNT' || pageDisabled}
                                    value={dataEdit?.promotionPriceType === 'DISCOUNT' ? dataEdit?.promotionPrice || '' : ''}
                                    onchange={(event) => {
                                        const value = event.target.value
                                        if (value?.length > 8) {
                                            return
                                        }
                                        if (event.target.value === '') {
                                            handleChangeDetailPromotion({ ...dataEdit, promotionPrice: null })
                                            return
                                        }
                                        if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                            if (dataEdit?.promotionPriceUnit === "PERCENT") {
                                                if (Number(value) <= 100) {
                                                    handleChangeDetailPromotion({ ...dataEdit, promotionPrice: value })
                                                }
                                                return
                                            }
                                            handleChangeDetailPromotion({ ...dataEdit, promotionPrice: value })

                                        }
                                    }}
                                    helperText={errorMessage.PROMOTIONPRICE}
                                />
                            </Box>
                            <Box sx={{ width: { sm: '50%', xs: '100%' } }}>
                                <AutocompleteSelect
                                    labelId='cost-unit'
                                    inputLabel={t('PROMOTION.FORM.COST_UNIT')}
                                    options={unitOp}
                                    getOptionLabel={(option) => option?.name || ''}
                                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                    required
                                    disabled={dataEdit?.promotionPriceType !== 'DISCOUNT' || pageDisabled}
                                    value={dataEdit?.promotionPriceType === 'DISCOUNT' ? unitOp.find((item) => item.value === dataEdit?.promotionPriceUnit) || '' : ''}
                                    onchange={(e, value) => handleChangeDetailPromotion({ ...dataEdit, promotionPriceUnit: value?.value, promotionPrice: (!value?.value || value?.value === 'PERCENT') ? null : dataEdit?.promotionPrice })}
                                    disableClearable
                                    errMessage={errorMessage.PROMOTIONPRICEUNIT}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'center'} justifyContent={'space-between'} marginTop={'16px'}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box>
                                <InputRadio
                                    checked={dataEdit?.promotionPriceType === 'SALEPRICE'}
                                    onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionPriceType: 'SALEPRICE', promotionPriceUnit: 'BAHT' })}
                                    disabled={!dataEdit?.promotionItems?.length || pageDisabled}
                                />
                            </Box>
                            <Box>
                                {t('PROMOTION.FORM.PRICE_TYPE_SALEPRICE')}
                            </Box>
                        </Box>
                        <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'center'} gap={'8px'} width={{ sm: '350px', xs: '100%' }}>
                            <Box sx={{ width: { sm: '50%', xs: '100%' }, margin: { sm: '0', xs: '0 0 16px 0' } }}>
                                <InputTextField
                                    id={'amount'}
                                    label={t('PROMOTION.FORM.AMOUNT')}
                                    disabled={!dataEdit?.promotionItems?.length || dataEdit?.promotionPriceType !== 'SALEPRICE' || pageDisabled}
                                    required
                                    value={dataEdit?.promotionPriceType === 'SALEPRICE' ? dataEdit?.promotionPrice || '' : ''}
                                    onchange={(event) => {
                                        const value = event.target.value
                                        if (event.target.value === '') {
                                            handleChangeDetailPromotion({ ...dataEdit, promotionPrice: null })
                                            return
                                        }
                                        if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                            handleChangeDetailPromotion({ ...dataEdit, promotionPrice: value })
                                        }
                                    }}
                                    helperText={errorMessage.PROMOTIONPRICE_}
                                />
                            </Box>
                            <Box sx={{ width: { sm: '50%', xs: '100%' } }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'flex-start'} marginTop={{ md: '16px' }}>
                <Box width={'200px'}>
                    {t('PROMOTION.FORM.USAGE')}
                </Box>
                <Box width={{ md: '700px', xs: '100%' }}>
                    <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                        <Box>
                            <InputRadio
                                checked={dataEdit?.promotionUsage === 'ALL'}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionUsage: 'ALL', })}
                                disabled={pageDisabled || dataList?.length === 1}
                            />
                        </Box>
                        <Box>
                            {t('PROMOTION.FORM.USAGE_ALL')}
                            <span>
                                <Tooltip title={<Box whiteSpace={'nowrap'} >{t('PROMOTION.FORM.USAGE_ALL_HOVER')}</Box>} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                    <InfoIcon />
                                </Tooltip>
                            </span>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                        <Box>
                            <InputRadio
                                checked={['OPERATIVE', 'OPERATIVEDF', 'OPERATIVECF', 'PRODUCT'].includes(dataEdit?.promotionUsage) || dataEdit?.promotionUsage === '1'}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionUsage: '1' })}
                                disabled={!dataEdit?.promotionItems?.length || dataEdit?.promotionItems?.length > 1 || pageDisabled}
                            />
                        </Box>
                        <Box>
                            {t('PROMOTION.FORM.USAGE_LIST')}
                            <span>
                                <Tooltip title={<Box whiteSpace={'nowrap'} >{t('PROMOTION.FORM.USAGE_LIST_HOVER')}</Box>} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }} PopperProps={{ sx: { '.MuiTooltip-tooltip': { maxWidth: '100%' } } }}>
                                    <InfoIcon />
                                </Tooltip>
                            </span>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} marginLeft={'32px'}>
                        <Box>
                            <InputRadio
                                checked={dataEdit?.promotionUsage === 'OPERATIVEDF'}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionUsage: 'OPERATIVEDF' })}
                                disabled={pageDisabled || !dataEdit?.promotionItems?.length || dataEdit?.promotionUsage === 'ALL' || (dataList?.some((item: any) => item?.group) && dataList?.some((item: any) => item?.group !== 'OPERATIVE')) || dataList?.length > 1}
                            />
                        </Box>
                        <Box>
                            {t('PROMOTION.FORM.USAGE_LIST_DF')}
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} marginLeft={'32px'}>
                        <Box>
                            <InputRadio
                                checked={dataEdit?.promotionUsage === 'OPERATIVECF'}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionUsage: 'OPERATIVECF' })}
                                disabled={pageDisabled || !dataEdit?.promotionItems?.length || dataEdit?.promotionUsage === 'ALL' || (dataList?.some((item: any) => item?.group) && dataList?.some((item: any) => item?.group !== 'OPERATIVE')) || dataList?.length > 1}
                            />
                        </Box>
                        <Box>
                            {t('PROMOTION.FORM.USAGE_LIST_CF')}
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} marginLeft={'32px'}>
                        <Box>
                            <InputRadio
                                checked={dataEdit?.promotionUsage === 'OPERATIVE'}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionUsage: 'OPERATIVE' })}
                                disabled={pageDisabled || !dataEdit?.promotionItems?.length || dataEdit?.promotionUsage === 'ALL' || (dataList?.some((item: any) => item?.group) && dataList?.some((item: any) => item?.group !== 'OPERATIVE')) || dataList?.length > 1}
                            />
                        </Box>
                        <Box>
                            {t('PROMOTION.FORM.USAGE_LIST_DF_CF')}
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} marginLeft={'32px'}>
                        <Box>
                            <InputRadio
                                checked={dataEdit?.promotionUsage === 'PRODUCT'}
                                onChange={() => handleChangeDetailPromotion({ ...dataEdit, promotionUsage: 'PRODUCT' })}
                                disabled={pageDisabled || !dataEdit?.promotionItems?.length || dataEdit?.promotionUsage === 'ALL' || dataList?.some((item: any) => item?.group === 'OPERATIVE') || dataList?.length > 1}
                            />
                        </Box>
                        <Box>
                            {t('PROMOTION.FORM.USAGE_LIST_PD')}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={{ sm: 'flex', xs: 'block' }} alignItems={'flex-start'} marginTop={{ md: '16px' }}>
                <Box width={'200px'} margin={'auto 0'}>
                    {t('PROMOTION.FORM.MINIMUM')}
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={'8px'} >
                    <InputTextField
                        label={t('PROMOTION.FORM.MINIMUM_COST')}
                        value={(dataEdit?.minimum === 0 || dataEdit?.minimum) ? dataEdit?.minimum : ''}
                        onchange={(event) => {
                            const value = event.target.value
                            if (value?.length > 8) {
                                return
                            }
                            if (event.target.value === '') {
                                handleChangeDetailPromotion({ ...dataEdit, minimum: null })
                                return
                            }
                            // && (value.length === 0 || value[0] !== '0')
                            if (/^\d*$/.test(value)) {
                                handleChangeDetailPromotion({ ...dataEdit, minimum: value })
                            }
                        }}
                        required
                        helperText={errorMessage?.MINIMUM}
                        disabled={pageDisabled}
                    />
                    <Box>
                        {t('CURRENCY_CHANGE.TH')}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default FormPromotionDiscount

type RenderDataListProps = {
    objData: any
    index: number
    dataList: any
    setDataList: (data: any, status?: boolean) => void
    errorMessage: any
    pageDisabled: boolean
}

export const RenderDataList = (props: RenderDataListProps) => {
    const { index, objData, dataList, setDataList, errorMessage, pageDisabled } = props
    const { t } = useTranslation()
    const branchIdCurrent = getBranch()

    const groupOp = [
        { name: t('PROMOTION.FORM.OPERATIVE_LIST'), group: 'OPERATIVE' },
        { name: t('PROMOTION.FORM.MEDICINE_LIST'), group: 'MEDICINE' },
        { name: t('PROMOTION.FORM.PRODUCT_LIST'), group: 'PRODUCT' },
    ]
    const [optionType, setOptionType] = useState<any>([])
    const [loadItemType, setLoadItemType] = useState<boolean>(false)

    const [optionItem, setOptionItem] = useState<any>([])
    const [loadItem, setLoadItem] = useState<boolean>(false)

    const newIndex = index + 1
    const { group, qty } = objData

    const renderGroup = useCallback(() => {
        return groupOp?.find((item) => item?.group === group) || null
    }, [group])

    const loadDataListType = async (search?: string) => {
        setLoadItemType(true)
        if (group === 'OPERATIVE') {
            const res = await OperativeTypeApi.findAll({ pageLimit: 30, search: search, status: 'ACTIVE' })
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    return { itemTypeName: value.operativeTypeName, itemTypeId: value.operativeTypeId, }
                })
                setOptionType(newData)
            }
        }
        if (group === 'MEDICINE' || group === 'PRODUCT') {
            let condition: any = { group: group, pageLimit: 10, status: 'ACTIVE' }
            if (search) condition = { ...condition, search: search || '' }
            const res = await ProductTypesApi.findAll(condition)
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    return { itemTypeName: value.productTypeName, itemTypeId: value.productTypeId }
                })
                setOptionType(newData)
            }
        }
        setLoadItemType(false)
    }

    const loadDataListItem = async (search?: string) => {
        setLoadItem(true)
        if (group === 'OPERATIVE') {
            const res = await OperativeApi.findAll({ pageLimit: 30, search: search, status: 'ACTIVE', operativeTypeId: objData?.itemTypeId })
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    const newPrice = value?.operativePrice?.length ? value?.operativePrice.find((item: any) => item?.branchId === branchIdCurrent)?.price || 0 : value?.salePrice || 0
                    return { itemName: value.operativeName, itemId: value.operativeId, price: newPrice }
                })
                setOptionItem(newData)
            }
        }
        if (group === 'MEDICINE' || group === 'PRODUCT') {
            const condition: any = { group: group, status: 'ACTIVE', page: 1, pageLimit: 10, search: search || '', itemTypeId: objData?.itemTypeId }
            const res = await ProductsApi.findAll(condition)
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    return { itemName: value.productName, itemId: value.productId, price: value?.salePrice || 0 }
                })
                setOptionItem(newData)
            }
        }
        setLoadItem(false)
    }

    const handleChangeDataList = (index: number, key: string, value: any) => {
        const newDataList = [...dataList]
        if (key === 'group') {
            newDataList[index] = { ...newDataList[index], [key]: value, itemTypeId: null, itemTypeName: null, itemId: null, itemName: null, price: 0, qty: 0 }
        }
        if (key === 'itemType') {
            newDataList[index] = { ...newDataList[index], itemTypeId: value?.itemTypeId, itemTypeName: value?.itemTypeName, itemId: null, itemName: null, price: 0, qty: 0 }
        }
        if (key === 'item') {
            newDataList[index] = { ...newDataList[index], ...value, qty: 0 }
        }
        if (key === 'qty') {
            newDataList[index] = { ...newDataList[index], qty: Number(value) }
        }

        setDataList(newDataList)
    }

    const onDisabledItem = (id: number) => {
        if (id && dataList?.length) {
            return dataList.some((item: any) => item?.itemId === id)
        }
        return false
    }

    const objRenderData = {
        key: index,
        id: index,
        obj: objData,
        columns: [
            { option: 'TEXT', align: 'center', label: newIndex },
            {
                option: 'COMPONENT',
                class: 'align-content-start',
                component: (
                    <Box sx={{ '.MuiOutlinedInput-root': { paddingRight: '35px !important' } }}>
                        <AutocompleteSelect
                            labelId={`group-list-${index}`}
                            inputLabel={t('PROMOTION.FORM.GROUP_LIST')}
                            noOptionsText={t('NOT_FOUND')}
                            getOptionLabel={(option) => option?.name || ''}
                            options={groupOp}
                            renderOption={(props, option) => {
                                return (
                                    <Box {...props}>
                                        {option?.name}
                                    </Box>
                                )
                            }}
                            onchange={(e, value) => {
                                const newValue = value?.group || null
                                handleChangeDataList(index, 'group', newValue)
                            }}
                            value={renderGroup()}
                            disableClearable
                            required
                            errMessage={(errorMessage?.PROMOTIONITEMS_GROUP && !group) ? errorMessage?.PROMOTIONITEMS_GROUP || '' : ''}
                            disabled={pageDisabled}
                        />
                    </Box>
                )
            },
            {
                option: 'COMPONENT',
                class: 'align-content-start',
                component: (
                    <Box sx={{ '.MuiOutlinedInput-root': { paddingRight: '35px !important' } }}>
                        <AutocompleteSelect
                            labelId={`type-list-${index}`}
                            inputLabel={t('PROMOTION.FORM.ITEMTYPE_LIST')}
                            noOptionsText={t('NOT_FOUND')}
                            getOptionLabel={(option) => option?.itemTypeName || ''}
                            options={optionType}
                            renderOption={(props, option) => {
                                return (
                                    <Box {...props} >
                                        {option?.itemTypeName}
                                    </Box>
                                )
                            }}
                            loading={loadItemType}
                            onInputChange={(e, value) => loadDataListType(value)}
                            onOpen={(event, value) => group && loadDataListType()}
                            onClose={(event, value) => group && loadDataListType()}
                            onchange={(e, value) => {
                                const newValue = value || null
                                handleChangeDataList(index, 'itemType', newValue)
                            }}
                            value={objData}
                            disabled={!group || pageDisabled}
                            disableClearable
                            required
                            errMessage={(!objData?.itemTypeId && errorMessage?.PROMOTIONITEMS_ITEM) ? t(`PROMOTION.MESSAGE.ERROR.PROMOTIONITEMS_TYPE`) : ''}
                        />
                    </Box>
                )
            },
            {
                option: 'COMPONENT',
                class: 'align-content-start',
                component: (
                    <Box sx={{ '.MuiOutlinedInput-root': { paddingRight: '35px !important' } }}>
                        <AutocompleteSelect
                            labelId={`list-${index}`}
                            inputLabel={t('PROMOTION.FORM.ITEM_LIST')}
                            noOptionsText={t('NOT_FOUND')}
                            getOptionLabel={(option) => option?.itemName || ''}
                            options={optionItem}
                            renderOption={(props, option) => {
                                const dis = onDisabledItem(option?.itemId || 0) ? { pointerEvents: 'none', opacity: '0.6' } : {}
                                return (
                                    <Box {...props} sx={dis}>
                                        {option?.itemName}
                                    </Box>
                                )
                            }}
                            loading={loadItem}
                            onInputChange={(e, value) => loadDataListItem(value)}
                            onOpen={(event, value) => (group && objData?.itemTypeId) && loadDataListItem()}
                            onClose={(event, value) => (group && objData?.itemTypeId) && loadDataListItem()}
                            onchange={(e, value) => {
                                const newValue = value || null
                                handleChangeDataList(index, 'item', newValue)
                            }}
                            value={objData}
                            disabled={!objData?.itemTypeId || pageDisabled}
                            disableClearable
                            required
                            errMessage={(errorMessage?.PROMOTIONITEMS_ITEM && !objData?.itemId) ? errorMessage?.PROMOTIONITEMS_ITEM || '' : ''}
                        />
                    </Box>
                )
            },
            {
                option: 'COMPONENT',
                class: 'align-content-start',
                component: (
                    <Box sx={{ '.MuiOutlinedInput-root': { paddingRight: '35px !important' }, '.css-97j6co span': { textWrap: 'nowrap' } }}>
                        <InputTextField
                            label={t('PROMOTION.FORM.COUNT_LIST')}
                            value={qty || ''}
                            onchange={(event) => {
                                const value = event.target.value
                                if (value?.length > 6) {
                                    return
                                }
                                if (event.target.value === '') {
                                    handleChangeDataList(index, 'qty', null)
                                    return
                                }
                                if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                    handleChangeDataList(index, 'qty', value)
                                }
                            }}
                            disabled={!objData?.itemId || pageDisabled}
                            required
                            helperText={(errorMessage?.PROMOTIONITEMS_QTY && !qty) ? errorMessage?.PROMOTIONITEMS_QTY || '' : ''}
                        />
                    </Box>
                )
            },
            {
                option: 'COMPONENT',
                component: (
                    <Box sx={{ height: '43px', textAlign: 'center', button: { filter: pageDisabled ? 'grayscale(1)' : '', opacity: pageDisabled ? '.5' : '1' } }}>
                        <IconButton onClick={() => setDataList(dataList.filter((item: any, num: number) => num !== index), true)} disabled={pageDisabled}>
                            <img src={icons.btnTrash} alt="trash" />
                        </IconButton>
                    </Box>
                )
            },
        ]
    }

    return (
        <TableRowCommon {...objRenderData} />
    )
}