import { Box, Button, IconButton } from '@mui/material'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { toBuddhistYear } from 'utils/app.utils'
import EventIcon from '@mui/icons-material/Event';
import ClearIcon from '@mui/icons-material/Clear'
import _ from 'lodash'
import moment from 'moment'
import DatePicker, { DateObject } from "react-multi-date-picker"
import useCalendarFormat, { thai_format_language, thai_calendar } from 'constants/configDatePicker'
import InputTextField from 'component/Input/InputTextField'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react';

export const CustomDatePicker = styled(DatePicker)<{ menu?: 'appointment' | '', views?: 'day' | 'month' | 'year' | 'week' | '' }>(({ theme, menu, views }) => {
    let divStyle = {}
    if (menu && menu === "appointment") {
        divStyle = {
            [theme.breakpoints.down('sm')]: {
                transform: 'translate(28.0003px, 123px)!important',
            },
            [theme.breakpoints.up('sm')]: {
                transform: 'translate(31.0003px, 130px) !important',
            },
            [theme.breakpoints.up('lg')]: {
                transform: 'translate(110.031px, 163px) !important',
            },
            [theme.breakpoints.up('xl')]: {
                transform: 'translate(110.031px, 163px) !important',
            }

        }
    }

    return ({
        zIndex: `2020 !important`,
        '&::after': {
            borderColor: colors.lightGray
        },
        '& + div': {
            ...divStyle,
            zIndex: `2020 !important`,
            '.rmdp-wrapper': {
                borderColor: colors.lightGray,
                '.rmdp-calendar': {
                    paddingBottom: 0
                }
            },
            '.rmdp-header': {
                '.rmdp-arrow-container': {
                    backgroundColor: `${colors.transparent} !important`,
                    boxShadow: 'none !important',
                    i: {
                        borderColor: colors.themeSecondColor
                    },
                    '&.disabled': {
                        i: {
                            borderColor: colors.disabledGray
                        }
                    }
                },
                '.rmdp-header-values': {
                    span: {
                        fontWeight: 500,
                        transition: 'all .3s ease',
                        '&:hover': {
                            color: colors.themeSecondColor
                        }
                    }
                }
            },
            '.rmdp-day-picker, .rmdp-month-picker, .rmdp-year-picker': {
                '.rmdp-week, .rmdp-ym': {
                    '.rmdp-week-day': {
                        color: colors.textPrimary,
                        fontSize: 14,
                        width: `32px`,
                        height: `32px`,
                    },
                    '.rmdp-day': {
                        width: `32px`,
                        height: `32px`,
                        span: {
                            boxShadow: 'none',
                            color: colors.textPrimary,
                            borderRadius: 4,
                            fontSize: 13,
                            '&:hover': {
                                backgroundColor: `${colors.extraLightGray} !important`
                            }
                        },
                        '&.rmdp-today': {
                            span: {
                                backgroundColor: colors.transparent,
                                border: `1px solid ${colors.themeSecondColor}`
                            }
                        },
                        '&.rmdp-selected': {
                            span: {
                                backgroundColor: `${colors.themeSecondColor} !important`,
                                color: `${colors.white} !important`
                            }
                        },
                        '&.rmdp-deactive, &.rmdp-disabled': {
                            span: {
                                color: colors.disabledLightGray
                            }
                        },
                        '&.rmdp-range': {
                            backgroundColor: colors.themeSecondColor,
                            boxShadow: 'none',
                            span: {
                                color: colors.white,
                                '&:hover': {
                                    backgroundColor: `${colors.transparent} !important`
                                }
                            },
                            '&.start': {
                                borderTopLeftRadius: 4,
                                borderBottomLeftRadius: 4
                            },
                            '&.end': {
                                borderTopRightRadius: 4,
                                borderBottomRightRadius: 4
                            }
                        },
                        '&.rmdp-disabled': {
                            pointerEvents: 'none'
                        }
                    }
                }
            },
        },
        '&.week-view': {
            '.rmdp-day-picker': {
                '.rmdp-week': {
                    '.rmdp-selected': {
                        '&:not(.rmdp-deactive)': {
                            backgroundColor: colors.themeSecondColor,
                        },
                        '&.rmdp-deactive': {
                            backgroundColor: colors.extraLightGray,
                            span: {
                                backgroundColor: `${colors.transparent} !important`,
                                color: `${colors.disabledLightGray} !important`
                            }
                        }
                    }
                }
            }
        },
        "&.show-date-appointment": {
            // marginLeft: -30,
            [theme.breakpoints.down('sm')]: {
                marginLeft: `-45px !important`
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: `25px`
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: -15
            }
        }
    })
})

export const CustomBoxDatePicker = styled(Box)<{ pointer?: string }>(({ theme, pointer }) => ({
    '.icon-calendar': {
        color: colors.themeSecondColor,
        '&.disabled': {
            color: colors.disabledGray
        }
    },
    '.btn-calendar-picker': {
        backgroundColor: `${colors.transparent} !important`,
        '.MuiFormControl-root': {
            pointerEvents: pointer === "auto" ? pointer : 'none',
            '.MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
                paddingRight: '36px !important'
            },
            '.MuiInputBase-input': {
                paddingRight: '44px !important'
            }
        },
        '.icon-clear-value': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 14,
            color: colors.black60
        },
        '.icon-calendar': {
            position: 'absolute',
            top: '50%',
            right: 14,
            transform: 'translateY(-50%)',
            '&.disabled': {
                color: colors.black25
            }
        },
        '&.date-picker-error': {
            '.MuiFormHelperText-root.Mui-error': {
                position: 'absolute',
                bottom: '-24px'
            },
        },
    }
}))

const CustomTodayButton = styled(Button)(({ theme }) => ({
    padding: '5px',
    backgroundColor: `${colors.transparent} !important`,
    borderTop: `1px solid ${colors.lightGray}`,
    borderRadius: 0,
    color: colors.themeSecondColor,
    '&:hover': {
        textDecoration: 'underline'
    }
}))

export enum PICKER_VIEWS {
    DAY = 'day',
    MONTH = 'month',
    YEAR = 'year',
    WEEK = 'week'
}

interface DatePickerType {
    value: any
    onchange: (val: any) => void
    dateFormat?: string
    required?: boolean
    key?: string
    inputName?: string
    label?: string
    helperText?: any
    placeholder?: string
    inputHeight?: number
    disabled?: boolean
    allowClear?: boolean
    onClear?: (val: any) => void
    disabledTextField?: boolean
    views?: PICKER_VIEWS
    disableFuture?: boolean
    disablePast?: boolean
    hideTodayButton?: boolean
    position?: any | ''
    menu?: any | ''
    datePublic?: any
    pointerEvent?: string
    inputEdit?: boolean
    hideOnScroll?: any
    minDate?: any
    maxDate?: any
    disabledToDay?: boolean
}


export default function InputSecondNewDatePicker(props: DatePickerType) {
    const { t, i18n } = useTranslation()
    const calendarFormat = useCalendarFormat()

    const renderDayValue = (date: string) => {

        if (props.views === PICKER_VIEWS.WEEK) {
            const isDate = moment(date)
            const startWeek = isDate.weekday(1)
            const week = []
            for (let day = 0; day <= 6; day++) {
                week.push(moment(startWeek).add(day, 'days').toDate())
            }
            return week
        }
        if (!props.inputEdit) {
            return date ? new Date(date) : ''
        }
        const dateObject = new Date(setDate(date))
        if (date.length === 10 && dateObject instanceof Date && !isNaN(dateObject.getTime())) {
            return date ? dateObject : ''
        } else {
            return date
        }

    }

    const renderValue = (date: string, dateFormat: string, view: PICKER_VIEWS | null) => {
        let format = 'DD/MM/YYYY'
        if (dateFormat) format = dateFormat
        else if (view === PICKER_VIEWS.MONTH) format = 'MMMM YYYY'
        else if (view === PICKER_VIEWS.YEAR) format = 'YYYY'
        if (!props.inputEdit) {
            return date ? toBuddhistYear(moment(date), format) : ''
        }
        return date ? toBuddhistYear(moment(setDate(date)), format) : ''

    }

    const setDate = (date: any) => {
        if (date.length === 10) {
            const set = date.split("/")
            if (set.length === 3) {
                const newDate = `${set[2] < 2500 ? set[2] : (set[2] - 543)}-${set[1]}-${set[0]}`
                return newDate
            } else {
                return date
            }
        } else {
            return date
        }

    }

    const isNumber = (num: any) => /\d/.test(num);

    const handleInputDate = (val: any) => {
        if (props.onchange) {
            const result = [];

            if (val && val.length) {
                for (let i = 0; i < val.length; i++) {
                    const current = val[i];
                    if (i === 2 && current !== "/") {
                        result.push("/");
                    } else if (i === 5 && current !== "/") {
                        result.push("/");
                    }

                    if ((isNumber(current) && i < 10) || i === 2 || i === 5) {
                        result.push(current);
                    }
                }
            }
            props.onchange(result.join(""))
        }
    }

    return (
        <CustomDatePicker
            {...props?.position || ''}
            menu={props?.menu || ''}
            hideOnScroll={props.hideOnScroll}
            portal
            locale={calendarFormat.format_language}
            calendar={calendarFormat.calendar}
            disabled={props.disabled}
            value={renderDayValue(props.value)}
            onChange={(value: any) => {
                const date = props.views === PICKER_VIEWS.WEEK ? _.last(value) : value
                const era = i18n.language === 'th' ? 543 : 0
                if (props.onchange) {
                    if (props.views === PICKER_VIEWS.WEEK) props.onchange(date.toDate())
                    else props.onchange(`${parseInt(date.format("YYYY")) - era}/${date.format("MM")}/${date.format("DD")}`)
                }
            }}
            containerClassName={`position-relative w-100`}
            className={`${props.views === PICKER_VIEWS.WEEK ? 'week-view' : ''} ${props?.position?.className || ''}`}
            style={(props?.position?.style || {})}
            containerStyle={{ marginBottom: props.helperText ? '24px' : 0 }}
            onlyMonthPicker={props.views === PICKER_VIEWS.MONTH}
            onlyYearPicker={props.views === PICKER_VIEWS.YEAR}
            onOpenPickNewDate={false}
            shadow={false}
            showOtherDays
            minDate={props.disablePast ? props?.minDate || new Date(props.datePublic) : ''}
            maxDate={(props.disableFuture && props?.maxDate) ? new DateObject(props?.maxDate) : ''}
            render={(value: any, openCalendar: any) => {
                return (
                    <CustomBoxDatePicker className='position-relative w-100' pointer={props?.pointerEvent || ''}>
                        {!props.disabledTextField && (
                            <Button
                                onClick={openCalendar}
                                disabled={props.disabled}
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                className={`btn-calendar-picker position-relative p-0 w-100 ${props.helperText ? 'date-picker-error' : ''}`}
                            >
                                <InputTextField
                                    required={props?.required || false}
                                    key={props.key}
                                    name={props.inputName || ''}
                                    label={props.label}
                                    helperText={props.helperText}
                                    placeholder={props?.placeholder || ''}
                                    inputHeight={props?.inputHeight || 43}
                                    disabled={props.disabled}
                                    value={renderValue(props.value, props.dateFormat || '', props.views || null)}
                                    style={{ pointerEvents: props.allowClear && props.value ? 'none' : 'auto' }}
                                    onchange={(e): any => {
                                        if (props?.inputEdit) {
                                            handleInputDate(e.target.value)
                                        }
                                    }}
                                />
                                {(props.allowClear && props.value) &&
                                    <ClearIcon className='icon-clear-value cursor-pointer' onClick={props.onClear} /> ||
                                    <EventIcon className={`icon-calendar ${props.disabled ? 'disabled' : ''}`} />
                                }
                            </Button>
                        ) || (
                                <IconButton onClick={openCalendar} disabled={props.disabled} className={`icon-calendar ${props.disabled ? 'disabled' : ''}`}>
                                    <EventIcon className={`icon-calendar ${props.disabled ? 'disabled' : ''}`} />
                                </IconButton>
                            )}
                    </CustomBoxDatePicker>
                )
            }}>
            {!props.hideTodayButton && (
                <CustomTodayButton variant="text" onClick={() => props.onchange && props.onchange(moment().toDate())} className='w-100' disabled={props?.disabledToDay}>{t('BUTTON.TODAY')}</CustomTodayButton>
            ) || <></>}
        </CustomDatePicker>
    )
}
