import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MemberData {
      rankId: number;
      rankCode: string;
      rankName: string;
      rankNameEn: string;
      color: string;
      status: string;
      default: string;
      totalPaid: string | number;
      serviceVisits: string | number;
      updatedBy: string;
      updatedAt: string;
      createdBy: string;
      createdAt: string;

}

interface MemberState {
      members: (MemberData | null)[];
      newMember: MemberData | null;
      currentMember: MemberData | null;
      defaultMember: MemberData | null;
}

const initialState: MemberState = {
      members: [],
      newMember: null,
      currentMember: null,
      defaultMember: null,
};

export const memberSlice = createSlice({
      name: 'member',
      initialState,
      reducers: {
            appendElement: (state, action: any) => {
                  state.members[action.payload.index] = action.payload.value;
            },
            removeElement: (state, action: PayloadAction<{ index: number }>) => {
                  state.members[action.payload.index] = null;
            },
            updateMember: (state, action: PayloadAction<{ index: number; data: Partial<MemberData> }>) => {
                  if (state.members[action.payload.index]) {
                        state.members[action.payload.index] = {
                              ...state.members[action.payload.index],
                              ...action.payload.data
                        } as MemberData;
                  }
            },
            setNewMember: (state, action: PayloadAction<MemberData | null>) => {
                  state.newMember = action.payload;
            },
            setCurrentMember: (state, action: PayloadAction<MemberData | null>) => {
                  state.currentMember = action.payload;
            },
            setDefaultMember: (state, action: PayloadAction<MemberData | null>) => {
                  state.defaultMember = action.payload;
            },
            updateNewMember: (state, action: PayloadAction<Partial<MemberData>>) => {
                  if (state.newMember) {
                        state.newMember = { ...state.newMember, ...action.payload };
                  }
            },
            updateCurrentMember: (state, action: PayloadAction<Partial<MemberData>>) => {
                  if (state.currentMember) {
                        state.currentMember = { ...state.currentMember, ...action.payload };
                  }
            },
      }
})

export const {
      appendElement,
      removeElement,
      updateMember,
      setNewMember,
      setCurrentMember,
      setDefaultMember,
      updateNewMember,
      updateCurrentMember
} = memberSlice.actions

export default memberSlice.reducer

export const selectAllMembers = (state: any) => state.member.members;
export const selectMemberByIndex = (state: any, index: number) => state.member.members[index];
export const selectNewMember = (state: any) => state.member.newMember;
export const selectCurrentMember = (state: any) => state.member.currentMember;
