import { Box, Typography, Avatar, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import LabelRanking from 'component/LabelRanking'

/** UTILS */
import { memberTypeOutline, numberFormat, checkPackage } from 'utils/app.utils'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { PACKAGE_ADDONS } from 'constants/package'

/** STYLE */
import { styled } from '@mui/material/styles'
import { imgs } from 'constants/images'
import { fileUrl } from 'api/api'
import _ from 'lodash'

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 49,
  height: 49,
  [theme.breakpoints.up('xs')]: {
    width: 56,
    height: 56
  }
}))

const Balance = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  }
}))

type InfoProps = {
  patient: any
  advance: number
  balance: number
}

export default function Info(props: InfoProps) {
  const { t } = useTranslation()
  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  const checkEvenByIndex = (index: number) => {
    if (index % 2 === 0) return 'justify-content-start'
    else return 'justify-content-end'
  }

  return (
    <Box className='d-flex px-md-2 px-xl-3 pb-3 mb-3' sx={{ borderBottom: `1px solid ${colors.lightGray}` }}>
      <ProfileAvatar alt="profile" src={renderImage(props.patient.profile)} sx={{ border: `3px solid ${memberTypeOutline(props.patient.rank)}`, backgroundColor: colors.white }}>
        <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
      </ProfileAvatar>
      <Box className='ml-2 ml-sm-3 w-100 overflow-hidden'>
        <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
          <Typography className="d-inline-block align-middle text-ellipsis" sx={{ fontSize: { sm: '18px' }, fontWeight: 600, color: colors.themeMainColor }}>
            {props.patient.branchCnNumber ? `${props.patient.branchCnNumber}` : ''} | {props.patient.firstname} {props.patient.lastname}
          </Typography>
          <Box className="d-inline-block ml-sm-2">
            <LabelRanking rankId={props.patient.rankId} />
          </Box>
        </Box>
        <Box className='pt-2'>
          <Box className='d-flex justify-content-between'>
            <Balance className='pr-1'>{t('PAYMENT.FORM_PAYMENT.ADVANCE_BALANCE')}</Balance>
            <Balance><span className="pr-sm-2">{numberFormat(props.advance)}</span> {t('CURRENCY_CHANGE.TH')}</Balance>
          </Box>
          <Box className='d-flex justify-content-between'>
            <Balance className='pr-1' >{t('คงเหลือ')}</Balance>
            <Balance  ><span className="pr-sm-2">{numberFormat(props.balance)}</span> {t('CURRENCY_CHANGE.TH')}</Balance>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
