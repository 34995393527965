import { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Grid, Box, Divider, Typography, ClickAwayListener } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { routeName } from 'routes/routes-name';
import HeaderLabel from 'component/CardCustom/HeaderLabel';
import { colors } from 'constants/theme';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ButtonCustom from 'component/Button/ButtonCustom';
import { isCreate, isDelete, isUpdate } from 'utils/verification.utils';
import TableCustom from 'component/Table/TableCustom';
import TableRowCommon from 'component/Table/TableRowCommon';
import InputCheckbox from 'component/Input/InputCheckbox';
import { useDispatch } from 'react-redux';
import { ColorTag } from 'features/setting/tag/style';
import MemberColorManage from './member-color-manage';
import HelperText from 'component/Input/HelperText';
import MemberManagementApi, { MemberManagementface } from 'api/setting/member.api';
import { getBranch } from 'utils/app.utils';
import SelectInput from 'new-components/inputs/select-input';
import { useLocation, useHistory } from 'react-router-dom';
import ClinicApi from 'api/master/clinic.api';
import AlertModal from 'new-components/modal/alert-modal';
import { imgs } from 'constants/images';
import Loading from 'component/Loading';
import { notiError, notiSuccess } from 'component/notifications/notifications';
import { KEY_STORAGE } from 'constants/common';
import PageModal from 'new-components/modal/page-modal';
import TextInput from 'new-components/inputs/text-input';
import TextAreaInput from 'new-components/inputs/text-area-input';

const permissions = {
      isCreate: isCreate(),
      isDelete: isDelete(),
      isUpdate: isUpdate(),
};

export default function FormMemberManagement() {
      const { t } = useTranslation();
      const location = useLocation();
      const { autoIncrement, defaultRankId }: any = location.state;
      const dispatch = useDispatch();
      const history = useHistory();
      const [rankId, setRankId] = useState<number | null>(null);
      const [rankCode, setRankCode] = useState('');
      const [rankName, setRankName] = useState('');
      const [rankNameEn, setRankNameEn] = useState('');
      const [rankNameDefault, setRankNameDefault] = useState('');
      const [rankColor, setRankColor] = useState('');
      const [totalPaid, setTotalPaid] = useState<number | string>('');
      const [serviceVisits, setServiceVisits] = useState<number | string>('');
      const [errorRank, setErrorRank] = useState<boolean>(false);
      const [popSelectColor, setPopSelectColor] = useState<boolean>(false);
      const [memberTypes, setMemberTypes] = useState<any[]>([]);
      const [memberType, setMemberType] = useState<any>({});

      const [detail, setDetail] = useState('')


      /** PAGINATION  */
      const [page, setPage] = useState(1);
      const [pageLimit, setPageLimit] = useState(10);
      const [rowCount, setRowCount] = useState(0);
      const [sortType, setSortType] = useState('ASC');
      const [sortBy, setSortBy] = useState<any>(autoIncrement);
      const [checkedIndex, setCheckedIndex] = useState<any>(defaultRankId);
      const [dfRankId, setDfRankId] = useState<any>(defaultRankId);
      const [ranks, setRanks] = useState<any[]>([]);
      const [openModal, setOpenModal] = useState(false);
      const [useStatus, setUseStatus] = useState('');
      const [isDisabled, setIsDisabled] = useState(true);
      const [loading, setLoading] = useState(false);
      const branchId = getBranch();

      const [isOpenModal, setIsOpenModal] = useState(false)

      const loadData = useCallback(async () => {
            try {
                  setLoading(true);
                  let condition: any = {};
                  if (page) condition = { ...condition, page: page };
                  if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
                  if (sortBy) condition = { ...condition, sortBy: sortBy };
                  if (sortType) condition = { ...condition, sortType: sortType };
                  const res = await MemberManagementApi.findAll(condition);
                  if (res.status === 200) {
                        localStorage.setItem(KEY_STORAGE.MEMBER_RANKING_TYPE, JSON.stringify(res.data))
                        setRowCount(parseInt(res.headers['x-total'], 10));
                        setRanks(res.data);
                        setDfRankId(defaultRankId)
                        const defaultRank = res.data.find((e: any) => e.rankId === defaultRankId);
                        setRankNameDefault(defaultRank.rankNameEn);
                        setCheckedIndex(defaultRank.rankId);
                        setIsDisabled(true)
                  }
                  setLoading(false);
            } catch (error) {
                  console.error('Error loading data:', error);
                  setLoading(true);
            }
      }, [page, pageLimit, sortBy, sortType]);

      useEffect(() => {
            loadData();
      }, [loadData]);

      const onSubmit = async () => {
            if (!rankName || !rankColor || rankColor === '#' || !rankCode) {
                  setErrorRank(true);
                  return;
            }

            if (sortBy === 'VISIT_COUNT' ? serviceVisits === '' : totalPaid === '') {
                  setErrorRank(true);
                  return;
            }

            const newData: MemberManagementface = {
                  rankCode: rankCode,
                  rankName: rankName,
                  rankNameEn: rankName,
                  color: rankColor,
                  totalPaid: Number(totalPaid),
                  serviceVisits: Number(serviceVisits),
                  detail: detail
            };
            if (rankId) {
                  await MemberManagementApi.update(rankId, newData)
                        .then((res: any) => {
                              setIsOpenModal(false)
                              if (res.status === 200) {
                                    notiSuccess(t(`MEMBER.MESSAGE.SUCCESS.UPDATE`));
                                    loadData();
                                    resetForm()
                              } else notiError(t('MEMBER.MESSAGE.ERROR'));
                        })
                        .catch((error) => {
                              notiError(t(`MEMBER.MESSAGE.ALERT.${error?.response?.data?.message}`));
                        });
            } else {
                  await MemberManagementApi.create(newData)
                        .then((res: any) => {
                              setIsOpenModal(false)
                              if (res.status === 201) {
                                    notiSuccess(t(`MEMBER.MESSAGE.SUCCESS.CREATE`));
                                    loadData();
                                    resetForm()
                              } else {
                                    notiError(t('MEMBER.MESSAGE.ERROR'));
                              }
                        })
                        .catch((error) => {
                              const errorMessage = error?.response?.data?.message;
                              notiError(t(`MEMBER.MESSAGE.ALERT.${errorMessage}`));
                        });
            }
      };

      const hasDuplicate = (name: string) => {
            const arr: any[] = [];
            for (const rank of ranks) {
                  if (Number(rank[name]) === 0) return true;
                  arr.push(Number(rank[name]));
            }
            return arr.some((item, index) => arr.indexOf(item) !== index);
      };

      const updateAutoIncremet = async () => {
            await ClinicApi.updateAutoIncremet({ autoIncrement: sortBy, defaultRankId: checkedIndex }, branchId)
                  .then((res: any) => {
                        if (res.status === 200) {
                              history.push(routeName.memberManagement)
                              notiSuccess(t('MEMBER.MESSAGE.SUCCESS.UPDATE_STATUS'));
                        } else {
                              notiError(t('MEMBER.MESSAGE.ERROR'));
                        }
                  })
      }


      const submit = async () => {
            try {
                  setOpenModal(false);
                  if (!isDisabled) {
                        notiError(t('MEMBER.MESSAGE.ALERT.HAS_DEFAULT'));
                        return
                  }
                  if (sortBy === 'NO_SETTING') updateAutoIncremet()
                  else {
                        const fieldToCheck = sortBy === 'TOTAL_SPENDING' ? 'totalPaid' : 'serviceVisits';
                        if (hasDuplicate(fieldToCheck)) {
                              notiError(t('MEMBER.MESSAGE.ALERT.HAS_DOUBLY'));
                        } else updateAutoIncremet()
                  }
            } catch (e) {
                  setOpenModal(false);
                  notiError(t('MEMBER.MESSAGE.ERROR'));
            }
      };

      const onEdit = (data: any) => {
            const { rankId, rankCode, rankName, rankNameEn, color, totalPaid, serviceVisits, detail } = data;
            setRankId(rankId);
            setRankCode(rankCode);
            setRankName(rankName);
            setRankNameEn(rankNameEn);
            setRankColor(color);
            setDetail(detail)
            setTotalPaid(Number(totalPaid) === 0 ? '' : totalPaid);
            setServiceVisits(Number(serviceVisits) === 0 ? '' : serviceVisits);
            setIsOpenModal(true)
      };

      const onDelete = () => {
            if (rankId) {
                  MemberManagementApi.remove(rankId)
                        .then((resp) => {
                              if (resp.status === 200) {
                                    setOpenModal(false);
                                    resetForm()
                                    notiSuccess(t('MEMBER.MESSAGE.SUCCESS.DELETE'));
                                    loadData();
                              } else notiError(t('MEMBER.MESSAGE.ERROR'));
                        })
                        .catch((e) => notiError(t('MEMBER.MESSAGE.ERROR')));
            }
      };

      const onChange = (evant?: string) => {
            if (evant) {
                  setLoading(false);
                  setSortBy(evant);
                  setUseStatus('CHANGE');
                  setIsDisabled(true)
                  loadData()
            } else notiError(t('MEMBER.MESSAGE.ERROR'));
      };



      const headCells = [
            { id: 'no', disablePadding: false, label: '#' },
            { id: 'rankCode', disablePadding: false, label: t('MEMBER.TABLE.CELL.MEMBER_ID') },
            { id: 'rankName', disablePadding: false, label: t('MEMBER.TABLE.CELL.MEMBER_NAME') },
            { id: 'color', disablePadding: false, label: t('MEMBER.TABLE.CELL.COLOR') },
            { id: 'total', disablePadding: false, label: t('MEMBER.TABLE.CELL.TOTAL_AMOUNT') },
            { id: 'defaults', disablePadding: false, label: t('MEMBER.TABLE.CELL.IS_DEFAULT') },
            { id: 'detail', disablePadding: false, label: t('MEMBER.TABLE.CELL.DETAIL') },
            { id: 'updatedBy', disablePadding: false, label: t('MEMBER.TABLE.CELL.UPDATED_BY') },
            { id: 'action', disablePadding: false, label: 'Action' },
      ];

      const renderData = (objData: any, no: number) => {
            no = page * pageLimit - pageLimit + no + 1;
            const { rankId, rankCode, rankNameEn, color, totalPaid, serviceVisits, updatedBy, updatedAt, detail } = objData;
            const mappings: { [key: string]: string } = {
                  'TOTAL_SPENDING': `${totalPaid} ${t('MEMBER.UNIT.BATH')}`,
                  'VISIT_COUNT': `${serviceVisits} ${t('MEMBER.UNIT.TIME')}`,
            };

            const objRenderData = {
                  key: rankId,
                  id: rankId,
                  obj: objData,
                  columns: [
                        { option: 'TEXT', align: 'center', label: no },
                        { option: 'TEXT', align: 'left', label: rankCode },
                        { option: 'TEXT', align: 'left', label: rankNameEn },
                        {
                              option: 'COMPONENT',
                              align: 'left',
                              component: (
                                    <Box sx={{ background: `${color}`, height: '40px', width: '200px', border: '1px solid #C5C5C5' }} />
                              ),
                        },
                        { option: 'TEXT', align: 'right', label: mappings[sortBy] || '-' },
                        {
                              option: 'COMPONENT',
                              align: 'center',
                              component: (
                                    <Box>
                                          <InputCheckbox
                                                disabled={checkedIndex === rankId ? false : isDisabled}
                                                checked={checkedIndex === rankId}
                                                onChange={(e) => {
                                                      const isChecked = e.target.checked;
                                                      setCheckedIndex(isChecked ? rankId : null);
                                                      setIsDisabled(isChecked);
                                                      setDfRankId(!isChecked ? defaultRankId : null)
                                                }}
                                                value={checkedIndex === no ? '1' : '0'}
                                          />
                                    </Box>
                              ),
                        },
                        {
                              option: 'COMPONENT', class: '', component: (
                                    <Typography sx={{ textOverflow: 'ellipsis', width: '300px' }}>{detail}</Typography>
                              )
                        },
                        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                        {
                              option: 'ACTION',
                              align: 'center',
                              label: 'action',
                              values: [
                                    { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
                                    { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: checkedIndex !== rankId && dfRankId !== rankId ? permissions.isDelete.disabled : true },
                              ],
                        },
                  ],
            };
            return (
                  <TableRowCommon
                        {...objRenderData}
                        onedit={() => onEdit(objRenderData.obj)}
                        ondelete={() => handleModal('DELETED', objRenderData.obj)}
                  />
            );
      };

      const onRequestSort = (sortByVal: any, sortTypeVal: string) => {
            setSortType(sortTypeVal);
            setSortBy(sortByVal);
            loadData();
      };

      const handleModal = (valueStatus: string, obj?: any) => {
            if (valueStatus === 'DELETED') {
                  setRankId(obj.rankId);
                  setRankName(obj.rankName);
                  setRankNameEn(obj.rankNameEn);
                  setUseStatus(valueStatus);
                  setOpenModal(true);
            }
      };

      const resetForm = () => {
            setRankId(null);
            setRankCode('');
            setRankName('');
            setRankNameEn('');
            setRankColor('');
            setTotalPaid('');
            setServiceVisits('');
            setUseStatus('');
            setDetail('')
      };

      const clearForm = () => {
            resetForm();
            setErrorRank(false);
            setIsOpenModal(false)
      };

      const handleChangeColor = ({ hex }: { hex: string }) => {
            setErrorRank(false);
            setRankColor(hex);
      };

      const handleSubmit = () => {
            if (sortBy !== autoIncrement) setOpenModal(true)
            else submit()
      };

      return (
            <div className={`h-100 container-card overflow-hidden`} style={{ backgroundColor: colors.white }}>
                  <div className='h-100 d-flex flex-column'>
                        <HeaderLabel
                              text={t('MEMBER.TITLE_UPDATE')}
                              navigator={{ previousTo: routeName.memberManagement, previousTitle: t('MEMBER.TITLE'), currentTitle: t('MEMBER.TITLE_UPDATE') }}
                              goBack={routeName.memberManagement}
                              onSubmit={() => sortBy === autoIncrement ? submit() : handleSubmit()}
                        />

                        <Card className="border shadow-sm mx-4 mb-4 overflow-auto h-100" style={{ maxWidth: '100%' }}>
                              <Card.Body>
                                    <Card.Title>
                                          <Box sx={{ padding: '24px 24px 8px 24px', width: '100%' }}>
                                                <Box padding={'0px 24px 24px 0px'}>
                                                      <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{t('MEMBER.LABEL.CONDITIONS_SETTING')}</Typography>
                                                </Box>
                                                <Grid container sx={{ padding: '0px 24px 16px 0px', width: '45%' }}>
                                                      <Grid item xs={12} sm={6} lg={4}>
                                                            <Typography>{t('MEMBER.LABEL.AUTO_PROMOTION')}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6} lg={8}>
                                                            <SelectInput
                                                                  label={t('MEMBER.LABEL.AUTO_PROMOTION')}
                                                                  options={[
                                                                        { name: t('MEMBER.AUTO_PROMOTION.NO_SETTING'), value: 'NO_SETTING', disabled: sortBy === 'NO_SETTING' },
                                                                        { name: t('MEMBER.AUTO_PROMOTION.TOTAL_SPENDING'), value: 'TOTAL_SPENDING', disabled: sortBy === 'TOTAL_SPENDING' },
                                                                        { name: t('MEMBER.AUTO_PROMOTION.VISIT_COUNT'), value: 'VISIT_COUNT', disabled: sortBy === 'VISIT_COUNT' },
                                                                  ]}
                                                                  onChange={(evant: any) => onChange(evant.target.value)}
                                                                  value={sortBy}
                                                                  defaultValue={sortBy}
                                                            />
                                                      </Grid>
                                                </Grid>
                                                <Grid container sx={{ padding: '0px 24px 16px 0px', width: '45%' }}>
                                                      <Grid item xs={12} sm={6} lg={4}>
                                                            <Typography>{t('MEMBER.LABEL.EXPIRATION_SETTING')}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6} lg={8}>
                                                            <SelectInput
                                                                  label={t('MEMBER.LABEL.EXPIRATION_SETTING')}
                                                                  options={[]}
                                                                  onChange={(evant: any) => null}
                                                                  disabled
                                                            />
                                                      </Grid>
                                                </Grid>
                                                <Grid container sx={{ padding: '0px 24px 16px 0px', width: '45%' }}>
                                                      <Grid item xs={12} sm={6} lg={4}>
                                                            <Typography>{t('MEMBER.LABEL.EXPIRATION_CONDITIONS')}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6} lg={8}>
                                                            <SelectInput
                                                                  label={t('MEMBER.LABEL.EXPIRATION_CONDITIONS')}
                                                                  options={[]}
                                                                  onChange={(evant: any) => null}
                                                                  disabled
                                                            />
                                                      </Grid>
                                                </Grid>
                                          </Box>
                                          <Divider className="mx-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                                          <Grid container sx={{ width: '100%', padding: '24px 24px 16px 24px', justifyContent: 'space-between' }}>
                                                <Grid item>
                                                      <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{t('MEMBER.LABEL.SET_MEMBER_LEVEL')}</Typography>
                                                </Grid>
                                                <Grid item>
                                                      <ButtonCustom
                                                            variant="outlined"
                                                            startIcon={<AddCircleIcon />}
                                                            onClick={() => setIsOpenModal(true)}
                                                            textButton={t('MEMBER.BUTTON.ADD')}
                                                      />
                                                </Grid>
                                          </Grid>
                                    </Card.Title>

                                    <Box className={'mt-1 pl-xl-3 pr-xl-3'}>
                                          <TableCustom
                                                page={page}
                                                pageLimit={pageLimit}
                                                sortType={sortType}
                                                sortBy={sortBy}
                                                onSort={onRequestSort}
                                                setPageLimit={(limit) => setPageLimit(limit)}
                                                setPage={(val) => setPage(val)}
                                                rowCount={rowCount}
                                                headCells={headCells}
                                                rowsData={ranks.map((val: any, i) => {
                                                      return renderData(val, i);
                                                })}
                                          />
                                    </Box>
                              </Card.Body>
                        </Card>
                        {loading && <Loading show={loading} type="fullLoading" />}
                        <PageModal
                              isOpen={isOpenModal}
                              title={rankId ? t('MEMBER.TITLE_UPDATE') : t('MEMBER.TITLE_CREATE')}
                              size='XS'
                              handleClose={() => clearForm()}
                              onCancel={{ onClick: () => clearForm(), title: t('BUTTON.CANCEL') }}
                              onSubmit={{ onClick: () => onSubmit(), title: t('BUTTON.SAVE') }}
                        >
                              <Box display="flex" flexDirection="column">
                                    <Typography fontWeight={400} fontSize="16px">{t('MEMBER.INPUT.LEVEL')} {ranks.length <= 9 ? `0${ranks.length + 1}` : ranks.length + 1}</Typography>
                                    <Box marginTop="16px">
                                          <TextInput
                                                label={t('MEMBER.INPUT.CODE')}
                                                value={rankCode}
                                                onChange={(event) => {
                                                      if (!event.target.value.match(/[`~%^&*!@#$()_|+\-=?;:'",.<>{}[\]\\/]/g)) {
                                                            setErrorRank(false);
                                                            setRankCode(event.target.value);
                                                      }
                                                }}
                                                required
                                                fullWidth
                                                helperText={errorRank && (!rankCode || rankCode === '') ? t('MEMBER.MESSAGE.ALERT.RANKCODE_PLEASE') : ''}
                                          />
                                    </Box>
                                    <Box>
                                          <Typography sx={{ textAlign: 'right', fontSize: '10px' }}>{rankName.length}/15</Typography>
                                          <TextInput
                                                label={t('MEMBER.INPUT.MEMBER_NAME')}
                                                value={rankName}
                                                onChange={(event) => {
                                                      setErrorRank(false);
                                                      const newValue = event.target.value;
                                                      if (newValue?.length <= 15) setRankName(newValue);
                                                }}
                                                required
                                                fullWidth
                                                helperText={errorRank && (!rankName || rankName === '') ? t('MEMBER.MESSAGE.ALERT.RANKNAME_PLEASE') : ''}
                                          />
                                    </Box>
                                    <Box marginTop="16px">
                                          {sortBy !== 'NO_SETTING' && (
                                                <TextInput
                                                      label={sortBy === 'TOTAL_SPENDING' ? t('MEMBER.INPUT.SPENDING_THRESHOLD') : t('MEMBER.INPUT.TOTAL_VISITS_SINCE')}
                                                      onChange={(event: any) => {
                                                            if (!isNaN(event.target.value)) {
                                                                  const value = Number(event.target.value);
                                                                  if (value > 0) {
                                                                        setErrorRank(false);
                                                                        sortBy === 'TOTAL_SPENDING' ? setTotalPaid(value) : setServiceVisits(value);
                                                                  }
                                                                  else {
                                                                        sortBy === 'TOTAL_SPENDING' ? setTotalPaid('') : setServiceVisits('');
                                                                  }
                                                            }
                                                      }}
                                                      required
                                                      fullWidth
                                                      helperText={errorRank && (sortBy === 'TOTAL_SPENDING' ?
                                                            !totalPaid || totalPaid === '' || totalPaid === 0 : !serviceVisits || serviceVisits === '' || serviceVisits === 0) ?
                                                            sortBy === 'TOTAL_SPENDING' ? t('MEMBER.MESSAGE.ALERT.RANK_TOTAL_PAID_PLEASE') : t('MEMBER.MESSAGE.ALERT.RANK_VISIT_PLEASE') : ''}
                                                      value={sortBy === 'TOTAL_SPENDING' ? `${totalPaid ?? ''}` : `${serviceVisits ?? ''}`}
                                                />
                                          )}
                                    </Box>
                                    <Box marginTop="16px">
                                          <TextAreaInput
                                                onChange={(event) => setDetail(event.target.value)}
                                                label={t('MEMBER.INPUT.DETAIL')}
                                                rows={2}
                                                fullWidth
                                                maxLength={500}
                                                inputProps={{
                                                      value: detail,
                                                      style: {
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'flex-start',
                                                            fontSize: "12px"
                                                      },
                                                      startAdornment: (
                                                            <Box sx={{ position: "absolute", bottom: "2px" }}>
                                                                  <Box sx={{ fontSize: '12px', color: '#808080' }}>{`${detail?.length || 0}/500`}</Box>
                                                            </Box>
                                                      ),
                                                }}
                                          />
                                    </Box>

                                    <Box marginTop="16px">
                                          <Box>
                                                {t('MEMBER.INPUT.MEMBER_COLOR')}
                                                <span style={{ color: colors.statusInactive, marginLeft: '3px' }}>*</span>
                                          </Box>
                                          <ClickAwayListener onClickAway={() => setPopSelectColor(false)}>
                                                <Box sx={{ position: 'relative' }}>
                                                      <ColorTag
                                                            color={rankColor || 'transparent'}
                                                            error={errorRank && (!rankColor || rankColor === '' || rankColor === '#')}
                                                            onClick={() => setPopSelectColor(!popSelectColor)}
                                                      />
                                                      {errorRank && (!rankColor || rankColor === '' || rankColor === '#') && (
                                                            <Box>
                                                                  <HelperText label={t('MEMBER.MESSAGE.ALERT.RANKCOLOR_PLEASE')} />
                                                            </Box>
                                                      )}
                                                      {popSelectColor && (
                                                            <MemberColorManage
                                                                  color={rankColor}
                                                                  setColor={setRankColor}
                                                                  handleChangeColor={handleChangeColor}
                                                                  onLoadMember={loadData}
                                                                  memberTypes={memberTypes}
                                                                  memberType={memberType}
                                                                  handleChangeMember={(val) => setMemberType(val)}
                                                            />
                                                      )}
                                                </Box>
                                          </ClickAwayListener>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          <Box sx={{ width: '93.75px', height: '93.75px', marginTop: '10px', border: `5px solid ${rankColor || 'transparent'}`, borderRadius: '80px', backgroundColor: colors.white }}>
                                                <img src={imgs.defaultAvatar} alt='defaultAvatar' width='85px' height='85px' />
                                          </Box>
                                    </Box>
                                    <Box sx={{ height: '32px', padding: '4px 8px 4px 8px', gap: '10px', borderRadius: '4px', marginTop: '10px', background: rankColor || 'transparent' }}>
                                          <Typography sx={{ fontSize: '16px', fontWeight: 700, color: colors.white, textAlign: 'center' }}>{rankName || ''}</Typography>
                                    </Box>
                              </Box>
                        </PageModal>
                        <Box>
                              <AlertModal
                                    isOpen={openModal}
                                    handleClose={() => setOpenModal(false)}
                                    type={useStatus === 'DELETED' ? 'error' : 'warning'}
                                    title={
                                          useStatus === 'DELETED'
                                                ? `${t('MEMBER.ALERT.CONFIRM_STATUS_')}${t(`BUTTON.DELETE_`)}`
                                                : `${t('MEMBER.ALERT.CONFIRM_SETTING_CHANGE')}`
                                    }
                                    message={
                                          useStatus === 'DELETED'
                                                ? `${t('MEMBER.ALERT.CONFIRM_DELETE_', { rankNameEn: rankNameEn })}`
                                                : `${t('MEMBER.ALERT.CONFIRM_STATUS', { rankName: t(`MEMBER.AUTO_PROMOTION.${sortBy}`) })}`
                                    }
                                    onSubmit={{ onClick: useStatus === 'DELETED' ? onDelete : submit, title: t('BUTTON.SUBMIT') }}
                                    onCancel={{ onClick: () => setOpenModal(false), title: t('BUTTON.CANCEL') }}
                              >
                                    <Box display={'flex'} justifyContent={'center'}> <Typography textAlign="center" fontSize="14px" fontWeight={400}>{useStatus === 'DELETED'
                                          ? `${t('MEMBER.ALERT.CHANGE_TO_DEFAULT_LEVEL_NOTICE', { rankName: rankNameDefault })}`
                                          : `${t('MEMBER.ALERT.ACCUMULATION_RESET_WARNING')}`}</Typography></Box>
                              </AlertModal>
                        </Box>
                  </div>
            </div >
      );
}


