import { Box } from '@mui/material'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputRadio from 'component/Input/InputRadio'
import InputTextField from 'component/Input/InputTextField'
import Loading from 'component/Loading'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import FormPromotionDetail from './form-promotion-detail'
import FormPromotionFooter from './form-promotion-footer'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import PromotionsApi, { PromotionProps } from 'api/promotion/promotion.api'

type FormPromotionsProps = {
    id?: number
}

const FormPromotions = (props: FormPromotionsProps) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [load, setLoad] = useState<boolean>(false)
    const [dataPromotion, setDataPromotion] = useState<any>({})

    const [promotionId, setPromotionId] = useState<number>(0)

    const [promotionTypeOption, setPromotionTypeOption] = useState<any>([])
    const promotionFormatOption: { name: string, value: string }[] = [{ name: t('PROMOTION.PROMOTION_TYPE.DISCOUNT'), value: 'DISCOUNT' }, { name: t('PROMOTION.PROMOTION_TYPE.EXTRA'), value: 'EXTRA' }]

    const [branches, setBranches] = useState<BranchInterface[]>([])
    const [seletorBranch, setSelectorBranch] = useState<boolean>(false)

    const [errorMessage, setErrorMessage] = useState<any>({})

    const loadDataPromotion = async (id?: number, isCopy?: boolean) => {
        setLoad(true)
        const newId = id || promotionId
        await PromotionsApi.findById(newId).then((res) => {
            const newBranchId: number[] = []
            const newUsageBranchUse: any[] = []
            const newUsageBranchDateData: any[] = []

            let newQtyTotal = res?.data?.qtyTotal || 0
            if (res?.data?.promotionBranchs?.length) {
                const newBranch = res?.data?.promotionBranchs
                if (res?.data?.usageBranch === "FIXBRANCH" || res?.data?.usageBranch === "ALL") {
                    newQtyTotal = newBranch.every((item: any) => item?.qtyTotal === newBranch[0]?.qtyTotal) ? newBranch[0].qtyTotal : res?.data?.qtyTotal
                }
                const checkNewQty = res?.data?.qty === 0
                const checkNewDate = res?.data?.promotionBranchs?.some((item: any) => res?.data?.startDate !== item?.startDate || res?.data?.endDate !== item?.endDate)
                res?.data?.promotionBranchs.forEach((item: any) => {
                    newBranchId.push(item?.branchId)
                    if (checkNewQty) {
                        if (!newUsageBranchUse?.length) {
                            newUsageBranchUse.push({ branchId: [item?.branchId], qtyTotal: item?.qtyTotal || 0 })
                        } else {
                            const newIndex = newUsageBranchUse?.findIndex((info: any) => info?.qtyTotal === item?.qtyTotal)
                            if (newIndex !== -1) {
                                newUsageBranchUse[newIndex] = { ...newUsageBranchUse[newIndex], branchId: [...newUsageBranchUse[newIndex]?.branchId, item?.branchId] }
                            } else {
                                newUsageBranchUse.push({ branchId: [item?.branchId], qtyTotal: item?.qtyTotal || 0 })
                            }
                        }

                    }

                    if (checkNewDate) {
                        if (!newUsageBranchDateData?.length) {
                            newUsageBranchDateData.push({ branchId: [item?.branchId], dateStart: item?.startDate, dateEnd: item?.endDate })
                        } else {
                            const newIndex = newUsageBranchDateData?.findIndex((info: any) => info?.dateStart === item?.startDate && info?.dateEnd === item?.endDate)
                            if (newIndex !== -1) {
                                newUsageBranchDateData[newIndex] = { ...newUsageBranchDateData[newIndex], branchId: [...newUsageBranchDateData[newIndex]?.branchId, item?.branchId] }
                            } else {
                                newUsageBranchDateData.push({ branchId: [item?.branchId], dateStart: item?.startDate, dateEnd: item?.endDate })
                            }
                        }
                    }
                })
            }

            const newItemDiscount: any[] = []

            if (res?.data?.promotionItems?.length) {
                res?.data?.promotionItems.forEach((item: any) => {
                    newItemDiscount.push({
                        itemId: item?.itemId,
                        itemName: item?.itemName,
                        price: item?.itemPrice,
                        itemTypeId: item?.itemTypeId,
                        itemTypeName: item?.itemTypeName,
                        group: item?.itemType,
                        qty: item?.qty
                    })
                })
            }

            const newItemExtra: any[] = []
            const newItemExtraFree: any[] = []

            if (res?.data?.promotionExtra?.length) {
                res?.data?.promotionExtra.forEach((item: any) => {
                    if (item?.type === 'BUY') {
                        const infos = newItemExtra[Number(item?.groupNumber || 1) - 1]
                        if (!infos) {
                            newItemExtra[Number(item?.groupNumber || 1) - 1] =
                            {
                                dataList: [{
                                    itemId: item?.itemId,
                                    itemName: item?.itemName,
                                    price: item?.itemPrice,
                                    itemTypeId: item?.itemTypeId,
                                    itemTypeName: item?.itemTypeName,
                                    group: item?.itemType,
                                    qty: item?.qty
                                }
                                ]
                            }
                        } else {
                            newItemExtra[Number(item?.groupNumber || 1) - 1] = {
                                ...infos,
                                dataList: [...infos?.dataList, {
                                    itemId: item?.itemId,
                                    itemName: item?.itemName,
                                    price: item?.itemPrice,
                                    itemTypeId: item?.itemTypeId,
                                    itemTypeName: item?.itemTypeName,
                                    group: item?.itemType,
                                    qty: item?.qty
                                }]
                            }
                        }
                    }
                    if (item?.type === 'EXTRA') {
                        const infos = newItemExtraFree[Number(item?.groupNumber || 1) - 1]
                        if (!infos) {
                            newItemExtraFree[Number(item?.groupNumber || 1) - 1] =
                            {
                                dataList: [{
                                    itemId: item?.itemId,
                                    itemName: item?.itemName,
                                    price: item?.itemPrice,
                                    itemTypeId: item?.itemTypeId,
                                    itemTypeName: item?.itemTypeName,
                                    group: item?.itemType,
                                    qty: item?.qty
                                }
                                ]
                            }
                        } else {
                            newItemExtraFree[Number(item?.groupNumber || 1) - 1] = {
                                ...infos,
                                dataList: [...infos?.dataList, {
                                    itemId: item?.itemId,
                                    itemName: item?.itemName,
                                    price: item?.itemPrice,
                                    itemTypeId: item?.itemTypeId,
                                    itemTypeName: item?.itemTypeName,
                                    group: item?.itemType,
                                    qty: item?.qty
                                }]
                            }
                        }
                    }


                })
            }

            const newGroups: any = []

            if (res?.data?.promotionGroups?.length) {
                res?.data?.promotionGroups.forEach((item: any) => {
                    newGroups.push({ groupType: item?.groupType, groupTypeId: item?.groupTypeId })
                })
            }

            const newDataCopy: any = isCopy ? {
                promotionName: `${res?.data?.promotionName}(Copy)`,
                promotionNameEn: res?.data?.promotionNameEn ? `${res?.data?.promotionNameEn}(Copy)` : '',
                promotionCode: "",
                startDate: '',
                endDate: '',
                // usageBranch: 'NOTFIX',
                // usageBranchUse: [],
                // usageBranchDateData: [],
                // promotionGroup: "ALL",
                // promotionGroups: [],
                // isRepeat: "1",
                status: "WAITING"
            } : {}

            setDataPromotion({
                ...res?.data,
                branchId: newBranchId,
                type: res?.data?.type,
                usageBranchUse: newUsageBranchUse,
                usageBranchDateData: newUsageBranchDateData,
                promotionItemsCurrent: newItemDiscount,
                promotionExtraCurrent: newItemExtra,
                promotionExtraFreeCurrent: newItemExtraFree,
                promotionGroups: newGroups,
                qtyTotal: newQtyTotal,
                ...newDataCopy
            })
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        const newLocation: any = location
        if (newLocation?.state) {
            const dataEdit = newLocation?.state
            if (!dataEdit?.copy) {
                setPromotionId(dataEdit?.promotionId)
            }
            loadDataPromotion(dataEdit?.promotionId, dataEdit?.copy)
        }

    }, [location])

    useEffect(() => {
        if (props?.id) {
            setPromotionId(props?.id)
            loadDataPromotion(props?.id)
        }
    }, [props?.id])

    const loadData = () => {
        // ClinicApi.findAllBranches().then(({ data }) => setBranches(data))
        ClinicApi.findAllActiveBranches().then(({ data }) => data && setBranches(data))
        loadPromotionType()

    }

    const loadPromotionType = async (search?: string) => {
        const condition = {
            page: 1,
            pageLimit: 30,
            SEARCH: search,
            sortType: 'ASC',
            sortBy: 'createdAt',
            STATUS: "ACTIVE"
        }

        await PromotionsApi.findAllType(condition).then((res) => {
            if (res?.data?.length) {
                const newData = res?.data?.map((item: any) => {
                    return {
                        name: item.promotionTypeName,
                        value: item.promotionTypeId
                    }
                })
                setPromotionTypeOption(newData)
            } else {
                setPromotionTypeOption([])
            }
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleChangePromotion = (event: any) => {
        clearError()
        const elementName = event.target.id
        const elementValue = event.target.value
        setDataPromotion({ ...dataPromotion, [elementName]: elementValue })
    }

    const handleChangeBranchId = (val: BranchInterface | 'ALL') => {
        clearError()
        const temp: any = { ...dataPromotion, branchId: [] }
        if (val !== "ALL") {
            if (dataPromotion.branchId === 'ALL') {
                const newBrnachId: number[] = []
                branches.forEach((item) => {
                    if (item.branchId !== val.branchId) {
                        newBrnachId.push(item.branchId)
                    }
                })
                temp['branchId'] = newBrnachId
            } else {
                if (dataPromotion.branchId?.includes(val.branchId)) {
                    const newBrnachId = dataPromotion.branchId.filter((item: any) => item !== val.branchId)
                    temp['branchId'] = newBrnachId
                } else {
                    const newBrnachId = dataPromotion?.branchId?.length ? [...dataPromotion?.branchId, val.branchId] : [val.branchId]
                    temp['branchId'] = newBrnachId
                }
            }
        } else {
            const newBrnachId = (dataPromotion.branchId === 'ALL' || dataPromotion.branchId?.length === branches?.length) ? [] : branches.map((item) => item.branchId)
            temp['branchId'] = newBrnachId
        }
        setDataPromotion(temp)
    }

    const onCheckBranchId = (option: BranchInterface) => {
        if (dataPromotion?.branchId === "ALL") {
            return true
        }
        if (!dataPromotion?.branchId?.length) {
            return false
        }
        const check = dataPromotion?.branchId?.some((item: any) => item === option?.branchId)
        return check
    }

    const renderValueBranchId = () => {
        if (dataPromotion?.branchId === "ALL") {
            return branches?.map((item) => {
                return [item.branchId]
            }) || []
        }
        return dataPromotion?.branchId || []
    }

    const handleChangeDetailPromotion = (data: any) => {
        clearError()
        setDataPromotion(data)
    }

    const clearError = () => {
        setErrorMessage({})
    }

    const onCheckErrorSubmit = () => {
        const textError = 'PROMOTION.MESSAGE.ERROR'
        let newError: any = {
            'PROMOTIONNAME': !dataPromotion?.promotionName ? t(`${textError}.PROMOTIONNAME`) : '',
            'PROMOTIONNAME_MAXLENGTH': dataPromotion?.promotionName?.length > 255 ? t(`${textError}.PROMOTIONNAME_MAXLENGTH`) : '',
            // 'PROMOTIONNAMEEN': !dataPromotion?.promotionNameEn ? t(`${textError}.PROMOTIONNAMEEN`) : '',
            'PROMOTIONNAMEEN_MAXLENGTH': dataPromotion?.promotionNameEn?.length > 255 ? t(`${textError}.PROMOTIONNAMEEN_MAXLENGTH`) : '',
            'PROMOTIONTYPEID': !dataPromotion?.promotionTypeId ? t(`${textError}.PROMOTIONTYPEID`) : '',
            'TYPE': !dataPromotion?.type ? t(`${textError}.TYPE`) : '',
            'BRANCHID': !dataPromotion?.branchId?.length ? t(`${textError}.BRANCHID`) : '',
            'PROMOTION_STARTDATE': !dataPromotion?.startDate ? t(`${textError}.PROMOTION_STARTDATE`) : '',
            'PROMOTION_ENDDATE': !dataPromotion?.endDate ? t(`${textError}.PROMOTION_ENDDATE`) : '',
            'DETAIL_MAXLENGTH': dataPromotion?.detail?.length > 300 ? t(`${textError}.DETAIL_MAXLENGTH`) : '',

        }

        const checkTypePrice = () => {
            if (!dataPromotion?.promotionPriceType) {
                newError = { ...newError, 'PROMOTIONPRICETYPE': t(t(`${textError}.PROMOTIONPRICETYPE`)), }
                return notiError(t(`${textError}.PROMOTIONPRICETYPE`))
            }

            if (dataPromotion?.promotionPriceType === 'DISCOUNT') {
                return newError = {
                    ...newError,
                    'PROMOTIONPRICE': !dataPromotion?.promotionPrice ? t(`${textError}.PROMOTIONPRICE`) : '',
                    'PROMOTIONPRICEUNIT': !dataPromotion?.promotionPriceUnit ? t(`${textError}.PROMOTIONPRICEUNIT`) : '',
                }
            }

            if (dataPromotion?.promotionPriceType === 'SALEPRICE') {
                return newError = {
                    ...newError,
                    'PROMOTIONPRICE_': !dataPromotion?.promotionPrice ? t(`${textError}.PROMOTIONPRICE_`) : '',
                }
            }
        }

        const checkUsagePrice = () => {
            if (!dataPromotion?.promotionUsage || dataPromotion?.promotionUsage === '0') {
                newError = { ...newError, 'PROMOTIONUSAGE_': t(`${textError}.PROMOTIONUSAGE_`), }
                return notiError(t(`${textError}.PROMOTIONUSAGE_`))
            }

            if (dataPromotion?.promotionUsage === '1') {
                newError = { ...newError, 'PROMOTIONUSAGE_1': t(`${textError}.PROMOTIONUSAGE_1`), }
                return notiError(t(`${textError}.PROMOTIONUSAGE_1`))
            }

        }

        const checkPromotionItems = () => {
            if (dataPromotion?.promotionItems?.length) {
                newError = {
                    ...newError,
                    'PROMOTIONITEMS_GROUP': dataPromotion?.promotionItems.some((item: any) => !item?.itemType) ? t(`${textError}.PROMOTIONITEMS_GROUP`) : '',
                    'PROMOTIONITEMS_ITEM': dataPromotion?.promotionItems.some((item: any) => !item?.itemId) ? t(`${textError}.PROMOTIONITEMS_ITEM`) : '',
                    'PROMOTIONITEMS_QTY': dataPromotion?.promotionItems.some((item: any) => !item?.qty) ? t(`${textError}.PROMOTIONITEMS_QTY`) : '',
                }
                return
            }
        }

        const checkPromotionExtra = () => {
            if (dataPromotion?.promotionExtra?.length) {
                const newDataBuy = dataPromotion?.promotionExtra.filter((item: any) => item.type === 'BUY')
                const newDataExtra = dataPromotion?.promotionExtra.filter((item: any) => item.type === 'EXTRA')
                if (!newDataBuy?.length) {
                    notiError(t(`${textError}.PROMOTIONITEMS_BU`))
                    newError = { ...newError, 'PROMOTIONITEMS_BU': t(`${textError}.PROMOTIONITEMS_BU`), }
                } else {
                    newDataBuy?.forEach((item: any, index: number) => {
                        if (item?.itemType === undefined) {
                            notiError(t(`${textError}.PROMOTIONITEM_BU_LIST`, { index: item?.groupNumber || index + 1 }))
                            newError = { ...newError, 'PROMOTIONITEM_BU_LIST': t(`${textError}.PROMOTIONITEM_BU_LIST`), }
                        }
                        if (!item?.itemType || !item?.itemId || !item?.qty) {
                            newError = {
                                ...newError,
                                'PROMOTIONITEMS_GROUP': !item?.itemType ? t(`${textError}.PROMOTIONITEMS_GROUP`) : '',
                                'PROMOTIONITEMS_ITEM': !item?.itemId ? t(`${textError}.PROMOTIONITEMS_ITEM`) : '',
                                'PROMOTIONITEMS_QTY': !item?.qty ? t(`${textError}.PROMOTIONITEMS_QTY`) : '',
                            }
                        }


                    })
                }

                if (!newDataExtra?.length) {
                    notiError(t(`${textError}.PROMOTIONITEMS_EX`))
                    newError = { ...newError, 'PROMOTIONITEMS_EX': t(`${textError}.PROMOTIONITEMS_EX`) }
                } else {
                    newDataExtra?.forEach((item: any, index: number) => {
                        if (item?.itemType === undefined) {
                            notiError(t(`${textError}.PROMOTIONITEM_EX_LIST`, { index: item?.groupNumber || index + 1 }))
                            newError = { ...newError, 'PROMOTIONITEM_EX_LIST': t(`${textError}.PROMOTIONITEM_EX_LIST`), }
                        }
                        if (!item?.itemType || !item?.itemId || !item?.qty) {
                            newError = {
                                ...newError,
                                'PROMOTIONITEMS_GROUP': !item?.itemType ? t(`${textError}.PROMOTIONITEMS_GROUP`) : '',
                                'PROMOTIONITEMS_ITEM': !item?.itemId ? t(`${textError}.PROMOTIONITEMS_ITEM`) : '',
                                'PROMOTIONITEMS_QTY': !item?.qty ? t(`${textError}.PROMOTIONITEMS_QTY`) : '',
                            }
                        }

                    })
                }
            } else {
                notiError(t(`${textError}.PROMOTIONITEMS_`))
                newError = { ...newError, 'PROMOTIONITEMS_': t(`${textError}.PROMOTIONITEMS_`), }
            }
        }

        const checkType = () => {
            if (dataPromotion?.type === 'DISCOUNT') {
                checkTypePrice()
                checkUsagePrice()
                newError = { ...newError, 'MINIMUM': (!dataPromotion?.minimum && dataPromotion?.minimum !== 0) ? t(`${textError}.MINIMUM`) : '', }
                checkPromotionItems()
            }
            if (dataPromotion?.type === 'EXTRA') checkPromotionExtra()
        }

        if (dataPromotion?.type) {
            checkType()
        }

        if (dataPromotion?.usageBranch !== 'NOTFIX' && dataPromotion?.usageBranch !== undefined) {
            if (dataPromotion?.usageBranch === '1') {
                notiError(t(`${textError}.PROMOTION_BRANCH_SELECT`))
                newError = { ...newError, 'PROMOTION_BRANCH_SELECT': t(`${textError}.PROMOTION_BRANCH_SELECT`), }
            } else {
                if (['ALL', 'FIXBRANCH'].includes(dataPromotion?.usageBranch)) {
                    newError = { ...newError, 'PROMOTION_QTYTOTAL': !dataPromotion?.qtyTotal ? t(`${textError}.PROMOTION_QTYTOTAL`) : '' }
                } else {
                    if (!dataPromotion?.usageBranchUse?.length) {
                        notiError(t(`${textError}.PROMOTION_BRANCH_ADD`))
                        newError = { ...newError, 'PROMOTION_BRANCH_ADD': t(`${textError}.PROMOTION_BRANCH_ADD`), }
                    } else {
                        newError = {
                            ...newError,
                            'PROMOTION_BRANCH_USE': dataPromotion?.usageBranchUse?.some((item: any) => !item?.branchId?.length) ? t(`${textError}.PROMOTION_BRANCH_USE`) : '',
                            'PROMOTION_USE_USE': dataPromotion?.usageBranchUse?.some((item: any) => !item?.qtyTotal) ? t(`${textError}.PROMOTION_USE_USE`) : ''
                        }

                        let branchSelect: number[] = []
                        dataPromotion?.usageBranchUse.forEach((item: any) => {
                            if (item?.branchId?.length) {
                                branchSelect = [...branchSelect, ...item?.branchId]
                            }
                        })

                        if (branchSelect?.length !== dataPromotion?.branchId?.length) {
                            notiError(t(`${textError}.PROMOTION_BRANCH_`))
                            newError = { ...newError, 'PROMOTION_BRANCH_': t(`${textError}.PROMOTION_BRANCH_`), }
                        }

                    }
                }
            }
        }

        if (dataPromotion?.usageBranchDate === '1' || dataPromotion?.usageBranchDateData?.length > 1) {
            if (!dataPromotion?.usageBranchDateData.length) {
                notiError(t(`${textError}.PROMOTION_BRANCH_DATE_SELECT`))
                newError = { ...newError, 'PROMOTION_BRANCH_DATE_SELECT': t(`${textError}.PROMOTION_BRANCH_DATE_SELECT`), }
            } else {
                newError = {
                    ...newError,
                    'PROMOTION_BRANCH_DATE': dataPromotion?.usageBranchDateData?.some((item: any) => !item?.branchId?.length) ? t(`${textError}.PROMOTION_BRANCH_DATE`) : '',
                    'PROMOTION_START_DATE': dataPromotion?.usageBranchDateData?.some((item: any) => !item?.dateStart) ? t(`${textError}.PROMOTION_START_DATE`) : '',
                    'PROMOTION_END_DATE': dataPromotion?.usageBranchDateData?.some((item: any) => !item?.dateEnd) ? t(`${textError}.PROMOTION_END_DATE`) : ''
                }
            }
        }

        if (dataPromotion?.promotionGroup && dataPromotion?.promotionGroup !== 'ALL') {
            if (dataPromotion?.promotionGroup === '1') {
                newError = { ...newError, 'PROMOTION_GROUP': t(`${textError}.PROMOTION_GROUP`), }
            }

            if (!dataPromotion?.promotionGroups?.length) {
                newError = { ...newError, 'PROMOTION_GROUPS': t(`${textError}.PROMOTION_GROUPS`), }
            }
        }
        return newError

    }

    const onSubmit = async () => {
        setLoad(true)

        const newError = onCheckErrorSubmit()
        if (Object.values(newError).some(value => value !== "")) {
            setErrorMessage(newError)
            setLoad(false)
            return
        }

        setLoad(true)
        const newPromotionBranchs = dataPromotion?.branchId?.map((item: any) => {
            const newQty = (dataPromotion?.usageBranch === 'NOTFIX' || !dataPromotion?.usageBranch) ? 0 : (dataPromotion?.usageBranch === 'BRANCH' && dataPromotion?.usageBranchUse?.length) ? dataPromotion?.usageBranchUse?.find((info: any) => info?.branchId.includes(item))?.qtyTotal || 0 : dataPromotion?.qtyTotal || 0
            const newDate = (dataPromotion?.usageBranchDate === '1' || dataPromotion?.usageBranchDateData?.length) ? dataPromotion?.usageBranchDateData?.find((info: any) => info?.branchId.includes(item)) : null
            return {
                branchId: item,
                qtyTotal: Number(newQty) || 0,
                startDate: newDate?.dateStart || dataPromotion?.startDate || '',
                endDate: newDate?.dateEnd || dataPromotion?.endDate || ''
            }
        })
        const { branchId,
            usageBranchDate,
            usageBranchDateData,
            usageBranchUse,
            promotionItemsCurrent,
            promotionExtraCurrent,
            promotionExtraFreeCurrent,
            promotionCode,
            updatedAt,
            updatedBy,
            createdAt,
            createdBy,
            promotionType,
            ...restOfDataPromotion } = dataPromotion

        const newPayload: PromotionProps = {
            ...restOfDataPromotion,
            detail: dataPromotion?.detail || '',
            usageBranch: dataPromotion?.usageBranch || 'NOTFIX',
            qty: 0,
            qtyTotal: Number(dataPromotion?.qtyTotal || 0) || 0,
            promotionPrice: Number(dataPromotion?.promotionPrice) || 0,
            promotionPriceUnit: dataPromotion?.type === 'EXTRA' ? 'BAHT' : dataPromotion?.promotionPriceUnit || 'BAHT',
            promotionUsage: dataPromotion?.type === 'EXTRA' ? "ALL" : dataPromotion?.promotionUsage,
            promotionBranchs: newPromotionBranchs || [],
            promotionGroup: restOfDataPromotion?.promotionGroup || 'ALL',
            promotionGroups: restOfDataPromotion?.promotionGroups || [],
            promotionItems: restOfDataPromotion?.promotionItems || [],
            promotionExtra: restOfDataPromotion?.promotionExtra || [],
            // status: promotionId ? "WAITING" : "WAITING",
            isRepeat: dataPromotion?.isRepeat || "1"

        }
        const textError = 'PROMOTION.MESSAGE.ERROR'

        setLoad(false)
        if (promotionId) {
            await PromotionsApi.updatePromotion({ ...newPayload, status: dataPromotion?.status }, promotionId).then((res) => {
                clearError()
                notiSuccess(t('PROMOTION.MESSAGE.EDIT_SUCCESS'))
                history.push(routeName.promotion)
            }).catch((error) => {
                const errMsg = error?.response?.data?.message
                if (errMsg) {
                    setErrorMessage({
                        'PROMOTIONNAME': errMsg === "IS_DUPLICATE_NAME" ? t(`${textError}.PROMOTIONNAME`) : '',
                        'PROMOTIONNAMEEN': errMsg === "IS_DUPLICATE_NAMEEN" ? t(`${textError}.PROMOTIONNAMEEN`) : '',
                    })
                } else {
                    notiError(t('PROMOTION.MESSAGE.ERROR_'))
                }
            }).finally(() => {
                setLoad(false)
            })
        } else {
            await PromotionsApi.createPromotion(newPayload).then((res) => {
                clearError()
                notiSuccess(t('PROMOTION.MESSAGE.SAVE_SUCCESS'))
                history.push(routeName.promotion)
            }).catch((error) => {
                const errMsg = error?.response?.data?.message
                if (errMsg) {
                    setErrorMessage({
                        'PROMOTIONNAME': errMsg === "IS_DUPLICATE_NAME" ? t(`${textError}.IS_DUPLICATE_NAME`) : '',
                        'PROMOTIONNAMEEN': errMsg === "IS_DUPLICATE_NAME_EN" ? t(`${textError}.IS_DUPLICATE_NAME_EN`) : '',
                    })
                } else {
                    notiError(t('PROMOTION.MESSAGE.ERROR_'))
                }
            }).finally(() => {
                setLoad(false)
            })
        }
    }

    return (
        <>
            <Box className={`container-card container-card h-100 overflow-hidden`}>
                <Loading show={load} />
                <Box className="d-flex flex-column h-100" display={(load && props?.id) ? 'none' : 'block'} >
                    <Box display={props?.id ? 'none' : 'block'}>
                        <HeaderLabel
                            text={promotionId ? t('PROMOTION.TITLE_UPDATE') : t('PROMOTION.TITLE_CREATE')}
                            navigator={{ previousTo: routeName.promotion, previousTitle: t('PROMOTION.TITLE'), currentTitle: promotionId ? t('PROMOTION.TITLE_UPDATE') : t('PROMOTION.TITLE_CREATE') }}
                            componentBtnCustom={
                                <Box className="flex-row flex-end ml-auto gap-3">
                                    <Box sx={{ width: '100px', button: { width: '100%', filter: 'grayscale(100%) sepia(0%) brightness(35%) contrast(120%)' } }}>
                                        <Link to={routeName.promotion}>
                                            <ButtonCustom
                                                variant={"outlined"}
                                                textButton={t("BUTTON.CANCEL")}
                                            />
                                        </Link>
                                    </Box>
                                    <Box sx={{ width: '100px', button: { width: '100%', } }}>
                                        <ButtonCustom
                                            textButton={t("BUTTON.SAVE")}
                                            onClick={onSubmit}
                                        />
                                    </Box>
                                </Box>
                            }
                        />
                    </Box>

                    <Box maxHeight={'100%'} height={(load && props?.id) ? 'calc(100vh - 208px)' : 'fit-content'} boxShadow={'0px 3px 6px #0000001a'} border={props?.id ? 'none' : '1px solid #e5e5e5'} margin={props?.id ? '0' : '0px 24px 24px 24px'} overflow={'auto'} sx={{ overflowX: 'hidden', '.MuiButtonBase-root': { filter: props?.id ? 'grayscale(1)' : '', opacity: props?.id ? '.7' : '' } }}>
                        <Box minHeight={'100%'} height={'fit-content'} width={'100%'} padding={props?.id ? '0' : '24px'}>
                            <Box display={'flex'} flexWrap={'wrap'} width={{ lg: 'calc(100% + 12px)', xs: '100%' }} margin={{ lg: '0 0 0 -6px', xs: '0' }} >
                                <Box width={{ lg: 'calc(100% / 3)', xs: '100%' }} padding={{ lg: '8px', xs: '8px 0 ' }} >
                                    {!props?.id ?
                                        <InputTextField
                                            id={'promotionName'}
                                            label={t('PROMOTION.FORM.PROMOTION_NAME')}
                                            value={dataPromotion?.promotionName}
                                            onchange={handleChangePromotion}
                                            required
                                            helperText={errorMessage?.PROMOTIONNAME || errorMessage?.PROMOTIONNAME_MAXLENGTH}
                                        />
                                        :
                                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                            <Box width={'100%'} maxWidth={'200px'}>{t('PROMOTION.FORM.PROMOTION_NAME')}</Box>
                                            <Box width={'calc(100% - 200px)'} className={`text-ellipsis`}>{dataPromotion?.promotionName}</Box>
                                        </Box>
                                    }
                                </Box>
                                <Box width={{ lg: 'calc(100% / 3)', xs: '100%' }} padding={{ lg: '8px', xs: '8px 0 ' }} >
                                    {!props?.id ?
                                        <InputTextField
                                            id={'promotionNameEn'}
                                            label={t('PROMOTION.FORM.PROMOTION_NAMEEN')}
                                            value={dataPromotion?.promotionNameEn}
                                            onchange={handleChangePromotion}
                                            // required
                                            helperText={errorMessage?.PROMOTIONNAMEEN || errorMessage?.PROMOTIONNAMEEN_MAXLENGTH}
                                        />
                                        :
                                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                            <Box width={'100%'} maxWidth={'200px'}>{t('PROMOTION.FORM.PROMOTION_NAMEEN')}</Box>
                                            <Box width={'calc(100% - 200px)'} className={`text-ellipsis`}>{dataPromotion?.promotionNameEn}</Box>
                                        </Box>
                                    }
                                </Box>
                                <Box width={{ lg: 'calc(100% / 3)', xs: '100%' }} padding={{ lg: '8px', xs: '8px 0 ' }} >
                                    {!props?.id ?
                                        <AutocompleteSelect
                                            labelId='promotion-type'
                                            inputLabel={t('PROMOTION.FORM.PROMOTION_TYPENAME')}
                                            options={promotionTypeOption}
                                            getOptionLabel={(option) => option?.name || ''}
                                            onchange={(e, value) => {
                                                clearError()
                                                setDataPromotion({ ...dataPromotion, promotionTypeId: value?.value || null })
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <Box {...props}>
                                                        {option?.name}
                                                    </Box>
                                                )
                                            }}
                                            filterOptions={(option: any) => option.name}
                                            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                            value={promotionTypeOption?.find((item: any) => item?.value === dataPromotion?.promotionTypeId) || ''}
                                            disableClearable
                                            required
                                            errMessage={errorMessage?.PROMOTIONTYPEID}
                                        />
                                        :
                                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                            <Box width={'100%'} maxWidth={'200px'}>{t('PROMOTION.FORM.PROMOTION_TYPENAME')}</Box>
                                            <Box width={'calc(100% - 200px)'} className={`text-ellipsis`}>{promotionTypeOption?.find((item: any) => item?.value === dataPromotion?.promotionTypeId)?.name || ''}</Box>
                                        </Box>
                                    }
                                </Box>
                                <Box width={{ lg: 'calc(100% / 3)', xs: '100%' }} padding={{ lg: '8px', xs: '8px 0 ' }} >
                                    {!props?.id ?
                                        <InputTextField
                                            id={'promotionCode'}
                                            label={t('PROMOTION.FORM.PROMOTION_CODE')}
                                            value={dataPromotion?.promotionCode || ''}
                                            disabled
                                        />
                                        :
                                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                            <Box width={'100%'} maxWidth={'200px'}>{t('PROMOTION.FORM.PROMOTION_CODE')}</Box>
                                            <Box width={'calc(100% - 200px)'} className={`text-ellipsis`}>{dataPromotion?.promotionCode || ''}</Box>
                                        </Box>
                                    }
                                </Box>
                                <Box width={{ lg: !props?.id ? 'calc(100% / 3)' : 'calc(100% * ( 2 / 3 ))', xs: '100%' }} padding={{ lg: '8px', xs: '8px 0 ' }} >
                                    {!props?.id ?
                                        <Box sx={{
                                            // height: '100%',
                                            '.MuiAutocomplete-root': {
                                                height: '100% !important'
                                            },
                                            '.MuiInputBase-root': {
                                                height: '100% !important'
                                            },
                                            '.Mui-multiple': {
                                                '.MuiFormControl-root': {
                                                    '.MuiOutlinedInput-root': {
                                                        marginTop: 0,
                                                        padding: '10px 24px 0px 16px !important',
                                                        flexWrap: 'nowrap'
                                                    },
                                                    '.MuiOutlinedInput-input': {
                                                        height: '100% !important',
                                                        minWidth: 'unset',
                                                        padding: '0 !important'
                                                    },
                                                }
                                            },
                                        }}>
                                            <AutocompleteSelect
                                                labelId="branches"
                                                getInputSearch={(e) => setSelectorBranch(e?.inputProps?.value !== "")}
                                                inputLabel={t('OPERATIVE.BRANCH_PRICE.BRANCH')}
                                                options={['ALL', ...branches]}
                                                getOptionLabel={(option: any) => `${option?.branchId || ''}`}
                                                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                                renderOption={(props, option) => {
                                                    if (option === 'ALL') {
                                                        return (
                                                            <ListFilterDoctors
                                                                {...props}
                                                                key={option}
                                                                component="li"
                                                                value={option}
                                                                onClick={() => handleChangeBranchId("ALL")}
                                                            >
                                                                <InputCheckbox
                                                                    label={t('ALL')}
                                                                    checked={dataPromotion?.branchId?.length === branches?.length || dataPromotion?.branchId === "ALL"}
                                                                    indeterminate={dataPromotion?.branchId && dataPromotion?.branchId?.length >= 1 && dataPromotion?.branchId !== "ALL" && dataPromotion?.branchId?.length !== branches?.length}
                                                                    className={'pe-none'}
                                                                />
                                                            </ListFilterDoctors>
                                                        )
                                                    } else {
                                                        return (
                                                            <ListFilterDoctors
                                                                {...props}
                                                                key={option.dentistId}
                                                                component="li"
                                                                value={option.dentistId}
                                                                onClick={() => handleChangeBranchId(option)}
                                                                title={option.branchName}
                                                            >
                                                                <InputCheckbox
                                                                    label={option.branchName}
                                                                    checked={onCheckBranchId(option)}
                                                                    className={'pe-none'}
                                                                />
                                                            </ListFilterDoctors>
                                                        )
                                                    }
                                                }}
                                                renderTags={(tagValue, getTagProps) => {
                                                    return (
                                                        <Box className='text-ellipsis ' sx={seletorBranch && { width: "0px !important" } || {}}>
                                                            {((_.size(tagValue) === branches?.length) || dataPromotion.branchId === 'ALL') && (
                                                                <span>{t('ALL')}</span>
                                                            ) || (
                                                                    <Box className='text-ellipsis '>
                                                                        {
                                                                            tagValue.map((option: any, index: number) => {
                                                                                return (
                                                                                    <span key={index}>
                                                                                        {_.find(branches, { branchId: option })?.branchName}
                                                                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                                                                    </span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Box>
                                                                )}
                                                        </Box>
                                                    )
                                                }}
                                                filterOptions={(option) => 'ALL' + option?.branchName || ''}
                                                value={renderValueBranchId()}
                                                required
                                                multiple
                                                disableClearable
                                                errMessage={errorMessage?.BRANCHID}
                                                height={43}
                                            />
                                        </Box>
                                        :
                                        <Box display={'flex'} alignItems={'flex-start'} width={'100%'}>
                                            <Box width={'100%'} maxWidth={'200px'}>{t('OPERATIVE.BRANCH_PRICE.BRANCH')}</Box>
                                            <Box width={'calc(100% - 200px)'} >{branches?.filter((item) => dataPromotion?.branchId?.includes(item?.branchId))?.map((item) => item.branchName)?.join(', ')}</Box>
                                        </Box>
                                    }
                                </Box>
                                <Box width={{ lg: 'calc(100% / 3)', xs: '100%' }} padding={{ lg: '8px', xs: '8px 0 ' }} >
                                    {!props?.id ?
                                        <AutocompleteSelect
                                            labelId='promotion-type'
                                            inputLabel={t('PROMOTION.FORM.TYPE')}
                                            options={promotionFormatOption}
                                            getOptionLabel={(option) => option?.name || ''}
                                            onchange={(e, value) => {
                                                clearError()
                                                setDataPromotion({ ...dataPromotion, type: value?.value || null, promotionExtra: [], promotionItems: [], promotionUsage: null })
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <Box {...props}>
                                                        {option?.name}
                                                    </Box>
                                                )
                                            }}
                                            filterOptions={(option: any) => option.name}
                                            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                            value={dataPromotion?.type ? promotionFormatOption.find((item) => item.value === dataPromotion?.type) : ''}
                                            disableClearable={!dataPromotion?.type}
                                            errMessage={errorMessage?.TYPE}
                                            required
                                        />
                                        :
                                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                            <Box width={'100%'} maxWidth={'200px'}>{t('PROMOTION.FORM.TYPE')}</Box>
                                            <Box width={'calc(100% - 200px)'} className={`text-ellipsis`}>{dataPromotion?.type ? promotionFormatOption.find((item) => item.value === dataPromotion?.type)?.name || '' : ''}</Box>
                                        </Box>

                                    }
                                </Box>
                            </Box>

                            {dataPromotion?.type &&
                                <Box>
                                    <FormPromotionDetail
                                        dataEdit={dataPromotion}
                                        handleChangeDetailPromotion={handleChangeDetailPromotion}
                                        errorMessage={errorMessage}
                                        pageDisabled={!!props?.id}
                                    />
                                </Box>
                            }
                            {dataPromotion?.type &&
                                <Box>
                                    <FormPromotionFooter
                                        dataEdit={dataPromotion}
                                        handleChangeDetailPromotion={handleChangeDetailPromotion}
                                        branches={branches}
                                        errorMessage={errorMessage}
                                        pageDisabled={!!props?.id}
                                    />
                                </Box>
                            }

                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default FormPromotions
