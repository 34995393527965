import { Box, Collapse, Grid, Tooltip, Typography } from '@mui/material'
import { colors } from 'constants/theme'
import { BoxFormCollapse } from 'features/setting/branch-service-point/branch/style'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ChannelBox } from './PaymentChannels'
import { useDispatch, useSelector } from 'react-redux'
import { setPaymentChannelByKey, setSummerySubByKey, setSummerySubTreatmentRight, setSummerySubTreatmentRightByKey, setSummeryTreatmentRight, setSummeryTreatmentRightByKey, summery, summerySub, summerySubTreatmentRight, summeryTreatmentRight, valPayment } from 'app/slice/payment.slice'
import IconPromotion from 'assets/icon/icon-promotion';
import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'
import PromotionsApi from 'api/promotion/promotion.api'
import _ from 'lodash'
import PrivilegeList from './PrivilegeList'
import IconGiftVoucher from 'assets/icon/icon-gift-voucher'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputTextField from 'component/Input/InputTextField'
import MainButton from 'new-components/buttons/main-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import InfoIcon from '@mui/icons-material/Info';
import { ModalVoucherSelectLot } from 'component/Treatments/PopupManageTreatmentCourse'
import { PaymentInterface } from 'api/counter/payment/payment-interface'
import VoucherApi, { VoucherPaymentFindByCodeProps } from 'api/voucher/voucher.api'
import { notiError } from 'component/notifications/notifications'
import { getCrm } from 'utils/app.utils'
import IconClinicDisabeld from 'assets/icon/icon-clinic-disabeld'

type PaymentPromotionProps = {
    menu?: 'PAYMENT' | 'OVERDUE' | 'RETROSPECTIVE' | 'EDIT_PAYMENT'
    paymentChannels: any
    handleSelectPaymentChannel: (channel: any) => void
    checkIndex: (key: string) => number
    selectChannels: any
    promotionPayment: any
    voucherPayment: any
    treatment: any
    disableTextFiled: (chanel: number) => boolean
    patientCaseId?: number
}

const PaymentPromotion = (props: PaymentPromotionProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const channelShow = [7, 8]

    const valSummerySub = useSelector(summerySub)
    const valSummeryTreatmentRight = useSelector(summeryTreatmentRight)
    const valSummerySubTreatmentRight = useSelector(summerySubTreatmentRight)

    const [checkedVoucher, setCheckedVoucher] = useState<boolean>(false)
    const [searchVoucher, setSearchVoucher] = useState<string>("")
    const [errorSearchVoucher, setErrorSearchVoucher] = useState<string>("")
    const [voucherItem, setVoucherItem] = useState<any>({})
    const [popVoucherSelectLot, setPopVoucherSelectLot] = useState<boolean>(false)
    const [dataPopVoucherSelectLot, setDataPopVoucherSelectLot] = useState<any>({})

    useEffect(() => {
        if (valSummeryTreatmentRight[8]) {
            const pcId: any = 8
            const objBalance: any = {}
            const objValue: any = {}
            if (valSummerySubTreatmentRight[pcId]) {
                for (const ptrId in valSummerySubTreatmentRight[pcId]) {
                    if (Object.prototype.hasOwnProperty.call(valSummerySubTreatmentRight[pcId], ptrId)) {
                        const strSub = valSummerySubTreatmentRight[pcId][ptrId];
                        for (const index in strSub) {
                            if (Object.prototype.hasOwnProperty.call(strSub, index)) {
                                const valSub = strSub[index];
                                const { value, treatmentPrice } = valSub
                                if (objBalance[index] === undefined) objBalance[index] = treatmentPrice
                                if (objValue[index] === undefined) objValue[index] = 0
                                // dispatch(setSummerySubTreatmentRightByKey({ key: pcId, keySub: index, patientTreatmentRightId: ptrId, value: { ...valSub, paidBalance: objBalance[index] } }))
                                objBalance[index] -= value
                                objValue[index] += value
                            }
                        }
                    }
                }
                _.map(objBalance, (balance: any, index: any) => {
                    const sub: any = valSummerySub[pcId] !== undefined ? valSummerySub[pcId][index] : null
                    if (sub)
                        dispatch(setSummerySubByKey({ key: pcId, keySub: index, value: { ...sub, value: objValue[index] } }))
                })
            }
        }

        if (valSummeryTreatmentRight[7]) {
            const pcId: any = 7
            const objBalance: any = {}
            const objValue: any = {}
            if (valSummerySubTreatmentRight[pcId]) {
                for (const ptrId in valSummerySubTreatmentRight[pcId]) {
                    if (Object.prototype.hasOwnProperty.call(valSummerySubTreatmentRight[pcId], ptrId)) {
                        const strSub = valSummerySubTreatmentRight[pcId][ptrId];
                        for (const index in strSub) {
                            if (Object.prototype.hasOwnProperty.call(strSub, index)) {
                                const valSub = strSub[index];
                                const { value, treatmentPrice } = valSub
                                if (objBalance[index] === undefined) objBalance[index] = treatmentPrice
                                if (objValue[index] === undefined) objValue[index] = 0
                                // dispatch(setSummerySubTreatmentRightByKey({ key: pcId, keySub: index, patientTreatmentRightId: ptrId, value: { ...valSub, paidBalance: objBalance[index] } }))
                                objBalance[index] -= value
                                objValue[index] += value
                            }
                        }
                    }
                }
                _.map(objBalance, (balance: any, index: any) => {
                    const sub: any = valSummerySub[pcId] !== undefined ? valSummerySub[pcId][index] : null
                    if (sub)
                        dispatch(setSummerySubByKey({ key: pcId, keySub: index, value: { ...sub, value: objValue[index] } }))
                })
            }
        }

    }, [valSummeryTreatmentRight])

    const onLoadVoucher = async () => {
        if (searchVoucher && searchVoucher !== '') {
            const condition: VoucherPaymentFindByCodeProps = {
                code: searchVoucher,
            }
            await VoucherApi.findVoucherPaymentByCode(props?.patientCaseId || 0, condition).then((res) => {
                handleVoucherSelect(res?.data)
            }).catch((err) => {
                const error = err?.response?.data
                if (error.statusCode === 404) {
                    if (error.message === "NOT_FOUND_VOUCHER_CODE") {
                        setErrorSearchVoucher(t(`PAYMENT.MESSAGE.ERROR.${error.message}`))
                    } else {
                        notiError(t(`PAYMENT.MESSAGE.ERROR.${error.message}`))
                    }
                } else {
                    notiError(t(`เกิดข้อผิดพลาด`))
                }
            })
        }
    }

    const onOpenPopVoucherSelectLot = (item: any) => {
        setDataPopVoucherSelectLot(item)
        setPopVoucherSelectLot(true)
    }

    const onClerPopVoucherSelectLot = () => {
        setDataPopVoucherSelectLot({})
        setPopVoucherSelectLot(false)
    }

    const handleVoucherSelect = (item?: any) => {
        setSearchVoucher(item?.voucherCode || '')
        if (item?.voucherListId) {
            setVoucherItem({ ...dataPopVoucherSelectLot, ...item })
            onClerPopVoucherSelectLot()
        } else {
            setVoucherItem({})
        }
    }

    const renderVoucher = () => {
        if (voucherItem?.voucherId) {
            return
        } else if (props?.voucherPayment?.length) {
            return (
                <Box width={"100%"} padding={'16px 0 0 62px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
                    {props?.voucherPayment?.map((item: any, index: number) => {
                        return (
                            <Box key={index} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                                <Box width={'140px'}>
                                    {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_NAME')}
                                </Box>
                                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                        {item?.voucherName}
                                        {item?.detail &&
                                            (
                                                <span>
                                                    <Tooltip title={item?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </span>
                                            )
                                        }
                                    </Box>
                                    <Box
                                        sx={{ textDecoration: 'underline', color: colors.themeSecondColor, cursor: 'pointer', width: 'fit-content', paddingLeft: '8px' }}
                                        onClick={() => onOpenPopVoucherSelectLot(item)}
                                    >
                                        {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_SELECT_INDEX', { index: item?.voucherDetails?.length || 0 })}
                                    </Box>
                                </Box>

                            </Box>
                        )
                    })}
                </Box>
            )
        } else {
            return (
                <Box width={"100%"} padding={'16px 0 0 62px'}>
                    <Box width={"100%"} alignContent={'center'} textAlign={'center'} color={'#808080'}>
                        {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_NONE')}
                    </Box>
                </Box>
            )
        }
    }

    const oChangePaymentCannel = (item: any) => {
        if (item.key === 'VOUCHER') {
            if (item.isActive) {
                setSearchVoucher('')
                setVoucherItem({})
            }
        }
        props.handleSelectPaymentChannel(item)
    }

    return (
        <>
            <Box padding={'0 16ox'} marginBottom={'8px'}>
                <Tooltip title={getCrm() ? '' : t('PAYMENT.FORM_PAYMENT.PRIVILEGES_PROMOTION_TOOLTIP')} placement={'top'}>
                    <BoxFormCollapse sx={{ backgroundColor: getCrm() ? '' : '#d0cece' }}>
                        {getCrm() ?
                            <Box fontSize={'18px'} fontWeight={'500'} >
                                {t('PAYMENT.FORM_PAYMENT.PRIVILEGES_PROMOTION', { index: (props.promotionPayment?.length || 0) + (props?.voucherPayment?.length || 0) })}
                            </Box>
                            : <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', 'img': { width: '24px', height: '24px' } }}>
                                <Box fontSize={'18px'} fontWeight={'500'}>
                                    {t('PAYMENT.FORM_PAYMENT.PRIVILEGES_PROMOTION_')}
                                </Box>
                                <IconClinicDisabeld />
                            </Box>
                        }
                        <Box
                            className={`box-icon-expand-more`}
                            sx={{ transform: `rotate(${open ? 180 : 0}deg)`, pointerEvents: getCrm() ? 'auto' : 'none' }}
                            onClick={() => setOpen(!open)}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    backgroundColor: colors.white,
                                    border: `0.5px solid ${colors.textLightGray}`,
                                    borderRadius: '50px',
                                    color: colors.themeMainColor
                                }}
                            />
                        </Box>
                    </BoxFormCollapse>
                </Tooltip>

                <Collapse in={open}  >
                    <Box padding={'16px'}>
                        <Grid container spacing={1} className="mt-0" sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px 0' }}>
                            {props.paymentChannels.map((item: any, index: number) => {
                                if (!channelShow.includes(item?.paymentChanel)) return
                                const key = `${item.key.toLowerCase()}Payment`
                                const newProps: any = { ...props }
                                const itemLength = (newProps?.[key]?.length || 0)
                                return (
                                    <Grid item xs={3} className=""
                                        sx={{
                                            minWidth: 'calc(100% / 3)',
                                            maxWidth: 'calc(100% / 3)',
                                            minHeight: '50px',
                                            maxHeight: '50px',
                                            paddingTop: '0px !important',
                                            display: 'block',
                                        }}
                                        key={index}
                                    >
                                        <ChannelBox
                                            onClick={() => oChangePaymentCannel(item)}
                                            className={`align-items-center px-2 px-sm-0 text-ellipsis ${item.isActive ? 'active' : ''}`}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex start !important',
                                                paddingLeft: '24px !important',
                                                gap: '8px',
                                                width: '100%',
                                                height: '100%',
                                                borderWidth: '2.5px !important',
                                                '.number': {
                                                    paddingLeft: ' 4px !important',
                                                    textAlign: 'left'
                                                },
                                                'svg': {
                                                    height: '29px !important'
                                                },
                                                '.MuiTypography-root': {
                                                    width: 'fit-content !important'
                                                }

                                            }}
                                        >
                                            {props.checkIndex(item.key) !== 0 && <p className={'number'}>{props.checkIndex(item.key)}</p>}
                                            <Box sx={{ svg: { minWidth: '30px', height: '50px !important', filter: item.isActive ? '' : 'grayscale(1)', opacity: item.isActive ? '' : '.6' } }}>
                                                {item.key === 'VOUCHER' && <IconGiftVoucher />}
                                                {item.key === 'PROMOTION' && <IconPromotion />}
                                            </Box>

                                            <Tooltip title={t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${item.key}_LENGTH`, { index: itemLength })} arrow placement="right">
                                                <Typography className="pl-2 pl-sm-0 pt-sm-1 text-ellipsis channel-name">{t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${item.key}_LENGTH`, { index: (itemLength || 0) })}</Typography>
                                            </Tooltip>
                                        </ChannelBox>
                                    </Grid>
                                )
                            }).reverse()
                            }
                        </Grid>
                    </Box>
                </Collapse >
                {
                    props.selectChannels.map((item: any, index: number) => {
                        if (!channelShow.includes(item?.paymentChanel)) return
                        if (item?.paymentChanel === 8) {
                            return (
                                <Box key={index} padding={'16px'}>
                                    <UseStyled.TitlePayment>
                                        {`${index + 1}. `}
                                        {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${item.key}`)}
                                    </UseStyled.TitlePayment>
                                    <Box padding={'16px 0 0 0'} fontWeight={500} >
                                        {t('PAYMENT.FORM_PAYMENT.SUM_NET_PROMOTION')}
                                    </Box>
                                    <Box>
                                        {props?.promotionPayment?.map((item: any, index: number) => {
                                            return (
                                                <PrivilegeList
                                                    disableIcon={true}
                                                    type='TreatmentDiscount'
                                                    menu={props?.menu}
                                                    paymentChannelId={8}
                                                    patientTreatmentRightId={item?.promotionBranchId}
                                                    treatmentRight={item}
                                                    key={index}
                                                    title={item?.promotionName}
                                                    treatments={props.treatment}
                                                    disableFiled={props.disableTextFiled(8)}
                                                    treatmentInfo={item?.detail}
                                                    discountHasExpired={false}
                                                />
                                            )
                                        })}
                                    </Box>

                                </Box>
                            )
                        }
                        if (item?.paymentChanel === 7) {
                            return (
                                <Box key={index} padding={'16px'}>
                                    <UseStyled.TitlePayment>
                                        {`${index + 1}. `}
                                        {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${item.key}`)}
                                    </UseStyled.TitlePayment>
                                    <Box padding={'16px 0 0 0'} fontWeight={500} >
                                        {t('ส่วนลด Gift Voucher')}
                                    </Box>
                                    <Box>
                                        {!voucherItem?.voucherListId &&
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', padding: "16px 14px 0 0" }}>
                                                <Box sx={{ '.MuiCheckbox-root': { padding: '0px !important' } }}>
                                                    <InputCheckbox onChange={() => setCheckedVoucher(!checkedVoucher)} checked={checkedVoucher} />
                                                </Box>
                                                <Box sx={{ width: '100%', '.MuiOutlinedInput-root': { paddingRight: '9px !important' }, '.MuiFormControl-root': { position: 'relative' }, '.MuiFormHelperText-root': { position: 'absolute', bottom: '-20px' } }}>
                                                    <InputTextField
                                                        label={t('รหัส Gift Voucher')}
                                                        onchange={(event) => {
                                                            setErrorSearchVoucher("")
                                                            setSearchVoucher(event.target.value)
                                                        }}
                                                        value={searchVoucher}
                                                        // onblur={() => setSearchVoucher(voucherItem?.voucherCode || '')}
                                                        inputProps={{
                                                            endAdornment: (
                                                                <Box display={'flex'} alignItems={'center'} gap={'8px'}>

                                                                    <FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} />
                                                                </Box>
                                                            ),
                                                        }}
                                                        helperText={errorSearchVoucher}
                                                    />
                                                </Box>
                                                <Box sx={{ minWidth: '100px', button: { width: '100%' } }}>
                                                    <MainButton
                                                        onClick={onLoadVoucher}
                                                        title={t('เช็ครหัส')}
                                                        type={'primary'}
                                                        variant={'outlined'}
                                                    />
                                                </Box>
                                            </Box>
                                        }

                                        <Box >
                                            {renderVoucher()}
                                        </Box>

                                        {voucherItem?.voucherListId &&
                                            <PrivilegeList
                                                disableIcon={true}
                                                type='TreatmentDiscount'
                                                menu={props?.menu}
                                                paymentChannelId={7}
                                                patientTreatmentRightId={Number(voucherItem?.voucherListId)}
                                                treatmentRight={voucherItem}
                                                key={0}
                                                title={`${voucherItem?.voucherCode} ${voucherItem?.voucherName}`}
                                                treatments={props.treatment}
                                                disableFiled={props.disableTextFiled(7)}
                                                treatmentInfo={voucherItem?.detail}
                                                discountHasExpired={false}
                                                onCallbackDel={(id: number) => {
                                                    setVoucherItem({})
                                                    setSearchVoucher("")
                                                }}
                                            />
                                        }
                                    </Box>

                                </Box>
                            )
                        }

                    })
                }

            </Box >

            {
                popVoucherSelectLot &&
                <ModalVoucherSelectLot
                    onClose={onClerPopVoucherSelectLot}
                    data={dataPopVoucherSelectLot}
                    handleVoucherSelect={handleVoucherSelect}
                />
            }
        </>

    )
}

export default PaymentPromotion