import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'
import { Box, Typography, Divider } from '@mui/material'
import _ from 'lodash'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles, { CustomTable } from 'features/setting/treatments/recalls/style'

/** API */
import RecallApi from 'api/setting/treatments/recall.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { colors } from 'constants/theme'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputTextarea from 'component/Input/InputTextarea'

import Recall from './recall'
import { KeyboardReturn } from '@mui/icons-material'
import AlertModal from 'new-components/modal/alert-modal'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}
const initStateErrorMessage = {
  RECALL_NOT_FOUND: ``,
  RECALL_ALREADY_IN_SYSTEM: ``,
  CREATE_RECALL_WAS_UNSUCCESSFU: ``,
  UPDATE_RECALL_UNSUCCESSFUL: ``,
  IS_DUPLICATE_RECALL_NAME: ``,
  IS_DUPLICATE_RECALL_NAME_EN: ``,
  OPERATIVE_TYPE: ``,
  OPERATIVE_TYPE_NUMBER_BASE: ``,
  OPERATIVE_TYPE_ANY_REQUIRED: ``,
  RECALL_AMOUNT: ``,
  RECALL_AMOUNT_NUMBER_BASE: ``,
  RECALL_AMOUNT_ANY_REQUIRED: ``,
  MESSAGE: ``,
  MESSAGE_STRING_VALID: ``,
  MESSAGE_ANY_REQUIRED: ``
}

export default function Recalls() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [recalls, setRecalls] = useState([])

  const [isAlertDel, setIsAlertDel] = useState<number>(0)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await RecallApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setRecalls(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])


  useEffect(() => {
    loadData()
  }, [loadData])

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('RECALL.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          RecallApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('RECALL.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('RECALL.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = async (id: number) => {
    await RecallApi.remove(id)
      .then((resp) => {
        notiSuccess(t('RECALL.MESSAGE.SUCCESS.DELETE'))
        setIsAlertDel(0)
        loadData()
      })
      .catch((e) => {
        notiError(t('RECALL.MESSAGE.ERROR'))
      })
  }

  const onEdit = (data: any) => {
    history.push(`${routeName.recalls}/update`, data)
  }
  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '80px' },
    { id: 'recallOperative', disablePadding: false, label: t('RECALL.TABLE.CELL.RECALL_TYPE_OPERATIVE') },
    { id: 'recallOperative', disablePadding: false, label: t('RECALL.TABLE.CELL.RECALL_OPERATIVE') },
    { id: 'recallAmount', disablePadding: false, label: t('RECALL.TABLE.CELL.RECALL_AMOUNT') },
    { id: 'status', disablePadding: false, label: t('RECALL.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('RECALL.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { recallId: rcId, recallAmount: rcAmount, recallUnit: rcUnit, updatedBy, updatedAt,
      status: rcStatus, message: rcMessage, operativeTypeName: optTypeName, operativeName: rcOperativeName } = objData
    const statusBtnActive = rcStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = rcStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: rcId,
      id: rcId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: optTypeName || '-' },
        { option: 'TEXT', align: 'left', label: rcOperativeName || '-' },
        { option: 'TEXT', align: 'left', label: `${rcAmount} ${t(`TIME_DURATION_TYPE.${rcUnit}`)}` },
        { option: 'STATUS', align: 'center', label: rcStatus, class: 'text-ellipsis' },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt }, class: 'text-ellipsis' },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => setIsAlertDel(rcId)}
      />
    )
  }

  const renderMsgAlertDel = () => {
    const data: any = recalls.find((item: any) => Number(item.recallId) === Number(isAlertDel))
    if (data?.recallAmount) {
      return `${data?.recallAmount} ${t(`TIME_DURATION_TYPE.${data.recallUnit}`)}`
    }
    return ''

  }

  return (
    <div className={classes.root}>
      <AlertModal
        isOpen={!!isAlertDel}
        title={t('PROMOTION_TYPE.MESSAGE.DEL_TITLE')}
        message={t('RECALL.MESSAGE.DEL_SUBTITLE', { recall: renderMsgAlertDel() })}
        type="error"
        onSubmit={{ onClick: () => onDelete(isAlertDel), title: t("BUTTON.SUBMIT") }}
        onCancel={{
          onClick: () => setIsAlertDel(0), title: t("BUTTON.CANCEL")
        }}
        handleClose={() => setIsAlertDel(0)}
      />
      <Card className="border-0 h-100">
        <HeaderCard text={t('RECALL.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={9}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('RECALL.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={3} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => window.open(`${routeName.recalls}/create`)} textButton={t('RECALL.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <CustomTable>
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                rowCount={rowCount}
                headCells={headCells}
                rowsData={recalls.map((val, i) => {
                  return renderData(val, i)
                })}
              />
            </CustomTable>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
