import { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** UTILS || SLICE */
import { showModal } from 'app/slice/modal.slice'
import { imgs } from 'constants/images'

/** API */
import TreatmentsAPI from 'api/dentists/treatment.api'
import { fileUrl } from 'api/api'

/** COMPONENT */
import Breadcrumb, { BreadcrumbType } from 'component/Breadcrumb/Breadcrumb'
import SearchPatients from 'component/SearchPatients'
import BtnProfile from 'component/Treatments/BtnProfile'
import PopupPatientDetail from 'component/Treatments/PopupPatientDetail'
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as TreatmentsUseStyled from 'features/treatments/UseStyle'
import { Col, Dropdown, Row } from 'react-bootstrap'
import InputTextSearch from 'component/Input/InputTextSearch'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import OverdueApi from 'api/finances/overdue.api'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { dateToView } from 'utils/date.utils'
import { getClinicInfo, numberToCurrencyFormat } from 'utils/app.utils'
import ButtonCustom from 'component/Button/ButtonCustom'
import PopupPayment from 'features/finance/payment/component/popupPayment/Index'
import { setTreatmentByKey } from 'app/slice/treatment.slice'
import { PrintReceiptInterface } from 'api/counter/payment/payment-interface'
import PaymentApi from 'api/counter/payment/payment.api'
import Loading from 'component/Loading'
import HistoryPayment from '../payment/component/history-payment'
import TreatmentSummaryApi from 'api/dentists/treatment-summary.api'
import FunctionPrint from 'component/Print/print'
import PdfOutStandingPayment from 'component/Print/pdfOutStandingPayment'
import PopupOutStandingPayment from 'component/Treatments/PopupOutStandingPayment'

import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SummaryValue = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: 4,
  padding: '0.5rem',
  backgroundColor: colors.white,
  color: colors.black,
  border: `1px solid ${colors.black12}`,
  whiteSpace: 'nowrap'
}))

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(1)': {
          width: '10%'
        },
        '&:nth-of-type(2)': {
          width: '40%'
        },
        '&:nth-of-type(3)': {
          width: '10%'
        },
        '&:nth-of-type(4)': {
          width: '10%'
        },
        '&:nth-of-type(5)': {
          width: '15%'
        },
        '&:nth-of-type(6)': {
          width: '20%',
          padding: 15
        },
        [theme.breakpoints.down('lg')]: {
          '&:nth-of-type(1)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(2)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(3)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(4)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(5)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(6)': {
            width: 'auto',
            padding: 15,
            whiteSpace: 'nowrap'
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&:nth-of-type(1)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(2)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(3)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(4)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(5)': {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          '&:nth-of-type(6)': {
            width: 'auto',
            padding: 15,
            whiteSpace: 'nowrap'
          }
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'middle',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        [theme.breakpoints.down('sm')]: {
          verticalAlign: 'top'
        }
      }
    }
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
    display: 'none'
  }
}))

export const DropdownDocument = styled(Dropdown)(({ theme }) => ({
  '.dropdown-toggle': {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    border: `1px solid ${colors.themeSecondColor} !important`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none !important',
    height: 36,
    '&:after': {
      marginLeft: '0.5rem'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    }
  },
  '.dropdown-menu': {
    position: 'fixed !important',
    marginBottom: 4,
    borderRadius: 4,
    background: colors.white,
    border: `1px solid ${colors.lightGray}`,
    '.dropdown-item': {
      width: "auto",
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

export const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
  paddingLeft: 32,
  paddingRight: 32
}))
export const DropdownTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500
}))

type ColumnAction = {
  orderId: number
  orderPaymentId: number
  onPaid?: (orderId: number, orderPaymentId: number) => void
  onDocumentReceipt?: (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL' | 'PDF_OUT' | 'PDF_OUT_FINISHED') => void
  disabledOutFinished?: boolean
}

export default function OverdueDetail() {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const breadcrumbs: BreadcrumbType[] = [{ title: 'OVERDUE_DETAIL.BREADCRUMB.MAIN', path: routeName.overdue }, { title: 'OVERDUE_DETAIL.BREADCRUMB.DETAIL' }]
  const branchInfo = getClinicInfo()

  const [patientId, setPatientId] = useState(0)
  const [patient, setPatient]: any = useState({})
  const [overDue, setOverdue] = useState([])
  const [isShowPopupDetail, setIsShowPopupDetail] = useState<boolean>(false)
  const [sum, setSum] = useState<number>(0)
  const [sendPayment, setSendPayment] = useState<boolean>(false)
  const [orderPaymentId, setOrderPaymentId] = useState<number>(0)
  const [initBalance, setInitBalance] = useState<any>({})
  const [logHistories, setLogHistories] = useState<any[]>([])

  const [modalData, setModalData] = useState<{ isShow: boolean; data: any }>({ isShow: false, data: {} })

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [rowCoutOverdue, setRowCoutOverdue] = useState<number>(0)
  const [sortBy, setSortBy] = useState('lastPad')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [rangeDate, setRangeDate] = useState<string[]>(emptyRangePicker)

  const pdfOutRef: any = useRef()
  const [dataOut, setDataOut] = useState<any>({})
  const [popPdfOut, setPopPdfOut] = useState<boolean>(false)
  const [popPdfOutType, setPopPdfOutType] = useState<'OUT' | 'OUT_FINISHED'>('OUT')
  const [popPdfOutTotal, setPopPdfOutTotal] = useState<number>(0)
  const [disabledSelect_OutFinished, setDisabledSelect_OutFinished] = useState<boolean>(false)

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (rangeDate) {
      if ((rangeDate[0] !== null || rangeDate[0] !== '') && (rangeDate[1] !== null || rangeDate[1] !== '')) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    const res = await OverdueApi.findOverduePatient(condition, patientId)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setRowCoutOverdue(res?.data?.countOverdue || 0)
      setSum(res.headers['x-sum'] || 0)
      setOverdue(res.data?.rows || [])
    }
  }, [page, pageLimit, search, sortBy, sortType, rangeDate, patientId])

  useEffect(() => {
    if (patientId !== 0) loadData()
  }, [loadData])

  const getPatientId = (id: number) => {
    setPatientId(id)
    loadPatient(id)
    const currentPath = window.location.pathname;
    const val = { patientId: id }
    history.push(currentPath, val)
  }

  const loadPatient = async (valuePatientId: number) => {
    setPatient([])
    if (valuePatientId) {
      const patientData = await TreatmentsAPI.findPatient(valuePatientId)
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) setPatient(patientData.data)
    }
  }

  const handleClear = () => {
    setPatientId(0)
    setPatient({})
    setRowCount(0)
    setSum(0)
    setOverdue([])
  }

  const onViewHistory = () => {
    dispatch(showModal())
    setIsShowPopupDetail(true)
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  useEffect(() => {
    const patientState: any = location.state
    if (!_.isEmpty(patientState) && patientState.patientId) {
      setPatientId(patientState.patientId)
      loadPatient(patientState.patientId)
    }
  }, [])

  const handlePrintPdfOut = async (type: 'PDF_OUT' | 'PDF_OUT_FINISHED', patientCase: number, isShow?: 'DF' | 'MODAL') => {
    const outStandingPayment = await TreatmentSummaryApi.findTreatmentSummary(patientCase)
    const newDataOverDue: any = [...overDue]
    const dataOverDue = newDataOverDue.find((item: any) => item['order.patientCaseId'] === patientCase)
    let newDataOut: any = {
      patientFullName: `${patient?.prefix?.prefixName}${patient?.firstname} ${patient?.lastname}`,
      patientCode: branchInfo?.patientCodeAppointment === '1' ? patient?.cnNumber : branchInfo?.patientCodeAppointment === '0' ? patient?.branchCnNumber : patient?.snCode || '-',
      paymentNumber: dataOverDue?.paymentNumber || null,
      patientCaseId: patientCase,
      orderId: dataOverDue?.orderId || 0
    }
    if (type === 'PDF_OUT_FINISHED' && dataOverDue?.patientSignature) {
      newDataOut = {
        ...newDataOut,
        price: dataOverDue?.overdueTotal || 0,
        dateEnd: dataOverDue?.overdueDate || '',
        dateStart: dataOverDue?.overdueDateStart || '',
        doctorId: dataOverDue?.doctorId || 0,
        doctorName: dataOverDue?.doctorName || 0,
        managerName: dataOverDue?.managerName || '',
        signature: {
          patientSignature: dataOverDue?.patientSignature || '',
          doctorSignature: dataOverDue?.doctorSignature || '',
          managerSignature: dataOverDue?.managerSignature || '',
        },
      }

    } else {
      newDataOut = {
        ...newDataOut,
        price: outStandingPayment?.data?.overdueTotal || 0,
        dateEnd: outStandingPayment?.data?.overdueDate || '',
        dateStart: outStandingPayment?.data?.overdueDateStart || moment().format('YYYY-MM-DD') || '',
        doctorId: outStandingPayment?.data?.doctorId || 0,
        doctorName: outStandingPayment?.data?.doctorName || 0,
        managerName: outStandingPayment?.data?.managerName || '',
        signature: {
          patientSignature: outStandingPayment?.data?.patientSignature || '',
          doctorSignature: outStandingPayment?.data?.doctorSignature || '',
          managerSignature: outStandingPayment?.data?.managerSignature || '',
        },
      }
    }
    setDataOut(newDataOut)

    if (type === 'PDF_OUT_FINISHED') {
      setPopPdfOutType('OUT_FINISHED')
      if (dataOverDue?.patientSignature) {
        const divPdf: any = document.querySelectorAll('.pdf-out-btn');
        if (divPdf?.length) {
          divPdf[0]?.click();
        }
      } else {
        setDisabledSelect_OutFinished(outStandingPayment?.data?.patientSignature)
        dispatch(showModal())
        setPopPdfOut(true)
      }
    } else {
      setPopPdfOutType('OUT')
      if (!newDataOut?.signature?.patientSignature) {
        dispatch(showModal())
        setPopPdfOut(true)
      } else {
        const divPdf: any = document.querySelectorAll('.pdf-out-btn');
        if (divPdf?.length) {
          divPdf[0]?.click();
        }
      }
    }
  }

  const onDocument = async (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL' | 'PDF_OUT' | 'PDF_OUT_FINISHED', paymentId: number, isShow?: 'DF' | 'MODAL') => {
    const printProp: PrintReceiptInterface = { lang: 'TH', type: 'FULL' }
    let receipt: any = ''
    setLoading(true)
    if (type === 'THERMAL') {
      printProp.hasServicePrice = isShow === 'DF' ? "1" : '0'
      receipt = await PaymentApi.printThermal(paymentId, printProp)
    } else if (type === 'A5' || type === 'A5_ORI' || type === 'A5_COPY' || type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') {
      if (type === 'A5_ORI' || type === 'A5_EN_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_COPY' || type === 'A5_EN_COPY') printProp.type = 'COPY'

      if (type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') printProp.lang = 'EN'

      receipt = await PaymentApi.printA5(paymentId, printProp)
    } else if (type === 'A5_LANDSCAPE' || type === 'A5_LANDSCAPE_ORI' || type === 'A5_LANDSCAPE_COPY') {
      printProp.hasServicePrice = isShow === 'DF' ? "1" : '0'
      printProp.orientation = 'LANDSCAPE'
      if (type === 'A5_LANDSCAPE_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_LANDSCAPE_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA5(paymentId, printProp)
    } else if (type === 'A4' || type === 'A4_ORI' || type === 'A4_COPY') {
      if (type === 'A4_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A4_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA4(paymentId, printProp)
    } else if (type === 'PDF_OUT' || type === 'PDF_OUT_FINISHED') {
      await handlePrintPdfOut(type, paymentId, isShow)
    }
    setLoading(false)
    if (receipt && receipt.status === 200) return window.open(`${fileUrl}/${receipt.data}`, '_blank')
    return false
  }

  const headCells = [
    { id: 'vn', disablePadding: false, label: 'VN' },
    { id: 'num', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.NO_RECEIPT') },
    { id: 'total', align: 'right', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.SUM_TOTAL') },
    { id: 'overdue', align: 'right', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.SUM_PAYED') },
    { id: 'date', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.DATE') },
    { id: 'status', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.STATUS') },
    { id: 'typeXray', align: 'center', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.NAME_RECORDER') },
    { id: 'action-treatment', disablePadding: false, align: 'center', label: t('Action') }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { patientId: id, vnNumber, createdBy, lastPad, overdue, paymentNumber, orderId, orderPaymentId: valueOrderPaymentId, updatedAt, updatedBy, overdueAl } = objData
    const totalPrice = objData['order.totalPrice'] ? objData['order.totalPrice'] : 0
    const amountPaid = objData['order.amountPaid'] ? objData['order.amountPaid'] : 0
    const patientCaseId = objData['order.patientCaseId']
    const overduePrice = totalPrice - amountPaid

    const checkDisabledOutFinished = overdue > 0 || false

    const objRenderData = {
      key: String(no),
      id: id,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'left', label: vnNumber },
        { option: 'TEXT', align: 'left', label: paymentNumber },
        { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(totalPrice) },
        { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(overdue || 0) },
        { option: 'TEXT', align: 'left', label: dateToView(lastPad) },
        // <FontAwesomeIcon icon={faCircle} color={column.label === 'ACTIVE' ? colors.statusActive : colors.statusInactive} />
        {
          option: 'COMPONENT', align: 'center', component: (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
              <FontAwesomeIcon icon={faCircle} color={!checkDisabledOutFinished ? colors.statusActive : colors.statusInactive} />
              <Box>{t(`OVERDUE_DETAIL.TABLE.OVERDUE${checkDisabledOutFinished ? '' : '_NONE'}`)}</Box>
            </Box>
          )
        },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <ColumnAction
              orderId={orderId}
              orderPaymentId={valueOrderPaymentId}
              onDocumentReceipt={(type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL' | 'PDF_OUT' | 'PDF_OUT_FINISHED') => {
                const paymentId = (type === 'PDF_OUT' || type === 'PDF_OUT_FINISHED') ? patientCaseId : valueOrderPaymentId
                setPopPdfOutTotal(overdueAl || overdue || objData['order.totalPrice'] || 0)
                onDocument(type, paymentId)
              }}
              disabledOutFinished={checkDisabledOutFinished}
            />
          )
        }
      ]
    }
    return <TableRowCommon {...objRenderData} />
  }

  const onPaidOverdule = async (orderId: number, valueOrderPaymentId: number) => {
    const resp = await OverdueApi.findDetailPayment(orderId, valueOrderPaymentId)
    if (resp.status === 200) {
      const summeryProducts: any = []
      if (!_.isEmpty(resp.data.orderOrderProducts)) {
        _.map(resp.data.orderOrderProducts, (prod, index) => {
          summeryProducts.push({ ...prod, total: prod.price * prod.qty - prod.discount + prod.additional })
        })
      }
      const summeryTreatments: any[] = [...resp.data.orderOrderTreatments, ...summeryProducts]
      setOrderPaymentId(resp.data.orderPaymentId)
      const items: any = {}
      const histories: any[] = _.map(resp.data.history, (his: any) => {
        const itemPaids: any = {}
        const paidHistory: any = []
        let amountItemPaid: any = 0
        const paidItems: any[] = _.map(his.orderPaymentChannels, (channel: any) => {
          // let abc: any = []
          return _.map(channel.orderPaymentItems, (payItem: any, i: number) => {
            if (items[payItem.refItemId] === undefined) items[payItem.refItemId] = 0
            items[payItem.refItemId] = items[payItem.refItemId] + payItem.amountPaid

            if (!paidHistory[i]) paidHistory[i] = payItem.amountPaid
            amountItemPaid += payItem.amountPaid

            if (itemPaids[payItem.refItemType] === undefined) itemPaids[payItem.refItemType] = {}
            if (itemPaids[payItem.refItemType][payItem.refItemId] === undefined) itemPaids[payItem.refItemType][payItem.refItemId] = 0
            itemPaids[payItem.refItemType][payItem.refItemId] = itemPaids[payItem.refItemType][payItem.refItemId] + payItem.amountPaid

            return {
              refItemId: payItem.refItemId,
              refItemType: payItem.refItemType,
              itemName: payItem.itemName,
              amountPaid: payItem.amountPaid
            }
          })
        })


        return {
          createdAt: his.createdAt,
          createdBy: his.createdBy,
          amountPaid: parseFloat(his.amountPaid) + parseFloat(his.amountAdvance),
          paidItems,
          itemPaids,
          paidHistory,
          amountItemPaid,
          itemBalances: _.map(summeryTreatments, (st: any) => {
            const type = st.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
            const refId = st.orderTreatmentId || st.orderProductId
            let paid = 0
            if (itemPaids[type] !== undefined && itemPaids[type][refId] !== undefined)
              paid = itemPaids[type][refId]

            return {
              itemCode: st.operativeCode || st.itemCode || '',
              itemName: st.operativeName || st.itemName || '',
              total: st.total,
              paid: paid
            }
          })
        }
      })
      setLogHistories(histories)
      const balanceTemp: any = {}
      _.map(Object.keys(items), (itemId: any) => {
        const index: any = _.findIndex(summeryTreatments, (st: any) => String(st.orderTreatmentId || st.orderProductId) === String(itemId))

        if (balanceTemp[index] === undefined) balanceTemp[index] = 0
        balanceTemp[index] = items[itemId]
      })
      setInitBalance(balanceTemp)
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: summeryTreatments }))
    }

    setSendPayment(true)
  }
  const onPaidHistory = async (orderId: number, valueOrderPaymentId: number) => {
    const resp = await OverdueApi.findDetailPayment(orderId, valueOrderPaymentId)
    if (resp.status === 200) {
      setModalData({ isShow: true, data: resp?.data?.history || {} })
      dispatch(showModal())
    }
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => setPage(val)

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  return (
    <Box className="px-3 pt-3 pb-4">
      <Loading show={loading} type="fullLoading" />
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Typography sx={{ fontSize: 20, fontWeight: 500 }}>{t('OVERDUE_DETAIL.TITLE')}</Typography>
      <Box className="mt-4" sx={{ width: { md: '80%', lg: '70%', xl: '45%' } }}>
        <SearchPatients status="ACTIVE" callbackPatientId={getPatientId} patient={patient} patientId={patient.patientId} onClear={handleClear} />
      </Box>
      <TreatmentsUseStyled.CardProfile className={`d-flex px-3 mt-3 ${!patient.patientId ? 'pe-none' : ''}`} onClick={onViewHistory}>
        <BtnProfile disabled={!patient.patientId} profile={renderImage(patient.profile)} branchCnNumber={patient.branchCnNumber} firstName={patient.firstname} lastName={patient.lastname} rankId={patient.rankId} lastVisit={patient.updatedAt} />
      </TreatmentsUseStyled.CardProfile>
      <PopupPatientDetail type="overdue" profile={renderImage(patient.profile)} patient={patient} showPopup={isShowPopupDetail} onClose={() => setIsShowPopupDetail(false)} />
      <Box className="pt-3">
        <Row className="flex-row">
          <Col xs={12} sm={6} lg={4} className="pt-2 pr-sm-1">
            <InputTextSearch
              keyInput={'search'}
              label={t('OVERDUE_DETAIL.DOCUMENT.SEARCH')}
              value={search}
              onchange={(event) => {
                setSearch(event.target.value)
                setPage(1)
              }}
              onClear={(event) => {
                setSearch('')
                setPage(1)
              }}
            />
          </Col>
          <Col xs={12} sm={6} lg={4} xl={'auto'} className="pt-2 pl-sm-1">
            <Box className="d-flex flex-column flex-sm-row" sx={{ minWidth: { lg: '320px' } }}>
              <div className="pt-sm-0 w-100">
                <InputSecondNewRangePicker
                  value={rangeDate || emptyRangePicker}
                  inputHeight={32}
                  allowClear
                  onchange={(val: any) => {
                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    setPage(1)
                  }}
                  onClear={() => setRangeDate(emptyRangePicker)}
                />
              </div>
            </Box>
          </Col>
          <Col xs={12} md={6} lg={'auto'} className="ml-auto pt-2 pl-md-1 pl-lg-0">
            <div className="d-flex">
              <SummaryValue style={{ marginRight: '4px' }}>
                <div style={{ fontWeight: 'bold', fontSize: 20 }}>
                  {rowCoutOverdue} {t('OVERDUE_DETAIL.DOCUMENT.LIST')}
                </div>
                <div>{t('OVERDUE_DETAIL.DOCUMENT.UNPAID_RECEIPTS')}</div>
              </SummaryValue>
              <SummaryValue style={{ marginLeft: '4px' }}>
                <div style={{ fontWeight: 'bold', fontSize: 20 }}>
                  {numberToCurrencyFormat(Number(sum))} {t('OVERDUE_DETAIL.DOCUMENT.BATH')}
                </div>
                <div>{t('OVERDUE_DETAIL.DOCUMENT.TOTAL_OUTSTANDING')}</div>
              </SummaryValue>
            </div>
          </Col>
        </Row>
      </Box>
      <div className={'mt-3'}>
        <TableWidth>
          <TableCustom
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            onSort={onRequestSort}
            setPageLimit={handleChangeRowsPerPage}
            setPage={handleChangePage}
            rowCount={rowCount}
            headCells={headCells}
            rowsData={overDue.map((val, i) => renderData(val, i))}
          />
        </TableWidth>
        <PopupPayment
          type="OVERDUE"
          initBalance={initBalance}
          orderPaymentId={orderPaymentId}
          histories={logHistories}
          isShow={sendPayment}
          setIsShow={(show: boolean) => setSendPayment(show)}
          patient={patient}
          onSuccess={() => {
            setSendPayment(false)
            setSearch('')
            setPage(1)
            loadData()
          }}
        />
        <HistoryPayment {...modalData} onReset={() => setModalData({ isShow: false, data: {} })} />
      </div>

      {popPdfOut &&
        <PopupOutStandingPayment
          patient={patient}
          onClose={() => {
            setPopPdfOut(false)
          }}
          treatments={{ dataOut: dataOut, total: popPdfOutTotal }}
          onSave={() => loadData()}
          type={popPdfOutType}
          disabledSelect_OutFinished={disabledSelect_OutFinished}
        />
      }

      <Box sx={{ display: 'none' }}>
        <FunctionPrint
          content={() => pdfOutRef.current}
          removeIfram
          trigger={() => (
            <ButtonCustom
              className={`pdf-out-btn`}
              variant={"outlined"}
              textButton={t("BUTTON.PRINT")}
            // onClick={() =>}
            />
          )}
          style={'@page { size: 210mm 450px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}

        />
      </Box>
      <Box ref={pdfOutRef} className={'print-show'}>
        <PdfOutStandingPayment
          dataOut={dataOut}
          type={popPdfOutType}
        />
      </Box>

    </Box>
  )

  function ColumnAction(props: ColumnAction) {
    const { t } = useTranslation()

    const onPrint = (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL' | 'PDF_OUT' | 'PDF_OUT_FINISHED') => {
      if (props.onDocumentReceipt) return props.onDocumentReceipt(type)
      else return false
    }
    return (
      <Box className="d-flex align-items-center">
        <ButtonCustom
          onClick={() => onPaidOverdule(props.orderId, props.orderPaymentId)}
          disabled={!props?.disabledOutFinished}
          textButton={t('OVERDUE_DETAIL.BUTTON.PAID_TREATMENT')}
          className={'mr-2 text-nowrap'}
          btnStyle={{ height: '36px', backgroundColor: colors.themeSecondColor, fontSize: '16px' }}
          style={{ margin: 0 }}
        />
        <ButtonCustom
          onClick={() => onPaidHistory(props.orderId, props.orderPaymentId)}
          textButton={t('OVERDUE_DETAIL.BUTTON.PAID_HISTORY')}
          className={'mr-2 text-nowrap'}
          btnStyle={{ height: '36px', fontSize: '16px' }}
          style={{ margin: 0 }}
          variant={"outlined"}
        />
        <ButtonCustom
          onClick={() => onPrint('PDF_OUT')}
          textButton={t('MODAL_OUTSTANDINGPAYMENT.PDF')}
          className={'mr-2 text-nowrap'}
          btnStyle={{ height: '36px', fontSize: '16px' }}
          style={{ margin: 0 }}
          variant={"outlined"}
        />
        <ButtonCustom
          onClick={() => onPrint('PDF_OUT_FINISHED')}
          textButton={t('MODAL_OUTSTANDINGPAYMENT_FINISHED.PDF')}
          className={'mr-2 text-nowrap'}
          disabled={props?.disabledOutFinished}
          btnStyle={{ height: '36px', fontSize: '16px' }}
          style={{ margin: 0 }}
          variant={"outlined"}
        />

        <DropdownDocument>
          <Dropdown.Toggle id="dropdown-basic">{t('REGISTER.BUTTON.PRINT_DOCUMENT')}</Dropdown.Toggle>
          <Dropdown.Menu>
            <DropdownTitle className="px-3">{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT')}</DropdownTitle>
            <DropdownItem onClick={() => onPrint('A5')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_ORI')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_ORI')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_COPY')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_COPY')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_EN')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_EN_ORI')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN_ORI')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_EN_COPY')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN_COPY')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_LANDSCAPE')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_LANDSCAPE_ORI')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE_ORI')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A5_LANDSCAPE_COPY')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE_COPY')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A4')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A4_ORI')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4_ORI')}</DropdownItem>
            <DropdownItem onClick={() => onPrint('A4_COPY')}> {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4_COPY')} </DropdownItem>
            <DropdownItem onClick={() => onPrint('THERMAL')}> {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.THERMAL')} </DropdownItem>
            {/* <DropdownItem onClick={() => onPrint('PDF_OUT')}> {t('MODAL_OUTSTANDINGPAYMENT.PDF')} </DropdownItem>
            <DropdownItem onClick={() => onPrint('PDF_OUT_FINISHED')} disabled={props?.disabledOutFinished}> {t('MODAL_OUTSTANDINGPAYMENT_FINISHED.PDF')} </DropdownItem> */}
          </Dropdown.Menu>
        </DropdownDocument>
      </Box>
    )
  }
}
