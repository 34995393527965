import { Box } from '@mui/material'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormPromotions from './form-promotion'

type PopViewPromotionProps = {
    objData: any
    onClose: () => void
}

const PopViewPromotion = (props: PopViewPromotionProps) => {
    const { t } = useTranslation()
    return (
        <>
            <ModalCustom
                title={t('PROMOTION.REGISTER.BUTTON_VIEW')}
                modalStyle={`modal-pop-view-promotion`}
                size='xl'
                onClose={props.onClose}
                closeButton
                textBtnCancel={t('BUTTON.CANCEL')}
                onReset={props.onClose}
                alignFooter='end'
                component={(
                    <Box overflow={'hidden'}>
                        <FormPromotions id={props?.objData?.promotionId || 0} />
                    </Box>
                )}
            />
        </>
    )
}

export default PopViewPromotion