import { Box, Grid, InputAdornment, Typography } from "@mui/material"
import ButtonExport from "component/Button/ButtonExport"
import MainButton from "new-components/buttons/main-button"
import SelectInput from "new-components/inputs/select-input"
import TextInput from "new-components/inputs/text-input"
import AddCircleIcon from '@mui/icons-material/AddCircle'
import TableCustom from "component/Table/TableCustom"
import { useEffect, useRef, useState } from "react"
import VoucherApi from "api/voucher/voucher.api"
import TableRowCommon from "component/Table/TableRowCommon"
import TableRowExport from "component/Pdf/TableRowExport"
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import { isCopy, isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loading from "component/Loading"
import AlertModal from "new-components/modal/alert-modal"
import SnackbarComponent from "new-components/snackbar/snackbar-component"
import { useHistory } from "react-router-dom"
import { routeName } from 'routes/routes-name'
import PageModal from "new-components/modal/page-modal"
import CheckboxButton from "new-components/buttons/checkbox-button"
import _ from "lodash"
import SelectCheckBoxInput from "new-components/inputs/select_checkbox_input"
import moment from "moment"
import DateRangeInput from "new-components/inputs/date-range-input"
import { dateToView } from "utils/date.utils"
import PromotionsApi from "api/promotion/promotion.api"
import ComponentApi from "api/components-api"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from "react-i18next"

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView(),
    isCopy: isCopy(),
}

const Voucher = () => {
    ////-------------------
    ////    ROUTE
    ////-------------------
    const history = useHistory()

    ////-------------------
    ////    I18N
    ////-------------------
    const { t } = useTranslation()

    ////-------------------
    ////    STATE
    ////-------------------
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createAt')
    const [sortType, setSortType] = useState('DESC')
    const [search, setSearch] = useState('')
    const [statusSelect, setStatusSelect] = useState<string>('ALL')
    const [loading, setLoading] = useState<boolean>(true)

    const [dataVoucher, setDataVoucher] = useState<any>([])
    const [dataVoucherExport, setDataVoucherExport] = useState<any>([])

    const [status, setStatus] = useState('')
    const [id, setId] = useState<number>(0)
    const [name, setName] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [alertModal, setAlertModal] = useState(false)
    const [isOpenSnack, setIsOpenSnack] = useState(false)
    const [typeSnack, setTypeSnack] = useState<any>("success")
    const [labelSnack, setLabelSnack] = useState('')

    const [isOpenPageModal, setIsOpenPageModal] = useState(false)
    const [rowCountModal, setRowCountModal] = useState(0)
    const [pageModal, setPageModal] = useState(1)
    const [pageLimitModal, setPageLimitModal] = useState(10)
    const [dataVoucherById, setDataVoucherById] = useState<any>([])
    const [countWaiting, setCountWaiting] = useState('')
    const [countReady, setCountReady] = useState('')
    const [countUsed, setCountUsed] = useState('')
    const [countExpired, setCountExpired] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [searchModal, setSearchModal] = useState('')
    const [statusModal, setStatusModal] = useState<number[]>([])
    const [resultStatus, setResultStatus] = useState('')
    const [lotNumber, setLotNumber] = useState<any>([])
    const [resultLotNumber, setResultLotNumber] = useState('')
    const [optionLotNumber, setOptionLotNumber] = useState([])
    const [dataVoucherList, setDataVoucherList] = useState<any>([])
    const [rangeDate, setRangeDate] = useState<string[]>(['', ''])
    const [subGroup, setSubGroup] = useState('')

    const [loadingModal, setLoadingModal] = useState(false)

    const [copy, setCopy] = useState(0)

    const componentExportRef = useRef<HTMLDivElement>(null)

    const preparation = async (searchData?: string, checkExport?: boolean) => {
        setLoading(true)
        const condition = {
            page: page,
            pageLimit: pageLimit,
            SEARCH: searchData === '' ? '' : search,
            sortBy: sortBy,
            sortType: sortType,
            STATUS: statusSelect === 'ALL' ? '' : statusSelect,
            EXPORT: checkExport ? '1' : '0'
        }
        const resp: any = await VoucherApi.findAllVoucher(condition)
        if (resp.status === 200) {
            if (checkExport) {
                setDataVoucherExport(resp.data)
            } else {
                setRowCount(resp?.headers['x-total'] || 0)
                setDataVoucher(resp.data)
            }
        }
        setLoading(false)
    }

    const preparationById = async (id: number) => {
        await VoucherApi.findVoucherById(id).then((resp) => {
            setDataVoucherById(resp.data)
        })
    }

    const preparationVoucherId = async (id: number, pageId?: number) => {
        setLoadingModal(true)
        await VoucherApi.findVoucherListId(id, { page: pageId ? pageId : pageModal, pageLimit: pageLimitModal, STATUS: resultStatus, SEARCH: searchModal, startDate: rangeDate[0], endDate: rangeDate[1], lotNumber: resultLotNumber }).then((resp: any) => {
            if (resp.status === 200) {
                const statusReady = _.find(resp.data.statusCounts, (item) => item.status === 'READY')
                const statusWaiting = _.find(resp.data.statusCounts, (item) => item.status === 'WAITING')
                const statusUsed = _.find(resp.data.statusCounts, (item) => item.status === 'USED')
                const statusExpired = _.find(resp.data.statusCounts, (item) => item.status === 'EXPIRED')
                const lotNumbers: any = _.map(resp.data?.lotNumbers, (item) => ({ name: item.lotNumber.toString().padStart(4, '0'), id: item.lotNumber }))
                setRowCountModal(resp?.headers['x-total'] || 0)
                setDataVoucherList(resp.data.rows)
                setCountWaiting(statusWaiting?.count)
                setCountReady(statusReady?.count)
                setCountUsed(statusUsed?.count)
                setCountExpired(statusExpired?.count)
                setOptionLotNumber(lotNumbers)
                setTotalCount(resp.data?.count)
            }
        })
        setLoadingModal(false)
    }

    const preparationPatient = async (data: string) => {
        if (data === 'RANK') {
            const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
            await PromotionsApi.findAllRank(condition).then((resPatientTypes) => {
                if (resPatientTypes.status === 200) {
                    const newData = _.map(dataVoucherById?.voucherGroups, (item) => {
                        const result = _.find(resPatientTypes?.data, (items) => items.rankId === item.groupTypeId)
                        return result ? result.rankName : null;
                    })
                    setSubGroup(newData?.join(', '))
                }
            })
        } else {
            const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
            await ComponentApi.findAllPatientType(condition).then((resPatientTypes) => {
                if (resPatientTypes.status === 200) {
                    const newData = _.map(dataVoucherById?.voucherGroups, (item) => {
                        const result = _.find(resPatientTypes?.data, (items) => items.patientTypeId === item.groupTypeId)
                        return result ? result.patientTypeName : null;
                    })
                    setSubGroup(newData?.join(', '))
                }
            })
        }
    }

    ////-------------------
    ////    EFFECT
    ////-------------------
    useEffect(() => {
        if (isOpenPageModal) {
            preparationVoucherId(dataVoucherById.voucherId)
        }
    }, [pageModal, pageLimitModal])

    useEffect(() => {
        if (isOpenPageModal) {
            preparationPatient(dataVoucherById?.voucherGroups?.[0]?.groupType)
        }
    }, [dataVoucherById?.voucherGroups?.[0]?.groupType])

    useEffect(() => {
        preparation()

        return () => {
            setId(0)
            setName('')
            setNameEn('')
        }
    }, [page, pageLimit])

    useEffect(() => {
        preparation('', true)
    }, [])
    ////-------------------
    ////    HANDLE
    ////-------------------
    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '50px', align: 'center', },
        { id: 'voucherTypeName', disablePadding: false, label: t('VOUCHER.TABLE.VOUCHER_TYPENAME'), align: 'left', },
        { id: 'voucherName', disablePadding: false, label: t('VOUCHER.TABLE.VOUCHER_NAME'), align: 'left', },
        { id: 'voucherNameEn', disablePadding: false, label: t('VOUCHER.TABLE.VOUCHER_NAMEEN'), align: 'left', },
        { id: 'unit', disablePadding: false, label: t('VOUCHER.TABLE.UNIT'), align: 'center', width: '150px' },
        { id: 'status', disablePadding: false, label: t('VOUCHER.TABLE.VOUCHER_STATUS'), align: 'center', width: '150px' },
        { id: 'updatedBy', disablePadding: false, label: t('VOUCHER.TABLE.UPDATED_BY'), align: 'center', width: '180px' },
        { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
    ]

    const renderData = (objData: any, index: number, isExport?: boolean) => {
        if (isExport) {
            index = index + 1
        } else {
            index = page * pageLimit - pageLimit + index + 1
        }

        const { voucherId, voucherTypeName, voucherName, voucherNameEn, total, updatedBy, updatedAt, status } = objData
        const objRenderData = {
            key: voucherId,
            id: voucherId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index },
                { option: 'TEXT', align: 'left', label: voucherTypeName || '' },
                { option: 'TEXT', align: 'left', label: voucherName || '' },
                { option: 'TEXT', align: 'left', label: voucherNameEn || '' },
                { option: 'TEXT', align: 'center', label: total || '' },
                { option: 'STATUS', align: 'center', label: status },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t('VOUCHER.ACTION.STATUS_ACTIVE'), disabled: status === 'ACTIVE' },
                        { option: 'STATUS_INACTIVE', label: t('VOUCHER.ACTION.STATUS_INACTIVE'), disabled: status === 'INACTIVE' },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'VIEW', label: t('VOUCHER.ACTION.VIEW'), disabled: permissions.isView.disabled },
                        { option: 'EDIT', label: t('VOUCHER.ACTION.EDIT'), disabled: permissions.isUpdate.disabled },
                        { option: 'COPY', label: t('VOUCHER.ACTION.COPY'), disabled: !permissions.isCopy.disabled },
                        { option: 'DELETE', label: t('VOUCHER.ACTION.DELETE'), disabled: permissions.isDelete.disabled }
                    ]
                },
            ]
        }

        if (isExport) {
            return <TableRowExport {...objRenderData} />
        } else {
            return (
                <TableRowCommon
                    {...objRenderData}
                    onView={() => {
                        setIsOpenPageModal(true)
                        setPageModal(1)
                        preparationById(voucherId)
                        preparationVoucherId(voucherId)
                    }}
                    oncopy={() => history.push(`${routeName.voucher}/create`, { ...objData, copy: true })}
                    onedit={() => history.push(`${routeName.voucher}/update`, { ...objData })}
                    ondelete={() => handleSubmitModal(Number(objRenderData.id), 'DELETED', objRenderData.obj)}
                    onactive={() => handleSubmitModal(Number(objRenderData.id), 'ACTIVE', objRenderData.obj)}
                    oninactive={() => handleSubmitModal(Number(objRenderData.id), 'INACTIVE', objRenderData.obj)}
                />
            )
        }


    }

    const handleSubmitModal = (id: number, status: string, obj?: any) => {
        if (status === 'DELETED') {
            setStatus("DELETED")
            setId(obj.voucherId)
            setName(obj.voucherName)
            setNameEn(obj.voucherNameEn)
            setAlertModal(true)
        } else {
            setAlertModal(true)
            setId(id)
            setStatus(status)
            setName(obj.voucherName)
            setNameEn(obj.voucherNameEn)
        }
    }

    const onActive = () => {
        if (id) {
            VoucherApi.chagneStatusVoucher(id, status).then((resp) => {
                if (resp.status === 200) {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("success")
                    setLabelSnack(t('VOUCHER.MESSAGE.SUCCESS'))
                    preparation(search, true)
                    preparation()
                } else {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("error")
                    setLabelSnack(t('VOUCHER.MESSAGE.ERROR'))
                }
            }).catch(() => {
                setAlertModal(false)
                setIsOpenSnack(true)
                setTypeSnack("error")
                setLabelSnack(t('VOUCHER.MESSAGE.ERROR'))
            })
        }
    }

    const onDelete = () => {
        if (id) {
            VoucherApi.deleteVoucher(id).then((resp) => {
                if (resp.status === 200) {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("success")
                    setLabelSnack(t('VOUCHER.MESSAGE.DELETE_SUCCESS'))
                    preparation(search, true)
                    preparation()
                } else {
                    setAlertModal(false)
                    setIsOpenSnack(true)
                    setTypeSnack("error")
                    setLabelSnack(t('VOUCHER.MESSAGE.ERROR'))

                }
            }).catch((error) => {
                setAlertModal(false)
                setIsOpenSnack(true)
                setTypeSnack("error")
                if (error?.response?.data?.message === 'THIS_VOUCHER_TYPE_IS_BEING_USED' || error?.response?.data?.message === 'THIS_VOUCHER_IS_BEING_USED') {
                    setLabelSnack(t('VOUCHER.MESSAGE.DUPICATE'))
                } else {
                    setLabelSnack(t('VOUCHER.MESSAGE.ERROR'))
                }

            })
        }
    }

    const handleVoucherUsage = () => {
        switch (dataVoucherById?.voucherUsage) {
            case "ALL":
                return 'ลดจากราคารวม'
            case "OPERATIVE":
                return 'ลดจากรายการหัตถการ (ลดทั้ง DF และ CF)'
            case "OPERATIVEDF":
                return 'ลดจากรายการหัตถการเฉพาะ DF'
            case "OPERATIVECF":
                return 'ลดจากรายการหัตถการเฉพาะ CF'
            case "PRODUCT":
                return 'ลดจากรายการยาและผลิตภัณฑ์'
            default:
                return '-'
        }
    }

    const headCellsModal = [
        { id: 'no', disablePadding: false, label: '#', width: '60px' },
        { id: 'id', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.ID') },
        { id: 'group', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.STATUS'), align: 'center', width: '100px' },
        { id: 'type', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.LOTNUMBER'), align: 'center', width: '120px' },
        { id: 'list', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.START_DATE'), align: 'center', width: '150px' },
        { id: 'qty', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.END_DATE'), align: 'center', width: '150px' },
        { id: 'qty', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.OWNER'), align: 'center', width: '200px' },
        { id: 'qty', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.UPDATED_BY'), align: 'center', width: '200px' },
    ]

    const renderDataModal = (objData: any, index: number) => {
        index = pageModal * pageLimitModal - pageLimitModal + index + 1
        const { voucherCode, status, lotNumber, startDate, endDate, ownerName, usedDate, updatedBy, updatedAt, voucherListId } = objData
        const statusType = (status: string) => {
            switch (status) {
                case 'READY':
                    return 'พร้อมใช้งาน'
                case 'WAITING':
                    return 'รอใช้งาน'
                case 'USED':
                    return 'ใช้งานแล้ว'
                case 'EXPIRED':
                    return 'หมดอายุ'
                default:
                    return ''
            }
        }

        const handleCopy = (voucherCode: any, index: number) => {
            setCopy(index);
            navigator.clipboard.writeText(voucherCode);
            setTimeout(() => {
                setCopy(0);
            }, 2000);
        };

        const objRenderData = {
            key: voucherListId,
            id: voucherListId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index },
                {
                    option: 'COMPONENT', align: 'left', component:
                        <Box
                            onClick={() => status === "READY" && navigator.clipboard.writeText(voucherCode)}
                            sx={{ cursor: 'pointer', position: 'relative' }}
                        >
                            <Box display="flex" flexDirection="row" alignItems="center" gap="8px" position="relative">
                                <Typography>{voucherCode}</Typography>
                                <Box>
                                    {copy === index &&
                                        <Box position="absolute" padding="10px" bgcolor="#5A5A5A" color="#FFFFFF" borderRadius="2px" top="-40px" fontSize="14px" fontWeight={400}>Copied</Box>
                                    }
                                    {status === 'READY' && <ContentCopyIcon onClick={() => handleCopy(voucherCode, index)} sx={{ color: '#808080', height: '22px', width: '20px' }} />}
                                </Box>
                            </Box>
                        </Box>
                }, { option: 'COMPONENT', align: 'center', component: <Box fontSize="16px" fontWeight={400} color={status === 'READY' ? '#1CB99A' : status === 'WAITING' ? '#EDBA07' : status === 'USED' ? '#5A5A5A' : '#B00020'}>{statusType(status)}</Box> },
                { option: 'TEXT', align: 'center', label: lotNumber.toString().padStart(4, '0') || '' },
                { option: 'TEXT', align: 'center', label: dateToView(startDate) || '' },
                { option: 'TEXT', align: 'center', label: dateToView(endDate) || '' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: ownerName || '-', updatedAt: usedDate !== null ? usedDate : '-' } },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },

            ]
        }



        return (
            <TableRowCommon {...objRenderData} />
        )
    }

    const headCellsGV = [
        { id: 'no', disablePadding: false, label: t('VOUCHER.FORM.TABLE.NO'), width: '100px' },
        { id: 'voucherUsage', disablePadding: false, label: t('VOUCHER.FORM.TABLE.CATEGORY') },
        { id: 'type', disablePadding: false, label: t('VOUCHER.FORM.TABLE.TYPE') },
        { id: 'list', disablePadding: false, label: t('VOUCHER.FORM.TABLE.LIST') },
        { id: 'amount', disablePadding: false, label: t('VOUCHER.FORM.TABLE.AMOUNT') },
    ]

    const renderDataGV = (objData: any, index: number) => {
        const objRenderData = {
            key: '',
            id: '',
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index + 1 },
                { option: 'TEXT', align: 'left', label: objData?.itemType === 'OPERATIVE' ? 'รายการหัตถการ' : objData?.itemType === 'MEDICINE' ? 'ยา' : 'สินค้าและผลิตภัณฑ์' },
                { option: 'TEXT', align: 'left', label: objData?.itemTypeName || '-' },
                { option: 'TEXT', align: 'left', label: objData?.itemName || '-' },
                { option: 'TEXT', align: 'left', label: objData?.qty || '-' },
            ]
        }

        return (
            <TableRowCommon {...objRenderData} />
        )
    }

    const handleTotalPrice = () => {

        return dataVoucherById?.voucherItems?.reduce((acc: number, item: any) => {
            if (item.itemPrice && item.qty) {
                return acc + (item.itemPrice * item.qty);
            }
            return acc
        }, 0)?.toLocaleString()
    }


    return (
        <Box>
            <Loading show={loadingModal} />
            <PageModal
                isOpen={isOpenPageModal}
                title={t('VOUCHER.VOUCHER_VIEW.TITLE')}
                size="L"
                onSubmit={{ onClick: () => null }}
                onCancel={{ onClick: () => setIsOpenPageModal(false) }}
                onCloseSubmit
                handleClose={() => setIsOpenPageModal(false)}
                overflow
            >
                <Box>
                    <Grid container spacing="16px">
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.VOUCHER_NAME')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={400}>{dataVoucherById?.voucherName} {dataVoucherById?.voucherNameEn && `(${dataVoucherById?.voucherNameEn})`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.VOUCHER_TYPE')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={400}>{dataVoucherById?.voucherType?.voucherTypeName}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.PRICE_TYPE_COST')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={400}>{dataVoucherById?.voucherPrice} {dataVoucherById?.voucherPriceUnit === "PERCENT" ? '%' : 'บาท'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.MINIMUM')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={400}>{dataVoucherById?.minimum} บาท</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={500}>จำนวน</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={400}>{dataVoucherById?.qty}</Typography>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.USAGE')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={400}>{handleVoucherUsage()}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.DETAIL')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize="16px" fontWeight={400}>{dataVoucherById?.detail || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={12}>
                            <CheckboxButton onChange={(e) => null} label={t('VOUCHER.FORM.BIND_OP_MED_PD')} disabled checked={dataVoucherById.voucherItems?.length > 0} />
                        </Grid>
                        <Grid item xs={12}>
                            {dataVoucherById.voucherItems?.length > 0 &&
                                <Box>
                                    <Typography marginTop="32px" marginBottom="16px" fontSize="14px" fontWeight={500}>{dataVoucherById.voucherItems.length > 1 ? t('VOUCHER.FORM.BIND_OP_MED_PD_DETAIL_ALL') : t('VOUCHER.FORM.BIND_OP_MED_PD_DETAIL')}</Typography>
                                    <TableCustom
                                        page={1}
                                        pageLimit={99}
                                        sortType={''}
                                        sortBy={''}
                                        onSort={() => console.log()}
                                        setPageLimit={() => console.log()}
                                        setPage={() => console.log()}
                                        rowCount={1}
                                        hidePagination
                                        headCells={headCellsGV}
                                        rowsData={dataVoucherById.voucherItems.map((item: any, index: number) => {
                                            return renderDataGV(item, index)
                                        })}

                                    />
                                </Box>
                            }
                        </Grid>
                        {dataVoucherById?.voucherItems?.length > 0 &&
                            <Grid item xs={12}>
                                <Box width="100%" bgcolor="#F7F8F8" height="54px" paddingX="32px" display="flex" flexDirection="row" gap='100px' justifyContent="end" alignItems="center">
                                    <Typography fontSize="18px" fontWeight={400}>{t('VOUCHER.FORM.TOTALPRICE')}</Typography>
                                    <Typography fontSize="18px" fontWeight={400}>{handleTotalPrice()} {t('VOUCHER.FORM.BAHT')}</Typography>
                                </Box>

                            </Grid>
                        }
                        <Grid item xs={4}>
                            <CheckboxButton onChange={(e) => null} label={t('VOUCHER.FORM.SETTING.PREFIX_ID')} disabled checked={dataVoucherById.voucherPrefix ? true : false} />
                        </Grid>
                        <Grid item xs={8}><Typography fontSize="16px" fontWeight={400} marginTop="10px" >{dataVoucherById?.voucherPrefix}</Typography></Grid>
                        <Grid item xs={4}><CheckboxButton onChange={(e) => null} label={t('VOUCHER.FORM.SETTING.PATIENT')} disabled checked={dataVoucherById?.voucherGroups?.length === 0 ? false : true} /></Grid>
                        <Grid item xs={8} ><Typography fontSize="16px" fontWeight={400} marginTop="10px">{dataVoucherById?.voucherGroups?.[0]?.groupType} : {subGroup}</Typography></Grid>
                        <Grid item xs={4}><CheckboxButton onChange={(e) => null} label={t('VOUCHER.FORM.SETTING.USE_VOUCHER_DONT_PATIENT')} disabled checked={dataVoucherById?.connectPatient === '0' || dataVoucherById?.connectPatient === '' ? false : true} /></Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={12}><Box border="1px solid" borderColor="#E5E5E5" width="100%" /></Grid>
                        <Grid item xs={12}><Typography fontSize="24px" fontWeight={500}>{t('VOUCHER.FORM.LIST_VOUCHER_ID')}</Typography></Grid>
                        <Grid item xs={1}>
                            <Typography color="#29302E" fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.STATUS')}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                <Typography color="#EDBA07" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_WAITING')}</Typography>
                                <Typography fontSize="16px" fontWeight={400}>{countWaiting || 0}/{totalCount}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                <Typography color="#1CB99A" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_READY')}</Typography>
                                <Typography fontSize="16px" fontWeight={400}>{countReady || 0}/{totalCount}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                <Typography color="#5A5A5A" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_USED')}</Typography>
                                <Typography fontSize="16px" fontWeight={400}>{countUsed || 0}/{totalCount}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                <Typography color="#B00020" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_EXPIRED')}</Typography>
                                <Typography fontSize="16px" fontWeight={400}>{countExpired || 0}/{totalCount}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}> <TextInput label={t('VOUCHER.FORM.SEARCH')} onChange={(e) => setSearchModal(e.target.value)} fullWidth value={searchModal} /></Grid>
                        <Grid item xs={3}> <SelectCheckBoxInput
                            label={t('VOUCHER.FORM.STATUS_FITTER.STATUS')}
                            onChange={(e) => {
                                setStatusModal(e)
                                const result = e.join(',')
                                setResultStatus(result)
                            }}
                            options={[
                                { name: t('VOUCHER.FORM.STATUS_FITTER.WAITING'), id: 'WAITING', },
                                { name: t('VOUCHER.FORM.STATUS_FITTER.READY'), id: 'READY', },
                                { name: t('VOUCHER.FORM.STATUS_FITTER.USED'), id: 'USED', },
                                { name: t('VOUCHER.FORM.STATUS_FITTER.EXPIRED'), id: 'EXPIRED' }
                            ]}
                            selected={statusModal || []}
                        /></Grid>
                        <Grid item xs={3}>
                            <SelectCheckBoxInput
                                label={t('VOUCHER.FORM.LOTNUMBER')}
                                onChange={(e) => {
                                    setLotNumber(e)
                                    const result = e.join(',')
                                    setResultLotNumber(result)
                                }}
                                options={optionLotNumber}
                                selected={lotNumber}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
                                <DateRangeInput
                                    inputHeight={40}
                                    value={rangeDate || ['', '']}
                                    onchange={(val: any) => {
                                        if (_.isEmpty(val)) setRangeDate(['', ''])
                                        else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                    }}
                                    label={''}
                                    allowClear
                                    onClear={() => setRangeDate(['', ''])}
                                />
                                <MainButton onClick={() => {
                                    setPageModal(1)
                                    preparationVoucherId(dataVoucherById.voucherId, 1)
                                }} title={t('VOUCHER.FORM.SEARCH_VOUCHER_LIST')} type="primary" variant="contained" style={{ width: '120px' }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box marginTop="16px" >
                        <TableCustom
                            headCells={headCellsModal}
                            onSort={(sortByVal: string, sortTypeVal: string) => {
                                setSortType(sortTypeVal)
                                setSortBy(sortByVal)
                            }}
                            page={pageModal}
                            pageLimit={pageLimitModal}
                            rowsData={dataVoucherList.map((item: any, index: number) => {
                                return renderDataModal(item, index)
                            })}
                            setPage={(e) => setPageModal(e)}
                            setPageLimit={(e) => setPageLimitModal(e)}
                            sortBy={sortBy}
                            sortType={sortType}
                            rowCount={rowCountModal}
                        />
                    </Box>
                </Box>
            </PageModal>
            <Loading show={loading} />
            <AlertModal
                isOpen={alertModal}
                title={status === "DELETED" ? t('VOUCHER.MESSAGE.DELETE_TITLE') : status === 'INACTIVE' ? t('VOUCHER.MESSAGE.STATUS_INACTIVE_TITLE') : t('VOUCHER.MESSAGE.STATUS_ACTIVE_TITLE')}
                message={status === "DELETED" ? t('VOUCHER_TYPE.MESSAGE.DELETE_MESSAGE', { name: name, nameEn: nameEn && `(${nameEn})` }) : t('VOUCHER.MESSAGE.STATUS_MESSAGE')}
                type={status === "DELETED" ? 'error' : 'warning'}
                onSubmit={{ onClick: status === 'DELETED' ? onDelete : onActive, title: status === "DELETED" ? t('VOUCHER.MESSAGE.BUTTON_SAVE_DELETE') : t('VOUCHER.MESSAGE.BUTTON_SAVE_ACTIVE') }}
                onCancel={{ onClick: () => setAlertModal(false), title: t('VOUCHER.MESSAGE.BUTTON_CANCEL') }}
                handleClose={() => setAlertModal(false)}
            />
            <SnackbarComponent
                onOpen={isOpenSnack}
                onClose={() => setIsOpenSnack(false)}
                label={labelSnack}
                type={typeSnack}
                transform={`translateX(calc(100vw - 100% - 32px)) translateY(calc(-100vh + 100% + ${window.innerHeight - 50}px))`}
                autoHideTime={3000}
            />
            <Box width="100%" height="80px" boxShadow="0px 3px 6px 0px #0000001F" border="1px solid #E5E5E5" paddingLeft="24px" display="flex" alignItems="center" fontSize="20px" fontWeight={500}  >
                {t('VOUCHER.TITLE')}
            </Box>
            <Box paddingX="24px" paddingTop="16px">
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5} lg={4}>
                                <TextInput
                                    onChange={(event) => {
                                        setSearch(event.target.value);
                                        setPage(1);
                                    }}
                                    label={t('VOUCHER.SEARCH')}
                                    value={search}
                                    size='large'
                                    fullWidth={true}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            setPage(1)
                                            preparation(search, true)
                                            preparation()
                                        }
                                    }}
                                    inputProps={{
                                        value: search,
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                {!search ? <FontAwesomeIcon icon={faSearch} style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 15 }} /> :
                                                    <FontAwesomeIcon
                                                        icon={faTimes}
                                                        onClick={() => {
                                                            setSearch('');
                                                            setPage(1);
                                                            preparation('', true)
                                                            preparation('')
                                                        }}
                                                        style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 17, cursor: 'pointer' }} />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={5} lg={4}>
                                <SelectInput
                                    value={statusSelect}
                                    onChange={(event: any) => {
                                        setStatusSelect(event.target.value)
                                    }}
                                    defaultValue={statusSelect}
                                    label={t('VOUCHER.STATUS.STATUS')}
                                    options={[
                                        { name: t('VOUCHER.STATUS.ALL'), value: "ALL" },
                                        { name: t('VOUCHER.STATUS.ACTIVE'), value: "ACTIVE" },
                                        { name: t('VOUCHER.STATUS.INACTIVE'), value: "INACTIVE" }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} lg={4}>
                                <MainButton
                                    title={t('VOUCHER.BUTTON.SEARCH')}
                                    onClick={() => {
                                        setPage(1)
                                        preparation(search, true)
                                        preparation()
                                    }}
                                    type="primary"
                                    variant="contained"
                                    style={{ height: '40px', width: '100px' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '16px' }}>
                            <ButtonExport
                                headCells={headCells}
                                portrait={true}
                                filter={''}
                                fileName={'รายการประเภท Gift Voucher'}
                                rowsData={dataVoucherExport.map((item: any, index: number) => {
                                    return renderData(item, index, true)
                                })}
                                marginPrint={'0.5cm'}
                                componentExportRef={componentExportRef}
                            />
                            <MainButton
                                onClick={() => history.push(`${routeName.voucher}/create`)}
                                title={t('VOUCHER.BUTTON.CREATE_VOUCHER')}
                                type="primary"
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                                style={{ height: '40px' }}
                                disabled={permissions.isCreate.disabled}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box marginTop="24px">
                    <TableCustom
                        page={page}
                        pageLimit={pageLimit}
                        sortType={sortType}
                        sortBy={sortBy}
                        onSort={(sortByVal: string, sortTypeVal: string) => {
                            setSortType(sortTypeVal)
                            setSortBy(sortByVal)
                        }}
                        setPageLimit={(e) => setPageLimit(e)}
                        setPage={(e) => setPage(e)}
                        rowCount={rowCount}
                        headCells={headCells}
                        rowsData={dataVoucher.map((item: any, index: number) => {
                            return renderData(item, index)
                        })}
                    />
                </Box>
            </Box>
            <Box className={`print-show`} ref={componentExportRef}>
                <Box fontSize={'10px'} fontWeight={'500'}>{'จัดการ Gift Voucher'}</Box>
                <Box fontSize={'10px'} fontWeight={'500'} display={'flex'} gap={'8px'} marginBottom={'8px'}>
                    <span>
                        {'ค้นหา'}: {search || '-'}
                    </span>
                    <span>
                        {'สถานะ'}: {statusSelect === "ALL" ? 'ทั้งหมด' : statusSelect === 'ACTIVE' ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
                    </span>
                </Box>
                <Box
                    width={'100%'}
                    marginBottom={'8px'}
                    sx={{
                        '.no-link': { display: 'flex', justifyContent: 'center', gap: '4px' },
                        'th,td': {
                            fontSize: '8px',
                            margin: '0',
                            padding: '1px',
                            borderBottom: '1px solid black',
                            borderTop: '1px solid black',
                            textAlign: 'center',
                            ':last-child': {
                                borderRight: 0,
                            }
                        }
                    }}
                >
                    <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                        <thead>
                            {_.map(headCells.filter((headCell: any) => headCell.id !== 'action' && headCell.id !== 'COMPONENT'), (val: any, index: number) => {
                                return (
                                    <th
                                        style={{
                                            textAlign: val.align ? val.align : 'left',
                                            fontSize: 8,
                                            fontWeight: 500
                                        }}>
                                        {val.label || (val.labelKey)}
                                    </th>
                                )
                            })}
                        </thead>
                        <tbody>
                            {dataVoucherExport?.length ? dataVoucherExport?.map((item: any, index: number) => {
                                return renderData(item, index, true)
                            }) : []}
                            {!dataVoucherExport?.length && (
                                <td colSpan={headCells.length}>
                                    {'NOT_FOUND'}
                                </td>
                            )}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    )
}
export default Voucher