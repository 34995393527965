import { useState, useEffect, useCallback } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Box, styled, TableCell, TableRow } from '@mui/material'
import _ from 'lodash'

/** API */


/** COMPONENT */

import ModalCustom from 'component/ModalCustom/ModalCustom'

import { colors } from 'constants/theme'

/** CONSTANT */
import closePopup from 'assets/icon/appointment/close.svg'

/** SLICE & UTILS & FEATURES */
import { resetModal } from 'app/slice/modal.slice'
import { numberFormat } from 'utils/app.utils'

import Loading from 'component/Loading'




import TableCustom from 'component/Table/TableCustom'
import { RawAdditionalType, RawDiscountType, cal_sum, renderSumAdditional, renderSumDiscount } from 'features/treatments/SummaryTreatment/SummaryTreatment'
import ClinicApi from 'api/master/clinic.api'
import { ApprovalStatus } from 'api/approval/approval.api'


export const TableWidth = styled(Box)(({ theme }) => ({
    height: '100%',
    '.MuiTableContainer-root': {
        height: '100%',
    },
    '.MuiTable-root': {
        minWidth: 200,
        '.MuiTableHead-root': {
            '.MuiTableRow-head': {
                position: 'sticky',
                top: 0,
                zIndex: 10,
                '.MuiTableCell-head': {
                    color: '#000000 !important',
                    background: '#fff',
                    padding: 8,
                    borderTop: '1px solid  #E5E5E5',
                    borderBottom: '1px solid  #E5E5E5',
                    borderLeft: '1px hidden #ffffff !important',
                    borderRight: '1px hidden #ffffff !important'
                },
            }
        },
        '.MuiTableBody-root': {
            '.MuiTableCell-body': {
                border: 'none',
                padding: `8px !important`,
                verticalAlign: 'middle',
                borderLeft: '1px hidden #ffffff !important',
                borderRight: '1px hidden #ffffff !important',
                '&.add-new-list': {
                    '&.disabled': {
                        button: {
                            color: colors.disabledGray
                        }
                    }
                }
            }
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: 'unset',
            maxWidth: '100%',
            '.MuiTableBody-root': {
                '.MuiTableCell-body': {
                    verticalAlign: 'top',
                    '&:first-of-type:not(.add-new-list), &:last-of-type:not(.add-new-list)': {
                        paddingTop: `18px !important`
                    }
                }
            },
        }
    }
}))

const modalStyle = styled(Modal)(({ theme }) => ({
    zIndex: theme.zIndex.modal,
    '& .modal-content': {
        padding: '1rem 1.5rem 1.5rem',
        backdropFilter: 'saturate(1) blur(40px)',
        background: `linear-gradient(
            to bottom,
            ${colors.themeSecondColor} 0px,
            ${colors.themeSecondColor} 50px,
            #fff 50px,
            #fff 100%
          )`,
        border: 'none !important',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem'
        }
    },
    '& .modal-title': {
        width: '100%',
        h3: {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 600,
            color: colors.white,
            '&.header-danger': {
                color: colors.textPrimary
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 18
            }
        }
    },
    '& .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled': {
        opacity: 0.5
    },
    '& .modal-footer': {
        display: 'flex',
        flexDirection: 'row',
        '&:not(.footer-inline)': {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        }
    },
    '.btn-close': {
        position: 'absolute',
        top: '16px',
        right: '24px',
        boxShadow: 'none !important',
        background: `url(${closePopup}) center/1em auto no-repeat`,
        opacity: 0.6,
        '&:hover': {
            opacity: 1
        },
        [theme.breakpoints.down('xs')]: {
            right: '16px',
        }
    }
}))



/** VARIABLE */


export interface ConfirmDFProps {
    isShow: boolean
    onReset?: () => void
    handleSuccess?: () => void
    treatments?: any
}

export function PopupConfirmDF(props: ConfirmDFProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [loading] = useState<boolean>(false)
    /** OPTION */

    /** DATA */
    const [summeryTreatments, setSummeryTreatments] = useState<any>()
    const [doctors, setDoctors] = useState<any>()

    const loadDoctor = useCallback(async () => {
        const doctorsResp = await ClinicApi.findAllDentists()
        const allDoctors: Set<string> = new Set();
        const newTreatments: any[] = [];
        const updateTreatments = (treatments: any[]) => {
            _.map(treatments, (i: any) => {
                if (!allDoctors.has(i.doctorId)) {
                    allDoctors.add(i.doctorId);
                }
                if (i.tempOrderTreatmentId || i.orderTreatmentId) {
                    newTreatments.push(i);
                }
            });

            setSummeryTreatments(newTreatments);
        };

        updateTreatments(props.treatments.summeryTreatments);
        setDoctors(_.filter(doctorsResp.data, (i: any) => allDoctors.has(i.dentistId)));
    }, [])


    useEffect(() => {
        loadDoctor()
    }, [])




    const resetForm = async () => {
        dispatch(resetModal())
        if (props.onReset) props.onReset()
    }


    const headCellsDF = [
        { id: 'massage', disablePadding: false, align: 'center', label: t('TREATMENTS.POPUP_CONFIRM_DF.TABLE.LIST'), width: '50%' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENTS.POPUP_CONFIRM_DF.TABLE.PRICE'), width: '10%' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.TITLE.DISCOUNT'), width: '10%' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.TITLE.ADDON'), width: '10%' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENTS.POPUP_CONFIRM_DF.TABLE.DF'), width: '10%' },
        { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENTS.POPUP_CONFIRM_DF.TABLE.DF_PRICE'), width: '10%' },
    ]

    const renderData = (objData: any, no: number, id: number) => {
        const dataTreamtmentCurrent = (objData?.approvalItems?.body && objData?.approvalItems?.status === ApprovalStatus.PENDING) ? JSON.parse(objData?.approvalItems?.body) : undefined
        const newData = dataTreamtmentCurrent || objData

        const promotionMain = objData?.promotion?.length ? objData?.promotion?.some((info: any) => info?.isMain === '1' && info?.promotionType === 'DISCOUNT') : false
        const discountPromotionDf = promotionMain ? objData?.dfPromotionDiscountAmount || 0 : 0

        const discountVoucherDf = objData?.dfVoucherDiscountAmount || 0

        const { operativeName, price, dfAmount,
            dfAmountNet, dfUnit, dfDiscountAmount,
            dfDiscountUnit, cfDiscountAmount, cfDiscountUnit,
            qty, additional, additionalUnit,
            discount, discountUnit, cfAmount,
            doctorFeeAmount, clinicFeeAmount, operativeTypeId, doctorId } = newData

        const rawAdditional: RawAdditionalType = {
            price: price,
            qty: qty,
            additional: Number(additional),
            additionalUnit: additionalUnit
        }
        const rawDiscount: RawDiscountType = {
            price: price,
            qty: qty,
            discount: Number(discount),
            discountUnit: discountUnit,
            dfUnit: dfUnit,
            dfAmount: Number(dfAmount),
            dfDiscountAmount: Number(dfDiscountAmount),
            dfDiscountUnit: dfDiscountUnit,
            cfAmount: Number(cfAmount),
            cfDiscountAmount: Number(cfDiscountAmount),
            cfDiscountUnit: cfDiscountUnit,
            additionalNet: renderSumAdditional(rawAdditional),
            doctorFeeAmount: doctorFeeAmount,
            clinicFeeAmount: clinicFeeAmount
        }

        return (
            operativeName && doctorId === id ?
                <TableRow key={no}>
                    <TableCell align="left" scope="row" className="py-3 py-md-2">
                        {operativeName}
                    </TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2">
                        {numberFormat(price * qty)}
                    </TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2">
                        {Number((operativeTypeId && renderSumDiscount(rawDiscount)) || cal_sum(objData, rawDiscount.discount, "discount") || 0)}
                    </TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2">
                        {Number((operativeTypeId && renderSumAdditional(rawAdditional)) || cal_sum(objData, rawAdditional.additional, "additional") || 0)}
                    </TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2">
                        {dfUnit === 'PERCENT' ? dfAmount : numberFormat(dfAmount)}{dfUnit === 'PERCENT' ? '%' : ` ${t('CURRENCY_CHANGE.TH')}`}
                    </TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2">
                        {numberFormat(dfAmountNet - (Number(discountPromotionDf) + Number(discountVoucherDf))) === 'NaN' ? 0 : numberFormat(dfAmountNet - (Number(discountPromotionDf) + Number(discountVoucherDf)))}
                    </TableCell>
                </TableRow>
                :
                ''
        )
    }

    const renderSumData = (objData: any, id: number) => {
        let allPrice = 0
        _.map(objData, (data: any) => {

            if (data.doctorId === id) {
                const promotionMain = data?.promotion?.length ? data?.promotion?.some((info: any) => info?.isMain === '1' && info?.promotionType === 'DISCOUNT') : false
                const discountPromotionDf = promotionMain ? data?.dfPromotionDiscountAmount || 0 : 0
                const discountVoucherDf = data?.dfVoucherDiscountAmount || 0
                if (data?.approvalItems?.body) {
                    const newData = JSON.parse(data?.approvalItems?.body)
                    if (newData && (data?.approvalItems?.status !== ApprovalStatus.APPROVED && data?.approvalItems?.status !== ApprovalStatus.REJECT)) {
                        allPrice += Number(newData?.dfAmountNet || 0) - (Number(discountPromotionDf) + Number(discountVoucherDf))
                    } else {
                        allPrice += Number(data.dfAmountNet) - (Number(discountPromotionDf) + Number(discountVoucherDf))
                    }
                } else {
                    allPrice += Number(data.dfAmountNet) - (Number(discountPromotionDf) + Number(discountVoucherDf))
                }
            }
        })

        return (
            <TableRow style={{ borderTop: '1px solid  #E5E5E5', borderBottom: '5px double #E5E5E5', backgroundColor: '#F5F5F5' }}>
                <TableCell style={{ fontWeight: 'bold', fontSize: '18px' }} align="center" scope="row" className="py-3 py-md-2">
                    {t('TREATMENTS.POPUP_CONFIRM_DF.TABLE.ALL')}
                </TableCell>
                <TableCell align="center" scope="row" className="py-3 py-md-2" >
                    {''}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '18px', textAlign: "right" }} colSpan={4} align="center" scope="row" className="py-3 py-md-2">
                    {numberFormat(allPrice)} {t('TREATMENTS.POPUP_CONFIRM_DF.TABLE.CURRENCY')}
                </TableCell>
            </TableRow >
        )
    }

    return props.isShow ? (

        <ModalCustom
            // changeStyle={modalStyle}
            size={'xl'}
            title={t('TREATMENTS.POPUP_CONFIRM_DF.TITLE')}
            alignFooter={'end'}
            fullscreen={'sm-down'}
            component={
                <>
                    <Loading show={loading} type="fullLoading" />
                    {_.map(doctors, (doc: any) => {
                        if (_.some(summeryTreatments, (i: any) => i.doctorId === doc.dentistId)) {
                            return (
                                <>
                                    <Row className="mb-2">
                                        <Col sm={'auto'} style={{ marginTop: '5px', marginBottom: '-5px' }}>
                                            {`${doc.prefix} ${doc.dentistFullname}`}
                                        </Col>
                                    </Row>
                                    <div className='pt-3 overflow-hidden'>
                                        {/* <TableWidth> */}
                                        <TableCustom
                                            page={0}
                                            pageLimit={0}
                                            sortType={'ASC'}
                                            sortBy={''}
                                            rowCount={0}
                                            onSort={() => { return }}
                                            setPageLimit={() => { return }}
                                            setPage={() => { return }}
                                            textEmptyData={t('REGISTER.NO_ITEM')}
                                            headCells={headCellsDF}
                                            rowsData={[
                                                _.map(props.treatments.summeryTreatments, (i, index: number) => {
                                                    return renderData(i, index, doc.dentistId)
                                                }),
                                                renderSumData(summeryTreatments, doc.dentistId)
                                            ]}
                                            hidePagination
                                            tableFixedWidth
                                        />
                                        {/* </TableWidth> */}
                                    </div>
                                </>
                            )
                        } else { return }
                    })}

                </>
            }
            closeButton
            onSubmit={props.handleSuccess}
            onReset={() => resetForm()}
            disabledSubmit={loading}
            textBtnCancel={t('TREATMENTS.POPUP_CONFIRM_DF.BUTTON.CANCEL')}
            textBtnConfirm={t('TREATMENTS.POPUP_CONFIRM_DF.BUTTON.CONFIRM')}
        />
    ) : (
        <></>
    )
}
