import { Switch } from "@mui/material";

interface SwitchButtonProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    defaultChecked?: boolean
    disabled?: boolean
    checked?: boolean
}

export default function SwitchButton(props: SwitchButtonProps) {

    return (
        <Switch
            onChange={props.onChange}
            defaultChecked={props.defaultChecked}
            disabled={props.disabled}
            checked={props.checked}
            sx={{
                width: 48,
                height: 26,
                padding: 0,
                '& .MuiSwitch-switchBase': {
                    padding: 0,
                    marginTop: '3px',
                    marginLeft: '6px',
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                            backgroundColor: '#1CB99A',
                            opacity: 1,
                            border: 0,
                        },
                        '& .MuiSwitch-thumb': {
                            boxSizing: 'border-box',
                            width: 20,
                            height: 20,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: '#33cf4d',
                        border: '6px solid #fff',
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        opacity: 1,
                        backgroundColor: '#FFFFFF'
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: '0.7',
                    },
                },
                '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    width: 20,
                    height: 20,
                },
                '& .MuiSwitch-track': {
                    borderRadius: 13,
                    backgroundColor: '#A8A8A8',
                    opacity: 1,
                    transition: 'background-color 500ms',
                },
            }}
        />
    )
}