import { Box, CircularProgress, keyframes } from "@mui/material";


export default function Loading() {

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            position={'fixed'}
            top={0}
            right={0}
            left={0}
            bottom={0}
            zIndex={2500}
            sx={{ background: '#231F204C', backdropFilter: 'blur(1px)' }}
        >
            <CircularProgress />
        </Box>
    )
}