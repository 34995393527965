import { useState, useEffect, useCallback } from 'react'
import { Box, TableRow, TableCell } from '@mui/material'
import { InventoryTabs } from 'features/warehouses/inventory/form-inventory'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import InventoryApi from 'api/warehouses/inventory.api'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import Loading from 'component/Loading'
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'

/** UTILE */
import { SORT_TYPE } from 'constants/common'
import { toBuddhistYear, numberFormat } from 'utils/app.utils';
import { APPROVE_STATUS } from 'api/warehouses/adjustment.api'
import { colors } from 'constants/theme'

/** STYLE */
import * as UseStyled from 'features/warehouses/inventory/styled'
import ImportInventoryApi from 'api/warehouses/import-inventory.api'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { InventoryType } from '../inventory'

interface InventoryQuantityTabs {
    inventory: InventoryType
    setTotalExpire: (nunmTotal: number) => void
}

export default function HistoryQuantity(props: InventoryQuantityTabs) {
    const { t } = useTranslation()

    const [quantities, setQuantities] = useState([]);
    const [loading, setLoading] = useState(false);

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('date')
    const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.DESC)

    const loadData = useCallback((id: number, newTotal?: boolean) => {
        setLoading(true)
        let condition: any = { itemId: id }
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }

        InventoryApi.findInventoryHistoryById(id, condition)
            .then(({ data, headers }) => {
                setQuantities(data)
                setRowCount(headers['x-total'])
                if (newTotal) {
                    const dateCurrent = moment();
                    let newTotalExpire = 0
                    data?.forEach((item: any) => {
                        const dateInfo = moment(item?.expireDate);
                        if (item?.expireDate && dateCurrent.isAfter(dateInfo)) {
                            newTotalExpire += item?.qty
                        }
                    })
                    props.setTotalExpire(newTotalExpire)
                }
            })
            .catch(() => { return })
            .finally(() => setLoading(false))
    }, [page, pageLimit, sortBy, sortType])

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const renderDateExpired = (date: string, day: number) => {
        if (date && day) {
            const now = moment()
            const diff = moment(date).add('days', 1).diff(now, 'days')
            if (diff <= day) return 'date-expired'
            else return ''
        } else return ''
    }

    const handleDisposeInventory = async (inventoryId: number) => {
        const newPayload = {
            inventoryId: inventoryId,
            remark: ""
        }
        await ImportInventoryApi.disposeLot(newPayload).then((res) => {
            notiSuccess(t('INVENTORY.ALERT.DISPOSE_SUCCUSESS'))
            if (props.inventory?.itemId) loadData(props.inventory?.itemId, true)
        }).catch((error) => {
            notiError(t('INVENTORY.ALERT.ERROR'))
        })
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '150px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.LOT_NO'), class: 'text-nowrap' },
        { id: 'expireDate', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.EXPIRE_DATE'), width: '150px', sortable: true },
        { id: 'no', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.QTY_LOT'), class: 'text-nowrap' },
        { id: 'action', disablePadding: false, label: t('Action'), align: 'center', width: '120px' }
    ]

    const renderData = (objData: any, no: number) => {
        const { lotNo, expireDate, qty, inventoryId } = objData

        const handleQtyExpireDate = () => {
            const dateCurrent = moment();
            const dateInfo = moment(expireDate);
            if (expireDate && dateCurrent.isAfter(dateInfo)) {
                return colors.expired.danger
            } else {
                return ''
            }
        }
        const textColor = handleQtyExpireDate()

        no = page * pageLimit - pageLimit + no + 1
        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: lotNo || '-' },
                { option: 'TEXT', align: 'left', label: expireDate ? toBuddhistYear(moment(expireDate), 'DD/MM/YYYY') : '-', class: `cursor-pointer ${renderDateExpired(expireDate, props.inventory.amounDayBeforeExpired || 0)}`, style: { color: textColor } },
                { option: 'TEXT', align: 'center', label: numberFormat(qty, 0, 0), style: { color: textColor } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [{ option: 'STATUS_ACTIVE', label: t(`จำหน่าย`), disabled: textColor === '' }]
                }
            ],
            onactive: () => handleDisposeInventory(inventoryId)
        }

        return <TableRowCommon {...objRenderData} />
    }

    useEffect(() => {
        if (props.inventory?.itemId) loadData(props.inventory?.itemId)
    }, [loadData]);

    return (
        <UseStyled.ResponsiveTable>
            <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                headCells={headCells}
                rowsData={[
                    !loading ?
                        (
                            _.isEmpty(quantities) ? (
                                <TableRow>
                                    <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                        {t('REGISTER.NO_ITEM')}
                                    </TableCell>
                                </TableRow>
                            ) :
                                _.map(quantities, (quantity, index: number) => {
                                    return renderData(quantity, index)
                                })
                        ) : (
                            <TableRow>
                                <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                    <Box className='mx-auto' sx={{ maxWidth: 'max-content' }}>
                                        <Loading show={true} type='softLoading' />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                ]}
                tableFixedWidth
            />
        </UseStyled.ResponsiveTable>
    )
}
