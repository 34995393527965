import { Box, IconButton, Tooltip } from '@mui/material'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import MainButton from 'new-components/buttons/main-button'
import TextInput from 'new-components/inputs/text-input'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiSelectFilter } from './form-view-promotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import InfoIcon from '@mui/icons-material/Info';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { icons } from 'constants/images'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextField from 'component/Input/InputTextField'
import { notiError } from 'component/notifications/notifications'
import { useSelector } from 'react-redux'
import VoucherApi, { VoucherPatientUseProps } from 'api/voucher/voucher.api'
import Loading from 'component/Loading'
import { colors } from 'constants/theme'
import IconClose from 'assets/icon/iconClose'

type FormBindVocherProps = {
    onClose: () => void
    reload: () => void
}

const FormBindVoucher = (props: FormBindVocherProps) => {
    const { t } = useTranslation()
    const patient = useSelector((state: any) => state.register.patient)
    const [load, setLoad] = useState<boolean>(true)

    const vocherGroupOp = [
        { name: t('PROMOTION.FORM.NOITEM_LIST_'), value: 'ALL' },
        { name: t('PROMOTION.FORM.RANK_GROUP'), value: 'RANK' },
        { name: t('PROMOTION.FORM.PATIENT_GROUP'), value: 'PATIENT' }
    ]
    const [vocherGroupSelect, setVocherGroupSelect] = useState<string[]>(['ALL', 'RANK', 'PATIENT'])
    // resVoucher
    const [dataVoucher, setDataVoucher] = useState<any>([])
    const [search, setSearch] = useState<string>('')
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('voucherId')
    const [sortType, setSortType] = useState('DESC')

    const [voucherSelect, setVoucherSelect] = useState<any>([])
    const [errorMessage, setErrorMessage] = useState<any>({})

    const loadVoucherPatient = async () => {
        setLoad(true)
        const condition = {
            page: page,
            pageLimit: pageLimit,
            SEARCH: search,
            GROUP: vocherGroupSelect.join(',')
        }
        await VoucherApi.findVoucherPatient(patient.patientId, condition).then((res) => {
            setDataVoucher(res?.data || [])
            setRowCount(res?.headers['x-total'] || 0)
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadVoucherPatient()
    }, [page, pageLimit])

    const handleChangeFilter = (option: any, select: string[], setSelect: (data: string[]) => void, selectOption: { name: string, value: string }[]) => {
        if (option !== 'ALL') {
            if (!select?.length) {
                setSelect([option?.value])
                return
            }
            if (!select.includes(option.value)) {
                setSelect([...select, option?.value])
            } else {
                setSelect(select.filter((item: any) => item !== option.value))
            }
        } else {
            if (select?.length === selectOption?.length) {
                setSelect([])
            } else {
                setSelect(selectOption.map((item) => { return item?.value }))
            }
        }
    }

    const handleAddVoucher = (data: any) => {
        setErrorMessage({})
        const dataSelect = voucherSelect?.filter((item: any) => item?.voucherId === data?.voucherId)
        if (dataSelect?.length) {
            const voucherIdCurrent = dataSelect.map((item: any) => item?.lotDetailSelect?.lotNumber)
            const newData = data?.lotDetail?.find((item: any) => !voucherIdCurrent.includes(item?.lotNumber) && item?.readyUse)
            setVoucherSelect([...voucherSelect, { ...data, lotDetailSelect: { ...newData } }])
        } else {
            const newLost = data?.lotDetail?.find((item: any) => item?.readyUse)
            if (newLost) {
                const newData = { ...data, lotDetailSelect: { ...newLost } }
                setVoucherSelect([...voucherSelect, newData])
            }
        }
    }

    const handleDelVoucher = (index: number) => {
        setErrorMessage({})
        const newData = voucherSelect.filter((item: any, num: number) => num !== index)
        setVoucherSelect(newData)
    }

    const handleChangeVoucherLotDetail = (value: any, index: number) => {
        setErrorMessage({})
        const newData = [...voucherSelect]
        newData[index] = { ...newData[index], lotDetailSelect: value }
        setVoucherSelect(newData)
    }

    const handleChangeVoucherQrt = (lotDetailSelect: any, value: any, index: number) => {
        setErrorMessage({})
        const newData = [...voucherSelect]
        const newValue = Number(lotDetailSelect?.readyUse || 0) >= Number(value || 0) ? Number(value || 0) : Number(lotDetailSelect?.readyUse || 0)
        newData[index] = { ...newData[index], lotDetailSelect: { ...newData[index]?.lotDetailSelect, qty: newValue } }
        setVoucherSelect(newData)
    }

    const headCells: any[] = [
        { id: 'no', disablePadding: false, label: t('#'), width: '50px', align: 'center', },
        { id: 'vocherTypeName', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.VOUCHER_TYPENAME'), align: 'left', class: 'text-nowrap ' },
        { id: 'vocherName', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.VOUCHER_NAME'), align: 'left', class: 'text-nowrap col-voucher-name' },
        { id: 'condition', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.CONDITION'), align: 'left', class: 'text-nowrap ' },
        { id: 'count', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.VOUCHER_TOTAL'), align: 'center', class: 'text-nowrap', width: 'fit-content' },
        { id: 'action', disablePadding: false, label: t('Action'), align: 'center', class: 'text-nowrap' },
    ]

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => setPage(val)

    const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

    const renderData = (objData: any, index: number,) => {
        index = page * pageLimit - pageLimit + index + 1
        const { voucherId, voucherName, voucherTypeName, detail, total, groupItem } = objData

        const dataSelect = voucherSelect?.filter((item: any) => item?.voucherId === voucherId)

        const onCheckDisabledAdd = () => {
            const newTotal = total.split("/")
            return newTotal[0] === "0" ? true : false
        }

        const onRenderLotDetail = () => {
            return objData?.lotDetail?.filter((item: any) => item?.readyUse) || []
        }

        const commonColumns = [
            { option: 'TEXT', align: 'center', label: index },
            { option: 'TEXT', align: 'left', label: voucherTypeName },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} >
                        {voucherName}
                        {detail &&
                            <span>
                                <Tooltip title={detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                    <InfoIcon />
                                </Tooltip>
                            </span>
                        }
                    </Box>
                )
            },
            { option: 'TEXT', class: 'text-nowrap ', align: 'left', label: groupItem },
            { option: 'TEXT', align: 'center', label: total, style: { color: onCheckDisabledAdd() ? colors.statusInactive : '' } },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box textAlign={'center'} sx={{ button: { minWidth: 'fit-content !important', padding: '0 14px', fontSize: '20px', fontWeight: '600' } }}>
                        <MainButton
                            disabled={dataSelect?.length === onRenderLotDetail()?.length || onCheckDisabledAdd()}
                            type={'primary'}
                            variant={'contained'}
                            title={'+'}
                            onClick={() => handleAddVoucher(objData)}
                        />
                    </Box>
                )
            },
        ]

        const objRenderData = {
            key: voucherId,
            id: voucherId,
            obj: objData,
            columns: commonColumns
        }

        return <TableRowCommon {...objRenderData} />
    }

    const headCellsSelect: any[] = [
        { id: 'no', disablePadding: false, label: t('#'), width: '50px', align: 'center', },
        { id: 'vocherName', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.VOUCHER_NAME'), align: 'left', class: 'text-nowrap col-voucher-name' },
        { id: 'code', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.VOUCHER_CODE_LOT'), align: 'center', class: 'text-nowrap col-voucher-lotnumber' },
        { id: 'endDate', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.VOUCHER_DATEEND'), align: 'center', class: 'text-nowrap col-dateend' },
        { id: 'count', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.COUNT_TOTAL'), align: 'center', class: 'text-nowrap', },
        { id: 'count', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.COUNT_USAGE'), align: 'center', class: 'text-nowrap', },
        { id: 'count', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.COUNT_REMAIN'), align: 'center', class: 'text-nowrap', },
        { id: 'count', disablePadding: false, label: t('VOUCHER.REGISTER.FORM.COUNT_BIND'), align: 'center', class: 'text-nowrap', },
        { id: 'action', disablePadding: false, label: t('Action'), align: 'center', class: 'text-nowrap' },
    ]

    const renderDataSelect = (objData: any, index: number,) => {
        const newIndex = index + 1
        const { voucherId, voucherName, detail, lotDetail, lotDetailSelect } = objData

        const formatNumber = (num: number) => {
            if (!num) {
                return num
            }
            return num.toString().padStart(4, '0');
        };

        const commonColumns = [
            { option: 'TEXT', align: 'center', label: newIndex },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} >
                        {voucherName}
                        {detail &&
                            <span>
                                <Tooltip title={detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                    <InfoIcon />
                                </Tooltip>
                            </span>
                        }
                    </Box>
                )
            },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box display={'flex'} gap={'8px'} sx={{ '.MuiAutocomplete-root': { width: '100%' }, '.MuiInputBase-root': { paddingRight: '25px !important' } }}>
                        <AutocompleteSelect
                            labelId='lot-detail'
                            noOptionsText={t('NOT_FOUND')}
                            getOptionLabel={(option) => option?.lotNumber ? formatNumber(option?.lotNumber) : ''}
                            options={lotDetail}
                            renderOption={(props, option) => {
                                const checkDisabled = voucherSelect?.some((item: any) => item?.lotDetailSelect?.lotNumber === option?.lotNumber)
                                return (
                                    <Box {...props} aria-disabled={(checkDisabled || !option?.readyUse) ? 'true' : 'false'}  >
                                        {option?.lotNumber ? formatNumber(option?.lotNumber) : ''}
                                    </Box>
                                )
                            }}
                            onchange={(e, value) => {
                                handleChangeVoucherLotDetail(value, index)
                            }}
                            value={lotDetailSelect}
                            disableClearable
                        />
                    </Box>
                )
            },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box >
                        <InputSecondNewDatePicker
                            key={'start-date'}
                            disabled
                            value={lotDetailSelect?.endDate}
                            onchange={() => console.log()}
                        />
                    </Box>
                )
            },
            { option: 'TEXT', class: 'text-nowrap ', align: 'center', label: lotDetailSelect?.total || '0' },
            { option: 'TEXT', class: 'text-nowrap ', align: 'center', label: lotDetailSelect?.totalUsed || '0' },
            { option: 'TEXT', class: 'text-nowrap ', align: 'center', label: Number(lotDetailSelect?.readyUse || 0) - Number(lotDetailSelect?.qty || 0) },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box display={'flex'} gap={'8px'} >
                        <InputTextField
                            onchange={(event) => handleChangeVoucherQrt(lotDetailSelect, Number(event.target.value), index)}
                            value={lotDetailSelect?.qty || ''}
                            helperText={(!lotDetailSelect?.qty && errorMessage?.QTY) ? errorMessage?.QTY : ''}
                        />
                    </Box>
                )
            },
            {
                option: 'COMPONENT', class: 'text-nowrap', component: (
                    <Box textAlign={'center'} >
                        <IconButton onClick={() => handleDelVoucher(index)} >
                            <img src={icons.btnTrash} alt="trash" />
                        </IconButton>
                    </Box>
                )
            },
        ]

        const objRenderData = {
            key: voucherId,
            id: voucherId,
            obj: objData,
            columns: commonColumns
        }

        return <TableRowCommon {...objRenderData} />
    }

    const onSubmit = async () => {
        if (!voucherSelect?.length) {
            notiError(t('VOUCHER.REGISTER.MESSAGE.ERROR.VOUCHER_BIND'))
            return
        } else {
            if (voucherSelect.some((item: any) => !item?.lotDetailSelect?.qty)) {
                setErrorMessage({ QTY: t('VOUCHER.REGISTER.MESSAGE.ERROR.COUNT_BIND') })
                return
            } else {
                setLoad(true)
                const newPayload: VoucherPatientUseProps = {
                    patientId: patient?.patientId,
                    vouchers: voucherSelect.map((item: any) => {
                        return { voucherId: item?.voucherId, lotNumber: item?.lotDetailSelect?.lotNumber, qty: item?.lotDetailSelect?.qty }
                    })
                }
                await VoucherApi.updateVoucherPatientUse(newPayload).then((res) => {
                    props.onClose()
                    props.reload()
                }).catch(() => {
                    // 
                }).finally(() => {
                    setLoad(false)
                })
            }
        }

    }

    return (
        <>
            <Loading show={load} />
            <ModalCustom
                title={t('VOUCHER.REGISTER.MODAL_BIND.TITLE')}
                modalStyle={'modal-bind-voucher-regsiter'}
                textBtnCancel={t('BUTTON.CANCEL')}
                onReset={() => props.onClose()}
                textBtnConfirm={t('BUTTON.SAVE')}
                onSubmit={() => onSubmit()}
                onClose={() => props.onClose()}
                alignFooter={'end'}
                closeButton
                component={(
                    <>
                        <Box>
                            <Box>
                                <Box>
                                    {t('VOUCHER.REGISTER.MODAL_BIND.TITLE_CURRENT')}
                                </Box>
                                <Box display={'flex'} flexWrap={'wrap'} width={{ md: 'calc(100% + 12px)', xs: '100%' }} margin={{ md: '16px -6px', xs: '16px 0' }} gap={{ md: '0', xs: '8px' }}>
                                    <Box
                                        width={{ md: 'calc(100% / 3)', xs: '100%' }}
                                        padding={{ md: '0px 6px', xs: '0' }}
                                        sx={{ '& .MuiOutlinedInput-root': { '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C5C5C5 !important', }, }, }}
                                    >
                                        <TextInput
                                            onChange={(event) => setSearch(event.target.value)}
                                            fullWidth
                                            value={search}
                                            placeholder={t('VOUCHER.REGISTER.MODAL_BIND.SEARCH')}
                                            inputProps={{
                                                endAdornment: <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                                    <Box sx={{ cursor: 'pointer', display: (!search || search === "") ? "none" : "block", svg: { width: '13px' }, path: { fill: '#000000 !important', stroke: 'transparent !important', } }} onClick={() => setSearch("")}><IconClose /></Box>
                                                    <FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} />
                                                </Box>
                                            }}
                                        />
                                    </Box>
                                    <Box width={{ md: 'calc(100% / 3)', xs: '100%' }} padding={{ md: '0px 6px', xs: '0' }}>
                                        <MultiSelectFilter
                                            labelId='type'
                                            inputLabel={t('VOUCHER.REGISTER.MODAL_BIND.GROUP')}
                                            selectOption={vocherGroupOp}
                                            handleChange={(data: any) => handleChangeFilter(data, vocherGroupSelect, setVocherGroupSelect, vocherGroupOp)}
                                            selectValue={vocherGroupSelect}
                                        />
                                    </Box>
                                    <Box width={{ md: 'calc(100% / 3)', xs: '100%' }} padding={{ md: '0px 6px', xs: '0' }} sx={{ 'button': { width: { md: '100px', xs: '100%' } } }}>
                                        <MainButton
                                            onClick={() => {
                                                setPage(1)
                                                loadVoucherPatient()
                                            }}
                                            title={t('INPUT.SEARCH')}
                                            type={'primary'}
                                            variant={'contained'}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ '.col-voucher-name': { minWidth: '250px' } }}>
                                    <TableCustom
                                        page={page}
                                        pageLimit={pageLimit}
                                        sortType={sortType}
                                        sortBy={sortBy}
                                        onSort={onRequestSort}
                                        setPageLimit={handleChangeRowsPerPage}
                                        setPage={handleChangePage}
                                        rowCount={rowCount}
                                        headCells={headCells}
                                        rowsData={dataVoucher?.length ? dataVoucher.map((item: any, index: number) => {
                                            return renderData(item, index)
                                        }) : []}
                                    />
                                </Box>
                            </Box>

                            {voucherSelect?.length ?
                                <Box>
                                    <Box>
                                        {t('VOUCHER.REGISTER.MODAL_BIND.TITLE')}
                                    </Box>
                                    <Box sx={{ paddingTop: '16px', '.col-voucher-name': { minWidth: '250px' }, '.col-voucher-lotnumber': { minWidth: '150px' }, '.col-dateend': { minWidth: '180px' } }}>
                                        <TableCustom
                                            page={page}
                                            pageLimit={pageLimit}
                                            sortType={sortType}
                                            sortBy={sortBy}
                                            onSort={onRequestSort}
                                            setPageLimit={handleChangeRowsPerPage}
                                            setPage={handleChangePage}
                                            rowCount={rowCount}
                                            headCells={headCellsSelect}
                                            rowsData={voucherSelect.map((item: any, index: number) => {
                                                return renderDataSelect(item, index)
                                            })}
                                            hidePagination
                                        />
                                    </Box>
                                </Box>
                                : <></>
                            }
                        </Box>
                    </>
                )}
            />
        </>
    )
}

export default FormBindVoucher