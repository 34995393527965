import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface MemberManagementface {
    rankCode: string
    rankName: string
    rankNameEn: string
    color: string
    totalPaid?: number
    serviceVisits?: number
    detail?: string
}

export interface defaultRank {
    defaultRank: number,
    autoIncrement: string
}

export default class MemberManagementApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get('member', { params: { ...props } }).then((res) => res)
    }
    static create(body: MemberManagementface) {
        return this.api.post('member', body)
    }

    static update(id: number, body: MemberManagementface) {
        return this.api.patch(`member/${id}`, body)
    }

    static updateDefault(id: number, defaultRank: defaultRank): Promise<any> {
        return this.api.patch(`member/${id}/default`, defaultRank)
    }

    static remove(id: number) {
        return this.api.delete(`member/${id}`)
    }
}
