import { useState, useEffect } from 'react'
import { Card, Form } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { Divider, Box, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import InputRadio from 'component/Input/InputRadio'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** STYLE */
import { ImageRatio, ServiceImage } from 'features/setting/branch-service-point/service-point/style';


/** API */
import LoginApi from 'api/login-api'

/** UTILS || SLICE */
import { checkPackage, getApp, numberOnly } from 'utils/app.utils'
import { PACKAGE_ADDONS } from 'constants/package'
import { PickRoom, colors } from 'constants/theme';

/** CONSTANT */
import { menuFirsts, menuSeconds, menuThirds } from 'constants/menus'
import InputTextField from 'component/Input/InputTextField'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import ServicePointApi from 'api/setting/branch-service-points/service-point.api'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import { routeName } from 'routes/routes-name'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import InputCheckbox from 'component/Input/InputCheckbox'
import Loading from 'component/Loading';
import AboutUs from 'api/admin/clinic-manage/about.api';

const initStateErrorMessage = {
  SERVICE_POINT_NAME: '',
  IS_DUPLICATE_SERVICE_POINT_NAME: '',
  SERVICE_POINT_NAME_STRING_BASE: '',
  SERVICE_POINT_NAME_STRING_EMPTY: '',
  SERVICE_POINT_NAME_MAXIMUM_LENGTH: '',
  SERVICE_POINT_NAME_ANY_REQUIRED: '',
  SORTING_REQUIRED: '',
  IS_DUPLICATE_SORTING: '',
  IS_DUPLICATE_SERVICE_POINT_NAME_EN: ''
}

const initStateEditMenu = {
  createdAt: '',
  createdBy: '',
  pointOfService: 'ROOMSERVICES',
  servicePointId: '',
  servicePointMenus: [
    {
      menuFirstId: 0,
      menuSecondId: 0,
      menuThirdId: 0
    }
  ],
  servicePointName: '',
  servicePointNameEn: '',
  status: '',
  sorting: null,
  updatedAt: '',
  updatedBy: ''
}

interface MenuSelectInterface {
  menuFirstId: number
  menuSecondId: number | null
  menuThirdId: number | null
}

export default function FormServicePoint() {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [initLocation, setInitLocation] = useState({})
  const [menus, setMenu] = useState<any>([])
  const [allMenus, setAllMenu] = useState<MenuSelectInterface[] | []>([])
  const [servicePointId, setServicePointId] = useState(Number)
  const [servicePointName, setServicePointName] = useState('')
  const [servicePointNameEn, setServicePointNameEn] = useState('')
  const [sorting, setSorting] = useState<any | null>(null);
  const [pointOfService, setPointOfService] = useState('ROOMSERVICES')
  const [statusServicePoint, setStatusServicePoint] = useState('ACTIVE')
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)


  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [editMenu, setEditMenu] = useState(initStateEditMenu)
  const [menuSelects, setMenuSelects] = useState<MenuSelectInterface[] | []>([])

  const [loadingPermiss, setLoadingPermiss] = useState<boolean>(false)
  const [permissClinic, setPermissClinic] = useState<any>({})

  // const res = await AboutUs.findAboutUs();

  const loadPermissClinic = async () => {
    setLoadingPermiss(true)
    const res = await AboutUs.findAboutUs().finally(() => setLoadingPermiss(false))
    setPermissClinic(res?.data || {})
  }

  useEffect(() => {
    setLoading(true)
    loadPermissClinic()
    LoginApi.menus().then(({ data }) => {
      if (data) {
        initRender(data)
      }
    })
    setInitLocation(location)
    const { state }: any = initLocation
    setEditMenu(state)
    setTimeout(function () {
      if (editMenu) onEdit()
      setLoading(false)
    }, 1000)
  }, [location, editMenu])

  const initRender = (menusRender: any) => {
    const menuAll: { menuFirstId: any; menuSecondId: any; menuThirdId: any }[] = []
    const tempFirstSelect: any = []
    const tempSecondSelect: any = []
    const tempThirdSelect: any = []

    setMenu(menusRender)

    for (const keyFirst in menusRender) {
      if (Object.prototype.hasOwnProperty.call(menusRender, keyFirst)) {
        const menuFirst = menusRender[keyFirst]
        menuAll.push({
          menuFirstId: menuFirst.menuFirstId,
          menuSecondId: null,
          menuThirdId: null
        })
        if (menuFirst.menuSeconds.length) {
          for (const keySecond in menuFirst.menuSeconds) {
            if (Object.prototype.hasOwnProperty.call(menuFirst.menuSeconds, keySecond)) {
              const menuSecond = menuFirst.menuSeconds[keySecond]

              if (menuSecond.menuThirds.length)
                for (const keyThird in menuSecond.menuThirds) {
                  if (Object.prototype.hasOwnProperty.call(menuSecond.menuThirds, keyThird)) {
                    const menuThird = menuSecond.menuThirds[keyThird]
                    menuAll.push({
                      menuFirstId: menuFirst.menuFirstId,
                      menuSecondId: menuSecond.menuSecondId,
                      menuThirdId: menuThird.menuThirdId
                    })
                    tempThirdSelect[menuThird.menuThirdId] = false
                  }
                }
              else {
                menuAll.push({
                  menuFirstId: menuFirst.menuFirstId,
                  menuSecondId: menuSecond.menuSecondId,
                  menuThirdId: null
                })
                tempSecondSelect[menuSecond.menuSecondId] = false
              }
            }
          }
        } else {
          tempFirstSelect[menuFirst.menuFirstId] = false
        }
      }
    }

    setAllMenu(menuAll)
    setLoading(false)
  }

  const onEdit = () => {
    const { servicePointId: servicePointIdData, servicePointName: servicePointNameData, servicePointNameEn: servicePointNameEnData, pointOfService: servicePointOfService, sorting: servicePointSorting, status: servicePointStatus } = editMenu

    setServicePointId(Number(servicePointIdData))
    setServicePointName(servicePointNameData)
    setServicePointNameEn(servicePointNameEnData)
    setStatusServicePoint(servicePointStatus)
    setPointOfService(servicePointOfService)
    setSorting(servicePointSorting)

    if (editMenu.servicePointMenus.length) setMenuSelects(editMenu.servicePointMenus?.map((spm: any) => ({ menuFirstId: spm.menuFirstId, menuSecondId: spm.menuSecondId, menuThirdId: spm.menuThirdId })))
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const submit = () => {
    if (!servicePointName) return setErrorMessage({ ...errorMessage, ...{ SERVICE_POINT_NAME: t(`SERVICE_POINT.MESSAGE.SERVICE_POINT_NAME`) } })
    if (!pointOfService) return setErrorMessage({ ...errorMessage, ...{ POINT_OF_SERVICE: t(`SERVICE_POINT.MESSAGE.POINT_OF_SERVICE`) } })
    if (!sorting && sorting !== 0) return setErrorMessage({ ...errorMessage, ...{ SORTING_REQUIRED: t(`SERVICE_POINT.MESSAGE.SORTING_REQUIRED`) } })

    const servicePointMenus = menuSelects

    setLoading(true)

    const objCreate = {
      servicePointName: servicePointName,
      servicePointNameEn: servicePointNameEn,
      pointOfService: pointOfService,
      servicePointMenus: servicePointMenus,
      sorting: sorting
    }

    const hasRoleMenu: boolean = _.some(servicePointMenus, (menu) => menu.menuFirstId > 0)
    if (!hasRoleMenu) {
      setLoading(false)
      return notiError(t('SERVICE_POINT.MESSAGE.NO_SELECT_ROLE_MENUS'))
    }

    ServicePointApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`SERVICE_POINT.MESSAGE.SUCCESS.CREATE`), '', null, () => {

            return
          })
          history.push(routeName.servicePoint)
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`SERVICE_POINT.MESSAGE.${err.message}`) } })
          if (err.message === 'IS_DUPLICATE_POINTOFSERVICE') return notiError(t('SERVICE_POINT.MESSAGE.IS_DUPLICATE_POINT_OF_SERVICE'))
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`SERVICE_POINT.MESSAGE.${err.message}`) } })
        if (err.message === 'IS_DUPLICATE_POINTOFSERVICE') return notiError(t('SERVICE_POINT.MESSAGE.IS_DUPLICATE_POINT_OF_SERVICE'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitUpdate = () => {
    if (!servicePointName) return setErrorMessage({ ...errorMessage, ...{ SERVICE_POINT_NAME: t(`SERVICE_POINT.MESSAGE.SERVICE_POINT_NAME`) } })
    if (!pointOfService) return setErrorMessage({ ...errorMessage, ...{ POINT_OF_SERVICE: t(`SERVICE_POINT.MESSAGE.POINT_OF_SERVICE`) } })
    if (!sorting && sorting !== 0) return setErrorMessage({ ...errorMessage, ...{ SORTING_REQUIRED: t(`SERVICE_POINT.MESSAGE.SORTING_REQUIRED`) } })

    setLoading(true)

    const servicePointMenus = menuSelects
    const objUpdate = {
      servicePointName: servicePointName,
      servicePointNameEn: servicePointNameEn,
      pointOfService: pointOfService,
      servicePointMenus: servicePointMenus,
      status: statusServicePoint,
      sorting: sorting
    }

    const hasRoleMenu: boolean = _.some(servicePointMenus, (menu) => menu.menuFirstId > 0)
    if (!hasRoleMenu) {
      setLoading(false)
      return notiError(t('SERVICE_POINT.MESSAGE.NO_SELECT_ROLE_MENUS'))
    }

    ServicePointApi.update(servicePointId, objUpdate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`SERVICE_POINT.MESSAGE.SUCCESS.UPDATE`), '', null, () => {
            return
          })
          history.push(routeName.servicePoint)
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`SERVICE_POINT.MESSAGE.${err.message}`) } })
          if (err.message === 'IS_DUPLICATE_POINTOFSERVICE') return notiError(t('SERVICE_POINT.MESSAGE.IS_DUPLICATE_POINT_OF_SERVICE'))
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`SERVICE_POINT.MESSAGE.${err.message}`) } })
        if (err.message === 'IS_DUPLICATE_POINTOFSERVICE') return notiError(t('SERVICE_POINT.MESSAGE.IS_DUPLICATE_POINT_OF_SERVICE'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCheckAll = (e: any) => {
    const checked: boolean = e.target.checked

    setIndeterminate(false)
    setCheckAll(checked)
    if (checked) setMenuSelects(_.map(allMenus, (am) => am))
    else setMenuSelects([])

  }

  useEffect(() => {
    setCheckAll(allMenus.length === menuSelects.length)

    _.map(Object.keys(_.groupBy(menuSelects, 'menuFirstId')), (fId: number) => {
      const filterMs: MenuSelectInterface[] = _.filter(menuSelects, (ms: MenuSelectInterface) => String(ms.menuFirstId) === String(fId))
      const countMs: number = filterMs.length
      const findMenu: any = _.find(menus, (menu: any) => String(menu.menuFirstId) === String(fId))
      if (findMenu !== undefined)
        if (findMenu.menuSeconds.length > 0) {
          const countChecked: number = _.filter(filterMs, (fMs: MenuSelectInterface) => fMs.menuSecondId !== null).length
          const obj: any = { menuFirstId: Number(fId), menuSecondId: null, menuThirdId: null }
          if (countChecked) {
            if (countMs === countChecked) setMenuSelects([...menuSelects, obj])
          } else setMenuSelects([..._.filter(menuSelects, (ms: MenuSelectInterface) => JSON.stringify(ms) !== JSON.stringify(obj))])
        }
    })
  }, [menuSelects])

  const handleCheckMenu = (e: any, value: MenuSelectInterface) => {
    const checked: boolean = e?.target?.checked || false
    const { menuFirstId, menuSecondId, menuThirdId }: MenuSelectInterface = value

    const arrVal: any = []
    const menuFirst: any = _.find(menus, (menu: any) => menu.menuFirstId === menuFirstId)
    if (menuFirst) {
      const { menuFirstId, menuSeconds } = menuFirst
      const menuSecond: any = _.find(menuSeconds, (data: any) => data.menuSecondId === menuSecondId)
      if (!menuSecond)
        _.map(menuSeconds, (data: any) => {
          const { menuSecondId, menuThirds } = data
          const valSecond: MenuSelectInterface = { menuFirstId, menuSecondId, menuThirdId: null }
          arrVal.push(valSecond)

          const menuThird: any = _.find(menuThirds, (dataMenuThirds: any) => dataMenuThirds.menuThirdId === menuThirdId)
          if (!menuThird)
            _.map(menuThirds, (dataMenuThirds: any) => {
              const { menuThirdId } = dataMenuThirds
              const valThird: MenuSelectInterface = { menuFirstId, menuSecondId, menuThirdId }
              arrVal.push(valThird)
            })

        })
    }

    const insertVal: any[] = _.map([value, ...arrVal], (ms: MenuSelectInterface) => JSON.stringify(ms))

    if (checked) {
      setMenuSelects([...menuSelects, value, ...arrVal])
    } else {

      setMenuSelects(menuSelects.filter((ms: MenuSelectInterface) => !insertVal.includes(JSON.stringify(ms))))
    }
  }

  const checkSelect = (value: MenuSelectInterface) => (menuSelects.find((ms: MenuSelectInterface) => ms.menuFirstId === value.menuFirstId && ms.menuSecondId === value.menuSecondId && ms.menuThirdId === value.menuThirdId) ? true : false)

  return (
    <div className={`container-card h-100 overflow-hidden`}>
      <Box className="h-100 d-flex flex-column">
        <HeaderLabel
          text={servicePointId ? t('SERVICE_POINT.TITLE_UPDATE') : t('SERVICE_POINT.TITLE_CREATE')}
          goBack={routeName.servicePoint}
          disabledSend={loading}
          onSubmit={servicePointId ? submitUpdate : submit}
          navigator={{ previousTo: routeName.servicePoint, previousTitle: t('SERVICE_POINT.TITLE'), currentTitle: servicePointId ? t('SERVICE_POINT.TITLE_UPDATE') : t('SERVICE_POINT.TITLE_CREATE') }}
        />
        <Card className="border shadow-sm mx-4 mb-4 h-100 overflow-hidden">
          <Card.Body className="h-100 py-0 pl-2 pr-3 overflow-hidden">
            <Grid container spacing={1} className={'h-100 mt-0'}>
              <Grid item lg={2} className={'w-100 pr-lg-2 border-right'} sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` } }}>
                <div className="align-content-center flex-center">
                  <ServiceImage className="text-center w-100 p-4" style={{ border: `1px solid ${colors.lightGray}` }}>
                    <ImageRatio className="position-relative mx-auto">
                      <Box className="w-100">
                        {
                          (pointOfService === 'COUNTER' && <PickRoom.Counter color={colors.themeMainColor} />) ||
                          (pointOfService === 'PAYMENT' && <PickRoom.Payment color={colors.themeMainColor} />) ||
                          (pointOfService === 'ROOMSERVICES' && <PickRoom.Service color={colors.themeMainColor} />) ||
                          <PickRoom.OtherService color={colors.themeMainColor} />
                        }
                      </Box>
                    </ImageRatio>
                    <p className={'m-0'} style={{ fontSize: 18, fontWeight: 500, color: colors.themeMainColor }}>
                      {t(`SERVICE_POINT.${pointOfService}`)}
                    </p>
                  </ServiceImage>
                </div>
                {servicePointId ? (
                  <div className={'mt-3'}>
                    <Form className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-xl-between w-100s">
                      <Form.Label className="mr-3 mb-0">{t('INPUT.LABEL_STATUS')}</Form.Label>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        onChange={() => setStatusServicePoint(statusServicePoint === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                        checked={statusServicePoint === 'ACTIVE' ? true : false}
                        className="ml-xl-auto"
                      />
                    </Form>
                    <Divider className="mx-0 my-3 d-xl-none" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item lg={10} className={'w-100 pl-4 h-100 custom-scroll overflow-auto'}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={'w-100 mt-xl-4'}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} lg={3} className={'w-100'}>
                        <InputTextField
                          label={t('SERVICE_POINT.INPUT.SERVICE_POINT_NAME')}
                          value={servicePointName}
                          hookValue={true}
                          onchange={(event) => {
                            setServicePointName(event.target.value)
                            clearErrorMessage()
                          }}
                          helperText={
                            errorMessage.SERVICE_POINT_NAME ||
                            errorMessage.IS_DUPLICATE_SERVICE_POINT_NAME ||
                            errorMessage.SERVICE_POINT_NAME_STRING_BASE ||
                            errorMessage.SERVICE_POINT_NAME_STRING_EMPTY ||
                            errorMessage.SERVICE_POINT_NAME_MAXIMUM_LENGTH ||
                            errorMessage.SERVICE_POINT_NAME_ANY_REQUIRED
                          }
                          required={true}
                        />
                      </Grid>
                      <Grid item sm={6} lg={3} className={'w-100'}>
                        <InputTextField label={t('SERVICE_POINT.INPUT.SERVICE_POINT_NAME_EN')}
                          helperText={errorMessage.IS_DUPLICATE_SERVICE_POINT_NAME_EN}
                          value={servicePointNameEn} onchange={(e) => setServicePointNameEn(e.target.value)} />
                      </Grid>
                      <Grid item sm={6} lg={3} className={'w-100'}>
                        <InputTextField label={t('SERVICE_POINT.INPUT.SORT')} value={sorting}
                          required
                          onchange={(e) => {
                            setSorting(numberOnly(e) || '')
                            clearErrorMessage()
                          }}
                          helperText={
                            errorMessage.SORTING_REQUIRED ||
                            errorMessage.IS_DUPLICATE_SORTING}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={'w-100 d-flex justify-content-between justify-content-sm-start ml-1'}>
                    <FormControl className="mr-2">
                      <RadioGroup
                        row
                        aria-label="pointOfService"
                        onChange={(event) => setPointOfService(event.target.value)}
                        className="d-block d-sm-flex"
                        name="row-radio-buttons-group">
                        <FormControlLabel className="d-block d-sm-inline-block" checked={pointOfService === 'COUNTER'} value="COUNTER" control={<InputRadio />} label={t('SERVICE_POINT.COUNTER')} />
                        <FormControlLabel className="d-block d-sm-inline-block" checked={pointOfService === 'PAYMENT'} value="PAYMENT" control={<InputRadio />} label={t('SERVICE_POINT.PAYMENT')} />
                        <FormControlLabel className="d-block d-sm-inline-block" checked={pointOfService === 'ROOMSERVICES'} value="ROOMSERVICES" control={<InputRadio />} label={t('SERVICE_POINT.ROOMSERVICES')} />
                        <FormControlLabel className="d-block d-sm-inline-block mr-1" checked={pointOfService === 'SERVICES'} value="SERVICES" control={<InputRadio />} label={t('SERVICE_POINT.SERVICES')} />
                        <ButtonInfoSimple text={t('SERVICE_POINT.MESSAGE.SERVICE_NOT_VISIBLE')} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider className="mx-0 mt-2" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                <Typography className={'my-3'} sx={{ fontSize: '18px', fontWeight: 500 }}>
                  {t('SERVICE_POINT.MENU_PERMISSION')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={'w-100'}>
                    <Box className="pl-2">
                      <InputCheckbox key={'check-all'} label={t('SERVICE_POINT.SELECT_ALL')} checked={checkAll} indeterminate={indeterminate} onChange={handleCheckAll} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={'w-100'}>
                    <Grid
                      container
                      spacing={2}
                      className="pb-4"
                      sx={{
                        '.divider-backend-menu': {
                          display: 'none',
                          '&:not(.divider-backend-menu ~ .divider-backend-menu).divider-backend-menu': {
                            display: 'block'
                          }
                        }
                      }}>
                      {_.map(_.sortBy(menus, ['menuFirstSorting']), ({ menuFirstId, menuFirstKey, menuSeconds: mSnds, menuFirstTags }: any, indexFirstKeyNumber: number) => {
                        if (menuFirstKey !== 'CLINIC_MANAGEMENT' && checkPackage(_.includes(PACKAGE_ADDONS, menuFirstKey) ? menuFirstKey : ''))

                          return (
                            <>
                              {menuFirstTags === 'BACKEND' && (
                                <Grid
                                  item xs={12}
                                  key={indexFirstKeyNumber}
                                  className={'w-100 divider-backend-menu'}>
                                  {/* <Divider className="mx-0 my-3" sx={{ backgroundColor: `transparent !important`, opacity: 1, borderColor: `${colors.lightGray} !important` }} /> */}
                                </Grid>
                              )}
                              {mSnds.length === 0 ? (
                                <Grid item md={6} key={menuFirstId} className={'w-100 d-flex flex-column'}>
                                  <Box className="pl-2 mb-auto" sx={{ backgroundColor: colors.themeSecondColor10, fontSize: '18px' }}>
                                    <InputCheckbox
                                      key={menuFirstId}
                                      label={menuFirsts[menuFirstKey] ? t(menuFirsts[menuFirstKey]?.textName) : 'NO_Name'}
                                      checked={checkSelect({ menuFirstId, menuSecondId: null, menuThirdId: null })}
                                      onChange={(e) => {
                                        handleCheckMenu(e, { menuFirstId, menuSecondId: null, menuThirdId: null })
                                      }}
                                      style={{ '.MuiFormControlLabel-label': { fontSize: '18px', fontWeight: 500 } }}
                                    />
                                  </Box>
                                  <Divider className={`mx-0 mt-2 ${indexFirstKeyNumber + 1 === menus.length ? 'd-none' : ''}`} sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                                </Grid>
                              ) : (
                                [
                                  <Grid item md={indexFirstKeyNumber + 1 === menus.length ? 12 : 6} key={menuFirstId} className={`w-100 d-flex flex-column`}>
                                    <Box className="pl-2 d-flex align-items-center" sx={{ height: '42px', backgroundColor: colors.themeSecondColor10 }}>
                                      <InputCheckbox
                                        label={menuFirsts[menuFirstKey] ? t(menuFirsts[menuFirstKey]?.textName) : 'NO_Name'}
                                        checked={checkSelect({ menuFirstId, menuSecondId: null, menuThirdId: null })}
                                        onChange={(e) => handleCheckMenu(e, { menuFirstId, menuSecondId: null, menuThirdId: null })}
                                        style={{ '.MuiFormControlLabel-label': { fontSize: '18px', fontWeight: 500 } }}
                                      />
                                    </Box>

                                    <Grid container spacing={1} key={menuFirstId} className="mt-1 mb-auto">
                                      {_.map(_.sortBy(mSnds, ['menuSecondSorting']), ({ menuSecondId, menuSecondKey, menuThirds: mTrds }: any, indexSecound: number) => {
                                        // TREATMENT_RIGHT
                                        const isCheckApp = menuSeconds[menuSecondKey]?.key === "APPLICATION" ? getApp() === "PERMISS" && permissClinic?.enableApp === '1' : true
                                        const isCheckWeb = menuSeconds[menuSecondKey]?.key === "WEB_MANAGE" ? permissClinic?.useWeb === '1' : true
                                        const isCheckCrm = menuSeconds[menuSecondKey]?.key === "CRM_MANAGE" ? permissClinic?.useCrm === '1' : true

                                        if (["CHECKIN"].includes(menuSeconds[menuSecondKey]?.key)) {
                                          return
                                        }
                                        if (
                                          checkPackage(_.includes(PACKAGE_ADDONS, menuSecondKey) ? menuSecondKey : '') &&
                                          menuSeconds[menuSecondKey] &&
                                          isCheckApp &&
                                          isCheckWeb &&
                                          isCheckCrm
                                        )
                                          return mTrds.length === 0 ? (
                                            <Grid item sm={6} key={menuSecondId} className={'w-100 d-flex flex-column'} sx={{ padding: { xl: '0 32px !important' } }}>
                                              {!["TREATMENT_RIGHT"].includes(menuSeconds[menuSecondKey]?.key) ?
                                                <Box className="mb-auto ml-2">
                                                  <InputCheckbox
                                                    key={menuSecondId}
                                                    label={menuSeconds[menuSecondKey] ? t(menuSeconds[menuSecondKey]?.textName) : 'NO_Name'}
                                                    checked={checkSelect({ menuFirstId, menuSecondId, menuThirdId: null })}
                                                    onChange={(e) => handleCheckMenu(e, { menuFirstId, menuSecondId, menuThirdId: null })}
                                                  />
                                                </Box>
                                                :
                                                <Box className="mb-auto">
                                                  <Typography className="mt-1 mb-1" sx={{ color: colors.themeMainColor, fontWeight: 500 }}>
                                                    {menuSeconds[menuSecondKey] ? t(menuSeconds[menuSecondKey]?.textName) : 'NO_Name'}
                                                  </Typography>
                                                  <Box sx={{ padding: { xl: '0 16px !important' } }}>
                                                    <Grid container spacing={1}>
                                                      <Grid item md={12} className={`w-100 ml-2 `}>
                                                        <InputCheckbox
                                                          key={menuSeconds[menuSecondKey]}
                                                          label={menuSeconds[menuSecondKey] ? t(menuSeconds[menuSecondKey]?.textName) : 'NO_Name'}
                                                          checked={checkSelect({ menuFirstId, menuSecondId, menuThirdId: null })}
                                                          onChange={(e) => handleCheckMenu(e, { menuFirstId, menuSecondId, menuThirdId: null })}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </Box>
                                                </Box>
                                              }

                                              {menuFirsts[menuFirstKey]?.name === 'Setting' && <Divider className="mx-0 mt-2 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
                                            </Grid>
                                            // USAGE_APPROVAL_MANAGEMENT
                                          ) : menuSeconds[menuSecondKey]?.key === "USAGE_APPROVAL_MANAGEMENT" ? (
                                            <Grid item xs={12} sm={menuFirsts[menuFirstKey]?.name === 'Setting' ? 6 : 12} key={menuSecondId} className={'w-100 d-flex flex-column'}
                                              sx={{
                                                padding: {
                                                  xl: '0 32px !important', "&:last-child": {
                                                    'hr': {
                                                      display: "none"
                                                    }
                                                  }
                                                }
                                              }}>
                                              <Box className="mb-auto">
                                                <Typography className="mt-1 mb-1" sx={{ color: colors.themeMainColor, fontWeight: 500 }}>
                                                  {menuSeconds[menuSecondKey] ? t(menuSeconds[menuSecondKey]?.textName) : 'NO_Name'}
                                                </Typography>
                                                <Box sx={{ padding: { xl: '0 16px !important' } }}>
                                                  <Grid container spacing={1}>
                                                    {_.map([menus[indexFirstKeyNumber]?.menuSeconds?.find((i: any) => i?.menuSecondKey === "CHECKIN"), ..._.sortBy([...mTrds], ['menuThirdSorting'])], ({ menuThirdId, menuThirdKey, menuSecondId, menuSecondKey: menuSecondKey_, }: any) => {

                                                      if (menuSecondKey_ && checkPackage(_.includes(PACKAGE_ADDONS, menuSecondKey_) ? menuSecondKey_ : '')) {

                                                        return (
                                                          <Grid item key={menuSecondKey_} md={6} className={`w-100`} sx={{ 'label': { marginLeft: '0' } }}>
                                                            <InputCheckbox
                                                              key={menuSeconds[menuSecondKey_]}
                                                              label={menuSeconds[menuSecondKey_] ? t(menuSeconds[menuSecondKey_]?.textName) : 'NO_Name'}
                                                              checked={checkSelect({ menuFirstId, menuSecondId, menuThirdId: null })}
                                                              onChange={(e) => handleCheckMenu(e, { menuFirstId, menuSecondId, menuThirdId: null })}
                                                            />
                                                          </Grid>
                                                        )
                                                      }

                                                      if (checkPackage(_.includes(PACKAGE_ADDONS, menuThirdKey) ? menuThirdKey : ''))
                                                        return (
                                                          <Grid item key={menuThirdId} md={6} className={`w-100 ml-0`} sx={{ 'label': { marginLeft: '0' } }}>
                                                            <InputCheckbox
                                                              key={menuThirdId}
                                                              label={menuThirds[menuThirdKey] ? t(menuThirds[menuThirdKey]?.textName) : 'NO_Name'}
                                                              checked={checkSelect({ menuFirstId, menuSecondId, menuThirdId })}
                                                              onChange={(e) => handleCheckMenu(e, { menuFirstId, menuSecondId, menuThirdId })}
                                                            />
                                                          </Grid>
                                                        )
                                                    }
                                                    )}
                                                  </Grid>
                                                </Box>
                                              </Box>
                                              <Divider className="mx-0 mt-2 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important`, display: indexSecound + 1 === mSnds.length ? 'none' : '' }} />
                                            </Grid>
                                          ) : (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={menuFirsts[menuFirstKey]?.name === 'Setting' ? 6 : 12}
                                              key={menuSecondId}
                                              className={'w-100 d-flex flex-column'}
                                              sx={{
                                                padding: {
                                                  xl: '0 32px !important',
                                                  "&:last-child": {
                                                    'hr': {
                                                      display: "none"
                                                    }
                                                  }
                                                }
                                              }}>
                                              <Box className="mb-auto">
                                                <Typography className="mt-1 mb-1" sx={{ color: colors.themeMainColor, fontWeight: 500 }}>
                                                  {menuSeconds[menuSecondKey] ? t(menuSeconds[menuSecondKey]?.textName) : 'NO_Name'}
                                                </Typography>
                                                <Box sx={{ padding: { xl: '0 16px !important' } }}>
                                                  <Grid container spacing={1}>
                                                    {_.map(_.sortBy(mTrds, ['menuThirdSorting']), ({ menuThirdId, menuThirdKey }: any) => {
                                                      if (checkPackage(_.includes(PACKAGE_ADDONS, menuThirdKey) ? menuThirdKey : ''))
                                                        return (
                                                          <Grid item key={menuThirdId} md={12} className={`w-100 ml-2 ${menuThirdKey === 'CURRENCY' && 'd-none'}`}>
                                                            <InputCheckbox
                                                              key={menuThirdId}
                                                              label={menuThirds[menuThirdKey] ? t(menuThirds[menuThirdKey]?.textName) : 'NO_Name'}
                                                              checked={checkSelect({ menuFirstId, menuSecondId, menuThirdId })}
                                                              onChange={(e) => handleCheckMenu(e, { menuFirstId, menuSecondId, menuThirdId })}
                                                            />
                                                          </Grid>
                                                        )
                                                    }
                                                    )}
                                                  </Grid>
                                                </Box>
                                              </Box>
                                              <Divider className="mx-0 mt-2 mb-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important`, display: indexSecound + 1 === mSnds.length ? 'none' : '' }} />
                                            </Grid>
                                          )
                                      })}
                                    </Grid>
                                    <Divider className={`mx-0 mt-2 ${indexFirstKeyNumber + 1 === menus.length ? 'd-none' : ''}`} sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                                  </Grid>
                                ]
                              )}
                            </>
                          )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </Box>
      <Loading show={loading || loadingPermiss} type={'fullLoading'} />
    </div>
  )
}
