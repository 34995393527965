import { useEffect, useState } from 'react'
import { Box, Typography, Grid, MenuItem, Tooltip } from '@mui/material'
import _, { forEach } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS */
import { numberToCurrencyFormat } from 'utils/app.utils'

/** API */
import { PaymentInterface } from 'api/counter/payment/payment-interface'
import { fileUrl } from 'api/api'

/** STYLE */
import { styled } from '@mui/material/styles'
import Bank from 'assets/icon/payment-method/bank'
import Cash from 'assets/icon/payment-method/cash'
import Credit from 'assets/icon/payment-method/credit'
import Debit from 'assets/icon/payment-method/debit'
import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'

import {
  valBankTransfer,
  valBankCredit,
  valBankDebit,
  setBankTransfer,
  setBankCredit,
  setBankDebit,
} from 'app/slice/payment.slice'
import { onImgError } from 'constants/images'
import Advance from 'assets/icon/payment-method/advanced'

import BasicSelect from 'component/Select/BasicSelect'
import Voucher from 'assets/icon/payment-method/voucher'
import InputTextField from 'component/Input/InputTextField'

export const ChannelBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 4,
  border: `1px solid ${colors.lightGray}`,
  backgroundColor: colors.white,
  height: 96,
  paddingLeft: 10,
  paddingRight: 10,
  textAlign: 'center',
  '.MuiTypography-root': {
    color: colors.disabledGray
  },
  'p.channel-name': {
    width: '90%',
  },
  '&.active': {
    border: `5px solid ${colors.themeSecondColor}`,
    position: 'relative',
    '.MuiTypography-root': {
      color: colors.themeSecondColor
    }
  },
  '& .number': {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 24,
    minHeight: 24,
    color: colors.white,
    background: colors.themeSecondColor,
    borderRadius: '0px 0 20px',
    padding: '0px 0 0 6px',
    fontSize: 14,
    lineHeight: 'normal'
  },
  [theme.breakpoints.down('sm')]: {
    svg: {
      width: 'auto',
      height: 30
    }
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 1px',
    height: 44,
    outline: `1px solid ${colors.lightGray}`,
    border: '3px solid transparent',
    svg: {
      height: 20
    },
    '&.active': {
      border: `3px solid ${colors.themeSecondColor}`,
      outline: `1px solid transparent`
    }
  }
}))

export const ButtonBank = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 4,
  border: `2px solid ${colors.lightGray}`,

  img: {
    borderRadius: 4,
    height: 30,
    width: 30,
    objectFit: 'cover'
  },
  '&.active': {
    boxShadow: `0 0 6px 0 ${colors.themeSecondColor}`,
    borderColor: colors.themeSecondColor
  }
}))

export const TypographyBankNumber = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  [theme.breakpoints.up('sm')]: {
    fontSize: 16
  }
}))

type PaymentChannelsType = {
  banks?: any[]
  balancePaid?: number
  overdue?: number
  advance?: boolean
  selectChannels?: any
  data: any
  onChange: (data: any) => void

}

export default function PaymentChannels(props: PaymentChannelsType) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const bankCredit = useSelector(valBankCredit)
  const bankDebit = useSelector(valBankDebit)
  const bankTransfer = useSelector(valBankTransfer)

  /** OPTIONS */
  const [paymentChannels, setPaymentChannels]: any = useState([
    { key: 'TREATMENTRIGHT', paymentChanel: 5, isActive: false },
    { key: 'ADVANCE', paymentChanel: 6, isActive: false },
    { key: 'CASH', paymentChanel: 1, isActive: false },
    { key: 'TRANSFER', paymentChanel: 2, isActive: false },
    { key: 'CREDIT', paymentChanel: 3, isActive: false },
    { key: 'DEBIT', paymentChanel: 4, isActive: false },
    { key: 'VOUCHER', paymentChanel: 7, isActive: false }
  ])
  const [bankCredits, setBankCredits] = useState<any[]>([])
  const [bankDebits, setBankDebits] = useState<any[]>([])
  const [bankTransfers, setBankTransfers] = useState<any[]>([])

  /** VALUES */
  const [selectChannels, setSelectChannels]: any = useState<any[]>(props.selectChannels || [])
  const [disabled, setIsDisabled]: any = useState<any[]>([])
  const [editVerifySummary, setEditVerifySummary] = useState<any[]>([])

  useEffect(() => {
    setBankCredits([])
    setBankDebits([])
    setBankTransfers([])
    if (props.banks?.length) {
      setBankTransfers([...props.banks.filter((bank: any) => bank.hasTransfer === 'SUPPORT')])
      setBankCredits([...props.banks.filter((bank: any) => bank.hasCredit === 'SUPPORT')])
      setBankDebits([...props.banks.filter((bank: any) => bank.hasDebit === 'SUPPORT')])
    }

  }, [props.banks])


  const handleSelectPaymentChannel = (channel: any) => {
    let tempChannels: any = []
    channel.isActive = !channel.isActive
    if (!_.find(selectChannels, { key: channel.key })) {
      // dispatch(setPaymentChannelByKey({ key: channel.paymentChanel, value: true }))
      tempChannels = [...selectChannels, channel]
    } else {
      tempChannels = _.filter(selectChannels, (temp: any) => {
        if (temp) {
          return temp.key !== channel.key
        }
      })

      // dispatch(setPaymentChannelByKey({ key: channel.paymentChanel, value: false }))

      if (channel.key === 'TRANSFER') dispatch(setBankTransfer({}))
      if (channel.key === 'CREDIT') dispatch(setBankCredit({}))
      if (channel.key === 'DEBIT') dispatch(setBankDebit({}))
    }
    setSelectChannels(tempChannels)
  }


  useEffect(() => {
    const newSet: any = []
    _.map(paymentChannels, (paymentChannel, index) => {
      newSet.push({ ...paymentChannel, isActive: _.map(selectChannels, (e: any) => e.key).indexOf(paymentChannel.key) !== -1 ? true : false })
    })
    setPaymentChannels(newSet)
  }, [props.selectChannels, selectChannels])

  const handleSelectPaymentChannelTransfer = (bank: any) => {
    if (bankTransfer.bankId !== bank.bankId) dispatch(setBankTransfer(bank))
    else dispatch(setBankTransfer({}))
  }

  const handleSelectPaymentChannelCredit = (bank: any) => {
    if (bankCredit.bankId !== bank.bankId) dispatch(setBankCredit(bank))
    else dispatch(setBankCredit({}))
  }

  const handleSelectPaymentChannelDebit = (bank: any) => {
    if (bankDebit.bankId !== bank.bankId) dispatch(setBankDebit(bank))
    else dispatch(setBankDebit({}))
  }


  const disableTextFiled = (chanel: number) => {
    const paidBalance = _.sumBy(disabled[chanel], 'paidBalance')
    const value = _.sumBy(disabled[chanel], 'value')
    if (value === 0 && paidBalance === 0) {
      return chanel === 5 ? false : true
    } else {
      return false
    }
  }

  const checkIndex = (key: string) => {
    return _.findIndex(selectChannels, { key: key }) + 1
  }


  return (
    <Box className="mb-3">
      <Grid container spacing={2} className="mt-0">
        <Grid sm={6}>
          <UseStyled.Title className="px-md-2 px-xl-3 mt-2">
            {t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.TITLE')}
          </UseStyled.Title>
        </Grid>
        <Grid sm={6}>
          <Typography className="pl-3 d-none d-sm-block mt-2 " sx={{ flex: 'none', textAlign: 'right' }}>
            {`${props.data?.prefix}-${props.data?.advanceNumber}`}
          </Typography>
        </Grid>
      </Grid>
      <Box className="px-md-2 px-xl-3">
        <Grid container spacing={1} className="mt-0 d-none"  >
          {_.map(paymentChannels, (paymentChannel, index) => {
            return (
              <Grid item xs={3} className="pt-3" sx={{ width: '50%' }} style={{ display: (props.advance && paymentChannel.key === 'TREATMENTRIGHT') || (props.advance && paymentChannel.key === 'ADVANCE') ? 'none' : 'block' }}>
                <ChannelBox onClick={() => handleSelectPaymentChannel(paymentChannel)} className={`d-flex align-items-center justify-content-sm-center flex-sm-column px-2 px-sm-0 text-ellipsis ${paymentChannel.isActive ? 'active' : ''} disabled`}>
                  {checkIndex(paymentChannel.key) !== 0 && <p className={'number'}>{checkIndex(paymentChannel.key)}</p>}
                  {paymentChannel.key === 'CASH' && <Cash active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'TRANSFER' && <Bank active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'CREDIT' && <Credit active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'DEBIT' && <Debit active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'VOUCHER' && <Voucher style={{ width: 59, height: 40 }} active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  <Tooltip title={t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${paymentChannel.key}`)} arrow placement="right">
                    <Typography className="pl-2 pl-sm-0 pt-sm-1 text-ellipsis channel-name">{t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${paymentChannel.key}`)}</Typography>
                  </Tooltip>
                </ChannelBox>
              </Grid>
            )
          })}
        </Grid>

        {_.map(selectChannels, (selectChannels, index) => {
          return (
            <>
              {selectChannels.key === 'CASH' && (
                <div className="pt-3 ">
                  <UseStyled.TitlePayment>
                    {`${index + 1}. `}
                    {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}
                    ใช้ไปแล้ว: {numberToCurrencyFormat(props.data.usedCash)}
                  </UseStyled.TitlePayment>
                  <Box className="d-flex align-items-start mt-3 pr-md-2 pr-xl-3">
                    <Box className={`d-flex w-100 `} sx={{ paddingLeft: '2px' }}>
                      <><Typography className="pr-2" sx={{ fontWeight: 500 }}>
                        {t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.CASH')}
                      </Typography>
                      </>
                      <UseStyled.CustomField>
                        <InputTextField
                          value={props.data.paidCash || 0}
                          type='number'
                          // financeText
                          hideControl
                          onchange={(e) => props.onChange({ ...props.data, paidCash: e.target.value })}
                          inputTextAlign="right"
                        />
                      </UseStyled.CustomField>
                    </Box>
                  </Box>

                </div>
              )}
              {selectChannels.key === 'TRANSFER' && (
                <>
                  <div className="pt-3">
                    <UseStyled.TitlePayment>
                      {`${index + 1}. `}
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}{' '}
                      ใช้ไปแล้ว: {numberToCurrencyFormat(props.data.usedTransfer)}
                    </UseStyled.TitlePayment>
                    <Typography className="mt-3" sx={{ fontWeight: 500 }}>
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.TRANSFER`)}
                    </Typography>
                    <div className="pl-3">
                      {_.map(bankTransfers, (bank, index) => {
                        return (
                          <ButtonBank onClick={() => handleSelectPaymentChannelTransfer(bank)} className={`d-inline-block mt-3 mr-2 ${bankTransfer.bankId === bank.bankId ? 'active' : ''}`}>
                            <img src={`${fileUrl}/${bank.bankAvata}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                          </ButtonBank>
                        )
                      })}
                      {bankTransfer?.bankId && (
                        <Box className="d-flex align-items-start mt-3 pr-md-2 pr-xl-3">
                          <Box className={`d-flex w-100 `} sx={{ paddingLeft: '2px' }}>
                            <Box className="d-flex align-items-start align-items-md-center mx-0" sx={{ marginLeft: '-16px' }}>
                              <img src={`${fileUrl}/${bankTransfer.bankAvata}`} className="mt-1 mt-md-0" style={{ borderRadius: '4px', width: 30, height: 30 }} onError={onImgError} alt="" />
                              <Box>
                                <Typography className="d-inline-block ml-2">{bankTransfer.bankName}</Typography>
                                <TypographyBankNumber className="d-md-inline-block ml-2 ml-md-5">{t('PAYMENT.FORM_PAYMENT.TITLE_BANK', { number: bankTransfer.bankAccountNumber })}</TypographyBankNumber>
                              </Box>
                            </Box>
                            <UseStyled.CustomField>
                              <InputTextField
                                value={props.data.paidTransfer || 0}
                                type='number'
                                // financeText
                                hideControl
                                onchange={(e) => props.onChange({ ...props.data, paidTransfer: e.target.value })}
                                inputTextAlign="right"
                              />
                            </UseStyled.CustomField>
                          </Box>
                        </Box>
                      )}
                    </div>
                  </div>
                </>
              )}
              {selectChannels.key === 'CREDIT' && (
                <>
                  <div className="pt-3">
                    <UseStyled.TitlePayment>
                      {`${index + 1}. `}
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}{' '}
                      ใช้ไปแล้ว: {numberToCurrencyFormat(props.data.usedCredit)}
                    </UseStyled.TitlePayment>
                    <Typography className="mt-3" sx={{ fontWeight: 500 }}>
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.CREDIT`)}
                    </Typography>
                    <div className="pl-3">
                      {_.map(bankCredits, (bank, index) => {
                        return (
                          <ButtonBank onClick={() => handleSelectPaymentChannelCredit(bank)} className={`d-inline-block mt-3 mr-2 ${bankCredit.bankId === bank.bankId ? 'active' : ''}`}>
                            <img src={`${fileUrl}/${bank.bankAvata}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                          </ButtonBank>
                        )
                      })}
                      {bankCredit?.bankId && (
                        <Box className="d-flex align-items-start mt-3 pr-md-2 pr-xl-3">
                          <Box className={`d-flex w-100 `} sx={{ paddingLeft: '2px' }}>
                            <>
                              <div>
                                <Box className="d-flex align-items-start align-items-md-center mx-0 w-100" sx={{ marginLeft: '-16px' }}>
                                  <img src={`${fileUrl}/${bankCredit.bankAvata}`} className="mt-1 mt-md-0" style={{ borderRadius: '4px', width: 30, height: 30 }} onError={onImgError} alt="" />
                                  <Box>
                                    <Typography className="d-inline-block ml-2">{bankCredit.bankName}</Typography>
                                  </Box>
                                </Box>
                                <Box className="d-flex align-items-start align-items-md-center w-100">
                                  <Box>
                                    <TypographyBankNumber className="d-md-inline-block ml-2 ml-md-5 mr-2">{t('PAYMENT.FORM_PAYMENT.TITLE_CREDIT')}</TypographyBankNumber>
                                    {_.map(bankCredit.credit, (card, index) => (
                                      <ButtonBank onClick={() => dispatch(setBankCredit({ ...bankCredit, ...card }))} className={`d-inline-block mr-2 ${bankCredit.cardTypeId === card.cardTypeId ? 'active' : ''}`} style={{}}>
                                        <img src={`${fileUrl}/${card.cardTypeLogo}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                                      </ButtonBank>
                                    ))}
                                  </Box>
                                </Box>
                              </div>
                            </>
                            <UseStyled.CustomField>
                              <InputTextField
                                value={props.data.paidCredit || 0}
                                type='number'
                                // financeText
                                hideControl
                                onchange={(e) => props.onChange({ ...props.data, paidCredit: e.target.value })}
                                inputTextAlign="right"
                              />
                            </UseStyled.CustomField>
                          </Box>
                        </Box>
                      )}


                    </div>
                  </div>
                </>
              )}
              {selectChannels.key === 'DEBIT' && (
                <>
                  <div className="pt-3">
                    <UseStyled.TitlePayment>
                      {`${index + 1}. `}
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}{' '}
                      ใช้ไปแล้ว: {numberToCurrencyFormat(props.data.usedDebit)}
                    </UseStyled.TitlePayment>
                    <Typography className="mt-3" sx={{ fontWeight: 500 }}>
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.DEBIT`)}
                    </Typography>
                    <div className="pl-3">
                      {_.map(bankDebits, (bank, index) => {
                        return (
                          <ButtonBank onClick={() => handleSelectPaymentChannelDebit(bank)} className={`d-inline-block mt-3 mr-2 ${bankDebit.bankId === bank.bankId ? 'active' : ''}`}>
                            <img src={`${fileUrl}/${bank.bankAvata}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                          </ButtonBank>
                        )
                      })}
                      {bankDebit?.bankId && (
                        <Box className="d-flex align-items-start mt-3 pr-md-2 pr-xl-3">
                          <Box className={`d-flex w-100 `} sx={{ paddingLeft: '2px' }}>
                            <>
                              <div>
                                <Box className="d-flex align-items-start align-items-md-center w-100" sx={{ marginLeft: '-16px' }}>
                                  <img src={`${fileUrl}/${bankDebit.bankAvata}`} className="mt-1 mt-md-0" style={{ borderRadius: '4px', width: 30, height: 30 }} onError={onImgError} alt="" />
                                  <Box>
                                    <Typography className="d-inline-block ml-2">{bankDebit.bankName}</Typography>
                                  </Box>
                                </Box>
                                <Box className="d-flex align-items-start align-items-md-center w-100">
                                  <Box>
                                    <TypographyBankNumber className="d-md-inline-block ml-2 ml-md-5 mr-2">{t('PAYMENT.FORM_PAYMENT.TITLE_CREDIT')}</TypographyBankNumber>
                                    {_.map(bankDebit.debit, (card, index) => (
                                      <ButtonBank onClick={() => dispatch(setBankDebit({ ...bankDebit, ...card }))} className={`d-inline-block mr-2 ${bankDebit.cardTypeId === card.cardTypeId ? 'active' : ''}`}>
                                        <img src={`${fileUrl}/${card.cardTypeLogo}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                                      </ButtonBank>
                                    ))}
                                  </Box>
                                </Box>
                              </div>
                            </>
                            <UseStyled.CustomField>
                              <InputTextField
                                value={props.data.paidDebit || 0}
                                type='number'
                                // financeText
                                hideControl
                                onchange={(e) => props.onChange({ ...props.data, paidDebit: e.target.value })}
                                inputTextAlign="right"
                              />
                            </UseStyled.CustomField>
                          </Box>
                        </Box>
                      )}

                    </div>
                  </div>
                </>
              )}
              {selectChannels.key === 'VOUCHER' && (
                <div className="pt-3">
                  <UseStyled.TitlePayment>
                    {`${index + 1}. `}
                    {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}
                    ใช้ไปแล้ว: {numberToCurrencyFormat(props.data.usedVoucher)}
                  </UseStyled.TitlePayment>
                  <Box className="d-flex align-items-start mt-3 pr-md-2 pr-xl-3">
                    <Box className={`d-flex w-100 `} sx={{ paddingLeft: '2px' }}>
                      <><Typography className="pr-2" sx={{ fontWeight: 500 }}>
                        {t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.VOUCHER')}
                      </Typography>
                      </>
                      <UseStyled.CustomField>
                        <InputTextField
                          value={props.data.paidVoucher || 0}
                          type='number'
                          financeText
                          hideControl
                          onchange={(e) => props.onChange({ ...props.data, paidVoucher: e.target.value })}
                          inputTextAlign="right"
                        />
                      </UseStyled.CustomField>
                    </Box>
                  </Box>
                </div>
              )}
            </>
          )
        })}
      </Box>
    </Box>
  )
}
