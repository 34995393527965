import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { Card, Form } from 'react-bootstrap'
import { Box, Grid, Typography, } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** UTILS || SLICE */

/** API */
import PromotionApi, { PromotionType, ObjImage } from 'api/application/promotion.api'
import { fileUrl } from 'api/api'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTimePicker from 'component/Input/InputTimePicker'
import UploadImage from 'component/Upload/UploadImage'
import InputTextEditor from 'component/Input/InputTextEditor'
import { notiSuccess, notiError } from 'component/notifications/notifications'

/** STYLE */
import * as UseStyled from 'features/application/promotions/useStyled'
import { colors } from 'constants/theme'

type ErrorMessage = {
  EMPTY_SUBJECT?: string
  EMPTY_DATE_PUBLIC?: string
  EMPTY_TIME_PUBLIC?: string
  EMPTY_TIME_END?: string
  EMPTY_IMAGE?: string
  EMPTY_CONTENT?: string
}

export default function FormPromotion() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const maxHeight = 450
  const maxWidth = 700

  const objImage: ObjImage = {
    fileId: null,
    base64: '',
    type: '',
    fileExtension: '',
    fileSize: ''
  }

  const [promotionId, setPromotionId] = useState<number>(0);
  const [promotion, setPromotion] = useState<PromotionType>(
    {
      subject: '',
      content: '',
      datePublic: '',
      timePublic: '',
      dateEnd: '',
      timeEnd: '',
      image: objImage
    })
  const [previewImage, setPreviewImage]: any = useState()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});
  const [isDeleteImage, setIsDeleteImage] = useState(false);




  const loadData = async (id: number) => {
    await PromotionApi.findById(id).then((resp) => {
      if (!_.isEmpty(resp.data)) {
        const data: any = resp.data
        setPromotion({
          subject: data.subject,
          content: data.content,
          datePublic: data.datePublic,
          timePublic: data.timePublic,
          dateEnd: data.dateEnd,
          timeEnd: data.timeEnd,
          status: data.status,
          image: {
            fileId: data?.image?.fileId || 0,
            base64: data?.image?.base64 || '',
            type: data?.image?.type || '',
            fileExtension: data?.image?.fileExtension || '',
            fileSize: data?.image?.fileSize || ''
          }
        })
        setPreviewImage(`${fileUrl}/${data?.image.filePath}/${data?.image.fileName}`)
      }
    }).catch((e) => {
      return
    })
  }


  const submit = () => {
    if (!promotion.subject || !promotion.datePublic || promotion.datePublic === '0000-00-00' || !promotion.timePublic || promotion.timePublic === '00:00:00' || (promotion.dateEnd && (!promotion.timeEnd || promotion.timeEnd === '00:00:00'))) {
      return setErrorMessage({
        EMPTY_SUBJECT: !promotion.subject ? t('PROMOTIONS.MESSAGE.ERROR.EMPTY_SUBJECT') : '',
        EMPTY_DATE_PUBLIC: !promotion.datePublic || promotion.datePublic === '0000-00-00' ? t('PROMOTIONS.MESSAGE.ERROR.EMPTY_DATE_PUBLIC') : '',
        EMPTY_TIME_PUBLIC: !promotion.timePublic || promotion.timePublic === '00:00:00' ? t('PROMOTIONS.MESSAGE.ERROR.EMPTY_TIME_PUBLIC') : '',
        EMPTY_TIME_END: (promotion.dateEnd && (!promotion.timeEnd || promotion.timeEnd === '00:00:00')) ? t('PROMOTIONS.MESSAGE.ERROR.EMPTY_TIME_END') : ''
      });
    } else if ((!isDeleteImage && promotionId && !promotion.image.fileId) || (isDeleteImage && promotionId && !promotion.image.base64) || (!promotionId && !promotion.image.base64)) {
      return notiError(t('PROMOTIONS.MESSAGE.ERROR.EMPTY_IMAGE'));
    } else if (!promotion.content || promotion.content === `<p></p>\n` || promotion.content === `<p>&nbsp;</p>\n`) {
      return notiError(t('PROMOTIONS.MESSAGE.ERROR.EMPTY_CONTENT'));
    } else {
      promotionId ? update() : create();
    }
  };

  useEffect(() => {
    if (promotion.image.base64) {
      setPreviewImage(`data:image/jpeg;base64,${promotion.image.base64}`);
    }
  }, [promotion.image.base64]);

  const create = async () => {
    await PromotionApi.createPromotion(promotion).then((e) => {
      notiSuccess(t('PROMOTIONS.MESSAGE.SUCCESS.CREATE'))
      history.push(routeName.promotions)
    }).catch((e) => {
      notiError(t('PROMOTIONS.MESSAGE.ERROR.DEFAULT'))
    })
  }

  const update = async () => {
    try {
      if (isDeleteImage || promotion.image.base64) {
        await uploadPromotionImage(promotion.image);
      }

      console.log(promotion)
      await PromotionApi.updatePromotion(promotionId, promotion);
      notiSuccess(t('PROMOTIONS.MESSAGE.SUCCESS.UPDATE'));
      history.push(routeName.promotions);
    } catch (e) {
      notiError(t('PROMOTIONS.MESSAGE.ERROR.DEFAULT'));
    }
  };


  const uploadPromotionImage = (files: any) => {
    if (files && files[0]) {
      const rawFile = files[0];
      const fileName = rawFile.name.split('.');

      const file: any = new window.Image();
      file.src = window.URL.createObjectURL(rawFile);

      file.onload = () => {
        if (file.width > maxWidth || file.height > maxHeight) {
          return notiError(t('PROMOTIONS.FORM.IMAGE_SIZE'));
        } else {
          const reader: any = new FileReader();
          reader.onload = () => {
            const base64 = reader.result.split(',');
            const base64String = base64.length > 0 ? base64[base64.length - 1] : reader.result;
            setPromotion(prev => {
              setIsDeleteImage(true);
              const fileId = prev.image?.fileId && prev.image.fileId > 0
                ? 0
                : prev.image?.fileId || 0;

              const detailImage = {
                fileId: fileId,
                base64: base64String,
                type: '',
                fileExtension: fileName[fileName.length - 1],
                fileSize: rawFile.size.toString(),
              };

              return {
                ...prev,
                image: detailImage,
              };
            });


            setPreviewImage(reader.result);
          };

          reader.readAsDataURL(rawFile);
        }
      };
    }
  };


  const clearErrorMessage = () => {
    setErrorMessage({})
  }

  useEffect(() => {
    const valuePromotionId: any = location.state
    if (!_.isEmpty(valuePromotionId) && valuePromotionId.promotionId) {
      loadData(valuePromotionId.promotionId)
      setPromotionId(valuePromotionId.promotionId)
    }
  }, [location])

  return (
    <div className={`container-card overflow-auto`}>
      <div className="d-flex flex-column">
        <HeaderLabel
          text={`${promotionId ? t('PROMOTIONS.FORM.FORM_EDIT') : t('PROMOTIONS.FORM.FORM_ADD')}`}
          navigator={{ previousTo: routeName.promotions, previousTitle: t('PROMOTIONS.HEADER'), currentTitle: promotionId ? t('PROMOTIONS.FORM.FORM_EDIT') : t('PROMOTIONS.FORM.FORM_ADD') }}
          goBack={routeName.promotions}
          onSubmit={submit}
        />
        <Card className="border shadow-sm mx-4 mb-4 h-auto card">
          <Card.Body className="h-100 p-3">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} className='w-100'>
                <InputTextField
                  required
                  label={t('PROMOTIONS.FORM.TITLE')}
                  value={promotion.subject}
                  onchange={(e: any) => {
                    setPromotion({ ...promotion, subject: e.target.value })
                    clearErrorMessage()
                  }}
                  helperText={errorMessage.EMPTY_SUBJECT || ''}
                />
              </Grid>
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('PROMOTIONS.FORM.TITLE_DATE')}</Typography>
              </Grid>
              <Grid item className='w-100'>
                <Grid container spacing={2}>
                  <Grid item xs={7} md={5} lg={4} className='w-100'>
                    <InputSecondNewDatePicker
                      required
                      label={t('PROMOTIONS.FORM.DATE_START')}
                      value={promotion.datePublic && promotion.datePublic !== '0000-00-00' ? promotion.datePublic : ''}
                      onchange={(e: any) => {
                        setPromotion({ ...promotion, datePublic: moment(e).format('YYYY-MM-DD') })
                        clearErrorMessage()
                      }}
                      helperText={errorMessage.EMPTY_DATE_PUBLIC || ''}
                    />
                  </Grid>
                  <Grid item xs={5} md={3} lg={3} className='w-100'>
                    <InputTimePicker
                      required
                      key={'timePublic'}
                      label={t('PROMOTIONS.FORM.TIME_START')}
                      value={promotion.timePublic && promotion.timePublic !== '00:00:00' ? promotion.timePublic : ''}
                      helperText={errorMessage.EMPTY_TIME_PUBLIC || ''}
                      onChange={(e: any) => {
                        setPromotion({ ...promotion, timePublic: moment(e).format('HH:mm') })
                        clearErrorMessage()
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className='w-100'>
                <Grid container spacing={2}>
                  <Grid item xs={7} md={5} lg={4} className='w-100'>
                    <InputSecondNewDatePicker
                      allowClear
                      label={t('PROMOTIONS.FORM.DATE_END')}
                      value={promotion.dateEnd && promotion.dateEnd !== '0000-00-00' ? promotion.dateEnd : ''}
                      disablePast
                      datePublic={promotion.datePublic && promotion.datePublic !== '0000-00-00' ? promotion.datePublic : ''}
                      onchange={(e: any) => {
                        setPromotion({ ...promotion, dateEnd: moment(e).format('YYYY-MM-DD') })
                        clearErrorMessage()
                      }}
                      onClear={() => {
                        setPromotion({ ...promotion, dateEnd: '', timeEnd: '' })
                        clearErrorMessage()
                      }}
                    />
                  </Grid>
                  <Grid item xs={5} md={3} lg={3} className='w-100'>
                    <InputTimePicker
                      key={'timeEnd'}
                      label={t('PROMOTIONS.FORM.TIME_END')}
                      value={promotion.timeEnd && promotion.timeEnd !== '00:00:00' ? promotion.timeEnd : ''}
                      onChange={(e: any) => {
                        setPromotion({ ...promotion, timeEnd: moment(e).format('HH:mm') })
                        clearErrorMessage()
                      }}
                      helperText={errorMessage.EMPTY_TIME_END || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {promotionId && (
                <Grid item className='w-100'>
                  <Box className='d-flex align-items-center justify-content-between justify-content-sm-start mt-2'>
                    <Typography className='mr-3' sx={{ fontSize: 18, fontWeight: 500 }}>{t('PROMOTIONS.FORM.STATUS')}</Typography>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      onChange={() => setPromotion({ ...promotion, status: promotion.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
                      checked={promotion.status === 'ACTIVE' ? true : false}
                    />
                  </Box>
                </Grid>
              ) || <></>}
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('PROMOTIONS.FORM.IMAGE')}</Typography>
              </Grid>
              <Grid item className='w-100'>
                <UseStyled.UploadImage>
                  <UploadImage
                    setValue={uploadPromotionImage}
                    pathFile={previewImage}
                    canDelete
                    onDelete={() => {
                      setPromotion(prev => ({
                        ...prev,
                        image: objImage
                      }));
                      setIsDeleteImage(true);
                      setPreviewImage(null);
                    }}
                  />
                </UseStyled.UploadImage>
                <Typography className='mt-1' sx={{ fontSize: 16, color: colors.disabledGray }}>({t('PROMOTIONS.FORM.IMAGE_SIZE')})</Typography>
              </Grid>
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('PROMOTIONS.FORM.CONTENT')}</Typography>
              </Grid>
              <Grid item className='w-100'>
                {(promotionId && promotion.content) && (
                  <InputTextEditor
                    value={promotion.content}
                    setValue={(val: string) => setPromotion({ ...promotion, content: val })}
                  />
                ) || <></>}
                {(!promotionId) && (
                  <InputTextEditor
                    setValue={(val: string) => setPromotion({ ...promotion, content: val })}
                  />
                ) || <></>}
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
