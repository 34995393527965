import { useEffect, useState } from 'react'
import { Box, Drawer, Button } from '@mui/material';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import CollapseContainer from 'component/Collapse/container'
import TreatmentServicePoint from 'features/treatments/Queue/TreatmentServicePoint'
import SummaryBox, { SummaryBoxType } from 'features/treatments/Queue/SummaryBox'
import SelectDate from 'component/Treatments/SelectDate'
import TreatmentAppointment from 'features/treatments/Queue/TreatmentAppointment'

import * as UseStyled from 'features/treatments/Queue/Style/StyleTreatmentsQueue'
import AboutUs from 'api/admin/clinic-manage/about.api';
import { getBranch } from 'utils/app.utils';
import { useDispatch } from 'react-redux';
import { editBranchInfo } from 'app/slice/user.slice';
import { KEY_STORAGE } from 'constants/common';
import moment from 'moment';


export default function TreatmentsQueue() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showSummary, setShowSummary] = useState(false)
  const [date, setDate] = useState('')
  const [countService, setCountService] = useState<SummaryBoxType>({})

  const branchId = getBranch()
  const [branch, setBranch] = useState<any>()

  const loadBranchInfo = () => {
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        setBranch(data)
        dispatch(editBranchInfo({ value: { data } }))
        localStorage.setItem(KEY_STORAGE.CLINIC_INFO, JSON.stringify({ ...data, lastUpdate: moment().format('YYYY-MM-DD HH:mm') }))
      })
      .catch((e) => { return })
  }

  useEffect(() => {
    loadBranchInfo()
  }, [branchId])

  return (
    <div className={'h-100 overflow-hidden'}>
      <UseStyled.TreatmentQueueLayout className={'h-100'}>
        <CollapseContainer
          styleComponentLeft={{ height: '100%' }}
          styleComponentRight={{ height: '100%' }}
          componentLeft={
            <UseStyled.FragmentLeft className="h-100 p-3">
              <TreatmentServicePoint branch={branch} setCountServicePoint={(count: number) => setCountService({ ...countService, queue: count })} />
            </UseStyled.FragmentLeft>
          }
          componentRight={
            <UseStyled.FragmentRight className="h-100 overflow-hidden">
              <UseStyled.Header className={`position-relative d-flex align-items-center px-3 px-xs-4`}>
                <UseStyled.SelectDate>
                  <SelectDate setDate={(valueDate: string) => setDate(valueDate)} />
                </UseStyled.SelectDate>
                <Button onClick={() => setShowSummary(true)} className={`btn-summary ml-auto d-xl-none`}>
                  {t('TREATMENT_QUEUE.BUTTON.SUMMARY')}
                </Button>
              </UseStyled.Header>

              <Box className="d-flex align-items-center overflow-hidden" style={{ height: 'calc(100% - 70px)' }}>
                <Box className="w-100 h-100 overflow-hidden py-4" style={{ flex: 1 }}>
                  <TreatmentAppointment branch={branch} date={date} setCountAppointment={(count: number) => setCountService({ ...countService, appointment: count })} />
                </Box>
                <SummaryBox queue={countService.queue} appointment={countService.appointment} date={date} className="d-none d-xl-block" />
                <Drawer anchor="right" open={showSummary} onClose={() => setShowSummary(false)} className="summary-layout-drawer d-xl-none">
                  <SummaryBox queue={countService.queue} appointment={countService.appointment} date={date} onClose={() => setShowSummary(false)} />
                </Drawer>
              </Box>
            </UseStyled.FragmentRight>
          }
        />
      </UseStyled.TreatmentQueueLayout>
      {/* <ModalCustomNotification /> */}
    </div>
  )
}
