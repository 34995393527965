import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Box, Typography, Tabs, Tab, MenuItem, Divider } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { showModal } from 'app/slice/modal.slice'
import { selectMe } from 'app/slice/user.slice';

/** API */
import TreatmentsAPI from 'api/dentists/treatment.api'
import { fileUrl } from 'api/api'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs } from 'constants/images';
import { checkPackage } from 'utils/app.utils'
import { PACKAGE_ADDONS } from 'constants/package'

/** COMPONENT */
import SearchPatients from 'component/SearchPatients'
import HeaderCard from 'component/CardCustom/HeaderCard'
/** Treatments */
import IndexOPD from 'features/treatments/MainMenu/OPD/Index'
import IndexDoctorNote from 'features/treatments/MainMenu/DoctorNote/Index'
import IndexImage from 'features/treatments/MainMenu/Image/Index'
/** End Treatments */
import BtnProfile from 'component/Treatments/BtnProfile'
import PopupPatientDetail from 'component/Treatments/PopupPatientDetail'
import AppointmentHistory from 'features/historyTreatments/MainMenu/AppointmentHistory/Index'
import DocumentHistory from 'features/historyTreatments/MainMenu/DocumentHistory'
import { TABS_NAME, SUB_TABS_NAME } from 'features/treatments/treatmentsIndex'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

/** STYLE */
import * as UseStyled from 'features/counter/retrospective-edit/UseStyle'
import * as TreatmentsUseStyled from 'features/treatments/UseStyle'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
  name: string
}

export default function RetrospectiveEdit() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectMe)
  const location = useLocation()
  const history = useHistory()

  const [tabs, setTabs] = useState<TABS_NAME[]>([TABS_NAME.OPD, TABS_NAME.DOCTOR_NOTE, TABS_NAME.IMAGE, TABS_NAME.APPOINTMENT, TABS_NAME.DOCUMENT])
  const [subTabs, setSubTabs] = useState<SUB_TABS_NAME[]>([SUB_TABS_NAME.ALL])
  const [activeTab, setActiveTab] = useState<TABS_NAME>(TABS_NAME.OPD)
  const [activeSubTab, setActiveSubTab] = useState<SUB_TABS_NAME>(SUB_TABS_NAME.ALL)

  const [isShowPopupDetail, setIsShowPopupDetail] = useState<boolean>(false)

  const [anchorElTabletTabs, setAnchorElTabletTabs] = useState<null | HTMLElement>(null)
  const openTabletTabs = Boolean(anchorElTabletTabs)
  const [tabletWidth, setTabletWidth] = useState(0)

  const [anchorElMobileTabs, setAnchorElMobileTabs] = useState<null | HTMLElement>(null)
  const openMobileTabs = Boolean(anchorElMobileTabs)
  const [mobileWidth, setMobileWidth] = useState(0)
  const [patient, setPatient]: any = useState({})

  useEffect(() => {

    const addonDoctorNote: TABS_NAME[] = checkPackage(PACKAGE_ADDONS.ADDON_DOCTOR_NOTE) ? [TABS_NAME.DOCTOR_NOTE] : []
    setTabs([TABS_NAME.OPD, ...addonDoctorNote, TABS_NAME.IMAGE, TABS_NAME.APPOINTMENT, TABS_NAME.DOCUMENT])

    const addonTxP = checkPackage(PACKAGE_ADDONS.ADDON_TREATMENT_PLAN_MANAGE) ? [SUB_TABS_NAME.TXP] : []
    const addonTxC = checkPackage(PACKAGE_ADDONS.ADDON_TREATMENT_COURSE_MANAGE) ? [SUB_TABS_NAME.TXC] : []
    const addonXray = checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) ? [SUB_TABS_NAME.XRAY] : []
    const addonLab = checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? [SUB_TABS_NAME.LAB] : []
    setSubTabs([SUB_TABS_NAME.ALL, ...addonTxP, ...addonTxC, ...addonXray, ...addonLab])
  }, [])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(tabs[newValue])
  }

  const handleOpenDropDown = () => {
    const dropdownTabletWidth = Array.from(document.getElementsByClassName('btn-dropdown-menu-tablet-tab') as HTMLCollectionOf<HTMLElement>)
    const dropdownMobileWidth = Array.from(document.getElementsByClassName('btn-dropdown-menu-mobile-tab') as HTMLCollectionOf<HTMLElement>)
    setTabletWidth(Number(dropdownTabletWidth[0]?.offsetWidth))
    setMobileWidth(Number(dropdownMobileWidth[0]?.offsetWidth))
  }

  const onCloseMenu = () => {
    setAnchorElMobileTabs(null)
    setAnchorElTabletTabs(null)
  }

  const getPatientId = (id: number) => {
    loadPatient(id)
  }

  const handleClear = () => {
    setPatient({})
    setActiveTab(tabs[0])
    setActiveSubTab(subTabs[0])
  }

  const loadPatient = async (patientId: number) => {
    if (patientId) {
      const patientData = await TreatmentsAPI.findPatient(patientId)
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
        setPatient(patientData.data)
        const currentPath = window.location.pathname;
        history.replace(currentPath, { patientId: patientId });
      } else setPatient([])
    }
  }

  const onViewHistory = () => {
    dispatch(showModal())
    setIsShowPopupDetail(true)
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  useEffect(() => {
    const locationState: any = location.state
    if (!_.isEmpty(locationState)) loadPatient(locationState.patientId)
  }, [location.pathname])

  return (
    <div>
      <HeaderCard text={t('RETROSPECTIVE_EDIT.HEADER')} />
      <Box className={`px-3 px-md-4 pt-3 pt-md-4 pb-4 ${activeTab === TABS_NAME.OPD || activeTab === TABS_NAME.IMAGE ? 'h-100' : ''}`}>
        <UseStyled.LayoutHistoryTreatment className={`${activeTab === TABS_NAME.OPD || activeTab === TABS_NAME.IMAGE ? 'h-100 d-flex flex-column' : ''} ${activeTab === TABS_NAME.IMAGE ? 'overflow-hidden' : ''}`}>
          <Grid container spacing={2} className="mb-3">
            <Grid item className='w-100'>
              <Box sx={{ maxWidth: { sm: '650px' } }}>
                <SearchPatients status="ACTIVE" callbackPatientId={getPatientId} patientId={patient.patientId} patient={patient} onClear={handleClear} />
              </Box>
            </Grid>
            <Grid item className='w-100'>
              <TreatmentsUseStyled.CardProfile className={`d-flex px-3 ${!patient.patientId ? 'pe-none' : ''}`} onClick={onViewHistory}>
                <BtnProfile disabled={!patient.patientId} profile={renderImage(patient.profile)} branchCnNumber={patient.branchCnNumber} firstName={patient.firstname} lastName={patient.lastname} rankId={patient.rankId} lastVisit={patient.updatedAt} />
              </TreatmentsUseStyled.CardProfile>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item className='w-100'>
              <UseStyled.CustomTabs disabled={!patient.patientId}>
                <Tabs value={activeTab} onChange={handleChangeTab} aria-label="treatment tab" className="d-none d-md-block">
                  {_.map(tabs, (title: string, index: number) => {
                    return (
                      (index === 0 && (
                        <>
                          <TreatmentsUseStyled.CustomButton
                            key={`tabChild-${index}`}
                            aria-haspopup="true"
                            aria-expanded={openTabletTabs ? 'true' : undefined}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              handleChangeTab(event, 0)
                              setAnchorElTabletTabs(event.currentTarget)
                              handleOpenDropDown()
                            }}
                            className={`btn-tab btn-dropdown-menu-tablet-tab ${activeTab === TABS_NAME.OPD ? 'Mui-selected' : ''}`}
                            disableRipple
                            disabled={!patient.patientId}
                            {...a11yProps(index)}>
                            <span>{t(title)}</span>
                            <span className={'d-xl-none pl-1'}>({activeSubTab})</span>
                            {_.size(subTabs) > 1 && <FontAwesomeIcon icon={faCaretDown} className={'icon-selector d-xl-none'} />}
                          </TreatmentsUseStyled.CustomButton>
                          {_.size(subTabs) > 1 && (
                            <TreatmentsUseStyled.MenuBtnMore
                              id="basic-menu-more"
                              anchorEl={anchorElTabletTabs}
                              open={openTabletTabs}
                              onClose={onCloseMenu}
                              onClick={onCloseMenu}
                              className="d-xl-none"
                              sx={{
                                '.MuiMenu-list': {
                                  width: tabletWidth,
                                  maxHeight: '270px'
                                }
                              }}>
                              {_.map(subTabs, (subTab: SUB_TABS_NAME, indexSubTab: number) => {
                                return (
                                  <MenuItem
                                    key={indexSubTab}
                                    className={'d-block text-ellipsis'}
                                    onClick={() => {
                                      setActiveTab(tabs[0])
                                      setActiveSubTab(subTab)
                                    }}
                                  >
                                    {subTab}
                                  </MenuItem>
                                )
                              })}
                            </TreatmentsUseStyled.MenuBtnMore>
                          )}
                        </>
                      )) || <Tab key={`tab-${index}`} label={<span className='text-ellipsis w-100'>{t(title)}</span>} {...a11yProps(index)} className={`btn-tab ${activeTab === title ? 'Mui-selected' : ''}`} disableRipple />
                    )
                  })}
                </Tabs>
              </UseStyled.CustomTabs>

              <UseStyled.CustomTabs disabled={!patient.patientId} className="d-md-none">
                <TreatmentsUseStyled.CustomButton
                  id="mobile-tabs"
                  aria-controls={openMobileTabs ? 'basic-menu-more' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMobileTabs ? 'true' : undefined}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorElMobileTabs(event.currentTarget)
                    handleOpenDropDown()
                  }}
                  className={'btn-tab btn-dropdown-menu-mobile-tab'}
                  disableRipple>
                  {activeTab === TABS_NAME.OPD ? `OPD (${activeSubTab})` : t(activeTab)}
                  <FontAwesomeIcon icon={faCaretDown} className={'icon-selector'} />
                </TreatmentsUseStyled.CustomButton>

                <TreatmentsUseStyled.MenuBtnMore
                  id="basic-menu-more"
                  anchorEl={anchorElMobileTabs}
                  open={openMobileTabs}
                  onClose={onCloseMenu}
                  onClick={onCloseMenu}
                  className="d-xl-none"
                  sx={{
                    '.MuiMenu-list': {
                      width: mobileWidth,
                      maxHeight: '270px'
                    }
                  }}>
                  <Typography className="pb-1 px-3" sx={{ fontWeight: 600 }}>
                    {t(tabs[0])}
                  </Typography>
                  {_.map(subTabs, (subTab: SUB_TABS_NAME, indexSubTab: number) => {
                    return (
                      <MenuItem
                        key={indexSubTab}
                        className={'d-block text-ellipsis'}
                        onClick={() => {
                          setActiveTab(tabs[0])
                          setActiveSubTab(subTab)
                        }}>
                        {subTab}
                      </MenuItem>
                    )
                  })}
                  <Divider className="mx-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                  {_.map(tabs, (title: string, indexTitle: number) => {
                    if (indexTitle > 0)
                      return (
                        <MenuItem key={indexTitle} className={'d-block text-ellipsis'} onClick={() => setActiveTab(tabs[indexTitle])}>
                          {t(title)}
                        </MenuItem>
                      )
                  })}
                </TreatmentsUseStyled.MenuBtnMore>
              </UseStyled.CustomTabs>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={`${activeTab === TABS_NAME.OPD || activeTab === TABS_NAME.IMAGE ? 'h-100' : ''} ${activeTab === TABS_NAME.IMAGE ? 'overflow-hidden flex-column' : ''}`}>
            <Grid
              item
              className={`w-100 mt-3 ${activeTab === TABS_NAME.IMAGE ? 'overflow-hidden' : ''}`}
              sx={{
                '& > div': {
                  height: '100%',
                  '& > div': {
                    height: '100%',
                    '& > div': {
                      height: '100%'
                    }
                  }
                }
              }}>
              <TabPanel value={activeTab} index={TABS_NAME.OPD} name="opd">
                <IndexOPD
                  type='RetrospectiveEdit'
                  disabledMenu={!patient.patientId}
                  userId={user.userId}
                  patient={patient}
                  tabs={subTabs}
                  activeTab={activeSubTab}
                  setActiveTab={(tab: SUB_TABS_NAME) => setActiveSubTab(tab)}
                  setGotoPage={(tab: string) => {
                    if (tab === 'Xray') setActiveSubTab(SUB_TABS_NAME.XRAY);
                    if (tab === 'Lab') setActiveSubTab(SUB_TABS_NAME.LAB);
                  }}
                  hasChangeProductPrice={'UNPERMISS'}
                  hasChangeProductDiscountAdditional={'UNPERMISS'} hasCreateTreatment={'UNPERMISS'} hasCreateTreatmentPlan={'UNPERMISS'} hasCreateTreatmentCourse={'UNPERMISS'} />
              </TabPanel>
              <TabPanel value={activeTab} index={TABS_NAME.DOCTOR_NOTE} name="doctor_note">
                <IndexDoctorNote userId={user.userId} patient={patient} hasDoctorNote={user?.hasDoctorNote} isHistoryTreatment hasCreateDoctorNote={'UNPERMISS'} />
              </TabPanel>
              <TabPanel value={activeTab} index={TABS_NAME.IMAGE} name="image">
                <IndexImage patient={patient} />
              </TabPanel>
              <TabPanel value={activeTab} index={TABS_NAME.APPOINTMENT} name="appointment_history">
                <AppointmentHistory patientId={patient.patientId} />
              </TabPanel>
              <TabPanel value={activeTab} index={TABS_NAME.DOCUMENT} name="document">
                <DocumentHistory patientId={patient.patientId} />
              </TabPanel>
            </Grid>
          </Grid>
          <PopupPatientDetail type='historyTreatment' isNotShownPhone={true} profile={renderImage(patient.profile)} patient={patient} showPopup={isShowPopupDetail} onClose={() => setIsShowPopupDetail(false)} />
        </UseStyled.LayoutHistoryTreatment>
      </Box>
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `history-treatment-tab-${index}`,
    'aria-controls': `history-treatment-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role={`tab`} hidden={value !== index} id={`history-treatment-tab-${index}`} aria-labelledby={`history-treatment-tabpanel-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
