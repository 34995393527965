import { Box, Tooltip } from '@mui/material'
import { swalActive, swalCustom } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import ButtonExport from 'component/Button/ButtonExport'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import Loading from 'component/Loading'
import TableRowExport from 'component/Pdf/TableRowExport'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toBuddhistYear } from 'utils/app.utils'
import { isCreate, isDelete, isView, isUpdate, isCopy } from 'utils/verification.utils'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from 'constants/theme'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { routeName } from 'routes/routes-name'
import PromotionsApi from 'api/promotion/promotion.api'
import { showModal } from 'app/slice/modal.slice'
import PopViewPromotion from './pop-view-promotion'
import { notiSuccess } from 'component/notifications/notifications'
import MainButton from 'new-components/buttons/main-button'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView(),
    isCopy: isCopy(),
}

const Promotions = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [load, setLoad] = useState<boolean>(false)
    const componentExportRef = useRef<HTMLDivElement>(null)

    const [dataPromotion, setDataPromotion] = useState<any>([])
    const [dataPromotionExport, setDataPromotionExport] = useState<any>([])

    const styleSelector = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }
    const statusOption = [
        { status: 'WAITING' }, { status: 'USING' }, { status: "EXPIRED" },
    ]
    const [statusSelect, setStatusSelect] = useState<string>('')
    const [branches, setBranches] = useState<BranchInterface[]>([])

    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createdAt')
    const [sortType, setSortType] = useState('DESC')
    const [search, setSearch] = useState('')

    const [popViewPromotion, setPopViewPromotion] = useState<boolean>(false)
    const [dataPopViewPromotion, setDataPopViewPromotion] = useState<any>({})

    const loadFilterOption = async () => {
        await ClinicApi.findAllActiveBranches().then(({ data }) => data && setBranches(data))
    }

    const loadPromotion = async (checkExport?: boolean) => {
        setLoad(true)
        const condition = {
            page: page,
            pageLimit: pageLimit,
            SEARCH: search,
            sortBy: sortBy,
            sortType: sortType,
            STATUS: statusSelect,
            EXPORT: checkExport ? '1' : '0'
        }

        await PromotionsApi.findAll(condition).then((res) => {
            if (checkExport) {
                setDataPromotionExport(res?.data)
            } else {
                setRowCount(res?.headers['x-total'] || 0)
                setDataPromotion(res?.data)
            }
        }).finally(() => {
            setLoad(false)
        })
        setLoad(false)
    }

    useEffect(() => {
        loadPromotion(true)
        loadFilterOption()
    }, [])

    useEffect(() => {
        loadPromotion()
    }, [page, pageLimit])

    const onCreate = () => {
        history.push(`${routeName.promotion}/create`)
    }

    const onEdit = (obj: any) => {
        history.push(`${routeName.promotion}/update`, { ...obj })
    }

    const onView = (obj: any) => {
        setPopViewPromotion(true)
        dispatch(showModal())
        setDataPopViewPromotion(obj)
    }

    const onClosePopViewPromotion = () => {
        setPopViewPromotion(false)
    }

    const onCopy = (obj: any) => {
        history.push(`${routeName.promotion}/create`, { ...obj, copy: true })
    }

    const onDelete = async (data: any) => {
        const { promotionNameEn, promotionName, promotionId } = data

        swalCustom(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PROMOTION.MESSAGE.DEL_TITLE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PROMOTION.MESSAGE.DEL_SUB', { promotionName: promotionName, promotionNameEn: promotionNameEn })}</p>`,
            (res: any) => {
                if (res && promotionId) {
                    setLoad(true)
                    PromotionsApi.deletePromotion(promotionId).then((res) => {
                        notiSuccess(t('PROMOTION.MESSAGE.DEL_SUCCESS'))
                        loadPromotion()
                        loadPromotion(true)
                    }).finally(() => {
                        setLoad(false)
                    })
                }
            }, 'warning', { showCloseButton: false, containerClass: 'promotion-del', confirmButton: { confirmClassName: 'promotion-del-con' } }
        )

    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => setPage(val)

    const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '50px', align: 'center', },
        { id: 'promotionTypeName', disablePadding: false, label: t('PROMOTION.TABLE.PROMOTION_TYPENAME'), align: 'left', class: 'text-nowrap ' },
        { id: 'promotionCode', disablePadding: false, label: t('PROMOTION.TABLE.PROMOTION_CODE'), align: 'center', class: 'text-nowrap' },
        { id: 'promotionName', disablePadding: false, label: t('PROMOTION.TABLE.PROMOTION_NAME'), align: 'left', class: 'text-nowrap col-pro-name' },
        { id: 'promotionNameEn', disablePadding: false, label: t('PROMOTION.TABLE.PROMOTION_NAMEEN'), align: 'left', class: 'text-nowrap' },
        { id: 'promotionFormat', disablePadding: false, label: t('PROMOTION.TABLE.TYPE'), align: 'center', class: 'text-nowrap' },
        { id: 'branch', disablePadding: false, label: t('PROMOTION.TABLE.USAGE_BRANCH'), align: 'left', class: 'text-nowrap' },
        { id: 'count', disablePadding: false, label: t('PROMOTION.TABLE.COUNT'), align: 'center', class: 'text-nowrap' },
        { id: 'dateStart', disablePadding: false, label: t('PROMOTION.TABLE.USAGE_DATE'), align: 'center', class: 'text-nowrap' },
        { id: 'status', disablePadding: false, label: t('APPLICATION.TABLE.CELL.STATUS'), align: 'center', width: '150px' },
        { id: 'updatedBy', disablePadding: false, label: t('APPLICATION.TABLE.CELL.UPDATED_BY'), align: 'center', width: '180px' },
        { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
    ]

    const renderData = (objData: any, index: number, isExport?: boolean) => {
        if (isExport) {
            index = index + 1
        } else {
            index = page * pageLimit - pageLimit + index + 1
        }

        const { promotionId, promotionCode, promotionName, promotionNameEn, promotionType,
            updatedBy, updatedAt, createdAt, status, branch, count, dateStart, dateEnd, amountPromotion, promotionBranchs, type, promotionDate } = objData

        const valueAction = (status === 'USING' || status === 'EXPIRED') ? [{ option: 'VIEW', label: t(`BUTTON.VIEW`), disabled: permissions.isView.disabled },
        { option: 'COPY', label: t(`BUTTON.COPY`), disabled: !permissions.isCopy.disabled }]
            :
            [{ option: 'VIEW', label: t(`BUTTON.VIEW`), disabled: permissions.isView.disabled },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'COPY', label: t(`BUTTON.COPY`), disabled: !permissions.isCopy.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }]

        const objRenderData = {
            key: promotionId,
            id: promotionId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index },
                { option: 'TEXT', align: 'left', label: promotionType?.promotionTypeName || '' },
                { option: 'TEXT', align: 'left', label: promotionCode || '' },
                { option: 'TEXT', align: 'left', label: promotionName || '' },
                { option: 'TEXT', align: 'left', label: promotionNameEn || '' },
                { option: 'TEXT', align: 'center', label: type ? t(`PROMOTION.TYPE.${type}`) : '' },
                {
                    option: 'COMPONENT',
                    component: (!isExport ? <BoxBranchFilter filterBranch={promotionBranchs?.length ? promotionBranchs : []} /> : (<Box>{promotionBranchs[0]?.branchName || ''}</Box>))
                },
                { option: 'TEXT', align: 'center', label: amountPromotion || '' },
                { option: 'TEXT', align: 'center', label: promotionDate || '', class: 'text-nowrap' },
                {
                    option: 'COMPONENT',
                    class: 'row-status',
                    component: (
                        <Box className={'text-nowrap'}>
                            {!isExport && <FontAwesomeIcon icon={faCircle} color={status === 'USING' ? colors.statusActive : status === 'EXPIRED' ? colors.statusInactive : '#EDBA07'} />}
                            <span className="ml-2">{t(`PROMOTION.STATUS.${status}`)}</span>
                        </Box>
                    )
                },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: valueAction
                },
            ]
        }

        if (isExport) {
            return <TableRowExport {...objRenderData} />
        }

        return (
            <TableRowCommon
                {...objRenderData}
                onedit={() => onEdit(objData)}
                ondelete={() => onDelete(objData)}
                onView={() => onView(objData)}
                oncopy={() => onCopy(objData)}
            />
        )
    }

    return (
        <>
            <Box>
                <Loading show={load} />
                <Card className="border-0 h-100">
                    <HeaderCard text={t('PROMOTION.TITLE')} />
                    <Card.Body className="overflow-auto">
                        <Card.Title className={'mb-0 pl-xl-3'}>
                            <Box className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                                <Box className="d-flex align-items-center gap-2" sx={{ width: { md: 'calc(100% - 350px)', xs: '100%' } }}>
                                    <Box sx={{ width: { md: '36%', xs: '100%' } }}>
                                        <InputTextField
                                            // label={t('PROMOTION_TYPE.SEARCH')}
                                            placeholder={t('PROMOTION.SEARCH_PLACEHOLDER')}
                                            value={search}
                                            onchange={(e) => {
                                                setSearch(e.target.value)
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: { md: '36%', xs: '100%' } }}>
                                        <AutocompleteSelect
                                            labelId="status"
                                            options={["ALL", ...statusOption]}
                                            getOptionLabel={(option) => `${t('APPROVELLIST.FILTER.STATUS')}: ${option.status ? t(`PROMOTION.STATUS.${option.status}`) : t('ALL')} `}
                                            renderOption={(props, option) => {
                                                return (
                                                    <ListFilterDoctors {...props} component="li" sx={styleSelector}>
                                                        {option === "ALL" ? t('ALL') : t(`PROMOTION.STATUS.${option.status}`)}
                                                    </ListFilterDoctors>
                                                )
                                            }}
                                            onchange={(e, value) => {
                                                setStatusSelect(value?.status || '')
                                            }}
                                            noOptionsText={t('NOT_FOUND')}
                                            filterOptions={(option) => t(`STATUS.${option?.status || ''}`)}
                                            value={_.find(statusOption, { status: statusSelect }) || ''}
                                            height={43}
                                            disableClearable={!statusSelect && statusSelect !== 'ALL'}
                                        />
                                    </Box>
                                    <Box sx={{ width: 'fit-content' }}>
                                        <MainButton
                                            style={{ width: '100px' }}
                                            onClick={() => {
                                                setPage(1)
                                                loadPromotion()
                                                loadPromotion(true)
                                            }}
                                            title={t('INPUT.SEARCH')}
                                            type={'primary'}
                                            variant={'contained'}
                                        />
                                    </Box>
                                </Box>
                                <Box className="d-flex align-items-center gap-2" sx={{ width: 'fit-content' }}>
                                    <Box>
                                        <ButtonExport
                                            headCells={headCells}
                                            portrait={false}
                                            filter={''}
                                            fileName={t('PROMOTION.TITLE')}
                                            rowsData={dataPromotionExport?.length ? dataPromotionExport?.map((item: any, index: number) => {
                                                return renderData(item, index, true)
                                            }) : []}
                                            marginPrint={'0.5cm'}
                                            componentExportRef={componentExportRef}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonCustom
                                            mode="add"
                                            disabled={permissions.isCreate.disabled}
                                            onClick={() => onCreate()}
                                            textButton={t('PROMOTION.BUTTON.CREATE_PROMOTION')}
                                            className="w-auto ml-auto d-flex mt-auto"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Card.Title>

                        <Box className={'mt-3 pl-xl-3 pr-xl-2'} sx={{ '.col-pro-name': { minWidth: '250px' } }}>
                            <TableCustom
                                page={page}
                                pageLimit={pageLimit}
                                sortType={sortType}
                                sortBy={sortBy}
                                onSort={onRequestSort}
                                setPageLimit={handleChangeRowsPerPage}
                                setPage={handleChangePage}
                                rowCount={rowCount}
                                headCells={headCells}
                                rowsData={dataPromotion?.length ? dataPromotion?.map((item: any, index: number) => {
                                    return renderData(item, index)
                                }) : []}
                            />
                        </Box>
                    </Card.Body>
                </Card>
            </Box>

            {popViewPromotion &&
                <PopViewPromotion
                    objData={dataPopViewPromotion}
                    onClose={onClosePopViewPromotion}
                />
            }

            <Box>
                <Box className={`print-show`} ref={componentExportRef}>
                    <Box fontSize={'10px'} fontWeight={'500'}>{t('รายการโปรโมชั่น')}</Box>
                    <Box fontSize={'10px'} fontWeight={'500'} display={'flex'} gap={'8px'} marginBottom={'8px'}>
                        <span>
                            {t('ค้นหา')}: {search || '-'}
                        </span>
                        <span>
                            {t('สถานะ')}: {_.find(statusOption, { status: statusSelect })?.status ? t(`PROMOTION.STATUS.${_.find(statusOption, { status: statusSelect })?.status}`) : '-'}
                        </span>
                    </Box>
                    <Box
                        width={'100%'}
                        marginBottom={'8px'}
                        sx={{
                            '.no-link .mx-auto': { display: 'flex', justifyContent: 'center', gap: '4px' },
                            '.row-status': { textAlign: 'center !important' },
                            'th,td': {
                                fontSize: '8px',
                                margin: '0',
                                padding: '1px',
                                borderBottom: '1px solid black',
                                borderTop: '1px solid black',
                                textAlign: 'center',
                                ':last-child': {
                                    borderRight: 0,
                                }
                            }
                        }}
                    >
                        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                            <thead>
                                {_.map(headCells.filter((headCell: any) => headCell.id !== 'action' && headCell.id !== 'COMPONENT'), (val: any, index: number) => {
                                    return (
                                        <th
                                            style={{
                                                textAlign: val.align ? val.align : 'left',
                                                fontSize: 8,
                                                fontWeight: 500
                                            }}>
                                            {val.label || t(val.labelKey)}
                                        </th>
                                    )
                                })}
                            </thead>
                            <tbody>
                                {dataPromotionExport?.length ? dataPromotionExport?.map((item: any, index: number) => {
                                    return renderData(item, index, true)
                                }) : []}
                                {!dataPromotionExport?.length && (
                                    <td colSpan={headCells.length}>
                                        {t('NOT_FOUND')}
                                    </td>
                                )}
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Promotions

type BoxBranchFilterProps = {
    filterBranch: BranchInterface[]
}

const BoxBranchFilter = (props: BoxBranchFilterProps) => {
    const { filterBranch } = props

    return (
        <Box sx={{
            '.dropdown-toggle': {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent !important',
                color: 'black !important',
                border: 'none',
                boxShadow: 'none !important',
                ":after": {
                    display: filterBranch?.length > 1 ? 'block' : 'none'
                },

            },
            '.dropdown-menu': {
                position: 'fixed !important',
                marginBottom: 4,
                background: colors.white,
                border: `1px solid ${colors.lightGray}`,
            }
        }}>
            <Dropdown>
                <Dropdown.Toggle>
                    <Box whiteSpace={'nowrap'} marginRight={'4px'}>
                        {filterBranch[0]?.branchName || ''}
                    </Box>
                </Dropdown.Toggle>
                {filterBranch?.length > 1 &&
                    <Dropdown.Menu>
                        {filterBranch.map((item, index) => {
                            return (
                                <Dropdown.Item key={index}>
                                    {item?.branchName}
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                }
            </Dropdown>
        </Box>
    )
}