import { menuThirds } from "constants/menus"
import FormPromotions from "features/manage-crm/promotion/form-promotion"
import Promotions from "features/manage-crm/promotion/promotion"
import PromotionType from "features/manage-crm/promotion/promotion-type"
import { routeName } from "routes/routes-name"

const routePromotion = [
    {
        ...menuThirds.PROMOTION_TYPE,
        key: menuThirds.PROMOTION_TYPE.key,
        name: menuThirds.PROMOTION_TYPE.name,
        textName: menuThirds.PROMOTION_TYPE.textName,
        active: false,
        appBar: true,
        path: routeName.promotionType,
        component: PromotionType
    },
    {
        ...menuThirds.PROMOTION,
        key: menuThirds.PROMOTION.key,
        name: menuThirds.PROMOTION.name,
        textName: menuThirds.PROMOTION.textName,
        active: false,
        appBar: true,
        component: Promotions
    },
    {
        ...menuThirds.PROMOTION,
        key: menuThirds.PROMOTION.key,
        name: menuThirds.PROMOTION.name,
        textName: menuThirds.PROMOTION.textName,
        active: false,
        appBar: true,
        path: routeName.promotion + '/create',
        component: FormPromotions
    },
    {
        ...menuThirds.PROMOTION,
        key: menuThirds.PROMOTION.key,
        name: menuThirds.PROMOTION.name,
        textName: menuThirds.PROMOTION.textName,
        active: false,
        appBar: true,
        path: routeName.promotion + '/update',
        component: FormPromotions
    },
]

export default routePromotion