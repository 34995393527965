import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

interface CheckboxButtonProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    label?: string
    checked?: boolean
    defaultChecked?: boolean
    indeterminate?: boolean
    disabled?: boolean
    inputProps?: any
}

export default function CheckboxButton(props: CheckboxButtonProps) {

    return (
        <FormControlLabel label={props.label} control={
            <Checkbox
                indeterminate={props.indeterminate}
                defaultChecked={props.defaultChecked}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
                inputProps={props.inputProps}
                sx={{
                    color: '#A8A8A8',
                    '&.Mui-checked': {
                        color: '#1CB99A',
                    },
                    '&.Mui-disabled': {
                        color: '#C5C5C5'
                    },
                    '&:active': {
                        color: '#1CB99A'
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: 24
                    }
                }}
            />}
        />

    )
}