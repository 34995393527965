import React from 'react'

type IconProps = {
    width?: string
    height?: string
    fill?: string
}

const IconPromotion = (props: IconProps) => {
    return (
        <svg
            width={props?.width || "24"}
            height={props?.height || "40"}
            viewBox="0 0 24 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4810_279613)">
                <path d="M20.457 12.555L12.486 8.12604C12.3374 8.04338 12.1701 8 12 8C11.8299 8 11.6626 8.04338 11.514 8.12604L3.543 12.555C3.0754 12.8148 2.68577 13.1949 2.41448 13.6559C2.14318 14.117 2.00008 14.6421 2 15.177V27C2.00159 28.3256 2.52888 29.5965 3.46622 30.5338C4.40356 31.4712 5.6744 31.9985 7 32H17C18.3256 31.9985 19.5964 31.4712 20.5338 30.5338C21.4711 29.5965 21.9984 28.3256 22 27V15.177C21.9999 14.6421 21.8568 14.117 21.5855 13.6559C21.3142 13.1949 20.9246 12.8148 20.457 12.555ZM20 27C20 27.7957 19.6839 28.5588 19.1213 29.1214C18.5587 29.684 17.7956 30 17 30H7C6.20435 30 5.44129 29.684 4.87868 29.1214C4.31607 28.5588 4 27.7957 4 27V15.177C3.99956 14.9982 4.0471 14.8224 4.13768 14.6682C4.22827 14.5139 4.35856 14.3868 4.515 14.3L12 10.144L19.486 14.3C19.6422 14.3869 19.7723 14.5141 19.8627 14.6684C19.9531 14.8226 20.0005 14.9983 20 15.177V27Z"
                    fill={props?.fill || "#1CB99A"}
                />
                <path d="M8.952 18.2H12.214C13.3713 18.2 14.2487 18.466 14.846 18.998C15.4433 19.5207 15.742 20.2953 15.742 21.322C15.742 22.4047 15.434 23.2307 14.818 23.8C14.2113 24.36 13.32 24.64 12.144 24.64H10.968V28H8.952V18.2ZM12.312 23.044C12.7973 23.044 13.1613 22.904 13.404 22.624C13.656 22.3347 13.782 21.9193 13.782 21.378C13.782 20.8647 13.6467 20.4773 13.376 20.216C13.1147 19.9453 12.7227 19.81 12.2 19.81H10.968V23.044H12.312Z"
                    fill={props?.fill || "#1CB99A"}
                />
                <path d="M12 16.5002C12.8284 16.5002 13.5 15.8287 13.5 15.0002C13.5 14.1718 12.8284 13.5002 12 13.5002C11.1716 13.5002 10.5 14.1718 10.5 15.0002C10.5 15.8287 11.1716 16.5002 12 16.5002Z"
                    fill={props?.fill || "#1CB99A"}
                />
            </g>
            <defs>
                <clipPath id="clip0_4810_279613">
                    <rect width="24" height="24" fill="white" transform="translate(0 8)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IconPromotion