import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import React, { useRef, useEffect } from 'react';

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

const CustomInput = styled(InputBase)(({ theme }) => ({
  boxShadow: 'none !important',
  flex: 1,
  height: '2rem',
  '&.Mui-disabled': {
    '& + svg': {
      color: `${colors.disabledGray} !important`
    }
  },
  '.MuiInputBase-input': {
    textOverflow: 'ellipsis',
    paddingBottom: 0,
    '-webkit-text-fill-color': 'unset',
    '&::placeholder': {
      color: colors.black60,
      opacity: 1,
    }
  }
}))

type InputProps = {
  onchange?: (value: any) => void
  onClear?: (value: any) => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
  required?: boolean
  disabled?: boolean
  variant?: any
  size?: 'medium' | 'small'
  label?: string
  value?: string
  type?: string
  helperText?: string
  keyInput: any
  formStyle?: any
  onKeyDown?: (event: any) => void
  focus?: boolean
}
export default function InputTextSearch(props: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div>
      <Paper
        component="div"
        sx={{
          p: '0 1rem',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxHeight: '2rem',
          boxShadow: 'unset',
          border: `1px solid  ${colors.black12}`,
          backgroundColor: props.disabled ? colors.black12 : colors.white,
          ...props.formStyle
        }}
        key={`paper-${props.keyInput || 'search'}`}
      >
        <CustomInput
          key={props.keyInput}
          inputRef={props.focus ? inputRef : null}
          inputProps={{ 'aria-label': props.label, onPaste: props.onPaste }}
          onChange={props.onchange}
          size={props.size || 'small'}
          required={props.required}
          placeholder={props.label}
          value={props.value}
          disabled={props.disabled}
          type={props.type || 'text'}
          onKeyDown={props.onKeyDown}
        />
        {!props.value ? <FontAwesomeIcon icon={faSearch} style={{ color: colors.black60, fontSize: 15 }} /> : <FontAwesomeIcon icon={faTimes} onClick={props.onClear} style={{ color: colors.black60, fontSize: 17, cursor: 'pointer' }} />}
      </Paper>
    </div>
  )
}
